import { ReactElement } from 'react';
import { Box,Divider,Grid,Typography } from '@material-ui/core';
import { KYCVerifyForm } from './components';
import TwoFactorFormPage from './TwoFactorAuthPage';
import CryptoAccountsPage from './CryptoAccountsPage';
import BankAccountsPage from './BankAccountsPage';
import AddressManagementPage from './AddressManagementPage';

const AccountSettingPage = (): ReactElement => (
    // UI Elements
    <>
        <Box className="card-header">
            <Grid container direction="row" spacing={0} className='view-profile'>
                <Typography className="title account-setting-text" variant="h3">
                    Account Settings
                </Typography>
            </Grid>
        </Box>
        {/* <Divider className='border-content' /> */}
        <Box className="account-setting">
       
            <Grid xs={12} sm={10} md={8} lg={5} xl={5} className='address-wrapper bank-wrapper setting-box'>
                <Box className='account-box'>
                <AddressManagementPage />
                </Box>
            </Grid>
            <Grid xs={12} sm={10} md={8} lg={5} xl={5} className='address-sub-wrapper bank-wrapper setting-box'>
            {/* <Grid xs={12} sm={10} md={8} lg={5} xl={5} className='address-wrapper bank-wrapper setting-box'> */}
            <Box className='account-box'>
                <BankAccountsPage />
                </Box>
            </Grid>
            <Grid xs={12} sm={10} md={8} lg={5} xl={5} className='address-wrapper bank-wrapper setting-box'>
                <Box className="account-box">
                    <CryptoAccountsPage />
                </Box>
            </Grid>
            <Grid xs={12} sm={10} md={8} lg={10} xl={10} className='kyc-wrapper setting-box'>
                <KYCVerifyForm />
            </Grid>
           
            {/* <Grid xs={12} sm={10} md={8} lg={5} className='crypto-wrapper setting-box'>
            <Box my={6} className="crypto-wrapper">
                <CryptoAccountsPage />
            </Box>
            </Grid>
            <Grid xs={12} sm={10} md={8} lg={5} className='factor-wrapper setting-box'>
            <Box my={6} className="factor-wrapper">
                <TwoFactorFormPage />
            </Box>
            </Grid> */}
        </Box>
        
    </>
);
export default AccountSettingPage;
