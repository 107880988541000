import { ReactElement, useState,useCallback, useEffect } from 'react';
import { Box } from "@material-ui/core";
import debounce from 'lodash.debounce';


interface PriceFilter {
    type: string,
    filter: string,
    price: string,
    setValue: any,
    onChangeHandler:any,
    warningMessage: {
        message: string,
        type: string
    },
}

const PriceFilterInput = (props: any): ReactElement => {
    const [priceValue,setPriceValue] = useState<string>("");
    const {type, filter, price, setValue, onChangeHandler, warningMessage}: PriceFilter = props;
    // const debouncedCall = useCallback(
    //     debounce((num: string, typefilter: string) => {
    //         onChangeHandler(num, typefilter);
    //     },500),
    //     []
    // )
    const priceInputChangeHandler = (e:any): void => {
        setPriceValue(e.target.value);
        // debouncedCall(e.target.value,type);
        onChangeHandler(e.target.value,type);
    }

    useEffect(()=>{
        setPriceValue(price);
    },[price]);

    return (
        <Box className="filter-container price-filter-container">
            <p style={{marginRight:`${type==='min'?'7px':'3px'}`,fontSize:'1rem'}}>
                {type==='min'?'Min':'Max'}
            </p>
            <input type="number" className='price-filter-input' value={`${priceValue}`} onChange={priceInputChangeHandler}  placeholder={`${filter}`} />
        </Box>
    );
};

export default PriceFilterInput;