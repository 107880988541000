import { ReactElement } from 'react';
import { HeadTag } from '../../shared/components';
import WalletBalance from './components/WalletBalance';

const WalletPage = (props:any): ReactElement => (
    <>
        <HeadTag title="Wallet" />
        <WalletBalance {...props} />
    </>
);

export default WalletPage;
