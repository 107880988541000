import Dialog from '@material-ui/core/Dialog';
import { Box, DialogContent, IconButton } from '@material-ui/core';
import { useState } from 'react';
import { Close } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';

const ViewImageZoom = (props: any): any => {
    const { fullimage, preview, com } = props;
    const [open, setOpen] = useState(false);
    const [load, setLoad] = useState(true);

    const handleClickOpen = (): any => {
        setOpen(true);
    };

    const handleClose = (): any => {
        setOpen(false);
    };

    const onloadfile = (): any => {
        setLoad(false);
    };

    return (
        <>
            <Box onClick={handleClickOpen}>{com}</Box>
            <Dialog
                fullScreen
                open={open}
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        borderRadius: '0px',
                    },
                }}
                BackdropProps={{ style: { background: 'rgba(0, 0, 0, 0.8)' } }}
                // style={{ backgroundColor: 'transparent' }}
                onClose={handleClose}
            >
                <IconButton
                    style={{
                        position: 'fixed',
                        top: '0.5rem',
                        right: '0.5rem',
                        color: '#fff',
                        zIndex: 2300,
                    }}
                    onClick={handleClose}
                >
                    <Close />
                </IconButton>
                <DialogContent>
                    <Box
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        flexDirection="column"
                        // height='auto'
                        className="zoom-image-container"
                    >
                        {load && (
                            <Skeleton
                                variant="rect"
                                width="80vh"
                                height="60vh"
                            />
                        )}
                        <img
                            onLoad={onloadfile}
                            style={{ objectFit: 'contain' }}
                            src={fullimage}
                            alt="item"
                            width="100%"
                            height="100%"
                        />
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ViewImageZoom;
