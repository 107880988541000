import { Box } from "@material-ui/core";
import { ReactElement } from 'react';

const CuratingLoading = (props: any): ReactElement => {
    const { name } = props

    return (<>
        <Box height='78vh'>
            <div className="globlLoading">
                <Box className="empty-content">
                    <img height='200px' alt='loader' src="/images/Comploader.gif" />
                </Box>
            </div>
        </Box>
    </>

    )
}

export default CuratingLoading;