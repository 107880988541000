import { ReactElement } from 'react';
import { AddressManagementList } from './components';
import { HeadTag } from '../../shared/components';

const AddressManagementPage = (): ReactElement => (
    <>
        <HeadTag title="Address Management" />
        <AddressManagementList />
    </>
);

export default AddressManagementPage;
