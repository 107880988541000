import { useState, useEffect, ReactElement } from 'react';
import {
    Box,
    Grid,
    Avatar,
    Dialog,
    DialogContent,
    DialogTitle,
    Card,
    CardContent,
    Typography,
    IconButton,
    Divider,
} from '@material-ui/core';
import moment from 'moment';
import 'moment-timezone';
import CloseIcon from '@material-ui/icons/Close';
import {
    AppPrimaryButton,
    AppSecondaryButton,
} from '../../../shared/components/AppComponents';
import { Utils } from '../../../shared/helpers';

export interface Currency {
    amount: string;
    currencyType: string;
}

export interface CurrencyConvertion {
    from: Currency;
    to: Currency;
    holdingId: string;
    expiresIn: Date;
}

const SaleConfirmation = (props: any): ReactElement => {
    const {
        open,
        onClose,
        quantity,
        price,
        sellType,
        feePart,
        expireIn,
        timezone,
        isLoading,
        actionPlaceSellList,
        itemDetail,
        editions,
    } = props;
    // const itemDetail = itemDetail;
    const [purchaseType, setPurchaseType] = useState('direct');

    // Hooks
    useEffect(() => {
        if (sellType === 1) {
            setPurchaseType('direct');
        } else if (sellType === 2) {
            setPurchaseType('auction');
        }
    }, []);

    // UI Elements
    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth="md"
                fullWidth
                className="sale-confirmation-wrapper"
            >
                <DialogTitle>
                    <Box className="title-section">
                        <Typography variant="h3"> { sellType === 1 ? 'Sale' : 'Auction' }</Typography>
                        <IconButton onClick={onClose}>
                            <CloseIcon color="primary" />
                        </IconButton>
                    </Box>
                    <Divider />
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={4} className="checkout-section">
                        <Grid item xs={12} sm={7} className="left-panel">
                            <Box className="payment-type">
                                <Box>
                                    <Typography variant="h4">
                                        Confirmation
                                    </Typography>
                                    <Box mt={2}>
                                        <Typography variant="body1">
                                            Are you sure, you want to { sellType === 1 ? 'sell' : 'auction' } this
                                            NFT ?
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box mb={8}>
                                    <Grid container spacing={2}>
                                        <Grid
                                            item
                                            xs={6}
                                            className="end-content"
                                        >
                                            <AppSecondaryButton
                                                title="CANCEL"
                                                fullWidth
                                                onClick={() => onClose()}
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            xs={6}
                                            className="end-content"
                                        >
                                            <AppPrimaryButton
                                                title={`CONFIRM &  ${ sellType === 1 ? 'SELL' : 'AUCTION' }` }
                                                disabled={isLoading}
                                                isLoading={isLoading}
                                                onClick={actionPlaceSellList}
                                                fullWidth
                                                loadingText={`POSTING ${ sellType === 1 ? 'SELL' : 'AUCTION' }`}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <Card className="right-tile sale-confirmation-panel ">
                                <CardContent>
                                    <Box pb={2}>
                                        <Typography variant="h4">
                                        { sellType === 1 ? 'Sell' : 'Auction' } Summary
                                        </Typography>
                                    </Box>
                                    <Box pb={2}>
                                        <Grid
                                            container
                                            wrap="nowrap"
                                            spacing={2}
                                        >
                                            <Grid item>
                                                <Avatar
                                                    className="item-image"
                                                    src={
                                                        itemDetail.itemInfo
                                                            .thumbnail
                                                    }
                                                    variant="square"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    variant="body2"
                                                    className="text-gray"
                                                >
                                                    {
                                                        itemDetail
                                                            .collectionInfo.name
                                                    }
                                                </Typography>
                                                <Typography variant="body1">
                                                    {itemDetail.itemInfo.name}
                                                </Typography>
                                                <Box my={2}>
                                                    {purchaseType ===
                                                        'auction' && (
                                                        <Typography
                                                            variant="body1"
                                                            className="text-gray"
                                                        >
                                                            Min Bid
                                                        </Typography>
                                                    )}

                                                    <Typography variant="h4">
                                                        {price} USD
                                                        <span className="text-gray f14">
                                                            {`* ${quantity} Quantity`}
                                                        </span>
                                                    </Typography>

                                                    {sellType === 2 && (
                                                        <>
                                                            <Typography
                                                                variant="body1"
                                                                className="text-gray"
                                                            >
                                                                Auction ends in{' '}
                                                                {moment()
                                                                    .add(
                                                                        expireIn,
                                                                        'day'
                                                                    )
                                                                    .format(
                                                                        'DD MMM yyyy hh:mm A'
                                                                    )}{' '}
                                                                {timezone}{' '}
                                                            </Typography>
                                                        </>
                                                    )}
                                                </Box>

                                                <Box
                                                    display="flex"
                                                    mt={2}
                                                    alignItems="center"
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        className="mr-2"
                                                    >
                                                        Editions:
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        className="gray-dark"
                                                    >
                                                        {Utils.sortEditions(
                                                            editions
                                                        ).join(', ')}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <>
                                        <hr />

                                        <Box display="flex" pt={1}>
                                            <Box flexGrow={1}>
                                                <Typography variant="body1">
                                                    Fee{' '}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="body1">
                                                    {feePart.fee}{' '}
                                                    {feePart.currencyType}
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <Box display="flex" pt={1}>
                                            <Box flexGrow={1}>
                                                {' '}
                                                <Typography variant="body1">
                                                    Royalty
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="body1">
                                                    {feePart.royalty}{' '}
                                                    {feePart.currencyType}
                                                </Typography>
                                            </Box>
                                        </Box>

                                        {sellType === 1 && (
                                            <>
                                                <Box display="flex" pt={1}>
                                                    <Box flexGrow={1}>
                                                        <Typography variant="body1">
                                                            Seller Cost
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography variant="body1">
                                                            {feePart.sellerCost}{' '}
                                                            {
                                                                feePart.currencyType
                                                            }
                                                        </Typography>
                                                    </Box>
                                                </Box>

                                                <Box display="flex" pt={1}>
                                                    <Box flexGrow={1}>
                                                        <Typography variant="subtitle1">
                                                            Total
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography variant="subtitle1">
                                                            {feePart.totalCost}{' '}
                                                            {
                                                                feePart.currencyType
                                                            }
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </>
                                        )}
                                    </>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default SaleConfirmation;
