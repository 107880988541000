/* eslint no-underscore-dangle: 0 */
import { ReactElement, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {
    Typography,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    TablePagination,
    Box,
    Avatar,
    Divider,
} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/Receipt';
import moment from 'moment';
import { Utils } from '../../../shared/helpers';
import WithdrawReqSkeleton from '../../../shared/skeletons/WithdrawReqSkeleton';
import { EmptyPlaceholder } from '../../../shared/components';
import { ProfileService, DefaultPagination } from '../../../services';

export interface PaymentTransactionInfo {
    _id: string;
    userId: string;
    status: string;
    itemId: {
        _id: any;
        name: string,
        thumbnail: string;
    };
    saleId: string;
    quantity: string;
    amount: string;
    royalty: string;
    fee: string;
    feeInValue: string;
    exhibitionFee: string;
    createdAt: string;
    modeOfPayment: string;
    paymentId: string;
    paymentRef: string;
    tokenType: string;
    transactionid: string;
    directNFTTransfer: boolean;

}

const PaymentTransactionList = (): ReactElement => {
    const [financialList, setFinancialList] = useState<
        PaymentTransactionInfo[]
    >([]);
    const [pagination, setPagination] = useState({ ...DefaultPagination(10) });
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate();


    // API Calls
    const loadFinancialTransaction = (): any => {
        ProfileService.getPyamentTransaction(
            pagination.limit,
            pagination.offset,
        )
            .then((response: any) => {
                const { transactions, pagination: paginationResponse } = response.data.data;

                setFinancialList(transactions);
                setPagination(paginationResponse);
                setLoader(false);
            })
            .catch((error: any) => {
                setLoader(false);
                Utils.handleErrorResponse({
                    error,
                });
            });
    };

    // Hooks
    useEffect(() => {
        setLoader(true);
        loadFinancialTransaction();
    }, []);

    // UI Actions
    const actionHandleLimitChange = (event: any): any => {
        const rowsPerPage = event.target.value;
        pagination.limit = parseInt(rowsPerPage, 10);
        loadFinancialTransaction();
    };

    const actionHandlePageChange = (event: any, newPage: number): any => {
        pagination.offset = newPage + 1;
        loadFinancialTransaction();
    };

    const actionToItem = (id: any): any => {
        navigate(`/item/${id}`)
    }

    // UI Elements
    return (
        <Box className="profile-content">
            <Box className="card-header-btn view-profile">
                <Typography className="title" variant="h3">
                    Wallet History
                </Typography>
            </Box>
            <Divider className='border-content' />
            <Box className="profile-card">
                <Typography variant="body1" className="subtitle">
                    You can view all your payment transactions here.
                </Typography>

                <Box mt={4}>
                    {financialList.length === 0 && loader === false && (
                        <EmptyPlaceholder
                            icon={<ReceiptIcon />}
                            message="No Payment History found"
                        />
                    )}
                    {loader === true && <WithdrawReqSkeleton />}

                    {loader === false && financialList.length > 0 && (
                        <>
                            <TableContainer>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell >
                                                S.No
                                            </TableCell>
                                            <TableCell>
                                                Logo
                                            </TableCell>
                                            <TableCell>
                                                Item Name
                                            </TableCell>
                                            <TableCell >
                                                Transaction Id
                                            </TableCell>
                                            {/* <TableCell>Item Id</TableCell> */}
                                            {/* <TableCell >
                                                Quantity
                                            </TableCell> */}
                                            <TableCell >
                                                Amount
                                            </TableCell>
                                            {/* <TableCell >
                                                Royalty
                                            </TableCell> */}
                                            {/* <TableCell >
                                                Fee
                                            </TableCell> */}
                                            {/* <TableCell >
                                                exhibitionFee
                                            </TableCell> */}
                                            <TableCell >
                                                Status
                                            </TableCell>
                                            <TableCell >
                                                Payment Mode
                                            </TableCell>
                                            <TableCell >
                                                Date
                                            </TableCell>
                                            <TableCell >
                                                Direct Transfer
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {financialList.map(
                                            (financiallist, index) => (
                                                <TableRow
                                                    hover
                                                    style={{cursor:'pointer'}}
                                                    className="f13 exo2-regular"
                                                    key={financiallist._id}
                                                    onClick={() => actionToItem(financiallist.itemId._id)}
                                                >
                                                    <TableCell>
                                                        {(pagination.offset - 1) *
                                                            pagination.limit +
                                                            (index + 1)}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Avatar
                                                            alt='L'
                                                            src={
                                                                Utils.getUrl(financiallist.itemId ? financiallist.itemId.thumbnail : '')
                                                            }
                                                        />
                                                    </TableCell>

                                                    <TableCell>
                                                        {financiallist.itemId ? financiallist.itemId.name : ''}
                                                    </TableCell>
                                                    <TableCell>
                                                        {/* <Avatar
                                                            src={
                                                                financiallist.item
                                                                    .thumbnail
                                                            }
                                                        /> */}
                                                        {financiallist._id}
                                                    </TableCell>
                                                    {/* <TableCell
                                                        component="td"
                                                        scope="row"
                                                    >
                                                        {financiallist.itemId}
                                                    </TableCell> */}
                                                    {/* <TableCell
                                                        component="td"
                                                        scope="row"
                                                    >
                                                        {financiallist.quantity}
                                                    </TableCell> */}
                                                    <TableCell
                                                        component="td"
                                                        scope="row"
                                                    >
                                                        {financiallist.amount} USD
                                                    </TableCell>
                                                    {/* <TableCell
                                                        component="td"
                                                        scope="row"
                                                    >
                                                        {financiallist.royalty} USD
                                                    </TableCell> */}
                                                    {/* <TableCell
                                                        component="td"
                                                        scope="row"
                                                    >
                                                        {financiallist.feeInValue} USD
                                                    </TableCell> */}
                                                    {/* <TableCell
                                                        component="td"
                                                        scope="row"
                                                    >
                                                        {financiallist.exhibitionFee} USD
                                                    </TableCell> */}
                                                    <TableCell
                                                        component="td"
                                                        scope="row"
                                                    >
                                                        {financiallist.status}
                                                    </TableCell>
                                                    <TableCell
                                                        component="td"
                                                        scope="row"
                                                    >
                                                        {Utils.formatPaymentModes(
                                                            financiallist.modeOfPayment
                                                        )}
                                                    </TableCell>
                                                    <TableCell
                                                        component="td"
                                                        scope="row"
                                                        style={{ whiteSpace: 'nowrap' }}
                                                    >
                                                        {moment(
                                                            financiallist.createdAt
                                                        ).format(
                                                            'DD MMM YYYY h:mm a'
                                                        )}
                                                    </TableCell>
                                                    <TableCell
                                                        component="td"
                                                        scope="row"
                                                        style={{ whiteSpace: 'nowrap' }}
                                                    >
                                                        {`${financiallist.directNFTTransfer?"Yes":"No"}`}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                component="div"
                                count={pagination.total}
                                onPageChange={actionHandlePageChange}
                                onRowsPerPageChange={actionHandleLimitChange}
                                page={pagination.offset - 1}
                                rowsPerPage={pagination.limit}
                                rowsPerPageOptions={[5, 10, 25]}
                            />
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default PaymentTransactionList;
