import { createTheme } from '@material-ui/core';
import Typography from './overrides/Typography';
import Palette from './overrides/Palette';
import MuiButton from './overrides/MuiButton';

const theme = createTheme({
    spacing: 4,
    palette: Palette,
    typography: Typography,
    overrides: {
        MuiTypography: {},
        MuiButton,
        MuiOutlinedInput: {
            root: {},
        },
        MuiFormLabel: {
            root: {
                color: '#151515',
            },
        },
        MuiCheckbox: {
            root: {
                color: '#151515',
            },
        },
        MuiTableCell: {
            root: {
                'border-bottom': 'none',
            },
            head: {
                'font-size': '14px',
                fontWeight: 400,
            },
            body: {
                'font-size': '14px',
                fontWeight: 300,
            },
            stickyHeader: {
                backgroundColor: '#f2f2f2',
            },
        },
        MuiDialog: {
            paper: {
                backgroundColor: 'white',
                borderRadius: '5px',
            },
        },
        MuiPopover: {
            paper: {},
        },
        MuiListItem: {
            root: {},
        },
        MuiListItemText: {
            root: {
                color: '#151515',
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: '40px',
            },
        },
        MuiDrawer: {
            paper: {
                backgroundColor: '#171717',
                borderRadius: '20px',
                border: '1px solid #1C2022',
            },
        },
        MuiAvatar: {
            root: {},
            circle: {},
            square: {},
            img: {},
            colorDefault: {
                backgroundColor: '#8522fa',
            },
        },
        MuiTooltip: {
            tooltip: {
                backgroundColor: '#1c2022',
                padding: '20px',
            },
        },
        MuiChip: {
            root: {
                borderRadius: '30px',
                padding: '15px 10px',
                height: 'auto',
            },
        },
    },
});

export default theme;
