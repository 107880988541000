import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Grid,
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    IconButton,
    Divider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { SecondaryEmailSchema } from '../../../validators/ProfileSchema';
import { ProfileActions } from '../../../state/profile/Actions';
import { AppState } from '../../../state/RootReducer';
import { Utils } from '../../../shared/helpers';
import {
    AppPrimaryButton,
    AppFormikTextField,
} from '../../../shared/components/AppComponents';
import { ProfileService } from '../../../services';

const SecondaryEmailForm = (props: any): ReactElement => {
    const { profileInfo, updateSecondaryEmail, showForm, onCloseForm } = props;
    const navigate = useNavigate();
    const emailForm = {
        email: profileInfo.secondaryEmailId,
    };

    // API Calls
    const saveProfile = (formValues: any, callback: any): any => {
        ProfileService.secondaryEmail(formValues)
            .then((response: any) => {
                Utils.handleSuccessResponse(response);
                callback(true);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({
                    error,
                });
                callback(false);
            });
    };

    // UI Actions
    const actionSaveDetail = (formValues: any, formik: any): any => {
        formik.setSubmitting(true);
        saveProfile(formValues, (status: boolean) => {
            if (status) {
                updateSecondaryEmail(formValues);
                navigate('/profile/view');
                onCloseForm();
            }
            formik.setSubmitting(false);
        });
    };

    const actionOnCancel = (): void => {
        onCloseForm();
    };

    // UI Elements
    return (
        <Dialog disableEscapeKeyDown open={showForm}>
            <DialogTitle>
                <Box className="title-section">
                    <Box className="flex-column">
                        <Typography variant="h4">Secondary Email</Typography>
                        <Box my={1}>
                            <Typography variant="body2">
                                Update your email to receive notifications
                            </Typography>
                        </Box>
                    </Box>
                    <IconButton onClick={actionOnCancel}>
                        <CloseIcon color="primary" />
                    </IconButton>
                </Box>
                <Divider />
            </DialogTitle>
            <DialogContent>
                <Formik
                    enableReinitialize
                    initialValues={emailForm}
                    validationSchema={SecondaryEmailSchema}
                    validateOnMount
                    onSubmit={(formValues, formik) => {
                        actionSaveDetail(formValues, formik);
                    }}
                >
                    {({ handleSubmit, isSubmitting, isValid }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container>
                                <Grid xs={12} item>
                                    <AppFormikTextField
                                        label="Email"
                                        name="email"
                                        type="text"
                                        outerLabel
                                        size="large"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="flex-end">
                                <AppPrimaryButton
                                    title="SAVE"
                                    isLoading={isSubmitting}
                                    loadingText="SAVING"
                                    disabled={isSubmitting || !isValid}
                                    onClick={handleSubmit}
                                />
                            </Grid>
                        </form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

const mapStateToProps = (state: AppState): any => ({
    profileInfo: state.profileInfo,
});

const mapDispatchToProps = (dispatch: any): any => ({
    updateSecondaryEmail: (data: any) =>
        dispatch(ProfileActions.updateSecondaryEmail(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SecondaryEmailForm);
