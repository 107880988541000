import { Grid, Box, Container } from '@material-ui/core';
import { ReactElement } from 'react';
import { HeadTag } from '../../shared/components';
import { MarketPlaceTab } from './components';

const MarketPlace = (): ReactElement => {
    const title = 'Marketplace';
    const description =
        'Rarest Collectibles from most famous personalities of Bollywood, Sports, Music and art are available at TUS NFT Marketplace. Check it out now.';

    return (
        <Container>
            <HeadTag title={title} description={description} />
            <Grid container >
                <Grid
                    className="nft-panel"
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                >
                    <Box className="nft-list">
                        <MarketPlaceTab />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default MarketPlace;
