import {
    Popover,
    List,
    ListItem,
    ListItemIcon,
    Box,
    IconButton,
    ListItemText,
} from '@material-ui/core';
import { ReactElement, useState } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

export interface MoreMenuData {
    title: string;
    icon: any;
}
interface MoreMenuProps {
    menus: MoreMenuData[];
    onMenuSelect: any;
}
const MoreMenu = (props: MoreMenuProps): ReactElement => {
    const { menus, onMenuSelect } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    // UI Actions
    const handleClick = (event: any): void => {
        setAnchorEl(event.currentTarget);
    };

    const closePopup = (): void => {
        setAnchorEl(null);
    };

    // UI Elements
    return (
        <Box className="popover-menu">
            <IconButton
                className="btn-teritary btn-small"
                onClick={handleClick}
            >
                <MoreHorizIcon />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={closePopup}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List style={{ marginBottom: '10px' }} component="nav">
                    {menus.map((menu, index) => (
                        <ListItem
                            key={`moremenu_${index}`}
                            button
                            onClick={() => {
                                onMenuSelect(menu, index);
                                closePopup();
                            }}
                        >
                            <ListItemIcon>{menu.icon}</ListItemIcon>
                            <ListItemText primary={menu.title} />
                        </ListItem>
                    ))}
                </List>
            </Popover>
        </Box>
    );
};

export default MoreMenu;
