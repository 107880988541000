import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Grid, Divider } from '@material-ui/core';
import { Formik } from 'formik';
import { ResetPasswordSchema } from '../../../validators/AuthenticationSchema';
import {
    AppPrimaryButton,
    AppFormikPasswordField,
} from '../../../shared/components/AppComponents';
import { Toast, Utils } from '../../../shared/helpers';
import { AuthenticationService } from '../../../services';

const ResetPasswordForm = (props: any): ReactElement => {
    const navigate = useNavigate();
    const resetPassordForm = {
        newPassword: '',
        confirmPassword: '',
    };
    const { id } = props;
    const token = id;

    // API Calls
    const resetpassword = (requestObj: any, callback: any): any => {
        AuthenticationService.resetPassword(requestObj)
            .then((response: any) => {
                Utils.handleSuccessResponse(response);
                callback(true);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
                callback(false);
            });
    };

    // UI Actions
    const actionResetPassword = (formValues: any, formik: any): any => {
        const { newPassword,  confirmPassword } = formValues;
        if (newPassword !== confirmPassword) {
            Toast.showErrorMessage(
                "New password and confirm password does not match."
            );
            formik.setSubmitting(false);
            formik.resetForm();
        } else {
            const requestObj = {
                code: token,
                password: formValues.newPassword,
            };
            resetpassword(requestObj, (status: boolean) => {
                formik.setSubmitting(true);
                if (status) {
                    navigate('/login', { replace: true });
                }
                formik.setSubmitting(false);
            });
        }
    };

    // UI Elements
    return (
        <Box className="auth-bg">
            <Box className="auth-content">
                <Formik
                    initialValues={resetPassordForm}
                    validationSchema={ResetPasswordSchema}
                    enableReinitialize
                    validateOnMount
                    onSubmit={(values: any, formik: any): any => {
                        actionResetPassword(values, formik);
                    }}
                >
                    {({ handleSubmit, isSubmitting, isValid }): any => (
                        <Grid item xs={12}>
                            <form autoComplete="off">
                                <Box>
                                    <Box className='reset-password-content'>
                                        <Typography
                                            variant="h3"
                                            className="mb-4 auth-main-title"
                                        >
                                            Reset password
                                        </Typography>
                                        <Divider className='auth-divider'/>
                                    </Box>
                                </Box>

                                <Box>
                                    {/* <InputLabel required className="mt-4">
                                        New Password
                                    </InputLabel> */}
                                    <AppFormikPasswordField
                                        name="newPassword"
                                        type="password"
                                        size="medium"
                                        placeholder ="New Password*"
                                    />
                                </Box>

                                <Box>
                                    {/* <InputLabel required>
                                        Confirm Password
                                    </InputLabel> */}
                                    <AppFormikPasswordField
                                        name="confirmPassword"
                                        type="password"
                                        size="medium"
                                        placeholder ="Confirm Password*"
                                    />
                                </Box>
                                <Box py={2} className="text-center">
                                    <AppPrimaryButton
                                        title="RESET"
                                        className='backBtn auth-label'
                                        isLoading={isSubmitting}
                                        loadingText="RESETING"
                                        disabled={isSubmitting || !isValid}
                                        onClick={handleSubmit}
                                    />
                                </Box>
                            </form>
                        </Grid>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

export default ResetPasswordForm;
