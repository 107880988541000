import { ReactElement } from 'react';
import {
    Box,
    Grid,
    Typography,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    Divider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { AppPrimaryButton } from '../../../shared/components/AppComponents';

const DisClaimerForm = (props: any): ReactElement => {
    const { open, onCloseForm, isAgreed } = props;

    return (
        <Dialog maxWidth="xs" fullWidth open={open}>
            <DialogTitle>
                <Box className="title-section">
                    <Typography variant="h4">Disclaimer</Typography>
                    <IconButton onClick={onCloseForm}>
                        <CloseIcon color="primary" />
                    </IconButton>
                </Box>
                <Divider />
            </DialogTitle>
            <DialogContent>
                <Box>
                    <Typography variant="body1">
                        Buyer is responsible for the cost of shipping, handling
                        and insuring the artwork during transfer. The cost of
                        shipping and insurance, depending on the size of the
                        item and location of the buyer, shall be communicated to
                        the buyer via E-mail once we receive the Redeem Request.
                    </Typography>

                    <Grid container className="end-content mt-4">
                        <AppPrimaryButton
                            title="Agree and Continue"
                            onClick={isAgreed}
                        />
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default DisClaimerForm;
