
import {
    Box,
    Dialog,
    DialogContent,
    Typography,
    IconButton,
    Divider,
    Button,
    CircularProgress,
    Tooltip,
    Fade,
    Chip
} from '@material-ui/core';
import moment from 'moment';
import Flatpickr from 'react-flatpickr'
import "flatpickr/dist/themes/light.css";
import { ReactElement, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { getUrl, getHeaders } from '../../../shared/helpers/Utils';
import axios from '../../../shared/managers/AxiosManager';
import { Toast } from '../../../shared/helpers';

const PostOffer = (props?: any): ReactElement => {
    const { detail: { itemInfo }, detail, getItemDetail, ownerInfo ,offer ,setOffer ,onPostOffer } = props;
    const [open, setOpen] = useState(offer)
    const [picker, setPicker] = useState(new Date())
    const [payload, setPayload] = useState({ tokenType: 'ERC1155', quantity: 0, pricePerQuantity: 0 })
    const [loading, setLoading] = useState(false)



    const handleInput = (e: any): any => {
        const { name, value } = e.target
        setPayload({ ...payload, [name]: Number(value) })
    }
    const dateChange = (date: any): any => {
        setPicker(date[0])
    }
    const onCloseDialog = (): any => {
        setOpen(!open)
        setOffer(!open)
    }

    const addToPostOffer = (): any => {
        setLoading(true)
        onPostOffer(payload)
        setOpen(!open)
        setOffer(!open)

        
    }

    const lessThenQty =  (e: any):any => {
        if (Number(e.target.value) > itemInfo?.maxPostOfferQuantity) {
            e.target.value = 0
            Toast.showInfoMessage("Can't add quantity more then available quantity")
        }
    }
   
    const moreThenPrice =  (e: any):any => {
        if (Number(e.target.value) < itemInfo?.minOfferPricePerQuantity) {
        
            Toast.showInfoMessage("Can't add price less then primary sale amount")
        }
    }

    return (
        <div style={{width:'100%'}} title='Sell'>
            <Dialog className="dialog-sell" maxWidth='sm' onClose={onCloseDialog} open={open} >
                <Box
                    display='flex'
                    justifyContent='end'
                >
                    <IconButton onClick={onCloseDialog}>
                        <CloseIcon fontSize='small' color='primary' />
                    </IconButton>
                </Box>
                <DialogContent   className='sell-content' >
                    <Box
                        display='flex'
                        justifyContent='start'
                        alignItems='center'
                    >
                        <Box mr={6}>
                            <img height='90' width='90' src={getUrl(`${itemInfo.thumbnail}`, '200x200/')} className='sellimage' />
                        </Box>
                        <Box>
                            <Typography variant='h3'>{itemInfo?.name}</Typography>
                        </Box>
                    </Box>
                    <Box 
                        justifyContent='center'
                        display='flex'   
                        alignItems='center' 
                    >
                        <Typography
                            className="title nft-type"
                            variant="body2"
                        >
                            Offer price should be more then {"  "}{itemInfo?.minOfferPricePerQuantity} USD
                        </Typography>
                       
                    </Box>
                    <Box
                        mt={2}
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                    >
                        <Typography variant='h5'>
                            Quantity
                        </Typography>
                        <input
                            onInput={lessThenQty}
                            autoComplete='false'
                            onChange={handleInput} type='number'
                            name='quantity'
                            className='sellItem-bid-input' defaultValue={itemInfo?.price} placeholder='QTY' />
                    </Box>
                    <Box my={2}>
                        <Divider />
                    </Box>
                    <Box
                        mt={2}
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                    >
                        <Typography variant='h5'>
                            Set Price
                        </Typography>
                        <Box
                            className='bidWrapper'
                            display='flex'
                            flexDirection='row'
                            alignItems='center'>
                            <input
                                onInput={moreThenPrice}
                                autoComplete='false'
                                onChange={handleInput}
                                type='number'
                                name='pricePerQuantity'
                                className='sellItem-bid-input' defaultValue={itemInfo?.price} placeholder='USD' />
                        </Box>
                    </Box>
                    <Box my={2}>
                        <Divider />
                    </Box>
                    <Box>
                        <Button
                            disabled={payload?.quantity === 0 || payload?.pricePerQuantity < itemInfo?.minOfferPricePerQuantity || loading}
                            className='sellButton' onClick={addToPostOffer}>
                            {loading ? <span><CircularProgress style={{ width: '1rem', height: '1rem' }} color='secondary' /> Loading ...</span> : <span>POST OFFER</span>}
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default PostOffer;
