import { RestEnds } from '../../shared/constants';

export const AuthenticationMock = (mockAxios: any): void => {
    // Register success/failed mock
    mockAxios.onPost(RestEnds.REGISTER).reply((config: any) => {
        const data = JSON.parse(config.data);
        if (data.email === 'test@gmail.com' && data.password === 'Test@123') {
            return [
                201,
                {
                    data: {
                        message: 'Account created success fully',
                    },
                },
            ];
        }
        if (data.email === 'test@gmail.fail' && data.password === 'Test@123') {
            return [
                412,
                {
                    data: {
                        message: 'Email is already exists',
                    },
                },
            ];
        }
        // passThrough
        return mockAxios.originalAdapter(config);
    });
    // Success Login Mock
    mockAxios
        .onPost(RestEnds.LOGIN, {
            email: 'test@gmail.com',
            password: 'Test@123',
        })
        .reply(200, {
            data: {
                accessToken:
                    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJFemlUZWNoIiwiYXVkIjoiRXppVGVjaCIsImFkbWluSWQiOiI2MDc1NDI4NmEyN2VhODAwMzI0ZGZkNDEiLCJuYW1lIjoiSmV5YWRoYXJhbmkiLCJ0eXBlIjoiQWNjZXNzIiwiaWF0IjoxNjE4MzE0NjI5LCJleHAiOjE2MTgzMTU1Mjl9.FmbqevIHAvMwKMK98RUatJQeER0ue81u6rNUcqM1__I',
                refreshToken:
                    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJFemlUZWNoIiwiYXVkIjoiRXppVGVjaCIsImFkbWluSWQiOiI2MDc1NDI4NmEyN2VhODAwMzI0ZGZkNDEiLCJuYW1lIjoiSmV5YWRoYXJhbmkiLCJ0eXBlIjoiQWNjZXNzIiwiaWF0IjoxNjE4MzE0NjI5LCJleHAiOjE2MTgzMTU1Mjl9.FmbqevIHAvMwKMK98RUatJQeER0ue81u6rNUcqM1__I',
            },
        });
    // Failure Login Mock
    mockAxios
        .onPost(RestEnds.LOGIN, {
            email: 'test@gmail.fail',
            password: 'Test@123',
        })
        .reply(412, {
            data: {
                message: 'Invalid login credentials',
            },
        });
};
