/* eslint no-underscore-dangle: 0 */
import { ReactElement } from 'react';
import { Typography, Box } from '@material-ui/core';
import { connect } from 'react-redux';
import { AppState } from '../../../state/RootReducer'
import { MarketPlaceActions } from '../../../state/marketplace/Action';
import { MarketPlaceFilterState } from '../../../state/marketplace/Reducer';
import { MarketFilterState } from '../../../state/filter/Reducer';

interface FilterProps {
    marketPlaceFilter: MarketPlaceFilterState | any;
    marketFilterList: MarketFilterState| any;
    setPrice: any;
    setFilter: any;
}

const AssetTypeFilter = (props: any): ReactElement => {
    const url = window.location.href.split('/')[4];
    const {
        marketPlaceFilter,
        setFilter,
    }: FilterProps = props;
   
    const assetFilterChangeHandler = (event: any): void => {
        
        setFilter({
            ...marketPlaceFilter,
            // [event.target.name]: event.target.value,
            'assetType': event.target.value
        });
    } 
  
    return (
        <Box className='region-wrapper region-wrapper-py'>
            <Typography className="filter-margin">
                Media Type
            </Typography>
            <Box className='region-form'>
            <Box className="region-form-inner">
                <select 
                    // name="region" 
                    className='region-form-control'
                    onChange={assetFilterChangeHandler}
                    value={marketPlaceFilter?.assetType}
                >
                    <option value="" selected>Choose...</option>
                    <option value="images" key="0" >Image</option>
                    <option value="video" key="1" >Video</option>
                    <option value="gif" key="2" >GIF</option>
                    <option value="audio" key="3" >Audio</option>
                    
                </select>
                </Box>
            </Box>
        </Box>
    )
}

const mapStateToProps = (state: AppState): any => ({
    marketPlaceFilter: state?.marketPlaceFilter||[],
    marketFilterList: state?.MarketFilterList||[],
});

const mapDispatchToProps = (dispatch: any): any => ({
    setFilter: (data: any) => dispatch(MarketPlaceActions.setFilter(data)),
});


export default connect(mapStateToProps, mapDispatchToProps)(AssetTypeFilter);