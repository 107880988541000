import { ReactElement } from 'react';
import GradientChip, { GradientVariants } from './GradientChip';

interface NFTTagProps {
    tag: string;
}
const NFTTag = (props: NFTTagProps): ReactElement => {
    const { tag } = props;
    // UI Elements
    return (
        <>
            {tag && (
                <GradientChip variant={GradientVariants.LIGHT} label={tag} />
            )}
        </>
    );
};

export default NFTTag;
