import { ReactElement, useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Grid,
    TextField,
    Button,
    FormControlLabel
} from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { connect } from 'react-redux';
import EditProfileForm from './EditProfileForm';
import { AppState } from '../../../state/RootReducer';
import { ProfileState } from '../../../state/profile/Reducer';
import { ProfileActions } from '../../../state/profile/Actions';
import { Utils } from '../../../shared/helpers';
import {
    AppPrimaryButton,
} from '../../../shared/components/AppComponents';
import { CollectionService, ProfileService } from '../../../services';
import { MarketPlaceFilterState } from '../../../state/marketplace/Reducer';

export interface ViewProfileProps {
    profileInfo: ProfileState;
}

interface FilterProps {
    marketPlaceFilter: MarketPlaceFilterState | any;
    setPrice: any;
    setFilter: any;
    actionOpenSetting: any;
}
/* eslint no-underscore-dangle: 0 */
const ViewProfile = (props: any): ReactElement => {
    const { profileInfo, setProfileData, updatePrivateStatus, updateUserName, actionOpenSetting } =
        props;

    // Username
    const [showUsernameForm, setShowUsernameForm] = useState(false);
    const [usernameError, setUsernameError] = useState('');
    const [checkValidUsername, setCheckValidUsername] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const [showShare, setShowShare] = useState(false);
    const [oldUserName, setOldUserName] = useState('');
    const [newUserName, setNewUserName] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [bio, setBio] = useState('');
    const [region, setRegion] = useState('');
    const [discord, setDiscord] = useState('');
    const [twitter, setTwitter] = useState('');
    const [instagram, setInstagram] = useState('');
    const [website, setWebsite] = useState('');
    const [username, setUsername] = useState('');
    const [directNFTTransfer, setDirectNFTTransfer] = useState(false);
    
    
    // Instance Methods
    const isValidUserName = (
        userName: string
    ): { status: boolean; message: string } => {
        if (userName !== '' && userName.length >= 3) {
            if (userName.match(/^[a-zA-Z0-9_-]{3,20}$/)) {
                return {
                    status: true,
                    message: '',
                };
            }
            return {
                status: true,
                message:
                    'Username can contains only alphanumeric, -, _ and maximum 20 characters',
            };
        }
        return {
            status: true,
            message: 'Minimum 3 characters',
        };
    };

    const setUserNameValidationStatus = (
        validUserName: boolean,
        errorMessage: string
    ): any => {
        setUsernameError(errorMessage);
        setCheckValidUsername(validUserName);
    };

    // API Calls
    const getProfileDetails = (): any => {
        ProfileService.getProfile()
            .then((response: any) => {
                const Response = response.data.data;
                setProfileData(Response);
                setEmail(Response.emailId)
                setName(Response.name)
                setBio(Response.bio)
                setDirectNFTTransfer(Response?.directNFTTransfer || false)
                // setRegion(Response.region)
                const socialData = (JSON.parse(Response?.social))
                setInstagram(socialData?.instagram)
                setTwitter(socialData?.twitter)
                setWebsite(socialData?.website)
                setDiscord(socialData?.discord)
                setRegion(Response.region)
                // setProfileInfo(Response);
            })
            .catch((error) => {
                Utils.handleErrorResponse({ error });
            });
            if (profileInfo)
            {
                setEmail(profileInfo.emailId)
                setName(profileInfo.name)
                setBio(profileInfo.bio)
                // setRegion(Response.region)
                const socialData = (JSON.parse(profileInfo?.social))
                setInstagram(socialData?.instagram)
                setTwitter(socialData?.twitter)
                setWebsite(socialData?.website)
                setDiscord(socialData?.discord)
                setRegion(profileInfo.region)
            }
    };

    const checkUserNameAvailabity = (userName: string, callback: any): void => {
        ProfileService.availableUsername(userName)
            .then((response: any) => {
                const Response = response.data.data;
                if (Response.available === true) {
                    setNewUserName(userName);
                    callback(true);
                } else {
                    callback(false);
                }
            })
            .catch();
    };

    const setUserName = (formValues: any, callback: any): any => {
        ProfileService.updateUsername(formValues)
            .then((response) => {
                Utils.handleSuccessResponse(response);
                callback(true);
            })
            .catch((error) => {
                Utils.handleErrorResponse({ error });
                callback(false);
            });
    };

    const updatePrivacyStatus = (status: boolean): any => {
        ProfileService.updatePrivacyStatus({ status })
            .then((response) => {
                Utils.handleSuccessResponse(response);
            })
            .catch((error) => {
                Utils.handleErrorResponse({ error });
            });
    };

    const updateDirectTransferStatus = (event: any): any => {
        setDirectNFTTransfer(!directNFTTransfer);
        const reqObj = {
            directNFTTransfer: !directNFTTransfer
        }
        ProfileService.updateDirectTransferStatus(reqObj)
            .then((response) => {
                Utils.handleSuccessResponse(response);
                setProfileData({...profileInfo, directNFTTransfer: !directNFTTransfer})
            })
            .catch((error) => {
                Utils.handleErrorResponse({ error });
            });
    }
    // region

    const {
        marketPlaceFilter,
        setFilter,
    }: FilterProps = props;
    const [regiondata, setRegiondata] = useState([])

    // const regionFilterChangeHandler = (event: any): void => {
    //     const regionuser = regiondata.filter(item => item._id === event.target.value)

    //     setFilter({
    //         ...marketPlaceFilter,
    //         [event.target.name]: event.target.value,
    //         'regionName': regionuser[0]?.name
    //     });
    // }
    const getRegions = (): void => {
        CollectionService.getCountries()
            .then((result: any) => {
                const data = result?.data?.data;
                if (data) {
                    setRegiondata(data)

                }
            })
            .catch((error) => {
                Utils.handleErrorResponse({ error });
            })
    }

    // Hooks
    useEffect(() => {
        getProfileDetails();
        getRegions()
    }, [newUserName]);

    // UI Actions
    const userNameChanged = (userName: string): any => {
        const validation = isValidUserName(userName);
        setUsername(userName)
        setUserNameValidationStatus(validation.status, validation.message);
        if (validation.status) {
            checkUserNameAvailabity(userName, (status: boolean) => {
                if (!status) {
                    setUserNameValidationStatus(false, 'Already taken');
                }
            });
        }
    };

    const actionSetUserName = (): void => {
        setLoader(true);
        const userName: HTMLInputElement = document.getElementById(
            'userName'
        ) as HTMLInputElement;

        setUserName({ userName: username }, (status: boolean) => {
            if (status) {
                updateUserName(userName.value.toLocaleLowerCase());
                setShowUsernameForm(false);
                setLoader(false);
                setOldUserName('');
                setNewUserName('');
            } else {
                setLoader(false);
            }
        });
    };

    const actionUpdateStatus = (event: any): void => {
        const status = event.target.checked;
        updatePrivateStatus(!status);
        updatePrivacyStatus(!status);
    };

    // update profile api

    const editProfile = (): void => {
        /* eslint object-shorthand: [2, "consistent"] */
        /* eslint-env es6 */
        const data = {
            twitter: twitter,
            instagram: instagram,
            discord: discord,
            website: website
        }
        const editProfileForm = {
            name: name,
            // emailId:email,
            region: region,
            bio: bio,
            directNFTTransfer: directNFTTransfer.toString(),
            social: JSON.stringify(data)
        };
        ProfileService.editProfile(editProfileForm)
            .then((response) => {
                Utils.handleSuccessResponse(response);
            })
            .catch((error) => {
                Utils.handleErrorResponse({ error });
            })

    }

    return (
        <Box className="profile-content">
            <Box className="card-header">
                <Grid container direction="row" spacing={0} className='view-profile'>
                    <Typography className="title profile-details-top-text" variant="h3">
                        Profile Settings
                    </Typography>
                </Grid>
            </Box>
            <Box className="profile-card profile-card-box-wrapper">
                <Grid container spacing={1} className='profile-div-box'>
                    <Grid item sm={12} xs={12} lg={6} md={6} className='profile-page-wrapper'>
                        <Box className='profile-setusername'>
                            <Typography variant="body1" className='username-title'>Username</Typography>
                            <Box className='username-field-box'>
                                <TextField
                                    id="userName"
                                    className='profile-userName-input-field'
                                    margin="normal"
                                    onKeyUp={(e: any) => {
                                        userNameChanged(e.target.value);
                                    }}
                                    placeholder="Enter username"
                                    color="secondary"
                                    variant="outlined"
                                    error={checkValidUsername}
                                    helperText={usernameError}
                                    defaultValue={profileInfo.userName}
                                />
                                <AppPrimaryButton
                                    className="ml-2 mt-4 btnContent profile-username-btn profile-username-set-btn"
                                    disabled={
                                        usernameError !== '' ||
                                        loader ||
                                        newUserName === oldUserName
                                    }
                                    onClick={actionSetUserName}
                                    title="SET USERNAME"
                                    loadingText="SETTING"
                                    isLoading={loader}
                                />
                            </Box>
                        </Box>
                        <Box>
                            <div className='profile-name-field'>
                                <Typography variant="body1" className='subtitle'>Name</Typography>
                            </div>
                            <input type='text' placeholder="Enter your name" onChange={(e) => setName(e.target.value)} value={name} className='details profile-name-field-input' />
                        </Box>

                        <Box>
                            <div className='profile-email-field'>
                                <Typography variant="body1" className='subtitle'>Email Address</Typography>
                            </div>
                            <input type='email' placeholder="Enter email" disabled onChange={(e) => setEmail(e.target.value)} value={email} className='details profile-email-field-input' />
                        </Box>

                        <div>
                            <div className='profile-email-field'>
                                <Typography variant="body1" className='subtitle'>Region</Typography>
                            </div>
                            <Box className='region-form region-form-select-input'>
                                <Box className="region-form-inner">
                                    <select
                                        name="region"
                                        className='region-form-control'
                                        onChange={(e) => { setRegion(e.target.value) }}
                                        value={region}
                                    >
                                        <option value="" selected>Choose...</option>
                                        {regiondata !== undefined && regiondata && regiondata.length > 0 && regiondata.map((regions, key) => <option value={regions._id} key={key}>{regions.name}</option>)}

                                    </select>
                                </Box>
                            </Box>
                        </div>
                        <Box>
                            <div className='profile-bio-field'>
                                <Typography variant="body1" className='subtitle'>Bio</Typography>
                            </div>
                            <textarea placeholder="Tell your story !" onChange={(e) => setBio(e.target.value)} value={bio} className='details profile-bio-field-input' cols={5} />
                        </Box>
                    </Grid>

                    <Grid item sm={12} xs={12} lg={6} md={6} className='profile-social-connection'>
                        <Box className='social-connection-wrapper-box'>
                            <Typography className='social-text'>Social Connections</Typography>
                            <Box className='social-box'>
                                <img src="/images/social-img-discord.svg" alt="" />
                                <Box className='text-input-box'>
                                    <input type="text" value={discord} onChange={(e) => { setDiscord(e.target.value) }} placeholder='Discord' />
                                </Box>
                            </Box>

                            <Box className='social-box'>
                                <img src="/images/social-img-insta.svg" alt="" />
                                <Box className='text-input-box'>
                                    <input type="text" value={instagram} onChange={(e) => { setInstagram(e.target.value) }} placeholder='Instagram' />
                                </Box>
                            </Box>

                            <Box className='social-box'>
                                <img src="/images/social-img-twitter.svg" alt="" />
                                <Box className='text-input-box'>
                                    <input type="text" placeholder='Twitter' value={twitter} onChange={(e) => { setTwitter(e.target.value) }} />
                                </Box>
                            </Box>

                            <Box className='social-box'>
                                <img src="/images/social-img-earth.svg" alt="" />
                                <Box className='text-input-box'>
                                    <input type="text" placeholder='Website' value={website} onChange={(e) => { setWebsite(e.target.value) }} />
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <Grid item className='profile-name-field'>
                                <Typography variant='body1' className='subtitle'>Direct Artwork Transfer</Typography>
                            </Grid>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={directNFTTransfer}
                                            onChange={updateDirectTransferStatus}
                                            color="primary"
                                            className='details'
                                        />
                                    }
                                    label={ `${directNFTTransfer ? 'On' : 'Off'}` }
                                />
                             <Typography className="secondary-text"> If it's ON then to receive the purchased artworks at your wallet address you must have to set wallet address from <Typography  onClick={() => actionOpenSetting(14) } className="setting-link">Account Settings</Typography> </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Button className='social-submit-btn' onClick={editProfile}>Submit now</Button>
            </Box>
            {showEditModal && (
                <EditProfileForm
                    {...props}
                    openProfileModal={showEditModal}
                    onCloseProfileModal={() => setShowEditModal(false)}
                />
            )}
        </Box>
    );
};

const mapStateToProps = (state: AppState): any => ({
    profileInfo: state.profileInfo,
});

const mapDispatchToProps = (dispatch: any): any => ({
    setProfileData: (data: any) =>
        dispatch(ProfileActions.setProfileData(data)),
    updatePrivateStatus: (data: any) =>
        dispatch(ProfileActions.updatePrivateStatus(data)),
    updateUserName: (data: any) =>
        dispatch(ProfileActions.updateUserName(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewProfile);
