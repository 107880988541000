import { useEffect, useRef, useState, ReactElement, Suspense, lazy } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import {
    Box,
    Container,
    Grid,
    IconButton,
    Avatar,
    Typography,
    List,
    ListItem,
    ListItemText,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    // CardHeader,
    // Card,
    // CardContent,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
// import PanoramaIcon from '@material-ui/icons/Panorama';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { AppState } from '../../state/RootReducer';
import { ProfileActions } from '../../state/profile/Actions';
import {
    EmptyPlaceholder,
    HeadTag,
    ImageCropper,
    UserInfo,
} from '../../shared/components';
import { Utils, Toast } from '../../shared/helpers';
import { FollowListSkeleton } from '../../shared/skeletons';
import {
    BuySellService,
    ProfileService,
    DefaultPagination,
    CollectionService,
} from '../../services';
import ProfilePage from './ProfilePage';
import CreationsPage from './CreationsPage';
import FinancialTransactionPage from './FinancialTransactionPage';
import WalletPage from './WalletPage';
import MyOffersPage from './MyOffersPage';
import ImportNFTPage from './ImportNFTPage';
import ExportHistoryPage from './ExportHistoryPage';
import ChangePasswordPage from './ChangePasswordPage';
import WithdrawRequestPage from './WithdrawRequestPage';
import NFTRequestPage from './NFTRequestPage';
import MyNFTsPage from './MyNFTsPage';
import PaymentTransactionPage from './PaymentTransactionPage';

// import MyLandsPage from './MyLandsPage'
import { MyFavoritePage } from '.';
import { OffersInfo } from './components/MyOffers';
import AccountSettingPage from './AccountSettingPage';
// import { AppPrimaryButton } from '../../shared/components/AppComponents';
import WithDrawAmount from './components/WithDrawAmount';
// import { AppPrimaryButton } from '../../shared/components/AppComponents';

const MiniWalletSection = lazy(() => import('./components/MiniWalletSection'))

export interface Balance {
    balance: string;
    token: string;
}

export interface Wallet {
    walletName: string;
    address: string;
    balances: Balance[];
}

export interface FiatWallet {
    address: string;
    freeBalance: number;
    lockedBalance: number;
    totalBalance: number;
    currencyType: string;
}

const ProfileWrapper = (props: any): ReactElement => {
    const title = 'Profile';
    const { profileInfo, setProfileData } = props;
    const location = useLocation();
    const [menuIndex, setMenuIndex] = useState(1);
    const [, setCryptoWallets] = useState<Wallet[]>([]);
    const [, setFiatWallet] = useState<FiatWallet>({} as FiatWallet);
    const profileImageInput = useRef<HTMLInputElement>(null);
    // const coverImageInput = useRef<HTMLInputElement>(null);
    const [open, setOpen] = useState(false);
    const scroll = 'paper';
    const [followType, setFollowType] = useState('');
    const descriptionElementRef = useRef<HTMLElement>(null);
    const [followerList, setFollowerList] = useState([]);
    const [followingList, setFollowingList] = useState([]);
    const [followLoader, setFollowLoader] = useState(false);
    const [, setLoadingWallet] = useState(false);
    const [pagination, setPagination] = useState({
        ...DefaultPagination(3),
    });
    const [openWithdrawModal, setOpenWithdrawModal] = useState(false);
    const [withDrawType] = useState('fiat');
    const [hasMoreList, setHasMoreList] = useState(false);
    const [showCropper, setShowCropper] = useState(false);
    const [imageToCrop, setImageToCrop] = useState(undefined);
    const [showImageUpload, setShowImageUpload] = useState(false);
    const [fileIdentifier, setFileIdentifier] = useState('');
    const [cropRatio, setCropRatio] = useState(1);

    // myOffers

    const [, setOffersList] = useState<OffersInfo[]>([]);
    const [, setMyFavorites] = useState(0);
    const [myNFTSrc, setMyNFTSrc] = useState([])
    // const [totalNFTs, setTotalNFTs] = useState()
    // const [fadeClass] = useState("")
    // const [hideInterestDiv] = useState("NONE")
    // const [showMsgDiv] = useState("BLOCK")

    // API Calls
    const getProfileDetails = (): any => {
        ProfileService.getProfile()
            .then((response: any) => {
                const Response = response.data.data;
                setProfileData(Response);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
            });
    };

    // MyOffers API Call
    const loadOffers = (): void => {
        ProfileService.offersList(pagination.limit, pagination.offset)
            .then((response: any) => {
                const offers = response.data.data.offersInfo;
                setOffersList(offers);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({
                    error,
                });
            });
    };

    const loadFavorites = (): void => {
        ProfileService.favouriteList(pagination.limit, pagination.offset).then(
            (response: any) => {
                const { data } = response.data;
                setMyFavorites(data.pagination.total);
            }
        );
    };

    const loadMyNFTs = (): void => {
        CollectionService.getOwnedItems(pagination.limit, pagination.offset)
            .then((response: any) => {
                const { data } = response.data;
                const res = data.items[0].thumbnail
                setMyNFTSrc(myNFTSrc.concat(res))
                // setTotalNFTs(data.pagination.total)
            })
            .catch((error: any) => {
                // setLoadingItems(false);
                Utils.handleErrorResponse({ error });
            });
    };

    const shortenName = (name: string): string => {
        if (name && name.length > 15) {
            return `${name.substring(0, 15)}...`;
        }
        return name;
    };

    const getFollowers = (): void => {
        if (pagination.lastOffset !== pagination.offset) {
            pagination.lastOffset = pagination.offset;
            setFollowLoader(true);
            ProfileService.getFollowers(pagination.limit, pagination.offset)
                .then((result: any) => {
                    const res = result.data.data.followers;
                    const paginationResponse = result.data.data.pagination;
                    setHasMoreList(Utils.hasMoreRecords(paginationResponse));
                    setFollowerList(followerList.concat(res));
                    setPagination({
                        ...paginationResponse,
                        lastOffset: pagination.lastOffset,
                        offset: pagination.offset + 1,
                    });
                    setFollowLoader(false);
                })
                .catch((error: any) => {
                    Utils.handleErrorResponse({ error });
                    setFollowLoader(false);
                });
        }
    };

    const getFollowings = (): any => {
        if (pagination.lastOffset !== pagination.offset) {
            pagination.lastOffset = pagination.offset;
            setFollowLoader(true);
            ProfileService.getFollowings(pagination.limit, pagination.offset)
                .then((response: any) => {
                    const Response = response.data.data.following;
                    const paginationResponse = response.data.data.pagination;
                    setHasMoreList(Utils.hasMoreRecords(paginationResponse));
                    setFollowingList(followingList.concat(Response));
                    setPagination({
                        ...paginationResponse,
                        lastOffset: pagination.lastOffset,
                        offset: pagination.offset + 1,
                    });
                    setFollowLoader(false);
                })
                .catch((error: any) => {
                    Utils.handleErrorResponse({ error });
                    setFollowLoader(false);
                });
        }
    };

    // Hooks
    useEffect(() => {
        if (location.state !== null) {
            const obj:any = location.state;

            if(obj.payment){
                setMenuIndex(15);
            }
            if (obj.offer) {
                setMenuIndex(5);
            }
            else if (location?.state) {
                setMenuIndex(12);
            }
        }
        // getProfileDetails();
        // BuySellService.loadCryptoPaymentAvailability();
        loadOffers();
        loadFavorites();
        loadMyNFTs();
    }, [location.state]);

    useEffect(() => {
        // Hide cropper modal when navigating back

        setShowCropper(false);
    }, [location.pathname]);

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const loadMoreList = (): any => {
        if (followType === 'followings') {
            getFollowings();
        } else if (followType === 'followers') {
            getFollowers();
        }
    };

    const showEmptyFollowList = (): any => {
        if (followType === 'followings') {
            return followLoader === false && followingList.length === 0;
        }
        if (followType === 'followers') {
            return followLoader === false && followerList.length === 0;
        }
        return followLoader === false && followType !== '';
    };

    const fileUpload = (data: any, type: string): any => {
        if (type === 'profile') {
            setShowImageUpload(true);
            ProfileService.updateProfilePic(data)
                .then(() => {
                    setShowImageUpload(false);
                    setShowCropper(false);
                    getProfileDetails();
                    toast.success('Profile picture uploaded successfully!');
                })
                .catch((error) => {
                    setShowCropper(false);
                    setShowImageUpload(false);
                    Utils.handleErrorResponse({ error });
                });
        } else if (type === 'bg') {
            setShowImageUpload(true);
            ProfileService.updateProfileBgPic(data)
                .then(() => {
                    setShowImageUpload(false);
                    setShowCropper(false);
                    getProfileDetails();
                    toast.success('Cover picture uploaded successfully!');
                })
                .catch((error) => {
                    setShowCropper(false);
                    setShowImageUpload(false);
                    Utils.handleErrorResponse({ error });
                });
        }
    };

    // UI Actions
    const actionFileUpload = (event: any, type: string): any => {
        const file = event.target.files[0];
        if (file) {
            if (
                file.type === 'image/png' ||
                file.type === 'image/jpeg' ||
                file.type === 'image/jpg'
            ) {
                const size = file.size / 1024 / 1024;
                if (size <= 4) {
                    setImageToCrop(file);
                    setShowCropper(true);
                    setFileIdentifier(type);
                    setCropRatio(type === 'bg' ? 4 / 1 : 1);
                } else {
                    Toast.showInfoMessage('Allowed file is 4MB');
                }
            } else {
                toast.error('Allowed files are .jpeg, .png, and .jpg');
            }
        }
    };

    const loadWalletBalance = (): void => {
        setLoadingWallet(true);
        ProfileService.walletBalance()
            .then((response) => {
                const wallet = response.data.data;
                setCryptoWallets(wallet.cryptoWallet);
                setFiatWallet(wallet.fiatWallet);
                setLoadingWallet(false);
            })
            .catch((error) => {
                Utils.handleErrorResponse(error);
                setLoadingWallet(false);
            });
    };

    useEffect(() => {
        // loadWalletBalance();
    }, []);

    const onWithDrawCancel = (): void => setOpenWithdrawModal(false);

    const onWithDrawSuccess = (): void => {
        setOpenWithdrawModal(false);
        loadWalletBalance();
    };

    // Action - dialog
    const openFollowDialog =
        (type: string): any =>
            () => {
                setFollowType(type);
                if (type === 'followings') {
                    getFollowings();
                } else if (type === 'followers') {
                    getFollowers();
                }
                setOpen(true);
            };

    const paginationReset = (): void => {
        pagination.offset = 1;
        pagination.lastOffset = 0;
        pagination.total = 0;
    };

    const handleClose = (): any => {
        setOpen(false);
        setFollowType('');
        setFollowerList([]);
        setFollowingList([]);
        setHasMoreList(false);
        paginationReset();
    };

    // Child Actions
    const onCropperClose = (): void => {
        setShowCropper(false);
        setImageToCrop(undefined);
    };

    const onCropFinished = (
        croppedInfo: any,
        fileDocIdentifier: string
    ): any => {
        const data = new FormData();
        data.append('picture', croppedInfo.croppedFile);
        fileUpload(data, fileDocIdentifier);
        setImageToCrop(undefined);
    };

    // UI Elements
    const Menu = ({
        index,
        menuTitle,
    }: {
        index: number;
        menuTitle: string;
    }): ReactElement => (
        <Typography
            variant="body1"
            className={
                index === menuIndex ? 'nft-menu active-menu' : 'nft-menu'
            }
            onClick={() => {
                setMenuIndex(index);
            }}
        >
            {menuTitle}
        </Typography>
    );

    const actionAddAddress = (index: any): any => {
        setMenuIndex(index);
        setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
        }, 600);
    }

    const actionOpenKyc = (index: any): any => {
        setMenuIndex(index);
        setTimeout(() => {
            window.scrollTo(0, 1500)
        }, 600);
    }

    const actionOpenSetting = (index: any): any => {
        setMenuIndex(index);
        setTimeout(() => {
            window.scrollTo(0, 300)
        }, 600);
    }

    // const walletIntrestUpdate = (walletIntrested: boolean): any => {
    //     ProfileService.updateWalletIntrest({ walletIntrested })
    //         .then((response) => {
    //             Utils.handleSuccessResponse(response);
    //             getProfileDetails();
    //         })
    //         .catch((error) => {
    //             Utils.handleErrorResponse({ error });
    //         });
    // };

    // const actionwalletIntrest = (event: any): void => {
    //     const walletIntrested = event;
    //     walletIntrestUpdate(walletIntrested);
    // };

    // UI Elements
    return (
        <Box className="profile-wrapper" mb={2}>
            <HeadTag title={title} />
            <ImageCropper
                fileIdentifier={fileIdentifier}
                cropRatio={cropRatio}
                showCropper={showCropper}
                inputFile={imageToCrop}
                onCropperClose={onCropperClose}
                onCropFinished={onCropFinished}
                showImageUpload={showImageUpload}
            />

            <Dialog
                maxWidth="xs"
                fullWidth
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle>
                    <Box className="title-section">
                        <Typography align="center" variant="h4">
                            {Utils.titleCase(followType)}
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon color="primary" />
                        </IconButton>
                    </Box>
                    <Divider />
                </DialogTitle>

                <DialogContent>
                    <InfiniteScroll
                        threshold={0}
                        pageStart={0}
                        loadMore={loadMoreList}
                        hasMore={hasMoreList}
                        useWindow={false}
                    >
                        <DialogContentText
                            id="scroll-dialog-description"
                            ref={descriptionElementRef}
                            tabIndex={-1}
                        >
                            {showEmptyFollowList() && (
                                <EmptyPlaceholder
                                    message={`No ${followType} found...`}
                                    icon={<></>}
                                />
                            )}

                            <List className="follow-list">
                                {followLoader === true && (
                                    <FollowListSkeleton />
                                )}
                                {followType === 'followers' &&
                                    followerList.length > 0 &&
                                    followLoader === false &&
                                    followerList.map((follower: any) => (
                                        <ListItem key={follower.id}>
                                            <UserInfo
                                                id={follower.id}
                                                name={follower.name}
                                                imgUrl={follower.profilePicture}
                                                address={follower.address}
                                                blueTick={follower.blueTick}
                                                isPrivate={follower.isPrivate}
                                            />
                                        </ListItem>
                                    ))}

                                {followType === 'followings' &&
                                    followingList.length > 0 &&
                                    followLoader === false &&
                                    followingList.map((following: any) => (
                                        <ListItem key={following.id}>
                                            <UserInfo
                                                id={following.id}
                                                name={following.name}
                                                imgUrl={
                                                    following.profilePicture
                                                }
                                                address={following.address}
                                                blueTick={following.blueTick}
                                                isPrivate={following.isPrivate}
                                            />
                                        </ListItem>
                                    ))}
                            </List>
                        </DialogContentText>
                    </InfiniteScroll>
                </DialogContent>
            </Dialog>

            {/* <Grid container direction="row" spacing={0}>
                <Grid item xs={12} id="top-panel">
                    <Avatar
                        className="user-bg"
                        variant="square"
                        src={profileInfo.backgroundPicture}
                    >
                        <PanoramaIcon style={{ fontSize: '100px' }} />
                    </Avatar>
                    <input
                        ref={coverImageInput}
                        type="file"
                        accept="image/png,image/jpeg,image/jpg"
                        style={{ display: 'none' }}
                        id="user-bg-upload"
                        onChange={(e) => actionFileUpload(e, 'bg')}
                    />

                    <IconButton
                        className="cover-trigger"
                        onClick={() => {
                            coverImageInput!.current!.value = '';
                            coverImageInput!.current!.click();
                        }}
                    >
                        <img src="/icons/icon_camera.png" />
                    </IconButton>
                </Grid>
            </Grid> */}
            {/* <Container>/ */}
                <Grid container direction="row" className="spacing-minus">
                    <Grid
                        item
                        lg={3}
                        md={3}
                        sm={12}
                        xs={12}
                        style={{ display: 'none' }}
                    >
                        <Box className="left-panel">
                            <Box className="left-panel-top-menu" >
                                <Box mt={2} className="profile-pic-container">
                                    <input
                                        ref={profileImageInput}
                                        type="file"
                                        accept=".jpg,.jpeg,.png"
                                        style={{ display: 'none' }}
                                        id="profile-upload"
                                        onChange={(e) =>
                                            actionFileUpload(e, 'profile')
                                        }
                                    />

                                    <Box className="profile-pic">
                                        <Avatar className='profile-pic-icon'
                                            src={profileInfo.profilePicture}
                                        >
                                            {Utils.getInitial(profileInfo?.name)}
                                        </Avatar>

                                        <IconButton
                                            className="profile-trigger"
                                            onClick={() => {
                                                profileImageInput!.current!.value =
                                                    '';
                                                profileImageInput!.current!.click();
                                            }}
                                        >
                                            <img src="/icons/icon_camera.png" />
                                        </IconButton>
                                    </Box>
                                    <Box mt={1} className="center-content">
                                        <Typography
                                            variant="body2"
                                            className="break-text"
                                        >
                                            {profileInfo.userName}
                                        </Typography>
                                    </Box>

                                    <Box
                                        mt={3}
                                        className="space-content"
                                        onClick={openFollowDialog('followers')}
                                    >
                                        <Typography
                                            variant="body1"
                                            className="cursor"
                                        >
                                            Followers
                                        </Typography>

                                        <Typography variant="body1">
                                            {profileInfo.followers}
                                        </Typography>
                                    </Box>

                                    <Box
                                        mt={2}
                                        mb={6}
                                        className="space-content"
                                        onClick={openFollowDialog('followings')}
                                    >
                                        <Typography
                                            variant="body1"
                                            className="cursor"
                                        >
                                            Following
                                        </Typography>

                                        <Typography variant="body1">
                                            {profileInfo.following}
                                        </Typography>
                                    </Box>
                                    <Box mt={2} className="center-content">
                                        <Typography
                                            variant="body1"
                                            className="f18 break-text"
                                        >
                                            {profileInfo?.name}
                                        </Typography>
                                        {profileInfo.blueTick && (
                                            <span className="ml-5 flex">
                                                <img
                                                    className="icon-verified"
                                                    src="/icons/icon_verified.png"
                                                    alt="verify_icon"
                                                />
                                            </span>
                                        )}
                                    </Box>
                                    {/*  */}
                                </Box>
                            </Box>

                            <Divider />
                            <Suspense fallback={<div>Loading...</div>}>
                               {/* <MiniWalletSection actionOpenSetting={actionOpenSetting} /> */}
                            </Suspense>
                            <Divider />

                            <List component="nav" className="profile-menus">
                                <ListItem
                                    button
                                    component={NavLink}
                                    activeClassName="active-link"
                                    to="owned-nfts"
                                >
                                    <ListItemText primary="My collection" />
                                </ListItem>

                                <ListItem
                                    button
                                    component={NavLink}
                                    activeClassName="active-link"
                                    to="creations"
                                >
                                    <ListItemText primary="My Collections" />
                                </ListItem>

                                <ListItem
                                    button
                                    component={NavLink}
                                    activeClassName="active-link"
                                    to="financial-transactions"
                                >
                                    <ListItemText primary="Wallet History" />
                                </ListItem>

                                <ListItem
                                    button
                                    component={NavLink}
                                    activeClassName="active-link"
                                    to="wallet"
                                >
                                    <ListItemText primary="My Wallet" />
                                </ListItem>

                                <ListItem
                                    button
                                    component={NavLink}
                                    activeClassName="active-link"
                                    to="offers"
                                >
                                    <ListItemText primary="My Offers" />
                                </ListItem>

                                <ListItem
                                    button
                                    to="view"
                                    component={NavLink}
                                    activeClassName="active-link"
                                >
                                    <ListItemText primary="Profile Settings" />
                                </ListItem>

                                <ListItem
                                    button
                                    component={NavLink}
                                    activeClassName="active-link"
                                    to="import-nft"
                                >
                                    <ListItemText primary="Import NFT" />
                                </ListItem>

                                <ListItem
                                    button
                                    component={NavLink}
                                    activeClassName="active-link"
                                    to="export-history"
                                >
                                    <ListItemText primary="Export History" />
                                </ListItem>
                                {profileInfo.registrationType === 'Email' && (
                                    <ListItem
                                        button
                                        component={NavLink}
                                        activeClassName="active-link"
                                        to="change-password"
                                    >
                                        <ListItemText primary="Change Password" />
                                    </ListItem>
                                )}
                                <ListItem
                                    button
                                    to="withdraw-requests"
                                    component={NavLink}
                                    activeClassName="active-link"
                                >
                                    <ListItemText primary="Withdrawal Requests" />
                                </ListItem>

                                <ListItem
                                    button
                                    to="nft-request"
                                    component={NavLink}
                                    activeClassName="active-link"
                                >
                                    <ListItemText primary="My Physical NFTs" />
                                </ListItem>
                                <ListItem
                                    button
                                    to="favorites"
                                    component={NavLink}
                                    activeClassName="active-link"
                                >
                                    <ListItemText primary="My Favorites" />
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                    <Grid container spacing={4} className='left-panel-main'>
                        <Grid item xs={12} sm={6} md={6} xl={6} className="profile-img-name-tag">
                            <Box>
                                <Box className="left-panel left-panel-top-menu">
                                    <Box className="profile-pic-container">
                                        <input
                                            ref={profileImageInput}
                                            type="file"
                                            accept=".jpg,.jpeg,.png"
                                            style={{ display: 'none' }}
                                            id="profile-upload"
                                            onChange={(e) =>
                                                actionFileUpload(e, 'profile')
                                            }
                                        />

                                        <Box className="profile-pic">
                                            <Avatar
                                                src={profileInfo.profilePicture}
                                            >
                                                {Utils.getInitial(profileInfo.name)}
                                            </Avatar>

                                            <IconButton
                                                className="profile-trigger"
                                                onClick={() => {
                                                    profileImageInput!.current!.value =
                                                        '';
                                                    profileImageInput!.current!.click();
                                                }}
                                            >
                                                <img src="/icons/icon_camera.png" />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="profile-name-tag">
                                <Box mt={6} className="profile-name-tag-text">
                                    <Typography
                                        variant="body1"
                                        className="f18 break-text profilename"
                                    >
                                        {profileInfo?.name}
                                        {/* {shortenName(profileInfo?.name)} */}
                                    </Typography>
                                    {profileInfo.blueTick && (
                                        <span className="ml-5 flex">
                                            <img
                                                className="icon-verified"
                                                src="/icons/icon_verified.png"
                                                alt="verify_icon"
                                            />
                                        </span>
                                    )}
                                    {/* <Box my={3} className="flex">
                                            <Box
                                                pr={3}
                                                className="space-content"
                                                display="flex"
                                                onClick={openFollowDialog(
                                                    'followers'
                                                )}
                                            >
                                                <Typography
                                                        variant="body1"
                                                        className="cursor"
                                                        style={{ fontWeight: 600 }}
                                                    >
                                                        {profileInfo.followers}{' '}
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    className="cursor"
                                                    style={{ margin: 'auto 4%' }}
                                                >
                                                    Followers
                                                </Typography>

                                                <Typography variant="body1">
                                                    {profileInfo.followers}
                                                </Typography>
                                            </Box>

                                            <Box
                                                mt={2}
                                                mb={6}
                                                className="space-content"
                                                display='flex'
                                                onClick={openFollowDialog(
                                                    'followings'
                                                )}
                                            >
                                                <Typography
                                                        variant="body1"
                                                        className="cursor"
                                                        style={{ fontWeight: 600,display: 'flex' }}
                                                    >
                                                    
                                                    <Typography style={{margin: 'auto 5px', fontSize: '25px'}}> ⋅ </Typography>
                                                    {profileInfo.following}{' '}
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    className="cursor"
                                                    style={{ margin: 'auto 4%' }}
                                                >
                                                    
                                                    Following
                                                </Typography>

                                                <Typography variant="body1">
                                                    {profileInfo.following}
                                                </Typography>
                                            </Box>
                                        </Box> */}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            {/* <Box mt={6} className="profile-re-wallet">
                                <Box className='tus-wallet'>
                                    <Typography variant="h4">
                                    TUS Wallet
                                    </Typography>
                                    <Typography variant="body1">
                                        0.0000 ETH
                                    </Typography>
                                    <AppPrimaryButton
                                        title="Withdraw"
                                        className=" primary-btn btnContent wallet-btn"
                                        onClick={() => {
                                            setOpenWithdrawModal(true);
                                            setWithDrawType('fiat');
                                        }}
                                    />
                                </Box>

                                <Box className='tus-wallet'>
                                    <Typography variant="h4">
                                        Fiat Wallet
                                    </Typography>
                                    <Typography variant="body1">
                                        0.0000 USD
                                    </Typography>
                                    <AppPrimaryButton
                                        title="Withdraw"
                                        className=" primary-btn btnContent wallet-btn"
                                        onClick={() => {
                                            setOpenWithdrawModal(true);
                                            setWithDrawType('fiat');
                                        }}
                                    />
                                </Box>

                            </Box> */}

                            <Suspense fallback={<div>Loading...</div>}>
                                <MiniWalletSection actionOpenSetting={actionOpenSetting} />
                            </Suspense>
                            {/* <Box mt={6} className="profile-re-wallet">
                                <Box className='tus-wallet'>
                                    <Typography variant="h4">
                                        tus wallet
                                    </Typography>
                                    <Typography variant="body1">
                                        0.0000 ETH
                                    </Typography>
                                    <AppPrimaryButton
                                        title="Withdraw"
                                        className=" primary-btn btnContent wallet-btn"
                                   
                                    />
                                </Box>

                                <Box className='tus-wallet'>
                                    <Typography variant="h4">
                                        tus wallet
                                    </Typography>
                                    <Typography variant="body1">
                                        0.0000 ETH
                                    </Typography>
                                    <AppPrimaryButton
                                        title="Withdraw"
                                        className=" primary-btn btnContent wallet-btn"
                                    
                                    />
                                </Box>

                            </Box> */}
                        </Grid>
                        {/* WithDraw Form */}
                        {/* <Dialog
                            maxWidth="sm"
                            fullWidth
                            open={openWithdrawModal}
                            className="withdraw-container"
                        >
                            <DialogTitle>
                                <Box className="title-section">
                                    <Typography variant="h4">Withdraw</Typography>
                                    <IconButton onClick={onWithDrawCancel}>
                                        <CloseIcon/>
                                    </IconButton>
                                </Box>
                                <Divider />
                            </DialogTitle>

                            <DialogContent>
                                <WithDrawAmount
                                    profileInfo={profileInfo}
                                    withDrawType={withDrawType}
                                    onWithDrawCancel={onWithDrawCancel}
                                    onWithDrawSuccess={onWithDrawSuccess}
                                    actionOpenSetting={actionOpenSetting}
                                />
                            </DialogContent>
                        </Dialog> */}

                    </Grid>
                    {/* {profileInfo.walletIntrested && profileInfo.walletIntrested ? (null) : (<Grid container spacing={4} className='' id='interested-div'> */}
                        {/* <Grid item xs={12} sm={12} md={8} className='main'>
                            <Box mt={2} className="interested justify-center" id="wallet-interest"
                                style={{ display: (showMsgDiv === 'NONE') ? 'none' : 'block' }}>
                                <Grid container direction="row"
                                    className={(fadeClass === "FADE-OUT") ? "animated fadeOut" : " "}>
                                    <Grid item xs={12} sm={6} md={1} className='icons iconsvg-div'>
                                        <img src="/icons/Icon-Wallet.svg" className='icon-svg' alt="wallet" />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={7} className='text'>
                                        <Box>
                                            <Typography variant='body2' id='textsmall'>
                                                Fantico wallet is going to be one for all!
                                            </Typography>
                                            <Typography variant='body2'>
                                                Show us your interest
                                                <IconButton>
                                                    <img src="/icons/Arrow-purple.svg" />
                                                </IconButton>
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3} className='button'>
                                        <AppPrimaryButton
                                            title="Yes, I am interested!"
                                            className='verify-btn interested-wallet'
                                            // onClick={setFadeClass('animated fadeOut')}
                                            onClick={() => {
                                                actionwalletIntrest(true)
                                                // setFadeClass("FADE-OUT");
                                                // setTimeout(() => {
                                                //     setHideInterestDiv('BLOCK');
                                                //     setShowMsgDiv('NONE');
                                                // }, 2000); 
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box mt={2} className="interested justify-center" id='thanks-div'
                                style={{ display: (hideInterestDiv === 'BLOCK') ? 'block' : 'none' }}
                            >
                                <Grid container direction="row">
                                    <Grid item xs={12} sm={6} md={1} className='icons iconsvg-div'>
                                        <img src="/icons/Icon-Thumbs up.svg" className='icon-svg' alt="thumb" />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={7} className='text'>
                                        <Box className='thank-you'>
                                            <Typography variant='body2' id='textsmall'>
                                                Thank you for showing interest!
                                            </Typography>
                                            <Typography variant='body2'>We will keep you posted.</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid> */}
                    {/* </Grid>)} */}



                    {/* <Grid item lg={9} md={9} sm={12} xs={12}>
                        <Box mt={4}>
                            <Outlet />
                        </Box>
                    </Grid> */}
                </Grid>
            {/* </Container> */}
            <Grid xs={12} className="bg-white content-wrepper">
                <Box mt={8} className="nft-menus nft-profile-box">
                    <Box className='profile-menu-tag'>
                        <img src='/images/profile.svg' />
                        <Menu index={1} menuTitle="Profile Settings" />
                    </Box>
                    <Box className='profile-menu-tag'>
                        <img src='/images/collectionStar.svg' />
                        <Menu index={12} menuTitle="My Collection" />
                    </Box>

                    {/* <Menu index={13} menuTitle="My Lands" /> */}
                    <Box className='profile-menu-tag'>
                        <img src='/images/earning.svg' />
                        <Menu index={4} menuTitle="My Wallet" />
                    </Box>
                     {/* <Box className='profile-menu-tag'>
                        <img src='/images/menu-fav.svg' />
                        <Menu index={11} menuTitle="My Favorites" />
                    </Box> */}

                    <Box className='profile-menu-tag'>
                        <img src='/images/offer.svg' />
                        <Menu index={5} menuTitle="My Offers" />
                    </Box>
                   {/* <Box className='profile-menu-tag'>
                        <img src='/images/colletion.svg' />
                        <Menu index={2} menuTitle="My Collections" />
                    </Box> */}
                    <Box className='profile-menu-tag'>
                        <img src='/images/menu-history.svg' />
                        <Menu index={3} menuTitle="Wallet History" />
                    </Box>
                    <Box className='profile-menu-tag'>
                        <img src='/images/menu-history.svg' />
                        <Menu index={15} menuTitle="Payment History" />
                    </Box>
                    <Box className='profile-menu-tag'>
                        <img src='/images/Percent.svg' />
                        <Menu index={6} menuTitle="Import History" />
                    </Box>
                    <Box className='profile-menu-tag'>
                        <img src='/images/menu-history.svg' />
                        <Menu index={7} menuTitle="Export History" />
                    </Box>
                    <Box className='profile-menu-tag'>
                        <img src='/images/profile.svg' />
                        <Menu index={8} menuTitle="Change Password" />
                    </Box>
                    <Box className='profile-menu-tag'>
                        <img src='/images/payment.svg' />
                        <Menu index={9} menuTitle="Withdraw Request" />
                    </Box>
                    {/* <Box className='profile-menu-tag'>
                        <img src='/images/profile.svg' />
                        <Menu index={10} menuTitle="NFT Request" />
                    </Box> */}
                    <Box className='profile-menu-tag'>
                        <img src='/images/account.svg' />
                        <Menu index={14} menuTitle="Account Settings" />
                    </Box>


                </Box>
                <Box className="nft-more-info" mt={2}>
                    {menuIndex === 1 && <ProfilePage actionOpenSetting={actionOpenSetting} />}
                    {menuIndex === 2 && <CreationsPage actionOpenKyc={actionOpenKyc} />}
                    {menuIndex === 3 && <FinancialTransactionPage />}
                    {menuIndex === 4 && <WalletPage actionOpenSetting={actionOpenSetting} />}
                    {menuIndex === 5 && <MyOffersPage />}
                    {menuIndex === 6 && <ImportNFTPage />}
                    {menuIndex === 7 && <ExportHistoryPage />}
                    {menuIndex === 8 && <ChangePasswordPage />}
                    {menuIndex === 9 && <WithdrawRequestPage />}
                    {menuIndex === 10 && <NFTRequestPage />}
                    {menuIndex === 11 && <MyFavoritePage />}
                    {menuIndex === 12 && (
                        <MyNFTsPage
                            actionOpenKyc={actionOpenKyc}
                            actionAddAddress={actionAddAddress}
                        />
                    )}
                    {menuIndex === 15 && <PaymentTransactionPage />}
                    {/* {menuIndex === 13 && (
                                <MyLandsPage 
                                    actionOpenKyc={actionOpenKyc}
                                    actionAddAddress={actionAddAddress}
                                />
                            )} */}
                    {menuIndex === 14 && <AccountSettingPage />}
                </Box>
            </Grid>
        </Box>
    );
};

const mapDispatchToProps = (dispatch: any): any => ({
    setProfileData: (data: any) =>
        dispatch(ProfileActions.setProfileData(data)),
});

const mapStateToProps = (state: AppState): any => ({
    profileInfo: state.profileInfo,
    acceptCrypto: state.applicationState.acceptCrypto,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileWrapper);
