import { ReactElement } from 'react';
import { Typography, Box, Chip } from '@material-ui/core';
import { connect } from 'react-redux';
import { ActivityActions } from '../../../state/activity/Action';
import { AppState } from '../../../state/RootReducer';

const Filters = (props: any): ReactElement => {
    const { activityFilter, setFilter } = props;

    // UI Elements
    const StatusFilter = ({
        label,
        valueKey,
    }: {
        label: string;
        valueKey: string;
    }): ReactElement => (
        <Chip
            className={activityFilter[valueKey] ? 'chips active-chip' : 'chips'}
            label={label}
            onClick={(): void => {
                setFilter({
                    ...activityFilter,
                    [valueKey]: !activityFilter[valueKey],
                });
            }}
        />
    );

    const isAllFilterSelected = (): boolean => {
        const allSelected =
            !activityFilter.listing &&
            !activityFilter.sales &&
            !activityFilter.transfer &&
            !activityFilter.bids;
        return allSelected;
    };

    return (
        <Box className="activity-filter">
            <Box display="flex" alignItems="center">
                <Typography variant="body1" className="mr-4">
                    Show
                </Typography>
                <Chip
                    className={
                        isAllFilterSelected() ? 'chips active-chip' : 'chips'
                    }
                    label="All"
                    onClick={(): void => {
                        setFilter({
                            listing: false,
                            sales: false,
                            bids: false,
                            transfer: false,
                        });
                    }}
                />
                <StatusFilter valueKey="listing" label="Listings" />
                <StatusFilter valueKey="sales" label="Transfer" />
                <StatusFilter valueKey="bids" label="Bids" />
                <StatusFilter valueKey="transfer" label="Create" />
            </Box>
        </Box>
    );
};

const mapStateToProps = (state: AppState): any => ({
    activityFilter: state.activityFilter,
});

const mapDispatchToProps = (dispatch: any): any => ({
    setFilter: (data: any) => dispatch(ActivityActions.setFilter(data)),
    clearFilter: (data: any) => dispatch(ActivityActions.clearFilter(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
