import { ReactElement, useState } from 'react';

import { Grid, Box, Typography, TextField } from '@material-ui/core';
// import { AppFormikTextField } from './AppComponents'

const FractoCardElements = (props: any): ReactElement => {
    const { onFormChange, cardForm, validateCard } = props;
    // const [formError, setFormError] = useState('');
    const [cardStatus, setCardStatus]: any = useState({
        cardNumber: false,
        cardCvc: false,
        cardExpiry: false,
    });
    // const [errors, setErrors]: any = useState({
    //     cardNumber: '',
    //     cardCvc: '',
    //     cardExpiry: '',
    // });
    return (
        <Grid container spacing={4}>
            <>
                <Grid xs={12} item>
                    <Typography variant="body1" className="mb-2">
                        Card Number
                    </Typography>

                    <Box className="">
                        <TextField
                            error={Boolean(validateCard.cardNumber)}
                            helperText={validateCard.cardNumber ? "Invalid card number" : ""}
                            className="outer-label"
                            placeholder="Card Number *"
                            name="cardNumber"
                            type="text"
                            size="medium"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            value={cardForm.cardNumber}
                            onChange={onFormChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box>
                        <Typography variant="body1" className="mb-2">
                            Expiry
                        </Typography>
                    </Box>
                    <Box className="">
                        <TextField
                            error={Boolean(validateCard.cardExpiry)}
                            helperText={validateCard.cardExpiry ? "Invalid expiry date" : ""}
                            className="outer-label"
                            placeholder="Expiry Date *"
                            name="cardExpiry"
                            type="text"
                            size="medium"
                            variant="outlined"
                            margin="normal"
                            value={cardForm.cardExpiry}
                            onChange={onFormChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box>
                        <Typography variant="body1" className="mb-2">
                            CVV
                        </Typography>
                    </Box>
                    <Box className="">
                        <TextField
                            error={Boolean(validateCard.cardCvc)}
                            helperText={validateCard.cardCvc ? "Invalid cvv" : ""}
                            className="outer-label"
                            placeholder="CVV *"
                            name="cardCvc"
                            type="text"
                            size="medium"
                            variant="outlined"
                            margin="normal"
                            value={cardForm.cardCvc}
                            onChange={onFormChange}
                        />
                    </Box>
                </Grid>
                <Box style={{ fontSize: 'xx-small' }}>
                    powered by{' '}
                    <a href="https://frac.to/" target="_blank" rel="noreferrer">
                        <img
                            src="/images/fracto.svg"
                            style={{ height: '15px' }}
                        />
                    </a>
                </Box>
            </>
        </Grid>
    );
};

export default FractoCardElements;
