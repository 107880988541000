import { ReactElement, useRef, useState, useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { Box, Typography, Grid, Divider } from '@material-ui/core';
import { Formik } from 'formik';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import SignupGoogleForm from './SignupGoogleForm';
import { CreateAccountSchema } from '../../../validators/AuthenticationSchema';
// import DividerWithText from '../../../shared/components/DividerWithText';
import {
    AppPrimaryButton,
    AppFormikTextField,
    AppFormikCheckBox,
    AppFormikPasswordField,
} from '../../../shared/components/AppComponents';
import { Utils } from '../../../shared/helpers';
import { AuthenticationService, CaptchaSetting } from '../../../services';

const SignupForm = (): ReactElement => {
    const navigate = useNavigate();
    const [captcha, setCaptcha] = useState('');
    const captchaRef = useRef(null);
    const signupForm = {
        name: '',
        email: '',
        password: '',
        policy: false,
    };
    const [captchaSetting, setCaptchaSetting] = useState<any>()
    // API Calls
    const getCaptchaSetting = (): void => {
        CaptchaSetting.getCaptchaSetting('register')
            .then((response: any) => {
                if (response.data.data)
                    setCaptchaSetting(response.data.data)
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
            })
    }
    useEffect(() => {
        if (process.env.REACT_APP_DEV_MODE === 'true') {
            // getCaptchaSetting()
        } else {
            setCaptchaSetting({ status: true })
        }
    }, [])
    const signup = (requestObj: any, callback: any): any => {
        AuthenticationService.register(requestObj)
            .then((response: any) => {
                Utils.handleSuccessResponse(response);
                callback(true);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
                callback(false);
            });
    };

    // UI Actions
    const actionSignup = (formValues: any, formik: any): any => {
        const { name, email, password } = formValues;
        const requestObj = {
            name,
            email,
            password,
            captchaToken:captcha ,
            page: 'register',
        };
        signup(requestObj, (status: boolean) => {
            if (status) {
                navigate('/login', { replace: true });
                formik.setSubmitting(false);
            } else {
                setCaptcha('');
                if (captchaRef !== null) {
                    const hcaptcha: any = captchaRef.current;
                    if (hcaptcha instanceof HCaptcha) {
                        hcaptcha.resetCaptcha();
                    }
                }
                formik.setSubmitting(false);
            }
        });
    };

    // Captcha events
    const onVerifyCaptcha = (token: any): any => {
        if (token) {
            setCaptcha(token);
        } else {
            setCaptcha('');
        }
    };

    const onExpireCaptcha = (): any => {
        setCaptcha('');
    };

    // UI Elements
    return (
        <>
            <Box className="auth-bg">
                <Box className="auth-content auth-content-verify auth-content-verify-signup">
                    <Grid item xs={12}>
                        <Formik
                            initialValues={signupForm}
                            enableReinitialize
                            validationSchema={CreateAccountSchema}
                            validateOnMount
                            onSubmit={(values, formik): any => {
                                actionSignup(values, formik);
                            }}
                        >
                            {({ handleSubmit, isSubmitting, isValid }): any => (
                                <form
                                    autoComplete="off"
                                    onSubmit={handleSubmit}
                                >
                                    <Box className="title-content">
                                        <Typography
                                            className="auth-main-title signup-h3"
                                            variant="h3"
                                        >
                                            Create an account
                                        </Typography>
                                        <Divider className='auth-divider' />
                                    </Box>

                                    <Box pt={3} className='email-label' id="signup-name">
                                        {/* <InputLabel
                                            required
                                            className="black-medium signup-label"
                                        >
                                            Name
                                        </InputLabel> */}
                                        <AppFormikTextField className="auth-label"
                                            name="name"
                                            type="text"
                                            size="medium"
                                            placeholder="Name*"
                                        />
                                    </Box>

                                    <Box pt={2} className='email-label' id="signup-email">
                                        {/* <InputLabel
                                            required
                                            className="black-medium signup-label"
                                        >
                                            Email
                                        </InputLabel> */}
                                        <AppFormikTextField
                                            name="email"
                                            type="text"
                                            size="medium"
                                            placeholder="Email ID*"
                                        />
                                    </Box>
                                    <Box pt={2} className='email-label' id="signup-pwd">
                                        {/* <InputLabel
                                            required
                                            className="black-medium signup-label"
                                        >
                                            Password
                                        </InputLabel> */}
                                        <AppFormikPasswordField
                                         className="pwd-icon-img"
                                            name="password"
                                            type="password"
                                            size="medium"
                                            placeholder="Password"
                                        />
                                    </Box>

                                    <Box
                                        py={1}
                                        className="signup-privacy-wrapper"
                                        id="tandc"
                                    >
                                        <AppFormikCheckBox
                                            label={
                                                <Box className='signup-text-line'>
                                                    {/* I agree to Fantico's Terms and Condition &
                                                    Privacy Policy */}
                                                    <Typography variant="body1" >
                                                        I agree to
                                                        TUS&apos;s{' '}
                                                        <Typography
                                                            component={NavLink}
                                                            to="/terms"
                                                            target="_blank"
                                                            className="primary"
                                                            variant="body1"
                                                        >
                                                            Terms and Condition
                                                        </Typography>
                                                        <span>{` & `}</span>
                                                        <Typography
                                                            className="primary"
                                                            variant="body1"
                                                            component={NavLink}
                                                            to="/privacy"
                                                            target="_blank"
                                                        >
                                                            Privacy Policy
                                                        </Typography>
                                                    </Typography>
                                                </Box>
                                            }
                                            name="policy"
                                        />
                                    </Box>
                                    {/* {
                                        captchaSetting !== undefined && captchaSetting.status && */}
                                        <Box py={1} className="captcha-sm">
                                            <HCaptcha
                                                sitekey="2b1dc988-7604-4a33-ba53-d705132c81c5"
                                                onVerify={onVerifyCaptcha}
                                                onExpire={onExpireCaptcha}
                                                ref={captchaRef}
                                            />
                                        </Box>
                                     {/* } */}

                                    {/* <Box className='signIn-content' id="signup-btn"> */}
                                    <Box className='signIn-content'>
                                        <AppPrimaryButton
                                            title="Create my account"
                                            isLoading={isSubmitting}
                                            fullWidth
                                            className='signInBtn auth-label'
                                            loadingText="SIGNING UP"
                                            disabled={
                                                isSubmitting || captcha===''||

                                                !isValid 
                                            }
                                            onClick={handleSubmit}
                                        />
                                    </Box>                                    

                                    <Box
                                        component="div"
                                        py={2}
                                        className="center-content otherContent "
                                    >
                                        {/* <Grid spacing={3}> */}
                                        <Typography
                                            component={NavLink}
                                            variant="body1"
                                            to="/login"
                                        >
                                            Already an user?
                                            <span className="primary ml-2 sign-black">
                                                Sign In
                                            </span>
                                        </Typography>

                                        {/* </Grid> */}
                                    </Box>

                                    {/* <DividerWithText /> */}

                                    {/* <Box py={2} id="google-signup">
                                        <SignupGoogleForm />
                                    </Box> */}

                                    <Box
                                        component="div"
                                        id="google-login"
                                        pt={8}
                                    >
                                        {/* <img src="/images/icon _google_.svg"  alt="" className='google-img-icon'/> */}
                                        <SignupGoogleForm />
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

export default SignupForm;
