import { useCallback, useEffect, useState, ReactElement } from 'react';
import { Box, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import Cropper from 'react-easy-crop';
import { AppPrimaryButton, AppTeritaryButton } from './AppComponents';

const ImageCropper = (props: any): ReactElement => {
    const {
        showCropper,
        fileIdentifier,
        inputFile,
        onCropperClose,
        onCropFinished,
        cropRatio,
        showImageUpload,
    } = props;
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedArea, setCroppedArea] = useState(null);
    const [zoom, setZoom] = useState(1);
    const [inputImg, setInputImg] = useState('');

    // Instance Methods
    const readImagePreview = (): void => {
        const url = URL.createObjectURL(inputFile);
        setInputImg(url);
    };

    // Hooks
    useEffect(() => {
        if (inputFile) {
            readImagePreview();
        }
    }, [inputFile]);

    const createImage = (): any =>
        new Promise((resolve, reject) => {
            const image = new Image();
            image.addEventListener('load', () => {
                resolve(image);
            });
            image.addEventListener('error', (error) => {
                reject(error);
            });
            image.setAttribute('crossOrigin', 'anonymous');
            image.src = inputImg;
        });

    const getCroppedImg = async (cropImage: any): Promise<any> => {
        const image: any = await createImage();
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = cropImage.width;
        canvas.height = cropImage.height;
        ctx!.drawImage(
            image!,
            cropImage.x,
            cropImage.y,
            cropImage.width,
            cropImage.height,
            0,
            0,
            canvas.width,
            canvas.height
        );

        return new Promise((resolve) => {
            canvas.toBlob((blob: any) => {
                // eslint-disable-next-line no-param-reassign
                blob.name = inputFile.name;
                resolve({
                    croppedFile: blob,
                    croppedImageUrl: URL.createObjectURL(blob),
                });
            }, 'image/jpeg');
        });
    };

    // Cropper events
    const onCropComplete = async (
        newCroppedArea: any,
        croppedAreaPixel: any
    ): Promise<any> => {
        setCroppedArea(newCroppedArea);
        setCroppedAreaPixels(croppedAreaPixel);
    };

    // UI Actions
    const actionCloseCropper = (): any => {
        onCropperClose();
    };
    const actionFinishCropping = useCallback(async () => {
        const image = await getCroppedImg(croppedAreaPixels);
        onCropFinished(image, fileIdentifier);
    }, [croppedAreaPixels, croppedArea]);

    // UI Elements
    return (
        <>
            <Dialog
                maxWidth="md"
                fullWidth
                open={showCropper}
                onClose={actionCloseCropper}
            >
                <DialogContent>
                    <div
                        style={{
                            position: 'relative',
                            width: '100%',
                            height: '400px',
                        }}
                    >
                        <Cropper
                            image={inputImg!}
                            crop={crop}
                            zoom={zoom}
                            showGrid
                            aspect={cropRatio}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Box p={2}>
                        <AppTeritaryButton
                        className='btn-profile-cancle'
                            title="CANCEL"
                            disabled={showImageUpload}
                            onClick={actionCloseCropper}
                        />

                        <AppPrimaryButton
                            onClick={actionFinishCropping}
                            disabled={showImageUpload}
                            isLoading={showImageUpload}
                            title="FINISH"
                            loadingText="UPLOADING"
                        />
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ImageCropper;
