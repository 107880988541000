import { Box, Grid, Typography } from '@material-ui/core';
import { ReactElement, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import CollectionsIcon from '@material-ui/icons/Collections';
import { HeadTag, EmptyPlaceholder } from '../../../shared/components';
import { Utils } from '../../../shared/helpers';
import { AppState } from '../../../state/RootReducer';
import { DefaultPagination, CollectionService } from '../../../services';

export interface CURATORINFO {
    marketPlaceFilter?: any;
    loaderUi?: any;
    isAPICalling?:any;
}

const CuratorsTab = (props: CURATORINFO): ReactElement => {
    const title = 'Curator Tab';
    const description = "Curator Tab";

    const { marketPlaceFilter, loaderUi, isAPICalling } = props;
    const [curators, setCurators] = useState<CURATORINFO[]>([]);
    const [pagination, setPagination] = useState({ ...DefaultPagination(30) });
    const [hasMoreItems, setHasMoreItems] = useState(false);
    const [loader, setLoader] = useState(false);

    // Api Call
    const loadCurators = (): void => {
        if (pagination.lastOffset !== pagination.offset) {
            pagination.lastOffset = pagination.offset;
            setLoader(true)
            CollectionService.getCurators(
                30,
                pagination.offset,
                marketPlaceFilter.query,
                marketPlaceFilter.region,
                'curator',
                'asc'
            ).then((response: any) => {
                const { users, pagination: paginationResponse } = response.data.data;
                setCurators(
                    pagination.offset === 1
                        ? users
                        : curators.concat(users)
                );
                setHasMoreItems(Utils.hasMoreRecords(paginationResponse));
                setLoader(false);
                setPagination({
                    ...paginationResponse,
                    lastOffset: pagination.lastOffset,
                    offset: pagination.offset + 1,
                });
            })
                .catch((error: any) => {
                    setLoader(false);
                    Utils.handleErrorResponse({ error });
                });
        }
    }

    // Business Logic
    const reloadCurators = (): void => {
        pagination.offset = 1;
        pagination.lastOffset = 0;
        setCurators([]);
        loadCurators();
    };

    const loadMoreItems = (): void => {
        loadCurators();
    };

    // Hooks

    useEffect(() => {
        reloadCurators()
    }, [marketPlaceFilter])


    // UI Elements
    return (
        <Box className='artist-detail-main-container'>
            <InfiniteScroll
                threshold={50}
                pageStart={1}
                loadMore={loadMoreItems}
                hasMore={hasMoreItems}
            >
                <HeadTag title={title} description={description} />

                <Box>
                    {/* {(loader || isAPICalling) ? loaderUi :  */}
                    <Box className='exhibition-main-wrapper curator-tab-main-wrapper'>
                        <Box className='exibition-artists-component'>
                            <Box className='exibition-artists-tag'>
                                {curators && curators.length > 0 && curators.map((curator: any, key: any) => (
                                    <Grid item xs={6} sm={4} md={4} lg={3} key={key}>
                                        {/* eslint no-underscore-dangle: 0 */}
                                        <Link to={`/curator/${curator._id}`}>
                                            <Box className='exibition-artists-bg'>
                                                <Box className='exibition-artists-tag-box' >
                                                    <img src={curator.profilePicture ? Utils.getUrl(`${curator?.profilePicture}`, '464x464/') : '/images/art-blank-design.svg'} alt="ArtistImg" />
                                                </Box>
                                                <Typography>{curator?.name}</Typography>
                                            </Box>
                                        </Link>
                                    </Grid>
                                ))}

                            </Box>
                        </Box>
                    </Box>
                   {/* } */}
                </Box>
            </InfiniteScroll>
            {(loader || isAPICalling) ? loaderUi : <></>}
            {(!loader || !isAPICalling) && curators.length === 0 && (
                <EmptyPlaceholder
                    icon={<img src="/icons/NoData.png" />}
                    message="Curators not available"
                />
            )}
        </Box>
    )
}

const mapStateToProps = (state: AppState): any => ({
    marketPlaceFilter: state.marketPlaceFilter,
    isAPICalling: state.authState.isAPICalling
});

export default connect(mapStateToProps, null)(CuratorsTab);
