import { Typography, Box, Avatar } from '@material-ui/core';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import PanoramaIcon from '@material-ui/icons/Panorama';

export interface NFTCollectionData {
    name: string;
    logo: string;
    backgroundPicture: string;
    description: string;
    featured: boolean;
    thumbnail: string;
    id: string;
    itemsCount: number;
}

const CreationItem = (props: NFTCollectionData): ReactElement => {
    const { id, itemsCount, name, logo } = props;

    return (
        <Link to={`/creation/${id}`}>
            <Box className="top-collection trans-3d-hover" p={2}>
                <Avatar
                    variant="square"
                    className="collection-banner"
                    src={logo}
                >
                    <PanoramaIcon />
                </Avatar>

                <Box className="item-detail">
                    <Box className="item-detail-wrapper">
                        <Box>
                            <Typography
                                variant="body1"
                                className="collection-name"
                            >
                                {name}
                            </Typography>
                        </Box>

                        <Box
                            display="flex"
                            justifyContent="space-between"
                            width="100%"
                        >
                            <Box className="item-detail-collections">
                                <Typography
                                    variant="body2"
                                    className="item-text"
                                >
                                    {itemsCount} Collectibles
                                </Typography>
                            </Box>
                            <Box className="item-detail-favourites">
                                <img
                                    className="icon-item"
                                    src="/icons/icon_items.png"
                                    alt="items_count"
                                />
                                <Typography
                                    variant="body2"
                                    className="item-text"
                                >
                                    {itemsCount}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Link>
    );
};

export default CreationItem;
