import { useEffect, useState, ReactElement } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import {
    Box,
    Grid,
    Avatar,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ChildFriendlyIcon from '@material-ui/icons/ChildFriendly';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PanoramaIcon from '@material-ui/icons/Panorama';
import GavelIcon from '@material-ui/icons/Gavel';
import { connect } from 'react-redux';
import TimeAgo from 'react-timeago';
import { Utils } from '../../../shared/helpers';
import { NameLink, EmptyPlaceholder } from '../../../shared/components';
import { AppState } from '../../../state/RootReducer';
import { ActivityActions } from '../../../state/activity/Action';
import { ActivityService, DefaultPagination } from '../../../services';
import { AppPrimaryButton } from '../../../shared/components/AppComponents';

export interface Tradings {
    from: {
        isPrivate: boolean;
        name: string;
        address: string;
        userId: string;
    };
    to: {
        isPrivate: boolean;
        name: string;
        address: string;
        userId: string;
    };
    event: string;
    itemName: string;
    itemFile: string;
    itemLogo: string;
    saleType: string;
    amount: number;
    quantity: number;
    currencyType: string;
    timestamp: string;
    editionQuantity: string[];
}

type ActivityProps = {
    activityFilter: any;
    clearFilter: any;
};

const ActivityList = (props: ActivityProps): ReactElement => {
    const { activityFilter, clearFilter } = props;
    const { pathname } = useLocation();
    const [tradings, setTradings] = useState<Tradings[]>([]);
    const [pagination, setPagination] = useState({ ...DefaultPagination(30) });
    const [loadingHistory, setLoadingHistory] = useState(false);
    const [hasMoreItems, setHasMoreItems] = useState(true);

    const getQueryEvent = (): any => {
        const events = [];
        if (activityFilter.listing) {
            events.push('list');
        }
        if (activityFilter.sales) {
            events.push('transfer');
        }
        if (activityFilter.bids) {
            events.push('bids');
        }
        if (activityFilter.transfer) {
            events.push('create');
        }
        return events.toString();
    };

    // API Calls
    const loadTradingHistory = (): void => {
        let promise;
        if (pagination.lastOffset !== pagination.offset) {
            pagination.lastOffset = pagination.offset;
            const queryByEvent = getQueryEvent();
            promise = ActivityService.getTradingHistory(
                pagination.limit,
                pagination.offset,
                'recent',
                queryByEvent
            );
            setLoadingHistory(true);
            promise
                .then((response) => {
                    const activities = response.data.data.activity;
                    const paginationResponse = response.data.data.pagination;
                    if (pagination.offset === 1) {
                        setTradings(activities);
                    } else {
                        setTradings(tradings.concat(activities));
                    }
                    setLoadingHistory(false);
                    setHasMoreItems(Utils.hasMoreRecords(paginationResponse));
                    setPagination({
                        ...paginationResponse,
                        lastOffset: pagination.lastOffset,
                        offset: pagination.offset + 1,
                    });
                })
                .catch((error: any) => {
                    Utils.handleErrorResponse(error);
                    setTradings([]);
                    setLoadingHistory(false);
                });
        }
    };

    // Business Logic
    const reloadTradingHistory = (): void => {
        // if (pagination.lastOffset !== pagination.offset) {
        pagination.offset = 1;
        pagination.lastOffset = 0;
        setTradings([]);
        loadTradingHistory();
        // }
    };

    // Hooks
    useEffect(() => {
        reloadTradingHistory();
        getQueryEvent();
    }, [activityFilter]);

    useEffect(() => {
        if (pathname !== '/activity') {
            clearFilter();
        }
    }, [pathname]);

    const loadMoreHistory = (): void => {
        loadTradingHistory();
    };

    // UI Actions
    const actionEventBasedLogo = (eventName: string): any => {
        switch (eventName) {
            case 'list':
                return <LocalOfferIcon />;
            case 'bids':
                return <GavelIcon />;
            case 'create':
                return <ChildFriendlyIcon />;
            case 'cancel':
                return <ChildFriendlyIcon />;
            case 'transfer':
                return <SwapHorizIcon />;
            default:
                break;
        }
        return eventName;
    };

    // UI Elements
    return (
        <>
            <TableContainer className="activity-table">
                <Table stickyHeader className="striped-table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Event</TableCell>
                            <TableCell>Item</TableCell>
                            <TableCell>Edition</TableCell>
                            <TableCell>Purchase Type</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>From</TableCell>
                            <TableCell>To</TableCell>
                            <TableCell>Date</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {tradings.map((trading: any, index: number) => (
                            <TableRow key={`activity_${index}`}>
                                <TableCell>
                                    <Grid
                                        container
                                        spacing={2}
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            {actionEventBasedLogo(
                                                trading.event
                                            )}
                                        </Grid>
                                        <Grid item>
                                            <Typography>
                                                {Utils.titleCase(trading.event)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell>
                                    <Box className="align-content">
                                        <Avatar
                                            src={trading.itemThumbnail}
                                            variant="rounded"
                                        >
                                            <PanoramaIcon />
                                        </Avatar>
                                        <Typography
                                            className="ml-2"
                                            component={NavLink}
                                            to={`/item/${trading.itemId}`}
                                        >
                                            {trading.itemName}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    {Utils.sortEditions(
                                        trading.editionQuantity
                                    ).join(', ')}
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {Utils.titleCase(trading.saleType)}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{trading.quantity}</Typography>
                                </TableCell>
                                <TableCell>
                                    {trading.amount > 0
                                        ? `${trading.amount} ${trading.currencyType}`
                                        : '-'}
                                </TableCell>
                                <TableCell>
                                    <Typography component={NavLink} to="">
                                        <NameLink
                                            isPrivate={trading.from.isPrivate}
                                            name={trading.from.name}
                                            address={trading.from.address}
                                            id={trading.from.userId}
                                        />
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography component={NavLink} to="">
                                        <NameLink
                                            isPrivate={trading.to.isPrivate}
                                            name={trading.to.name}
                                            address={trading.to.address}
                                            id={trading.to.userId}
                                        />
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        <TimeAgo date={trading.timestamp} />
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {!loadingHistory && hasMoreItems && (
                    <Grid container justifyContent="center">
                        <Box p={2}>
                            <AppPrimaryButton
                                className="btn-small"
                                onClick={loadMoreHistory}
                                title="Load More"
                            />
                        </Box>
                    </Grid>
                )}
                {loadingHistory && (
                    <Grid container justifyContent="center">
                        <Box p={2}>
                            <CircularProgress color="primary" />
                        </Box>
                    </Grid>
                )}
            </TableContainer>
            {tradings.length === 0 && loadingHistory === false && (
                <EmptyPlaceholder
                    icon={<AssessmentIcon />}
                    message="No activities found"
                />
            )}
        </>
    );
};

const mapStateToProps = (state: AppState): any => ({
    activityFilter: state.activityFilter,
});

const mapDispatchToProps = (dispatch: any): any => ({
    clearFilter: (data: any) => dispatch(ActivityActions.clearFilter(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityList);
