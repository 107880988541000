import { ReactElement } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { HeadTag } from '../../../shared/components';
import { AppBigNavButton } from '../../../shared/components/AppComponents'
import NFTList from './NFTList';

const Artwork = (props:any): ReactElement => {
    const title = 'About';
    const { arts } = props
    const description =
        "About";

    return (
        <Box className='artwork-wrapper-py'>
            <HeadTag title={title} description={description} />
            <Grid container className="artwork-wrapper" alignItems="center">
                <Grid xs={12} id="About">
                    <Box className='about-title-box artwork-py'>
                        <Typography className="about-title">Trending Artwork</Typography>
                        <AppBigNavButton
                            endIcon={<img src="/images/banner-right-arrow.svg" />}
                            title="View All"
                            to="/marketplace"
                            id="hero-button"
                        />
                    </Box>
                </Grid>
               <NFTList trending arts={arts} />
            </Grid>
        </Box>
    )
}

export default Artwork