import { Box, Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const NotFound = (): any => {
    console.log()
    return (
        <>
            {/* <div className="notFound"> */}
            <Box className="error-page-wrapper">
                <Box className='error-page-box'>
                    <Typography variant='h2'>Lost Your Way?</Typography>
                    <Box className='error-page-img'>
                        <img src="/images/mona-lisa.png" alt="img"/>
                    </Box>
                    <Typography variant='h5'>Our artists and curators <br /> are waiting for you on the <br /> home page</Typography>
                    <Link to='/home'>
                        <Button className='error-home-btn'>VISIT HOME PAGE</Button>
                    </Link>
                </Box>
            </Box>
            {/* </div> */}
        </>
    )
};


export default NotFound;