import Payment from "payment";
import moment from "moment";
import { LocationModal } from '../models/StateModal';
import Toast from './Toast';
import { AppPreference } from '../managers';
import { AppPagination } from '../models/General';
import metadata from '../../metadata.json';
import store, { AppState } from '../../state/RootReducer';
import { ProfileActions } from '../../state/profile/Actions';
import { AuthActions } from '../../state/authentication/Action';
import { defaultProfileInfo } from '../../state/profile/Reducer';

const getInitial = (name = ''): any =>
    name ? name
        .replace(/\s+/, ' ')
        .split(' ')
        .slice(0, 2)
        .map((v) => v && v[0].toUpperCase())
        .join('')
        : '';

const hasMoreRecords = (pagination: AppPagination): boolean =>
    pagination.limit * pagination.offset < pagination.total;

const handleErrorResponse = ({ error }: any): void => {
    if (error && error.response) {
        const { data, status } = error.response;
        if (status === 400) {
            const { msg } = data;
            Toast.showErrorMessage(msg);
        } else if (status === 500) {
            Toast.showErrorMessage('Please try again');
        }
    }
};

const handleSuccessResponse = (response: any): any => {
    const { msg } = response.data;
    Toast.showSuccesMessage(msg);
};

const getNextPath = (location: any): any => {
    if (location.state !== null) {
        const { nextPathName } = location.state as LocationModal;
        return nextPathName;
    }
    const path = '';
    return path;
};

const formatedAddress = (address: string): any => {
    if (address) {
        if (address.length > 10) {
            return `${address.substr(0, 7)}...${address.substr(
                address.length - 6,
                address.length - 1
            )}`;
        }
        return address;
    }
    return '-';
};

const titleCase = (value: string): any => {
    if (value) {
        return value
            .trim()
            .split(' ')
            .map((w) =>
                w !== '' ? w[0].toUpperCase() + w.substr(1).toLowerCase() : ''
            )
            .join(' ');
    }
    return '-';
};

const fileExtension = (fileName: string): string => {
    if (fileName) {
        const url = fileName.split('?')[0];
        const extension = url.split('.').pop();
        return extension || '';
    }
    return '';
};

const isPlayableItem = (file: any): any => {
    let fileUrl = '';
    if (file instanceof Array) {
        const firstUrl = file[0];
        fileUrl = firstUrl;
    } else {
        fileUrl = file;
    }
    if (fileUrl !== '') {
        const extension = fileExtension(fileUrl);
        const mediaExtensions = ['mp4', 'mpga', 'webm', 'oga', 'wav'];
        return mediaExtensions.indexOf(extension) > -1;
    }
    return false;
};

const isPlayableUrl = (fileUrl: any): any => {
    if (fileUrl !== '') {
        const extension = fileExtension(fileUrl);
        const mediaExtensions = ['mp4', 'mpga', 'webm', 'oga', 'wav'];
        return mediaExtensions.indexOf(extension) > -1;
    }
    return false;
};

const copyObject = (object: any): any => JSON.parse(JSON.stringify(object));

const getLastFourDigit = (value: any): any => value.substr(-4);

const formatCrypto = (crypto: any): string =>
    parseFloat(`${crypto}`).toFixed(5);

const formatPaymentModes = (mode: string): any => {
    if (mode === 'card') {
        return 'Card';
    }
    if (mode === 'metamask') {
        return 'Metamask';
    }
    if (mode === 'fiatWallet') {
        return 'Fiat Wallet';
    }
    if (mode === 'cryptoWallet') {
        return 'Crypto Wallet';
    }
    if (mode === 'wallet') {
        return 'Wallet';
    }
    return mode;
};

const isPDFDocument = (documentUrl: string): boolean => {
    const isPDF = fileExtension(documentUrl) === 'pdf';
    return isPDF;
};

const getFileFromBlobUrl = async (blobUrl: any): Promise<any> => {
    const blob = await fetch(blobUrl).then((r) => r.blob());
    return blob;
};

const sortEditions = (editions: string[]): string[] => {
    editions.sort((a, b) => {
        const aPart = a.split('/')[0];
        const bPart = b.split('/')[0];
        return parseInt(aPart, 10) - parseInt(bPart, 10);
    });
    return editions;
};

const formateName = (name: string): any => {
    if (name.length > 12) {
        return `${name.substring(0, 12)}...`
    }
    return name
}


function clearNumber(value: any = ""): any {
    return value.replace(/\D+/g, "");
}

// ** Headers
export function getHeaders(): any {
    return {
        "x-auth-token": `bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        "content-type": "application/json"
    }
}

export function formatCreditCardNumber(value: any): any {
    if (!value) {
        return value;
    }

    const issuer = Payment.fns.cardType(value);
    const clearValue = clearNumber(value);
    let nextValue;
    console.log("Payment: ", Payment.fns)
   
    switch (issuer) {
        case "amex":
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                10
            )} ${clearValue.slice(10, 15)}`;
            break;
        case "dinersclub":
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                10
            )} ${clearValue.slice(10, 14)}`;
            break;
        default:
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                8
            )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 16)}`;
            break;
    }
    return {value: nextValue.trim(), error: !Payment.fns.validateCardNumber(nextValue)};
}

export function formatCVC(value: any, allValues: any = {}): any {
    const clearValue = clearNumber(value);
    let maxLength = 4;

    if (allValues.number) {
        const issuer = Payment.fns.cardType(allValues.number);
        maxLength = issuer === "amex" ? 4 : 3;
    }
    return {value: clearValue.slice(0, maxLength), error: !Payment.fns.validateCardCVC(clearValue)}
}

export function formatExpirationDate(value: any): any {
    const clearValue = clearNumber(value);
    if (clearValue.length >= 3) {
        return {value: `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`, error: !Payment.fns.validateCardExpiry(value.slice(0,5))};
    }
    return clearValue;
}

export function formatFormData(data: any): any {
    return Object.keys(data).map(d => `${d}: ${data[d]}`);
}

export function getUrl(data: string, size?: string): any {
    if (data) {
        const type = data?.split("/");
        const gif = type[type.length - 1].split(".")[1]
        if (gif === 'gif') {
            return data ? `${process.env.REACT_APP_GIFURL}/${data}` : "";
        }
        if (size) {
            return data ? `${process.env.REACT_APP_CLOUDFRONT}${size}${data}` : "";

        }
        return data ? `${process.env.REACT_APP_CLOUDFRONT}${data}` : "";
    }
    return data

}

export function getVideoUrl(data: string): any {
    if (data) {
        const test = data.split(".")
        test.pop()
        return data ? `${process.env.REACT_APP_VIDEOURL}/${data}` : '';

        // return data ? `${process.env.REACT_APP_VIDEOURL}/${test.toString()}_360.m3u8` : "";
    }
    return data;


}


export const ethPrice = (price: number, convertion: any): any => {
    if (convertion.to.amount !== '0' && price) {
        const usdValue = parseFloat(convertion.to.amount);
        return (price / usdValue).toFixed(3);
    }
    return 0
}

export const checkLauncDate = (date: any): any => {
    if (date && moment(date).diff(new Date()) < 0) return true;
    return false;
};

export const goesLive = (date: any): any => {
    const Days = Math.floor(moment(date).diff(moment(),"days"))
    const Hours =  Math.floor( (moment(date).diff(moment(),"hours")% 1440) / 60);
    const Minutes = Math.floor((moment(date).diff(moment(),"minutes")% 1440) % 60);

    return `${Days} Day${Days > 1 ? 's' : ''}, ${Hours} hours, ${Minutes} mins`;
}

export const clearCache = (): any => {
    const jsonInfo:any = metadata
    const server = process.env.REACT_APP_ENV;
    if (AppPreference.getDeployCount() !== jsonInfo[server].buildRevision.toString()) {
        AppPreference.clearStorage()
        if ('caches' in window) {
            caches?.keys().then((names) => {
                names.forEach((name) => {
                    caches.delete(name);
                });
            });
        }
        store.dispatch(ProfileActions.setProfileData(defaultProfileInfo));
        store.dispatch(AuthActions.setLoginStatus(false));
        AppPreference.setDeployCount(jsonInfo[server].buildRevision)
    }
}

export const clearSession = ():any => {
    AppPreference.clearStorage()
    if ('caches' in window) {
        caches?.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
    }
    store.dispatch(ProfileActions.setProfileData(defaultProfileInfo));
    store.dispatch(AuthActions.setLoginStatus(false));
}
export const goesLiveIn = (date: any): any => {
    
    const Days = Math.floor(moment(date).diff(moment(),"days"))
    const Hours =  Math.floor(((moment(date).diff(moment(),"hours")+ 24) % 24));
    const Minutes = Math.floor((moment(date).diff(moment(),"minutes")% 1440) % 60);
    const Seconds =  Math.floor((moment(date).diff(moment(),"seconds")% 1440) % 60);
    return { day: Days, hour:Hours, minute: Minutes, second: Seconds }
    
}

export const isSecondarySale = ():boolean => {
    if(process.env.REACT_APP_SECONDARY_SALE === "true"){
        return true;
    }
        return false;
}


export default {
    hasMoreRecords,
    handleSuccessResponse,
    handleErrorResponse,
    titleCase,
    isPlayableItem,
    formatedAddress,
    getNextPath,
    copyObject,
    getLastFourDigit,
    getInitial,
    formatCrypto,
    formatPaymentModes,
    isPlayableUrl,
    fileExtension,
    isPDFDocument,
    getFileFromBlobUrl,
    sortEditions,
    formateName,
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
    formatFormData,
    getUrl,
    ethPrice,
    checkLauncDate,
    goesLive,
    getHeaders,
    clearCache,
    goesLiveIn,
    clearSession
};
