import {
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    TablePagination,
    Box,
    Avatar,
    Typography,
    Divider,
} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/Receipt';
import moment from 'moment';
import { useEffect, useState } from 'react';
import CollectionsIcon from '@material-ui/icons/Collections';
import { ProfileService, DefaultPagination } from '../../../services';
import { Utils } from '../../../shared/helpers';
import WithdrawReqSkeleton from '../../../shared/skeletons/WithdrawReqSkeleton';
import { CopyLink, EmptyPlaceholder } from '../../../shared/components';
import ProfileEmptyPlaceholder from '../../../shared/components/ProfileEmptyPlaceHolder';

const ExportHistoryList = (): any => {
    const [exportHistoryList, setExportHistoryList] = useState([]);
    const [pagination, setPagination] = useState({ ...DefaultPagination(10) });
    const [loader, setLoader] = useState(true);

    // API Calls
    const loadExportHistory = (): void => {
        setLoader(true);
        ProfileService.exportHistory(pagination.limit, pagination.offset)
            .then((response: any) => {
                const exportHistory = response.data.data.report;
                const paginationResponse = response.data.data.pagination;
                setExportHistoryList(exportHistory);
                setPagination(paginationResponse);
                setLoader(false);
            })
            .catch((error: any) => {
                setLoader(false);
                Utils.handleErrorResponse({
                    error,
                });
            });
    };

    // Hooks
    useEffect(() => {
        loadExportHistory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // UI Actions
    const actionHandleLimitChange = (event: any): void => {
        const rowsPerPage = event.target.value;
        pagination.limit = parseInt(rowsPerPage, 10);
        loadExportHistory();
    };

    const actionHandlePageChange = (event: any, newPage: number): void => {
        pagination.offset = newPage + 1;
        loadExportHistory();
    };

    // UI Elements
    return (
        <>
            <Box className="profile-content">
                <Box className="card-header-btn view-profile">
                    <Typography className="title" variant="h3">
                        Export History
                    </Typography>
                </Box>
                <Divider className='border-content'/>
                <Box className="profile-card">
                    <Typography variant="body1" className="subtitle">
                        Your can view all export history here.
                    </Typography>

                    {exportHistoryList.length === 0 && loader === false && (
                        // <ProfileEmptyPlaceholder
                        //     icon={<ReceiptIcon />}
                        //     message="No Export History found"
                        // />

                        <EmptyPlaceholder
                        icon={<ReceiptIcon />}
                                    message="No Export History found"
                                />
                    )}
                    {loader === true && <WithdrawReqSkeleton />}

                    {loader === false && exportHistoryList.length > 0 && (
                        <Box mt={4}>
                            <TableContainer>
                                <Table stickyHeader className="striped-table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>S.No</TableCell>
                                            <TableCell>Item Logo</TableCell>
                                            <TableCell>Item Name</TableCell>
                                            <TableCell>Quantity</TableCell>
                                            <TableCell>
                                                Transaction Amount
                                            </TableCell>
                                            <TableCell>Payment Mode</TableCell>
                                            <TableCell>Token ID</TableCell>
                                            <TableCell>Transaction Hash</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Exported At</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {exportHistoryList.map(
                                            (exportlist: any, index) => (
                                                <TableRow key={`export_${index}`}>
                                                    <TableCell>
                                                        {(pagination.offset - 1) *
                                                            pagination.limit +
                                                            (index + 1)}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Box
                                                            sx={{
                                                                alignItems:
                                                                    'center',
                                                                display: 'flex',
                                                            }}
                                                        >
                                                            <Avatar
                                                                src={
                                                                    Utils.getUrl(`${exportlist.itemThumbnail}`)
                                                                }
                                                            />
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell className="text-case">
                                                        {exportlist.itemName}
                                                    </TableCell>
                                                    <TableCell>
                                                        {exportlist.quantity}
                                                    </TableCell>
                                                    <TableCell>
                                                        {exportlist.txnAmount}{' '}
                                                        {exportlist.currencyType
                                                            ? exportlist.currencyType
                                                            : ''}
                                                    </TableCell>
                                                    <TableCell>
                                                        {Utils.titleCase(
                                                            exportlist.modeOfPayment
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        <CopyLink
                                                            text={
                                                                exportlist.tokenId
                                                            }
                                                            address
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <CopyLink
                                                            text={
                                                                exportlist.transferHash
                                                            }
                                                            address
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        {exportlist.status}
                                                    </TableCell>
                                                    <TableCell>
                                                        {moment(exportlist.createdAt).format(
                                                            'DD MMM YYYY hh:mm A'
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                component="div"
                                count={pagination.total}
                                onPageChange={actionHandlePageChange}
                                onRowsPerPageChange={actionHandleLimitChange}
                                page={pagination.offset - 1}
                                rowsPerPage={pagination.limit}
                                rowsPerPageOptions={[5, 10, 25]}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default ExportHistoryList;
