import { ReactElement } from 'react';
import { Avatar, Box, CardMedia, Fade, Grid, Tooltip, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ReginIconName from './regionIconName';
import { getUrl } from '../../../shared/helpers/Utils';

const ExhibitionItem = ({ activeExhibitionTab }: any): ReactElement => (

    <Link
        to={`/exhibition/${activeExhibitionTab._id}`}
        className=""
    >
        {/* eslint no-underscore-dangle: 0 */}
        <Box className="about-image-box exhibition exhibition-tab">
            <CardMedia
                component="img"
                height="287"
                image={activeExhibitionTab.logo ? getUrl(
                    `${activeExhibitionTab.logo}`,
                    '684x487/'
                ) : '/images/if-no-image.png'} alt="thumbnail_image"
            />
            <Box className="exhibition-tab-wrapper">
                <Box className="exhibition-tab-text">
                    <Typography
                        variant="h1">
                        {activeExhibitionTab?.title
                            ? activeExhibitionTab?.title
                            : 'No title'}
                    </Typography>
                </Box>
                <Grid container spacing={1}>
                    <Grid item sm={12} md={12} lg={12} xl={12}>
                        <Box
                            display='flex'
                            flexDirection='row'
                            alignItems='center' className="collage-mobile">
                            <Avatar src={
                                activeExhibitionTab
                                    .curator
                                    .profilePicture
                                    ? getUrl(
                                        `${activeExhibitionTab.curator.profilePicture}`,
                                        '100x100/'
                                    )
                                    : '/icons/user.png'
                            } alt='user' />
                            <Box
                                pl={1}
                                height={46}
                                display='flex'
                                flexDirection='column'
                                justifyContent='center'
                                className="banner-text">
                                <Typography className='text-gray curator-text mb-1' >
                                    Curated By
                                </Typography>
                                <Tooltip arrow title={activeExhibitionTab.curator.name} TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}>
                                    <Typography className='curatorName text-ellipsis--1' variant="body1">
                                        {activeExhibitionTab?.curator?.name}
                                    </Typography>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item style={{ width: '100%' }} sm={12} md={12} lg={12} xl={12}>
                        <Box height={36} className='text-ellipsis--2'>
                            <Typography variant="body1">
                                {activeExhibitionTab?.description.replace(/<[^>]+>/g, '')}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box className="exhibition-button">
                    {activeExhibitionTab?.region && <ReginIconName name={`${activeExhibitionTab?.region?.name}, ${activeExhibitionTab?.region?.subRegion}`} />}
                    <Box className="exhibition-text-line">
                        <Typography variant="h6">
                            View Exhibition
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    </Link>

)


export default ExhibitionItem