import { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, CardMedia, CircularProgress, Grid, Typography } from '@material-ui/core';
import CollectionsIcon from '@material-ui/icons/Collections';
import Slider from "react-slick";
import { EmptyPlaceholder, HeadTag, SlideNextArrow, SlidePrevArrow } from '../../../shared/components';
import { AppBigNavButton } from '../../../shared/components/AppComponents';
import { CollectionService } from '../../../services';
import { getUrl } from '../../../shared/helpers/Utils';
import ReginIconName from './regionIconName';


interface Props {
    artists: Record<string, any>[]
}

const Artist: React.FC<Props> = ({ artists }: Props): ReactElement => {
    const title = 'About';

    const description =
        "About";
    const ImageSliderConfigArtist = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        arrows: true,
        slidesToScroll: 4,
        initialSlide: 0,
        nextArrow: <SlideNextArrow />,
        prevArrow: <SlidePrevArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: true,
                }
            },
            {
                breakpoint: 969,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    // TODO immediate implementation

    /* eslint no-underscore-dangle: 0 */

    return (
        <Box id="artist-wrapper" className='artwork-main-wrapper-py'>
            <HeadTag title={title} description={description} />
            <Grid container className="artwork-wrapper" alignItems="center">
                <Grid xs={12} id="About">
                    <Box className='about-title-box artist-py'>
                        <Typography className="about-title">Trending Artist</Typography>
                        {artists.length && artists.length > 4 && <AppBigNavButton
                            endIcon={<img src="/images/banner-right-arrow.svg" />}
                            title="View All"
                            to="/marketplace/artist"
                            id="hero-button"
                        />}
                    </Box>
                </Grid>
                <Grid container xs={12} spacing={4} className="main-my-grid-wrapper">
                    <>
                        <Slider className='nft-slider' {...ImageSliderConfigArtist}>
                            {artists.map((artist: any) => (
                                <Link to={`/artist/${artist._id}`} key={artist._id} className="artist-link">
                                    <Grid

                                        className='my-grid'
                                    >
                                        <Box className="artist-box">
                                            <Box className='artist-box-img'>
                                                <CardMedia
                                                    component='img'
                                                    image={artist?.thumbnail ? getUrl(`${artist?.thumbnail}`, '1000x1200/') : '/images/if-no-image.png'} alt="ArtistImg"
                                                />
                                            </Box>
                                            <Box className='artist-content'>
                                                <Typography className="artist-title">{artist?.name}</Typography>
                                                <ReginIconName name={artist?.region?.name} />
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Link>
                            ))}
                        </Slider>

                        {artists === undefined || artists.length === 0 && (
                            <EmptyPlaceholder
                                icon={<img src="/icons/NoData.png" />}
                            />
                        )}

                    </>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Artist