import { Box, Tab, Grid, Typography, Tooltip, CircularProgress, CardMedia } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Link, useParams } from 'react-router-dom';
import { ReactElement, useState, useEffect } from 'react';
import CollectionsIcon from '@material-ui/icons/Collections';
import { useSelector } from 'react-redux';
import { DefaultPagination, CollectionService } from '../../services';
import { NumberPretty } from '../../shared/components/AppComponents';
import { Utils } from '../../shared/helpers';
import { EmptyPlaceholder } from '../../shared/components';
import { ethPrice, getUrl } from '../../shared/helpers/Utils';
import { CurrencyConvertion } from '../../state/application/Reducer';
import { AppState } from '../../state/RootReducer';


const CommunityTab = (): ReactElement => {
    const { userId } = useParams();
    const [value, setValue] = useState('1');
    const handleChange = (e: any, newValue: string): any => {
        setValue(newValue)
    }

    const [artistsDetail, setArtistsDetail] = useState([]);
    const [pagination, setPagination] = useState({ ...DefaultPagination(30) });
    const [loadingItems, setLoadingItems] = useState(false);
    const convertion: CurrencyConvertion = useSelector(
        (state: AppState) => state.applicationState.currencyConverstion
    );
    // TODO immediate implementation
    // API Calls
    const loadItems = (reload = false): void => {
        if (pagination.lastOffset !== pagination.offset) {
            if (reload) {
                pagination.offset = 1
            }
            pagination.lastOffset = pagination.offset;
            setLoadingItems(true);

            CollectionService.getOwnedItems(pagination.limit, pagination.offset, false, userId)
                .then((response: any) => {
                    const { data } = response.data;
                    setArtistsDetail(data.items);
                    const paginationResponse = data.pagination;
                    setLoadingItems(false);
                    setPagination({
                        ...paginationResponse,
                        lastOffset: pagination.lastOffset,
                        offset: pagination.offset + 1,
                    });
                })
                .catch((error: any) => {
                    setLoadingItems(false);
                    Utils.handleErrorResponse({ error });
                });
        }
    };

    useEffect(() => {
        loadItems();
    }, []);

    return (
        <>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="artist-tab">
                    <TabList onChange={handleChange} aria-label="lab API tabs example" className='community-tab'>
                        <Tab label="Created" value='1' />
                        <Tab label="Collected" value='2' />
                        <Tab label="Liked" value='3' />
                    </TabList>
                </Box>
                <TabPanel className='market-slider-wrapper' value={value}>
                    <Box className={value === '1' ? 'market-slider active' : 'market-slider'}>
                        <Grid container md={12}>
                            {/* {!loadingItems && artistsDetail.length === 0 && (
                                <EmptyPlaceholder
                                    icon={<CollectionsIcon color="primary" />}
                                    message="You don't own any Artist"
                                />
                            )} */}
                            {/* {artistsDetail.length !== 0 && artistsDetail.map((artistItem: any, index) => ( */}
                            <>
                                <Grid xs={12} sm={6} md={4} lg={3} item className="main-my-grid-wrapper main-my-grid-wrapper-community">
                                    <Grid className='my-grid'>
                                        {/* <Link to={`/item/${artistItem.id}`}> */}
                                        <div className='nft-item trans-3d-hover'>
                                            <Box style={{ width: '100%' }} p={2}>
                                                <Box style={{ display: 'flex', justifyContent: 'end' }}>
                                                    <img
                                                        className="icon-fav-heart"
                                                        src="/icons/fav_heart.svg"
                                                        alt="verify_icon"
                                                    />
                                                </Box>
                                                <CardMedia
                                                    component="img"
                                                    height="290"
                                                    image='/images/artist-image.png'
                                                    alt="thumbnail_image"
                                                />
                                            </Box>

                                            <Box className="item-detail">
                                                <Box className="items-content">
                                                    <Box display="flex" justifyContent="space-between">
                                                        <Tooltip arrow title="name">
                                                            <Typography variant="body1" className="item-name">
                                                                Kajikazawa
                                                            </Typography>
                                                        </Tooltip>
                                                    </Box>

                                                    <Box className="user-detail" mt={2}>
                                                        <Tooltip
                                                            title='Private Account'
                                                            placement="right-end"
                                                        >
                                                            <Box className="name-section">
                                                                <Box className='name-section-com'>
                                                                    <img
                                                                        className="avatar user-avatar-com"
                                                                        src='/icons/user.png'
                                                                        alt="user_avatar"
                                                                    />
                                                                </Box>
                                                                <Box>
                                                                    <Box className="fvc">
                                                                        <Typography
                                                                            variant="body2"
                                                                            className="user-name"
                                                                        >
                                                                            Tam Gryn
                                                                        </Typography>

                                                                        <img
                                                                            className="icon-verified"
                                                                            src="/icons/icon_verified.png"
                                                                            alt="verify_icon"
                                                                        />
                                                                    </Box>

                                                                    <Typography
                                                                        variant="body2"
                                                                        className="user-role"
                                                                    >
                                                                        Tam Gryn
                                                                        {/* {artistItem.postedBy.name} */}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </Tooltip>
                                                        {/* VIEWS ICONS & COUNT */}
                                                        <Box className="views">
                                                            <img className="view" src="/icons/visibility.svg" />
                                                            {/* <NumberPretty
                                                                    count={artistItem.numberOfViews}
                                                                /> */}
                                                            <Typography>476</Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box className="auction-detail">
                                                    <Box className="bid-info" p={2}>

                                                        <Box
                                                            display="flex"
                                                            flexDirection="column"
                                                            alignItems=""
                                                            mt={2}
                                                        >
                                                            <Typography
                                                                variant="body1"
                                                                className="current-bid-value"
                                                            >
                                                                1330 USD{/* $ {artistItem.price} */}
                                                            </Typography>
                                                            <Box mt={1} className="eth-price">
                                                                <Typography variant="body2" id='eth-balance'>
                                                                    ( 0.706 ETH)
                                                                    {/* ( {ethPrice(artistItem.price,convertion)} ETH) */}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>

                                                    <Box className="stock-info">
                                                        <Box className='Artwork-sell'>
                                                            <Typography className='Artwork-buy-btn'>
                                                                BUY
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </div>
                                        {/* </Link> */}
                                    </Grid>
                                </Grid>
                            </>
                            {/* ))} */}
                            {/* {loadingItems && (
                                <Box className="empty-content">
                                    <CircularProgress />
                                </Box>
                            )} */}
                        </Grid>
                    </Box>
                </TabPanel>
                <TabPanel value='2'>
                    <Box className='data-not-found'>
                        <EmptyPlaceholder
                            icon={<CollectionsIcon color="primary" />}
                            message="Not bought any Art"
                        />
                    </Box>
                </TabPanel>
                <TabPanel value='3'>
                    <Box className='data-not-found'>
                        <EmptyPlaceholder
                            icon={<CollectionsIcon color="primary" />}
                            message="Not yet explored Arts"
                        />
                    </Box>
                </TabPanel>
            </TabContext>
        </>
    )
}

export default CommunityTab
