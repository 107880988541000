import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { HeadTag } from '../../shared/components';
import { ResetPasswordForm } from './components';

const ResetPasswordPage = (): ReactElement => {
    const { id } = useParams();
    return (
        <>
            <HeadTag title="Reset Password" />
            <ResetPasswordForm id={id} />
        </>
    );
};

export default ResetPasswordPage;
