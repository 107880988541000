import { ReactElement } from 'react';
import { Box, Chip } from '@material-ui/core';
import { connect } from 'react-redux';
import { AppState } from '../../../state/RootReducer';
import { ActivityActions } from '../../../state/activity/Action';
import { AppTeritaryButton } from '../../../shared/components/AppComponents';

const SelectedFilter = (props: any): ReactElement => {
    const { activityFilter, setFilter, clearFilter, marketFilterList } = props;
    return (
        <Box className="selected-filters-container">
            <Box pl={2} pt={1}>
                {(activityFilter.listing ||
                    activityFilter.sales ||
                    activityFilter.bids ||
                    activityFilter.transfer) && (
                    <Box className="selected-filters">
                        {activityFilter.listing && (
                            <Chip
                                label="Listings"
                                onDelete={() => {
                                    setFilter({
                                        ...activityFilter,
                                        listing: !activityFilter.listing,
                                    });
                                }}
                                color="secondary"
                                variant="outlined"
                            />
                        )}

                        {activityFilter.sales && (
                            <Chip
                                label="Transfer"
                                onDelete={() => {
                                    setFilter({
                                        ...activityFilter,
                                        sales: !activityFilter.sales,
                                    });
                                }}
                                color="secondary"
                                variant="outlined"
                            />
                        )}

                        {activityFilter.bids && (
                            <>
                                <Chip
                                    label="Bids"
                                    onDelete={() => {
                                        setFilter({
                                            ...activityFilter,
                                            bids: !activityFilter.bids,
                                        });
                                    }}
                                    color="secondary"
                                    variant="outlined"
                                />
                            </>
                        )}

                        {activityFilter.transfer && (
                            <Chip
                                label="Create"
                                onDelete={() => {
                                    setFilter({
                                        ...activityFilter,
                                        transfer: !activityFilter.transfer,
                                    });
                                }}
                                color="secondary"
                                variant="outlined"
                            />
                        )}
                        <AppTeritaryButton
                            title="Clear All"
                            className="btn-small"
                            onClick={() => {
                                clearFilter(marketFilterList);
                            }}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

const mapStateToProps = (state: AppState): any => ({
    activityFilter: state.activityFilter,
    marketFilterList: state.MarketFilterList,
});

const mapDispatchToProps = (dispatch: any): any => ({
    setFilter: (data: any) => dispatch(ActivityActions.setFilter(data)),
    clearFilter: (data: any) => dispatch(ActivityActions.clearFilter(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectedFilter);
