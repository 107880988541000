export enum MarketPlaceReduxActions {
    SET_SEARCH = 'SET_SEARCH',
    SET_CATEGORY = 'SET_CATEGORY',
    SET_FILTER = 'SET_FILTER',
    SET_PRICE = 'SET_PRICE',
    CLEAR_FILTER = 'CLEAR_FILTER',
    RESET_ALL_FILTER = 'RESET_ALL_FILTER',
    SET_CELEBRITY = 'SET_CELEBRITY',
}

const setCategory = (payload: any): any => ({
    type: MarketPlaceReduxActions.SET_CATEGORY,
    payload,
});

const setPrice = (payload: any): any => ({
    type: MarketPlaceReduxActions.SET_PRICE,
    payload,
});

const setSearch = (payload: any): any => ({
    type: MarketPlaceReduxActions.SET_SEARCH,
    payload,
});

const setFilter = (payload: any): any => ({
    type: MarketPlaceReduxActions.SET_FILTER,
    payload,
});

const clearFilter = (payload: any): any => ({
    type: MarketPlaceReduxActions.CLEAR_FILTER,
    payload,
});

const resetAllFilter = (payload: any): any => ({
    type: MarketPlaceReduxActions.RESET_ALL_FILTER,
    payload,
});

const setCelebrity = (payload: any): any => ({
    type: MarketPlaceReduxActions.SET_CELEBRITY,
    payload,
});

 
export const MarketPlaceActions = {
    setCategory,
    setPrice,
    setSearch,
    setFilter,
    clearFilter,
    resetAllFilter,
    setCelebrity,
};
