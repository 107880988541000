import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const VideoLoader = (props: any): any => (
    <Box
        className="video-loader-skeleton"
        width="100%"
        display="flex"
        justifyContent="center"
    >
        <Skeleton
            style={{ borderRadius: '1rem' }}
            width="100%"
            height="100%"
            animation="pulse"
            variant="rect"
        />
    </Box>
);
export default VideoLoader;
