import { ReactElement } from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import { Formik } from 'formik';
import { ChangePasswordSchema } from '../../../validators/ProfileSchema';
import {
    AppPrimaryButton,
    AppFormikPasswordField,
} from '../../../shared/components/AppComponents';
import { Utils } from '../../../shared/helpers';
import { ProfileService } from '../../../services';

const ChangePasswordForm = (): ReactElement => {
    const changePasswordForm = {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    };

    // API Calls
    const changePassword = (formValues: any, callback: any): any => {
        ProfileService.changePassword(formValues)
            .then((response: any) => {
                Utils.handleSuccessResponse(response);
                callback(true);
            })
            .catch((error: any) => {
                callback(false);
                Utils.handleErrorResponse({
                    error,
                });
            });
    };

    // UI Actions
    const actionChangePassword = (formValues: any, formik: any): any => {
        const { currentPassword, newPassword } = formValues;
        const requestObj = {
            currentPassword,
            newPassword,
        };
        formik.setSubmitting(true);
        changePassword(requestObj, () => {
            formik.resetForm();
            formik.setSubmitting(false);
        });
    };

    // UI Elements
    return (
        <Box className="app-profile-wrapper" my={3}>
            <Box className="profile-header view-profile" mb={2}>
                <Typography variant="h3" className="title">
                    Change Password
                </Typography>

            </Box>
            <Divider className='border-content'/>
            <Box className="profile-card forgot-pwd-profile-card">
                <Typography variant="body2" className="subtitle">
                    Modify your password here.
                </Typography>


                <Box mt={3}>
                    <Formik
                        enableReinitialize
                        initialValues={changePasswordForm}
                        validationSchema={ChangePasswordSchema}
                        validateOnMount
                        onSubmit={(formValues, formik) => {
                            actionChangePassword(formValues, formik);
                        }}
                    >
                        {({ handleSubmit, isSubmitting, isValid }) => (
                            <form onSubmit={handleSubmit} className="forgot-password-form">
                                <AppFormikPasswordField
                                    className="forgot-password-form-input"
                                    label="Current Password"
                                    name="currentPassword"
                                    type="password"
                                />

                                <AppFormikPasswordField
                                    className="forgot-password-form-input"
                                    label="New Password"
                                    name="newPassword"
                                    type="password"
                                />

                                <AppFormikPasswordField
                                    className="forgot-password-form-input"
                                    label="Confirm Password"
                                    name="confirmPassword"
                                    type="password"
                                />

                                <Box>
                                    <AppPrimaryButton
                                        title="SUBMIT"
                                        isLoading={isSubmitting}
                                        loadingText="CHANGING"
                                        disabled={isSubmitting || !isValid}
                                        onClick={handleSubmit}
                                        className="forgot-pwd-submit-btn"
                                    />
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </Box>
        </Box>
    );
};

export default ChangePasswordForm;
