import * as Yup from 'yup';
import { firstName, lastName, email, contactNo, message } from './Schema';

export const ContactUsSchema = Yup.object().shape({
    firstName,
    lastName,
    email,
    contactNo,
    message,
});
