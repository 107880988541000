import {
    Box,
    TableContainer,
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    TablePagination,
    Typography,
    Divider,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import CollectionsIcon from '@material-ui/icons/Collections';
import moment from 'moment';
import { DefaultPagination, ProfileService } from '../../../services';
import { CopyLink, EmptyPlaceholder } from '../../../shared/components';
import { Utils } from '../../../shared/helpers';
import WithdrawReqSkeleton from '../../../shared/skeletons/WithdrawReqSkeleton';
import ImportNFTDialog from './ImportNFT';
import { AppPrimaryButton } from '../../../shared/components/AppComponents';

export interface ImportedNFT {
    _id: string;
    fromAddress: string;
    toAddress: string;
    quantity: string;
    txnId: string;
    status: string;
    tokenId: string;
    userId: string;
    createdAt: Date;
    updatedAt: Date;
    editionQuantity: [];
}

const ImportedNFTHistory = (): any => {
    const [importedNFT, setImportedNFT] = useState<ImportedNFT[]>([]);
    const [pagination, setPagination] = useState({ ...DefaultPagination(10) });
    const [loadingNFTs, setLoadingNFTs] = useState(true);
    const [showImportDialog, setShowImportDialog] = useState(false);

    // API Calls
    const loadImportedNFTs = (): void => {
        setLoadingNFTs(true);
        ProfileService.importedNFTs(pagination.limit, pagination.offset)
            .then((response: any) => {
                const nfts = response.data.data.report;
                const paginationResponse = response.data.data.pagination;
                setImportedNFT(nfts);
                setPagination(paginationResponse);
                setLoadingNFTs(false);
            })
            .catch((error: any) => {
                setLoadingNFTs(false);
                Utils.handleErrorResponse({
                    error,
                });
            });
    };

    // Hooks
    useEffect(() => {
        loadImportedNFTs();
    }, []);

    // Instance Methods
    const reloadNFTs = (): void => {
        pagination.offset = 1;
        loadImportedNFTs();
    };

    // Child Methods
    const onCancel = (): void => {
        setShowImportDialog(false);
    };
    const onImportSuccess = (): void => {
        setShowImportDialog(false);
        reloadNFTs();
    };

    // UI Actions
    const actionHandleLimitChange = (event: any): void => {
        const rowsPerPage = event.target.value;
        pagination.limit = parseInt(rowsPerPage, 10);
        loadImportedNFTs();
    };

    const actionHandlePageChange = (event: any, newPage: number): void => {
        pagination.offset = newPage + 1;
        loadImportedNFTs();
    };

    return (
        <>
            <Box className="profile-content">
                <Box className="card-header-btn view-profile">
                    <Typography className="title" variant="h3">
                        Import Artworks
                    </Typography>
                    <AppPrimaryButton
                        className="btn-small saveBtn import-nft-btn mt-1"
                        onClick={() => {
                            setShowImportDialog(true);
                        }}
                        title="IMPORT"
                    />
                </Box>
                <Divider className='border-content'/>
                <Box className="profile-card">
                    <Typography variant="body1" className="subtitle">
                        Import your artworks from other marketplaces here.
                    </Typography>

                    {importedNFT.length === 0 && !loadingNFTs && (
                        <EmptyPlaceholder
                            icon={<CollectionsIcon />}
                            message="You have not imported any NFTs"
                        />
                    )}
                    {loadingNFTs && <WithdrawReqSkeleton />}
                    {loadingNFTs === false && importedNFT.length > 0 && (
                        <Box mt={4}>
                            <TableContainer>
                                <Table stickyHeader className="striped-table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>From</TableCell>
                                            <TableCell>To</TableCell>
                                            <TableCell>Quantity</TableCell>
                                            {/* <TableCell>Editions</TableCell> */}
                                            <TableCell>Transaction Hash</TableCell>
                                            <TableCell>Token Id</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Imported At</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {importedNFT.map((nft) => (
                                            <TableRow key={nft.userId}>
                                                <TableCell>
                                                    {Utils.formatedAddress(
                                                        nft.fromAddress
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {Utils.formatedAddress(
                                                        nft.toAddress
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {nft.quantity}
                                                </TableCell>
                                                {/* <TableCell>
                                                    {Utils.sortEditions(
                                                        nft.editionQuantity
                                                    ).join(', ')}
                                                </TableCell> */}
                                                <TableCell>
                                                    <CopyLink
                                                        text={nft.txnId}
                                                        address
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <CopyLink
                                                        text={nft.tokenId}
                                                        address
                                                    />
                                                </TableCell>
                                                <TableCell>{nft.status}</TableCell>
                                                <TableCell>
                                                    {moment(nft.createdAt).format(
                                                        'DD MMM YYYY hh:mm A'
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                component="div"
                                count={pagination.total}
                                onPageChange={actionHandlePageChange}
                                onRowsPerPageChange={actionHandleLimitChange}
                                page={pagination.offset - 1}
                                rowsPerPage={pagination.limit}
                                rowsPerPageOptions={[5, 10, 20, 25]}
                            />
                        </Box>
                    )}
                </Box>
            </Box>

            <ImportNFTDialog
                {...{ showImportDialog, onCancel, onImportSuccess }}
            />
        </>
    );
};

export default ImportedNFTHistory;
