import { ReactElement } from 'react';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const HowItWorks = (): ReactElement => {
    const steps = [
        { title: `Registration`, text: 'Start with logging in or signing up. You may do this either through social connect or using your decentralised wallet' },
        { title: `Explore The Library`, text: 'Explore exclusively curated exhibitions and artworks by our partner curators and amazing artists respectively.' },
        { title: `Collect Your Favourite Artworks`, text: 'Choose your preferred way of payment and buy your favourite piece of artwork to own the NFT and keep them in your TUS wallet.'},
        { title: `Manage Your NFTs`, text: 'Store or manage your NFTs in the TUS wallet, or optionally transfer them out to your private wallet.' },
        { title: `Share and re-sell`, text: 'Showcase your NFTs through social media or digital screens, or list them in our secondary marketplace to re-sell them.' }
    ]

    return (
        <div className='vertical-scroll'>
            <div className="how-ItWorks-stepper-wrapper">
                {steps?.map((item, i) => (
                    <Box key={i} className='stepper-item'>
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            className='step-status-wrapper'
                            height='100%'
                            width='100%'
                        >
                            <Box
                                className='how-it-work-content'
                                width='100%'
                                height='100%'
                            >
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    ml={2}>
                                    <img style={{ width: '42px' }} alt={`step ${i}`} src={`/icons/howItWorks/step${i + 1}.svg`} />
                                    <Box ml={2}>
                                        <Typography variant='h6' className='subtitle-steps'>
                                            {item?.title}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box
                                    p={2}
                                    width='100%'
                                    minWidth='250px'
                                    height='80%'
                                >
                                    <Typography className='text-gray-desc'>
                                        {item?.text}
                                    </Typography>
                                </Box>
                            </Box>
                           {i !== 4 && <ArrowRightAltIcon fontSize='large' />}
                            {/* <Box style={{ border: '1px solid #000000', backgroundColor: '#000000', width: '2rem' }} /> */}
                        </Box>
                    </Box>
                ))}
            </div>
        </div>
    )
}

export default HowItWorks