import { ReactElement } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Box, Typography, Grid, Avatar, Divider } from '@material-ui/core';
import { Formik } from 'formik';
import { CreateAccountMetaMaskSchema } from '../../../validators/AuthenticationSchema';
import {
    AppPrimaryButton,
    AppFormikCheckBox,
    AppFormikTextField,
} from '../../../shared/components/AppComponents';
import { Utils } from '../../../shared/helpers';
import { AuthenticationService } from '../../../services';
import { useAuth } from '../hooks/UseAuth';

const SignupMetaMaskForm = (props: any): ReactElement => {
    const navigate = useNavigate();
    const { walletDetail } = props;
    const metaMaskForm = {
        email: '',
        name: '',
        policy: false,
    };
    const { processLoginResponse } = useAuth();

    // API Calls
    const metamask = (requestObj: any, callback: any): any => {
        AuthenticationService.registerWithMetamask(requestObj)
            .then((response: any) => {
                callback(true, response);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
                callback(false);
            });
    };

    // UI Actions
    const actionSignupMetaMask = (formValues: any, formik: any): any => {
        const { name, email } = formValues;
        const requestObj = {
            name,
            email,
            address: walletDetail.publicAddress,
            signature: walletDetail.signature,
        };
        metamask(requestObj, (status: boolean, response: any) => {
            formik.setSubmitting(true);
            if (status) {
                Utils.handleSuccessResponse(response);
                navigate('/login', { replace: true });
                // processLoginResponse(response);
            }
            formik.setSubmitting(false);
        });
    };

    // UI Elements
    return (
        <Box className="auth-bg meta-main-sign">
            <Box className="auth-content meta-mask-auth-content">
                <Formik
                    initialValues={metaMaskForm}
                    enableReinitialize
                    validationSchema={CreateAccountMetaMaskSchema}
                    validateOnMount
                    onSubmit={(values, formik) => {
                        actionSignupMetaMask(values, formik);
                    }}
                >
                    {({ handleSubmit, isSubmitting, isValid }) => (
                        <form autoComplete="off" className='metamask-form'>
                            <Box component="div" mt={1} className="title-content meta-content">
                                <Typography variant="h3" className="auth-main-title meta-mask-main-h3">
                                    Create an account
                                </Typography>
                                <Divider className='auth-divider' />
                            </Box>

                            <Box mt={4} className="metamask-logo">
                                <Avatar
                                    alt="MetaMask Logo"
                                    src="/images/metamask-logo.png"
                                    className="metamask-logo-image"
                                />

                                <Box className='metamask-img-icon'>
                                    <Typography variant="h5">Wallet</Typography>
                                    <Grid
                                        container
                                        spacing={0}
                                        item
                                        xs={9}
                                        sm={12}
                                        md={12}
                                    >
                                        <Typography variant="body1" noWrap>
                                            {walletDetail.publicAddress}
                                        </Typography>
                                    </Grid>
                                </Box>
                            </Box>

                            <Box component="div" pt={3} className="metamask-name-field">
                                {/* <InputLabel required>Name</InputLabel> */}
                                <AppFormikTextField name="name" type="text" placeholder="Enter Name" />
                            </Box>

                            <Box component="div" pt={3} className="metamask-email-field">
                                {/* <InputLabel required>Email</InputLabel> */}
                                <AppFormikTextField name="email" type="text" placeholder="Enter Email" className="meta-field"/>
                            </Box>

                            <Box py={1} className="align-center">
                                <AppFormikCheckBox
                                    label={
                                        <Box>
                                            <Typography variant="body1">
                                                I agree to TUS&apos;s{' '}
                                                <Typography
                                                    component={NavLink}
                                                    to="/terms"
                                                    target="_blank"
                                                    className="primary"
                                                    variant="body1"
                                                >
                                                    Terms and Condition
                                                </Typography>
                                                <span>{` & `}</span>
                                                <Typography
                                                    className="primary"
                                                    variant="body1"
                                                    component={NavLink}
                                                    to="/privacy"
                                                    target="_blank"
                                                >
                                                    Privacy Policy
                                                </Typography>
                                            </Typography>
                                        </Box>
                                    }
                                    name="policy"
                                    className=""
                                />
                            </Box>

                            <Box component="div" py={2} className="meta-account-tag">
                                <Grid container spacing={1} className="meta-account-wrapper">
                                    <Grid
                                        // item
                                        // xs={6}
                                        // sm={8}
                                        className="start-content"
                                    >
                                        <Typography
                                            className="text-secondary"
                                            component={NavLink}
                                            variant="body1"
                                            to="/login"
                                        >
                                            Already have an account?
                                            <span className="primary ml-2">
                                                Login
                                            </span>
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        // item
                                        // xs={6}
                                        // sm={4}
                                        // className="end-content"
                                        className='meta-wrapper-btn'
                                    >
                                        <AppPrimaryButton

                                        className={isValid ? 'meta-button' : ''}                                      
                                            title="SIGN UP"
                                            isLoading={isSubmitting}
                                            loadingText="SIGNING UP"
                                            disabled={isSubmitting || !isValid}
                                            onClick={handleSubmit}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

export default SignupMetaMaskForm;
