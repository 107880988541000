import {
    Typography,
    Box,
    Slider,
    IconButton,
} from '@material-ui/core';
import debounce from 'lodash.debounce';
import { FilterList, Close } from '@material-ui/icons';
import { ReactElement, useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { MarketPlaceActions } from '../../../state/marketplace/Action';
import { AppState } from '../../../state/RootReducer';
import { Toast, Utils } from '../../../shared/helpers';
import {
    AppCheckBox, AppSecondaryButton,
} from '../../../shared/components/AppComponents';
import ExhibitionFilter from './ExhibitionFilter';
import { ApplicationActions } from '../../../state/application/Action';
import { CollectionService } from '../../../services';
import { Category } from '../../../state/application/Reducer';
import { MarketPlaceFilterState } from '../../../state/marketplace/Reducer';
import CelebrityList from './CelebrityList';
import ReginFilter from './ReginFilter';
import AssetTypeFilter from './AssetTypeFilter';
import EditionsFilter from './EditionsFilter';
import PriceFilterInput from './PriceFilterInput';

interface FilterProps {
    marketPlaceFilter: MarketPlaceFilterState | any;
    setPrice: any;
    setFilter: any;
    resetAllFilter: any;
    categories: Category[];
    setCategories: any;
    marketFilterList: any;
}
const NftFilters = (props: any): ReactElement => {
    const {
        marketPlaceFilter,
        setPrice,
        setFilter,
        marketFilterList,
        // resetAllFilter,
        // categories,
        setCategories,
    }: FilterProps = props;

    
    const [warningMessage, setWarningMessage] = useState({message:'',type:''});
    const [validRange, setValidRange] = useState(false);
    // const [maxPrice, setMaxPrice] = useState('');

    // const valuetext = (value: number):any => {
    //     console.log("value",value);
    //     return value
    // }

    // API Calls
    const loadCategories = (): void => {
        CollectionService.getAllCategory()
            .then((result: any) => {
                const res = result.data.data;
                setCategories(res);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
            });
    };

    // const actionOnReset = (): void => {
    //     setMinPrice('');
    //     setMaxPrice('');
    //     resetAllFilter();
    // };

    // Hooks
    useEffect(() => {
        // loadCategories();
        // actionOnReset()
    }, []);

    // UI Actions
    // const actionApplyFilter = (): void => {
    //     if (minPrice === '' || maxPrice === '') {
    //         Toast.showErrorMessage('Please enter min and max price');
    //     } else if (
    //         Number(minPrice) !== 0 &&
    //         Number(minPrice) > Number(maxPrice)
    //     ) {
    //         Toast.showErrorMessage('Max price value should be greater');
    //     } else {
    //         setPrice({ minPrice, maxPrice });
    //     }
    // };


    // UI Elements
    const StatusFilter = ({
        label,
        valueKey,
    }: {
        label: string;
        valueKey: string;
    }): ReactElement => (
        <AppCheckBox
            label={label}
            value={marketPlaceFilter[valueKey]}
            onChange={(status: boolean): void => {
                setFilter({
                    ...marketPlaceFilter,
                    [valueKey]: status,
                });
            }}
        />
    );
    const BlockChainFilter = ({
        label,
        value,
    }: {
        label: string;
        value: string;
    }): ReactElement => (
        <AppCheckBox
            label={label}
            value={marketPlaceFilter.blockChain.indexOf(value) > -1}
            onChange={(status: boolean): void => {
                const { blockChain } = marketPlaceFilter;
                const index = blockChain.indexOf(value);
                if (status) {
                    blockChain.push(value);
                } else {
                    blockChain.splice(index, 1);
                }
                setFilter({
                    ...marketPlaceFilter,
                    blockChain: [...blockChain],
                });
            }}
        />
    );

    const artworkTypeChangeHandler = (event: any): void => {
        setFilter({
            ...marketPlaceFilter,
            physicalNFT: event.target.value
        })
    }

    const TypeFilter = ({
        label,
        value,
    }: {
        label: string;
        value: string;
    }): ReactElement => (
        <AppCheckBox
            label={label}
            // value={marketPlaceFilter.physicalNFT}
            onChange={(status: boolean): void => {
                if (marketPlaceFilter.physicalNFT === 'all') {
                    setFilter({
                        ...marketPlaceFilter,
                        physicalNFT: value,
                    });
                } else {
                    setFilter({
                        ...marketPlaceFilter,
                        physicalNFT: 'all',
                    });
                }
            }}
        />
    );


    const NsfwFilter = ({
        label,
        value,
    }: {
        label: string;
        value: string;
    }): ReactElement => (
        <AppCheckBox
            label={label}
            // value={marketPlaceFilter.physicalNFT}
            onChange={(status: boolean): void => {
                console.log("statusss: ", status)
                if (marketPlaceFilter.nsfw === 'true') {
                    setFilter({
                        ...marketPlaceFilter,
                        nsfw: 'false',
                    });
                } else {
                    setFilter({
                        ...marketPlaceFilter,
                        nsfw: 'true',
                    });
                }
            }}
        />
    );


    const CuratorsPickFilter = ({
          label,
          value,
      }: {
          label: string;
          value: string;
      }): ReactElement => (
          <AppCheckBox
              label={label}
              // value={marketPlaceFilter.curatorsPick}
              onChange={(status: boolean): void => {
                  if (marketPlaceFilter.curatorsPick === 'true') {
                      setFilter({
                          ...marketPlaceFilter,
                          curatorsPick: 'false',
                      });
                  } else {
                      setFilter({
                          ...marketPlaceFilter,
                          curatorsPick: 'true',
                      });
                  }
              }}
          />
      );


    // const CategoryFilter = ({
    //     label,
    //     name,
    //     value,
    // }: {
    //     label: string;
    //     value: string;
    //     name: string;
    // }): ReactElement => (
    //     <AppThemeRadioInput
    //         label={label}
    //         value={name}
    //         selectedValue={value}
    //         onChange={(status: boolean): void => {
    //             setFilter({
    //                 ...marketPlaceFilter,
    //                 category: status,
    //             });
    //         }}
    //     />
    // );

    const openFilter = (): any => {
        const appEl: HTMLDivElement = document.getElementsByClassName(
            'app-body'
        )[0] as HTMLDivElement;
        const el = document.getElementsByClassName('filter-container')[0];
        appEl.style.zIndex = '1000';
        el.classList.add('open');
    };

    const closeFilter = (): any => {
        const appEl: HTMLDivElement = document.getElementsByClassName(
            'app-body'
        )[0] as HTMLDivElement;
        const el = document.getElementsByClassName('filter-container')[0];
        appEl.style.zIndex = '0';
        el.classList.remove('open');
    };

    const hasStatus = (): any => {
        const f = marketPlaceFilter;
        return f.buy || f.newItem || f.onAuction || f.hasOffer;
    };

    const hasPrice = (): any =>
        Number(marketPlaceFilter.maxPrice) > 0 ||
        Number(marketPlaceFilter.minPrice) > 0;
    const hasCategory = (): any => marketPlaceFilter.category !== 'All NFTs';
    const hasCeleb = (): any => marketPlaceFilter.celebrities.length > 0;
    const hasType = (): any => marketPlaceFilter.crypto;

    // mui slider
     const [value, setValue] = useState<number[]>([marketPlaceFilter.minPrice, marketPlaceFilter.maxPrice]);

     const priceInputChangeHandler = (num:string, filter:string): void => {
        if(filter === 'min'){
                if(Number(num)<=value[1] && Number(num) >= marketPlaceFilter.absMin)setValidRange(true);
                if(Number(num) < marketPlaceFilter.absMin){
                    setValidRange(false);
                    setWarningMessage({message:`Minimum price should not be less then ${marketPlaceFilter.absMin}`,type:'min'})
                }else if(Number(num) > marketPlaceFilter.absMax){
                    setValidRange(false);
                    setWarningMessage({message:`Minimum price should not be greater then ${marketPlaceFilter.absMax}`,type:'min'})
                }else if(Number(num) > value[1]) {
                    setValidRange(false);
                    setWarningMessage({message:`Minimum price should not be greater then ${value[1]}`,type:'min'})
                }else if(value[1] > marketPlaceFilter.absMax) {
                    setValidRange(false);
                    setWarningMessage({message: `Maximun price should not be greater than ${marketPlaceFilter.absMax}`, type:'max'})
                }else {   
                    setWarningMessage({message:``,type:''})
                }
                
                setValue([Number(num),value[1]]);
        }else if (filter === 'max'){
                if(Number(num)>=value[0] && Number(num)<=marketPlaceFilter.absMax)setValidRange(true);
                if(Number(num) > marketPlaceFilter.absMax){
                    setValidRange(false);
                    setWarningMessage({message:`Maximum price should not be greater then ${marketPlaceFilter.absMax}`,type:'max'})
                }else if(Number(num) < marketPlaceFilter.absMin ){
                    setValidRange(false);
                    setWarningMessage({message:`Maximum price should not be less then ${marketPlaceFilter.absMin}`,type:'max'})
                }else if(Number(num) < value[0]) {
                    setValidRange(false);
                    setWarningMessage({message:`Maximum price should not be less then ${value[0]}`,type:'max'})
                }else if(value[0] < marketPlaceFilter.absMin){
                    setValidRange(false);
                    setWarningMessage({message: `Minimum price should not be less than ${marketPlaceFilter.absMin}`, type:'min'})
                }else{
                    
                    setWarningMessage({message:``,type:''})
                }
  
                setValue([value[0],Number(num)]);
        }
    }



    const debouncedCall = useCallback(
        debounce((priceRange: any) => {
            setPrice(priceRange);
            // resetPagination(search);
        }, 500),
        []
    );

    useEffect(()=>{
        if(validRange){
            debouncedCall({ ...marketPlaceFilter, minPrice:value[0], maxPrice:value[1]});
        }
        
    },[value])

    const handleChange = (event: any, newValue: number | number[]): void => {
        // console.log(event)
        let minPrice = marketFilterList.min;
        let maxPrice = marketFilterList.max;
        setValue(newValue as number[]);
        setWarningMessage({message:``,type:''});
        (newValue as number[]).forEach((price, index) => {
            if (index === 0) {
                minPrice = price
            }
            if (index === 1) {
                maxPrice = price
            }
        });


        // const newarr = newValue[0];
        // console.log("newValue: ", newarr);
        // const minPrice: any = Number(newValue[0]);
        // const maxPrice: any = Number(newValue[1]);
        debouncedCall({ ...marketPlaceFilter, minPrice, maxPrice });

    };

    // mui select
    // const [age, setAge] = useState('');
    // const handleChange2 = (event: any): any => {
    //     setAge(event.target.value);
    // };

    useEffect(()=> {
        if(marketPlaceFilter.minPrice === marketPlaceFilter.absMin && marketPlaceFilter.maxPrice === marketPlaceFilter.absMax){
            setValue([marketFilterList.min, marketFilterList.max]);
            setWarningMessage({message:``,type:''});
        }

    },[marketPlaceFilter.minPrice, marketPlaceFilter.maxPrice]);

    // useEffect(() => {
    //     console.log('updated');
    //     setValue([marketPlaceFilter.minPrice, marketPlaceFilter.maxPrice] as number[]);
    // }, [marketPlaceFilter.minPrice, marketPlaceFilter.maxPrice])


    return (
        <Box className="filter-container">
            <Typography
                className="filter-margin filter-margin-icon heading mobile top"
                variant="h4"
                onClick={openFilter}
            >
                <FilterList color="primary" />
                FILTER
                {(hasStatus() ||
                    hasPrice() ||
                    hasCategory() ||
                    hasCeleb() ||
                    hasType()) && <bdi />}
            </Typography>
            <Box width='100%' className="mobile-filter">
                <Box className='mobile-filter-wrapper'>
                    <Box
                        className='hide-close-btn-if-not-mobile'
                        width='90%'
                        mb={2}
                    >
                        <div>
                            <IconButton size='small' style={{ background: '#000', color: '#fff' }} onClick={closeFilter}>
                                <Close />
                            </IconButton>
                        </div>
                    </Box>
                    <CelebrityList {...props} />
                    <Box p={2} mb={2}>
                        <AppSecondaryButton
                            fullWidth
                            title="Apply"
                            className="btn-small mobile apply-btn"
                            onClick={closeFilter}
                        />
                    </Box>
                    <Box className='price-wrapper'>
                        <Box className='price-box'>
                            <Typography className="filter-margin">
                                {/* Price{hasPrice() && <> {" "}({marketPlaceFilter.minPrice} USD - {marketPlaceFilter.maxPrice} USD )</>} */}
                                Price (USD)
                                <Box className='filter-input'>
                                    {value && warningMessage && <PriceFilterInput type="min" warningMessage={warningMessage} filter="price" price={value[0]} onChangeHandler={priceInputChangeHandler}/> }
                                    {value && warningMessage && <PriceFilterInput type="max" warningMessage={warningMessage}  filter="price" price={value[1]} onChangeHandler={priceInputChangeHandler}/> }
                                    {warningMessage && warningMessage?.message.length > 0 && <p className='price-filter-message'>{warningMessage.message}</p>}
                                </Box>
                              

                            </Typography>
                            <Box sx={{ width: 300 }} className="price-slider">
                                <Slider
                                    // aria-label="Always visible"
                                    className='price-slider-design'
                                    getAriaLabel={() => 'Price range'}
                                    value={value}
                                    onChange={handleChange}
                                    valueLabelDisplay="auto"
                                    // step={10}
                                    // getAriaValueText={20}
                                    min={marketFilterList.min}
                                    max={marketFilterList.max}
                                // getAriaValueText={valuetext}
                                />
                            </Box>
                        </Box>
                    </Box>

                    <Box className='status-wrapper'>
                        <Box >      {/* expandIcon={<ExpandMoreIcon />} */}
                            <Typography className="filter-margin">
                                Status{hasStatus() && <bdi />}
                            </Typography>

                        </Box>

                        <Box className="filter-margin filter-content">
                            <Box>
                                <Box className={`buy-now class ${marketPlaceFilter.buy ? 'status-selected' : ''}`}>
                                    <StatusFilter valueKey="buy" label="Available for sale" />
                                </Box>
                                <Box className={`buy-now class ${marketPlaceFilter.notLaunched ? 'status-selected' : ''}`}>
                                    <StatusFilter
                                        valueKey="notLaunched"
                                        label="Upcoming"
                                    />
                                </Box>
                                {/* <Box className={`buy-now class ${marketPlaceFilter.allArts ? 'status-selected' : ''}`}>
                                    <StatusFilter valueKey="allArts" label="All Arts" />
                                </Box> */}
                            </Box>
                        </Box>
                    </Box>
                    <Box className='region-wrapper region-wrapper-py'>
                        <Typography className="filter-margin">
                            Artworks Type
                        </Typography>
                        <Box className="filter-margin filter-content">
                            <Box className={`buy-now class ${marketPlaceFilter.nsfw === 'true' ? 'status-selected' : ''}`}>
                                <NsfwFilter value={marketPlaceFilter.nsfw} label="NSFW Art" />
                            </Box>
                        </Box>
                        <Box className="filter-margin filter-content">
                            <Box className={`buy-now class ${marketPlaceFilter.physicalNFT === 'true' ? 'status-selected' : ''}`}>
                                <TypeFilter value="true" label="NFT with Physical Artwork" />
                            </Box>
                        </Box>
                        <Box className="filter-margin filter-content">
                            <Box className={`buy-now class ${marketPlaceFilter.curatorsPick === 'true' ? 'status-selected' : ''}`}>
                                <CuratorsPickFilter value="true" label="Curator's Pick" />
                            </Box>
                        </Box>
                    </Box>
                    <EditionsFilter />
                    <AssetTypeFilter/>
                    <ReginFilter />
                    <ExhibitionFilter />
                </Box>
            </Box>
        </Box>
    );
};

const mapStateToProps = (state: AppState): any => ({
    marketPlaceFilter: state.marketPlaceFilter,
    categories: state.applicationState.categories,
    marketFilterList: state.MarketFilterList,
});

const mapDispatchToProps = (dispatch: any): any => ({
    setPrice: (data: any) => dispatch(MarketPlaceActions.setPrice(data)),
    setFilter: (data: any) => dispatch(MarketPlaceActions.setFilter(data)),
    clearFilter: (data: any) => dispatch(MarketPlaceActions.clearFilter(data)),
    resetAllFilter: (data: any) =>
        dispatch(MarketPlaceActions.resetAllFilter(data)),
    setCategories: (data: any) =>
        dispatch(ApplicationActions.setCategoriesList(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NftFilters);
