import { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Typography,
    Grid,
    Container,
    Card,
    CardContent,
    Box,
    TextField,
    IconButton,
    Divider,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ClearIcon from '@material-ui/icons/Clear';
import HeadSection from './HeadSection';

const FAQPage = (): ReactElement => {
    const { slug } = useParams();
    const [currentSection, setSection] = useState(slug);
    const faqContent = [
        {
            section: 'general-information',
            question: 'What is TheUpsideSpace?',
            answer: (
                <>
                    <Typography className="justify-text" variant="h5">
                        TheUpsideSpace is a curator-led digital arts platform
                        that spotlights artistic expressions from Southeast
                        Asia, South Asia and the Middle East. Our mission is to
                        build a thriving *creative* ecosystem of art
                        enthusiasts, new audiences, existing collectors and
                        artists from untapped geographies in one place. NFTs and
                        blockchain are the tools of our vision: Curating
                        Limitless Possibilities.
                    </Typography>
                    <br />
                    <Typography className="justify-text" variant="h5">
                        TUS was created to be more than an NFT marketplace.
                        TUS’s purpose comes to life as an online art lab, a
                        creator studio, and an inclusive community for discovery
                        and discourse that celebrates both uniqueness and
                        diversity.
                    </Typography>
                    <br />
                    <Typography className="justify-text" variant="h5">
                        We put creativity at the center, artists at the helm and
                        an innovative tech-culture as the means to unleash
                        change by overcoming physical boundaries and unlocking
                        value while leaving no one behind.
                    </Typography>
                    <br />
                    <Typography className="justify-text" variant="h5">
                        A new age of art and artistic expression are leading the
                        way . Welcome to TheUpsideSpace.
                    </Typography>
                    <br />
                </>
            ),
        },
        {
            section: 'general-information',
            question: 'Why is it Curator-led?',
            answer: (
                <Typography className="justify-text" variant="h5">
                    We believe collecting art should be simple, joy driven and
                    impactful. Curators select, organise and present artwork to
                    reflect a theme or perspective. Skillful curation
                    communicates meaningful stories. Stories are what connect us
                    all.
                </Typography>
            ),
        },
        {
            section: 'general-information',
            question: 'Who are your Curators?',
            answer: (
                <Typography className="justify-text" variant="h5">
                    TheUpsideSpace has 4 types of curators - Independent art
                    curators, Artists as curators, Gallerists as curators and
                    Curators from sister industries such as fashion, design,
                    architecture. This offers our collectors a wide range of
                    perspectives and access to quality art while also
                    highlighingt new and exciting practices.
                </Typography>
            ),
        },
        {
            section: 'general-information',
            question: 'How are sales divided on TUS?',
            answer: (
                <>
                    <Typography className="justify-text" variant="h5">
                        Sales on TheUpsideSpace are divided as follows:
                    </Typography>
                    <br />
                    <Typography variant="h5">70% to the artist</Typography>
                    <Typography variant="h5">10% to the curator</Typography>
                    <Typography variant="h5">20% to the platform</Typography>
                </>
            ),
        },
        {
            section: 'general-information',
            question: 'What about royalties?',
            answer: (
                <Typography className="justify-text" variant="h5">
                    At TheUpsideSpace, artists receive a 10% royalty on
                    secondary sales provided the sale takes place on the
                    platform. If a work is moved out and sold on another
                    platform, TUS cannot guarantee the secondary sales
                    royalties.
                </Typography>
            ),
        },
        {
            section: 'general-information',
            question: 'Why Ethereum?',
            answer: (
                <Typography className="justify-text" variant="h5">
                    Ethereum has the best ecosystem for NFTs currently. This
                    includes artists, communities and collectors. Having
                    transitioned from Proof of Work to Proof of Stake has made
                    Ethereum much more environment friendly.
                </Typography>
            ),
        },
        {
            section: 'general-information',
            question: 'Why the option of Fiat and Crypto purchases?',
            answer: (
                <Typography className="justify-text" variant="h5">
                    We want to make the world of Web 3 accessible to everyone,
                    irrespective of where they live or how tech savvy they are.
                    Offering NFTs in both Fiat and Crypto, allows non web 3
                    natives to buy an NFT by simply using their credit card for
                    purchases. Similarly, artists can choose to participate and
                    show their work as NFTs and receive payments from sales in
                    USD.
                </Typography>
            ),
        },
        {
            section: 'general-information',
            question: 'Why do we need curation in the NFT space? ',
            answer: (
                <>
                    <Typography className="justify-text" variant="h5">
                        Rather than the endless scrolling demanded of NFT art
                        collectors today, curation helps collectors navigate the
                        art maze, while highlighting works of value and strong
                        practices.
                    </Typography>
                    <br />
                    <Typography className="justify-text" variant="h5">
                        Curators level the digital playing field for artists of
                        diverse backgrounds while also giving the audience
                        access to quality artwork. We see our curators as
                        enablers, partnering in uplifting art from under-served
                        geographies, while co-creating an exciting ecosystem
                        that unleashes value, real engagement and meaning for
                        all.
                    </Typography>
                    <br />
                </>
            ),
        },
        {
            section: 'general-information',
            question: 'Why TheUpsideSpace? ',
            answer: (
                <>
                    <Typography className="justify-text" variant="h5">
                        Because we care about every individual that makes up
                        this ecosystem. For artists, curators and collectors, we
                        handhold them through the process of being a part of
                        web3.
                    </Typography>
                    <br />
                    <Typography className="justify-text" variant="h5">
                        Artists who aren't familiar with digital tools have the
                        option of working with our digital team to create a
                        digital work.
                    </Typography>
                    <br />
                    <Typography className="justify-text" variant="h5">
                        Collectors get to view carefully curated shows and see
                        the art in context.
                    </Typography>
                    <br />
                    <Typography className="justify-text" variant="h5">
                        Both artists and collectors can navigate this space
                        without friction- needing to do so via cryptocurrency.
                    </Typography>
                    <br />
                    <Typography className="justify-text" variant="h5">
                        And for those familiar with the world of NFTs, we offer
                        you unique insights into a region via our curators and
                        the narratives they bring together.
                    </Typography>
                    <br />
                    <Typography className="justify-text" variant="h5">
                        We have a clear vision and goal- we engage with the
                        philosophy of Web3 to question hierarchies, return
                        autonomy to the artist, discover new narratives and
                        enhance the experience of being human. We don’t only
                        want to help you navigate this new space- we want to
                        combine humanity with technology to co-create a new
                        world together.
                    </Typography>
                    <br />
                    <Typography variant="h5">
                        Expression without limits
                    </Typography>
                    <Typography variant="h5">
                        Space without constraints
                    </Typography>
                    <Typography variant="h5">
                        Culture driven innovation
                    </Typography>
                    <br />
                    <Typography variant="h5">
                        Home for an emerging art evolution
                    </Typography>
                    <br />
                    <Typography variant="h5">
                        Curating Limitless Possibilities.
                    </Typography>
                    <br />
                    <Typography variant="h5">We see only an Upside.</Typography>
                    <br />
                </>
            ),
        },
        {
            section: 'general-information',
            question: 'Why buy NFTs?',
            answer: (
                <>
                    <Typography className="justify-text" variant="h5">
                        The future belongs to creators who are no longer held
                        back by mediums, methods, ideas and borders. With one
                        eye on the culture and another on the future, art NFTs
                        are changing how art has travelled through history. More
                        and more artists from all backgrounds are engaging with
                        the blockchain and digital realm. At the end of the day,
                        the spirit of creativity is aligned with technology,
                        experimentation and curiosity. NFTs are a means of
                        representing ownership of a digital asset or artwork.
                        NFTs can easily be bought and shared with the world
                        since they are primarily digital. They also help build
                        impact and goodwill in so many ways. NFTs break
                        barriers, allowing artists and collectors to reach out
                        to each other, ownership and movement of the work is
                        transparent and you can even do fun things for example
                        using NFTs you can fundraise, champion talent, build
                        your own set of patrons, etc. We are at the beginning of
                        an art revolution- at a point now where the internet was
                        in the early 90’s- and we see this as the ideal time to
                        explore and invest in NFT art.
                    </Typography>
                    <br />
                    <Typography variant="h5">the world is changing</Typography>
                    <Typography variant="h5">the form is changing</Typography>
                    <Typography variant="h5">
                        the function is changing
                    </Typography>
                    <Typography variant="h5">
                        but creative expression is abiding.
                    </Typography>
                </>
            ),
        },
        {
            section: 'general-information',
            question: ' Why the focus on SEA, SA and ME?',
            answer: (
                <Typography className="justify-text" variant="h5">
                    We come from these regions and we've grown up seeing the
                    unique voices we have in the creative community. We want the
                    stories of our regions to be heard globally. We want the
                    diversity of our languages, cultures and practices to be
                    highlighted and celebrated.
                </Typography>
            ),
        },
        {
            section: 'general-information',
            question:
                'What is an NFT beyond it being a Non Fungible Token, how should it be perceived?',
            answer: (
                <>
                    <Typography className="justify-text" variant="h5">
                        NFTs are rolls of data that cryptographically prove the
                        ownership of a digital asset that cannot be replicated
                        and are stored on a digital ledger- we have established
                        that. But it’s also important to understand that art as
                        NFTs is just a new way of exchanging a particular medium
                        of art - in this case digital art. It’s as simple as
                        that. While NFTs cover a wide range of digital assets -
                        collectibles, tickets and what not - it is very
                        important that we distinguish Art as NFTs. If you were
                        buying digital art, there is no better way to currently
                        do so than in the form of an NFT. It gives you clear
                        ownership, allows you to store, display and even resell
                        it easily. At the Upside we see NFTs as a powerful
                        catalyst and medium that fosters an engaging art
                        ecosystem for all, where the community can assign value
                        to artworks, rather than a small group of insiders. NFTs
                        allows artists and art lovers to come together
                        meaningfully in patronage rather than as mere creators
                        and consumers; sharing names in a public ledger as
                        artists and owners while collectively defining the
                        cultural value of the artworks.
                    </Typography>
                    <br />
                    <Typography className="justify-text" variant="h5">
                        Never before in the history of art have technologies
                        sparked such big changes, unifying the power of
                        creativity with the possibility of technology.
                    </Typography>
                    <br />
                    <Typography variant="h5">
                        How do I display my art NFT?
                    </Typography>
                    <br />
                    <Typography className="justify-text" variant="h5">
                        When you buy an NFT today, not only do you invest in a
                        piece of art, but you also become an influential member
                        of our ecosystem, supporting an artist’s career, so why
                        not yell it from those metaphorical rooftops? Display
                        your art on your social media, whip out your handheld
                        device to share artworks with others. NFTs can also be
                        screened on your television at home, as well as
                        displayed in specialised NFT frames like{' '}
                        <a href="https://tokenframe.com/">TokenFrame</a>,{' '}
                        <a href="https://infiniteobjects.com/">
                            Infinite Objects
                        </a>{' '}
                        , <a href="https://lagoframe.com/">Lago Frame</a> etc.
                        The possibilities keep evolving.
                    </Typography>
                </>
            ),
        },
        {
            section: 'Collector FAQs',
            question:
                'Do I need to own Cryptocurrency to buy an artwork from TUS?',

            answer: (
                <Typography variant="h5">
                    Not necessarily, on TUS You can also buy artwork in fiat
                    currency using debit or credit cards.
                </Typography>
            ),
        },
        {
            section: 'Collector FAQs',
            question: 'I like an artwork. How do I buy it?',
            answer: (
                <Typography className="justify-text" variant="h5">
                    You can press the “Buy” button to get options to buy the NFT
                    of the artwork. You can then choose either cryptocurrency or
                    fiat payment to make the payment.
                </Typography>
            ),
        },
        {
            section: 'Collector FAQs',
            question: `I don’t know how to create a wallet. Help!`,
            answer: (
                <Typography className="justify-text" variant="h5">
                    You can create a TUS wallet by simply creating an account
                    with TUS. On the other hand if you want to create a private
                    crypto wallet, you will need to use a service like{' '}
                    <a href="https://metamask.io/faqs/">Metamask</a>
                    <br />
                    If you need help, you can always email us at
                    support@theupsidespace.com
                </Typography>
            ),
        },
        {
            section: 'Collector FAQs',
            question: 'How do I access the artwork I purchased?',
            answer: (
                <Typography className="justify-text" variant="h5">
                    The artwork you buy will remain in your TUS wallet which can
                    be accessed from the “My NFTs” tab which is visible once you
                    log in. You may also optionally move the artwork from the
                    TUS wallet to your own private wallet.
                    <br />
                    If you have enabled Direct Transfer in the Profile section,
                    then your NFTs are transferred to your private wallet. You
                    can view them as per your wallet preferences.
                </Typography>
            ),
        },
        {
            section: 'Collector FAQs',
            question: 'How do I display the artwork I purchased?            ',
            answer: (
                <Typography className="justify-text" variant="h5">
                    Digital art can be displayed through digital frames
                    available in the market, which include generic TV screens to
                    specialised frames like{' '}
                    <a href="https://tokenframe.com/">TokenFrame</a>,{' '}
                    <a href="https://infiniteobjects.com/">Infinite Objects</a>{' '}
                    , <a href="https://lagoframe.com/">Lago Frame</a> etc.
                </Typography>
            ),
        },
        {
            section: 'Collector FAQs',
            question:
                'Why do some of the NFTs have physical work being offered with them?',
            answer: (
                <>
                    <Typography className="justify-text" variant="h5">
                        Physical work is being offered for free by some artists
                        for a duration of 30 days of purchase of the NFT but is
                        not linked to the NFT. Buyers pay shipping & handling
                        charges of the physical work based on the location of
                        the buyer directly to the artist .
                    </Typography>
                    <br />
                    <Typography className="justify-text" variant="h5">
                        TheUpsideSpace is not involved in this transaction.
                        Any coordination for complementary physical pieces is
                        between buyer and artist.
                    </Typography>
                    <br />
                    <Typography className="justify-text" variant="h5">
                        Please note, none of the NFTS are linked to the physical
                        work, which means if the NFT is resold, the buyer is
                        under no obligation to send the physical work along with
                        the NFT. If the buyer does not collect the physical work
                        within 30 days of the primary sale, the artist is free
                        to sell or give the physical work away to anyone else.
                    </Typography>
                </>
            ),
        },
        {
            section: 'Collector FAQs',
            question:
                'I bought a work on your platform. Where do I sell it?            ',
            answer: (
                <Typography className="justify-text" variant="h5">
                    Artworks bought in TUS can be sold in the TUS secondary
                    marketplace. Alternatively you may transfer it out of your
                    TUS wallet to your private wallet and list it in other
                    marketplaces like Opensea.
                </Typography>
            ),
        },
        {
            section: 'Collector FAQs',
            question:
                'What are the advantages of being a collector in the NFT space?',
            answer: (
                <Typography className="justify-text" variant="h5">
                    As a collector, you can access strong practices and
                    narratives from different countries. You have clear
                    ownership of the work and you can display, store or sell it
                    without the cumbersomeness of dealing with a physical piece.
                </Typography>
            ),
        },
        {
            section: 'Collector FAQs',
            question: "What do you do with an NFT after it's bought?",
            answer: (
                <Typography className="justify-text" variant="h5">
                    You can show it off on your social media to highlight an
                    artist whom you support. At home you can display it on your
                    TV screens or even custom screens. The best part of owning
                    art as NFTs is you don't have to worry about storage and if
                    you want to change your art, you don't have to bother about
                    unpacking, framing, installing etc. You can curate your art
                    to suit your mood in a matter of minutes.
                </Typography>
            ),
        },
        {
            section: 'Curator FAQs',
            question: 'How do I curate a show for TUS?',
            answer: (
                <Typography className="justify-text" variant="h5">
                    We are happy to connect with curators from the regions of
                    Southeast Asia, South Asia and the Middle East, who are
                    interested in exploring the NFT space. Please leave us an
                    email at{' '}
                    <a href="mailto:info@theupsidespace.com">
                        info@theupsidespace.com
                    </a>{' '}
                    and we will get back to you.
                </Typography>
            ),
        },
        {
            section: 'Curator FAQs',
            question: 'Who can curate for TheUpsideSpace?',
            answer: (
                <Typography variant="h5">
                    TheUpsideSpace has 4 types of curators - Independent art
                    curators, Artists as curators, Gallerists as curators and
                    Curators from sister industries such as fashion, design etc.
                    This offers our collectors a wide range of quality art that
                    highlight new and exciting practices as well
                </Typography>
            ),
        },
        {
            section: 'Artist FAQs',
            question:
                'One of my work just sold, how do I access the sales information?',
            answer: (
                <Typography variant="h5">
                    Once your artwork gets sold, you can see the amount credited
                    to your wallet which is visible when you log in. You have to
                    provide your KYC information, billing address and bank
                    account details to be able to request a withdrawal.
                </Typography>
            ),
        },
        {
            section: 'Artist FAQs',
            question: 'How to do the account verification?',
            answer: (
                <Typography variant="h5">
                    Go to Account Settings, Upload a selfie with ID in your hand
                    and upload the required documents for identity proof. Our
                    team will process the documents and update the status. Check
                    back the KYC section for status updates.{' '}
                </Typography>
            ),
        },

        {
            section: 'Artist FAQs',
            question: 'How do I get paid in Fiat?',
            answer: (
                <Typography variant="h5">
                    After the account verification, Click on the withdraw button
                    in My Wallet section.
                    <br />
                    Put the required withdrawal amount (should be higher or
                    equal to the minimum threshold). Select the FIAT bank
                    account and Click on Withdraw. Once submitted you can review
                    the existing request status in the Withdraw Request section.
                </Typography>
            ),
        },
        {
            section: 'Artist FAQs',

            question: 'How do I get paid in Crypto',
            answer: (
                <Typography variant="h5">
                    Please write us an email at payments@theupsidespace.com with
                    your private wallet address, currency and network. Our team
                    will get back to you with the withdrawal process.
                </Typography>
            ),
        },
        {
            section: 'Artist FAQs',
            question: 'How do I get paid on a secondary sale?',
            answer: (
                <Typography variant="h5">
                    Once the artwork is sold on the TUS secondary marketplace,
                    you will receive 10% royalty credited to your custodial
                    wallet. This can be withdrawn in the same manner as the
                    primary sales amount.
                </Typography>
            ),
        },
        {
            section: 'Artist FAQs',
            question: ' How do I show my work on TUS?',
            answer: (
                <Typography variant="h5">
                    We are currently only showing artists via curated
                    exhibitions. Our curators are our partners and we see them
                    as enablers, they help artists navigate the space and tell
                    their stories and they help collectors discover works of
                    value and strong practices. In the future we hope to be able
                    to have an in-house team to assist with these things and
                    then be able to have open calls for artists.
                </Typography>
            ),
        },
        {
            section: 'Artist FAQs',
            question:
                'What are the advantages of being an artist in the NFT space?',
            answer: (
                <Typography variant="h5">
                    With NFTs, you can go beyond physical boundaries and have
                    your art reach a global audience. You can track your work
                    easily and can potentially gain from every sale via in-built
                    royalties.
                </Typography>
            ),
        },
        {
            section: 'Tech FAQs',
            question: 'How do custodial wallets work? Are they secure?',
            answer: (
                <Typography variant="h5">
                    Custodial wallets are created for you programmatically by
                    TUS on signing up. These wallets are actual blockchain
                    wallets with private keys stored in our secure key vault.
                    This vault is only accessible by the production server and
                    is secured for any malicious access.
                </Typography>
            ),
        },
        {
            section: 'Tech FAQs',
            question: 'How is TUS different from Opensea in its usage?',
            answer: (
                <Typography variant="h5">
                    TUS is a custodial wallet platform which helps both crypto
                    friendly and non-crypto savvy users to easily buy and sell
                    artworks on blockchain.
                </Typography>
            ),
        },
    ];

    const sidebarMenu = [
        {
            icon: '/images/faq-general-icon.png',
            title: 'General Information',
            name: 'general-information',
        },
        {
            icon: '/images/faq-start.png',
            title: 'Curator FAQs',
            name: 'Curator FAQs',
        },
        {
            icon: '/images/faq-sell.png',
            title: 'Collector FAQs',
            name: 'Collector FAQs',
        },
        {
            icon: '/images/faq-create.png',
            title: 'Artist FAQs',
            name: 'Artist FAQs',
        },
        {
            icon: '/images/faq-partener.png',
            title: 'Tech FAQs',
            name: 'Tech FAQs',
        },
    ];

    return (
        <Container>
            <Box id="faqPage">
                <HeadSection
                    title="The FAQs"
                    subtitle="Welcome to TUS Help Desk"
                />

                {false && (
                    <Box className="query-box">
                        <TextField
                            variant="outlined"
                            size="small"
                            placeholder="Type your query here..."
                            InputProps={{
                                endAdornment: (
                                    <IconButton type="button">
                                        <ClearIcon />
                                    </IconButton>
                                ),
                            }}
                        />
                    </Box>
                )}
                <Grid container className="faq-help-box-wrapper">
                    <Grid
                        xs={12}
                        sm={4}
                        lg={3}
                        item
                        className="faq-help-box-wrapper-grid"
                    >
                        <div className="faqLeft">
                            <Card className="list">
                                <Typography
                                    variant="h4"
                                    className="faq-heading"
                                >
                                    FAQs
                                </Typography>
                                <List component="nav">
                                    {sidebarMenu.map((menu: any, i) => (
                                        <>
                                            <ListItem
                                                onClick={() =>
                                                    setSection(menu.name)
                                                }
                                                button
                                                selected={
                                                    currentSection === menu.name
                                                }
                                            >
                                                {/* <ListItemIcon>
                                                    <img
                                                        src={menu.icon}
                                                        alt=""
                                                    />
                                                </ListItemIcon> */}

                                                <ListItemText
                                                    className="faq-text"
                                                    primary={menu.title}
                                                />

                                                <ListItemIcon>
                                                    {currentSection ===
                                                        menu.name && (
                                                        <NavigateNextIcon color="primary" />
                                                    )}
                                                </ListItemIcon>
                                            </ListItem>
                                            {i !== 5 && (
                                                <Box className="divider" />
                                            )}
                                        </>
                                    ))}
                                </List>
                            </Card>
                        </div>
                    </Grid>

                    <Grid
                        xs={12}
                        sm={8}
                        lg={9}
                        item
                        className="faq-right faq-help-box-wrapper-grid"
                    >
                        {faqContent
                            .filter((item) => item.section === currentSection)
                            .map((item, index) => (
                                <>
                                    <Card
                                        className="faqContainer"
                                        key={`faq_${index}`}
                                    >
                                        <CardContent className="faq-help-card">
                                            <Typography
                                                variant="h4"
                                                className="faq-heading"
                                            >
                                                {item.question}
                                            </Typography>
                                            <Divider className="mt-3" />
                                            <Box className="faq-content" mt={3}>
                                                {item.answer}
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </>
                            ))}
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default FAQPage;
