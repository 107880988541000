import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Box, CardMedia, Fade, Grid, Tooltip, Typography, Avatar, CardActionArea } from '@material-ui/core';
import CollectionsIcon from '@material-ui/icons/Collections';
import { EmptyPlaceholder, HeadTag } from '../../../shared/components';
import { AppBigNavButton } from '../../../shared/components/AppComponents';
import { getUrl } from '../../../shared/helpers/Utils';
import ReginIconName from './regionIconName';

const Exhibitions = ({ exihibition }: any): ReactElement => {
    const title = 'About';
    const description =
        "About";
    const { active } = exihibition;

    return (
        <Box id="exhibition-wrapper" className='exhibition-wrapper-py'>
            <HeadTag title={title} description={description} />
            <Grid container className="banner-wrapper" alignItems="center">
                <Grid xs={12} id="About">
                    <Box className='about-title-box exhibition-py'>
                        <Typography className="about-title">Active Exhibitions</Typography>
                        {active?.length > 0 && active?.length > 3 && <AppBigNavButton
                            endIcon={<img src="/images/banner-right-arrow.svg" />}
                            title="View All"
                            to="/marketplace/exhibition"
                            id="hero-button"
                        />}
                    </Box>
                </Grid>
                <Box className='about-content-wrepper  flex-wrap' >
                    {active !== undefined && active && active.map((activeExhibition: any) => (
                        <>
                            <Grid key={activeExhibition} xs={12} sm={6} lg={6} className='exhibition-box-content active-wrap-img upcoming-pr'>
                                {/* eslint no-underscore-dangle: 0 */}
                                <Link to={`/exhibition/${activeExhibition._id}`} >
                                    <Box className='about-image-box exhibition'>
                                        <CardMedia
                                            component="img"
                                            height="286"
                                            image={activeExhibition?.backgroundPicture ? getUrl(`${activeExhibition.backgroundPicture}`, '1000x1000/') : '/images/if-no-image.png'} alt="backgroundImage"
                                        />
                                        <Box className='exhibition-content'>
                                            <Typography variant="h1">
                                                {activeExhibition.title ? activeExhibition.title : "No title"}
                                            </Typography>
                                            <Grid container spacing={1}>
                                                <Grid item style={{width:'100%'}} sm={12} md={12} lg={12} xl={12}>
                                                    <Box
                                                        width='100%'
                                                        display='flex'
                                                        flexDirection='row'
                                                        alignItems='center' className="collage-mobile">
                                                        <Avatar src={getUrl(activeExhibition.curator.profilePicture) ? getUrl(`${activeExhibition.curator.profilePicture}`, '400x400/') : '/icons/user.png'} />
                                                        <Box
                                                            pl={1}
                                                            width='90%'
                                                            display='flex'
                                                            flexDirection='column'
                                                            className="banner-text">
                                                            <Typography className='text-gray curator-text mb-1'>
                                                                Curated By
                                                            </Typography>
                                                            <Tooltip arrow title={activeExhibition.curator.name} TransitionComponent={Fade}
                                                                TransitionProps={{ timeout: 600 }}>
                                                                <Typography variant="body1" className='curatorName text-ellipsis--1' title={activeExhibition.curator.name} >
                                                                    {activeExhibition?.curator?.name}
                                                                </Typography>
                                                            </Tooltip>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                                <Grid item style={{width:'100%'}} sm={12} md={12} lg={12} xl={12}>
                                                    <Box height={36} className='text-ellipsis--2'>
                                                        <Typography variant="body1">
                                                            {activeExhibition?.description.replace(/<[^>]+>/g, '')}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box className="exhibition-button">
                                                <Box className='exhibition-icon exhibition-icon-grid-wrapper'>
                                                    {activeExhibition?.region && <ReginIconName name={`${activeExhibition?.region?.name}, ${activeExhibition?.region?.subRegion}`} />}
                                                </Box>
                                                <AppBigNavButton
                                                    title="View Exhibition"
                                                    to={`/exhibition/${activeExhibition._id}`}
                                                    id="hero-button"
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Link>
                            </Grid>
                        </>
                    ))}
                </Box>

                {active === undefined || active.length === 0 && (
                    <EmptyPlaceholder
                        icon={<CollectionsIcon color="primary" />}
                        message="Active Exhibitions not available"
                    />
                )}
            </Grid>
        </Box>
    )
}

export default Exhibitions