import { ReactElement } from 'react';
import { LoginForm } from './components';
import { HeadTag } from '../../shared/components';

const LoginPage = (): ReactElement => (
    <>
        <HeadTag title="Login" />
        <LoginForm />
    </>
);

export default LoginPage;
