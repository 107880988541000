import ApiManager from '../shared/managers/APIManager';
import { RestEnds } from '../shared/constants';

const register = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.REGISTER, data, true);

const getRegisterNonce = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.METAMASK_NONCE, data, true);

const registerWithMetamask = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.REGISTERIWTHMETAMASK, data, true);

const registerWithGoogle = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.REGISTERIWTHGOOGLE, data, true);

const login = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.LOGIN, data, true);

const loginWithMetamask = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.LOGINWITHMETAMASK, data, true);

const loginWithGoogle = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.LOGINWITHGOOGLE, data, true);

const resendEmail = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.RESEND_EMAIL, data, true);

const forgotPassword = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.FORGOT_PASSWORD, data, true);

const resetPassword = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.RESET_PASSWORD, data, true);

const verifyEmail = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.VERIFY_EMAIL, data, true);

const securityVerification = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.SECURITY_VERIFICATION, data, true);

const logout = async (): Promise<any> =>
    ApiManager.sendPost(RestEnds.LOGOUT, {}, true);

const subscribe = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.SUBSCRIBE, data, false);

const betaRegistration = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.BETA_REGISTER, data, false);

const betaLogin = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.BETA_LOGIN, data, false);

const kycLogin = async (): Promise<any> => 
    ApiManager.sendPost(RestEnds.KYC_LOGIN, {}, true)

export const AuthenticationService = {
    register,
    registerWithMetamask,
    registerWithGoogle,
    login,
    loginWithMetamask,
    loginWithGoogle,
    getRegisterNonce,
    resendEmail,
    forgotPassword,
    resetPassword,
    verifyEmail,
    logout,
    securityVerification,
    subscribe,
    betaLogin,
    betaRegistration,
    kycLogin
};
