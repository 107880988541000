import { ReactElement } from 'react';
import { HeadTag } from '../../shared/components';
import { MyNFTs } from './components';

const MyNFTsPage = (props: any): ReactElement => (
    <>
        <HeadTag title="My NFTs" />
        <MyNFTs {...props} />
    </>
);

export default MyNFTsPage;
