import { useEffect, useState, ReactElement } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {
    Grid,
    TextField,
    Typography,
    Avatar,
    Box,
    InputAdornment,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    CircularProgress,
    Checkbox,
    ListItemText,
    Divider,
    Container,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FormHelperText from '@material-ui/core/FormHelperText';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';
import { ItemFullDetail } from '../NFTDetailPage';
import SaleConfirmation from './SaleConfirmation';
import { AppState } from '../../../state/RootReducer';
import {
    AppPrimaryButton,
    ConfirmDialog,
} from '../../../shared/components/AppComponents';
import { Utils } from '../../../shared/helpers';
import {
    CollectionService,
    ProfileService,
    BuySellService,
} from '../../../services';
import { useDebouncedEffect } from '../../../shared/hooks';

export interface NFTDetailProps {
    ItemDetail: ItemFullDetail;
    acceptCrypto: any;
}

const NFTSellInitiate = (props: any): ReactElement => {
    const { acceptCrypto, profileInfo } = props;
    const navigate = useNavigate();
    const [sellType, setSellType] = useState(1);
    const [itemDetail, setItemDetail] = useState<ItemFullDetail>(
        {} as ItemFullDetail
    );
    const fiatMinimum = 10;
    const minimumPrice = fiatMinimum;
    const [loadingItemDetail, setLoadingItemDetail] = useState(true);
    const [qty, setQty] = useState(0);
    const [price, setPrice] = useState(minimumPrice);
    const [total, setTotal] = useState(minimumPrice);
    const [editionsList, setEditionsList] = useState<string[]>([]);
    const [editions, setEditions] = useState<string[]>([]);
    const [paymentMode, setPaymentMode] = useState('fiat');
    const [currencyType, setCurrencyType] = useState('');
    const [validForm, setValidForm] = useState(false);
    const [qtyError, setQtyError] = useState('');
    const [priceError, setPriceError] = useState('');
    const [initiatingSell, setInitiatingSell] = useState(false);
    const [withDrawAccounts, setWithDrawAccounts] = useState('');
    const [feePart, setFeePart] = useState({
        fee: 0,
        royalty: 0,
        sellerCost: 0,
        totalCost: 0,
        currencyType: '',
    });
    const [calculatingFee, setCalculatingFee] = useState(false);
    const { itemId } = useParams();
    const [expireIn, setExpireIn] = useState(1);
    const [showAccountNotFoundDialog, setShowAccountNotFoundDialog] =
        useState(false);
    const timezone = moment.tz(moment.tz.guess()).format('z');
    const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
    const days = [
        {
            value: 1,
            title: 'in 1 Days',
        },
        {
            value: 2,
            title: 'in 2 Days',
        },
        {
            value: 3,
            title: 'in 3 Days',
        },
        {
            value: 4,
            title: 'in 4 Days',
        },
        {
            value: 5,
            title: 'in 5 Days',
        },
        {
            value: 6,
            title: 'in 6 Days',
        },
        {
            value: 7,
            title: 'in 7 Days',
        },
        {
            value: 14,
            title: 'in 2 Weeks',
        },
        {
            value: 21,
            title: 'in 3 Weeks',
        },
        {
            value: 28,
            title: 'in 4 Weeks',
        },
    ];
    const [showSaleConfirmModal, setShowSaleConfirmModal] = useState(false);

    const isAllselected =
        editionsList.length > 0 && editions.length === editionsList.length;

    // API Calls
    const fetchSystemFee = (): any => {
        const data: any = {
            price,
            quantity: qty,
            itemId,
            bid: sellType !== 1,
            editions,
        };
        if (paymentMode === 'crypto' && sellType === 2) {
            data.from = 'USD';
            data.to = 'ETH';
        }
        if (sellType === 2) {
            data.paymentMode = paymentMode;
        }
        setCalculatingFee(true);
        BuySellService.getCalculation(data)
            .then((response: any) => {
                if (paymentMode === 'crypto' && sellType === 2) {
                    const { fee } = response.data.data;
                    setFeePart(fee);
                } else {
                    const fee = response.data.data;
                    setFeePart(fee);
                }
                setCalculatingFee(false);
            })
            .catch(() => {
                setCalculatingFee(false);
            });
    };

    const getItemDetail = (): any => {
        setLoadingItemDetail(true);
        CollectionService.getItemDetail(itemId)
            .then((response: any) => {
                const itemDetailResponse = response.data.data;
                setItemDetail(itemDetailResponse);
                setEditionsList([]);
                setEditions([]);
                setLoadingItemDetail(false);
                setCurrencyType(itemDetail.itemInfo.currencyType);
            })
            .catch(() => {
                setLoadingItemDetail(false);
            });
    };

    const sellItemAsFixedPrice = (): any => {
        const data = {
            itemId,
            quantity: Number(qty),
            amount: Number(total),
            pricePerQuantity: Number(price),
            acceptedPaymentMethod: paymentMode,
            editions,
        };

        setInitiatingSell(true);
        BuySellService.placeSellItem(data)
            .then((response) => {
                setInitiatingSell(false);
                Utils.handleSuccessResponse(response);
                navigate(-1);
            })
            .catch((error: any) => {
                setInitiatingSell(false);
                setShowSaleConfirmModal(false);
                Utils.handleErrorResponse({ error });
            });
    };

    const sellItemInAuction = (): any => {
        const data: any = {
            itemId,
            quantity: Number(qty),
            minimumPrice: Number(total),
            liveTill: Number(expireIn),
            pricePerQuantity: Number(price),
            acceptedPaymentMethod: paymentMode,
            editions,
        };
        setInitiatingSell(true);
        BuySellService.listForAuction(data)
            .then((response) => {
                setInitiatingSell(false);
                Utils.handleSuccessResponse(response);
                navigate(-1);
            })
            .catch((error: any) => {
                setInitiatingSell(false);
                setShowSaleConfirmModal(false);
                Utils.handleErrorResponse({ error });
            });
    };

    const loadCryptoAccounts = (): any => {
        ProfileService.getCrpytoAccountDetails()
            .then((response: any) => {
                const accounts = response.data.data.addressDetails;
                setWithDrawAccounts(accounts);
            })
            .catch((error: any) => {
                setShowSaleConfirmModal(false);
                Utils.handleErrorResponse({ error });
            });
    };

    // Instance Methods
    const setItemQty = (quantity: any): any => {
        const tempQty = parseInt(quantity, 10);
        if (!Number.isNaN(tempQty)) {
            setTotal(price * tempQty);
        } else {
            setTotal(0);
        }
        setQty(quantity);
    };

    const setItemPrice = (itemPrice: any): void => {
        const tempPrice = parseFloat(itemPrice);
        if (!Number.isNaN(tempPrice)) {
            setTotal(tempPrice * qty);
        } else {
            setTotal(0);
        }
        setPrice(itemPrice);
    };

    const validateForm = (): any => {
        let qtyErr = '';
        let priceErr = '';
        const floatRegex = /^\d+(\.\d+)?$/;

        // Editions validation
        if (editions.length === 0) {
            qtyErr = 'Please select editions to sell';
        }

        // Price validation
        if (!floatRegex.test(`${price}`)) {
            priceErr = 'Price is required';
        } else if (typeof price === 'string' && price === '') {
            priceErr = 'Price is required';
        } else if (price >= minimumPrice) {
            // console.log("minimumPrice",minimumPrice)
        } else {
            priceErr = `Minimum price should be greater than or equal to ${minimumPrice} ${currencyType}`;
        }

        setValidForm(qtyErr === '' && priceErr === '');
        setQtyError(qtyErr);
        setPriceError(priceErr);
    };

    const loadEditions = (): void => {
        const ownerInfo = itemDetail.ownedBy.find(
            (owner) => owner.id === profileInfo.userId
        );
        if (ownerInfo) {
            setEditionsList(ownerInfo.editionQuantity);
            validateForm();
        }
    };

    // Hooks
    useDebouncedEffect(
        (): void => {
            if (priceError === '' && qtyError === '') {
                fetchSystemFee();
            }
        },
        [price, qty, editions, sellType, priceError, qtyError],
        1000
    );

    useEffect(() => {
        if (itemDetail.ownerInfo) {
            loadEditions();
        }
    }, [itemDetail]);

    useEffect(() => {
        if (itemDetail.ownerInfo) {
            validateForm();
        }
    }, [price, qty, currencyType, sellType, itemDetail.ownerInfo]);

    useEffect(() => {
        getItemDetail();
        loadCryptoAccounts();
        BuySellService.loadCryptoPaymentAvailability();
    }, []);

    useEffect(() => {
        const methods = [
            {
                title: 'Fiat',
                value: 'fiat',
            },
        ];
        if (acceptCrypto) {
            methods.push({
                title: 'Crypto',
                value: 'crypto',
            });
            methods.push({
                title: 'All',
                value: 'all',
            });
        }
        setPaymentMethods(methods);
    }, [acceptCrypto, sellType]);

    // UI Actions
    const actionPlaceSellList = (): void => {
        if (
            paymentMode === 'fiat' ||
            ((paymentMode === 'crypto' || paymentMode === 'all') &&
                withDrawAccounts.length > 0)
        ) {
            if (sellType === 1) {
                sellItemAsFixedPrice();
            } else {
                sellItemInAuction();
            }
        } else {
            setShowAccountNotFoundDialog(true);
        }
    };

    const onPaymentModeChange = (event: any): void => {
        const mode = event.target.value;
        setPaymentMode(mode);
    };

    // UI Elements
    const AccountNotFoundDialog = (
        <ConfirmDialog
            title="Add crypto account"
            subTitle="Please add crypto account to receive crypto in profile"
            cancelTitle="CANCEL"
            successTitle="ADD NOW"
            isPositiveConfirm
            showConfirm={showAccountNotFoundDialog}
            onCancel={() => {
                setShowAccountNotFoundDialog(false);
            }}
            onSuccess={() => {
                navigate('/profile/view');
            }}
        />
    );

    return (
        <>
            {!loadingItemDetail && (
                <Box id="item-sell-initiate-container">
                    <Container>
                        <Box className="back-header">
                            <Typography
                                component={NavLink}
                                to=""
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                <ArrowBackIosIcon />
                                <Box className="avatar-cont">
                                    <Avatar
                                        src={itemDetail.itemInfo.thumbnail}
                                        variant="square"
                                    />
                                </Box>
                                <Box className="names">
                                    <Typography className="subtitle">
                                        {itemDetail.collectionInfo.name}
                                    </Typography>
                                    <Box mt={1}>
                                        <Typography
                                            variant="h4"
                                            className="title"
                                        >
                                            {itemDetail.itemInfo.name}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Typography>
                        </Box>

                        <Divider />

                        {/* Sell Options */}
                        <Box className="sell-options">
                            <Box
                                className={
                                    sellType === 1
                                        ? 'sell-option active-option'
                                        : 'sell-option'
                                }
                                onClick={() => {
                                    setSellType(1);
                                }}
                            >
                                <Typography variant="h4">Set Price</Typography>
                                <Typography className="mt-2" variant="body1">
                                    Sell at a fixed price
                                </Typography>
                            </Box>
                            <Box
                                className={
                                    sellType === 2
                                        ? 'sell-option active-option'
                                        : 'sell-option'
                                }
                                onClick={() => {
                                    setSellType(2);
                                }}
                            >
                                <Typography variant="h4">
                                    Highest Bid
                                </Typography>
                                <Typography className="mt-2" variant="body1">
                                    Auction to the highest bidder
                                </Typography>
                            </Box>
                        </Box>

                        <Grid container xs={12} spacing={4}>
                            <Grid item lg={8} md={7} xs={12}>
                                <Box id="sell-border-container">
                                    <Box m={2}>
                                        <Typography variant="h4">
                                            {sellType === 1
                                                ? 'Sell at a fixed price'
                                                : 'Auction to highest bidder'}
                                        </Typography>
                                    </Box>

                                    <Grid container className="left-tile">
                                        <Grid item xs={8}>
                                            <Typography className="title-wrapper">
                                                NFT Editions
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className="gray-dark"
                                            >
                                                Select Editions you wants to
                                                sell.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                            >
                                                <InputLabel>
                                                    Editions
                                                </InputLabel>
                                                <Select
                                                    value={editions}
                                                    onChange={(event: any) => {
                                                        const selectedEditions: string[] =
                                                            event.target.value;
                                                        if (
                                                            selectedEditions[
                                                                selectedEditions.length -
                                                                    1
                                                            ] === 'all'
                                                        ) {
                                                            setEditions(
                                                                editions.length ===
                                                                    editionsList.length
                                                                    ? []
                                                                    : editionsList
                                                            );
                                                            setItemQty(
                                                                editions.length ===
                                                                    editionsList.length
                                                                    ? 0
                                                                    : editionsList.length
                                                            );

                                                            return;
                                                        }

                                                        setEditions(
                                                            Utils.sortEditions(
                                                                selectedEditions
                                                            )
                                                        );
                                                        setItemQty(
                                                            selectedEditions.length
                                                        );
                                                    }}
                                                    label="Editions"
                                                    multiple
                                                    renderValue={() =>
                                                        editions.join(', ')
                                                    }
                                                >
                                                    <MenuItem value="all">
                                                        <Checkbox
                                                            checked={
                                                                isAllselected
                                                            }
                                                        />
                                                        <ListItemText primary="Select All" />
                                                    </MenuItem>
                                                    {editionsList.map(
                                                        (value, index): any => (
                                                            <MenuItem
                                                                key={`editions_${index}`}
                                                                value={value}
                                                            >
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={
                                                                        editions.indexOf(
                                                                            value
                                                                        ) > -1
                                                                    }
                                                                />
                                                                <ListItemText
                                                                    primary={
                                                                        value
                                                                    }
                                                                />
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>

                                                <FormHelperText className="Mui-error">
                                                    {qtyError}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Grid container className="left-tile">
                                        <Grid item xs={8}>
                                            <Typography className="title-wrapper">
                                                Accept Payment
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className="gray-dark"
                                            >
                                                Select the payment mode which
                                                you want to sell your NFT.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                            >
                                                <InputLabel>
                                                    Payment Mode
                                                </InputLabel>
                                                <Select
                                                    value={paymentMode}
                                                    onChange={(event: any) => {
                                                        onPaymentModeChange(
                                                            event
                                                        );
                                                    }}
                                                    label="Payment mode"
                                                >
                                                    {paymentMethods.map(
                                                        (
                                                            method,
                                                            index
                                                        ): any => (
                                                            <MenuItem
                                                                key={`payment_${index}`}
                                                                value={
                                                                    method.value
                                                                }
                                                            >
                                                                {method.title}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Grid container className="left-tile">
                                        <Grid item xs={8}>
                                            <Typography className="title-wrapper">
                                                Quantity
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className="gray-dark"
                                            >
                                                Number of items to sell.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                type="number"
                                                value={qty}
                                                inputProps={{ readOnly: true }}
                                                onChange={(e: any) =>
                                                    setItemQty(e.target.value)
                                                }
                                            />
                                        </Grid>
                                    </Grid>

                                    {/* Fixed price sell */}
                                    {sellType === 1 ? (
                                        <>
                                            <Grid
                                                container
                                                xs={12}
                                                className="left-tile"
                                            >
                                                <Grid item xs={8}>
                                                    <span>
                                                        <Typography className="title-wrapper">
                                                            Price per item{' '}
                                                            <small>
                                                                (Minimum Price
                                                                should be{' '}
                                                                {minimumPrice}{' '}
                                                                {currencyType})
                                                            </small>
                                                        </Typography>
                                                    </span>
                                                    <Typography
                                                        variant="body1"
                                                        className="gray-dark"
                                                    >
                                                        Will be on sale until
                                                        you transfer this item
                                                        or cancel it.
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        type="number"
                                                        value={price}
                                                        onChange={(e: any) =>
                                                            setItemPrice(
                                                                e.target.value
                                                            )
                                                        }
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    {
                                                                        currencyType
                                                                    }
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                    <FormHelperText className="Mui-error">
                                                        {priceError}
                                                    </FormHelperText>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                container
                                                xs={12}
                                                className="left-tile"
                                            >
                                                <Grid item xs={8}>
                                                    <Typography className="title-wrapper">
                                                        Total Price
                                                    </Typography>
                                                    <Typography
                                                        variant="body1"
                                                        className="gray-dark"
                                                    >
                                                        The total amount that
                                                        will be transferred when
                                                        the sale happens.
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        disabled
                                                        fullWidth
                                                        margin="normal"
                                                        variant="outlined"
                                                        value={total}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    {
                                                                        currencyType
                                                                    }
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>
                                    ) : (
                                        <>
                                            <Grid
                                                container
                                                className="left-tile"
                                            >
                                                <Grid item xs={8}>
                                                    <Typography className="title-wrapper">
                                                        Minimum Bid
                                                    </Typography>
                                                    <Typography
                                                        variant="body1"
                                                        className="gray-dark"
                                                    >
                                                        Set your starting bid
                                                        price
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        type="number"
                                                        value={price}
                                                        onChange={(e: any) =>
                                                            setItemPrice(
                                                                e.target.value
                                                            )
                                                        }
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    {
                                                                        itemDetail
                                                                            .itemInfo
                                                                            .currencyType
                                                                    }
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                    <FormHelperText className="Mui-error">
                                                        {priceError}
                                                    </FormHelperText>
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                container
                                                className="left-tile"
                                            >
                                                <Grid item xs={8}>
                                                    <Typography className="title-wrapper">
                                                        Expiration Date
                                                    </Typography>
                                                    <Typography
                                                        variant="body1"
                                                        className="gray-dark"
                                                    >
                                                        Your auction will
                                                        automatically end at
                                                        this time and the
                                                        highest bidder will win.
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControl
                                                        fullWidth
                                                        variant="outlined"
                                                    >
                                                        <InputLabel>
                                                            Expire Time
                                                        </InputLabel>
                                                        <Select
                                                            value={expireIn}
                                                            onChange={(
                                                                event: any
                                                            ) => {
                                                                setExpireIn(
                                                                    parseInt(
                                                                        `${event.target.value}`,
                                                                        10
                                                                    )
                                                                );
                                                            }}
                                                            label="Expire Time"
                                                        >
                                                            {days.map(
                                                                (
                                                                    day: any,
                                                                    index: number
                                                                ): any => (
                                                                    <MenuItem
                                                                        key={`day_${index}`}
                                                                        value={
                                                                            day.value
                                                                        }
                                                                    >
                                                                        {
                                                                            day.title
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}
                                </Box>
                            </Grid>

                            {/* Sell Summary */}
                            <Grid item lg={4} md={5} xs={12}>
                                <Box id="sell-border-container">
                                    <Box m={2}>
                                        <Typography variant="h4">
                                            Summary
                                        </Typography>
                                    </Box>
                                    <Divider />
                                    {calculatingFee && (
                                        <Box
                                            p={10}
                                            m={10}
                                            display="flex"
                                            justifyContent="center"
                                        >
                                            <CircularProgress />
                                        </Box>
                                    )}
                                    {!calculatingFee && (
                                        <Box p={4}>
                                            {sellType === 2 && (
                                                <Box mb={4}>
                                                    <Typography variant="body1">
                                                        {`Your item will be auctioned. The highest bidder will win it on 
                                                    ${moment()
                                                        .add(expireIn, 'day')
                                                        .format(
                                                            'DD MMM yyyy hh:mm A'
                                                        )}
                                                    ${timezone}`}
                                                    </Typography>
                                                </Box>
                                            )}
                                            <AppPrimaryButton
                                                isLoading={initiatingSell}
                                                className="btn-small mb-4"
                                                loadingText="PLACING"
                                                disabled={!validForm}
                                                onClick={() =>
                                                    setShowSaleConfirmModal(
                                                        true
                                                    )
                                                }
                                                title="PLACE YOUR LISTING"
                                            />
                                            <Grid container spacing={4}>
                                                <Grid item xs={6}>
                                                    <Typography>Fee</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography align="right">
                                                        {feePart.fee}{' '}
                                                        {feePart.currencyType}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography>
                                                        Royalty
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography align="right">
                                                        {feePart.royalty}{' '}
                                                        {feePart.currencyType}
                                                    </Typography>
                                                </Grid>
                                                {/* Fixed price selling */}
                                                {sellType === 1 && (
                                                    <>
                                                        <Grid item xs={6}>
                                                            <Typography>
                                                                Seller Cost
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography align="right">
                                                                {
                                                                    feePart.sellerCost
                                                                }{' '}
                                                                {
                                                                    feePart.currencyType
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    </>
                                                )}
                                            </Grid>
                                        </Box>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            )}
            {loadingItemDetail && (
                <Box className="empty-content">
                    <CircularProgress />
                </Box>
            )}
            {AccountNotFoundDialog}
            {showSaleConfirmModal && (
                <SaleConfirmation
                    open={showSaleConfirmModal}
                    onClose={() => setShowSaleConfirmModal(false)}
                    itemDetail={itemDetail}
                    quantity={qty}
                    price={price}
                    total={total}
                    sellType={sellType}
                    feePart={feePart}
                    expireIn={expireIn}
                    actionPlaceSellList={actionPlaceSellList}
                    isLoading={initiatingSell}
                    timezone={timezone}
                    editions={editions}
                />
            )}
        </>
    );
};

const mapStateToProps = (state: AppState): any => ({
    isUserLoggedIn: state.authState.isUserLoggedIn,
    profileInfo: state.profileInfo,
    acceptCrypto: state.applicationState.acceptCrypto,
});

export default connect(mapStateToProps, null)(NFTSellInitiate);
