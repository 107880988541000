import { HeadTag } from '../../shared/components';
import ImportedNFTHistory from './components/ImportedNFTHistory';

const ImportNFTPage = (): any => (
    <>
        <HeadTag title="Import Artwork List" />
        <ImportedNFTHistory />
    </>
);

export default ImportNFTPage;
