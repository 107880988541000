import { ReactElement, useState } from 'react';
import {
    Box,
    Typography,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Divider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Formik } from 'formik';
import {
    AppFormikTextArea,
    AppThemeRadioInput,
    AppPrimaryButton,
} from '../../../shared/components/AppComponents';
import { Utils } from '../../../shared/helpers';
import { CollectionService } from '../../../services';
import { ReportValidation } from '../../../validators/Creation';

interface ReportNFT {
    itemId: string;
    showReport: boolean;
    onCloseReport: any;
}

const ReportNFT = (props: ReportNFT): ReactElement => {
    const { showReport, onCloseReport, itemId } = props;
    const [selectedValue, setSelectedValue] = useState('');
    const reportList = [
        {
            title: 'Offensive content',
            value: 'Offensive content',
        },
        {
            title: 'Fraud',
            value: 'Fraud',
        },
        {
            title: 'Duplicate NFT',
            value: 'Duplicate NFT',
        },
        {
            title: 'Product already sold',
            value: 'Product already sold',
        },
        {
            title: 'Other',
            value: 'Other',
        },
    ];
    const reportForm = {
        comment: '',
    };

    // API Calls
    const create = (data: any, callback: any): void => {
        CollectionService.createReport(data)
            .then((response: any) => {
                Utils.handleSuccessResponse(response);
                callback(true);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({
                    error,
                });
                callback(false);
            });
    };

    const actionOnFormSubmit = (formValues: any, formik: any): void => {
        const { comment } = formValues;
        const requestObj = {
            itemId,
            category: selectedValue,
            comment,
        };
        formik.setSubmitting(true);
        create(requestObj, (status: boolean) => {
            if (status) {
                formik.setSubmitting(false);
                formik.resetForm();
                setSelectedValue('');
                onCloseReport();
            }
        });
    };

    // UI Elements
    return (
        <Dialog
            open={showReport}
            onClose={onCloseReport}
            fullWidth
            maxWidth="xs"
            disableEscapeKeyDown
        >
            <DialogTitle>
                <Box className="title-section">
                    <Typography variant="h4">Report NFT</Typography>
                    <IconButton onClick={onCloseReport}>
                        <CloseIcon color="primary" />
                    </IconButton>
                </Box>
                <Divider />
            </DialogTitle>

            <Box>
                <Formik
                    initialValues={reportForm}
                    validationSchema={ReportValidation}
                    onSubmit={(formValues, formik) => {
                        actionOnFormSubmit(formValues, formik);
                    }}
                >
                    {({ handleSubmit, isSubmitting, dirty, isValid }) => (
                        <>
                            <DialogContent id="verification-success-popup">
                                <Typography>
                                    Let us know why you think this item should
                                    be removed from marketplace.
                                </Typography>
                                {reportList.map((report, index) => (
                                    <AppThemeRadioInput
                                        key={`report_${index}`}
                                        label={report.title}
                                        selectedValue={selectedValue}
                                        value={report.value}
                                        onChange={(value: string) => {
                                            setSelectedValue(value);
                                        }}
                                    />
                                ))}

                                <AppFormikTextArea
                                    name="comment"
                                    type="text"
                                    placeholder="Comments"
                                    rows={5}
                                />

                                <Box display="flex" justifyContent="flex-end">
                                    <AppPrimaryButton
                                        title="REPORT"
                                        onClick={handleSubmit}
                                        loadingText="Reporting"
                                        isLoading={isSubmitting}
                                        disabled={
                                            isSubmitting ||
                                            !(dirty && isValid) ||
                                            selectedValue === ''
                                        }
                                    />
                                </Box>
                            </DialogContent>
                        </>
                    )}
                </Formik>
            </Box>
        </Dialog>
    );
};

export default ReportNFT;
