import { ReactElement } from 'react';
import { Helmet } from 'react-helmet';

export const HeadTag = (props: any): ReactElement => {
    const { title, description } = props;

    return (
        <>
            <Helmet>
                <title>{ `TheUpsideSpace | ${title}` }</title>
                <meta
                    name="description"
                    content={
                        description ||
                        "NFT MarketPlace | Sports | Music | Art"
                    }
                />
            </Helmet>
        </>
    );
};
