import { ReactElement } from 'react';
import { Box, Typography, Divider } from '@material-ui/core';
import { HeadTag } from '../../shared/components';
import { Filters, ActivityList } from './components';

const Activity = (props: any): ReactElement => {
    const title = 'Trading History';

    // UI Elements
    return (
        <>
            <HeadTag title={title} />
            <Box className="activity-container">
                <Box className="activity-heading">
                    <Typography variant="h2" className="heading">
                        Trading history
                    </Typography>
                    <Divider />
                    <Filters />
                </Box>

                <ActivityList {...props} />
            </Box>
        </>
    );
};

export default Activity;
