import { AuthenticationService } from './Authentication';
import { ProfileService } from './Profile';
import { CollectionService } from './Collection';
import { ActivityService } from './Activity';
import { BuySellService } from './BuySellService';
import { ContactService } from './ContactServices';
import { CareerService } from './CareerService';
import { CaptchaSetting } from './CaptchaSetting';
import { LandService } from './LandService';

export interface AppPagination {
    offset: number;
    lastOffset: number;
    limit: number;
    total: number;
}

export const DefaultPagination = (limit: number): AppPagination =>
    ({
        offset: 1,
        lastOffset: 0,
        limit,
        total: 0,
    } as AppPagination);

export {
    AuthenticationService,
    ProfileService,
    CollectionService,
    ActivityService,
    BuySellService,
    ContactService,
    CareerService,
    CaptchaSetting,
    LandService,
};
