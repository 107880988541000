import { ReactElement, useEffect, useState } from 'react';
import { Container, Box, CircularProgress, Grid } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroller';
import CollectionsIcon from '@material-ui/icons/Collections';
import { DefaultPagination, ProfileService } from '../../../services';
import { Utils } from '../../../shared/helpers';
import NFTItem, { NFTInfo } from '../../home/components/NFTItem';
import { EmptyPlaceholder } from '../../../shared/components';

const MyFavorite = (): ReactElement => {
    const [items, setItems] = useState<NFTInfo[]>([]);
    const [pagination, setPagination] = useState({ ...DefaultPagination(12) });
    const [loadingItems, setLoadingItems] = useState(false);
    const [hasMoreItems, setHasMoreItems] = useState(false);

    // API Calls
    const loadItems = (): void => {
        if (pagination.lastOffset !== pagination.offset) {
            pagination.lastOffset = pagination.offset;
            setLoadingItems(true);
            ProfileService.favouriteList(pagination.limit, pagination.offset)
                .then((response: any) => {
                    const { data } = response.data;
                    const paginationResponse = data.pagination;
                    if (pagination.offset === 1) {
                        setItems(data.items);
                    } else {
                        setItems(items.concat(data.items));
                    }
                    setHasMoreItems(Utils.hasMoreRecords(paginationResponse));
                    setLoadingItems(false);
                    setPagination({
                        ...paginationResponse,
                        lastOffset: pagination.lastOffset,
                        offset: pagination.offset + 1,
                    });
                })
                .catch((error: any) => {
                    setLoadingItems(false);
                    Utils.handleErrorResponse({ error });
                });
        }
    };

    // Instance methods
    const loadMoreItems = (): void => {
        loadItems();
    };

    // Hooks
    useEffect(() => {
        loadItems();
    }, []);

    // UI Actions
    const unFavorite = (id: any): void => {
        const itemList = items.filter((obj) => obj.id !== id);
        setItems(itemList);
    };

    // UI Elements
    return (
        <Box my={2}>
            <Container>
                {!loadingItems && items.length === 0 && (
                    <EmptyPlaceholder
                        icon={<CollectionsIcon color="primary" />}
                        message="You haven’t added any favourite NFTs yet"
                    />
                )}

                <InfiniteScroll
                    threshold={0}
                    pageStart={0}
                    loadMore={loadMoreItems}
                    hasMore={hasMoreItems}
                >
                    <Grid container xs={12} spacing={4}>
                        {items.length !== 0 && (
                            <>
                                {items.map((nftItem) => (
                                    <Grid
                                        key={nftItem.id}
                                        item
                                        lg={4}
                                        md={4}
                                        sm={6}
                                        xs={12}
                                    >
                                        <NFTItem
                                            {...{
                                                ...nftItem,

                                                className: ' profile-nft',
                                            }}
                                            unFavorite={unFavorite}
                                        />
                                    </Grid>
                                ))}
                            </>
                        )}
                    </Grid>
                    {loadingItems && (
                        <Box className="empty-content">
                            <CircularProgress />
                        </Box>
                    )}
                </InfiniteScroll>
            </Container>
        </Box>
    );
};

export default MyFavorite;
