
import {
    Box,
    Dialog,
    DialogContent,
    Typography,
    IconButton,
    Divider,
    Button,
    Chip
} from '@material-ui/core';
import moment from 'moment';
import { ReactElement, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { getHeaders, getUrl } from '../../../shared/helpers/Utils';
import axios from '../../../shared/managers/AxiosManager';
import { Toast } from '../../../shared/helpers';

const CancelNft = (props: any): ReactElement => {
    const { detail: { itemInfo }, getItemDetail, saleInfo } = props;
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)


    const onCloseDialog = (): any => {
        setOpen(!open)
    }
    const cancelSell = (): any => {
        setLoading(true)
        /* eslint no-underscore-dangle: 0 */
        axios.post(`${process.env.REACT_APP_BASE_URL}/v1/list/${saleInfo?.id}/cancel`, {}, { headers: getHeaders() }).then((response: any) => {
            // const { data } = response?.data
            if (response.status === 200 || response.status === 201) {
                getItemDetail()
                Toast.showSuccesMessage('🎉 Successfully removed from sell')
                setOpen(!open)
                setLoading(false)
            } else {
                setLoading(false)
                Toast.showErrorMessage(response?.data?.msg || '');
            }

        }).catch((error: any) => {
            Toast.showErrorMessage(error.message);
        })
    }

    return (
        <div>
            <Chip
                variant='default'
                size='small'
                style={{ background: 'black', borderRadius: '2px', color: 'white', width: '100%' }}
                label='CANCEL'
                onClick={onCloseDialog}
            />

            <Dialog maxWidth="sm" onClose={onCloseDialog} open={open}>
                <Box display="flex" justifyContent="end">
                    <IconButton onClick={onCloseDialog}>
                        <CloseIcon fontSize="small" color="primary" />
                    </IconButton>
                </Box>
                <DialogContent>
                    <Box
                        display="flex"
                        justifyContent="start"
                        alignItems="center"
                    >
                        <Box mr={6}>
                            <img
                                height="90"
                                width="90"
                                src={getUrl(
                                    `${itemInfo.thumbnail}`,
                                    '200x200/'
                                )}
                                className="sellimage"
                            />
                        </Box>
                        <Box>
                            <Typography variant="h3">
                                {itemInfo?.name}
                            </Typography>
                            {saleInfo && saleInfo.length && (
                                <Typography>
                                    {moment(saleInfo[0]?.launch_date).format(
                                        'MM-DD-YYYY'
                                    )}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                    {saleInfo && saleInfo.length && (
                        <Box mt={2}>
                            <Typography variant="h5">
                                Price: {saleInfo[0]?.pricePerQuantity || 0} USD
                            </Typography>
                        </Box>
                    )}
                    <Box my={4}>
                        <Divider />
                    </Box>
                    <Box
                      
                    >
                        {/* <Chip
                            className="reject-bid-nft"
                            size="small"
                            onClick={onCloseDialog}
                            label="Reject"
                            variant="outlined"
                        /> */}
                        <Chip
                            disabled={loading}
                            className="cancel-bid-btn"
                            size="small"
                            label={<> {loading ? 'Loading...' : 'CONFIRM TO CANCEL'} </>}
                            onClick={cancelSell}
                        />
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default CancelNft;
