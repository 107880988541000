import { Avatar, Box, Typography } from "@material-ui/core";
import { ReactElement } from 'react';

const ReginIconName = (props: any): ReactElement => {
    const { name } = props

    return (<> {
        name !== undefined ? <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
        >
            <Box mr={1}>
                <Avatar style={{ height: '24px', width: '24px' }} src="/socialicons/region.svg" alt="region name" />
            </Box>
            <Typography className="region-name-1">{name}</Typography>
        </Box> : <small>No region found</small>
    }

    </>

    )
}

export default ReginIconName;