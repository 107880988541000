import store from '../state/RootReducer';
import { ProfileActions } from '../state/profile/Actions';
import ApiManager from '../shared/managers/APIManager';
import { RestEnds } from '../shared/constants';

const setupTwoFa = async (): Promise<any> =>
    ApiManager.sendPost(RestEnds.SETUP_TWO_FA, null, true);

const confirmTwoFa = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.CONFIRM_TWO_FA, data, true);

const resetTwoFa = async (): Promise<any> =>
    ApiManager.sendPost(RestEnds.RESET_TWO_FA, null, true);

const toggleTwoFa = async (data: any): Promise<any> =>
    ApiManager.sendPatch(RestEnds.TOGGLE_TWO_FA, data, true);

const getProfile = async (): Promise<any> =>
    ApiManager.sendGet(RestEnds.PROFILE, true);

const editProfile = async (data: any): Promise<any> =>
    ApiManager.sendPatch(RestEnds.PROFILE, data, true);

const updateProfilePic = async (data: any): Promise<any> =>
    ApiManager.sendPatch(RestEnds.PROFILE_PIC, data, true);

const updateProfileBgPic = async (data: any): Promise<any> =>
    ApiManager.sendPatch(RestEnds.PROFILE_BG_PIC, data, true);

const getKyc = async (): Promise<any> => ApiManager.sendGet(RestEnds.KYC, true);

const uploadKyc = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.KYC, data, true);

const changePassword = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.CHANGE_PASSWORD, data, true);

const availableUsername = async (data: any): Promise<any> =>
    ApiManager.sendGet(`${RestEnds.AVAILABLE_USERNAME}/${data}`, true);

const updateUsername = async (data: any): Promise<any> =>
    ApiManager.sendPatch(RestEnds.PROFILE_USERNAME, data, true);

const walletBalance = async (): Promise<any> =>
    ApiManager.sendGet(RestEnds.WALLET_BALANCE, true);

const createBankAccount = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.BANK_ACCOUNT, data, true);

const editBankAccount = async (data: any): Promise<any> =>
    ApiManager.sendPatch(RestEnds.BANK_ACCOUNT, data, true);

const getAccountDetails = async (): Promise<any> =>
    ApiManager.sendGet(RestEnds.BANK_ACCOUNT, true);

const deleteAccountDetails = async (accountId: string): Promise<any> =>
    ApiManager.sendDelete(RestEnds.DELETE_BANK_ACCOUNT(accountId), true);

const withdrawAmount = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.WITH_DRAW, data, true);

const autoKycSuccess = async (data: any): Promise<any> => 
    ApiManager.sendPost(RestEnds.KYC_SUCCESS, data, true)

const autoKycFailure = async (data: any): Promise<any> => 
    ApiManager.sendPost(RestEnds.KYC_FAILED, data, true)

const getOffersList  = async (type:any, limit: any, offset: any) :Promise<any> => 
    ApiManager.sendGet(RestEnds.OFFER_LISTING(type, limit, offset), true)

const withdrawList = async (
    limit: any,
    offset: any,
    sortBy: string,
    status: string,
    type: string
): Promise<any> =>
    ApiManager.sendGet(
        RestEnds.WITHDRAW_REQUESTS(limit, offset, sortBy, status, type),
        true
    );

const financialTransactionList = async (
    limit: any,
    offset: any,
    sortBy: string,
    transactionType: string
): Promise<any> =>
    ApiManager.sendGet(
        RestEnds.FINANCIAL_TRANSACTION(limit, offset, sortBy, transactionType),
        true
    );

const updatePrivacyStatus = async (data: any): Promise<any> =>
    ApiManager.sendPatch(RestEnds.PRIVACY_STATUS, data, true);

const updateWalletIntrest = async (data: any): Promise<any> =>
    ApiManager.sendPatch(RestEnds.WALLET_INTREST, data, true);

const getUserPortfolio = async (
    limit: any,
    offset: any,
    userId: string
): Promise<any> => ApiManager.sendGet(RestEnds.PORTFOLIO_INFO(userId), true);

const getProfileDetailsAndStore = async (): Promise<any> =>
    getProfile().then((result: any) => {
        const res = result.data.data;
        store.dispatch(ProfileActions.setProfileData(res));
    });

const getFollowers = async (limit: any, offset: any): Promise<any> =>
    ApiManager.sendGet(RestEnds.FOLLOWERS_LIST(limit, offset), true);

const getFollowings = async (limit: any, offset: any): Promise<any> =>
    ApiManager.sendGet(RestEnds.FOLLOWINGS_LIST(limit, offset), true);

const followProfile = async (userId: string): Promise<any> =>
    ApiManager.sendPost(RestEnds.FOLLOW, { followId: userId }, true);

const unfollowProfile = async (userId: string): Promise<any> =>
    ApiManager.sendPost(RestEnds.UNFOLLOW, { followId: userId }, true);

const createCryptoAccount = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.CRYPTO_ACCOUNT, data, true);

const editCryptoAccount = async (data: any): Promise<any> =>
    ApiManager.sendPatch(RestEnds.CRYPTO_ACCOUNT, data, true);

const getCrpytoAccountDetails = async (): Promise<any> =>
    ApiManager.sendGet(RestEnds.CRYPTO_ACCOUNT, true);

const importedNFTs = async (limit: any, offset: any): Promise<any> =>
    ApiManager.sendGet(RestEnds.IMPORTED_NFT_HISTORY(limit, offset), true);

const createImportEntry = async (importData: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.CREATE_IMPORT, importData, true);

const exportHistory = async (limit: any, offset: any): Promise<any> =>
    ApiManager.sendGet(RestEnds.EXPORT_HISTORY_LIST(limit, offset), true);

const addAddress = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.ADD_ADDRESS, data, true);

const editAddress = async (data: any): Promise<any> =>
    ApiManager.sendPatch(RestEnds.ADD_ADDRESS, data, true);

const deleteAddress = async (addressId: string): Promise<any> => 
    ApiManager.sendDelete(RestEnds.DELETE_ADDRESS(addressId), true)    

const getAddressList = async (limit: any, offset: any): Promise<any> =>
    ApiManager.sendGet(RestEnds.ADDRESS_LIST(limit, offset), true);

const getNFTRequestList = async (limit: any, offset: any): Promise<any> =>
    ApiManager.sendGet(RestEnds.NFT_REQUEST_LIST(limit, offset), true);

const createNFTRequest = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.NFT_REQUEST, data, true);

const payment = async (requestId: any, data: any): Promise<any> => {
    const url: any = `${RestEnds.NFT_REQUEST}/${requestId}`;
    return ApiManager.sendPatch(url, data, true);
};

const offersList = async (limit: any, offset: any): Promise<any> =>
    ApiManager.sendGet(RestEnds.OFFRES_LIST(limit, offset), true);

const favouriteList = async (limit: any, offset: any): Promise<any> =>
    ApiManager.sendGet(RestEnds.FAV_LIST(limit, offset), true);

const secondaryEmail = async (data: any): Promise<any> =>
    ApiManager.sendPatch(RestEnds.SECONDARY_EMAIL, data, true);

const deleteSecondaryEmail = async (): Promise<any> =>
    ApiManager.sendDelete(RestEnds.SECONDARY_EMAIL, true);

const getPyamentTransaction = async (limit: any, offset: any): Promise<any> => 
    ApiManager.sendGet(RestEnds.PAYMENT_HISTORY(limit, offset), true)

const updateDirectTransferStatus = (data: any): Promise<any> => 
    ApiManager.sendPatch(RestEnds.UPDATE_DIRECT_TRANSFER, data, true);



export const ProfileService = {
    setupTwoFa,
    confirmTwoFa,
    resetTwoFa,
    editProfile,
    changePassword,
    getProfile,
    updateProfilePic,
    updateProfileBgPic,
    getKyc,
    uploadKyc,
    availableUsername,
    updateUsername,
    toggleTwoFa,
    walletBalance,
    withdrawList,
    createBankAccount,
    editBankAccount,
    getAccountDetails,
    deleteAccountDetails,
    withdrawAmount,
    financialTransactionList,
    getUserPortfolio,
    updatePrivacyStatus,
    getProfileDetailsAndStore,
    getFollowers,
    getFollowings,
    followProfile,
    unfollowProfile,
    getCrpytoAccountDetails,
    createCryptoAccount,
    editCryptoAccount,
    importedNFTs,
    createImportEntry,
    exportHistory,
    addAddress,
    editAddress,
    deleteAddress,
    getAddressList,
    getNFTRequestList,
    createNFTRequest,
    payment,
    offersList,
    favouriteList,
    secondaryEmail,
    deleteSecondaryEmail,
    autoKycSuccess,
    updateWalletIntrest,
    autoKycFailure,
    getPyamentTransaction,
    updateDirectTransferStatus,
    getOffersList
};

export const Proofs = [
    {
        value: 'license',
        title: 'Driving License',
    },
    {
        value: 'passport',
        title: 'Passport',
    },
    {
        value: 'voterId',
        title: 'Voter ID',
    },
];
export const AddressProofs = [
    {
        value: 'electricity',
        title: 'Electricity Bill',
    },
    {
        value: 'gas',
        title: 'Gas Bill',
    },
    {
        value: 'water',
        title: 'Water Bill',
    },
];
