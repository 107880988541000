import { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Typography,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    TablePagination,
    Box,
    Avatar,
    Divider,
} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/Receipt';
import moment from 'moment';
import { Utils } from '../../../shared/helpers';
import WithdrawReqSkeleton from '../../../shared/skeletons/WithdrawReqSkeleton';
import { EmptyPlaceholder } from '../../../shared/components';
import { ProfileService, DefaultPagination } from '../../../services';

export interface FinancialTransactionInfo {
    from: string;
    to: string;
    amount: number;
    paymentMode: string;
    transactionId: string;
    events: string;
    transactionType: string;
    fiatWalletId: string;
    createdAt: string;
    currencyType: string;
    item: {
        itemId: string;
        name: string;
        logo: string;
        file: any;
        thumbnail: string;
    };
    id: string;
    userName: string;
    name: string;
    editionQuantity: string[];
    status: string;
}

const FinancialTransactionList = (): ReactElement => {
    const [financialList, setFinancialList] = useState<
        FinancialTransactionInfo[]
    >([]);
    const [pagination, setPagination] = useState({ ...DefaultPagination(10) });
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate();

    // API Calls
    const loadFinancialTransaction = (): any => {
        ProfileService.financialTransactionList(
            pagination.limit,
            pagination.offset,
            'recent',
            'all'
        )
            .then((response: any) => {
                const Response = response.data.data.transactionList;
                const paginationResponse = response.data.data.pagination;
                setFinancialList(Response);
                setPagination(paginationResponse);
                setLoader(false);
            })
            .catch((error: any) => {
                setLoader(false);
                Utils.handleErrorResponse({
                    error,
                });
            });
    };

    // Hooks
    useEffect(() => {
        setLoader(true);
        loadFinancialTransaction();
    }, []);

    // UI Actions
    const actionHandleLimitChange = (event: any): any => {
        const rowsPerPage = event.target.value;
        pagination.limit = parseInt(rowsPerPage, 10);
        loadFinancialTransaction();
    };

    const actionHandlePageChange = (event: any, newPage: number): any => {
        pagination.offset = newPage + 1;
        loadFinancialTransaction();
    };

    const actionToItem = (id: any): any => {
        navigate(`/item/${id}`)
    }

    // UI Elements
    return (
        <Box className="profile-content">
            <Box className="card-header-btn view-profile">
                <Typography className="title" variant="h3">
                    Wallet History
                </Typography>
            </Box>
            <Divider className='border-content' />
            <Box className="profile-card">
                <Typography variant="body1" className="subtitle">
                    You can view all your financial transactions here.
                </Typography>

                <Box mt={4}>
                    {financialList.length === 0 && loader === false && (
                        <EmptyPlaceholder
                            icon={<ReceiptIcon />}
                            // message="No transactions found"
                            message="No  Wallet History found"
                        />
                    )}
                    {loader === true && <WithdrawReqSkeleton />}

                    {loader === false && financialList.length > 0 && (
                        <>
                            <TableContainer>
                                <Table stickyHeader className="striped-table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                S.No
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                Item Logo
                                            </TableCell>
                                            <TableCell>Item Name</TableCell>
                                            <TableCell component="th" scope="row">
                                                Transaction ID
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                Event
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                Status
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                Amount
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                Payment Mode
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                Date
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {financialList.map(
                                            (financiallist, index) => (
                                                <TableRow
                                                    hover
                                                    style={{cursor:'pointer'}}
                                                    className="f13 exo2-regular"
                                                    key={financiallist.id}
                                                    onClick={() => actionToItem(financiallist.item.itemId)}
                                                >
                                                    <TableCell>
                                                        {(pagination.offset - 1) *
                                                            pagination.limit +
                                                            (index + 1)}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Avatar
                                                            alt='L'
                                                            src={
                                                                Utils.getUrl(financiallist.item ? financiallist.item.thumbnail : '')
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        component="td"
                                                        scope="row"
                                                    >
                                                        {financiallist.item.name}
                                                    </TableCell>
                                                    <TableCell
                                                        className="type"
                                                        component="td"
                                                        scope="row"
                                                    >
                                                        {financiallist.transactionId ===
                                                            null
                                                            ? '-'
                                                            : financiallist.transactionId}
                                                    </TableCell>
                                                    <TableCell
                                                        className="text-case"
                                                        component="td"
                                                        scope="row"
                                                    >
                                                        {Utils.titleCase(
                                                            financiallist.events
                                                        )}
                                                    </TableCell>
                                                    <TableCell
                                                        className="text-case"
                                                        component="td"
                                                        scope="row"
                                                    >
                                                        {Utils.titleCase(
                                                            financiallist.status
                                                        )}
                                                    </TableCell>
                                                    <TableCell
                                                        className="type"
                                                        component="td"
                                                        scope="row"
                                                    >
                                                        {financiallist.transactionType ===
                                                            'debit' &&
                                                            financiallist.events !==
                                                            'cashback'
                                                            ? ' - '
                                                            : ''}
                                                        {financiallist.amount}{' '}
                                                        {financiallist.currencyType}
                                                    </TableCell>
                                                    <TableCell
                                                        component="td"
                                                        scope="row"
                                                    >
                                                        {Utils.formatPaymentModes(
                                                            financiallist.paymentMode
                                                        )}
                                                    </TableCell>
                                                    <TableCell
                                                        component="td"
                                                        scope="row"
                                                    >
                                                        {moment(
                                                            financiallist.createdAt
                                                        ).format(
                                                            'DD MMM YYYY h:mm a'
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                component="div"
                                count={pagination.total}
                                onPageChange={actionHandlePageChange}
                                onRowsPerPageChange={actionHandleLimitChange}
                                page={pagination.offset - 1}
                                rowsPerPage={pagination.limit}
                                rowsPerPageOptions={[5, 10, 25]}
                            />
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default FinancialTransactionList;
