import { ReactElement, useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    Dialog,
    DialogContent,
    DialogTitle,
    Box,
    IconButton,
    Divider,
} from '@material-ui/core';
import { Formik } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import {
    AddBankAccountSchema,
    EditBankAccountSchema,
} from '../../../validators/ProfileSchema';
import {
    AppFormikTextField,
    AppFormikTextArea,
    AppPrimaryButton,
    AppTeritaryButton,
} from '../../../shared/components/AppComponents';
import { Utils } from '../../../shared/helpers';
import { ProfileService } from '../../../services';

const BankAccountForm = (props: any): ReactElement => {
    const {
        modalFormType,
        openAccountModal,
        actionOnCancel,
        accountDetails,
        bankAccountInfoToEdit,
        onClose,
    } = props;
    const createAccountForm = {
        name: '',
        bankName: '',
        accountNumber: '',
        confirmAccountNumber: '',
        IFSC: '',
        IBAN: '',
        swiftCode: '',
        additionalDetails: '',
    };
    const [bankAccountForm, setBankAccountForm] = useState(createAccountForm);
    const [accountId, setAccountId] = useState('');

    // Instance methods
    const bindBankAccountInfoToEdit = (): any => {
        const {
            name,
            bankName,
            accountNumber,
            IFSCCode,
            IBANCode,
            swiftCode,
            additionalDetails,
            id,
        } = bankAccountInfoToEdit;
        setAccountId(id);
        setBankAccountForm({
            name,
            bankName,
            accountNumber,
            IFSC: IFSCCode,
            IBAN: IBANCode,
            swiftCode,
            additionalDetails,
            confirmAccountNumber: '',
        });
    };

    // Hooks
    useEffect(() => {
        if (bankAccountInfoToEdit) {
            bindBankAccountInfoToEdit();
        } else {
            setBankAccountForm(bankAccountForm);
        }
    }, [bankAccountInfoToEdit]);

    // API Calls
    const create = (formValues: any, callback: any): any => {
        const object = Utils.copyObject(formValues);
        // Need to check
        ProfileService.createBankAccount(object)
            .then((response: any) => {
                Utils.handleSuccessResponse(response);
                callback(true);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
                callback(false);
            });
    };

    const edit = (formValues: any, callback: any): any => {
        const { name, accountNumber, IFSC, IBAN, swiftCode, additionalDetails }: any = formValues;
        const requestObj = {
            name,
            accountNumber,
            IFSC,
            IBAN,
            swiftCode,
            accountId,
            additionalDetails
        };
        ProfileService.editBankAccount(requestObj)
            .then((response: any) => {
                Utils.handleSuccessResponse(response);
                callback(true);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
                callback(false);
            });
    };

    // UI Actions
    const actionAddBankAccount = (formValues: any, formik: any): any => {
        formik.setSubmitting(true);
        if (accountDetails.length !== 0 && modalFormType === 'Edit') {
            edit(formValues, (status: boolean) => {
                if (status) {
                    formik.resetForm();
                    actionOnCancel();
                }
                formik.setSubmitting(false);
            });
        } else {
            const {
                name,
                bankName,
                accountNumber,
                IFSC,
                IBAN,
                swiftCode,
                additionalDetails,
            }: any = formValues;
            const requestObj = {
                name,
                bankName,
                accountNumber,
                IFSC,
                IBAN,
                swiftCode,
                additionalDetails,
            };

            create(requestObj, (status: boolean) => {
                if (status) {
                    formik.resetForm();
                    actionOnCancel();
                }
                formik.setSubmitting(false);
            });
        }
    };

    // UI Elements
    return (
        <Dialog disableEscapeKeyDown open={openAccountModal}>
            <DialogTitle>
                <Box className="title-section">
                    <Typography variant="h4" className='tobias-fontfamily'>
                        {modalFormType === 'Add' ? 'Add' : 'Edit'} Bank Account
                    </Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon color="primary" onClick={actionOnCancel} />
                    </IconButton>
                </Box>
                <Divider />
            </DialogTitle>
            <DialogContent>
                <Formik
                    enableReinitialize
                    initialValues={bankAccountForm}
                    validationSchema={
                        modalFormType === 'Add'
                            ? AddBankAccountSchema
                            : EditBankAccountSchema
                    }
                    validateOnMount
                    onSubmit={(values, formik) => {
                        actionAddBankAccount(values, formik);
                    }}
                >
                    {({ handleSubmit, isSubmitting, isValid }) => (
                        <form autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6} className='bank-account-field'>
                                    <AppFormikTextField
                                        label="Account Holder Name *"
                                        name="name"
                                        type="text"
                                        outerLabel
                                        size="large"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} className='bank-account-field'>
                                    <AppFormikTextField
                                        label="Account Number *"
                                        name="accountNumber"
                                        inputProps={{
                                            readOnly: modalFormType === 'Edit',
                                        }}
                                        outerLabel
                                        size="large"
                                    />
                                </Grid>
                                {modalFormType !== 'Edit' && (
                                    <Grid item xs={12} md={6} className='bank-account-field'>
                                        <AppFormikTextField
                                            label="Confirm Account Number *"
                                            name="confirmAccountNumber"
                                            type="text"
                                            outerLabel
                                            size="large"
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12} md={6} className='bank-account-field'>
                                    <AppFormikTextField
                                        label="IFSC Code"
                                        name="IFSC"
                                        type="text"
                                        outerLabel
                                        size="large"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6} className='bank-account-field'>
                                    <AppFormikTextField
                                        label="Bank Name *"
                                        name="bankName"
                                        type="text"
                                        inputProps={{
                                            readOnly: modalFormType === 'Edit',
                                        }}
                                        outerLabel
                                        size="large"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6} className='bank-account-field'>
                                    <AppFormikTextField
                                        label="IBAN Code"
                                        name="IBAN"
                                        type="text"
                                        outerLabel
                                        size="large"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6} className='bank-account-field'>
                                    <AppFormikTextField
                                        label="Swift Code *"
                                        name="swiftCode"
                                        type="text"
                                        outerLabel
                                        size="large"
                                    />
                                </Grid>

                                <Grid item xs={12} className='fieldset-box'>
                                    <AppFormikTextArea
                                        label="Additional Detail"
                                        name="additionalDetails"
                                        outerLabel
                                        inputProps={{
                                            readOnly: modalFormType === 'Edit',
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Box py={2}>
                                <Grid container className="end-content">
                                    <Grid>
                                        <Box mr={1}>
                                            <AppTeritaryButton
                                                title="CANCEL"
                                                onClick={actionOnCancel}
                                                className="account-cancel-btn"
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid>
                                        <AppPrimaryButton
                                            title={
                                                modalFormType === 'Add'
                                                    ? 'ADD'
                                                    : 'UPDATE'
                                            }
                                            isLoading={isSubmitting}
                                            loadingText={
                                                modalFormType === 'Add'
                                                    ? 'CREATING'
                                                    : 'UPDATING'
                                            }
                                            disabled={isSubmitting || !isValid}
                                            onClick={handleSubmit}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default BankAccountForm;
