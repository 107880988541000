import { useState, ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, InputLabel, Typography, Grid, Divider } from '@material-ui/core';
import OtpInput from 'react-otp-input';
import { connect } from 'react-redux';
import { AuthActions } from '../../../state/authentication/Action';
import { AppPrimaryButton } from '../../../shared/components/AppComponents';
import { Utils } from '../../../shared/helpers';
import { AuthenticationService } from '../../../services';
import { useAuth } from '../hooks/UseAuth';

const SecurityVerificationForm = (props: any): ReactElement => {
    const { tokenId } = props;
    const tokenIdData: any = tokenId;
    const [isSubmitting, setLoading] = useState(false);
    const [token, setToken] = useState('');
    const { processLoginResponse } = useAuth();

    // API Calls
    const securityverification = (requestObj: any): any => {
        AuthenticationService.securityVerification(requestObj)
            .then((response: any) => {
                processLoginResponse(response);
            })
            .catch((error: any) => {
                setLoading(false);
                Utils.handleErrorResponse({ error });
            });
    };

    // UI Actions
    const actionSecurityVerification = (e: any): any => {
        e.preventDefault();
        const requestObj = {
            code: token,
            tokenId: tokenIdData,
        };
        setLoading(true);
        securityverification(requestObj);
    };

    const actionOnChange = (value: string): any => setToken(value);

    // UI Elements
    return (
        <>
            <Box className="auth-bg">
                <Box className="auth-content">
                    <form autoComplete="off">
                        <Box component="div" mt={2}>
                            <Typography
                                className="security-header mb-2"
                                variant="h3"
                            >
                                Security Verification
                            </Typography>
                            <Divider />
                        </Box>
                        <Box component="div" mt={4}>
                            <Typography variant="body1">
                                To secure you account, please complete the
                                following verification.
                            </Typography>
                        </Box>

                        <Box pt={3}>
                            <InputLabel required className="text-gray">
                                Google verification code
                            </InputLabel>
                        </Box>
                        <Box pt={3}>
                            <OtpInput
                                value={token}
                                onChange={actionOnChange}
                                numInputs={6}
                                inputStyle="input-otp"
                                shouldAutoFocus
                                inputType="number"
                                renderInput={(inputProps) => (
                                    <input
                                        className="input-split mb-2"
                                        {...inputProps}
                                    />
                                )}
                            />
                        </Box>

                        <AppPrimaryButton
                            className="mt-4"
                            title="VERIFY"
                            isLoading={isSubmitting}
                            loadingText="VERIFYING"
                            disabled={
                                isSubmitting ||
                                Object.entries(token).length !== 6
                            }
                            onClick={actionSecurityVerification}
                        />

                        <Box py={4}>
                            <Grid item className="center-content p-5">
                                <Typography
                                    className="flex"
                                    component={NavLink}
                                    variant="body1"
                                    to="/login"
                                >
                                    Back to Login
                                </Typography>
                            </Grid>
                        </Box>
                    </form>
                </Box>
            </Box>
        </>
    );
};

const mapDispatchToProps = (dispatch: any): any => ({
    setLoginStatus: (data: any): boolean =>
        dispatch(AuthActions.setLoginStatus(data)),
});

export default connect(null, mapDispatchToProps)(SecurityVerificationForm);
