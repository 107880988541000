import { ReactElement, useState, useRef, useEffect } from 'react';
import { 
    Box,
    Typography,
    Grid,
} from "@material-ui/core";
import ShareIcon from '@material-ui/icons/Share';
import FlagIcon from '@material-ui/icons/Flag';
import { useNavigate } from 'react-router-dom';
import { 
    AppPrimaryButton,
    NumberPretty, 
    AppTeritaryButton 
} from '../../../shared/components/AppComponents';
import {
    MoreMenu,
    SocialShare
} from '../../../shared/components';
import { MoreMenuData } from '../../../shared/components/MoreMenu';
import { Toast } from '../../../shared/helpers';
import DisClaimerForm from './DisClainerForm'

const menus = [
    {
        title: 'Share',
        icon: <ShareIcon color="primary" />,
    },
    {
        title: 'Report',
        icon: <FlagIcon color="primary" />,
    },
];

const LandDetails = (props: any): ReactElement => {
    const { 
        landDetails,
        authState,
        onBuyLand
    } = props
    const navigate = useNavigate();
    const [showFullDescription, setShowFullDescription] = useState(false);
    const descriptionRef = useRef<HTMLElement>(null as any);
    const [showReadMore, setShowReadMore] = useState(false);
    const [showShareDialog, setShowShareDialog] = useState(false);
    const [disclaimerFor, setDisclaimerFor] = useState('');
    const [showDisclaimer, setShowDisclaimer] = useState(false);

    useEffect(() => {
        if (descriptionRef !== null && descriptionRef.current !== null) {
            const descriptionOverflowing =
                descriptionRef.current.clientHeight <
                    descriptionRef.current.scrollHeight ||
                descriptionRef.current.clientWidth <
                    descriptionRef.current.scrollWidth;
            if (descriptionOverflowing) {
                setShowReadMore(true);
            } else {
                setShowReadMore(false);
            }
        }
    })

    const actionDisclaimerAgreedToBuyNFT = (): any => {
        if (disclaimerFor === 'buy') {
            setShowDisclaimer(false);
            setDisclaimerFor('');
            onBuyLand(landDetails);
        }
    };

  return (
      <>
      {landDetails && (
        <>
            <Box>
                <Box className="nft-detail">
                    <Box className="nft-title-section">
                        <Typography variant="h3" className="title">
                            {landDetails.metadata.name}
                        </Typography>
                        <Box>
                            {false && (
                                <>
                                    <AppTeritaryButton
                                        className="btn-small"
                                        title={
                                            <NumberPretty
                                                count={0}
                                            />
                                        }
                                        // startIcon={getFavIcon()}
                                        onClick={() =>  console.log("")}
                                    />
                                </>
                            )}
                        </Box>
                        
                        <MoreMenu
                            menus={menus}
                            onMenuSelect={(menu: MoreMenuData) => {
                                if (menu.title === 'Share') {
                                    setShowShareDialog(true);
                                } else if (menu.title === 'Report') {
                                    if (authState.isUserLoggedIn) {
                                        // setShowReport(true);
                                    } else {
                                        Toast.showSuccesMessage(
                                            'Login to report items'
                                        );
                                        navigate('/login', {
                                            state: {
                                                nextPathName:
                                                    window.location
                                                        .pathname,
                                            },
                                        });
                                    }
                                }
                            }}
                        />
                    </Box>
                    <Box className="nft-description">
                        <Typography
                            variant="body1"
                            className={
                                showFullDescription
                                    ? 'description-full'
                                    : 'description'
                            }
                            ref={descriptionRef}
                            dangerouslySetInnerHTML={{
                                __html: landDetails.metadata.description,
                            }}
                        />
                        {showReadMore && (
                            <Typography
                                className="pointer"
                                variant="caption"
                                onClick={() =>
                                    setShowFullDescription(
                                        !showFullDescription
                                    )
                                }
                            >
                                {showFullDescription
                                    ? 'Show less'
                                    : 'Read more'}
                            </Typography>
                        )}
                    </Box>
                    <Grid
                        container
                        className=""
                        spacing={4}
                    >
                        <Grid container className="bid-detail" spacing={4}>
                                <Grid item md={6} xs={12}>
                                    <Box className="bid-detail-1">
                                        <Typography className="heading">
                                            Current Price
                                        </Typography>
                                        <Typography
                                            component={Box}
                                            variant="body1"
                                            className="bid-amount"
                                        >
                                            {landDetails.landSalesPriceUsd} USD
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Box className="bid-detail-2">
                                        <Typography className="heading">
                                            Quantity Available
                                        </Typography>

                                        <Typography
                                            className="nft-count mt-2"
                                            component={Box}
                                        >
                                            1
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box my={2}>
                                        <AppPrimaryButton
                                            className="btn-small"
                                            title="BUY"
                                            fullWidth
                                            onClick={() => {
                                                if (authState.isUserLoggedIn) {
                                                    if (
                                                        true
                                                    ) {
                                                        setDisclaimerFor('buy');
                                                        setShowDisclaimer(true);
                                                    } else {
                                                        onBuyLand(
                                                            landDetails
                                                        );
                                                    }
                                                } else {
                                                    Toast.showSuccesMessage(
                                                        'Login to buy items'
                                                    );
                                                    navigate('/login', {
                                                        state: {
                                                            nextPathName:
                                                                `${window.location.pathname}?longitude=${landDetails.metadata.longitude}&latitude=${landDetails.metadata.latitude}`,
                                                        },
                                                    });
                                                }
                                            }}
                                        />
                                    </Box>
                                </Grid>
                        </Grid>
                    </Grid>
                    </Box>
            </Box>
            {showShareDialog &&
                <SocialShare
                    onCloseDialog={() => {
                        setShowShareDialog(false);
                    }}
                    showShare={showShareDialog}
                    tooltip="Share your portfolio"
                    title={`Checkout NFT @${landDetails.metadata.name.replace(
                        ' ',
                        ''
                    )} at @TUS`}
                    link={`/land/details?longitude=${landDetails.metadata.longitude}&latitude=${landDetails.metadata.latitude}`}
                />
            }
            {showDisclaimer && (
                <DisClaimerForm
                    open={showDisclaimer}
                    onCloseForm={() => setShowDisclaimer(false)}
                    isAgreed={() => {
                        if (disclaimerFor === 'buy') {
                            actionDisclaimerAgreedToBuyNFT();
                        } 
                    }}
                />
            )}
        </>
        
      )}
      </>
  )
}

export default LandDetails