import { Box, Typography } from '@material-ui/core';
import 'moment-timezone';
import { ReactElement } from 'react';
import Countdown from 'react-countdown';

const MiniAuctionTimer = (props: any): ReactElement => {
    const { liveTill,availableQuantity } = props;
    const countdown = ({ days, completed, formatted }: any): ReactElement => {
        if (completed) {
            return <>
            <Box className="expire-info-2" p={2}>
                    <Typography
                         variant="body2"
                         className="end-text"
                            >
                        { availableQuantity > 0 && availableQuantity ?
                        "AUCTION EXPIRED":"SOLD"    
                    }
                    </Typography>
                    
                </Box> 
            
                   
            </>;
        }
        // Render a countdown
        return (
            <>
                <Box  mt={2}>
                <Typography
                    variant="body2"
                    className="end-text"
                    >
                    Ending in
                </Typography>
                <Box mt={2}>
                {days > 0 && <span>{days} Days</span>}
                {days === 0 && (
                    <span>{`${formatted.hours}:${formatted.minutes}:${formatted.seconds}`}</span>
                )}
                </Box>
                </Box>
             
                
            </>
        );
    };

    return (
        <>
            <Countdown date={liveTill} renderer={countdown} />
        </>
    );
};
export default MiniAuctionTimer;
