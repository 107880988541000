import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { ReactElement } from 'react';
import { Box } from '@material-ui/core';

export const SlideNextArrow = (props: any): ReactElement => {
    const { onClick } = props;
    return (
        <>
            {onClick && (
                <Box
                    className="slider-arrow slide-arrow-next trans-3d-hover"
                    onClick={() => {
                        onClick();
                    }}
                >
                    <ArrowForwardIosIcon />
                </Box>
            )}
        </>
    );
};

export const SlidePrevArrow = (props: any): ReactElement => {
    const { onClick } = props;
    return (
        <>
            {onClick && (
                <Box
                    className="slider-arrow slide-arrow-prev trans-3d-hover"
                    onClick={() => {
                        onClick();
                    }}
                >
                    <ArrowForwardIosIcon />
                </Box>
            )}
        </>
    );
};
