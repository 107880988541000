import { ReactElement, useState, useEffect } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { Settings } from '@material-ui/icons';

import {
    Button,
    Grid,
    IconButton,
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Avatar,
    AppBar,
    // Typography,
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router'
import { connect } from 'react-redux';

import { AppTeritaryButton } from './AppComponents';
import { ProfileActions } from '../../state/profile/Actions';
import { defaultProfileInfo } from '../../state/profile/Reducer';
import store, { AppState } from '../../state/RootReducer';
import { AuthActions } from '../../state/authentication/Action';
import { AppPreference } from '../managers';
import { AuthenticationService } from '../../services';
import Utils from '../helpers/Utils';
import AppNotification from './AppNotification';
// import SearchComponent from './SearchComponent';

const AppHeader = (props: any): ReactElement => {
    const history = useHistory();
    const { isUserLoggedIn, profileInfo, notificationCom } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [showUserMenu, setShowUserMenu] = useState(false);


    // Instance Methods
    const hideMenus = (): void => {
        setShowMobileMenu(false);
        setShowUserMenu(false);
    };

    // Hooks
    useEffect(() => {
        hideMenus();
        Utils.clearCache()
    }, [pathname]);

    // UI Actions
    const handleLogout = (): any => {
        AuthenticationService.logout().then(() => {
            store.dispatch(ProfileActions.setProfileData(defaultProfileInfo));
            store.dispatch(AuthActions.setLoginStatus(false));
            AppPreference.clearLoginToken();
            navigate('/home');
        })
            .catch(() => {
                AppPreference.clearLoginToken();
                store.dispatch(ProfileActions.setProfileData(defaultProfileInfo));
                store.dispatch(AuthActions.setLoginStatus(false));
                navigate('/home');
            })

            ;
    };

    const emailCheck = (emailId: string): boolean => {
        const regex = new RegExp('[a-z0-9]+@fantico.io');
        return regex.test(emailId);
    };

    const actionToProfile = (): any => {
        navigate('/profile/view',
            { state: { myNft: "myNft" } }
        );
        history?.go(0)
    }
    // UI Elements
    const NavMenus = (): ReactElement => (
        <>
            {/* <SearchComponent
                onItemClicked={() => {
                    setShowMobileMenu(false);
                }}
            /> */}
            <AppTeritaryButton
                to="home"
                className="btn-menu btn-small top-menu"
                title="Home"
                component={NavLink}
                onClick={hideMenus}
            />

            <AppTeritaryButton
                to="marketplace"
                className="btn-menu btn-small top-menu"
                title="Explore"
                component={NavLink}
                onClick={hideMenus}
            />
            <AppTeritaryButton
                className="btn-menu btn-small top-menu"
                title="Community"
                // component={NavLink}
                onClick={() => window.open("https://community.theupsidespace.com", "_self")}

            />
            {emailCheck(profileInfo.emailId) ? (
                <AppTeritaryButton
                    to="activity"
                    className="btn-menu btn-small top-menu"
                    title="Activity"
                    component={NavLink}
                    onClick={hideMenus}
                />
            ) : (
                ''
            )}
            {isUserLoggedIn && (
                <>
                    <AppTeritaryButton
                        // to="profile/view"
                        className="btn-menu btn-small top-menu nav-my-nfts"
                        title="My NFTs"
                        // component={NavLink}
                        onClick={() => { hideMenus(); actionToProfile() }}
                    />
                </>
            )}
            <AppTeritaryButton
                to="faq/general-information"
                className="btn-menu btn-small top-menu"
                title="FAQs"
                component={NavLink}
                onClick={hideMenus}
            />

            <AppTeritaryButton
                to="about"
                className="btn-menu btn-small top-menu"
                title="About Us"
                component={NavLink}
                onClick={hideMenus}
            />
            {false && (
                <Button className="btn-menu btn-teritary btn-small top-menu">
                    Resources
                </Button>
            )}
        </>
    );
    const MobileTrigger = (): ReactElement => (
        <>
            {!showMobileMenu && (
                <IconButton onClick={() => setShowMobileMenu(true)}>
                    <MenuIcon className="mobileicon" />
                </IconButton>
            )}
            {showMobileMenu && (
                <IconButton onClick={() => setShowMobileMenu(false)}>
                    <CloseIcon className="mobileicon" />
                </IconButton>
            )}
        </>
    );
    const UserDropDown = (): ReactElement => (
        <>
            {!isUserLoggedIn && (
                <>
                    <AppTeritaryButton
                        // className="btn-login btn-small"
                        className="btn-menu btn-small top-menu"
                        title="Sign In"
                        onClick={() => navigate('/login')}
                    />
                    <AppTeritaryButton
                        // className="btn-login btn-small"
                        className="btn-menu btn-small top-menu"
                        title="Sign Up"
                        onClick={() => navigate('/create-wallet')}
                    />
                </>

            )}
            {isUserLoggedIn && (
                <>
                    <Box className="user-account-block" style={{ position: 'relative' }}>
                        <IconButton
                            onClick={() => {
                                setShowUserMenu(!showUserMenu);
                            }}
                        >
                            <Avatar
                                className="user-icon"
                                src={profileInfo.profilePicture}
                            >
                                <img src="/icons/User.svg" />
                            </Avatar>
                            <ExpandMoreIcon className="black up-arrow-icon" />
                        </IconButton>
                        {showUserMenu && (
                            <List className="user-menu-list">
                                <ListItem button onClick={() => {
                                    if (profileInfo.type) {
                                        navigate(`/${profileInfo.type}/${profileInfo.userId}`);
                                    } else {
                                        navigate(`/user/${profileInfo.userId}`);
                                    }

                                }}>
                                    <ListItemIcon>
                                        <AccountCircle className="black " />
                                    </ListItemIcon>
                                    <ListItemText primary="My Profile" />
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() => {
                                        navigate('/profile/view');
                                    }}
                                >
                                    <ListItemIcon>
                                        <Settings className='black' />
                                    </ListItemIcon>
                                    <ListItemText primary="Settings" />
                                </ListItem>

                                <ListItem button onClick={handleLogout}>
                                    <ListItemIcon>
                                        <ExitToAppIcon className="black" />
                                    </ListItemIcon>
                                    <ListItemText primary="Logout" />
                                </ListItem>
                            </List>
                        )}
                    </Box>
                </>
            )}
        </>
    );
    return (
        <AppBar position="static">
            <Box>
             { process.env.REACT_APP_NOTIFICATION_ENABLE === 'true' && <AppNotification msg={process.env.REACT_APP_NOTIFICATION} />}
                <Box
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    className="app-top-header"
                >
                    <Box>
                        <img
                            className="app-logo cursor"
                            onClick={() => navigate('/home')}
                            src="/images/TUSwhite.svg"
                            alt="logo"
                        />
                    </Box>
                    <Box className="mobile-menu header-box">
                        <UserDropDown />
                        <MobileTrigger />
                    </Box>
                    <Box className="desktop-menu header-box">
                        <NavMenus />
                        <UserDropDown />
                    </Box>
                    {showMobileMenu && (
                        <Box className="mobile-menu-contaniner">
                            <Box className="mobile-nav">
                                <NavMenus />
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
        </AppBar>
    );
};

const mapStateToProps = (state: AppState): any => ({
    isUserLoggedIn: state.authState.isUserLoggedIn,
    profileInfo: state.profileInfo,
});

export default connect(mapStateToProps)(AppHeader);
