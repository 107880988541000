import { ReactElement } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { HeadTag } from '../../../shared/components';
import { AppBigNavButton } from '../../../shared/components/AppComponents';

const About = (): ReactElement => {
    const title = 'About';
    const description =
        "About";

    return (
        <Box id="about-wrapper" className='about-main about-main-wrraper'>
            <HeadTag title={title} description={description} />
            <Grid container className="banner-wrapper" alignItems="center">
                <Grid xs={12} id="About">
                    <Box className='about-title-box'>
                        <Typography className="about-title">About TheUpsideSpace</Typography>
                    </Box>
                </Grid>
                <Box className='about-content-wrepper'>
                    <Grid xs={12} md={4} className="about-wrapper-grid-box">
                        <Box className='about-image-box about-wrapper-img-box'>
                            <img src="/images/about.svg" alt="about" />
                        </Box>
                    </Grid>
                    <Grid xs={12} md={8} className="about-section-wrapper">
                        <Box className='about-content-box'>
                            <Typography className="about-title justify-text" variant='body2'>TheUpsideSpace is a curator-led digital arts platform that spotlights artistic expressions from Southeast Asia, South Asia and the Middle East. Our mission is to build a thriving creative ecosystem of art enthusiasts, new audiences, existing collectors and artists from untapped geographies in one place. NFTs and blockchain are the tools of our vision: Curating Limitless Possibilities.</Typography>
                        </Box>
                        {/* <Box className='about-read-more'>
                            <AppBigNavButton
                                endIcon={<img src="/images/banner-right-arrow.svg" />}
                                title="Read More"
                                to="/about"
                                id="hero-button"
                            />
                        </Box> */}
                    </Grid>
                </Box>
            </Grid>
        </Box>
    )
}

export default About