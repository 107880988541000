import { ReactElement, useState } from 'react';
import {
    Box,
    Typography,
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Divider,
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
// import OtpInput from 'react-otp-input';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { AppState } from '../../../state/RootReducer';
import { ProfileState } from '../../../state/profile/Reducer';
import { ProfileActions } from '../../../state/profile/Actions';
import { Utils } from '../../../shared/helpers';
import {
    AppPrimaryButton,
    AppSecondaryButton,
    AppTeritaryButton,
} from '../../../shared/components/AppComponents';
import { ProfileService } from '../../../services';

export interface ViewProfileProps {
    profileInfo: ProfileState;
}

interface TwoFAResponse {
    secret: string;
    url: string;
}

const TwoFactorForm = (props: any): ReactElement => {
    const { profileInfo, setProfileData } = props;
    const [isSuccessDialogOpened, setIsSuccessDialogOpened] = useState(false);
    const [isResetDialogOpened, setIsResetDialogOpened] = useState(false);
    const [twoFaProgress, setTwoFaProgress] = useState(false);
    const [twoFASetupData, setTwoFASetupData] = useState<
        TwoFAResponse | undefined
    >(undefined);
    const [otp, setOtp] = useState('');
    const [loader, setLoader] = useState(false);
    const [secretCopied, setSecretCopied] = useState(false);

    // API Calls
    const setupTwoFA = (callback: any): any => {
        ProfileService.setupTwoFa()
            .then((response: any) => {
                const Response = response.data.data;
                Utils.handleSuccessResponse(response);
                callback(true, Response);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
                callback(false);
            });
    };

    const toggleTwoFa = (formValues: any, callback: any): any => {
        ProfileService.toggleTwoFa(formValues)
            .then((response: any) => {
                Utils.handleSuccessResponse(response);
                callback(true, response);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
                setLoader(false);
            });
    };

    const confirmTwoFASetup = (formValues: any, callback: any): any => {
        ProfileService.confirmTwoFa(formValues)
            .then((response: any) => {
                Utils.handleSuccessResponse(response);
                callback(true, response);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
                callback(false);
            });
    };

    // UI Actions
    const actionSetupTwoFA = (): void => {
        setLoader(true);
        setupTwoFA((status: boolean, response: TwoFAResponse) => {
            if (status) {
                setTwoFASetupData(response);
                setTwoFaProgress(true);
            }
            setOtp('');
            setLoader(false);
        });
    };

    const actionCompleteTwoFaSetup = (): void => {
        const formValues = { code: otp };
        setLoader(true);
        confirmTwoFASetup(formValues, (status: boolean) => {
            if (status) {
                profileInfo.twoFactorEnabled = true;
                profileInfo.twoFactorSetupCompleted = true;
                props.setProfileData({ ...profileInfo });
                setTwoFaProgress(false);
            }
            setLoader(false);
            setOtp('');
        });
    };

    const actionToggleTwoFA = (): void => {
        const formValues = {
            enable: !profileInfo.twoFactorEnabled,
            code: otp,
        };
        setLoader(true);
        toggleTwoFa(formValues, (status: boolean) => {
            if (status) {
                profileInfo.twoFactorEnabled = !profileInfo.twoFactorEnabled;
                setProfileData({ ...profileInfo });
            }
            setLoader(false);
            setIsResetDialogOpened(false);
            setOtp('');
        });
    };

    const pinOnChange = (value: any): void => {
        setOtp(value);
    };

    // UI Elements
    return (
        <Box className="profile-card">
            <Box className="card-header">
                <Typography className="title" variant="h3">
                    Security 2FA
                </Typography>
            </Box>
            {/* <Divider /> */}
            {!profileInfo.twoFactorSetupCompleted && !twoFaProgress && (
                <Typography variant="body1" className="subtitle" id="textsmall">
                    Activate Two Factor Authentication using Google
                    Authenticator. 2FA is a security feature that helps to
                    protect your account.
                </Typography>
            )}
            {profileInfo.twoFactorEnabled && (
                <Typography variant="body1" className="subtitle" id="textsmall">
                    You have activated the Two Factor Authentication.2FA is a
                    security feature that helps to protect your account.
                </Typography>
            )}
            {!profileInfo.twoFactorSetupCompleted && twoFaProgress && (
                <Typography variant="body1" className="subtitle" id="textsmall">
                    Use google authenticator to get your code, without this code
                    you can&apos;t login to your account.
                </Typography>
            )}
            {!profileInfo.twoFactorEnabled &&
                profileInfo.twoFactorSetupCompleted && (
                    <Typography
                        variant="body1"
                        className="subtitle"
                        id="textsmall"
                    >
                        Enable Two Factor Authentication using Google
                        Authenticator. 2FA is a security feature that helps to
                        protect your account.
                    </Typography>
                )}

            <Box>
                {!profileInfo.twoFactorSetupCompleted && !twoFaProgress && (
                    <AppPrimaryButton
                        title="SETUP 2FA"
                        className="btnContent"
                        onClick={actionSetupTwoFA}
                        isLoading={loader}
                        loadingText="SETTING"
                        disabled={loader}
                    />
                )}

                {profileInfo.twoFactorEnabled === true &&
                    profileInfo.twoFactorSetupCompleted === true && (
                        <>
                            <AppSecondaryButton
                                title="ENABLED"
                                disabled
                                className="mr-1"
                            />
                            <AppPrimaryButton
                                onClick={() => setIsResetDialogOpened(true)}
                                title="DISABLE 2FA"
                            />
                        </>
                    )}

                {!profileInfo.twoFactorSetupCompleted && twoFaProgress && (
                    <Grid container>
                        <Grid item lg={2} md={4} sm={12}>
                            <img
                                src={twoFASetupData?.url}
                                style={{ width: '90%' }}
                            />
                        </Grid>
                        <Grid item lg={10} md={8} sm={12} id="code-grid">
                            <div>
                                <Typography variant="h4">
                                    Authenticator Code
                                </Typography>
                                <br />
                                <Box className="flex">
                                    <Typography variant="body1">
                                        {twoFASetupData?.secret}
                                    </Typography>
                                    <CopyToClipboard
                                        text={
                                            twoFASetupData?.secret
                                                ? twoFASetupData?.secret
                                                : ''
                                        }
                                        onCopy={() =>
                                            setSecretCopied(!secretCopied)
                                        }
                                    >
                                        {secretCopied === true ? (
                                            <FileCopyIcon
                                                fontSize="small"
                                                id="copy-icon"
                                                className="ml-5 cursor"
                                            />
                                        ) : (
                                            <FileCopyOutlinedIcon
                                                fontSize="small"
                                                id="copy-icon"
                                                className="ml-5 cursor"
                                            />
                                        )}
                                    </CopyToClipboard>
                                </Box>
                                <br />
                                {/* <OtpInput
                                    value={otp}
                                    onChange={pinOnChange}
                                    numInputs={6}
                                    className="input-split"
                                    inputStyle="input-otp"
                                    isInputNum
                                    shouldAutoFocus
                                /> */}
                            </div>
                            <Box my={2}>
                                <AppPrimaryButton
                                    title="VERIFY"
                                    disabled={
                                        Object.entries(otp).length !== 6 ||
                                        loader
                                    }
                                    onClick={actionCompleteTwoFaSetup}
                                    isLoading={loader}
                                    loadingText="VERIFYING"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                )}

                {profileInfo.twoFactorEnabled === false &&
                    profileInfo.twoFactorSetupCompleted === true && (
                        <>
                            <AppSecondaryButton
                                title="DISABLED"
                                disabled
                                className="mr-1 mb-2"
                            />

                            <AppPrimaryButton
                                onClick={() => setIsResetDialogOpened(true)}
                                title="ENABLE 2FA"
                                className="mb-2"
                            />
                        </>
                    )}

                <Dialog open={isSuccessDialogOpened}>
                    <DialogTitle>
                        <Typography variant="h4">SUCCESS!</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="body1">
                            You have successfully completed the 2FA setup.
                            <br />
                            Use google authenticator as 2FA for future logins.
                        </Typography>
                    </DialogContent>
                    <Box my={1}>
                        <DialogActions>
                            <AppPrimaryButton
                                onClick={() => setIsSuccessDialogOpened(false)}
                                title="OK"
                            />
                        </DialogActions>
                    </Box>
                </Dialog>

                <Dialog open={isResetDialogOpened}>
                    <DialogTitle>
                        <Box className="title-section">
                            <Typography align="center" variant="h3">
                                {profileInfo.twoFactorEnabled
                                    ? 'DISABLE 2FA'
                                    : 'ENABLE 2FA'}
                            </Typography>
                            <IconButton
                                onClick={() => setIsResetDialogOpened(false)}
                            >
                                <CloseIcon color="primary" />
                            </IconButton>
                        </Box>
                        <Divider />
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="body1">
                            Are you sure wants to{' '}
                            {profileInfo.twoFactorEnabled
                                ? 'disable'
                                : 'enable'}{' '}
                            your 2FA. Enter the code from your authenticator
                            app.
                        </Typography>

                        <Box mt={3}>
                            {/* <OtpInput
                                value={otp}
                                onChange={pinOnChange}
                                numInputs={6}
                                className="input-split"
                                inputStyle="input-otp"
                                isInputNum
                                shouldAutoFocus
                            /> */}
                        </Box>
                    </DialogContent>
                    <Box my={1}>
                        <DialogActions>
                            <AppTeritaryButton
                                onClick={() => setIsResetDialogOpened(false)}
                                title="NO"
                            />

                            <AppPrimaryButton
                                disabled={
                                    loader || Object.entries(otp).length !== 6
                                }
                                title={
                                    profileInfo.twoFactorEnabled
                                        ? 'DISABLE'
                                        : 'ENABLE'
                                }
                                onClick={actionToggleTwoFA}
                                isLoading={loader}
                                loadingText={
                                    profileInfo.twoFactorEnabled
                                        ? 'DISABLING'
                                        : 'ENABLING'
                                }
                            />
                        </DialogActions>{' '}
                    </Box>
                </Dialog>
            </Box>
        </Box>
    );
};

const mapStateToProps = (state: AppState): any => ({
    profileInfo: state.profileInfo,
});

const mapDispatchToProps = (dispatch: any): any => ({
    setProfileData: (data: any) =>
        dispatch(ProfileActions.setProfileData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorForm);
