import { AuthActions } from '../../state/authentication/Action';
import { ProfileActions } from '../../state/profile/Actions';
import { defaultProfileInfo } from '../../state/profile/Reducer';
import store from '../../state/RootReducer';
import { RestEnds } from '../constants';
import AppPreference from './AppPreference';

const axios = require('axios');

// Axios global Config
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

/**
 *  Used to modify the request headers common for all requests
 */
axios.interceptors.request.use((configInfo: any) => {
    const config = configInfo;
    // Set request content type
    config.headers['Content-Type'] = 'application/json';
    let accessToken = AppPreference.getBetaAccessToken();
    if (accessToken !== null) {
        config.headers['x-auth-beta-token'] = `bearer ${accessToken}`;
    }

    // Append auth header
    if (config.headers['append-auth-header'] === 'true') {
        if (AppPreference.getAccessToken() != null) {
            accessToken = AppPreference.getAccessToken();
            config.headers['x-auth-token'] = `bearer ${accessToken}`;
        }
    }
    return config;
});

// Add a request interceptor
axios.interceptors.request.use((config: any) => {
    store.dispatch(AuthActions.isAPICalling(true))
    return config
}, (error: any) => {
    store.dispatch(AuthActions.isAPICalling(true))
    return Promise.reject(error)
})

// Add a response interceptor
axios.interceptors.response.use((response: any) => {
    store.dispatch(AuthActions.isAPICalling(false))
    return response
}, (error: any) => {
    store.dispatch(AuthActions.isAPICalling(false))
    return Promise.reject(error)
})

// To refresh accessToken and retry failed requests
let isAlreadyFetchingAccessToken = false;
let subscribers: any[] = [];
const onAccessTokenFetched = (accessToken: string): void => {
    subscribers = subscribers.filter((callback) => callback(accessToken));
};

const addSubscriber = (callback: any): void => {
    subscribers.push(callback);
};

const logout = (): void => {
    store.dispatch(ProfileActions.setProfileData(defaultProfileInfo));
    store.dispatch(AuthActions.setLoginStatus(false));
    AppPreference.clearLoginToken();
};

const betaLogout = (): void => {
    logout();
    store.dispatch(AuthActions.setBetaUserStatus(false));
    AppPreference.clearBetaToken();
};

// Function that will be called to refresh authorization
const refreshAccessToken = (): any =>
    axios
        .post(
            RestEnds.REFRESH_TOKEN,
            {},
            {
                headers: {
                    'x-auth-token': `bearer ${AppPreference.getRefreshToken()}`,
                },
            }
        )
        .then((response: any) => {
            const { accessToken, refreshToken } = response.data.data;
            AppPreference.setAccessToken(accessToken);
            AppPreference.setRefreshToken(refreshToken);
            isAlreadyFetchingAccessToken = false;
            onAccessTokenFetched(accessToken);
        })
        .catch(() => {
            subscribers = [];
            logout();
        });

axios.interceptors.response.use(
    (response: any) => response,
    (error: any) => {
        const { config, response } = error;
        const originalRequest = config;
        if (response?.status === 412) {
            betaLogout();
        } else if (
            response?.status === 401 &&
            config.url !== RestEnds.REFRESH_TOKEN
        ) {
            if (!isAlreadyFetchingAccessToken) {
                isAlreadyFetchingAccessToken = true;
                refreshAccessToken();
            }
            const retryOriginalRequest = new Promise((resolve) => {
                addSubscriber((accessToken: string) => {
                    originalRequest.headers[
                        'x-auth-token'
                    ] = `bearer ${accessToken}`;
                    resolve(axios(originalRequest));
                });
            });
            return retryOriginalRequest;
        }
        return Promise.reject(error);
    }
);

export default axios;
