import { Grid, Typography, Box, Divider, Container } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { ReactElement, useState } from 'react';
import { Formik } from 'formik';
import {
    AppPrimaryButton,
    AppSecondaryButton,
    AppTeritaryButton,
    AppFormikTextField,
    AppFormikTextArea,
    AppCheckBox,
    AppSelect,
    AppNavButton,
    AppThemeRadioInput,
} from './AppComponents';

const DesignBook = (): ReactElement => {
    // Instance Variables
    const [checked, setChecked] = useState(true);
    const [radioValue, setRadioValue] = useState('one');
    return (
        // UI Elements
        <Container maxWidth="md">
            <Grid id="design-book" container>
                <Grid item xs={12}>
                    <Box my={2}>
                        <Typography className="mb-4" variant="h4">
                            Typography
                        </Typography>
                        <Divider />
                    </Box>
                    <Typography className="mt-4" variant="h1">
                        Heading H1
                    </Typography>
                    <Typography className="mt-4" variant="h2">
                        Heading H2
                    </Typography>
                    <Typography className="mt-4" variant="h3">
                        Heading h3
                    </Typography>
                    <Typography className="mt-4" variant="h4">
                        Heading h4
                    </Typography>
                    <Typography className="mt-4" variant="h5">
                        Heading h5
                    </Typography>
                    <Typography className="mt-4" variant="h6">
                        Heading h6
                    </Typography>
                    <Typography className="mt-4" variant="body1">
                        Body 1
                    </Typography>
                    <Typography className="mt-4" variant="body2">
                        Body 2
                    </Typography>
                    <Typography className="mt-4" variant="caption">
                        Caption
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box mt={2}>
                        <Box my={2}>
                            <Typography className="mb-4" variant="h3">
                                Checkbox
                            </Typography>
                            <Divider />
                        </Box>
                        <AppCheckBox
                            label="App Material Checkbox"
                            value={checked}
                            onChange={(status: boolean): void => {
                                setChecked(status);
                            }}
                        />
                    </Box>

                    <Box mt={2}>
                        <Box my={2}>
                            <Typography className="mb-4" variant="h3">
                                Radio Input
                            </Typography>
                            <Divider />
                        </Box>
                        <AppThemeRadioInput
                            onChange={(value: string) => {
                                setRadioValue(value);
                            }}
                            selectedValue={radioValue}
                            value="one"
                            label={<Typography>Radio One</Typography>}
                        />
                        <AppThemeRadioInput
                            onChange={(value: string) => {
                                setRadioValue(value);
                            }}
                            selectedValue={radioValue}
                            value="two"
                            label="Radio Two"
                        />
                    </Box>

                    <Box mt={2}>
                        <Box my={2}>
                            <Typography className="mb-4" variant="h3">
                                Text Field
                            </Typography>
                            <Divider />
                        </Box>
                        <Formik
                            initialValues={{
                                smallInput: 'Small Input',
                                largeInput: 'Large Input',
                                password: 'Password',
                                multiLine: 'Multiline',
                                readOnly: 'Read only',
                                selectMenu: 1,
                            }}
                            enableReinitialize
                            onSubmit={() => {
                                // Call form submit action here
                            }}
                        >
                            {() => (
                                <Grid item xs={12}>
                                    <AppFormikTextField
                                        name="smallInput"
                                        label="Small Input"
                                    />
                                    <AppFormikTextField
                                        name="largeInput"
                                        label="Large Input"
                                        size="large"
                                    />

                                    <AppFormikTextField
                                        name="readOnly"
                                        label="Read only"
                                        size="large"
                                        readOnly
                                    />
                                    <AppFormikTextField
                                        name="password"
                                        label="Password"
                                        size="large"
                                        type="password"
                                    />
                                    <AppFormikTextArea
                                        name="multiLine"
                                        label="Multi Line"
                                        rows={4}
                                    />
                                    <AppSelect
                                        name="selectMenu"
                                        items={[
                                            { title: 'Menu One', value: 1 },
                                            { title: 'Menu Two', value: 2 },
                                        ]}
                                        titleKey="title"
                                        menuKey="value"
                                    />
                                    <Typography
                                        className="mt-4 mb-4"
                                        variant="h4"
                                    >
                                        Outer Label Variants
                                    </Typography>
                                    <AppFormikTextField
                                        name="largeInput"
                                        label="Large Input Outer Label"
                                        size="large"
                                        outerLabel
                                        placeholder="Placeholder"
                                    />
                                    <AppFormikTextArea
                                        name="multiLine"
                                        label="Multi Line Outer Label"
                                        rows={4}
                                        outerLabel
                                        placeholder="Placeholder"
                                    />

                                    <Typography
                                        className="mt-4 mb-4"
                                        variant="h4"
                                    >
                                        Outer Label Variants
                                    </Typography>
                                    <AppFormikTextField
                                        name="largeInput"
                                        label="Large Input Outer Label"
                                        size="large"
                                        outerLabel
                                        placeholder="Placeholder"
                                    />
                                </Grid>
                            )}
                        </Formik>
                    </Box>
                    <Box mt={2}>
                        <Box my={2}>
                            <Typography className="mb-4" variant="h3">
                                Buttons
                            </Typography>
                            <Divider />
                        </Box>
                        <Typography className="mb-4" variant="h4">
                            Primary Button
                        </Typography>

                        <AppPrimaryButton title="Primary Button" />
                        <AppPrimaryButton title="Primary Button" isLoading />
                        <AppPrimaryButton
                            title="Primary Button"
                            isLoading
                            disabled
                        />
                        <AppPrimaryButton
                            title="Primary Button With Icon"
                            endIcon={<ArrowForwardIosIcon />}
                        />
                        <AppPrimaryButton
                            title="Small Button"
                            className="btn-small"
                        />
                        <AppPrimaryButton
                            title="Small Button"
                            className="btn-small"
                            endIcon={<ArrowForwardIosIcon />}
                        />
                        <AppPrimaryButton title="Primary Button" disabled />
                    </Box>
                    <Box>
                        <Typography variant="h3">Secondary Button</Typography>
                        <AppSecondaryButton title="Secondary Button" />
                        <AppSecondaryButton
                            title="Secondary Button"
                            isLoading
                        />
                        <AppSecondaryButton
                            title="Secondary Button With Icon"
                            endIcon={<ArrowForwardIosIcon />}
                        />
                        <AppSecondaryButton
                            title="Small Button"
                            className="btn-small"
                        />
                        <AppSecondaryButton
                            title="Small Button"
                            className="btn-small"
                            endIcon={<ArrowForwardIosIcon />}
                        />
                        <AppSecondaryButton
                            disabled
                            title="Disabled Button"
                            className="btn-small"
                            endIcon={<ArrowForwardIosIcon />}
                        />
                    </Box>
                    <Box className="teritary-container">
                        <Typography variant="h4">Teritary Button</Typography>
                        <AppTeritaryButton title="Teritary Button" />
                        <AppTeritaryButton title="Teritary Button" isLoading />
                        <AppTeritaryButton
                            title="Teritary Button With Icon"
                            endIcon={<ArrowForwardIosIcon />}
                        />
                        <AppTeritaryButton
                            title="Small Button"
                            className="btn-small"
                        />
                        <AppTeritaryButton
                            title="Small Button"
                            className="btn-small"
                            endIcon={<ArrowForwardIosIcon />}
                        />
                        <AppTeritaryButton title="Teritary Button" disabled />
                    </Box>

                    <Box className="">
                        <Typography variant="h4">Nav Button</Typography>
                        <AppNavButton to="/style" title="Navigation Button" />
                    </Box>

                    <Box my={2}>
                        <Typography className="mt-4 mb-4" variant="h4">
                            Palettes
                        </Typography>
                        <Divider />
                    </Box>
                    <Grid container className="palettes">
                        <div className="palette primary">
                            <Typography>
                                Primary <br /> #8522fa
                            </Typography>
                        </div>
                        <div className="palette text-primary">
                            <Typography>
                                Text Primary <br /> #151515
                            </Typography>
                        </div>
                        <div className="palette text-secondary">
                            <Typography>
                                Text Secondary
                                <br />
                                #333333
                            </Typography>
                        </div>
                        <div className="palette text-gray">
                            <Typography>
                                Text Gray
                                <br />
                                #333333
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};
export default DesignBook;
