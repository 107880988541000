import { ReactElement } from 'react';
import { HeadTag } from '../../shared/components';
import { ForgotPasswordForm } from './components';

const ForgotPasswordPage = (): ReactElement => (
    <>
        <HeadTag title="Forgot Password" />
        <ForgotPasswordForm />
    </>
);

export default ForgotPasswordPage;
