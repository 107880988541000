import { ReactElement, useState, useEffect } from 'react';
import {
    Box,
    IconButton,
    Card,
    CardContent,
    Typography,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Grid,
    // Divider,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
// import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import BankAccountForm from './BankAccountForm';
// import ProfileEmptyPlaceholder from '../../../shared/components/ProfileEmptyPlaceHolder';
import { Utils } from '../../../shared/helpers';
import { ProfileService } from '../../../services';
import { AppPrimaryButton } from '../../../shared/components/AppComponents';

export interface BankAccountInfo {
    id: string;
    name: string;
    bankName: string;
    accountNumber: string;
    confirmAccountNumber: string;
    IFSCCode: string;
    IBANCode: string;
    swiftCode: string;
    additionalDetails: string;
}

const BankAccountList = (): ReactElement => {
    const [openAccountCreateModal, setOpenAccountCreateModal] = useState(false);
    const [bankAccountInfoToEdit, setBankAccountInfoToEdit] = useState<
        BankAccountInfo | undefined
    >(undefined);
    const [accountDetails, setAccountDetails] = useState<BankAccountInfo[]>([]);
    const [isLoading, setLoading] = useState(false);
    const [modalFormType, setModalFormType] = useState('');

    // API Calls
    const loadAccountDetails = (): any => {
        setLoading(true);
        ProfileService.getAccountDetails()
            .then((response: any) => {
                const Response = response.data.data.bankDetails;
                setAccountDetails(Response);
                setLoading(false);
            })
            .catch((error: any) => {
                setLoading(false);
                Utils.handleErrorResponse({ error });
            });
    };

    const actionDeleteAccountDetails = (id: string): any => {
        setLoading(true);
        ProfileService.deleteAccountDetails(id)
            .then((response: any) => {
                setLoading(false);
                Utils.handleSuccessResponse(response);
                loadAccountDetails();
            })
            .catch((error: any) => {
                setLoading(false);
                Utils.handleErrorResponse({ error });
            });
    };

    // Hooks
    useEffect(() => {
        loadAccountDetails();
    }, [openAccountCreateModal]);

    // UI Actions
    const actionCancel = (): void => {
        setOpenAccountCreateModal(false);
        setModalFormType('');
        loadAccountDetails();
    };

    const actionOnAddAccount = (): void => {
        setModalFormType('Add');
        setOpenAccountCreateModal(true);
        setBankAccountInfoToEdit(undefined);
    };

    const actionOpenEditModal = (accountdetail: any): any => {
        setOpenAccountCreateModal(true);
        setModalFormType('Edit');
        setBankAccountInfoToEdit(accountdetail);
    };

    // UI Elements
    return (
        <Box className="profile-card">
            <Box className="card-header-btn">
                <Typography className="title" variant="h3">
                    Bank Accounts
                </Typography>

                {/* <AppTeritaryButton
                    title="ADD NEW"
                    className="btn-small"
                    startIcon={<AddIcon />}
                    onClick={actionOnAddAccount}
                /> */}
            </Box>
            {/* <Divider /> */}
            {accountDetails.length === 0 && (
                <Box className='adress-sub-wrapper'>
                    <Box className='address-line'>
                        {/* {accountDetails.length === 0 && (
                    <> */}
                        <Typography variant="body2" className="subtitle subtitle-bank-line">
                            Manage your bank accounts to withdraw fiat currency.
                        </Typography>
                        {/* <Typography variant="body1" className="mt-5" id="textsmall">
                            You have not added your bank account yet.
                        </Typography>{' '} */}
                        {/* </>
                )} */}
                    </Box>
                    <Box className="adress-data adress-data-add-btn">
                        {/* {accountDetails.length === 0 && ( */}
                        <AppPrimaryButton
                            title="Add new"
                            className="primary-btn btnContent  addressBtn"
                            // startIcon={<AddIcon />}
                            onClick={actionOnAddAccount}
                        />
                        {/* )} */}
                    </Box>
                </Box>
            )}


            <Box>
                {accountDetails.length > 0 && (
                    <Grid container>
                        {!isLoading &&
                            accountDetails.map((account) => (
                                <Grid
                                    key={account.accountNumber}
                                    item
                                    xs={12}
                                    xl={12}
                                    className="address-data-box"
                                >
                                    <Card>
                                        <CardContent className="profile-card bank-profile-card-box">
                                            <Box className="space-content space-content-bank">
                                                <Typography
                                                    variant="body1"
                                                    className="align-center"
                                                >
                                                    {account.bankName}
                                                </Typography>

                                                <Box>
                                                    <IconButton
                                                        onClick={() =>
                                                            actionDeleteAccountDetails(
                                                                account.id
                                                            )
                                                        }
                                                    >
                                                        <DeleteOutlineIcon
                                                            color="primary"
                                                            fontSize="small"
                                                        />
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() =>
                                                            actionOpenEditModal(
                                                                account
                                                            )
                                                        }
                                                    >
                                                        <EditIcon
                                                            color="primary"
                                                            fontSize="small"
                                                        />
                                                    </IconButton>
                                                </Box>
                                            </Box>

                                            <Box mt={2}>
                                                <TableContainer>
                                                    <Table className="profile-card-table">
                                                        <TableBody>
                                                            {/* <Typography
                                                                variant="body1"
                                                                className="mb-4"
                                                            >
                                                                {account.name}
                                                            </Typography> */}

                                                            {/* <TableRow>
                                                                <TableCell
                                                                    component="th"
                                                                    scope="row"
                                                                    className="w-100"
                                                                >
                                                                    Bank Name 
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                {account.bankName}
                                                                </TableCell>
                                                            </TableRow> */}

                                                            <TableRow>
                                                                <TableCell
                                                                    component="th"
                                                                    scope="row"
                                                                    className="w-100"
                                                                >
                                                                    Account Holder Name :
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {account.name}
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell
                                                                    component="th"
                                                                    scope="row"
                                                                    className="w-100"
                                                                >
                                                                    Account Number :
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    ************
                                                                    {Utils.getLastFourDigit(
                                                                        account.accountNumber
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell
                                                                    component="th"
                                                                    scope="row"
                                                                    className="w-100"
                                                                >
                                                                    Bank Name :
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {
                                                                        account.bankName
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                            {account.swiftCode && (
                                                                <TableRow>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        className="w-100"
                                                                    >
                                                                        Swift
                                                                        Code :
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                        {
                                                                            account.swiftCode
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                    </Grid>
                )}


            </Box>
            {openAccountCreateModal && (
                <BankAccountForm
                    modalFormType={modalFormType}
                    openAccountModal={openAccountCreateModal}
                    actionOnCancel={actionCancel}
                    accountDetails={accountDetails}
                    bankAccountInfoToEdit={bankAccountInfoToEdit}
                />
            )}
        </Box>
    );
};

export default BankAccountList;
