import { ReactElement, useEffect, useState } from 'react';
import { Box, Typography, Grid, Dialog, DialogContent, DialogTitle, Divider, IconButton } from '@material-ui/core';
import { useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { Wallet, FiatWallet } from './WalletBalance';
import { BuySellService, ProfileService } from '../../../services';
import { Utils } from '../../../shared/helpers';
import { AppPrimaryButton } from '../../../shared/components/AppComponents';
import WithDrawAmount from './WithDrawAmount';


const MiniWalletSection = (props: any): ReactElement => {
    const { actionOpenSetting } = props
    const [openWithdrawModal, setOpenWithdrawModal] = useState(false);
    const profileInfo = useSelector((state: any) => state.profileInfo);
    const appState = useSelector((state: any) => state.applicationState);
    // const { profileInfo } = props;
    // const { appState } = props;
    const [cryptoWallets, setCryptoWallets] = useState<Wallet[]>([]);
    const [fiatWallet, setFiatWallet] = useState<FiatWallet>({} as FiatWallet);
    const [loadingWallet, setLoadingWallet] = useState(false);
    const [withDrawType, setWithDrawType] = useState('fiat');
    // API Calls
    const loadWalletBalance = (): void => {
        setLoadingWallet(true);
        ProfileService.walletBalance()
            .then((response) => {
                const wallet = response.data.data;
                setCryptoWallets(wallet.cryptoWallet);
                setFiatWallet(wallet.fiatWallet);
                setLoadingWallet(false);
            })
            .catch((error) => {
                Utils.handleErrorResponse(error);
                setLoadingWallet(false);
            });
    };
    const onWithDrawCancel = (): void => setOpenWithdrawModal(false);

    const onWithDrawSuccess = (): void => {
        setOpenWithdrawModal(false);
        loadWalletBalance();
    };

    // Hooks
    useEffect(() => {
        setTimeout(()=>{

            loadWalletBalance();
        },1000)
        BuySellService.loadCryptoPaymentAvailability();
    }, []);

    // UI Elements
    return (
        <>
            {!loadingWallet && (
                <Grid container className="" spacing={2}>
                    {/* <Box className="space-content" mb={4}>
                        <Typography variant="body1">
                            My Wallet Balance
                        </Typography>
                    </Box> */}



                    {/* {cryptoWallets && cryptoWallets.map((wallet) => (
                        <>
                            {((appState.acceptCrypto &&
                                wallet?.walletName === 'Fantico Wallet') ||
                                wallet?.walletName === 'Meta Wallet') && (
                                <>
                                    <Grid item xs={6} className='sub-cards-wrapper'>
                                        <Box mb={2} className="sub-cards">
                                            <Typography variant="body1" className='p-0'>
                                                TUS Wallet
                                            </Typography>

                                            {wallet &&
                                            wallet?.balances.map((balance) => (
                                                <Box className='sub-cards-value' >
                                                    {`${balance?.balance} ${balance?.token}`}
                                                </Box>
                                            ))}
                                            <AppPrimaryButton
                                                title="Withdraw"
                                                className=" primary-btn btnContent wallet-btn"
                                                onClick={() => {
                                                    setOpenWithdrawModal(true);
                                                    setWithDrawType('fiat');
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                </>
                            )}
                        </>
                    ))} */}
                    <Grid item xs={7} sm={8} md={6} className='sub-cards-wrapper'>
                        <Box className="sub-cards">
                            <Box>
                            <Typography variant="body1">Fiat Wallet</Typography>                            
                            
                            <Box className='sub-cards-value' >
                                {
                                // `${fiatWallet.totalBalance === undefined? "Loading...":fiatWallet.totalBalance} ${fiatWallet?.currencyType=== undefined? "":fiatWallet?.currencyType}`
                                `${fiatWallet.freeBalance === undefined? "Loading...":fiatWallet.freeBalance} ${fiatWallet?.currencyType=== undefined? "":fiatWallet?.currencyType}`
                                }
                            </Box>
                            </Box>
                            <AppPrimaryButton
                                title="Withdraw"
                                className=" primary-btn btnContent wallet-btn wallet-btn-profile"
                                onClick={() => {
                                    setOpenWithdrawModal(true);
                                    setWithDrawType('fiat');
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            )}
            <Dialog
                maxWidth="sm"
                fullWidth
                open={openWithdrawModal}
                className="withdraw-container"
            >
                <DialogTitle className='withdraw-popup-wrapper'>
                    <Box className="title-section">
                        <Typography variant="h4" className='withdraw-popup-title'>Withdraw</Typography>
                        <IconButton onClick={onWithDrawCancel}>
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                    <Divider />
                </DialogTitle>

                <DialogContent className='withdraw-text-box'>
                    <WithDrawAmount
                        profileInfo={profileInfo}
                        withDrawType={withDrawType}
                        onWithDrawCancel={onWithDrawCancel}
                        onWithDrawSuccess={onWithDrawSuccess}
                        actionOpenSetting={actionOpenSetting}
                        {...props}
                    />
                </DialogContent>
            </Dialog>
        </>
        
    );
    
};

// const mapStateToProps = (state: any): any => ({
//     profileInfo: state.profileInfo,
//     appState: state.applicationState,
// });

export default MiniWalletSection;
