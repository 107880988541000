import { ReactElement, useEffect, useMemo } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import { ThemeProvider, Box } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { GoogleOAuthProvider } from '@react-oauth/google';
import routes from './Routes';
import theme from './assets/styles/theme';
import { AppState } from './state/RootReducer';
import './assets/styles/App.scss';
import { BuySellService } from './services/BuySellService';
import { Utils } from './shared/helpers';
// import { BuySellService } from './services';

toast.configure();

const ScrollToTop = (): ReactElement => {
    const { pathname } = useLocation();
    const pagesWithWhiteHeader = [
        '/marketplace',
        '/activity',
        '/profile',
        '/login',
        '/verify-email',
        '/forgot-password',
        '/reset-password',
        '/security-verification',
        '/email-confirmation',
        '/metamask-signup',
        '/create-wallet',
        '/user',
    ];
    const canShowWhiteHeader = useMemo(() => {
        for (let i = 0; i < pagesWithWhiteHeader.length; i += 1) {
            const route = pagesWithWhiteHeader[i];
            if (pathname.indexOf(route) > -1) {
                return true;
            }
        }
        return false;
    }, [pathname]);

    useEffect(() => {
        const element = document.getElementsByClassName('app-top-header')[0];

        const listner = (): void => {
            const isTop = window.scrollY < 50;
            if (isTop && !canShowWhiteHeader) {
                element.classList.remove('dark-header');
            } else {
                element.classList.add('dark-header');
            }
        };

        if (canShowWhiteHeader&&element.classList) {
            element.classList.add('dark-header');
        } else if(element){
            element.classList.remove('dark-header');
        }
        window.scrollTo(0, 0);
        document.addEventListener('scroll', listner);
        return () => {
            document.removeEventListener('scroll', listner);
         }
     
    }, [pathname]);
    return <></>;
};

const App = (props: any): ReactElement => {
    const { isUserLoggedIn, emailId } = props;
    const routing = useRoutes(routes(isUserLoggedIn, emailId));

    useEffect(() => {
        BuySellService.loadCurrencyAndStore();
        Utils.clearCache()
    }, []);

    // UI Elements
    return (
        <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
            <ThemeProvider theme={theme}>
                <Helmet>
                    <script 
                        src="https://hv-camera-web-sg.s3-ap-southeast-1.amazonaws.com/hyperverge-web-sdk@4.1.6/src/sdk.min.js" 
                        type="text/javascript"  
                    />
                </Helmet>
                <ScrollToTop />

                <Box>{routing}</Box>
                <ToastContainer />
            </ThemeProvider>
        </GoogleOAuthProvider>
    );
};

const mapStateToProps = (state: AppState): any => ({
    isUserLoggedIn: state.authState.isUserLoggedIn,
    emailId: state.profileInfo.emailId,
});

export default connect(mapStateToProps)(App);
