import axios from './AxiosManager';
import { AuthenticationMock } from '../../services/mock';

const MockAdapter = require('axios-mock-adapter');

class APIManager {
    mockAPI: string | undefined = 'false';

    constructor(mockAPI: string | undefined) {
        this.mockAPI = mockAPI;
        this.includeMocks();
    }

    includeMocks(): void {
        if (this.mockAPI === 'true') {
            // This sets the mock adapter on the default instance
            const mockAxios = new MockAdapter(this.getProvider(), {
                delayResponse: 1000,
            });
            AuthenticationMock(mockAxios);
        }
    }

    getProvider(): any {
        return axios;
    }

    getConfig(auth: boolean): any {
        const config: any = {
            headers: {
                'append-auth-header': auth ? 'true' : 'false',
            },
        };
        return config;
    }

    sendPost(url: string | string, postData: any, auth = false): Promise<any> {
        return this.getProvider().post(url, postData, this.getConfig(auth));
    }

    sendPatch(url: string | string, postData: any, auth = false): Promise<any> {
        return this.getProvider().patch(url, postData, this.getConfig(auth));
    }

    sendGet(url: string | string, auth = false): Promise<any> {
        return this.getProvider().get(url, this.getConfig(auth));
    }

    sendGetWithBody(
        url: string | string,
        postData: any,
        auth = false
    ): Promise<any> {
        const config = this.getConfig(auth);
        config.params = postData;
        return this.getProvider().get(url, config);
    }

    sendDelete(url: string | string, auth = false): Promise<any> {
        return this.getProvider().delete(url, this.getConfig(auth));
    }
}

export default new APIManager('false');
