import { ReactElement } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import LazyLoad from 'react-lazyload';
import { HeadTag } from '../../../shared/components';
import HowItWorks from './howItWork';

const Works = (): ReactElement => {
    const title = 'About';
    const description =
        "About";

    return (
        <LazyLoad placeholder={<div>...</div>}>
            <Box id="works-wrapper" className='works-main-wrapper-py'>
                <HeadTag title={title} description={description} />
                <Grid container className="banner-wrapper" alignItems="center">
                    <Grid xs={12} id="About">
                        <Box className='about-title-box works-py'>
                            <Typography className="about-title">How it Works</Typography>
                        </Box>
                    </Grid>
                    <HowItWorks />
                </Grid>
            </Box>
        </LazyLoad>

    )
}

export default Works