import { useEffect, useState, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Divider, Typography, Grid } from '@material-ui/core';
import { Utils } from '../../../shared/helpers';
import { AppTeritaryButton } from '../../../shared/components/AppComponents';
import { AuthenticationService } from '../../../services';

const EmailVerifiedForm = (props: any): ReactElement => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState('');

    // API Calls
    const verifyEmail = (values: any): any => {
        AuthenticationService.verifyEmail(values)
            .then((response: any) => {
                const { msg } = response.data;
                setData(msg);
                Utils.handleSuccessResponse(response);
                setLoading(false);
            })
            .catch((error: any) => {
                setLoading(false);
                Utils.handleErrorResponse({ error });
                navigate('/');
            });
    };

    // Hooks
    useEffect(() => {
        const token = props.id;
        const requestObj = {
            code: token,
        };
        verifyEmail(requestObj);
    }, []);

    // UI Actions
    const actionVerify = (): any => {
        navigate('/login');
        setLoading(true);
    };

    // UI Elements
    return (
        <>
            <Box className="auth-bg">
                <Box className="auth-content email-verify-content ">
                    {/* <Box className='email-verify'>s</Box> */}
                    <Grid item xs={12}>
                        <Typography className="mb-4 auth-main-title" variant="h3">
                            Email 
                        </Typography>
                        <Divider className='auth-divider'/>

                        <Typography
                            className="m-10"
                            variant="body1"
                            align="center"
                        >
                            {data === ''
                                ? 'Your account has been processing.'
                                : data}
                        </Typography>

                        {data !== '' && (
                            <AppTeritaryButton
                                title="GO TO LOGIN"
                                fullWidth
                                isLoading={isLoading}
                                loadingText="LOGGING IN"
                                onClick={actionVerify}
                            />
                        )}
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

export default EmailVerifiedForm;
