import ApiManager from '../shared/managers/APIManager';
import { RestEnds } from '../shared/constants';
import store from '../state/RootReducer';
import { ApplicationActions } from '../state/application/Action';

const getCalculation = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.CALCULATION, data, true);

const placeSellItem = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.PLACE_ITEM_LIST, data, true);

const cancelSellItem = async (id: string): Promise<any> =>
    ApiManager.sendPost(`${RestEnds.PLACE_ITEM_LIST}/${id}/cancel`, null, true);

const initiateBuy = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.BUY_INITIATE, data, true);

const mintFee = async (data: any): Promise<any> => 
    ApiManager.sendPost(RestEnds.BUY_MINT_FEE, data, true);

const initiateBuyTripleA = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.BUY_INITIATE_TRIPLEA, data, true);

const initiateBuyCryptoCalculation = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.BUY_CRYPTO_CALCULATION, data, true);

const completeBuy = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.COMPLETE_BUY, data, true);

const buyFromEther = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.COMPLETE_BUY_FROM_ETHER, data, true);

const buyFracto = async (data: any): Promise<any> => 
    ApiManager.sendPost(RestEnds.BUY_FRACTO, data, true)

const successCashBack = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.CASH_BACK_SUCCESS, data, true)

const postABidUsingCrypto = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.POST_BID_FROM_ETHER, data, true);

const listForAuction = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.LIST_FOR_AUCTION, data, true);

const cancelAuction = async (auctionId: any): Promise<any> =>
    ApiManager.sendDelete(RestEnds.CANCEL_AUCTION(auctionId), true);

const placeBid = async (data: any): Promise<any> =>
    ApiManager.sendPatch(RestEnds.PLACE_BID, data, true);

const placeBidWithFracto = async (data: any): Promise<any> =>
    ApiManager.sendPatch(RestEnds.PLACE_BID_FRACTO, data, true);

const cancelBid = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.CANCEL_BID, data, true);

const getAuctions = async (
    itemId: string,
    limit: number,
    offset: number
): Promise<any> =>
    ApiManager.sendGet(RestEnds.AUCTIONS(itemId, limit, offset), true);

const getAuctionById = async (auctionId: string): Promise<any> =>
    ApiManager.sendGet(RestEnds.AUCTION_DETAIL(auctionId), true);

const convertPriceAndHold = async (
    amount: any,
    from: string,
    to: string
): Promise<any> =>
    ApiManager.sendGet(RestEnds.CURRENCY_HOLDING(amount, from, to), true);

const convertPrice = async (
    amount: any,
    from: string,
    to: string
): Promise<any> =>
    ApiManager.sendGet(RestEnds.CURRENCY_CONVERSION(amount, from, to), true);

const getStoredPrice = async (
    amount: any,
    from: string,
    to: string
): Promise<any> =>
    ApiManager.sendGet(
        RestEnds.STORED_CURRENCY_CONVERSION(amount, from, to),
        true
    );

const getOfferPrice = async (itemId: string): Promise<any> =>
    ApiManager.sendGet(RestEnds.OFFER_PRICE(itemId), true);

// const getOfferCalculation = async (data: any): Promise<any> =>
//     ApiManager.sendPost(RestEnds.OFFER_CALCULATION, data, true);

const initiateOffer = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.OFFER_INITIATE, data, true);


const initiateOfferCrypto = async (data:any) :Promise <any> =>
    ApiManager.sendPost(RestEnds.OFFER_INITIATE_CRYPTO,data,true)

const completeOffer = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.COMPLETE_OFFER, data, true);

const getOfferList = async (
    itemId: string,
    offset: number,
    limit: number
): Promise<any> =>
    ApiManager.sendGet(RestEnds.OFFER_LIST(itemId, offset, limit), true);

const acceptOffer = async (offerId: string): Promise<any> =>
    ApiManager.sendPatch(RestEnds.ACCEPT_OFFER(offerId), null, true);

const getRandomHex = async (): Promise<any> =>
    ApiManager.sendGet(RestEnds.RANDOM_HEX, true);

const loadCryptoPaymentAvailability = async (): Promise<any> => {
    ApiManager.sendGet(RestEnds.CRYPTO_SUPPORT, false).then((response: any) => {
        const { cryptoSupported } = response.data.data;
        store.dispatch(
            ApplicationActions.setCryptoCurrencyStatus(cryptoSupported)
        );
    });
};

const loadCurrency = async (): Promise<any> =>
    getStoredPrice(1, 'ETH', 'USD').then((result: any) => {
        const res = result.data.data;
        store.dispatch(ApplicationActions.setCurrencyConverstion(res));
    });

const loadCurrencyAndStore = (): void => {
    loadCurrency();
    setInterval(() => {
        loadCurrency();
    }, 600000);
};

export const BuySellService = {
    getCalculation,
    placeSellItem,
    cancelSellItem,
    initiateBuy,
    mintFee,
    initiateBuyTripleA,
    completeBuy,
    listForAuction,
    cancelAuction,
    placeBid,
    cancelBid,
    getAuctions,
    getAuctionById,
    initiateBuyCryptoCalculation,
    buyFromEther,
    buyFracto,
    convertPriceAndHold,
    convertPrice,
    loadCryptoPaymentAvailability,
    postABidUsingCrypto,
    getOfferPrice,
    initiateOffer,
    initiateOfferCrypto,
    completeOffer,
    getOfferList,
    acceptOffer,
    getRandomHex,
    loadCurrencyAndStore,
    placeBidWithFracto,
    successCashBack,
};
