import { Typography } from '@material-ui/core';
import { ReactElement, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AuthenticationService } from '../../../services';
import { AppSecondaryButton } from '../../../shared/components/AppComponents';
import { BlockChain, Toast, Utils } from '../../../shared/helpers';
import { AuthActions } from '../../../state/authentication/Action';
import { useAuth } from '../hooks/UseAuth';

const LoginMetaMask = (): ReactElement => {
    const navigate = useNavigate();
    const [loadingMetaMask, setLoadingMetaMask] = useState(false);
    const { processLoginResponse } = useAuth();

    // API Calls
    const loginWithMetamask = (loginData: any): void => {
        AuthenticationService.loginWithMetamask(loginData)
            .then((response: any) => {
                setLoadingMetaMask(false);
                processLoginResponse(response);
            })
            .catch((error: any) => {
                setLoadingMetaMask(false);
                Utils.handleErrorResponse({
                    error,
                });
            });
    };

    const ToastMetaMaskLink = (message: string): ReactElement => (
        <p>
            <Typography>
{ !message ?` You need MetaMask wallet to connect. Please first install the extension to continue. If you have just installed MetaMask, please make sure to close and reopen your browser. Still if you are unable to connect, please contact us at info@theupsidespace.com`:message}                <a
                    target="_blank"
                    rel="noreferrer"
                    className="text-underline"
                    href="https://metamask.io/download"
                >
                    {' '}
                </a>
            </Typography>
        </p>
    );

    // MetaMask Network Setup
    const setUpMetaMask = (): any => {
        setLoadingMetaMask(true);
        BlockChain.setUpMetaMaskForLogin()
            .then((response: any) => {
                AuthenticationService.getRegisterNonce({
                    address: response.address,
                })
                    .then((nonceResponse: any) => {
                        const responseData = nonceResponse.data.data;
                        const { nonce, type } = responseData;
                        BlockChain.verifySignature(response.address, nonce)
                            .then((verification) => {
                                if (type === 'login') {
                                    loginWithMetamask({
                                        address: verification.publicAddress,
                                        signature: verification.signature,
                                    });
                                } else {
                                    setLoadingMetaMask(false);
                                    navigate('/metamask-signup', {
                                        state: {
                                            publicAddress:
                                                verification.publicAddress,
                                            signature: verification.signature,
                                        },
                                    });
                                }
                            })
                            .catch((error) => {
                                setLoadingMetaMask(false);
                                if (error.code || error.message) {
                                    Toast.showErrorMessage(error.message);
                                } else {
                                    Toast.showErrorMessage(error);
                                }
                            });
                    })
                    .catch((error: any) => {
                        setLoadingMetaMask(true);
                        Utils.handleErrorResponse({ error });
                    });
            })
            .catch((error: any) => {
                setLoadingMetaMask(false);
                if (error.code || error.message) {
                    console.log(error)
                    Toast.showErrorMessage(ToastMetaMaskLink(error.message));
                } else {
                    Toast.showErrorMessage(error);
                }
            });
    };

    // UI Actions
    const actionMetaMask = (): any => {
        setUpMetaMask();
    };

    return (
        <>
                <img src="/images/MetaMask-Fox.svg" alt="" className='metamask-img-icons' />
            <AppSecondaryButton
                title={<span style={{paddingLeft:'6px'}}>Continue with MetaMask</span>}
                fullWidth
                className="googleBtn auth-label"
                isLoading={loadingMetaMask}
                loadingText="Submitting"
                onClick={actionMetaMask}
            />
        </>
    );
};

const mapDispatchToProps = (dispatch: any): any => ({
    setLoginStatus: (data: any) => dispatch(AuthActions.setLoginStatus(data)),
});

export default connect(null, mapDispatchToProps)(LoginMetaMask);
