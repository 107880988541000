export enum MarketFilterReduxActions {
    SET_REGION_LIST = 'SET_REGION_LIST',
    SET_EXHIBITION_LIST = 'SET_EXHIBITION_LIST',
    SET_MIN_MAX = 'SET_MIN_MAX',
}


const setRegionList = (payload: any): any => ({
    type: MarketFilterReduxActions.SET_REGION_LIST,
    payload
});

const setExhibitioinList = (payload: any): any => ({
    type: MarketFilterReduxActions.SET_EXHIBITION_LIST,
    payload
})

const setMinMax = (payload: any): any => ({
    type: MarketFilterReduxActions.SET_MIN_MAX,
    payload
})

export const MarketFilterAction = {
    setRegionList,
    setExhibitioinList,
    setMinMax,
}