import * as Yup from 'yup';

import {
    name,
    email,
    password,
    confirmPassword,
    newPassword,
    betaPassword,
    policy,
    token,
} from './Schema';

export const ForgotPasswordSchema = Yup.object().shape({ email });

export const ResetPasswordSchema = Yup.object().shape({
    newPassword,
    confirmPassword,
});

export const CreateAccountSchema = Yup.object().shape({
    name,
    email,
    password,
    policy,
});

export const BetaEmailVerificationSchema = Yup.object().shape({
    email,
});

export const BetaPasswordVerificationSchema = Yup.object().shape({
    password: betaPassword,
});

export const CreateAccountMetaMaskSchema = Yup.object().shape({
    name,
    email,
    policy,
});

export const LoginValidation = Yup.object().shape({
    email,
    password:Yup.string().required(),
});

export const SecurityVerificationSchema = Yup.object().shape({
    token,
});
