import { Box, Grid, Typography, Tooltip, CardMedia } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ReactElement } from 'react';
import CollectionsIcon from '@material-ui/icons/Collections';
import { EmptyPlaceholder } from '../../shared/components';
import { NumberPretty } from '../../shared/components/AppComponents';
import { getUrl, ethPrice } from '../../shared/helpers/Utils';
import { CurrencyConvertion } from '../../state/application/Reducer';
import { AppState } from '../../state/RootReducer';
import { NFTItem } from '../home/components';

const ExhibitionPost = ({ artsDetail }: any): ReactElement => {

    const exhibitionPostDetail = artsDetail;
    const convertion: CurrencyConvertion = useSelector(
        (state: AppState) => state.applicationState.currencyConverstion
    );
    /* eslint no-underscore-dangle: 0 */

    return (
        <>
            <Box className='market-slider'>
                <Grid container md={12} className="market-slider-wrapper-other">
                    {!exhibitionPostDetail && (
                        <EmptyPlaceholder
                            icon={<CollectionsIcon color="primary" />}
                            message="No arts found "
                        />
                    )}
                    {exhibitionPostDetail && exhibitionPostDetail.map((exeItem: any, index: any) => (
                        <>
                        <Grid xs={12} sm={6} md={4} lg={3} item className="main-my-grid-wrapper main-my-grid-wrapper-art" key={index}>
                            <Grid className='my-grid'>
                                <Link to={`/item/${exeItem._id}`}>
                                   <NFTItem {...exeItem}/>
                                </Link>
                            </Grid>
                        </Grid>

                        </>
                    ))}
                </Grid>
            </Box>
        </>
    )
}

export default ExhibitionPost
