import {
    // Accordion,
    // AccordionSummary,
    Typography,
    // AccordionDetails,
    // Grid,
    // TextField,
    Box,
    IconButton,
} from '@material-ui/core';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FilterList, Close } from '@material-ui/icons';
// import ClearIcon from '@material-ui/icons/Clear';
import { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { MarketPlaceActions } from '../../../state/marketplace/Action';
import { AppState } from '../../../state/RootReducer';
import { Utils } from '../../../shared/helpers';
import {
    // AppSecondaryButton,
    // AppThemeRadioInput,
    AppCheckBox, AppSecondaryButton,
} from '../../../shared/components/AppComponents';
import { ApplicationActions } from '../../../state/application/Action';
import { CollectionService } from '../../../services';
import { Category } from '../../../state/application/Reducer';
import { MarketPlaceFilterState } from '../../../state/marketplace/Reducer';
import ReginFilter from './ReginFilter';
import ExhibitionFilter from './ExhibitionFilter';
import CelebrityList from './CelebrityList';


interface FilterProps {
    marketPlaceFilter: MarketPlaceFilterState | any;
    setPrice: any;
    setFilter: any;
    resetAllFilter: any;
    categories: Category[];
    setCategories: any;
}
const ArtistFilters = (props: any): ReactElement => {
    const {
        marketPlaceFilter,
        // setPrice,
        setFilter,
        // resetAllFilter,
        // categories,
        setCategories,
    }: FilterProps = props;

    // const [minPrice, setMinPrice] = useState('');
    // const [maxPrice, setMaxPrice] = useState('');

    // const valuetext = (value: number):any => {
    //     console.log("value",value);
    //     return value
    // }

    // API Calls
    const loadCategories = (): void => {
        CollectionService.getAllCategory()
            .then((result: any) => {
                const res = result.data.data;
                setCategories(res);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
            });
    };

    // const actionOnReset = (): void => {
    //     setMinPrice('');
    //     setMaxPrice('');
    //     resetAllFilter();
    // };

    // Hooks
    useEffect(() => {
        // loadCategories();
        // actionOnReset()
    }, []);

    // UI Actions
    // const actionApplyFilter = (): void => {
    //     if (minPrice === '' || maxPrice === '') {
    //         Toast.showErrorMessage('Please enter min and max price');
    //     } else if (
    //         Number(minPrice) !== 0 &&
    //         Number(minPrice) > Number(maxPrice)
    //     ) {
    //         Toast.showErrorMessage('Max price value should be greater');
    //     } else {
    //         setPrice({ minPrice, maxPrice });
    //     }
    // };


    // UI Elements
    const StatusFilter = ({
        label,
        valueKey,
    }: {
        label: string;
        valueKey: string;
    }): ReactElement => (
        <AppCheckBox
            label={label}
            value={marketPlaceFilter[valueKey]}
            onChange={(status: boolean): void => {
                setFilter({
                    ...marketPlaceFilter,
                    [valueKey]: status,
                });
            }}
        />
    );
    const BlockChainFilter = ({
        label,
        value,
    }: {
        label: string;
        value: string;
    }): ReactElement => (
        <AppCheckBox
            label={label}
            value={marketPlaceFilter.blockChain.indexOf(value) > -1}
            onChange={(status: boolean): void => {
                const { blockChain } = marketPlaceFilter;
                const index = blockChain.indexOf(value);
                if (status) {
                    blockChain.push(value);
                } else {
                    blockChain.splice(index, 1);
                }
                setFilter({
                    ...marketPlaceFilter,
                    blockChain: [...blockChain],
                });
            }}
        />
    );
    // const TypeFilter = ({
    //     label,
    //     valueKey,
    // }: {
    //     label: string;
    //     valueKey: string;
    // }): ReactElement => (
    //     <AppCheckBox
    //         label={label}
    //         value={marketPlaceFilter[valueKey]}
    //         onChange={(status: boolean): void => {
    //             setFilter({
    //                 ...marketPlaceFilter,
    //                 category: status,
    //             });
    //         }}
    //     />
    // );
    // const CategoryFilter = ({
    //     label,
    //     name,
    //     value,
    // }: {
    //     label: string;
    //     value: string;
    //     name: string;
    // }): ReactElement => (
    //     <AppThemeRadioInput
    //         label={label}
    //         value={name}
    //         selectedValue={value}
    //         onChange={(status: boolean): void => {
    //             setFilter({
    //                 ...marketPlaceFilter,
    //                 category: status,
    //             });
    //         }}
    //     />
    // );

    const openFilter = (): any => {
        const appEl: HTMLDivElement = document.getElementsByClassName(
            'app-body'
        )[0] as HTMLDivElement;
        const el = document.getElementsByClassName('filter-container')[0];
        appEl.style.zIndex = '1000';
        el.classList.add('open');
    };

    const closeFilter = (): any => {
        const appEl: HTMLDivElement = document.getElementsByClassName(
            'app-body'
        )[0] as HTMLDivElement;
        const el = document.getElementsByClassName('filter-container')[0];
        appEl.style.zIndex = '0';
        el.classList.remove('open');
    };

    const hasStatus = (): any => {
        const f = marketPlaceFilter;
        return f.buy || f.newItem || f.onAuction || f.hasOffer;
    };

    const hasPrice = (): any =>
        Number(marketPlaceFilter.maxPrice) > 0 ||
        Number(marketPlaceFilter.minPrice) > 0;
    const hasCategory = (): any => marketPlaceFilter.category !== 'All NFTs';
    const hasCeleb = (): any => marketPlaceFilter.celebrities.length > 0;
    const hasType = (): any => marketPlaceFilter.crypto;

    // mui slider
    const [value, setValue] = useState<number[]>([20, 37]);

    const handleChange = (event: any, newValue: number | number[]): any => {
        setValue(newValue as number[]);
    };

    // mui select
    // const [age, setAge] = useState('');
    // const handleChange2 = (event: any): any => {
    //     setAge(event.target.value);
    // };


    return (
        <Box className="filter-container">
            <Typography
                className="filter-margin filter-margin-icon heading mobile top"
                variant="h4"
                onClick={openFilter}
            >
                <FilterList color="primary" />
                FILTER
                {(hasStatus() ||
                    hasPrice() ||
                    hasCategory() ||
                    hasCeleb() ||
                    hasType()) && <bdi />}
            </Typography>
            <Box className="mobile-filter">
                <Box className='mobile-filter-wrapper'>
                    <Box
                        className='hide-close-btn-if-not-mobile'
                        width='90%'
                        mb={2}
                    >
                        <div>
                            <IconButton size='small' style={{ background: '#000', color: '#fff' }} onClick={closeFilter}>
                                <Close />
                            </IconButton>
                        </div>
                    </Box>
                    <CelebrityList {...props} />
                    <Box p={2} mb={2}>
                        <AppSecondaryButton
                            fullWidth
                            title="Apply"
                            className="btn-small mobile apply-btn "
                            onClick={closeFilter}
                        />
                    </Box>
                    <ReginFilter />
                    <ExhibitionFilter />
                </Box>
            </Box>
        </Box>
    );
};

const mapStateToProps = (state: AppState): any => ({
    marketPlaceFilter: state.marketPlaceFilter,
    categories: state.applicationState.categories,
});

const mapDispatchToProps = (dispatch: any): any => ({
    setPrice: (data: any) => dispatch(MarketPlaceActions.setPrice(data)),
    setFilter: (data: any) => dispatch(MarketPlaceActions.setFilter(data)),
    clearFilter: (data: any) => dispatch(MarketPlaceActions.clearFilter(data)),
    resetAllFilter: (data: any) =>
        dispatch(MarketPlaceActions.resetAllFilter(data)),
    setCategories: (data: any) =>
        dispatch(ApplicationActions.setCategoriesList(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArtistFilters);
