import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import { ReactElement, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CollectionsIcon from '@material-ui/icons/Collections';
import { CollectionService, DefaultPagination } from '../../../services';
import { EmptyPlaceholder } from '../../../shared/components';
import { AppNavButton, AppPrimaryButton } from '../../../shared/components/AppComponents';
import { Utils } from '../../../shared/helpers';
import { AppState } from '../../../state/RootReducer';
import CreationItem from '../../creations/components/CreationItem';

const CreationList = (props: any): ReactElement => {
    const { profileInfo, authState, actionOpenKyc } = props;
    const [collection, setCollection] = useState([] as any);
    const [loader, setLoader] = useState(true);
    const [hasMoreCollections, setHasMoreCollections] = useState(true);
    const [pagination, setPagination] = useState({ ...DefaultPagination(6) });
    const searchQuery = '';
    const navigate = useNavigate();

    const loadCollections = (): any => {
        if (pagination.lastOffset !== pagination.offset) {
            pagination.lastOffset = pagination.offset;
            setLoader(true);
            CollectionService.getMyCreations(
                pagination.limit,
                pagination.offset,
                searchQuery
            )
                .then((response: any) => {
                    const result = response.data.data.collections;
                    const paginationResponse = response.data.data.pagination;

                    if (pagination.offset === 1) {
                        setCollection(result);
                    } else {
                        setCollection(collection.concat(result));
                    }
                    setHasMoreCollections(
                        Utils.hasMoreRecords(paginationResponse)
                    );
                    setLoader(false);
                    setPagination({
                        ...paginationResponse,
                        lastOffset: pagination.lastOffset,
                        offset: pagination.offset + 1,
                    });
                })
                .catch(() => {
                    setLoader(false);
                    navigate('../');
                });
        }
    };

    const loadMoreCollections = (): any => {
        loadCollections();
    };

    useEffect(() => {
        loadCollections();
    }, []);

    // UI Elements
    return (
        <Box mt={4}>
            <Box className="collection-detail-item-header">
                {false && profileInfo.blueTick && authState.isUserLoggedIn && (
                    <AppNavButton to="create" title="ADD NEW" />
                )}
            </Box>

            {!loader && collection.length === 0 && (
                <EmptyPlaceholder
                    icon={<CollectionsIcon />}
                    message="You do have any collections yet"
                />
            )}
            {profileInfo.kycVerified ?
                <Box mb={4}>
                    <InfiniteScroll
                        threshold={0}
                        pageStart={0}
                        loadMore={loadMoreCollections}
                        hasMore={hasMoreCollections}
                    >
                        {collection.length !== 0 && (
                            <Grid container spacing={4} className="top-collections">
                                {collection.map((c: any, index: number): any => (
                                    <Grid
                                        item
                                        md={4}
                                        sm={6}
                                        xs={12}
                                        key={`creation_${index}`}
                                    >
                                        <CreationItem {...c} />
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                        {loader && (
                            <Box className="empty-content">
                                <CircularProgress />
                            </Box>
                        )}
                    </InfiniteScroll>
                </Box>
            :
                <Box mb={2} mx="auto" className='text-center'>
                    <Typography
                        variant="body1"
                        className="my-4"
                    >
                        Complete your KYC to see your Collections.
                    </Typography>
                    <AppPrimaryButton
                        title="Verify KYC" 
                        className='btnContent'
                        onClick={() => actionOpenKyc(1)}
                    />
                </Box>
            }
        </Box>
    );
};

const mapDispatchToProps = (): any => ({});

const mapStateToProps = (state: AppState): any => ({
    authState: state.authState,
    profileInfo: state.profileInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(CreationList);
