import { ReactElement, useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, Divider, Typography } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import CollectionsIcon from '@material-ui/icons/Collections';
import Slider from 'react-slick';
import { CollectionService, DefaultPagination } from '../../../services';
import { Utils } from '../../../shared/helpers';
import NFTItem, { NFTInfo } from '../../home/components/NFTItem';
import { AppPrimaryButton } from '../../../shared/components/AppComponents';
import { AppState } from '../../../state/RootReducer';
import NFTRequestForm from './NFTRequestForm';
import '../../../assets/styles/App.scss';
import { SlideNextArrow, SlidePrevArrow,EmptyPlaceholder } from '../../../shared/components';


interface requestedNFTInfo {
    itemId: string;
}
const ImageSliderConfigNFT = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    arrows: true,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <SlideNextArrow />,
    prevArrow: <SlidePrevArrow />,
    responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
            dots: true
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
};
const MyNFTs = (props: any): ReactElement => {
    const { actionAddAddress,details  } = props
    const [items, setItems] = useState<NFTInfo[]>([]);
    const [pagination, setPagination] = useState({ ...DefaultPagination(12) });
    const [loadingItems, setLoadingItems] = useState(false);
    const [hasMoreItems, setHasMoreItems] = useState(false);
    const [showNFTRequestModal, setShowNFTRequestDialog] = useState(false);
    const [selectedNFT, setSelectedNFT] = useState<NFTInfo>();
    const [requestedNFT, setRequestedNFT] = useState<requestedNFTInfo[]>([]);


    // API Calls
    const loadItems = (reload = false): void => {
        if (pagination.lastOffset !== pagination.offset) {
            if (reload) {
                pagination.offset = 1
            }
            pagination.lastOffset = pagination.offset;
            setLoadingItems(true);
            let userId;
            if(details){
              userId=details
            }
            
            
            /* eslint no-underscore-dangle: 0 */


            if(props.artWork) {
               CollectionService.getArtistItems(userId,"created").then((response: any)=>{
                const { data } = response?.data
                let artworks = data;
                if(data.length>0) {
                    artworks =  artworks.filter((el:any)=>el._id!==props.artWork)
                }
                setItems(artworks);
                setLoadingItems(false);

               }).catch((error: any) => {
                    setLoadingItems(false);
                    Utils.handleErrorResponse({ error });
                });
           }else{
            CollectionService.getOwnedItems(pagination.limit, pagination.offset, false,userId)
                .then((response: any) => { 
                    const { data } = response.data;
                    setRequestedNFT(data.requestedNFT);
                    const paginationResponse = data.pagination;
                    if (pagination.offset === 1) {
                        setItems(data.items);
                    } else {
                        setItems(items.concat(data.items));
                    }
                    setHasMoreItems(Utils.hasMoreRecords(paginationResponse));
                    setLoadingItems(false);
                    setPagination({
                        ...paginationResponse,
                        lastOffset: pagination.lastOffset,
                        offset: pagination.offset + 1,
                    });
                })
                .catch((error: any) => {
                    setLoadingItems(false);
                    Utils.handleErrorResponse({ error });
                });
            }   
        }
    };

    // Instance methods
    const loadMoreItems = (): void => {
        loadItems();
    };

    const reloadItems = (): void => {
        loadItems(true)
    }

    // Hooks
    useEffect(() => {
        loadItems();
    }, []);

    // check Physical NFT is requested
    const isRequestedNFT = (): void => {
        for (let i = 0; i < items.length; i += 1) {
            for (let j = 0; j < requestedNFT.length; j += 1) {
                if (items[i].id === requestedNFT[j].itemId) {
                    items[i].phyNFTRequested = true;
                }
            }
        }

    }
    isRequestedNFT();

    // UI Actions
    return (
        <>
            <Box className="card-header">
                <Grid container direction="row" spacing={0} className='view-profile'>
                    {/* <Typography className="title" variant="h3"> */}
                      {details?
                        <Typography className="title" >
                      Other Works By the Artist
                      </Typography>
                      :
                      <Typography className="title" variant="h3">
                      My Collections
                      </Typography>

                      }  
                    {/* </Typography> */}
                </Grid>
            </Box>
            <Divider className='border-content' />
            <Box my={2} className="profile-card">
                    {!loadingItems && items.length === 0 && (
                    <EmptyPlaceholder
                        icon={<CollectionsIcon color="primary" />}
                        message="No data available yet"
                    />
                    )}

                    {/* {profileInfo.kycVerified ? */}
                    <InfiniteScroll
                        threshold={0}
                        pageStart={0}
                        loadMore={loadMoreItems}
                        hasMore={hasMoreItems}
                    >
                        <Grid container xs={12} className="main-my-grid-wrapper">
                            {items.length !== 0 && (
                                <>
                                 <Slider className='nft-slider' {...ImageSliderConfigNFT}>
                                    {items.map((nftItem) => (
                                        <Grid
                                        className='my-grid'
                                            key={nftItem.id}
                                            item
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            xs={12}
                                        >
                                            <NFTItem
                                                {...{
                                                    ...nftItem,
                                                    className: ' profile-nft',
                                                }}
                                            />

                                            {nftItem.physicalNFT && (
                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    className="pt-2"
                                                >
                                                    {nftItem.phyNFTRequested === true ? (<AppPrimaryButton
                                                        className="btn-small"
                                                        onClick={() => {
                                                            setShowNFTRequestDialog(
                                                                true
                                                            );
                                                            setSelectedNFT(nftItem);
                                                        }}
                                                        title="REQUEST PHYSICAL NFT"
                                                        disabled
                                                    />) : (<AppPrimaryButton
                                                        className="btn-small"
                                                        onClick={() => {
                                                            setShowNFTRequestDialog(
                                                                true
                                                            );
                                                            setSelectedNFT(nftItem);
                                                        }}
                                                        title="REQUEST PHYSICAL NFT"

                                                    />)}

                                                </Box>
                                            )}
                                        </Grid>
                                    ))}
                                    </Slider>
                                    
                                </>
                              
                            )}
                        </Grid>
                        {loadingItems && (
                            <Box className="empty-content">
                                <CircularProgress />
                            </Box>
                        )}
                    </InfiniteScroll>
                {showNFTRequestModal && (
                    <NFTRequestForm
                        requestedNFT={selectedNFT}
                        open={showNFTRequestModal}
                        onCloseNFTRequest={() => setShowNFTRequestDialog(false)}
                        loadItems={reloadItems}
                        actionAddAddress={actionAddAddress}
                    />
                )}
            </Box>
        </>
    );
};

const mapDispatchToProps = (): any => ({});

const mapStateToProps = (state: AppState): any => ({
    profileInfo: state.profileInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(MyNFTs);
