import { ReactElement } from 'react';
import {
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const WithdrawReqSkeleton = (): ReactElement => {
    const items = [1, 2, 3, 4, 5];
    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                <Skeleton style={{ height: '24px' }} />
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <Skeleton style={{ height: '24px' }} />
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <Skeleton style={{ height: '24px' }} />
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <Skeleton style={{ height: '24px' }} />
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <Skeleton style={{ height: '24px' }} />
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <Skeleton style={{ height: '24px' }} />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((value, index) => (
                            <TableRow key={`${value}_${index}`}>
                                <TableCell component="td" scope="row">
                                    <Skeleton style={{ height: '24px' }} />
                                </TableCell>
                                <TableCell component="td" scope="row">
                                    <Skeleton style={{ height: '24px' }} />
                                </TableCell>
                                <TableCell component="td" scope="row">
                                    <Skeleton style={{ height: '24px' }} />
                                </TableCell>
                                <TableCell component="td" scope="row">
                                    <Skeleton style={{ height: '24px' }} />
                                    <Skeleton style={{ height: '24px' }} />
                                </TableCell>
                                <TableCell component="td" scope="row">
                                    <Skeleton style={{ height: '24px' }} />
                                </TableCell>
                                <TableCell component="td" scope="row">
                                    <Skeleton style={{ height: '24px' }} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default WithdrawReqSkeleton;
