import {
    Box,
    Grid,
    Avatar,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    IconButton,
    CircularProgress,
    Divider,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { ReactElement, useEffect, useState } from 'react';
import { StripeCardNumberElement, loadStripe } from '@stripe/stripe-js';
import {
    CardNumberElement,
    useStripe,
    useElements,
    Elements,
} from '@stripe/react-stripe-js';
import { Formik } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import { BlockChain, Toast, Utils } from '../../../shared/helpers';
import { AppState } from '../../../state/RootReducer';
import { ExportCryptotSchema } from '../../../validators/Creation';
import {
    AppPrimaryButton,
    AppTeritaryButton,
    AppFormikTextField,
    AppThemeRadioInput,
    AppFormikMultiSelect,
} from '../../../shared/components/AppComponents';
import { CollectionService } from '../../../services';
import { StripeCard, FractoCard } from '../../../shared/components';
import { ItemFullDetail } from '../NFTDetailPage';
import { ProfileState } from '../../../state/profile/Reducer';

const publicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const { REACT_APP_PAYMENT_GATEWAY } = process.env
const stripePromise = loadStripe(publicKey!);

const self:any = window;

const NFTExport = (props: {
    acceptCrypto: boolean;
    openExportNFT: boolean;
    itemDetail: ItemFullDetail;
    onCloseExportDialog: (reloadItemDetail: boolean) => void;
    profileInfo: ProfileState;
}): ReactElement => {
    const {
        profileInfo,
        openExportNFT,
        acceptCrypto,
        itemDetail,
        onCloseExportDialog,
    } = props;
    const history = useHistory();
    const navigate = useNavigate();
    const [step, setStep] = useState(0);
    const [processing, setProcessing] = useState(false);
    const stripe = useStripe();
    self.stripe = stripe;
    const elements = useElements();
    const [paymentMode, setPaymentMode] = useState('');
    const [exportStatus, setExportStatus] = useState(false);
    const [validCard, setValidCard] = useState(false);
    const [validateCard, setValidateCard] = useState({
        cardNumber: false,
        cardCvc: false,
        cardExpiry: false,
    });
    const [loadingTransactionFee, setLoadingTransactionFee] = useState(false);
    const [exportResponse, setExportResponse] = useState('');
    const [transactionDetails, setTransactionDetails] = useState({
        fiat: {
            fee: '',
            secret: '',
            currencyType: '',
        },
        crypto: {
            fee: '',
            address: '',
            currencyType: '',
        },
        itemQuantity: 0,
    });
    const [exportNFTForm, setExportNFTForm] = useState({
        quantity: '',
        accountAddress: '',
        // editions: [],
    });
    const [editionsList, setEditionsList] = useState<string[]>([]);
    const [cardForm, setCardForm] = useState({
        cardNumber: '',
        cardCvc: '',
        cardExpiry: '',
    });
    const [otpEnable ,setOtpEnable] = useState(false);
    const [otpScreen,setOtpScreen] = useState('');
    const [fValue ,setFvalues] =useState();
    const [tripleA, setTripleA] = useState({ widgetUrl: '' })
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [paymentResponse, setPaymentResponse] = useState('');

    // Instance methods
    const loadEditions = (): void => {
        const ownerInfo = itemDetail.ownedBy.find(
            (owner) => owner.id === profileInfo.userId
        );
        if (ownerInfo) {
            setEditionsList(ownerInfo.editionQuantity);
        }
    };

    // API Calls
    const getTransactionFee = (formValues: any, callback: any): void => {
        const { quantity, accountAddress, } = formValues;
        setLoadingTransactionFee(true);
        CollectionService.getExportNFTFee({
            itemId: itemDetail.itemInfo.id,
            // editions,
            quantity,
            address: accountAddress,
        })
            .then((response: any) => {
                const transactionDetail = response.data.data;
                callback(true, transactionDetail);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
                callback(false);
            });
    };

    const createExport = (formValues: any, paymentId: string): void => {
        const { accountAddress, quantity } = formValues;
        setProcessing(true);
        const data: any = {
            // editions,
            itemId: itemDetail.itemInfo.id,
            paymentTxnId: paymentId,
            address: accountAddress,
            quantity,
            modeOfPayment: "fiat",
            txnAmount:self.transactionDetail.fiat.fee.toString()
        };

        CollectionService.completeNFTExport(data)
            .then(() => {
                setProcessing(false);
                setExportStatus(true);
                setExportResponse('Export request successfully created');
                setStep(2);
            })
            .catch(() => {
                setProcessing(false);
                setExportStatus(false);
                setExportResponse('Export NFT failed');
                setStep(2);
            });
    };

    const on3DSComplete = ():any => {
        // setOtpEnable(false);

        if(self.stripe){
        self.stripe.retrievePaymentIntent(self.clientSecret)
          .then((result:any)=>{
            if (result.error) {
              // PaymentIntent client secret was invalid
                setOtpEnable(false)
                setExportStatus(false);
                setProcessing(false);
                setStep(2)
                setExportResponse('Export NFT failed');
              
            }
            else if(result.paymentIntent.last_payment_error) {
                setOtpEnable(false)
                setExportStatus(false);
                setProcessing(false);
                setStep(2)
                setExportResponse('Export NFT failed');

            } 
            else if (result.paymentIntent.status === 'requires_capture'){
                setOtpEnable(false)
                setExportStatus(true);
                setStep(2)
                setExportResponse('Congrats! Your transaction has been completed successfully.Exported NFT');
                createExport(self.formValues, result.paymentIntent.id);
            }
            else if (result.paymentIntent.status === 'succeeded') {
                 setOtpEnable(false)
                 setExportStatus(true);
                 setStep(2)
                 setExportResponse('Congrats! Your transaction has been completed successfully.Exported NFT');   
                 createExport(self.formValues, result.paymentIntent.id);
                 // Show your customer that the payment has succeeded
              } else if (result.paymentIntent.status === 'requires_payment_method') {
                // Authentication failed, prompt the customer to enter another payment method
                  setOtpEnable(false);
                  setExportStatus(false);
                  setStep(2)
                  setExportResponse('Export NFT failed');
                
              }
              else {
                setOtpEnable(false)
                setExportStatus(false);
                // setStep(2);
              }

            
          });
        }
      }

    useEffect(()=> {
        window.addEventListener('message', (ev)=> ev?.data === '3DS-authentication-complete'? on3DSComplete() :'', false);
    },[])

    const handleError = (error: any): void => {
        setProcessing(false);
        if (error.code || error.message) {
            Toast.showErrorMessage(error.message);
        } else {
            Toast.showErrorMessage(error);
        }
    };

    const initiateCryptoPayment = async (formValues: any): Promise<any> => {
        setProcessing(true);
        BlockChain.setUpMetaMask()
            .then(async (response: any) => {
                BlockChain.verifySignature(response.address)
                    .then((verification) => {
                        const { crypto } = transactionDetails;
                        const transactionAmount: any = crypto.fee;
                        const amount = response.web3.utils.toWei(
                            transactionAmount.toString()
                        );
                        response.web3.eth
                            .sendTransaction({
                                from: verification.publicAddress,
                                to: crypto.address,
                                value: amount,
                            })
                            .on('transactionHash', (transactionHash: any) => {
                                createExport(formValues, transactionHash);
                            });
                    })
                    .catch((error) => {
                        handleError(error);
                    });
            })
            .catch((error: any) => {
                handleError(error);
            });
    };

    // UI Actions
    const actionOnFormSubmit = (formValues: any, formik: any): void => {
        getTransactionFee(
            formValues,
            (status: boolean, transactionFee: any) => {
                if (status) {
                    setTransactionDetails(transactionFee);
                    self.transactionDetail = transactionFee
                    setExportNFTForm(formValues);
                    setStep(1);
                }
                setLoadingTransactionFee(false);
                formik.setSubmitting(false);
            }
        );
    };

    const actionProcessStripePayment = async (
        formValues: any
    ): Promise<any> => {
        setProcessing(true);
        const clientSecret = transactionDetails.fiat.secret;
        console.log("clientSecret: ", clientSecret)
        self.formValues = formValues
        self.clientSecret = clientSecret;
        const payload = await stripe!.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements!.getElement(
                    CardNumberElement
                ) as StripeCardNumberElement,
            },
            return_url:window.location.href
           },
          {handleActions:false}
        );
        if(payload.paymentIntent){
            setOtpEnable(true)
            setStep(3)
            if(payload.paymentIntent?.next_action){
                setOtpScreen(payload.paymentIntent?.next_action?.redirect_to_url.url) 
            }
          }
        if (payload.error) {
            setProcessing(false);
            setExportStatus(false);
            setExportResponse(`${payload.error.message}`);
            setStep(2);
        } 
        // else {
        //     createExport(formValues, payload.paymentIntent.id);
        // }
    };

    const actionProcessFractoPayment = async (
        formValues: any
    ): Promise<any> => {
        const { quantity, accountAddress } = formValues;
        setProcessing(true);
        const ccnumber = cardForm.cardNumber.replace(/ +/g, '').trim();
        const ccexp = cardForm.cardExpiry.replace('/', '').trim();
        const cvv = cardForm.cardCvc.trim();
        const amount = transactionDetails.fiat.fee;

        const requestObj: any = {
            ccnumber,
            ccexp,
            cvv,
            amount,
            quantity, 
            address: accountAddress, 
            itemId: itemDetail.itemInfo.id,
            paymentType: 'export',
            modeOfPayment: "fiat",
        };
        CollectionService.exportPayWithFracto(requestObj)
            .then((response: any) => {
                setProcessing(false);
                setExportStatus(true);
                setExportResponse('Export request successfully created');
                setStep(2);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
                setProcessing(false);
            });
    };

    const initiateTripleAPayment = async (
        formValues: any
    ): Promise<any> => {
        const { accountAddress, quantity } = formValues
        const reqObj = {
            orderCurrency: "USD", 
            itemId: itemDetail.itemInfo.id, 
            successUrl:  `${window.location.href}?orderStatus=successExport`,
            cancelUrl: `${window.location.href}?orderStatus=failureExport`, 
            quantity, 
            address: accountAddress, 
            txnAmount: self.transactionDetail.crypto.fee.toString()
        }
        setProcessing(true);

        CollectionService.initiateTripleANFTExport(reqObj)
            .then((res: any) => {
                const response = res.data.data;
                const { widgetUrl } = response
                if (widgetUrl) setTripleA({ widgetUrl })
                setStep(3);
            }).catch((error: any) => {
                Utils.handleErrorResponse({
                    error,
                });
                setProcessing(false);
            })

    }

    const actionExportNFT = (): void => {
        if (paymentMode === 'crypto') {
            initiateTripleAPayment(exportNFTForm)
            // initiateCryptoPayment(exportNFTForm);
        } else if (paymentMode === 'fiat') {
            console.log("actionExportNFT fiat: ")
            if (REACT_APP_PAYMENT_GATEWAY === 'fracto') {
                actionProcessFractoPayment(exportNFTForm);
            } else if (REACT_APP_PAYMENT_GATEWAY === 'stripe') {
                actionProcessStripePayment(exportNFTForm);
            }
        } else {
            actionProcessStripePayment(exportNFTForm);
        }
    };

    const actionOnPreviouPage = (setSubmitting: any): void => {
        setSubmitting(false);
        setStep(0);
    };

    // Hooks
    useEffect(() => {
        if (itemDetail) {
            loadEditions();
        }
    }, [itemDetail]);

    const actionFractoFormChange = (event: any): void => {
        let result = {value: '', error: false};
        if (event.target.name === 'cardNumber') {
            result = Utils.formatCreditCardNumber(event.target.value);
        } else if (event.target.name === 'cardExpiry') {
            result = Utils.formatExpirationDate(event.target.value);
        } else if (event.target.name === 'cardCvc') {
            result = Utils.formatCVC(event.target.value, {
                number: cardForm.cardNumber,
            });
        }
        setCardForm({
            ...cardForm,
            [event.target.name]: result.value,
        });
        setValidateCard({...validateCard, [event.target.name]: result.error});
        setValidCard(true);
    };

    const actionToProfile = (): any => {
        navigate('/profile/view',
            { state: { payment: "Payment History" } }
        );
        history?.go(0)
    }

    const actionToMarketPlace = (): any => {
        navigate('/marketplace');
        history?.go(0)
    }

    // UI Elements
    return (
        <>
            <Dialog
                aria-labelledby="checkout-dialog-title"
                open={openExportNFT}
                id="checkout-dialog"
                fullWidth
                maxWidth="sm"
                disableEscapeKeyDown
            >
                <DialogTitle>
                    <Box className="title-section">
                        <Typography variant="h4">Export NFT</Typography>
                        <IconButton
                            onClick={() => {
                                onCloseExportDialog(exportStatus);
                            }}
                        >
                            <CloseIcon color="primary" />
                        </IconButton>
                    </Box>
                    <Divider />
                </DialogTitle>
                <DialogContent>
                    {loadingTransactionFee && (
                        <Box className="empty-content">
                            <CircularProgress />
                        </Box>
                    )}
                    {!loadingTransactionFee && (
                        <Formik
                            enableReinitialize
                            validateOnMount
                            initialValues={exportNFTForm}
                            validationSchema={ExportCryptotSchema()}
                            onSubmit={(formValues, formik) => {
                                actionOnFormSubmit(formValues, formik);
                            }}
                        >
                            {({
                                handleSubmit,
                                isSubmitting,
                                isValid,
                                values,
                                setSubmitting,
                            }) => (
                                <form>
                                    <Grid container>
                                        {step === 0 && (
                                            <>
                                                <Grid sm={12} item>
                                                    <AppFormikTextField
                                                        name="quantity"
                                                        size="large"
                                                        label="Enter Quantity *"
                                                        // items={editionsList}
                                                        outerLabel
                                                        placeholder="Enter Quantity"
                                                    />
                                                </Grid>
                                                <Grid sm={12} item>
                                                    <AppFormikTextField
                                                        name="accountAddress"
                                                        type="text"
                                                        size="large"
                                                        label="Export Address *"
                                                        placeholder="Export Address"
                                                        outerLabel
                                                    />
                                                </Grid>
                                                <Grid
                                                    container
                                                    justifyContent="flex-end"
                                                >
                                                    <Box mt={2}>
                                                        <AppTeritaryButton
                                                            className="btn-small"
                                                            title="CANCEL"
                                                            onClick={
                                                                onCloseExportDialog
                                                            }
                                                            disabled={
                                                                isSubmitting
                                                            }
                                                        />

                                                        <AppPrimaryButton
                                                            className="ml-10 btn-small"
                                                            title="NEXT"
                                                            loadingText="PROCESSING"
                                                            isLoading={
                                                                isSubmitting
                                                            }
                                                            disabled={
                                                                isSubmitting ||
                                                                !isValid ||
                                                                values
                                                                    .accountAddress
                                                                    .length !==
                                                                    42
                                                            }
                                                            onClick={
                                                                handleSubmit
                                                            }
                                                        />
                                                    </Box>
                                                </Grid>
                                            </>
                                        )}

                                        {step === 1 && (
                                            <>
                                                <Grid
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    item
                                                >
                                                    <Box display="flex">
                                                        <AppThemeRadioInput
                                                            label={
                                                                <Box>
                                                                    <Typography>
                                                                        Fiat
                                                                    </Typography>
                                                                    <Typography className="text-secondary">
                                                                        {`Pay using Fiat ${transactionDetails.fiat.fee} ${transactionDetails.fiat.currencyType}`}
                                                                    </Typography>
                                                                </Box>
                                                            }
                                                            value="fiat"
                                                            selectedValue={
                                                                paymentMode
                                                            }
                                                            onChange={(
                                                                value: string
                                                            ) => {
                                                                setValidCard(
                                                                    false
                                                                );
                                                                setPaymentMode(
                                                                    value
                                                                );
                                                            }}
                                                        />
                                                        <AppThemeRadioInput
                                                          label={
                                                            <Box>
                                                                <Typography>
                                                                    Crypto
                                                                </Typography>
                                                                <Typography className="text-secondary">

                                                                {`Pay using TripleA ${transactionDetails.crypto.fee} ${transactionDetails.crypto.currencyType}`}
                                                                
                                                                </Typography>
                                                            </Box>
                                                            }
                                                            value="crypto"
                                                            selectedValue={
                                                                paymentMode
                                                            }
                                                            onChange={(
                                                                value: string
                                                            ) => {
                                                                setPaymentMode(
                                                                    value
                                                                );
                                                            }}
                                                        />
                                                    </Box>

                                                    {paymentMode === 'fiat' && (
                                                        <Box my={1} mx={4}>
                                                            <Box className="border">
                                                                {REACT_APP_PAYMENT_GATEWAY === 'fracto' &&
                                                                    <FractoCard
                                                                        cardForm={
                                                                            cardForm
                                                                        }
                                                                        onFormChange={
                                                                            actionFractoFormChange
                                                                        }
                                                                        validateCard={validateCard}
                                                                    />
                                                                }
                                                                {REACT_APP_PAYMENT_GATEWAY === 'stripe' && 
                                                                    <StripeCard
                                                                        onFormChange={({
                                                                            completed,
                                                                        }: any) => {
                                                                            setValidCard(
                                                                                completed
                                                                            );
                                                                        }}
                                                                    />
                                                            
                                                                }
                                                            </Box>
                                                        </Box>
                                                    )}


                                                    {/* {paymentMode === 'fiat' && (
                                                                <Box my={1} mx={4}>
                                                                <Box className="border">
                                                                    j
                                                                </Box>
                                                                </Box>
                                                    )} */}
                                                </Grid>

                                                {/* {acceptCrypto && (
                                                    <Grid
                                                        lg={12}
                                                        md={12}
                                                        sm={12}
                                                        item
                                                    >
                                                        <Box display="flex">
                                                            <AppThemeRadioInput
                                                                label={
                                                                    <Box>
                                                                        <Typography>
                                                                            Crypto
                                                                        </Typography>
                                                                        <Typography className="text-secondary">
                                                                            {`Pay using Crypto ${transactionDetails.crypto.fee} ETH`}
                                                                        </Typography>
                                                                    </Box>
                                                                }
                                                                value="crypto"
                                                                selectedValue={
                                                                    paymentMode
                                                                }
                                                                onChange={(
                                                                    value: string
                                                                ) => {
                                                                    setPaymentMode(
                                                                        value
                                                                    );
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                )} */}
                                                <Grid
                                                    container
                                                    justifyContent="flex-end"
                                                >
                                                    <Box mt={2}>
                                                        <AppTeritaryButton
                                                            className="btn-small"
                                                            title="BACK"
                                                            disabled={
                                                                processing
                                                            }
                                                            onClick={() => {
                                                                actionOnPreviouPage(
                                                                    setSubmitting
                                                                );
                                                            }}
                                                        />
                                                        <AppPrimaryButton
                                                            className="ml-10 btn-small"
                                                            title="EXPORT"
                                                            isLoading={
                                                                processing
                                                            }
                                                            loadingText="EXPORTING"
                                                            disabled={
                                                                processing ||
                                                                !isValid ||
                                                                paymentMode ===
                                                                    '' ||
                                                                (paymentMode ===
                                                                    'fiat' &&
                                                                    !validCard)
                                                            }
                                                            onClick={() => {
                                                                actionExportNFT();
                                                            }}
                                                        />
                                                    </Box>
                                                </Grid>
                                            </>
                                        )}

                                        {step === 2 && (
                                            <Box
                                                className="empty-content"
                                                flexDirection="column"
                                            >
                                                <Box my={2}>
                                                    <Avatar
                                                        src={
                                                            exportStatus
                                                                ? '/icons/icon-success.png'
                                                                : '/icons/icon-failed.png'
                                                        }
                                                    />
                                                </Box>
                                                <Box mt={2}>
                                                    <Typography
                                                        className="text-secondary"
                                                        align="center"
                                                    >
                                                        {exportResponse}
                                                    </Typography>
                                                </Box>
                                                {!exportStatus && (
                                                    <Box
                                                        mt={2}
                                                        display="flex"
                                                        justifyContent="center"
                                                    >
                                                        <AppTeritaryButton
                                                            title="TRY AGAIN"
                                                            onClick={() => {
                                                                setStep(0);
                                                                setExportStatus(
                                                                    false
                                                                );
                                                            }}
                                                        />
                                                    </Box>
                                                )}
                                            </Box>
                                        )}
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    )}
                   {otpEnable && <><iframe src={otpScreen} title="3d-secure"   style={{ width: "600px", height: "800px", border: " 0" }} scrolling="no" /></>}
                   {
                    (step === 3 && !otpEnable)&& (
                            tripleA.widgetUrl ?
                                <iframe
                                    title='tripleA'
                                    id="triplea-payment-form"
                                    src={tripleA.widgetUrl}
                                    style={{ width: "550px", height: "700px", border: " 0" }}
                                    scrolling="no"
                                />
                                :
                                <Box
                                    className="center-content checkout-container"
                                    flexDirection="column"
                                >
                                    <Box mb={2}>
                                        <Typography variant="h4">
                                            {paymentStatus
                                                ? 'Payment Successful!'
                                                : 'Payment Failed!'}
                                        </Typography>
                                    </Box>
                                    <Box my={2}>
                                        <Avatar
                                            src={
                                                paymentStatus
                                                    ? '/icons/icon-success.png'
                                                    : '/icons/icon-failed.png'
                                            }
                                        />
                                    </Box>
                                    <Box mt={2}> 
                                        <Typography className="text-secondary" align="center">
                                            {paymentResponse}
                                        </Typography>
                                        <Box mt={2} id="checkout-success-options">
                                            <Box mr={3} id="view-nft-button">
                                                <AppPrimaryButton
                                                    onClick={() => actionToProfile()}
                                                    title="View History" 
                                                />
                                            </Box>
                                            <Box id="marketplace-button">
                                                <AppPrimaryButton
                                                    onClick={() => actionToMarketPlace()}
                                                    title="Continue Shopping"
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>

                        )
                    }
                </DialogContent>
            </Dialog>

        </>
    );
};
const mapStateToProps = (state: AppState): any => ({
    acceptCrypto: state.applicationState.acceptCrypto,
    profileInfo: state.profileInfo,
});
const NFTExportRedux = connect(mapStateToProps, null)(NFTExport);

const NFTExportForm = (props: any): ReactElement => (
    <Elements stripe={stripePromise}>
        <NFTExportRedux {...props} />
    </Elements>
);
export default NFTExportForm;
