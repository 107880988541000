import { useState, useEffect, ReactElement } from 'react';
import {
    Box,
    Grid,
    Card,
    Typography,
    CardContent,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    CircularProgress,
    Divider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Utils } from '../../../shared/helpers';
import {
    AppPrimaryButton,
    AppThemeRadioInput,
} from '../../../shared/components/AppComponents';
import { ProfileService } from '../../../services';
import { NFTInfo } from '../../home/components/NFTItem';

export interface AddressInfo {
    name: string;
    line1: string;
    line2: string;
    city: string;
    state: string;
    country: string;
    contactNumber: string;
    postcode: string;
    userId: string;
    id: string;
}

interface NFTRequestProps {
    requestedNFT: NFTInfo;
}

const NFTRequestForm = (props: any): ReactElement => {
    const { requestedNFT }: NFTRequestProps = props;
    const { open, onCloseNFTRequest, actionAddAddress } = props;
    const [selectedAddress, setSelectedAddress] = useState('');
    const [loadingAddress, setLoadingAddress] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const [step, setStep] = useState(0);
    const [addressList, setAddressList] = useState<AddressInfo[]>([]);

    // API Calls
    const loadAddressList = (): any => {
        setLoadingAddress(true);
        ProfileService.getAddressList(100, 1)
            .then((response: any) => {
                const { address } = response.data.data;
                setAddressList(address);
                setLoadingAddress(false);
            })
            .catch((error: any) => {
                setLoadingAddress(false);
                Utils.handleErrorResponse({
                    error,
                });
            });
    };

    const sendTransferNFTRequest = (formValues: any, callback: any): any => {
        ProfileService.createNFTRequest(formValues)
            .then((response: any) => {
                Utils.handleSuccessResponse(response);
                callback(true);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
                callback(false);
            });
    };

    // Hooks
    useEffect(() => {
        loadAddressList();
    }, []);

    // UI Actions
    const actionRequestNFT = (): any => {
        if (requestedNFT) {
            const requestObj = {
                itemId: requestedNFT.id,
                physicalAddressId: selectedAddress,
            };
            setSubmitting(true);
            sendTransferNFTRequest(requestObj, (status: boolean) => {
                setSubmitting(false);
                if (status) {
                    onCloseNFTRequest();
                    props.loadItems()
                }
            });    
        }
    };

    // UI Elements
    const getAddressList = (addressDetail: any): any => (
        <AppThemeRadioInput
            onChange={(value: string) => setSelectedAddress(value)}
            selectedValue={selectedAddress}
            value={addressDetail.id}
            label={
                <Typography>
                    <Typography variant="body1">Name: </Typography>
                    <Typography className="text-gray" variant="body2">
                        {Utils.titleCase(addressDetail.name)}
                    </Typography>
                    <Typography variant="body1">Address: </Typography>
                    <Typography className="text-gray" variant="body2">
                        {addressDetail.line1}
                        <br />
                        {addressDetail.line2}
                        <br />
                        {addressDetail.city}
                        <br />
                        {addressDetail.state}
                        <br />
                        {addressDetail.country}
                        <br />
                        {addressDetail.postcode}
                    </Typography>
                    <Typography variant="body1">Phone Number: </Typography>
                    <Typography className="text-gray" variant="body2">
                        {addressDetail.contactNumber}
                    </Typography>
                </Typography>
            }
        />
    );
    return (
        <Dialog maxWidth="xs" fullWidth open={open}>
            <DialogTitle>
                <Box className="title-section">
                    <Typography variant="h4">
                        {step === 0 ? 'Disclaimer' : 'Choose Address'}
                    </Typography>
                    <IconButton onClick={onCloseNFTRequest}>
                        <CloseIcon color="primary" />
                    </IconButton>
                </Box>
                <Divider />
            </DialogTitle>
            <DialogContent>
                {step === 0 && (
                    <Box>
                        <Typography variant="body2">
                            Buyer is responsible for the cost of shipping,
                            handling and insuring the artwork during transfer.
                            The cost of shipping and insurance, depending on the
                            size of the item and location of the buyer, shall be
                            communicated to the buyer via E-mail once we receive
                            the Redeem Request.
                        </Typography>

                        <Grid container className="end-content mt-4">
                            <AppPrimaryButton
                                title="Agree and Continue"
                                onClick={() => {
                                    setStep(1);
                                }}
                            />
                        </Grid>
                    </Box>
                )}
                {step === 1 && (
                    <>
                        {loadingAddress && (
                            <Box className="empty-content">
                                <CircularProgress />
                            </Box>
                        )}
                        {!loadingAddress && addressList.length === 0 && (
                            <Grid container justifyContent="center">
                                <Box py={5}>
                                    <AppPrimaryButton
                                        title="Add Address"
                                        onClick={() => actionAddAddress(1)}
                                    />
                                </Box>
                            </Grid>
                        )}
                        {!loadingAddress &&
                            addressList.map((addressDetails: AddressInfo) => (
                                <Box my={2} key={addressDetails.id}>
                                    <Card>
                                        <CardContent>
                                            {getAddressList(addressDetails)}
                                        </CardContent>
                                    </Card>
                                </Box>
                            ))}
                        {addressList.length > 0 && (
                            <Box p={2}>
                                <Grid container className="end-content">
                                    <AppPrimaryButton
                                        title="REQUEST NFT"
                                        isLoading={isSubmitting}
                                        loadingText="REQUESTING"
                                        disabled={
                                            selectedAddress === '' ||
                                            isSubmitting
                                        }
                                        onClick={actionRequestNFT}
                                    />
                                </Grid>
                            </Box>
                        )}
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default NFTRequestForm;
