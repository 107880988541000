import { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { 
    Box,
    Container,
    Grid,
    Typography
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Slider from 'react-slick';
import { AppState } from '../../state/RootReducer';
import { LandService } from '../../services';
import { Utils } from '../../shared/helpers';
import {
    HeadTag,
} from '../../shared/components';
import { ImageSliderConfig } from './components/SliderConfig';
import LandDetails from './components/LandDetails'
import LandCheckoutForm from './components/LandCheckout';

// const land = {
//     "landSalesPriceUsd": "25",
//     "metadataLink": "https://gateway.pinata.cloud/ipfs/QmSCEq2iNo3AbuqQq4eTAe123tN76g4vp1hCJvtdZJVjhN",
//     "metadata": {
//     "name": "VistaVerse Land",
//     "description": "VistaVerse land parcel",
//     "image": "https://some-link-to-the-image",
//     "tokenId": "246969115416777955197057632458006437206089072",
//     "city": "Mumbai",
//     "longitude": 72.5777,
//     "latitude": 18.776,
//     "type": "standard"
//     }
// }

const land = {
    "landSalesPriceUsd": "",
    "metadataLink": "",
    "metadata": {
    "name": "",
    "description": "",
    "image": "",
    "tokenId": "",
    "city": "",
    "longitude": 0,
    "latitude": 0,
    "type": ""
    }
}

const LandDetailsPage = (props: any): ReactElement => {
    const { authState, appState } = props;
    const title = "Land Details";
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const { longitude, latitude, city } = queryParams;
    const [tokenId, setTokenId] = useState<string>("");
    const [landDetails, setLandDetails] = useState<any>(land);
    const [attributes, setAttributes] = useState<any[]>([])
    const [purchaseType, setPurchaseType] = useState('direct');
    const [showCheckout, setShowCheckout] = useState(false);
    const [buyDetails, setBuyDetails] = useState<any>(null)

    const loadLandDetails = (): any => {
       if (longitude && latitude) {
           LandService.getLandDetails(
            longitude.toString(),
            latitude.toString(),
            city ? city.toString() : ""
           )
           .then((response: any) => {
               const data = response?.data
               setLandDetails(data.data)
            //    setLandDetails(land)
   
           })
           .catch((error: any) => {
               Utils.handleErrorResponse({ error })
           })
       }
    }

    const getMetaData = (metadataLink: string): any => {
        fetch(metadataLink)
            .then((response) => response.json())
            .then((data: any) => {
                console.log("responseJson: ", data);
                    setAttributes(data.attributes)
                    setTokenId(data.tokenId)
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error })
            });
    }

    const onBuyLand = (details: any): any => {
        setBuyDetails(details)
        setPurchaseType('direct');
        setShowCheckout(true);
    }

    const onPaymentResponse = (status: boolean): void => {
        if (status) {
            // reloadItemDetail();
        }
    };
 
    // Hooks
    useEffect(() => {
        loadLandDetails()
        getMetaData(land.metadataLink)
       
    }, [])

  return (
    <>
        <Box my={4}>
            <HeadTag title={title} />
            {landDetails && (
                <Container className="nft-detail-container">
                    <Grid container spacing={10}>
                        <Grid item sm={6} xs={12}>
                            <Slider {...ImageSliderConfig}>
                                <img
                                    key="slide_1"
                                    src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_960_720.jpg"
                                    className="slide-image"
                                />
                            </Slider>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <LandDetails 
                                landDetails={landDetails}  
                                authState={authState}
                                onBuyLand={onBuyLand}
                                
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box>
                                <Typography className="propertiesHeading">
                                    Meta Data
                                </Typography>
                                <Box className="properties">
                                    {attributes && 
                                    attributes.length > 0 && 
                                    attributes.map(
                                        (attribute: any, index: number) => (
                                            <Box
                                                className="property"
                                                key={`property_${index}`}
                                            >
                                                <Typography
                                                    className="title"
                                                    variant="body2"
                                                >
                                                    {attribute?.trait_type}
                                                </Typography>
                                                <Typography
                                                    className="value f20"
                                                    variant="body1"
                                                >
                                                    {attribute?.value}
                                                </Typography>
                                            </Box>
                                        )
                                    )}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            )}
        </Box>
        <LandCheckoutForm 
            showCheckout={showCheckout}
            actionCloseCheckoutDialog={() => {
                setShowCheckout(false);
            }}
            purchaseType={purchaseType}
            appState={appState}
            buyDetails={buyDetails}
            attributes={attributes}
            onPaymentResponse={onPaymentResponse}
        />
    </>
  )
}
const mapStateToProps = (state: AppState): any => ({
    profileInfo: state.profileInfo,
    authState: state.authState,
    appState: state.applicationState,
});

export default connect(mapStateToProps, null)(LandDetailsPage);
