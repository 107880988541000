// Reference:: https://material-ui.com/customization/default-theme/
const Typography = {
    fontFamily: 'Poppins',
    h1: {
        fontFamily: 'Playfair Display',
        fontWeight: 400,
        fontSize: 42,
        lineHeight: '48px',
    },
    h2: {
        fontFamily: 'Playfair Display',
        fontWeight: 400,
        fontSize: 36,
        lineHeight: '46px',
    },
    h3: {
        fontFamily: 'Playfair Display',
        fontWeight: 400,
        fontSize: 28,
        lineHeight: '30px',
    },
    h4: {
        fontFamily: 'Playfair Display',
        fontWeight: 400,
        fontSize: 22,
        lineHeight: '26px',
    },
    h5: {
        fontFamily: 'Playfair Display',
        fontWeight: 400,
        fontSize: 18,
        lineHeight: '22px',
    },
    h6: {
        fontFamily: 'Playfair Display',
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '16px',
    },
    body1: {
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '18px',
    },
    body2: {
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '15px',
    },
    caption: {
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: 10,
        lineHeight: '13px',
    },
    subtitle1: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: 17,
        lineHeight: '22px',
    },
};

export default Typography;
