import {useEffect, ReactElement, useState, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Box, Typography, Grid, Divider, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import LoginMetaMask from './LoginMetaMask';
import LoginGoogle from './LoginGoogle';
import { LoginValidation } from '../../../validators/AuthenticationSchema';
import { AuthActions } from '../../../state/authentication/Action';
import {
    AppPrimaryButton,
    AppFormikPasswordField,
    AppFormikTextField,
} from '../../../shared/components/AppComponents';
import { Utils } from '../../../shared/helpers';
// import { AuthenticationService } from '../../../services';
import { AuthenticationService, CaptchaSetting } from '../../../services';
import { useAuth } from '../hooks/UseAuth';

const LoginForm = (): ReactElement => {
    const navigate = useNavigate();
    const [captcha, setCaptcha] = useState('');
    const captchaRef = useRef(null);
    const loginForm = {
        email: '',
        password: '',
    };
    const [captchaSetting, setCaptchaSetting] = useState<any>();

    const { processLoginResponse } = useAuth();

    // API Calls
    const getCaptchaSetting = (): void => {
        CaptchaSetting.getCaptchaSetting('login')
            .then((response: any) => {
                console.log(response);
                if (response.data.data) setCaptchaSetting(response.data.data);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
            });
    };

    useEffect(() => {
        if (process.env.REACT_APP_DEV_MODE === 'true') {
            // getCaptchaSetting();
        } else {
            setCaptchaSetting({ status: true });
        }
    }, []);

    const login = (formValues: any, callback: any): void => {
        const data: any = { ...formValues,captchaToken: captcha, page: 'login' };
        AuthenticationService.login(data)
            .then((response: any) => {
                callback(true, response);
            })
            .catch((error: any) => {
                callback(false, error);
                Utils.handleErrorResponse({ error });
            });
    };

    // Business Logic
    const handleLoginResponse = (response: any, email: any): void => {
        const { userData } = response.data.data;
        const emailVerifiedStatus = userData.email.verified;
        if (emailVerifiedStatus === false) {
            navigate('/email-confirmation', {
                state: {
                    email,
                },
            });
        } else {
            processLoginResponse(response);
        }
    };

    // UI Actions
    const actionLogin = (formValues: any, formik: any): void => {
        login(formValues, (status: boolean, response: any) => {
            formik.setSubmitting(true);
            if (status) {
                const { email } = formValues;
                handleLoginResponse(response, email);
            } else {
                setCaptcha('');
                if (captchaRef !== null) {
                    const hcaptcha: any = captchaRef.current;
                    if (hcaptcha instanceof HCaptcha) {
                        hcaptcha.resetCaptcha();
                    }
                }
                formik.setSubmitting(false);
            }
        });
    };

    // Captcha events
    const onVerifyCaptcha = (token: string): any => {
        console.log(token);
        if (token) {
            setCaptcha(token);
        } else {
            setCaptcha('');
        }
    };

    const onExpireCaptcha = (): any => {
        setCaptcha('');
    };

    // UI Elements
    return (
        <>
            <Box className="auth-bg">
            <Box className="auth-content">
                    <Formik
                        initialValues={loginForm}
                        enableReinitialize
                        validationSchema={LoginValidation}
                        validateOnMount
                        onSubmit={(values: any, formik: any): void => {
                            actionLogin(values, formik);
                        }}
                    >
                        {({ handleSubmit, isSubmitting, isValid }): any => (
                            <Grid item xs={12}>
                                <form autoComplete="off">
                                    <Box className="title-content title-content-login">
                                        <Typography
                                            className="auth-main-title"
                                            variant="h3"
                                        >
                                            Login
                                        </Typography>
                                        <Divider className='auth-divider' />
                                    </Box>

                                    <Box
                                        component="div"
                                        id="google-login"
                                        pt={8}
                                    > 
                                    {/* <img src="/images/icon _google_.svg"  alt="" className='google-img-icon'/> */}
                                        <LoginGoogle/>
                                    </Box>

                                    <Box
                                        component="div"
                                        id="metamask-login"
                                        pt={6}
                                        pb={6}
                                    >
                                        <LoginMetaMask />
                                    </Box>
                                    <Divider className='auth-divider' />
                                    <Box className='email-label auth-login-box'>
                                        {/* <InputLabel required className="mt-10 auth-label">
                                            Email ID
                                        </InputLabel> */}
                                        <AppFormikTextField className="auth-label"
                                            name="email"
                                            type="text"
                                            size="medium"
                                            placeholder="Email ID*"
                                        />
                                    </Box>
                                    <Box pt={2} className='password-label auth-password-box'>
                                        {/* <InputLabel required className='auth-label'>
                                            Password
                                        </InputLabel> */}
                                        <AppFormikPasswordField
                                        className="auth-label"
                                            name="password"
                                            type="password"
                                            size="medium"
                                            placeholder="Password*"
                                        />
                                    </Box>
                                    <Box py={2} className="end-content">
                                        <Typography
                                            component={NavLink}
                                            to="/forgot-password"
                                            variant="body1"
                                            className="primary auth-label"
                                        >
                                            Forgot password
                                        </Typography>
                                    </Box>
                                    {/* {captchaSetting !== undefined &&
                                        captchaSetting.status && ( */}
                                            {/* <Box pt={2} className="captcha-sm">
                                                <HCaptcha
                                                    sitekey="2b1dc988-7604-4a33-ba53-d705132c81c5"
                                                    onVerify={onVerifyCaptcha}
                                                    onExpire={onExpireCaptcha}
                                                    ref={captchaRef}
                                                />
                                            </Box> */}
                                        {/* )} */}

                                    
                                    {/* <div className='human-box'>
                                        <label htmlFor="first-name">
                                            First Name
                                        </label>
                                        <input type="text" id="first-name" />
                                    </div> */}

                                    {/* <Box className='human-box'>
                                        <FormGroup >
                                            <FormControlLabel control={<Checkbox  className="human-box-checkbox"/>} label="I am a human" className='human-check'/>
                                            <img src='/images/captcha-icon.svg' className='captcha-icon'/>
                                        </FormGroup>
                                    </Box> */}

                                    <Box className='signIn-content'>
                                        <AppPrimaryButton
                                            title="Sign in"
                                            className='signInBtn auth-label'
                                            fullWidth
                                            isLoading={isSubmitting}
                                            loadingText="LOGGING IN"
                                            disabled={
                                                isSubmitting || 
                                                // captcha===''||
                                                
                                                // (captchaSetting !== undefined &&
                                                //     captchaSetting.status &&
                                                //     captcha === '') ||
                                                !isValid 
                                            }
                                            onClick={handleSubmit}
                                        />
                                    </Box>

                                    <Grid
                                        item
                                        xs={12}
                                        className="center-content"
                                    >
                                        <Typography variant="body1" className='auth-label'>
                                            New User?
                                        </Typography>
                                        <Typography
                                            color="primary"
                                            className="ml-2  auth-label"
                                            component={NavLink}
                                            to="/create-wallet"
                                        >
                                            Create an account
                                        </Typography>
                                    </Grid>

                                    
                                </form>
                            </Grid>
                        )}
                    </Formik>
                </Box>
            </Box>
        </>
    );
};

const mapDispatchToProps = (dispatch: any): any => ({
    setLoginStatus: (data: any) => dispatch(AuthActions.setLoginStatus(data)),
});

export default connect(null, mapDispatchToProps)(LoginForm);
