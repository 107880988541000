import { Box, Chip } from '@material-ui/core';
import { ReactElement } from 'react';

const EditionNFTCardCount = (props: any): ReactElement => {
    const { qty } = props

    return (
        <Box
            className='EditionNFTCardCount'
            width='100%'
        >
            <Chip className='edition-chip' size='small' label={`${qty} ${qty > 1 ? 'Editions' : 'Edition'}`} />
        </Box>
    )
}

export default EditionNFTCardCount;