import { Box } from '@material-ui/core';
import { ReactElement } from 'react';
import AppFooter from './AppFooter';
import SubscribeEmail from './subscribeEmail';
// import { NavLink } from 'react-router-dom';

const AppFooterSmall = (): ReactElement => (

    <div className='footer-wrapper-contant'>
        <div className='footer-content'>
            <Box className='footer-navlink'>
                <AppFooter />
            </Box>
        </div>
    </div>
);

export default AppFooterSmall;
