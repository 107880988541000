import * as Yup from 'yup';

const ibanCheck = require('iban');

export const email = Yup.string()
    .email('Invalid email')
    .required('Email is required')
    .nullable()
    .max(256, 'Email can have a maximum of 256 characters')
    .test(
        'Length validation',
        'Email can have maximum 40 characters',
        async (value: any) => {
            if (value === undefined || value === null) return true;
            const emailText = value.split('@');
            if (emailText[0]?.length > 40) return false;
            return true;
        }
    );

export const password = Yup.string()
    .min(8, 'Password must be 8 characters at minimum!')
    .max(15, 'Password must be 15 characters at maximum!')
    .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        'The Password must contain one uppercase, one lowercase, and a number (ranging from 8-15 characters).'
    )
    .required('Password is required');

export const betaPassword = Yup.string().required('Password is required');

export const name = Yup.string()
    .required('Name is required')
    .matches(
        /[a-zA-Z][a-zA-Z ]+[a-zA-Z]$/,
        'Name can contain only alpha and space'
    )
    .min(3, 'Name must be 3 characters at minimum!')
    .max(150, 'Name can have maximum of 150 characters');

export const accountHolderName = Yup.string()
    .required('Name is required')
    .matches(/^[a-zA-Z ]*$/, 'Name can contain only alpha and space')
    .max(35, 'Name can have maximum of 35 characters');

export const policy = Yup.string()
    .required('You must agree to the privacy policy')
    .matches(/^(true)$/, 'You must agree to the privacy policy');

export const token = Yup.string()
    .required('Token is required')
    .matches(/^[0-9]{6}$/, 'Enter a valid token')
    .max(10, 'Enter a valid token');

export const bio = Yup.string().max(
    1500,
    'Bio can be maximum 1500 of characters'
);

export const currentPassword = Yup.string()
    .min(8, 'Current Password must be 8 characters at minimum!')
    .max(15, 'Current Password must be 15 characters at maximum!')
    .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        'The Password must contain one uppercase, one lowercase, and a number (ranging from 8-15 characters).'
    )
    .required('Enter current password');

export const newPassword = Yup.string()
    .min(8, 'New Password must be 8 characters at minimum!')
    .max(15, 'New Password must be 15 characters at maximum!')
    .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        'The Password must contain one uppercase, one lowercase, and a number (ranging from 8-15 characters).'
    )
    .required('Enter new password');

export const confirmPassword = Yup.string()
    .required('Enter confirm password')
    .oneOf(
        [Yup.ref('newPassword'), null],
        'New Password and Confirm Password should match'
    );

export const bankName = Yup.string()
    .required('Bank Name is required')
    .trim()
    .max(60, 'Bank Name can have maximum of 60 characters')
    .matches(
        /^(([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+)*$/,
        'Please enter valid bank name'
    );

export const accountNumber = Yup.string()
    .required('Account Number is required')
    .matches(/^[0-9]*$/, 'Account can contain only numeric')
    .min(9, 'Account Number should have minimum of 9 characters')
    .max(18, 'Account Number can have maximum of 18 characters');

export const confirmAccountNumber = Yup.string()
    .required('Enter confirm account number')
    .oneOf(
        [Yup.ref('accountNumber'), null],
        'Confirm account number and Account number should match'
    );

export const IFSC = Yup.string()
    .optional()
    .nullable()
    .matches(
        /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/,
        'IFSC code can contain only alphanumeric characters (ex, ABCD0001231)'
    )
    .min(11, 'IFSC should have exactly 11 characters')
    .max(11, 'IFSC should have exactly 11 characters');

export const IBAN = Yup.string()
    .optional()
    .nullable()
    .max(36, 'IBAN can have maximum of 36 characters')
    .test('is-valid-iban', 'Please enter valid IBAN', (value: any) =>
        value ? ibanCheck.isValid(value) : true
    );

export const additionalDetails = Yup.string()
    .trim()
    .optional()
    .min(15, 'Additional details should have minimum of 15 characters')
    .max(250, 'Additional details can have maximum of 250 characters');

export const swiftCode = Yup.string()
    .trim()
    .required('Swift Code is required')
    .matches(
        /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/,
        'Please enter valid Swift Code'
    )
    .min(8, 'Swift Code should have minimum of 8 characters')
    .max(11, 'Swift Code can have maximum of 11 characters');

export const amount = Yup.number()
    .positive()
    .required('Amount is required')
    .min(1, 'Amount must be greater than 0');

export const description = Yup.string()
    .required('Decription is required')
    .trim()
    .max(500, 'Decription can have maximum of 500 characters');

export const collectionName = Yup.string()
    .required('Creation Name is required')
    .trim()
    .matches(
        /^[0-9 a-zA-Z\-'_&()[\]]*$/,
        "Creation Name can contain alphanumeric and allowed sepcial characters are _, ', -, &, (, ), [,]"
    )
    .max(150, 'Creation Name can have maximum of 150 characters');

export const itemName = Yup.string()
    .required('Item Name is required')
    .matches(
        /^[0-9 a-zA-Z\-'_&()[\]]*$/,
        "Item Name can contain alphanumeric and allowed sepcial characters are _, ', -, &, (, ), [,]"
    )
    .max(150, 'Item Name can have maximum of 150 characters');

export const quantity = Yup.string()
    .required('Supply is required')
    .matches(/^[0-9]{0,9}$/, 'Enter a valid supply')
    .max(10, 'Enter a valid supply in max 10 digits');

export const royalty = Yup.string()
    .required('Royalty is required')
    .matches(/^[0-9]{0,9}$/, 'Enter a valid royalty')
    .max(10, 'Enter a valid supply');

export const comment = Yup.string()
    .required('Comments is required')
    .max(250, 'Comments can have maximum of 250 characters');

export const accountName = Yup.string()
    .required('Account name is required')
    .matches(
        /^[a-zA-Z ]{0,256}$/,
        'Enter your Account name in format: Ethereum'
    )
    .max(30, 'Account name can have maximum of 30 characters')
    .optional();

export const accountAddress = Yup.string().required(
    'Account address is required'
);

export const tokenId = Yup.string().required('Token Id is required');

export const importQuantity = Yup.string()
    .required('Import quantity is required')
    .matches(/^[0-9]*$/, 'Import quantity should be numeric');

export const importAccountAddress = Yup.string().required(
    'Import Account address is required'
);

export const importCategory = Yup.string().required('Category is required');

export const firstName = Yup.string()
    // .label('First name')
    .required('First name is required')
    .matches(/^[A-Za-z]*$/, 'First name can contain only alpha and space')
    .max(150, 'First name can have maximum of 150 characters');

export const lastName = Yup.string()
    // .label('Last name')
    .required('Last name is required')
    .matches(/^[A-Za-z]*$/, 'Last name can contain only alpha and space')
    .min(3, 'Last name must have minimum of 3 characters')
    .max(150, 'Last name can have maximum of 150 characters');

export const contactNo = Yup.string()
    // .label('Contact number')
    .min(10)
    .max(20)
    .matches(/^[0-9]+$/, 'Contact number can contain only numbers')
    .required('Contact number is required');

export const message = Yup.string()
    .label('Message')
    .min(3)
    .max(1000)
    .required();

export const line1 = Yup.string()
    .required('Address Line 1 is required')
    .min(3, 'Address Line 1 should have minimum of 3 characters')
    .max(150, 'Address Line 1 can have maximum of 150 characters');

export const line2 = Yup.string()
    .required('Address Line 2 is required')
    .min(3, 'Address Line 2 should have minimum of 3 characters')
    .max(150, 'Address Line 2 can have maximum of 150 characters');

export const city = Yup.string()
    .required('City is required')
    .matches(/^[A-Za-z ]+$/, 'City can contain only characters');
export const state = Yup.string()
    .required('State is required')
    .matches(/^[A-Za-z ]+$/, 'State can contain only characters');
export const country = Yup.string().required('Country is required');
export const contactNumber = Yup.string()
    .required('Phone number is required')
    .matches(/^([0-9]{5,20})+$/, 'Phone number can contain only numbers')
    .min(5, 'Phone number should have minimum of 5 digits')
    .max(20, 'Phone number can have maximum of 20 digits');

export const postcode = Yup.string()
    .required('Postal Code is required')
    .matches(/^([0-9]{4,15})+$/, 'Postal Code can contain only numbers')
    .min(4, 'Postal Code should have minimum of 4 digits')
    .max(15, 'Postal Code can have maximum of 15 digits');

export const userName = Yup.string()
    .required('UserName is required')
    .matches(
        /^([a-zA-Z0-9_-]{3,20})+$/,
        'Username can contains only alphanumeric, -, _ and maximum 20 characters'
    )
    .min(3, 'Minimum 3 characters');

export const careerFile = Yup.string().required('File is required');

export const blockChain = Yup.string().required('Block Chain is required');
