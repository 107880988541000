    import { ReactElement, useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import { HeadTag } from '../../shared/components';
import {
    About,
    Exhibitions,
    Artwork,
    Artist,
    Works,
    UpcomingExhibitions,
    Announcement
} from './components';
import HomeBanner from './components/HomeBanner';
import { CollectionService } from '../../services';
import Utils from '../../shared/helpers/Utils';
import CuratingLoading from '../../shared/components/curatingLoading';

const HomePage = (): ReactElement => {
    const title = 'NFT Platform';
    const description =
        "NFT Marketplace";
    const [exhibitions, setExibution] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingExhibition, setLoadingExhibition] = useState(false);
    const [artists, setArtists] = useState([]);
    const [arts, setArts] = useState([]);
    const [announcments, setAnnouncments] = useState([]);




      // TODO immediate implementation
      const loadItems = (): void => {
        setLoadingExhibition(true)
        CollectionService.getAllexhibition(
            '',
            '',
            '',
            50

        )
            .then((response: any) => {
                if (response) setExibution(response.data.data)      
                setLoadingExhibition(false)
            })
            .catch(() => setLoadingExhibition(false));
    }


    const loadArtistItems = (): void => {
        setLoading(true)
        CollectionService.getTrendingArtists()
            .then((response: any) => {
                if (response&&response.data.data) {                   
                    setArtists(response.data.data)
                    setLoading(false)
                }
            })
            .catch(() => setLoading(false));

    } 

    const loadArtsItems = (): void => {
    CollectionService.getTrendingArts().then((response:any)=>{
        const art = response.data.data;
        // console.log(art)
        setArts(art);
    }).catch(error=>{
        Utils.handleErrorResponse({ error });
    });
  }  

  const loadAnnouncements = ():any => {
    setLoading(true)
    CollectionService.getLatestAnnouncements().then((response)=>{
      if(response.data.data){
          setAnnouncments(response.data.data)
      }
      setLoading(false)
    }).catch(err=>{
        Utils.handleErrorResponse({ err });
    })
  }

    useEffect(() => {
        loadItems();
        loadArtistItems()
        loadArtsItems()
        loadAnnouncements()
    }, []);    

    return (
        <Box id="home-wrapper">
            <HeadTag title={title} description={description} />
            <Container>
                {/* <Banner /> */}
                {loading || loadingExhibition ?
                   <CuratingLoading />
                :<>
                <HomeBanner exihibitions={exhibitions }/>
                <About />
                <Exhibitions exihibition={exhibitions}/>
                <Artwork arts={arts} />
                <Artist artists={artists} />
                <Announcement announcements={announcments} />
                <Works />
                <UpcomingExhibitions exihibition={exhibitions} />
                </>}
            </Container>
         
        </Box>
    );
};

export default HomePage;
