import { Avatar, Box, Button, CardMedia, CircularProgress, Container, Fade, Grid, Tooltip, Typography } from '@material-ui/core';
import moment from 'moment';
import { ReactElement, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import { CollectionService } from '../../services';
import { EmptyPlaceholder, HeadTag, SlideNextArrow, SlidePrevArrow } from '../../shared/components';
import { AppBigNavButton } from '../../shared/components/AppComponents';
import ArtistReadMore from '../../shared/components/ArtistReadMore';
import NotFound from '../../shared/components/NotFound';
import Video from '../../shared/components/Video';
import CuratingLoading from '../../shared/components/curatingLoading';
import GoesLiveUi from '../../shared/components/goesLiveUi';
import ViewImageZoom from '../../shared/components/imageZoom';
import { Utils } from '../../shared/helpers';
import { checkLauncDate, getUrl, getVideoUrl } from '../../shared/helpers/Utils';
import ReginIconName from '../home/components/regionIconName';
import ExhibitionPost from './ExhibitionPost';
import SocialShare from './SocialShare';

const ExhibitionDetail = (): ReactElement => {
    const title = 'Exhibition Detail';
    const description = " Exhibition Detail";
    const { exhibitionId } = useParams();
    const [artists, setArtists] = useState([]);
    const [controls] = useState(true);
    const [playing, setPlaying] = useState(true);
    const [muted] = useState(true);
    const [loop] = useState(true);
    const [volume] = useState(0);
    const [pip] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loadingExhibition, setExhibitionLoading] = useState(false);
    const [activeExhibitionDetail, setActiveExhibitionDetail] = useState([]);
    const [artsDetail, setArtsDetail] = useState([]);
    const [exhibitionDetail, setExhibitionDetail]: any = useState([]);
    const [errorPage, setErrorPage] = useState(false);
    const [showShareDialog, setShowShareDialog] = useState(false);
    const search = window?.location?.search;
    const params = new URLSearchParams(search);
    const view = params.get('view');

    const navigate = useNavigate();

    // TODO immediate implementation
    // API Calls
    const append = (data:any):any => {

        const head = document.getElementsByTagName("head")[0];
        const meta = document.createElement('meta');
        const ogImage = document.createElement('meta');
        const ogDescription = document.createElement('meta');
        const ogUrl = document.createElement('meta'); 
        const linkUrl = document.createElement('link');


        linkUrl.setAttribute("rel" ,"canonical");
        linkUrl.setAttribute("href" ,window.location.href)
        meta.setAttribute("property" ,"og:title");
        meta.setAttribute("content" ,data?.title);
        ogImage.setAttribute("property" ,"og:image");
        ogImage.setAttribute("content",getUrl(data?.backgroundPicture, '00x700/'))
        
        ogDescription.setAttribute("property" ,"og:description" );
        ogDescription.setAttribute("content" ,data?.description);

        ogUrl.setAttribute("property" ,"og:url" );
        ogUrl.setAttribute("content" ,window.location.href);
     
        if(!document.isEqualNode(meta)){
            head.insertAdjacentElement('afterbegin',meta);
            head.insertAdjacentElement('afterbegin',ogImage);
            head.insertAdjacentElement('afterbegin',ogDescription);
            head.insertAdjacentElement('afterbegin' ,ogUrl)
        }
    }

    const loadItems = (): void => {
        setExhibitionLoading(true)
        setLoading(true)
        CollectionService.getExibitionDetail(exhibitionId,view)
            .then((response: any) => {
                const { data } = response.data;
                setArtists(data.artists)
                setExhibitionDetail(data);
                append(data)
                setArtsDetail(data.arts)
                setLoading(false)
                setExhibitionLoading(false)
         
            })
            .catch((error: any) => {
                setExhibitionLoading(false)
                Utils.handleErrorResponse({ error });
                const statusCode = JSON.parse(
                    JSON.stringify(error)
                )?.message?.replace(/^\D+/g, '');
                if (statusCode === 400 || 404) {
                    // navigate('/notfound');
                    setErrorPage(true)
                }
            });
    };

    const exhibitionItems = (): void => {
        setLoading(true)
        CollectionService.getAllexhibition(
            '',
            '',
            '',
            50


        )
            .then((response: any) => {
                if (response) setActiveExhibitionDetail(response.data.data.active);
                setActiveExhibitionDetail(response.data.data.active)
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
                const statusCode = JSON.parse(
                    JSON.stringify(error)
                )?.message?.replace(/^\D+/g, '');
                if (statusCode === 400 || 404) {
                    setErrorPage(true)
                }
            });
    }



    useEffect(() => {
        loadItems();
        exhibitionItems();
       


    }, [exhibitionId]);

    // useEffect(() => {
    //     loadItems();
    // }, []);

    const ImageSliderConfigArtist = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        arrows: true,
        slidesToScroll: 2,
        initialSlide: 0,
        nextArrow: <SlideNextArrow />,
        prevArrow: <SlidePrevArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const goesLive = (date: any): any => {
        const duration = moment.duration(moment(date).diff(new Date()));
        return `${duration.days()} Day${duration.days() > 1 ? 's' : ''}, ${duration.hours()} hours, ${duration.minutes()} mins`;
    }

 
    if (errorPage) return <NotFound />
    return (
        <Box className='artist-detail-main-container'>
          {/* {OpenMeta(exhibitionDetail?.title,exhibitionDetail?.description,"article",getUrl(exhibitionDetail?.backgroundPicture, '00x700/'),window.location.href)} */}
            <HeadTag title={title} description={description} />
            
            {loadingExhibition ? <CuratingLoading /> :
                <>
                    {exhibitionDetail?.video && exhibitionDetail?.video?.length !== 0 ? (
                        <Box className='artist-detail-inner-container video-box 123'>
                            <Video video={getVideoUrl(exhibitionDetail.video[0])} pip={pip}
                                playing={playing}
                                controls={controls}
                                volume={volume}
                                muted={muted}
                                loop={loop}
                                setPlaying={setPlaying} />
                        </Box>
                    ) : exhibitionDetail?.logo && (
                        <ViewImageZoom
                            com={<Box className='artist-detail-inner-container exhibition-video'>
                                <img src={getUrl(exhibitionDetail?.logo, '00x700/')} />
                            </Box>}
                            preview={getUrl(exhibitionDetail?.logo, '00x700/')}
                            fullimage={getUrl(exhibitionDetail?.logo, '00x1000/')}
                        />
                    )
                    }
                    {!exhibitionDetail?.logo && exhibitionDetail?.video?.length === 0 && exhibitionDetail?.backgroundPicture ?
                        <ViewImageZoom
                            com={<Box className='artist-detail-inner-container exhibition-video'>
                                <img src={getUrl(exhibitionDetail?.backgroundPicture, '00x700/')} />
                            </Box>}
                            preview={getUrl(exhibitionDetail?.backgroundPicture, '00x700/')}
                            fullimage={getUrl(exhibitionDetail?.backgroundPicture, '00x1000/')}
                        />
                        : null
                    }

                    <Box className='artist-detail-section exhibition-detail-section'>
                        <Container>
                            <Box className='exhibition-main-wrapper'>
                                <Box className='exhibition-heading'>
                                    <Typography variant='h2'>
                                        {exhibitionDetail.title ? exhibitionDetail?.title : '---'}
                                    </Typography>
                                    <Button onClick={() => setShowShareDialog(true)} >
                                        <img src='/images/exibition-menu-icon.svg' />
                                    </Button>
                                </Box>


                                <Box className='exibition-img-name'>
                                    <Box className='exhibition-mobile'>
                                        <Box className='exhibition-mobile-wrapper'>
                                            {
                                                exhibitionDetail.length !== 0 && exhibitionDetail.curator.profilePicture !== undefined && (
                                                    <Avatar style={{ width: '4rem', height: '4rem' }} src={exhibitionDetail.curator.profilePicture ? getUrl(`${exhibitionDetail?.curator?.profilePicture}`, '400x400/') : '/icons/user.png'} alt="Avatar" />
                                                )
                                            }
                                            <Box
                                                ml={3}
                                                display='flex'
                                                flexDirection='column'
                                            >
                                                <Tooltip arrow title={exhibitionDetail?.curator?.name} TransitionComponent={Fade}
                                                    TransitionProps={{ timeout: 600 }}>
                                                    <Link to={`/curator/${exhibitionDetail.curator?._id}`}>
                                                        <Typography style={{ fontWeight: 800 }} variant="h4">
                                                            <span style={{ color: '#000000ab' }}>Curated By :</span>  <span className="underLine"> {exhibitionDetail?.curator?.name}  </span>
                                                        </Typography>
                                                    </Link>
                                                </Tooltip>
                                                <Box mt={1}>
                                                    <ReginIconName name={`${exhibitionDetail?.region?.name}, ${exhibitionDetail?.region?.region}`} />
                                                </Box>
                                            </Box>

                                        </Box>
                                    </Box>
                                    <Grid item>
                                        {exhibitionDetail?.startDate && !checkLauncDate(exhibitionDetail?.startDate) ? <GoesLiveUi title='Sale Starts In' date={exhibitionDetail?.startDate} /> : ''}
                                    </Grid>
                                </Box>
                                <Box mb={2} className="nft-description">
                                    {exhibitionDetail?.description?.length && <ArtistReadMore
                                        byWords
                                        length={350}
                                        ellipsis="..."
                                        parentClass="nft-description"
                                        className="nft-description-text">
                                        {exhibitionDetail?.description}
                                    </ArtistReadMore>}
                                </Box>


                                <Box className='exibition-explore-component'>
                                    <Box className='exibition-explore'>
                                        <Typography variant='h3'>
                                            Explore {exhibitionDetail?.title}
                                        </Typography>
                                    </Box>
                                    <Box className='exhibitionpost-wrapper'>
                                        <ExhibitionPost artsDetail={artsDetail} />
                                        {!loading && artsDetail.length === 0 && (
                                            <EmptyPlaceholder
                                                icon={<img src="/icons/NoData.png" />}
                                                message="Explore not available"
                                            />
                                        )}
                                    </Box>
                                </Box>

                                <Box className='exibition-artists-component'>
                                    <Box className='exibition-artists'>
                                        <Typography variant='h3'>
                                            Artists
                                        </Typography>
                                    </Box>
                                    <Box className='exibition-artists-tag'>

                                        {loading &&
                                            <Box className="empty-content">
                                                <CircularProgress />
                                            </Box>
                                        }
                                        {artists.sort((a, b) => a.name.localeCompare(b.name)).map((artist: any) => (
                                            <Grid item xs={6} sm={4} md={3} lg={2} className="exhibition-details-art-grid">
                                                <Box className='exibition-artists-bg'>
                                                    {/* eslint no-underscore-dangle: 0 */}
                                                    <Link to={`/artist/${artist._id}`}>
                                                        <Box className='exibition-artists-tag-box' >
                                                            <img src={artist.profilePicture ? getUrl(`${artist?.profilePicture}`, '400x400/') : '/images/art-blank-design.svg'} alt="ArtistImg" />
                                                        </Box>
                                                        <Typography>{artist?.name}</Typography>
                                                    </Link>
                                                </Box>
                                            </Grid>
                                        ))}

                                        {artists === undefined || artists.length === 0 && (
                                            <EmptyPlaceholder
                                                icon={<img src="/icons/NoData.png" />}
                                            />
                                        )}
                                    </Box>
                                </Box>
                                <Box className='exibition-other-component'>
                                    <Box className='exibition-other'>
                                        <Typography variant='h3'>
                                            Discover More
                                        </Typography>
                                    </Box>

                                    {loading && activeExhibitionDetail.length === 0 && (
                                        <Box className="empty-content">
                                            <CircularProgress />
                                        </Box>
                                    )}
                                    {activeExhibitionDetail.filter((el) => exhibitionDetail._id !== el._id).length > 0 ? (
                                        <Slider className='nft-slider' {...ImageSliderConfigArtist}>
                                            {activeExhibitionDetail && activeExhibitionDetail.filter((el) => exhibitionDetail._id !== el._id).map((activeItem: any) => (
                                                <Grid xs={12} sm={12} lg={12} className='exhibition-box-content upcoming-pr other-upcoming-pr'>
                                                    <Link to={`/exhibition/${activeItem._id}`}>
                                                        <Box className='about-image-box exhibition'>
                                                            <Box className='exhibition-img-block exhibition-img-block-other'>
                                                                <CardMedia
                                                                    component="img"
                                                                    height="290"
                                                                    image={activeItem?.thumbnail ? getUrl(`${activeItem.thumbnail}`, '686x279/') : '/images/if-no-image.png'} alt="thumbnail_image"
                                                                />
                                                            </Box>
                                                            <Typography variant="h6" className='exhibition-tag'>
                                                                {activeItem?.title}
                                                            </Typography>
                                                            <Box className='exhibition-content'>
                                                                <Typography variant="h1">
                                                                    {activeItem?.title}
                                                                </Typography>
                                                                <Grid container spacing={1}>
                                                                    <Grid item style={{ width: '100%' }} sm={12} md={12} lg={12} xl={12}>
                                                                        <Box
                                                                            width='100%'
                                                                            display='flex'
                                                                            flexDirection='row'
                                                                            alignItems='center' className="collage-mobile">
                                                                            <Avatar src={activeItem.curator.profilePicture ? getUrl(`${activeItem?.curator?.profilePicture}`, '57x60/') : '/icons/user.png'} />
                                                                            <Box
                                                                                pl={1}
                                                                                height={46}
                                                                                display='flex'
                                                                                flexDirection='column'
                                                                                className="banner-text">
                                                                                <Typography className='text-gray curator-text '>
                                                                                    Curated By
                                                                                </Typography>
                                                                                <Tooltip arrow title={activeItem.curator.name || 'no name'} TransitionComponent={Fade}
                                                                                    TransitionProps={{ timeout: 600 }}>
                                                                                    <Typography variant="h5" className='curatorName text-ellipsis--1'>
                                                                                        {activeItem?.curator?.name}
                                                                                    </Typography>
                                                                                </Tooltip>
                                                                            </Box>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item style={{ width: '100%' }} sm={12} md={12} lg={12} xl={12}>
                                                                        <Box height={36} className='text-ellipsis--2'>
                                                                            <Typography variant="body1">
                                                                                {activeItem?.description.replace(/<[^>]+>/g, '')}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                                <Box className="exhibition-button">
                                                                    <Box className='exhibition-icon exhibition-icon-grid-wrapper'>
                                                                        {activeItem?.region && <ReginIconName name={`${activeItem?.region?.name}, ${activeItem?.region?.subRegion}`} />}
                                                                    </Box>
                                                                    <AppBigNavButton
                                                                        title="View Exhibition"
                                                                        to={`/exhibition/${activeItem?._id}`}
                                                                        id="hero-button"
                                                                    />
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Link>

                                                </Grid>
                                            ))}
                                        </Slider>
                                    ) :
                                        <EmptyPlaceholder
                                            icon={<img src="/icons/NoData.png" />}
                                            message=""
                                        />
                                    }
                                </Box>
                            </Box>
                        </Container>
                    </Box>
                    <SocialShare
                        onCloseDialog={() => {
                            setShowShareDialog(false);
                        }}
                        header="Exhibition"
                        imagefile={getUrl(exhibitionDetail?.thumbnail, '164x164/')}
                        showShare={showShareDialog}
                        tooltip="Share your portfolio"
                        title={`${window.location.href} \n\n Exhibition @${(exhibitionDetail.title ? exhibitionDetail?.title : 'French Pastels').replace(
                            ' ',
                            '')} \n at @TheUpsideSpace \n\n ${exhibitionDetail?.description?.replace(/<[^>]*>/g, '')}`}
                        link={window.location.href}
                    />
                </>
            }
        </Box>
    )
}

export default ExhibitionDetail
