import { ReactElement } from 'react';
import {
    Typography,
    Grid,
    Container,
    Card,
    CardContent,
    Box,
    TextField,
    IconButton,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { Link } from 'react-router-dom';
import HeadSection from './HeadSection';

const HelpPage = (): ReactElement => {
    const helpData = [
        {
            title: 'General Information',
            subtitle: `Learn about TUS and understand how to create an account and KYC process to start with us`,
            path: '/faq/general-information',
            icon: '/images/faq-general-icon.png',
        },
        {
            title: 'Getting started',
            subtitle:
                'Learn how to create an account, set up your wallet and what you can do on TUS.',
            path: '/faq/getting-started',
            icon: '/images/faq-start.png',
        },
        {
            title: 'Buying',
            subtitle:
                'Learn how to buy your first NFT and understanding how to get physical possession of the collectibles.',
            path: '/faq/buying',
            icon: '/images/faq-buy.png',
        },
        {
            title: 'Selling',
            subtitle:
                'Learn how to resell your collectibles on TUS and understand how do royalties work.',
            path: '/faq/selling',
            icon: '/images/faq-sell.png',
        },
        {
            title: 'Creating',
            subtitle:
                'Learn who can create NFTs and who cannot, and understand how to create collectibles.',
            path: '/faq/creating',
            icon: '/images/faq-create.png',
        },
        {
            title: 'Partners and Developers',
            subtitle:
                'We look forward to partnership opportunities, feel free to reach out.',
            path: '/faq/partners-&-developers',
            icon: '/images/faq-partener.png',
        },
    ];
    return (
        <Box id="helpPage">
            <Container>
                <HeadSection
                    title="The FAQs"
                    subtitle="Welcome to TUS Help Desk"
                    // content="What can we help you find?"
                />

                {false && (
                    <Box className="query-box">
                        <TextField
                            variant="outlined"
                            size="small"
                            placeholder="Type your query here..."
                            InputProps={{
                                endAdornment: (
                                    <IconButton type="button">
                                        <ClearIcon />
                                    </IconButton>
                                ),
                            }}
                        />
                    </Box>
                )}

                <Grid container className="faq-help-box-wrapper">
                    {helpData.map((list: any, index: number) => (
                        <Grid xs={12} md={6} lg={4} item className="faq-box-wrapper-help" key={index}>
                            <Card className="teamCard">
                                <Link to={list.path}>
                                    <CardContent  >
                                        <Box className="mt-2 justify-center">
                                            <img
                                                src={list.icon}
                                                alt=""
                                                width="42"
                                                height="42"
                                            />
                                        </Box>

                                        <Typography
                                            variant="h3"
                                            className="mt-3 mb-3 playfair-bold justify-center main-faq-head-text"
                                        >
                                            {list.title}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            className="mt-4  justify-center main-faq-text"
                                        >
                                            {list.subtitle}
                                        </Typography>
                                    </CardContent>
                                </Link>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};

export default HelpPage;
