import { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Box,
    Typography,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    TextField,
    InputLabel,
    FormHelperText,
    CircularProgress,
    Avatar,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    FormControl,
    Divider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// import {
//     useElements,
//     useStripe,
//     Elements,
//     CardNumberElement,
// } from '@stripe/react-stripe-js';
// import { StripeCardNumberElement, loadStripe } from '@stripe/stripe-js';
import { AppState } from '../../../state/RootReducer';
import {
    AppPrimaryButton,
    AppThemeRadioInput,
    AppSecondaryButton,
    AppCheckBox,
} from '../../../shared/components/AppComponents';
// import { StripeCard } from '../../../shared/components';
import { ItemFullDetail, OwnedBy } from '../NFTDetailPage';
import { ProfileState } from '../../../state/profile/Reducer';
import { BlockChain, Toast, Utils } from '../../../shared/helpers';
import { BuySellService } from '../../../services';
import PriceCountDown from './PriceCountDown';
import { ApplicationDataState } from '../../../state/application/Reducer';
import { useDebouncedEffect } from '../../../shared/hooks';

const publicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
// const stripePromise = loadStripe(publicKey!);

export interface Wallet {
    currencyType: string;
    freeBalance: string;
    lockedBalance: string;
    totalBalance: string;
}

interface Currency {
    amount: string;
    currencyType: string;
}

export interface CurrencyConvertion {
    from: Currency;
    to: Currency;
    holdingId: string;
    expiresIn: Date;
}

export interface CryptoOfferSummary {
    itemId: string;
    userId: string;
    randomHex: string;
    cryptoAmount: string;
    totalCost: string;
    quantity: string;
    ownerAddress: string;
}
interface Currency {
    amount: string;
    currencyType: string;
}

export interface NFTCheckoutProps {
    itemDetail: ItemFullDetail;
    profileInfo: ProfileState;
    appState: ApplicationDataState;
    onPaymentResponse: any;
}

const NFTPostOffer = (props: any): ReactElement => {
    const {
        onPaymentResponse,
        profileInfo,
        appState,
        itemDetail,
    }: NFTCheckoutProps = props;
    const [quantity, setQuantity] = useState(0);
    const [price, setPrice] = useState('');
    const [quantityError, setQuantityError] = useState('');
    const [priceError, setPriceError] = useState('');
    const [paymentMode, setPaymentMode] = useState('fiat');
    const [showLoading, setShowLoading] = useState(false);
    const [showCard, setShowCard] = useState(false);
    const [aggreed, setAggreed] = useState(false);
    const [convertingPrice, setConvertingPrice] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [validCard, setValidCard] = useState(false);
    const [paymentResponse, setPaymentResponse] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const [wallet, setWallet] = useState<Wallet>({} as Wallet);
    const [metamaskAccountDetails, setMetamaskAccountDetails] = useState({
        address: '',
        balance: '',
    });
    const [ownersList, setOwnersList] = useState<OwnedBy[]>([]);
    const [editionsList, setEditionsList] = useState<string[]>([]);
    const [editions, setEditions] = useState<string[]>([]);
    const [ownerId, setOwnerId] = useState('');
    const [cryptoOfferSummary, setCryptoOfferSummary] =
        useState<CryptoOfferSummary>({
            itemId: '',
            userId: '',
            quantity: '',
            randomHex: '',
            cryptoAmount: '',
            totalCost: '',
            ownerAddress: '',
        });
    const [currencyConverstion, setCurrencyConverstion] = useState<
        CurrencyConvertion | undefined
    >();
    // const stripe = useStripe();
    // const elements = useElements();

    const [step, setStep] = useState(0);
    const [randomHex, setRandomHex] = useState('');
    const liveTill = process.env.REACT_APP_OFFER_LIVETILL;

    // Instance Methods
    const getSubTotal = (): number =>
        Number((quantity * parseFloat(price)).toFixed(2));

    // API Calls
    const convertFiatToCryptoPrice = (
        total: number,
        currencyType: string
    ): void => {
        setConvertingPrice(true);
        BuySellService.convertPriceAndHold(total, currencyType, 'ETH')
            .then((response) => {
                const converstion = response.data.data;
                setCurrencyConverstion(converstion);
                setConvertingPrice(false);
            })
            .catch(() => {
                setConvertingPrice(false);
            });
    };

    const processInitiateResponse = async (
        paymentMethod: string,
        response: any
    ): Promise<any> => {
        if (paymentMethod === 'crypto') {
            setShowLoading(true);
            BlockChain.setUpMetaMask()
                .then((metaMaskResponse: any) => {
                    setShowLoading(false);
                    setCryptoOfferSummary({
                        itemId: itemDetail.itemInfo.id,
                        userId: profileInfo.userId,
                        quantity: `${quantity}`,
                        randomHex: randomHex.toString(),
                        cryptoAmount:
                            currencyConverstion !== undefined
                                ? currencyConverstion.to.amount
                                : '',

                        totalCost: '',
                        ownerAddress: itemDetail.ownerInfo.address,
                    });
                    setMetamaskAccountDetails(metaMaskResponse);
                    setStep(1);
                })
                .catch((error) => {
                    setShowLoading(false);
                    if (error.code || error.message) {
                        Toast.showErrorMessage(error.message);
                    } else {
                        Toast.showErrorMessage(error);
                    }
                });
            setShowCard(false);
        } else {
            setStep(1);
            setClientSecret(response.paymentIntent);
            setShowCard(response.cardRequired);
            setWallet(response.fiatBalance);
        }
    };

    const initiateOffer = (): void => {
        setShowLoading(true);
        const data = {
            itemId: itemDetail.itemInfo.id,
            quantity: Number(quantity),
            pricePerQuantity: Number(price),
            editions,
            ownerId,
        };

        const promise = BuySellService.initiateOffer(data);
        promise
            .then((res: any) => {
                const response = res.data.data;
                setShowLoading(false);
                processInitiateResponse(paymentMode, response);
            })
            .catch((error) => {
                Utils.handleErrorResponse({
                    error,
                });
                setShowLoading(false);
            });
    };

    const completeOffer = (paymentId: string): void => {
        setShowLoading(true);
        const data: any = {
            itemId: itemDetail.itemInfo.id,
            paymentMode,
            quantity: editions.length,
            totalPrice: getSubTotal().toString(),
            liveTill,
            pricePerQuantity: Number(price),
            editions,
            ownerId,
        };

        if (paymentId !== '') {
            data.paymentId = paymentId;
        }
        BuySellService.completeOffer(data)
            .then(() => {
                setShowLoading(false);
                setPaymentStatus(true);
                setPaymentResponse(
                    'Congrats! Your NFT offer posted successfully.'
                );
                setStep(2);
                onPaymentResponse(true);
            })
            .catch(() => {
                setShowLoading(false);
                setPaymentStatus(false);
                setPaymentResponse('Offer posted  failed');
                setStep(2);
            });
    };

    const completeOfferForCrypto = (hash: any): void => {
        setShowLoading(true);
        const data: any = {
            itemId: cryptoOfferSummary.itemId,
            paymentMode,
            quantity: editions.length,
            totalPrice: cryptoOfferSummary.cryptoAmount,
            liveTill,
            paymentId: hash,
            randomHex: cryptoOfferSummary.randomHex.toString(),
            pricePerQuantity: Number(price),
            editions,
            ownerId,
        };

        BuySellService.completeOffer(data)
            .then(() => {
                setShowLoading(false);
                setPaymentStatus(true);
                onPaymentResponse(true);
                setPaymentResponse(
                    'Congrats! Your NFT offer posted successfully.'
                );
                setStep(2);
            })
            .catch(() => {
                setShowLoading(false);
                setPaymentStatus(false);
                setPaymentResponse('NFT Offer posted failed');
                setStep(2);
            });
    };

    const doCryptoPayment = async (): Promise<any> => {
        setShowLoading(true);
        BlockChain.postNFTOffer(cryptoOfferSummary)
            .then(async (response: any) => {
                completeOfferForCrypto(response.hash);
            })
            .catch((error: any) => {
                setShowLoading(false);
                if (error.code || error.message) {
                    Toast.showErrorMessage(error.message);
                } else {
                    Toast.showErrorMessage(error);
                }
            });
    };

    // Instance Methods
    const onPriceExpire = (): void => {
        if ((step === 0 || step === 1) && paymentMode === 'crypto') {
            convertFiatToCryptoPrice(
                getSubTotal(),
                itemDetail.itemInfo.currencyType
            );
        }
    };

    const getConfirmButtonTitle = (): string => {
        if (paymentMode === 'fiat') {
            return `CONFIRM & PAY ${getSubTotal()} ${
                itemDetail.itemInfo.currencyType
            }`;
        }
        return `CONFIRM & PAY ${currencyConverstion?.to.amount} ${currencyConverstion?.to.currencyType}`;
    };

    // Hooks
    useDebouncedEffect(
        (): void => {
            if (itemDetail) {
                if (paymentMode === 'crypto') {
                    convertFiatToCryptoPrice(
                        getSubTotal(),
                        itemDetail.itemInfo.currencyType
                    );
                }
            }
        },
        [itemDetail, paymentMode, price, quantity],
        1000
    );

    const loadRandomHex = (): any => {
        BuySellService.getRandomHex()
            .then((response) => {
                const { data } = response.data;
                setRandomHex(data.randomHex);
            })
            .catch();
    };

    useEffect(() => {
        loadRandomHex();
    }, []);

    useEffect(() => {
        const owners = itemDetail.ownedBy.filter(
            (tempOwner) => tempOwner.id !== profileInfo.userId
        );
        setOwnersList(owners);
    }, [itemDetail, profileInfo]);

    // UI Actions
    const setPurchaseQuantity = (qty: number): void => {
        let error = '';
        setQuantity(qty);
        if (qty === 0) {
            error = `Please select edition`;
        }
        setQuantityError(error);
    };

    const setOfferPrice = (offerPrice: string): void => {
        let error = '';
        if (Number.isNaN(parseFloat(offerPrice)) && offerPrice !== '') {
            error = `Please enter valid price`;
        } else if (parseFloat(offerPrice) < 10) {
            error = `Please enter valid price greater than or equal to 10`;
        }
        if (
            offerPrice.split('.').length > 1 &&
            offerPrice.split('.')[1].length > 2
        ) {
            setPrice(parseFloat(offerPrice).toFixed(2));
        } else {
            setPrice(offerPrice);
        }
        setPriceError(error);
    };

    const actionInitiateOffer = (): void => {
        initiateOffer();
    };

    // const actionProcessStripePayment = async (): Promise<any> => {
    //     setShowLoading(true);
    //     const payload = await stripe!.confirmCardPayment(clientSecret, {
    //         payment_method: {
    //             card: elements!.getElement(
    //                 CardNumberElement
    //             ) as StripeCardNumberElement,
    //         },
    //     });
    //     if (payload.error) {
    //         setShowLoading(false);
    //         setPaymentStatus(false);
    //         setPaymentResponse(`${payload.error.message}`);
    //         setStep(2);
    //     } else {
    //         completeOffer(payload.paymentIntent.id);
    //     }
    // };

    const actionConfirmOffer = (): void => {
        if (paymentMode === 'crypto') {
            doCryptoPayment();
        } else if (showCard) {
            // actionProcessStripePayment();
        } else {
            completeOffer('');
        }
    };

    // UI Elements
    const TotalSection = (
        <>
            {quantity === 0 && <>{itemDetail.itemInfo.currencyType}</>}
            {quantity !== 0 && (
                <>
                    {Number.isNaN(getSubTotal()) ? '' : getSubTotal()}{' '}
                    {itemDetail.itemInfo.currencyType}
                    {paymentMode === 'crypto' && (
                        <small>
                            <br />({currencyConverstion?.to?.amount} ETH )
                        </small>
                    )}
                </>
            )}
        </>
    );
    return (
        <>
            {step < 2 && (
                <Grid container spacing={2} className="checkout-container">
                    <Grid item md={6} sm={12} className="left-panel">
                        <Box className="nft-section">
                            <Box className="nft-offer-preview">
                                <img
                                    className="nft-image"
                                    src={itemDetail.itemInfo.thumbnail}
                                />
                                <Box className="nft-detail">
                                    <Typography variant="h4" className="mb-4">
                                        {itemDetail.itemInfo.name}
                                    </Typography>
                                    <Grid
                                        spacing={2}
                                        container
                                        alignItems="center"
                                    >
                                        <Grid xs={5} item>
                                            <Typography>Owner *</Typography>
                                        </Grid>
                                        <Grid xs={7} item>
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                            >
                                                <InputLabel className="bg-white">
                                                    Select Owner
                                                </InputLabel>
                                                <Select
                                                    value={ownerId}
                                                    onChange={(event: any) => {
                                                        const selectedValue =
                                                            event.target.value;
                                                        const tempOwner =
                                                            itemDetail.ownedBy.find(
                                                                (owner) =>
                                                                    owner.id ===
                                                                    selectedValue
                                                            );
                                                        if (tempOwner) {
                                                            setEditionsList(
                                                                tempOwner.editionQuantity
                                                            );
                                                        }
                                                        setEditions([]);
                                                        setOwnerId(
                                                            selectedValue
                                                        );
                                                    }}
                                                    className="white "
                                                    inputProps={{
                                                        readOnly: step > 0,
                                                    }}
                                                >
                                                    {ownersList.map(
                                                        (
                                                            owner: OwnedBy,
                                                            index: number
                                                        ): ReactElement => (
                                                            <MenuItem
                                                                key={`editions_${index}`}
                                                                value={owner.id}
                                                            >
                                                                <ListItemText
                                                                    primary={
                                                                        owner.isPrivate
                                                                            ? Utils.formatedAddress(
                                                                                  owner.address
                                                                              )
                                                                            : Utils.formateName(owner.name) 
                                                                    }
                                                                />
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        {editionsList.length > 0 && (
                                            <>
                                                <Grid xs={5} item>
                                                    <Typography>
                                                        Select Edition *
                                                    </Typography>
                                                </Grid>
                                                <Grid xs={7} item>
                                                    <FormControl
                                                        variant="outlined"
                                                        fullWidth
                                                        size="small"
                                                        className="select-edition"
                                                    >
                                                         <InputLabel className="bg-white">
                                                            Select Edition
                                                        </InputLabel>
                                                        <Select
                                                            value={editions}
                                                            onChange={(
                                                                event: any
                                                            ) => {
                                                                const selectedEditions: string[] =
                                                                    event.target
                                                                        .value;
                                                                setEditions(
                                                                    Utils.sortEditions(
                                                                        selectedEditions
                                                                    )
                                                                );
                                                                setPurchaseQuantity(
                                                                    selectedEditions.length
                                                                );
                                                            }}
                                                            multiple
                                                            renderValue={() =>
                                                                editions.join(
                                                                    ', '
                                                                )
                                                            }
                                                            inputProps={{
                                                                readOnly:
                                                                    step > 0,
                                                            }}
                                                        >
                                                            {editionsList.map(
                                                                (
                                                                    value: string,
                                                                    index: number
                                                                ): any => (
                                                                    <MenuItem
                                                                        key={`editions_${index}`}
                                                                        value={
                                                                            value
                                                                        }
                                                                    >
                                                                        <Checkbox
                                                                            color="primary"
                                                                            checked={
                                                                                editions.indexOf(
                                                                                    value
                                                                                ) >
                                                                                -1
                                                                            }
                                                                        />
                                                                        <ListItemText
                                                                            primary={
                                                                                value
                                                                            }
                                                                        />
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                    <FormHelperText className="Mui-error">
                                                        {quantityError}
                                                    </FormHelperText>
                                                </Grid>
                                            </>
                                        )}
                                        <Grid xs={5} item>
                                            <Typography>Price *</Typography>
                                        </Grid>
                                        <Grid xs={7} item>
                                            <TextField
                                                variant="outlined"
                                                placeholder="ex 10"
                                                type="number"
                                                size="small"
                                                value={price}
                                                InputProps={{
                                                    endAdornment: (
                                                        <Box mr={1}>
                                                            <Typography>
                                                                USD
                                                            </Typography>
                                                        </Box>
                                                    ),
                                                    readOnly: step > 0,
                                                }}
                                                onChange={(e) => {
                                                    setOfferPrice(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <FormHelperText className="Mui-error">
                                                {priceError}
                                            </FormHelperText>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>

                            <Box>
                                <hr />
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Typography>Subtotal</Typography>
                                    <Typography align="right">
                                        {TotalSection}
                                    </Typography>
                                </Box>
                                <Box
                                    mt={1}
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Typography variant="subtitle1">
                                        Total
                                    </Typography>
                                    {convertingPrice && <CircularProgress />}
                                    {!convertingPrice && (
                                        <Box>
                                            <Typography
                                                variant="subtitle1"
                                                align="right"
                                            >
                                                {TotalSection}
                                            </Typography>

                                            <Typography>
                                                {!convertingPrice &&
                                                    paymentMode === 'crypto' &&
                                                    currencyConverstion &&
                                                    step < 2 && (
                                                        <Typography className="text-secondary">
                                                            <PriceCountDown
                                                                onPriceExpire={
                                                                    onPriceExpire
                                                                }
                                                                expiresIn={
                                                                    currencyConverstion?.expiresIn
                                                                }
                                                            />
                                                        </Typography>
                                                    )}
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item md={6} sm={12} className="right-panel">
                        {step === 0 && (
                            <Box className="payment-mode">
                                <Box>
                                    <Typography variant="h4">
                                        Payment Mode
                                    </Typography>
                                    <Box mt={2}>
                                        <AppThemeRadioInput
                                            label="Fiat (USD)"
                                            selectedValue={paymentMode}
                                            value="fiat"
                                            onChange={() => {
                                                setPaymentMode('fiat');
                                            }}
                                        />
                                        {appState.acceptCrypto && (
                                            <AppThemeRadioInput
                                                label="Crypto (ETH)"
                                                selectedValue={paymentMode}
                                                value="crypto"
                                                onChange={() => {
                                                    setPaymentMode('crypto');
                                                }}
                                            />
                                        )}
                                    </Box>
                                </Box>

                                <AppPrimaryButton
                                    title="CONTINUE"
                                    isLoading={showLoading}
                                    disabled={
                                        showLoading ||
                                        convertingPrice ||
                                        quantityError !== '' ||
                                        editions.length === 0 ||
                                        priceError !== '' ||
                                        price === ''
                                    }
                                    onClick={() => {
                                        actionInitiateOffer();
                                    }}
                                />
                            </Box>
                        )}
                        {step === 1 && (
                            <Box className="payment-mode">
                                {!showCard && (
                                    <Box>
                                        <Typography variant="h5">
                                            Pay using wallet balance
                                        </Typography>
                                        {paymentMode === 'fiat' && (
                                            <Box
                                                mt={2}
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <Typography variant="h3">
                                                    {`${wallet.totalBalance} ${wallet.currencyType}`}
                                                </Typography>
                                                <Box ml={2}>
                                                    <Typography className="text-secondary">
                                                        {`Available: ${wallet.freeBalance} ${wallet.currencyType}`}
                                                    </Typography>
                                                    <Typography className="text-secondary">
                                                        {`Locked: ${wallet.lockedBalance} ${wallet.currencyType}`}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        )}
                                        {paymentMode === 'crypto' && (
                                            <Box mt={2}>
                                                <Typography variant="h2">
                                                    {`${Utils.formatCrypto(
                                                        metamaskAccountDetails.balance
                                                    )} ETH`}
                                                </Typography>
                                                <Box mt={2}>
                                                    <Typography className="text-gray">
                                                        Address:
                                                        <span className="text-secondary">
                                                            {metamaskAccountDetails.address !==
                                                            ''
                                                                ? Utils.formatedAddress(
                                                                      metamaskAccountDetails.address
                                                                  )
                                                                : ''}
                                                        </span>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>
                                )}
                                {showCard && (
                                    <Box>
                                        <Typography variant="h4">
                                            Pay by Card
                                        </Typography>
                                        <Box mt={4}>
                                            {/* <StripeCard
                                                onFormChange={({
                                                    completed,
                                                }: any) => {
                                                    setValidCard(completed);
                                                }}
                                            /> */}
                                        </Box>
                                    </Box>
                                )}
                                <Box mt={4}>
                                    <AppCheckBox
                                        value={aggreed}
                                        label={
                                            <Box>
                                                I agree with
                                                <a
                                                    href="/terms"
                                                    target="_blank"
                                                    className="primary ml-2"
                                                >
                                                    Terms and Conditions
                                                </a>
                                            </Box>
                                        }
                                        onChange={(status: boolean) => {
                                            setAggreed(status);
                                        }}
                                    />
                                    <Box>
                                        <AppSecondaryButton
                                            title="BACK"
                                            className="mr-4 mt-10"
                                            disabled={showLoading}
                                            onClick={() => {
                                                setStep(0);
                                            }}
                                        />

                                        <AppPrimaryButton
                                            className="mt-10"
                                            title={getConfirmButtonTitle()}
                                            disabled={
                                                !aggreed ||
                                                (showCard && !validCard) ||
                                                showLoading
                                            }
                                            loadingText="PROCESSING"
                                            isLoading={showLoading}
                                            onClick={actionConfirmOffer}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            )}
            {step === 2 && (
                <Box
                    className="center-content checkout-container"
                    flexDirection="column"
                >
                    <Box mb={2}>
                        <Typography variant="h4">
                            {paymentStatus
                                ? 'Payment Successful!'
                                : 'Payment Failed!'}
                        </Typography>
                    </Box>
                    <Box my={2}>
                        <Avatar
                            src={
                                paymentStatus
                                    ? '/icons/icon-success.png'
                                    : '/icons/icon-failed.png'
                            }
                        />
                    </Box>
                    <Box mt={2}>
                        <Typography className="text-secondary" align="center">
                            {paymentResponse}
                        </Typography>
                    </Box>
                </Box>
            )}
        </>
    );
};

interface NFTPostOfferFormProps {
    showOffer: boolean;
    actionCloseCheckoutDialog: any;
    // itemDetail: ItemFullDetail | undefined;
    // appState: ApplicationDataState;
    // onPaymentResponse: any;
}

const NFTPostOfferForm = (props: NFTPostOfferFormProps): ReactElement => {
    const { showOffer, actionCloseCheckoutDialog } = props;

    // UI Elements
    return (
        <Dialog open={showOffer} fullWidth maxWidth="md">
            <DialogTitle>
                <Box className="title-section">
                    <Typography variant="h4">Post Offer</Typography>
                    <IconButton
                        onClick={() => {
                            actionCloseCheckoutDialog();
                        }}
                    >
                        <CloseIcon color="primary" />
                    </IconButton>
                </Box>
                <Divider />
            </DialogTitle>
            <DialogContent>
                {/* <Elements stripe={stripePromise}>
                    <NFTPostOffer {...props} />
                </Elements> */}
            </DialogContent>
        </Dialog>
    );
};

const mapStateToProps = (state: AppState): any => ({
    appState: state.applicationState,
    profileInfo: state.profileInfo,
});

export default connect(mapStateToProps, null)(NFTPostOfferForm);
