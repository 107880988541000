import { ReactElement, useEffect, useState } from 'react';
import { Avatar, Box, CardHeader, CardMedia, CircularProgress, Fade, Grid, Tooltip, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CollectionsIcon from '@material-ui/icons/Collections';
import { EmptyPlaceholder, HeadTag } from '../../../shared/components';
import { AppBigNavButton } from '../../../shared/components/AppComponents';
import { getUrl } from '../../../shared/helpers/Utils';
import { CollectionService } from '../../../services';
import { AppState } from '../../../state/RootReducer';
import ReginIconName from '../../home/components/regionIconName';
// import { AppBigNavButton } from '../../../shared/components/AppComponents';

export interface EXHIBITIONPROPS {
    marketPlaceFilter?: any;
    loaderUi?: any;
    isAPICalling?: any;
}

const ExhibitionTab = (props: EXHIBITIONPROPS): ReactElement => {
    const { marketPlaceFilter, loaderUi, isAPICalling } = props;
    const title = 'Exhibition Tab';
    const description =
        "Exhibition Tab";
    // const {active } = exihibition;
    const [exhibitions, setExibution] = useState([]);
    const [loader, setLoader] = useState(true);

    // TODO immediate implementation
    const loadItems = (): void => {
        CollectionService.getExhibitionList(
            50,
            1,
            marketPlaceFilter.query,
            marketPlaceFilter.apiType,
            marketPlaceFilter.region
        )
            .then((response: any) => {
                const { data } = response.data
                if (data) {
                    setExibution(data)
                    setLoader(false)

                }
                setLoader(false)

            })
            .catch(() => setLoader(false));
    }

    useEffect(() => {
        loadItems();
    }, [marketPlaceFilter]);

    return (
        <Box
            id="exhibition-wrapper"
            className="exhibition-wrapper-py exhibition-wrapper-py-tab"
        >
            <HeadTag title={title} description={description} />
            <Grid className="banner-wrapper" alignItems="center">
                {(isAPICalling&&loader) ? loaderUi : <Box className="about-content-wrepper about-content-wrepper-tab flex-wrap">
                        {exhibitions &&
                            exhibitions.length > 0 &&
                            exhibitions.map((activeExhibitionTab: any) => (
                                <Grid
                                    xs={12}
                                    sm={6}
                                    lg={6}
                                    className="exhibition-tab-link"
                                >
                                    {/* eslint no-underscore-dangle: 0 */}
                                    <Link
                                        to={`/exhibition/${activeExhibitionTab._id}`}
                                        className=""
                                    >
                                        <Box className="about-image-box exhibition exhibition-tab">
                                            <CardMedia
                                                component="img"
                                                height="287"
                                                image={activeExhibitionTab.logo ? getUrl(
                                                    `${activeExhibitionTab.logo}`,
                                                    '684x487/'
                                                ) : '/images/if-no-image.png'} alt="thumbnail_image"
                                            />
                                            <Box className="exhibition-tab-wrapper">
                                                <Box className="exhibition-tab-text">
                                                    <Typography
                                                        variant="h1">
                                                        {activeExhibitionTab?.title
                                                            ? activeExhibitionTab?.title
                                                            : 'No title'}
                                                    </Typography>
                                                </Box>
                                                <Grid container spacing={1}>
                                                    <Grid item sm={12} md={12} lg={12} xl={12}>
                                                        <Box
                                                            display='flex'
                                                            flexDirection='row'
                                                            alignItems='center' className="collage-mobile">
                                                            <Avatar src={
                                                                activeExhibitionTab
                                                                    .curator
                                                                    .profilePicture
                                                                    ? getUrl(
                                                                        `${activeExhibitionTab.curator.profilePicture}`,
                                                                        '100x100/'
                                                                    )
                                                                    : '/icons/user.png'
                                                            } alt='user' />
                                                            <Box
                                                                pl={1}
                                                                height={46}
                                                                display='flex'
                                                                flexDirection='column'
                                                                justifyContent='center'
                                                                className="banner-text">
                                                                <Typography className='text-gray curator-text mb-1' >
                                                                    Curated By
                                                                </Typography>
                                                                <Tooltip arrow title={activeExhibitionTab.curator.name} TransitionComponent={Fade}
                                                                    TransitionProps={{ timeout: 600 }}>
                                                                    <Typography className='curatorName text-ellipsis--1' variant="body1">
                                                                        {activeExhibitionTab?.curator?.name}
                                                                    </Typography>
                                                                </Tooltip>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item style={{ width: '100%' }} sm={12} md={12} lg={12} xl={12}>
                                                        <Box height={36} className='text-ellipsis--2'>
                                                            <Typography variant="body1">
                                                                {activeExhibitionTab?.description.replace(/<[^>]+>/g, '')}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <Box className="exhibition-button">
                                                    {activeExhibitionTab?.region && <ReginIconName name={`${activeExhibitionTab?.region?.name}, ${activeExhibitionTab?.region?.subRegion}`} />}
                                                    <Box className="exhibition-text-line">
                                                        <Typography variant="h6">
                                                            View Exhibition
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Link>
                                </Grid>
                            ))}
                    </Box>
                }


                {exhibitions === undefined ||
                    ((!loader && !isAPICalling) && exhibitions.length === 0 && (
                        <EmptyPlaceholder
                            icon={<img src="/icons/NoData.png" />}
                        />
                    ))}
            </Grid>
        </Box>
    );
}

const mapStateToProps = (state: AppState): any => ({
    marketPlaceFilter: state.marketPlaceFilter,
    isAPICalling: state.authState.isAPICalling
});

export default connect(mapStateToProps, null)(ExhibitionTab);
