import { SlideNextArrow, SlidePrevArrow } from '../../../shared/components';

export const ImageSliderConfig = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SlideNextArrow />,
    prevArrow: <SlidePrevArrow />,
};
