/* eslint-disable no-irregular-whitespace */
import { ReactElement, useState, useRef } from 'react';
import { Formik } from 'formik';
import {
    Typography,
    Grid,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    Box,
    IconButton,
    AccordionSummary,
    Accordion,
    AccordionDetails,
    InputLabel,
    Divider,
    Button,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { toast } from 'react-toastify';
import { CareerSchema } from '../../validators/CareerSchema';
import HeadSection from './HeadSection';
import { Utils } from '../../shared/helpers';
import {
    AppPrimaryButton,
    AppFormikTextField,
} from '../../shared/components/AppComponents';
import { CareerService } from '../../services/CareerService';

const CareerPage = (): ReactElement => {
    const [expanded, setExpanded] = useState<string | false>(false);
    const [showForm, setShowForm] = useState(false);
    const [jobTitle, setJobTitle] = useState('');
    const inputFileRef: any = useRef();

    const careerForm = {
        firstName: '',
        lastName: '',
        email: '',
        contactNo: '',
    };
    const [profileFile, setProfileFile] = useState('');
    const [preProfile, setPreProfile] = useState('');
    const [fileName, setFileName] = useState('');

    const handleChange = (panel: string): any => {
        setExpanded(panel);
    };

    // API Calls
    const createCareer = (data: any, callback: any): any => {
        CareerService.postCareer(data)
            .then((response: any) => {
                Utils.handleSuccessResponse(response);
                callback(true);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
                callback(false);
            });
    };

    // UI Actions
    const actionOnFormSubmit = (formValues: any, formik: any): any => {
        const { firstName, lastName, email, contactNo }: any = formValues;
        const data = new FormData();
        data.append('firstName', firstName);
        data.append('lastName', lastName);
        data.append('email', email);
        data.append('contactNo', contactNo);
        data.append('jobTitle', jobTitle);
        data.append('file', profileFile);
        formik.setSubmitting(true);
        createCareer(data, (status: boolean): any => {
            if (status) {
                formik.resetForm();
                formik.setSubmitting(false);
                setShowForm(false);
                setExpanded(false);
                setProfileFile('');
                setPreProfile('');
            } else {
                formik.setSubmitting(false);
                setProfileFile('');
                setPreProfile('');
            }
        });
    };

    const actionFileUpload = (event: any): any => {
        const file = event.target.files[0];
        if (file) {
            if (
                file.type === 'application/pdf' ||
                file.type === 'application/msword' ||
                file.type ===
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            ) {
                setProfileFile(file);
                setFileName(file.name);
                const reader = new FileReader();
                let preFileTemp: any = '';
                reader.onload = () => {
                    preFileTemp = reader.result;
                    setPreProfile(preFileTemp);
                };
                reader.readAsDataURL(file);
            } else {
                toast.error('Only pdf and word files are supported');
                setProfileFile('');
                setPreProfile('');
            }
        }
    };

    const actionOnCloseForm = (): void => {
        setShowForm(false);
        setProfileFile('');
        setPreProfile('');
    };

    const actionOnUploadRef = (): void => {
        if (inputFileRef.current && inputFileRef.current !== null) {
            inputFileRef.current.click();
        }
    };

    // UI Elements
    return (
        <Container>
            <Dialog open={showForm} maxWidth="md">
                <DialogTitle>
                    <Box className="title-section">
                        <Typography variant="h3">Apply for Career</Typography>
                        <IconButton onClick={actionOnCloseForm}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <hr />
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Formik
                            enableReinitialize
                            initialValues={careerForm}
                            validationSchema={CareerSchema}
                            validateOnMount
                            onSubmit={(values: any, formik: any): any => {
                                actionOnFormSubmit(values, formik);
                            }}
                        >
                            {({ handleSubmit, isSubmitting, isValid }): any => (
                                <>
                                    <AppFormikTextField
                                        name="firstName"
                                        type="text"
                                        label="First Name *"
                                        placeholder="First Name"
                                        outerLabel
                                    />
                                    <AppFormikTextField
                                        name="lastName"
                                        type="text"
                                        label="Last Name *"
                                        placeholder="Last Name"
                                        outerLabel
                                    />
                                    <AppFormikTextField
                                        name="email"
                                        type="text"
                                        label="Email ID *"
                                        placeholder="Email ID"
                                        outerLabel
                                    />
                                    <AppFormikTextField
                                        name="contactNo"
                                        type="number"
                                        label="Contact number *"
                                        placeholder="Contact number"
                                        outerLabel
                                    />

                                    <Box my={2}>
                                        <InputLabel>CV/Resume *</InputLabel>
                                    </Box>

                                    <Box my={3}>
                                        <Grid container spacing={3}>
                                            <Grid
                                                item
                                                sm={6}
                                                className="align-center upload-container"
                                            >
                                                {' '}
                                                <input
                                                    type="file"
                                                    style={{
                                                        display: 'none',
                                                    }}
                                                    accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                    id="profile"
                                                    onChange={(e) =>
                                                        actionFileUpload(e)
                                                    }
                                                    ref={inputFileRef}
                                                />
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={actionOnUploadRef}
                                                    className="btn-small"
                                                >
                                                    Upload file
                                                </Button>
                                            </Grid>{' '}
                                            <Grid item sm={6}>
                                                {preProfile === '' && (
                                                    <Box className="flex-column">
                                                        <Typography variant="body1">
                                                            Max size: 5MB
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            Format: Pdf and docx
                                                        </Typography>
                                                    </Box>
                                                )}
                                                {preProfile !== '' && (
                                                    <Box className="align-center">
                                                        <Typography variant="body1">
                                                            {fileName}
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Box
                                        my={2}
                                        display="flex"
                                        justifyContent="flex-end"
                                    >
                                        <AppPrimaryButton
                                            title="Apply Now"
                                            isLoading={isSubmitting}
                                            loadingText="PROCESSING"
                                            disabled={
                                                isSubmitting ||
                                                !isValid ||
                                                profileFile === ''
                                            }
                                            onClick={handleSubmit}
                                            endIcon={<ArrowRightAltIcon />}
                                        />
                                    </Box>
                                </>
                            )}
                        </Formik>
                    </Box>
                </DialogContent>
            </Dialog>

            <Box id="careerPage">
                <HeadSection
                    title="Careers"
                    subtitle="Opportunities don’t happen, you create them"
                    content="We seek out the most likeable professionals with the brightest minds. So come for the big opportunities, and stay for the people."
                />
                <Grid container spacing={2} className="py-14">
                    <Grid xs={12} sm={12} item>
                        <Accordion
                            expanded={expanded === 'panel1'}
                            onChange={() => {
                                handleChange('panel1');
                                setJobTitle(`Head of Marketing`);
                            }}
                            className="career-container"
                        >
                            <AccordionSummary
                                expandIcon={<AddIcon color="primary" />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography variant="h4">
                                    Head of Marketing
                                </Typography>
                            </AccordionSummary>

                            <Box className="divider">
                                <Divider />
                            </Box>

                            <AccordionDetails className="acr">
                                <Typography
                                    variant="body1"
                                    className="poppins-medium my-3"
                                >
                                    A. Location
                                </Typography>
                                <Typography variant="body1">
                                    India, Singapore. Currently in a Work from
                                    home mode. Later on company can ask to join
                                    either in Mumbai or Singapore
                                </Typography>
                                <Typography
                                    variant="body1"
                                    className="poppins-medium my-3"
                                >
                                    B. Job Brief
                                </Typography>
                                <Typography variant="body1">
                                    We are looking for a Head of Marketing
                                    who’ll lead all our marketing activities
                                    from social media and digital campaigns to
                                    advertising and creative projects.
                                    <br />
                                    <br />
                                    Head of Marketing responsibilities include
                                    developing plans to help establish our
                                    brand, allocating resources to different
                                    projects and setting short-term and
                                    long-term department goals. If you’re a
                                    skilled Marketing strategist, able to
                                    inspire your team members, we’d like to meet
                                    you.
                                    <br />
                                    <br />
                                    Ultimately, you will run our Marketing
                                    department in ways that promote higher
                                    profitability and competitiveness.
                                </Typography>
                                <Typography
                                    variant="body1"
                                    className="poppins-medium my-3"
                                >
                                    C. Responsibilities
                                </Typography>
                                <Typography variant="body1">
                                    • Craft strategies for all Marketing teams,
                                    including Digital, Advertising,
                                    Communications and Creative
                                    <br />
                                    • Prepare and manage monthly, quarterly and
                                    annual budgets for the Marketing department
                                    <br />
                                    • Set, monitor and report on team goals
                                    <br />
                                    • Design branding, positioning and pricing
                                    strategies
                                    <br />
                                    • Ensure our brand message is strong and
                                    consistent across all channels and marketing
                                    efforts (like events, email campaigns, web
                                    pages and promotional material)
                                    <br />
                                    • Analyze consumer behaviour and determine
                                    customer personas
                                    <br />
                                    • Identify opportunities to reach new market
                                    segments and expand market share
                                    <br />
                                    • Craft quarterly and annual hiring plans
                                    <br />
                                    • Monitor competition (acquisitions, pricing
                                    changes and new products and features),
                                    Identifying potential customers.
                                    <br />
                                    • Coordinate sales and marketing efforts to
                                    boost brand awareness
                                    <br />
                                    • Participate in the quarterly and annual
                                    planning of company objectives
                                    <br />
                                </Typography>

                                <Typography
                                    variant="body1"
                                    className="poppins-medium my-3"
                                >
                                    D. Requirements
                                </Typography>
                                <Typography variant="body1">
                                    • Work experience as Head of Marketing or VP
                                    Marketing, preferably within our industry
                                    <br />
                                    • Experience running successful marketing
                                    campaigns
                                    <br />
                                    • Solid knowledge of web analytics and
                                    Google AdWords
                                    <br />
                                    • Leadership skills with the ability to set
                                    and prioritize goals
                                    <br />
                                    • MBA in Marketing or relevant field from an
                                    institute of Repute
                                    <br />
                                    • Excellent interpersonal, written and oral
                                    communication skills.
                                    <br />
                                </Typography>
                                <Box className="my-4">
                                    <AppPrimaryButton
                                        title="Apply Now"
                                        className="btn-small"
                                        onClick={() => setShowForm(true)}
                                        endIcon={<ArrowRightAltIcon />}
                                    />
                                </Box>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion
                            expanded={expanded === 'panel2'}
                            onChange={() => {
                                handleChange('panel2');
                                setJobTitle(
                                    `Node JS - Backend Developer (Positions - 3)`
                                );
                            }}
                            className="career-container"
                        >
                            <AccordionSummary
                                expandIcon={<AddIcon color="primary" />}
                            >
                                <Typography variant="h4">
                                    Node JS - Backend Developer (Positions - 3)
                                </Typography>
                            </AccordionSummary>

                            <Box className="divider">
                                <Divider />
                            </Box>

                            <AccordionDetails className="acr">
                                <Typography
                                    variant="body1"
                                    className="poppins-medium my-3"
                                >
                                    A. Location
                                </Typography>
                                <Typography variant="body1">
                                    Delhi-NCR, India currently Work from home
                                </Typography>
                                <Typography
                                    variant="body1"
                                    className="poppins-medium my-3"
                                >
                                    B. Job Brief
                                </Typography>
                                <Typography variant="body1">
                                    We are looking for a highly capable Node.js
                                    developer to optimize our web-based
                                    application performance. You will be
                                    collaborating with our front-end application
                                    developers, designing back-end components,
                                    and integrating data storage and protection
                                    solutions.
                                </Typography>
                                <Typography
                                    variant="body1"
                                    className="poppins-medium my-3"
                                >
                                    C. Responsibilities
                                </Typography>
                                <Typography variant="body1">
                                    • Collaborating with front-end developers on
                                    the integration of elements.
                                    <br />
                                    • Developing high-performance applications
                                    by writing testable, reusable, and efficient
                                    code..
                                    <br />
                                    • Implementing effective security protocols,
                                    data protection measures, and storage
                                    solutions..
                                    <br />
                                    • Documenting Node.js processes, including
                                    database schemas, as well as preparing
                                    reports..
                                    <br />
                                    • Recommending and implementing improvements
                                    to processes and technologies..
                                    <br />• Keeping informed of advancements in
                                    the field of Node.js development.
                                </Typography>

                                <Typography
                                    variant="body1"
                                    className="poppins-medium my-3"
                                >
                                    D. Requirements
                                </Typography>
                                <Typography variant="body1">
                                    • Proficient in working in ES6/Type Script
                                    with NodeJS & Express.
                                    <br />
                                    • Solid understanding the nature of
                                    asynchronous programming and its quirks and
                                    workarounds.
                                    <br />
                                    • Hands-on experience in server-side
                                    templating languages and server-side CSS
                                    pre-processors.
                                    <br />
                                    • In-depth understanding of front-end
                                    technologies, such as HTML5, and CSS3.
                                    <br />
                                    • Experience in understanding accessibility
                                    and security compliance along with user
                                    authentication and authorization between
                                    multiple systems, servers, and
                                    environments..
                                    <br />
                                    • Experience in integration of multiple data
                                    sources and databases into one system.
                                    <br />
                                    • Experience in fundamental design
                                    principles behind a scalable application.
                                    <br />
                                    • Proficient understanding of code
                                    versioning tools, such as Git and familiar
                                    with concepts of Cloud based microservices.
                                    <br />
                                    • Expertise in Rest API design and
                                    development..
                                    <br />• Experience working in NoSQL database
                                    like MongoDB.
                                </Typography>
                                <Box className="my-4">
                                    <AppPrimaryButton
                                        title="Apply Now"
                                        className="btn-small"
                                        onClick={() => setShowForm(true)}
                                        endIcon={<ArrowRightAltIcon />}
                                    />
                                </Box>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion
                            expanded={expanded === 'panel3'}
                            onChange={() => {
                                handleChange('panel3');
                                setJobTitle(
                                    `Blockchain Developer/ Architect (Positions - 2)`
                                );
                            }}
                            className="career-container"
                        >
                            <AccordionSummary
                                expandIcon={<AddIcon color="primary" />}
                            >
                                <Typography variant="h4">
                                    Blockchain Developer/ Architect (Positions -
                                    2)
                                </Typography>
                            </AccordionSummary>
                            <Box className="divider">
                                <Divider />
                            </Box>
                            <AccordionDetails className="acr">
                                <Typography
                                    variant="body1"
                                    className="poppins-medium my-3"
                                >
                                    A. Location
                                </Typography>
                                <Typography variant="body1">
                                    Delhi-NCR, India currently Work from home
                                </Typography>
                                <Typography
                                    variant="body1"
                                    className="poppins-medium my-3"
                                >
                                    B. Job Brief
                                </Typography>
                                <Typography variant="body1">
                                    We are looking for a highly capable Node.js
                                    developer to optimize our web-based
                                    application performance. You will be
                                    collaborating with our front-end application
                                    developers, designing back-end components,
                                    and integrating data storage and protection
                                    solutions.We are looking for a blockchain
                                    developer who will be responsible for
                                    designing, implementing, and supporting a
                                    distributed blockchain-based network. Your
                                    primary responsibility will be analysing
                                    requirements, designing blockchain
                                    technology around a certain business model,
                                    and the building and launching of a
                                    blockchain network.
                                    <br />
                                    <br />
                                    Also, Position requires maintenance &
                                    further development of existing platform
                                </Typography>
                                <Typography
                                    variant="body1"
                                    className="poppins-medium my-3"
                                >
                                    C. Responsibilities
                                </Typography>
                                <Typography variant="body1">
                                    • Research, design, develop, and test
                                    blockchain technologies
                                    <br />
                                    • Document new solutions as well as
                                    maintaining that of existing ones
                                    <br />
                                    • Be involved in the global blockchain
                                    community—work on implementing and
                                    integrating the latest improvement proposals
                                    <br />
                                </Typography>

                                <Typography
                                    variant="body1"
                                    className="poppins-medium my-3"
                                >
                                    D. Requirements
                                </Typography>
                                <Typography variant="body1">
                                    • Hands-on experience in Ethereum, Solidity,
                                    Golang, Nodejs, Javascript, NFT, ERC Tokens,
                                    DeFi, DAPPS
                                    <br />
                                    • Strong software development background,
                                    Expertise in Rest API design and
                                    development. <br />
                                    • Experience working in NoSQL databases like
                                    MongoDB.
                                    <br />
                                    • Strong knowledge of common algorithms and
                                    data structures
                                    <br />
                                    • Proficient understanding of code
                                    versioning tools, such as Git and familiar
                                    with concepts of Cloud based microservices.
                                    <br />
                                    • Must have worked with Blockchain products
                                    and played vital role to build that
                                    application as developer <br />
                                </Typography>
                                <Box className="my-4">
                                    <AppPrimaryButton
                                        title="Apply Now"
                                        className="btn-small"
                                        onClick={() => setShowForm(true)}
                                        endIcon={<ArrowRightAltIcon />}
                                    />
                                </Box>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion
                            expanded={expanded === 'panel4'}
                            onChange={() => {
                                handleChange('panel4');
                                setJobTitle(
                                    `Frontend Developer-React Developer (Positions - 2)`
                                );
                            }}
                            className="career-container"
                        >
                            <AccordionSummary
                                expandIcon={<AddIcon color="primary" />}
                            >
                                <Typography variant="h4">
                                    Frontend Developer- React Developer
                                    (Positions - 2)
                                </Typography>
                            </AccordionSummary>
                            <Box className="divider">
                                <Divider />
                            </Box>
                            <AccordionDetails className="acr">
                                <Typography
                                    variant="body1"
                                    className="poppins-medium my-3"
                                >
                                    A. Location
                                </Typography>
                                <Typography variant="body1">
                                    Delhi-NCR, India currently Work from home
                                </Typography>
                                <Typography
                                    variant="body1"
                                    className="poppins-medium my-3"
                                >
                                    B. Job Brief
                                </Typography>
                                <Typography variant="body1">
                                    You will be responsible for developing and
                                    implementing user interface components using
                                    React.js concepts and workflows. You will
                                    also be responsible for profiling and
                                    improving front-end performance and
                                    documenting our front-end codebase.
                                    <br />
                                    <br />
                                    To ensure success as a React.js Developer,
                                    you should have in-depth knowledge of
                                    JavaScript and React concepts, excellent
                                    front-end coding skills, and a good
                                    understanding of progressive web
                                    applications
                                </Typography>
                                <Typography
                                    variant="body1"
                                    className="poppins-medium my-3"
                                >
                                    C. Responsibilities
                                </Typography>
                                <Typography variant="body1">
                                    • Meeting with the development team to
                                    discuss user interface ideas and
                                    applications.
                                    <br />
                                    • Reviewing application requirements and
                                    interface designs.
                                    <br />
                                    • Identifying web-based user interactions.
                                    <br />
                                    • Developing and implementing
                                    highly-responsive user interface components
                                    using React concepts.
                                    <br />
                                    • Writing application interface codes using
                                    JavaScript following React.js workflows.
                                    <br />
                                    • Troubleshooting interface software and
                                    debugging application codes.
                                    <br />
                                    • Developing and implementing front-end
                                    architecture to support user interface
                                    concepts.
                                    <br />
                                    • Monitoring and improving front-end
                                    performance.
                                    <br />• Documenting application changes and
                                    developing updates.
                                </Typography>

                                <Typography
                                    variant="body1"
                                    className="poppins-medium my-3"
                                >
                                    D. Requirements
                                </Typography>
                                <Typography variant="body1">
                                    • Expertise of UI framework - React/Redux,
                                    RXJS
                                    <br />
                                    • Experience with React Native is preferred
                                    <br />
                                    • Preferred experience with new generation
                                    of Web Programming - using Micro Service,
                                    REST/JSON, Component UI models
                                    <br />
                                    • Expertise with data visualization flow
                                    development along with usage of modern
                                    charting and graphing Javascript library
                                    <br />
                                    • Preferred experience with Docker based
                                    development/deployment platform
                                    <br />
                                    • Preferred experience with AWS Cloud
                                    <br />
                                    • Preferred experience with AWS RedShift or
                                    Postgres
                                    <br />
                                    • React / Redux, RXJS, HTML, CSS, Javascript
                                    ( ES5, ES6), Data visualization and Chart
                                    Libraries
                                    <br />
                                    • Excellent troubleshooting skills.
                                    <br />• Good project management skill
                                </Typography>
                                <Box className="my-4">
                                    <AppPrimaryButton
                                        title="Apply Now"
                                        className="btn-small"
                                        onClick={() => setShowForm(true)}
                                        endIcon={<ArrowRightAltIcon />}
                                    />
                                </Box>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion
                            expanded={expanded === 'panel5'}
                            onChange={() => {
                                handleChange('panel5');
                                setJobTitle(
                                    `Sr. DevOps- Developer (Positions – 1)`
                                );
                            }}
                            className="career-container"
                        >
                            <AccordionSummary
                                expandIcon={<AddIcon color="primary" />}
                            >
                                <Typography variant="h4">
                                    Sr. DevOps- Developer (Positions – 1)
                                </Typography>
                            </AccordionSummary>
                            <Box className="divider">
                                <Divider />
                            </Box>
                            <AccordionDetails className="acr">
                                <Typography
                                    variant="body1"
                                    className="poppins-medium my-3"
                                >
                                    A. Location
                                </Typography>
                                <Typography variant="body1">
                                    Delhi-NCR, India currently Work from home
                                </Typography>
                                <Typography
                                    variant="body1"
                                    className="poppins-medium my-3"
                                >
                                    B. Job Brief
                                </Typography>
                                <Typography variant="body1">
                                    Looking someone who can take care all the
                                    server related works, their security, can
                                    handle code version through GitHub, handle
                                    the server logs, loads with automated tools.
                                    <br />
                                    <br />
                                    Hassle free deploying the code on servers
                                    with automated scripts. Should have
                                    provision to manage the server downtime
                                    efficiently during deployment time on
                                    production environment. Only privileges
                                    candidate can access the code and servers
                                    with restricted IP address.
                                </Typography>
                                <Typography
                                    variant="body1"
                                    className="poppins-medium my-3"
                                >
                                    C. Responsibilities
                                </Typography>
                                <Typography variant="body1">
                                    • Serve as a point of cross-functional
                                    contact and expertise, providing support to
                                    development and QA teams for the build and
                                    release process to all environments.
                                    <br />
                                    • Provide emergency support for critical
                                    issues, researching possible cause and
                                    providing solutions.
                                    <br />
                                    • Aid DevOps team in the maintenance of
                                    existing automated deployment operations as
                                    well as the advancement of desired state
                                    automated CI-CD Pipelines
                                    <br />
                                    • Documents build and deployment process for
                                    assigned product and/or tools
                                    <br />
                                    • Aid Infrastructure team in the
                                    implementation of new resources used by the
                                    development, test, and production
                                    environments.
                                    <br />
                                    • Maintain awareness of best-in-class tools,
                                    languages, and practices related to
                                    development operations.
                                    <br />
                                    • Provides instructions to Infrastructure,
                                    Development and Test teams to aid in product
                                    development life cycle.
                                    <br />• Participate in discussions regarding
                                    new product development and product
                                    enhancements.
                                </Typography>

                                <Typography
                                    variant="body1"
                                    className="poppins-medium my-3"
                                >
                                    D. Requirements
                                </Typography>
                                <Typography variant="body1">
                                    • Experience working on Linux based
                                    infrastructure
                                    <br />
                                    • Excellent understanding of Ruby, Python,
                                    Perl, and Java
                                    <br />
                                    • Configuration and managing databases such
                                    as MySQL, MongoDB
                                    <br />
                                    • Excellent troubleshooting
                                    <br />
                                    • Working knowledge of various tools,
                                    open-source technologies, and cloud services
                                    <br />
                                    • Awareness of critical concepts in DevOps
                                    and Agile principles
                                    <br />
                                    • Great Experience with public cloud
                                    platforms such as AWS, Google Cloud Platform
                                    (GCP), or Microsoft Azure.
                                    <br />
                                    • Must have implementation experience with
                                    TLS/SSL, public private key cryptography,
                                    how certificates work, and mutual
                                    authentication
                                    <br />• Should have deal with IP addressing,
                                    firewalls, open ports, DNS & DHCP
                                </Typography>
                                <Box className="my-4">
                                    <AppPrimaryButton
                                        title="Apply Now"
                                        className="btn-small"
                                        onClick={() => setShowForm(true)}
                                        endIcon={<ArrowRightAltIcon />}
                                    />
                                </Box>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion
                            expanded={expanded === 'panel6'}
                            onChange={() => {
                                handleChange('panel6');
                                setJobTitle(
                                    `Full Stack Developer (Positions – 1)`
                                );
                            }}
                            className="career-container"
                        >
                            <AccordionSummary
                                expandIcon={<AddIcon color="primary" />}
                            >
                                <Typography variant="h4">
                                    Full Stack Developer (Positions – 1)
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className="acr">
                                <Typography
                                    variant="body1"
                                    className="poppins-medium my-3"
                                >
                                    A. Location
                                </Typography>
                                <Typography variant="body1">
                                    Noida, India. Currently Work from home
                                </Typography>
                                <Typography
                                    variant="body1"
                                    className="poppins-medium my-3"
                                >
                                    B. Job Brief
                                </Typography>
                                <Typography variant="body1">
                                    Looking someone who can take care all the
                                    server related works, their security, can
                                    handle code version through GitHub, handle
                                    the server logs, loads with automated tools.{' '}
                                    <br />
                                    <br />
                                    Hassle free deploying the code on servers
                                    with automated scripts. Should have
                                    provision to manage the server downtime
                                    efficiently during deployment time on
                                    production environment. Only privileges
                                    candidate can access the code and servers
                                    with restricted IP address.
                                </Typography>
                                <Typography
                                    variant="body1"
                                    className="poppins-medium my-3"
                                >
                                    C. Responsibilities
                                </Typography>
                                <Typography variant="body1">
                                    • Serve as a point of cross-functional
                                    contact and expertise, providing support to
                                    development and QA teams for the build and
                                    release process to all environments.
                                    <br />
                                    • Provide emergency support for critical
                                    issues, researching possible cause and
                                    providing solutions.
                                    <br />
                                    • Aid DevOps team in the maintenance of
                                    existing automated deployment operations as
                                    well as the advancement of desired state
                                    automated CI-CD Pipelines
                                    <br />
                                    • Documents build and deployment process for
                                    assigned product and/or tools
                                    <br />
                                    • Aid Infrastructure team in the
                                    implementation of new resources used by the
                                    development, test, and production
                                    environments.
                                    <br />
                                    • Maintain awareness of best-in-class tools,
                                    languages, and practices related to
                                    development operations.
                                    <br />
                                    • Provides instructions to Infrastructure,
                                    Development and Test teams to aid in product
                                    development life cycle.
                                    <br />• Participate in discussions regarding
                                    new product development and product
                                    enhancements.
                                </Typography>

                                <Typography
                                    variant="body1"
                                    className="poppins-medium my-3"
                                >
                                    D. Requirements
                                </Typography>
                                <Typography variant="body1">
                                    • Experience working on Linux based
                                    infrastructure
                                    <br />
                                    • Excellent understanding of·Ruby,·Python,
                                    Perl, and Java
                                    <br />
                                    • Configuration and managing databases such
                                    as MySQL, MongoDB
                                    <br />
                                    • Excellent troubleshooting
                                    <br />
                                    • Working knowledge of various tools,
                                    open-source technologies, and cloud services
                                    <br />
                                    • Awareness of critical concepts in DevOps
                                    and Agile principles
                                    <br />
                                    • Great Experience with public cloud
                                    platforms such as AWS, Google Cloud Platform
                                    (GCP), or Microsoft Azure.
                                    <br />
                                    • Must have implementation experience with
                                    TLS/SSL, public private key cryptography,
                                    how certificates work, and mutual
                                    authentication
                                    <br />• Should have deal with IP addressing,
                                    firewalls, open ports, DNS & DHCP
                                </Typography>
                                <Box className="my-4">
                                    <AppPrimaryButton
                                        title="Apply Now"
                                        className="btn-small"
                                        onClick={() => setShowForm(true)}
                                        endIcon={<ArrowRightAltIcon />}
                                    />
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default CareerPage;
