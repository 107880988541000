import { HeadTag } from '../../shared/components';
import ExportHistoryList from './components/ExportHistoryList';

const ExportHistoryPage = (): any => (
    <>
        <HeadTag title="Export Artwork List" />
        <ExportHistoryList /> 
    </>
);

export default ExportHistoryPage;
