
import { Box, CircularProgress, Container, Grid, Typography } from '@material-ui/core';
import { ReactElement, useEffect, useState } from 'react';
// import { Link, useParams } from 'react-router-dom';
// import { Utils } from '../../shared/helpers';
// import { CollectionService } from '../../services';
import { HeadTag } from '../../shared/components';
// import Video from '../../shared/components/Video';
import CommunityTab from './CommunityTab';



const Community = (): ReactElement => {
    const title = 'Community';
    const description = " Community";
    // const { userId } = useParams();
    // const [controls] = useState(true);
    // const [loading, setLoding] = useState(false);
    // const [playing, setPlaying] = useState(true);
    // const [muted] = useState(true);
    // const [loop] = useState(true);
    // const [volume] = useState(0);
    // const [pip] = useState(true);
    // const [artistsUserDetail, setArtistsUserDetail]: any = useState([]);

    // TODO immediate implementation
    // API Calls
    // const loadItems = (): void => {
    //     setLoding(true)
    //     CollectionService.getArtistsUserDetail(userId)
    //         .then((response: any) => {
    //             const { data } = response.data;
    //             setArtistsUserDetail(data.userInfo);
    //             setLoding(false)
    //         })
    //         .catch((error: any) => {
    //             Utils.handleErrorResponse({ error });
    //             setLoding(false)
    //         });
    // };

    // useEffect(() => {
    //     loadItems();
    // }, []);


    return (
        <>
            {/* {loading ? (
                <Box className="empty-content">
                    <CircularProgress />
                </Box>
            ) : ( */}
                <Box className='artist-detail-main-container'>
                    <HeadTag title={title} description={description} />
                    <Box className='artist-detail-inner-container'>
                        <img src="/images/community-banner.svg" alt="banner" />
                    </Box>
                    {/* <Box className='artist-detail-inner-container'>
                        {artistsUserDetail.video ?
                            <>
                                <Video video={artistsUserDetail.video} pip={pip}
                                    playing={playing}
                                    controls={controls}
                                    volume={volume}
                                    muted={muted}
                                    loop={loop}
                                    setPlaying={setPlaying} />
                            </>
                            :
                            <img src={artistsUserDetail.backgroundPicture} />
                        }
                    </Box> */}
                    <Box className='community-detail-section'>
                        <Container>
                            <Box className='community-img-wrapper-box'>
                               <Grid item sm={12} md={4} >
                                <Box className='community-box'>
                                    <img src="/images/community-box-img.svg" alt="img" className='community-img'/>
                                    <Typography className='community-box-text'>This is the latest blog</Typography>
                                </Box>
                               </Grid>
                               <Grid item sm={12} md={4} className="community-box-second">
                                <Box className='community-box '>
                                <img src="/images/community-box-img.svg" alt="img" className='community-img'/>
                                    <Typography className='community-box-text'>This is the latest blog</Typography>
                                </Box>
                               </Grid>
                               <Grid item sm={12} md={4} >
                                <Box className='community-box'>
                                <img src="/images/community-box-img.svg" alt="img" className='community-img'/>
                                    <Typography className='community-box-text'>This is the latest blog</Typography>
                                </Box>
                               </Grid>                               
                            </Box>
                            <Box className='community-social-warpper'>
                                <Box className='community-social-text'>
                                    <Typography>Latest from our social (TUS social feed )</Typography>
                                </Box>
                                <Box className='community-social-box-wrapper'>
                                <Grid item xs={12} sm={6}  md={6} >
                                    <Box className='community-social-box'/>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} >
                                    <Box className='community-social-box'/>
                                </Grid>
                                </Box>
                            </Box>                            
                            <Box className='artist-main-tab'>
                                <CommunityTab/>
                            </Box>
                        </Container>
                    </Box>
                </Box>
            {/* )
            } */}
        </>
    )
}

export default Community
