import { ReactElement, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button } from '@material-ui/core';
import { PDFSkeleton } from '../../../shared/skeletons';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFViewer = (props: any): ReactElement => {
    const { url } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    // UI Actions
    const onDocumentLoadSuccess = ({ numPages }: any): any => {
        setNumberOfPages(numPages);
        setIsLoading(false);
    };

    const actionToNextPage = (): void => {
        if (pageNumber < numberOfPages) {
            const nextPageNumber = pageNumber + 1;
            setPageNumber(nextPageNumber);
        }
    };

    const actionToBackPage = (page: any): void => {
        const nextPageNumber = page - 1;
        setPageNumber(nextPageNumber);
    };

    // UI Elements
    return (
        <>
            {isLoading && <PDFSkeleton />}

            <div>
                <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={pageNumber} width={550} />
                </Document>
                {!isLoading && (
                    <div className="pdf-page-controls">
                        <Button
                            type="button"
                            onClick={() => actionToBackPage(pageNumber)}
                            disabled={pageNumber === 1}
                        >
                            ‹
                        </Button>
                        <span>
                            {pageNumber} of {numberOfPages}
                        </span>
                        <Button
                            type="button"
                            disabled={pageNumber === numberOfPages}
                            onClick={() => actionToNextPage()}
                        >
                            ›
                        </Button>
                    </div>
                )}
            </div>
        </>
    );
};

export default PDFViewer;
