import { useState, ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, CircularProgress } from '@material-ui/core';
import CollectionsIcon from '@material-ui/icons/Collections';
import { Link, useParams, useLocation } from 'react-router-dom';
import { EmptyPlaceholder } from '../../shared/components';
import { Utils } from '../../shared/helpers';
import { CollectionService } from '../../services';
import NFTItem from './NFTItem';
import { AppState } from '../../state/RootReducer';
import MyProfileNFTItem from './MyProfileNftItems';

const Liked = (): ReactElement => {
    const [items, setItems] = useState([]);   
    const { userId } = useParams();
    const [loadingItems, setLoadingItems] = useState(false);
    const [myProfile, setMyProfile] = useState(false);
    const personalData = useSelector((state:AppState)=> state.profileInfo)
    const location = useLocation();

    const loadItems = (): void => {
        setLoadingItems(true)
        CollectionService.getArtistItems(userId, "liked")
            .then((response: any) => {
                const { data } = response?.data
                setItems(data)
                setLoadingItems(false)

            })
            .catch((error: any) => {
                setLoadingItems(false);
                Utils.handleErrorResponse({ error });
            })
    }

    useEffect(() => {
        const {pathname} = location;
        const id = pathname.split('/')[2];
        if(personalData.userId === id) setMyProfile(true);
        loadItems();
    }, [])
    
    const removeItem = (id:any): void => {
        const newitems = items.filter((item)=> item._id !== id);
        // setItems(newitems);
        loadItems();
    }

    /* eslint no-underscore-dangle: 0 */

  return (
    <Grid container md={12}>
    {!loadingItems && items.length === 0 && (
        <EmptyPlaceholder
            icon={<img src="/icons/NoData.png" />}
            message=""
        />
    )}
    {items && items.length !== 0 && items.map((artistItem: any, index) => (
        <>
            <Grid xs={12} sm={6} md={4} lg={3} item className="main-my-grid-wrapper main-my-grid-wrapper-art" key={index}>
                <Grid className='my-grid'>
                    <Link to={`/item/${artistItem._id}`}>
                    {!myProfile && <NFTItem artistItem={artistItem} itemId={artistItem._id}/>}
                    {myProfile  && <MyProfileNFTItem removeItem={removeItem} artistItem={artistItem} itemId={artistItem._id}/>}
                    </Link>
                </Grid>
            </Grid>
        </>
    ))}
    {loadingItems && (
        <Box className="empty-content">
            <CircularProgress />
        </Box>
    )}
    </Grid>
  )
}

export default Liked;