import { ReactElement } from 'react';
import { HeadTag } from '../../shared/components';
import { CryptoAccountList } from './components';

const CryptoAccountsPage = (): ReactElement => (
    <>
        <HeadTag title="Artwork transfer address" />
        <CryptoAccountList />
    </>
);

export default CryptoAccountsPage;
