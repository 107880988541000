import { useState, useEffect, useCallback, ReactElement } from 'react';
import {
    // Accordion,
    // AccordionSummary,
    Typography,
    // AccordionDetails,
    Grid,
    Box,
    // Divider,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    TextField,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import SearchIcon from '@material-ui/icons/Search';
import debounce from 'lodash.debounce';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoneIcon from '@material-ui/icons/Done';
import InfiniteScroll from 'react-infinite-scroller';
import { Utils } from '../../../shared/helpers';
import { MarketPlaceActions } from '../../../state/marketplace/Action';
import { AppState } from '../../../state/RootReducer';
import { CollectionService, DefaultPagination } from '../../../services';
import { MarketPlaceFilterState } from '../../../state/marketplace/Reducer';

export interface Celebrity {
    name: string;
    profilePicture?: any;
    celebrity: boolean;
    id: string;
}
type CelebrityListProps = {
    selectedCelebrities: Celebrity[];
    setSelectedCelebrity: any;
};

interface FilterProps {
    marketPlaceFilter: MarketPlaceFilterState | any;
    setSearch: any;
}

const CelebrityList = (props: any): ReactElement => {
    const { marketPlaceFilter, setSearch }: FilterProps = props;
    const [celebrityList, setCelebrityList] = useState<Celebrity[]>([]);
    const [searchValue, setSearchValue] = useState(marketPlaceFilter.query);
    const [pagination, setPagination] = useState({ ...DefaultPagination(5) });
    const [loader, setLoader] = useState(false);
    const [hasMoreCelebrity, setHasMoreCelebrity] = useState(true);

    // API Calls
    // const loadCelebrityList = (searchTerm: string): void => {
    //     if (pagination.lastOffset !== pagination.offset) {
    //         pagination.lastOffset = pagination.offset;
    //         setLoader(true);
    //         const promise = CollectionService.getCelebrityList(
    //             pagination.limit,
    //             pagination.offset,
    //             searchTerm
    //         );
    //         promise
    //             .then((response: any) => {
    //                 let { celebrities } = response.data.data;
    //                 const paginationResponse = response.data.data.pagination;
    //                 if (pagination.offset === 1) {
    //                     setCelebrityList(celebrities);
    //                 } else {
    //                     celebrities = celebrityList.concat(celebrities);
    //                     setCelebrityList(celebrities);
    //                 }
    //                 setHasMoreCelebrity(
    //                     Utils.hasMoreRecords(paginationResponse)
    //                 );
    //                 setPagination({
    //                     ...paginationResponse,
    //                     lastOffset: pagination.lastOffset,
    //                     offset: pagination.offset + 1,
    //                 });
    //                 setLoader(false);
    //             })
    //             .catch((error: any) => {
    //                 setLoader(false);
    //                 Utils.handleErrorResponse({
    //                     error,
    //                 });
    //             });
    //     }
    // };

    // Hooks
    // useEffect(() => {
    //     loadCelebrityList('');
    // }, []);

    // Instance Methods
    // const getCelebrityIndex = (celebrity: Celebrity): number => {
    //     const matchedIndex = selectedCelebrities.findIndex(
    //         (c) => c.id === celebrity.id
    //     );
    //     return matchedIndex;
    // };

    // const addOrRemoveCelebrity = (celebrity: Celebrity): void => {
    //     const index = getCelebrityIndex(celebrity);
    //     if (index > -1) {
    //         const celebrities = [...selectedCelebrities];
    //         celebrities.splice(index, 1);
    //         props.setSelectedCelebrity(celebrities);
    //     } else {
    //         props.setSelectedCelebrity([...selectedCelebrities, celebrity]);
    //     }
    // };

    // const resetPagination = (searchTerm = ''): void => {
    //     pagination.offset = 1;
    //     pagination.lastOffset = 0;
    //     loadCelebrityList(searchTerm);
    // };

    const debouncedCall = useCallback(
        debounce((search: any) => {

            setSearch(search)
            // resetPagination(search);
        }, 500),
        []
    );

    // UI Actions
    // const loadMoreCelebrity = (): void => {
    //     loadCelebrityList(searchValue);
    // };

    const onCelebritySearch = (e: any): void => {
        const searchTerm = e.target.value;
        setSearchValue(searchTerm);
        debouncedCall(searchTerm);
    };

    // const actionToggleCelebrity = (celebrity: Celebrity): void => {
    //     addOrRemoveCelebrity(celebrity);
    // };

    const actionClearSearch = (): void => {
        setSearchValue('');
        setSearch('');
        // resetPagination();
    };

    useEffect(() => {
        setSearchValue(marketPlaceFilter.query)
    }, [marketPlaceFilter.query])

    // UI Elements
    return (
        <Box className="celebrity-list-container">
            {/* <Accordion defaultExpanded> */}
            <Box>
                {/* <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className="filter-margin">
                        Celebrities
                    </Typography>
                </AccordionSummary> */}
                {/* <AccordionDetails> */}
                    <Box className="filter-margin filter-content celebrity-search">
                        {/* <Divider /> */}
                        <Box className='text-field-search-box'>
                        <TextField
                            fullWidth
                            className="search-box celebrity-search-box"
                            variant="outlined"
                            size="small"
                            placeholder="Search"
                            value={searchValue}
                            onChange={(e) => onCelebritySearch(e)}
                            InputProps={{
                                // startAdornment: (
                                //     <SearchIcon
                                //         color="primary"
                                //         className="mr-2"
                                //     />
                                // ),
                                endAdornment: (
                                    <IconButton
                                        type="button"
                                        onClick={actionClearSearch}
                                    >
                                        <ClearIcon
                                            className={
                                                searchValue
                                                    ? 'primary'
                                                    : 'white'
                                            }
                                        />
                                    </IconButton>
                                ),
                            }}
                        />
                        <Box>
                            <img src="/images/search-icon.svg" alt="icon" />
                        </Box>
                        </Box>

                        <List dense className="celebrity-list">
                            {/* Selected Celebrities */}
                            {/* {selectedCelebrities.map((celebrity) => (
                                <ListItem
                                    key={celebrity.id}
                                    onClick={() => {
                                        actionToggleCelebrity(celebrity);
                                    }}
                                    button
                                >
                                    <ListItemAvatar>
                                        <Avatar src="">
                                            <DoneIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={celebrity.name} />
                                </ListItem>
                            ))} */}
                            {!loader && searchValue.length !== 0 && (
                                 <Grid container >  
                                    <Box p={2}>
                                        <Typography>
                                        Searched "{searchValue}"
                                        </Typography>
                                    </Box>
                                </Grid>
                            )}
                           
                            {/* Search Result */}
                            {/* <InfiniteScroll
                                threshold={0}
                                pageStart={0}
                                loadMore={loadMoreCelebrity}
                                hasMore={hasMoreCelebrity}
                                useWindow={false}
                            >
                                {celebrityList.map((celebrity) => (
                                    <Box key={celebrity.id}>
                                        {getCelebrityIndex(celebrity) ===
                                            -1 && (
                                            <ListItem
                                                onClick={() => {
                                                    actionToggleCelebrity(
                                                        celebrity
                                                    );
                                                }}
                                                button
                                            >
                                                <ListItemAvatar>
                                                    <Avatar
                                                        alt={celebrity.name}
                                                        src={
                                                            celebrity.profilePicture
                                                        }
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={celebrity.name}
                                                />
                                            </ListItem>
                                        )}
                                    </Box>
                                ))}
                            </InfiniteScroll>
                            {!loader && celebrityList.length === 0 && (
                                <Grid container >  
                                    <Box p={2}>
                                        <Typography>
                                            No celebrities found
                                        </Typography>
                                    </Box>
                                </Grid>
                            )} */}
                            {loader && (
                                <Grid container justifyContent="center">
                                    <Box p={2}>
                                        <CircularProgress className='circularProgress-icon'/>
                                    </Box>
                                </Grid>
                            )}
                        </List>
                    </Box>
                {/* </AccordionDetails> */}
                </Box>
            {/* </Accordion> */}
        </Box>
    );
};

const mapStateToProps = (state: AppState): any => ({
    marketPlaceFilter: state.marketPlaceFilter,
});

const mapDispatchToProps = (dispatch: any): any => ({
    setSearch: (data: any) => dispatch(MarketPlaceActions.setSearch(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CelebrityList);
