import { recoverPersonalSignature } from 'eth-sig-util';
import { bufferToHex } from 'ethereumjs-util';
import Web3 from 'web3';
import { CryptoBuySummary, CryptoOfferSummary } from '../models/NFTModal';


const erc1155ABI = require('../abis/ERC1155.json');
const erc1155UpABI = require('../abis/ERC1155Up.json')
const commonABI = require('../abis/Common.json');

const NFTContractAddress = process.env.REACT_APP_NFT_CONTRACT;
const PolygonNFTContractAddress = process.env.REACT_APP_POLYGON_NFT_CONTRACT;
const CommonContractAddress = process.env.REACT_APP_COMMON_CONTRACT;
const AlchemyKey = process.env.REACT_APP_ALCHEMYKEY;

const BlockChainConfig: any =
    process.env.REACT_APP_DEV_MODE === 'true'
        ? {
              ethereum: {
                allowedNetwork: 5,
                nftContractAddress: NFTContractAddress,
                compareChainId: true,
              },
              polygon: {
                  allowedNetwork: 80001,
                  nftContractAddress: PolygonNFTContractAddress,
                  compareChainId: true,
              },
              Sepolia:{
                allowedNetwork: 11155111,
                nftContractAddress: NFTContractAddress,
                compareChainId: true,
              },
              goerli: {
                allowedNetwork: 5,
                nftContractAddress: NFTContractAddress,
                compareChainId: true,
              }

          }
        : {
              ethereum: {
                  allowedNetwork: 'main',
                  nftContractAddress: NFTContractAddress,
                  compareChainId: false,
              },
              polygon: {
                  allowedNetwork: 137,
                  nftContractAddress: PolygonNFTContractAddress,
                  compareChainId: true,
              },
          };





// MetaMask Network Setup For Import, Export and Buy
const setUpMetaMask = (blockchain = 'ethereum'): Promise<any> => {
    let web3: Web3 | undefined;

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
        // Check if MetaMask is installed
        if (!(window as any).ethereum) {
            reject(new Error('Please install MetaMask first.'));
        }
        if (!web3) {
            try {
                // Request account access if needed
                await (window as any).ethereum.enable();
                // We don't know window.web3 version, so we use our own instance of Web3
                // with the injected provider given by MetaMask
                web3 = new Web3((window as any).ethereum);

                const ethereumParam = process.env.REACT_APP_DEV_MODE === 'true'? {
                    chainId: web3.utils.toHex('5'), // Goerli chain ID
                    chainName: 'Goerli Test Network',
                    nativeCurrency: {
                      name: 'Goerli Ether',
                      symbol: 'ETH',
                      decimals: 18,
                    },
                    rpcUrls: ['https://rpc.ankr.com/eth_goerli'],
                  } : {
                    chainId: web3.utils.toHex('1'), // Ethereum mainnet chain ID
                    chainName: 'Ethereum Mainnet',
                    nativeCurrency: {
                      name: 'Ether',
                      symbol: 'ETH',
                      decimals: 18,
                    },
                    rpcUrls: ['https://rpc.ankr.com/eth	'],
                  }
                  ;

                (window as any)?.ethereum.request({ method: 'eth_requestAccounts' })
                .then((accounts:any) => {
                    const goerliNetworkId:any = '5'; // Goerli network ID

                    web3.eth.net.getId()
                     .then(networkId => {
                        if (networkId !== goerliNetworkId) {
                            const provider = (window as any)?.ethereum;
                            provider.request({
                              method: 'wallet_addEthereumChain',
                              params: [ethereumParam],
                            });
                          }
                     })




                    // Switch to the Goerli network
                })

             (window as any)?.ethereum.on('disconnect', (err:any) => console.log("err" ,err));


            } catch (error) {
                reject(new Error('You need to allow MetaMask.'));
            }
        } else {
            reject(new Error('Unable to load MetaMask.'));
        }

        try {
            console.log("setupmetamaks... in try")
            const { allowedNetwork, compareChainId } =
            BlockChainConfig[blockchain];
            console.log("setupmetamaks... BlockChainConfig[blockchain]: ", BlockChainConfig[blockchain])

            const currentNetwork = compareChainId
                ? await web3!.eth.getChainId()
                : await web3!.eth.net.getNetworkType();
            
                console.log("currentNetwork: ", currentNetwork)

            if (currentNetwork === allowedNetwork) {
                const address = await web3!.eth.getCoinbase();
                if (address) {
                    const getEthBalance = await web3!.eth.getBalance(address);
                    const balance = await web3!.utils.fromWei(getEthBalance);
                    resolve({
                        address: address.toLowerCase(),
                        balance,
                        web3,
                    });
                } else {
                    reject(new Error('Unable to load MetaMask.'));
                }
            } else if (allowedNetwork === 'rinkeby') {
                reject(
                    new Error(
                        'Please switch ethereum network into Rinkeby Network in MetaMask'
                    )
                );
            } else if (allowedNetwork === 80001) {
                reject(
                    new Error(
                        'Please switch ethereum network into Polygon Network in MetaMask'
                    )
                );
            } else if (allowedNetwork === 137) {
                reject(
                    new Error(
                        'Please switch ethereum network into Polygon Network in MetaMask'
                    )
                );
            } else {
                reject(
                    new Error(
                        'Please switch ethereum network into Main Network in MetaMask'
                    )
                );
            }
        } catch {
            reject(new Error('Unable to load MetaMask.'));
        }
    });
};

const canUseNetworkToLogin = (
    chainId: number,
    networkName: string
): boolean => {
    const networks = Object.keys(BlockChainConfig);
    return networks.some((network: any) => {
        const config = BlockChainConfig[network];
        if (config.compareChainId && chainId === config.allowedNetwork) {
            return true;
        }
        if (!config.compareChainId && networkName === config.allowedNetwork) {
            return true;
        }
        return false;
    });
};

// MetaMask Network Setup For Import, Export and Buy
const setUpMetaMaskForLogin = (): Promise<any> => {
    let web3: Web3 | undefined;
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
        // Check if MetaMask is installed
        if (!(window as any).ethereum) {
            reject(new Error('Please install MetaMask first.'));
        }
     

        if (!web3) {
            try {
                // Request account access if needed
                await (window as any).ethereum.enable();
                // We don't know window.web3 version, so we use our own instance of Web3
                // with the injected provider given by MetaMask

                
              
            } catch (error) {
                reject(new Error('You need to allow MetaMask.'));
            }
        } else {
            reject(new Error('Unable to load MetaMask.'));
        }

        try {
            web3 = new Web3((window as any).ethereum);
            const ethereumParam = process.env.REACT_APP_DEV_MODE === 'true'? {
                chainId: web3.utils.toHex('5'), // Goerli chain ID
                chainName: 'Goerli Test Network',
                nativeCurrency: {
                  name: 'Goerli Ether',
                  symbol: 'ETH',
                  decimals: 18,
                },
                rpcUrls: ['https://rpc.ankr.com/eth_goerli'],
              } : {
                chainId: web3.utils.toHex('1'), // Ethereum mainnet chain ID
                chainName: 'Ethereum Mainnet',
                nativeCurrency: {
                  name: 'Ether',
                  symbol: 'ETH',
                  decimals: 18,
                },
                rpcUrls: ['https://mainnet.infura.io/v3/'],
              }
              ;
            (window as any)?.ethereum.request({ method: 'eth_requestAccounts' })
            .then((accounts:any) => {
                const goerliNetworkId:any = '5'; // Goerli network ID

                web3.eth.net.getId()
                 .then(networkId => {
                    if (networkId !== goerliNetworkId &&  process.env.REACT_APP_DEV_MODE === 'true' ) {
                        const provider = (window as any)?.ethereum;
                        provider.request({
                          method: 'wallet_addEthereumChain',
                          params: [ethereumParam],
                        }).then(async(res:any)=>{
                            const chainId = await web3!.eth.getChainId();
                            const network = await web3!.eth.net.getNetworkType();
                            if (canUseNetworkToLogin(chainId, network)) {
                                const address = await web3!.eth.getCoinbase();
                                if (address) {
                                    const getEthBalance = await web3!.eth.getBalance(address);
                                    const balance = await web3!.utils.fromWei(getEthBalance);
                                    resolve({
                                        address: address.toLowerCase(),
                                        balance,
                                        web3,
                                    });
                                } else {
                                    reject(new Error('Unable to load MetaMask.'));
                                }
                            } else {
                                const message =
                                    process.env.REACT_APP_DEV_MODE === 'true'
                                        ? 'Please switch to Goeril Network '
                                        : 'Please switch to Ethereum Main Network';
                                reject(new Error(message));
                            }
                        })
                        .catch((err:any)=>{
                            console.log(err)
                        })
                        ;
                    }else{
                        const provider = (window as any)?.ethereum;
                        provider.request({
                          method: 'wallet_switchEthereumChain',
                          params: [{ chainId: Web3.utils.toHex('1') }],
                        }).then(async(res:any)=>{
                            const chainId = await web3!.eth.getChainId();
                            const network = await web3!.eth.net.getNetworkType();
                            if (canUseNetworkToLogin(chainId, network)) {
                                const address = await web3!.eth.getCoinbase();
                                if (address) {
                                    const getEthBalance = await web3!.eth.getBalance(address);
                                    const balance = await web3!.utils.fromWei(getEthBalance);
                                    resolve({
                                        address: address.toLowerCase(),
                                        balance,
                                        web3,
                                    });
                                } else {
                                    reject(new Error('Unable to load MetaMask.'));
                                }
                            } else {
                                const message =
                                    process.env.REACT_APP_DEV_MODE === 'true'
                                        ? 'Please switch to Goeril Network '
                                        : 'Please switch to Ethereum Main Network';
                                reject(new Error(message));
                            }
                        })
                        .catch((err:any)=>{
                            console.log(err)
                        })
                    }
                 })
                 

                // Switch to the Goerli network
            })
      
        } catch {
            reject(new Error('Unable to load MetaMask.'));
        }
    });
};

// Get NFT token balance
const getNFTBalance = (
    tokenId: string,
    address: string,
    blockchain = 'ethereum'
): Promise<any> =>
    new Promise((resolve, reject) => {
        setUpMetaMask(blockchain)
            .then((account) => {
                // Check given address and metamask address are same
                const metaMaskAddress = account.address;
                const formatedAddress = address.toLowerCase();
                if (metaMaskAddress === formatedAddress) {
                    const { web3 } = account;
                    const { nftContractAddress } = BlockChainConfig[blockchain];
                    const contract = new web3.eth.Contract(
                        // erc1155ABI,
                        erc1155UpABI,
                        nftContractAddress
                    );
                    contract.methods
                        .balanceOf(formatedAddress, tokenId)
                        .call()
                        .then((response: any) => {
                            resolve({ ...account, nftBalance: response });
                        })
                        .catch((error: any) => {
                            console.log("error", error)
                            reject(new Error('Unable fetch NFT balance'));
                        });
                } else {
                    reject(
                        new Error(
                            'Entered address and Metamask account address are not matched'
                        )
                    );
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

// Import NFT from metamask account
const importNFT = (
    tokenId: string,
    quantity: string,
    fromAddress: string,
    toAddress: string,
    blockchain = 'ethereum'
): Promise<any> =>
    new Promise((resolve, reject) => {
        setUpMetaMask(blockchain)
            .then((account) => {
                // Check given address and metamask address are same
                const metaMaskAddress = account.address;
                const fromAddressLowerCase = fromAddress.toLowerCase();
                const toAddressLowerCase = toAddress.toLowerCase();
                if (metaMaskAddress === fromAddressLowerCase) {
                    const { web3 } = account;
                    const { nftContractAddress } = BlockChainConfig[blockchain];
                    const contract = new web3.eth.Contract(
                        // erc1155ABI,
                        erc1155UpABI,
                        nftContractAddress
                    );
                    contract.methods
                        .safeTransferFrom(
                            fromAddressLowerCase,
                            toAddressLowerCase,
                            tokenId,
                            quantity,
                            '0x00'
                        )
                        .send({
                            from: fromAddressLowerCase,
                            gasPrice: '20000000000',
                        })
                        .on('transactionHash', (receipt: any) => {
                            resolve(receipt);
                        })
                        .on('error', (error: any) => {
                            // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
                            reject(error);
                        });
                } else {
                    reject(
                        new Error(
                            'Entered address and Metamask account address are not matched'
                        )
                    );
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

// Post a bid for NFT auction using crypto using metamask account
const bidCryptoForAuction = (
    cryptoBuySummary: CryptoBuySummary,
    payableAmount: string
): Promise<any> =>
    new Promise((resolve, reject) => {
        setUpMetaMask()
            .then(async (response) => {
                const { web3 } = response;
                const address = response.address.toLowerCase();
                const publicAddress = address.toLowerCase();
                const nonce = Math.floor(Math.random() * 10000);
                const msg = `I am signing my one-time nonce: ${nonce}`;
                const signature = await web3!.eth.personal.sign(
                    msg,
                    publicAddress,
                    '' // MetaMask will ignore the password argument here
                );
                const msgBufferHex = bufferToHex(Buffer.from(msg, 'utf8'));
                const verifiedaddress = recoverPersonalSignature({
                    data: msgBufferHex,
                    sig: signature,
                });
                const verifiedAddress = verifiedaddress.toLocaleLowerCase();
                const publicaddress = publicAddress.toLocaleLowerCase();

                if (verifiedAddress === publicaddress) {
                    const contract = new web3!.eth.Contract(
                        commonABI,
                        CommonContractAddress
                    );
                    const gasPrice = await web3!.eth.getGasPrice();
                    const value = web3!.utils.toWei(payableAmount);

                    const gasFee = await contract.methods
                        .depositEther(
                            cryptoBuySummary.itemId,
                            cryptoBuySummary.saleId,
                            cryptoBuySummary.auctionId,
                            cryptoBuySummary.userId,
                            `${cryptoBuySummary.randomHex}`
                        )
                        .estimateGas({
                            from: address,
                            to: CommonContractAddress,
                            value,
                        });

                    await contract.methods
                        .depositEther(
                            cryptoBuySummary.itemId,
                            cryptoBuySummary.saleId,
                            cryptoBuySummary.auctionId,
                            cryptoBuySummary.userId,
                            `${cryptoBuySummary.randomHex}`
                        )
                        .send({
                            from: address,
                            gas: gasFee,
                            gasPrice,
                            nonce,
                            value,
                        })
                        .on('transactionHash', (hash: any) => {
                            resolve({ hash, verifiedAddress });
                        });
                } else {
                    const error = 'Signature verification failed';
                    reject(error);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

// Buy NFT using crypto using metamask account
const buyNFT = (cryptoBuySummary: CryptoBuySummary): Promise<any> =>
    new Promise((resolve, reject) => {
        setUpMetaMask()
            .then(async (response) => {
                const { web3 } = response;
                const address = response.address.toLowerCase();
                const publicAddress = address.toLowerCase();
                const nonce = Math.floor(Math.random() * 10000);
                const msg = `I am signing my one-time nonce: ${nonce}`;
                const signature = await web3!.eth.personal.sign(
                    msg,
                    publicAddress,
                    '' // MetaMask will ignore the password argument here
                );
                const msgBufferHex = bufferToHex(Buffer.from(msg, 'utf8'));
                const verifiedaddress = recoverPersonalSignature({
                    data: msgBufferHex,
                    sig: signature,
                });
                const verifiedAddress = verifiedaddress.toLocaleLowerCase();
                const publicaddress = publicAddress.toLocaleLowerCase();

                if (verifiedAddress === publicaddress) {
                    const contract = new web3!.eth.Contract(
                        commonABI,
                        CommonContractAddress
                    );
                    const gasPrice = await web3!.eth.getGasPrice();
                    const sellerAmount: any =
                        cryptoBuySummary.sellerCost.split(' ');
                    const totalAmount: any =
                        cryptoBuySummary.totalCost.split(' ');

                    const amount = web3!.utils.toWei(
                        sellerAmount[0].toString()
                    );
                    const value = web3!.utils.toWei(totalAmount[0].toString());

                    const gasFee = await contract.methods
                        .buyCrypto(
                            // cryptoBuySummary.itemId,
                            cryptoBuySummary.saleId,
                            cryptoBuySummary.sellerWithdrawalAddress,
                            amount,
                            `${cryptoBuySummary.randomHex}`
                        )
                        .estimateGas({
                            from: address,
                            to: cryptoBuySummary.sellerWithdrawalAddress,
                            value,
                        });

                    await contract.methods
                        .buyCrypto(
                            // cryptoBuySummary.itemId,
                            cryptoBuySummary.saleId,
                            cryptoBuySummary.sellerWithdrawalAddress,
                            amount,
                            `${cryptoBuySummary.randomHex}`
                        )
                        .send({
                            from: address,
                            gas: gasFee,
                            gasPrice,
                            nonce,
                            value,
                        })
                        .on('transactionHash', (hash: any) => {
                            resolve({ hash, verifiedAddress });
                        });
                } else {
                    const error = 'Signature verification failed';
                    reject(error);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

// Post Offer NFT using crypto using metamask account
const postNFTOffer = (cryptoOfferSummary: CryptoOfferSummary): Promise<any> =>
    new Promise((resolve, reject) => {
        setUpMetaMask()
            .then(async (response) => {
                const { web3 } = response;
                const address = response.address.toLowerCase();
                const publicAddress = address.toLowerCase();
                const nonce = Math.floor(Math.random() * 10000);
                const msg = `I am signing my one-time nonce: ${nonce}`;
                const signature = await web3!.eth.personal.sign(
                    msg,
                    publicAddress,
                    '' // MetaMask will ignore the password argument here
                );
                const msgBufferHex = bufferToHex(Buffer.from(msg, 'utf8'));
                const verifiedaddress = recoverPersonalSignature({
                    data: msgBufferHex,
                    sig: signature,
                });
                const verifiedAddress = verifiedaddress.toLocaleLowerCase();
                const publicaddress = publicAddress.toLocaleLowerCase();

                if (verifiedAddress === publicaddress) {
                    const contract = new web3!.eth.Contract(
                        commonABI,
                        CommonContractAddress
                    );

                    const gasPrice = await web3!.eth.getGasPrice();
                    const value = web3!.utils.toWei(
                        cryptoOfferSummary.cryptoAmount
                    );

                    const gasFee = await contract.methods
                        .hasOffer(
                            cryptoOfferSummary.itemId,
                            cryptoOfferSummary.quantity,
                            cryptoOfferSummary.userId,
                            cryptoOfferSummary.randomHex
                        )
                        .estimateGas({
                            from: address,
                            to: CommonContractAddress,
                            value,
                        });

                    await contract.methods
                        .hasOffer(
                            cryptoOfferSummary.itemId,
                            cryptoOfferSummary.quantity,
                            cryptoOfferSummary.userId,
                            cryptoOfferSummary.randomHex
                        )
                        .send({
                            from: address,
                            gas: gasFee,
                            gasPrice,
                            nonce,
                            value,
                        })
                        .on('transactionHash', (hash: any) => {
                            resolve({ hash, verifiedAddress });
                        });
                } else {
                    const error = 'Signature verification failed';
                    reject(error);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

// Verify signature
const verifySignature = (publicAddress: string, nonce = ''): Promise<any> => {
    let web3: Web3 | undefined;
    return new Promise(async (resolve, reject) => {
        try {
            // Check if MetaMask is installed
            if (!(window as any).ethereum) {
                reject(new Error('Please install MetaMask first.'));
            }
            if (!web3) {
                try {
                    // Request account access if needed
                    await (window as any).ethereum.enable();
                    // We don't know window.web3 version, so we use our own instance of Web3
                    // with the injected provider given by MetaMask
                    web3 = new Web3((window as any).ethereum);
                } catch (error) {
                    reject(new Error('You need to allow MetaMask.'));
                }
            }

            // Local Signing verification
            if (nonce === '') {
                const newNonce = Math.floor(Math.random() * 10000);
                const msg = `I am signing my one-time nonce: ${newNonce}`;
                const signature = await web3!.eth.personal.sign(
                    msg,
                    publicAddress,
                    '' // MetaMask will ignore the password argument here
                );
                const msgBufferHex = bufferToHex(Buffer.from(msg, 'utf8'));
                const verifiedaddress = recoverPersonalSignature({
                    data: msgBufferHex,
                    sig: signature,
                });
                const verifiedAddress = verifiedaddress.toLocaleLowerCase();
                const publicAddressLowerCase =
                    publicAddress.toLocaleLowerCase();
                if (verifiedAddress === publicAddressLowerCase) {
                    resolve({ publicAddress, signature });
                } else {
                    reject(new Error('Signature verification failed'));
                }
            } else {
                const publicAddressLowerCase =
                    publicAddress.toLocaleLowerCase();
                // Remove signing verification
                const signature = await web3!.eth.personal.sign(
                    nonce,
                    publicAddressLowerCase,
                    '' // MetaMask will ignore the password argument here
                );
                resolve({ publicAddress, signature });
            }
        } catch (error: any) {
            if (error && error.code && error.code === 4001) {
                reject(Error('Signature verification cancelled'));
            } else {
                reject(error);
            }
        }
    });
};

const gasPriceWeb3 = async():Promise<any> => {
    const web3 = new Web3(process.env.REACT_APP_ALCHEMYKEY);


    // Get the gas price
    const gasPrice = await web3.eth.getGasPrice();

    // Convert gas price to a readable format
    // const gasPriceInEther = web3.utils.fromWei(gasPrice, 'ether');

       const gasLimit = 21000; // Replace with the actual gas limit for your transfer

      // Calculate the transfer fee
      const transferFee = web3.utils.toBN(gasPrice).mul(web3.utils.toBN(gasLimit));

      const gasPriceInEther = web3.utils.fromWei(transferFee.toString(), 'ether');

      console.log(gasPriceInEther )

    return gasPriceInEther;
}


const getMintFee = async():Promise<any> => {
     // Create a Web3 instance

     const web3 = new Web3(AlchemyKey);


     // Get the gas price
     const gasPrice = await web3.eth.getGasPrice();

     // Estimate the gas required for the minting transaction
     const mintData = {
       // Define the parameters for your minting transaction
       // ...
     };

     const gasLimit = await web3.eth.estimateGas(mintData);

     const transferFee = web3.utils.toBN(gasPrice).mul(web3.utils.toBN(21000));

     const gasPriceInEther = web3.utils.fromWei(transferFee.toString(), 'ether');
     // Calculate the mint fee
     const mintFee = web3.utils.toBN(gasPrice).mul(web3.utils.toBN(gasLimit));

     const mintFeeInether = web3.utils.fromWei(mintFee.toString(), 'ether');

     return {
        transferFee:gasPriceInEther,
        mintFee:mintFeeInether
     }
}


export const BlockChain = {
    setUpMetaMask,
    setUpMetaMaskForLogin,
    getNFTBalance,
    importNFT,
    buyNFT,
    postNFTOffer,
    verifySignature,
    bidCryptoForAuction,
    gasPriceWeb3,
    getMintFee
};
