import { ReactElement, useState } from 'react';
import {
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
} from '@stripe/react-stripe-js';
import { Grid, Box, Typography, FormHelperText } from '@material-ui/core';

const elementStyles = {
    base: {
        padding: '20px',
        color: '#151515',
        fontWeight: 500,
        fontFamily: 'Source Code Pro, Consolas, Menlo, monospace',
        fontSize: '14px',
        '::placeholder': {
            color: '#9F9F9F',
        },
        ':-webkit-autofill': {
            color: '#e39f48',
        },
    },
    invalid: {
        color: '#E25950',
        '::placeholder': {
            color: '#FFCCA5',
        },
    },
};

const StripeCardElements = (props: any): ReactElement => {
    const { onFormChange } = props;
    // const [formError, setFormError] = useState('');
    const [cardStatus, setCardStatus]: any = useState({
        cardNumber: false,
        cardCvc: false,
        cardExpiry: false,
    });
    const [errors, setErrors]: any = useState({
        cardNumber: '',
        cardCvc: '',
        cardExpiry: '',
    });

    const handleStripeCardChange = (event: any): void => {
        if (event) {
            // Get element type
            const { elementType } = event;

            // Get element error
            const error = { ...errors };
            error[elementType] = event.error ? event.error.message : '';
            setErrors(error);

            // Get element status
            cardStatus[elementType] = event.complete;
            setCardStatus({ ...cardStatus });
            onFormChange({
                formError: error,
                completed:
                    cardStatus.cardNumber &&
                    cardStatus.cardCvc &&
                    cardStatus.cardExpiry,
            });
        }
    };

    return (
        <Grid container spacing={4}>
            <Grid xs={12} item>
                <Typography variant="body1" className="mb-2">
                    Card Number
                </Typography>

                <Box className="stripe-element">
                    <CardNumberElement
                        options={{
                            showIcon: true,
                            style: elementStyles,
                        }}
                        onChange={handleStripeCardChange}
                    />
                </Box>

                <FormHelperText className="Mui-error">
                    {errors.cardNumber}
                </FormHelperText>
            </Grid>
            <Grid item xs={6}>
                <Box>
                    <Typography variant="body1" className="mb-2">
                        Expiry
                    </Typography>
                </Box>
                <Box className="stripe-element expiry">
                    <CardExpiryElement
                        options={{
                            style: elementStyles,
                        }}
                        onChange={handleStripeCardChange}
                    />
                </Box>
                <FormHelperText className="Mui-error">
                    {errors.cardExpiry}
                </FormHelperText>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="body1" className="mb-2">
                    CVV
                </Typography>
                <Box className="stripe-element cvv">
                    <CardCvcElement
                        options={{
                            style: elementStyles,
                        }}
                        onChange={handleStripeCardChange}
                    />
                </Box>
                <FormHelperText className="Mui-error">
                    {errors.cardCvc}
                </FormHelperText>
            </Grid>
        </Grid>
    );
};

export default StripeCardElements;
