/* eslint no-underscore-dangle: 0 */
import { ReactElement } from 'react';
import { Typography, Box } from '@material-ui/core';
import { connect } from 'react-redux';
import { AppState } from '../../../state/RootReducer'
import { MarketPlaceActions } from '../../../state/marketplace/Action';
import { MarketPlaceFilterState } from '../../../state/marketplace/Reducer';
import { MarketFilterState } from '../../../state/filter/Reducer';

interface FilterProps {
    marketPlaceFilter: MarketPlaceFilterState | any;
    marketFilterList: MarketFilterState| any;
    setPrice: any;
    setFilter: any;
}

interface RegionList {
    name: string;
    _id: string;
}

const RegionFilter = (props: any): ReactElement => {
    const url = window.location.href.split('/')[4];
    const {
        marketPlaceFilter,
        marketFilterList,
        setFilter,
    }: FilterProps = props;
   
    const regionFilterChangeHandler = (event: any): void => {
        const region = marketFilterList?.regionList?.filter((item: string) => item === event.target.value) 

        setFilter({
            ...marketPlaceFilter,
            [event.target.name]: event.target.value,
            'regionName': region
        });
    } 
  
    return (
        <Box className='region-wrapper region-wrapper-py'>
            <Typography className="filter-margin">
                {(url === 'artwork' || url === undefined )? "Artist Region" : "Region"}
            </Typography>
            <Box className='region-form'>
            <Box className="region-form-inner">
                <select 
                    name="region" 
                    className='region-form-control'
                    onChange={regionFilterChangeHandler}
                    value={marketPlaceFilter?.region}
                >
                    <option value="" selected>Choose...</option>
                    {marketFilterList?.regionList && marketFilterList?.regionList.length > 0 && marketFilterList?.regionList.map((region: string, key: any) => <option value={region} key={key}>{region}</option> )}
                    
                </select>
                </Box>
            </Box>
        </Box>
    )
}

const mapStateToProps = (state: AppState): any => ({
    marketPlaceFilter: state?.marketPlaceFilter||[],
    marketFilterList: state?.MarketFilterList||[],
});

const mapDispatchToProps = (dispatch: any): any => ({
    setFilter: (data: any) => dispatch(MarketPlaceActions.setFilter(data)),
});


export default connect(mapStateToProps, mapDispatchToProps)(RegionFilter);