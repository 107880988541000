import { Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { ReactElement, useEffect, useState } from 'react';
import { goesLiveIn } from "../helpers/Utils";

const LaunchLive = (props: any): ReactElement => {
    const { title, date, isexpiry = 'false' } = props
    const [livetime, setLiveTime] = useState(null)

    useEffect((): any => {
        setInterval((): any => {
            setLiveTime(goesLiveIn(date))
        }, 1000)
    }, [])
    
    return (<Box
        width='100%'
        display='flex'
        flexDirection='column'
    >
        <span style={{ fontSize: '10px' }}>{title}</span>
        {!livetime ? <Skeleton animation={false} variant="rect" width={310} height={50} /> : <Box
            display='flex'
            justifyContent='space-evenly'
            alignItems='center'
            className='LaunchLiveWrapper'>
            <Box display='flex'
                flexDirection='column'
                alignItems='center'
            >
                <Typography variant='h5' >{livetime?.day}</Typography>
                <Typography style={{ fontSize: '10px' }}>DAYS</Typography>
            </Box>
            <Box mx={isexpiry === 'true' ? 1 : 4}>
                <Typography color='textSecondary' variant="h5"><b>:</b></Typography>
            </Box>
            <Box display='flex'
                flexDirection='column'
                alignItems='center'
            >
                <Typography variant='h5' >{livetime?.hour}</Typography>
                <Typography style={{ fontSize: '10px' }}>HOURS</Typography>
            </Box>
            <Box mx={isexpiry === 'true' ? 1 : 4}>
                <Typography color='textSecondary' variant="h5"><b>:</b></Typography>
            </Box>
            <Box display='flex'
                flexDirection='column'
                alignItems='center'
            >
                <Typography variant='h5' >{livetime?.minute}</Typography>
                <Typography style={{ fontSize: '10px' }}>MINUTES</Typography>
            </Box>
            <Box mx={isexpiry === 'true' ? 1 : 4}>
                <Typography color='textSecondary' variant="h5"><b>:</b></Typography>
            </Box>
            <Box display='flex'
                flexDirection='column'
                alignItems='center'
            >
                <Typography variant='h5' >{livetime?.second}</Typography>
                <Typography style={{ fontSize: '10px' }}>SECONDS</Typography>
            </Box>
        </Box>}
    </Box>)
}

export default LaunchLive;