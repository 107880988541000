export const RestEnds = {
    // Authentication
    LOGIN: '/v1/login',
    LOGINWITHMETAMASK: 'v1/login/metamask',
    LOGINWITHGOOGLE: 'v1/login/google',
    REGISTER: '/v1/register/email',
    REGISTERIWTHMETAMASK: '/v1/register/metamask',
    METAMASK_NONCE: '/v1/nonce',
    REGISTERIWTHGOOGLE: '/v1/register/google',
    FORGOT_PASSWORD: '/v1/forgot-password',
    RESEND_EMAIL: '/v1/resend-email',
    RESET_PASSWORD: '/v1/reset-password',
    SECURITY_VERIFICATION: '/v1/2fa/verify',
    VERIFY_EMAIL: '/v1/email-verify',
    LOGOUT: '/v1/logout',
    REFRESH_TOKEN: '/v1/token',
    SUBSCRIBE: '/v1/subscribe',
    BETA_REGISTER: '/v1/beta-register',
    BETA_LOGIN: '/v1/beta-login',
    KYC_LOGIN: '/v1/kyc-login',
    KYC_SUCCESS: '/v1/kyc-success',
    KYC_FAILED: '/v1/kyc-transaction',

    // Profile
    STATS: 'stats',
    PROFILE: '/v1/profile',
    PROFILE_PIC: '/v1/profile/picture',
    PROFILE_BG_PIC: 'v1/profile/background/picture',
    PROFILE_USERNAME: '/v1/profile/username',
    PRIVACY_STATUS: '/v1/profile/status',
    WALLET_INTREST: '/v1/walletIntrestUpdate',
    CHANGE_PASSWORD: '/v1/change-password',
    SETUP_TWO_FA: '/v1/2fa/setup',
    CONFIRM_TWO_FA: '/v1/2fa/confirmation',
    VERIFY_TWO_FA: '/v1/2fa/verify',
    TOGGLE_TWO_FA: '/v1/2fa/config',
    RESET_TWO_FA: '/v1/2fa/reset',
    KYC: '/v1/kyc',
    AVAILABLE_USERNAME: '/v1/username',
    WALLET_BALANCE: '/v1/balance',
    FINANCIAL_TRANSACTION: (
        limit: any,
        offset: any,
        sortBy: string,
        transactionType: string
    ) =>
        `/v1/wallet/transactions?limit=${limit}&offset=${offset}&sortBy=${sortBy}&transactionType=${transactionType}`,
    BANK_ACCOUNT: '/v1/account',
    DELETE_BANK_ACCOUNT: (accountId: string) => `/v1/account/${accountId}`,
    WITH_DRAW: '/v1/withdrawal',
    WITHDRAW_REQUESTS: (
        limit: any,
        offset: any,
        sortBy: string,
        status: string,
        type: string
    ) =>
        `/v1/withdrawal?limit=${limit}&offset=${offset}&sortBy=${sortBy}&status=${status}&type=${type}`,
    FOLLOWERS_LIST: (limit: any, offset: any) =>
        `/v1/followers?limit=${limit}&offset=${offset}`,
    FOLLOWINGS_LIST: (limit: any, offset: any) =>
        `/v1/followings?limit=${limit}&offset=${offset}`,
    FOLLOW: `/v1/follow`,
    UNFOLLOW: `/v1/un-follow`,
    CRYPTO_ACCOUNT: `/v1/crypto-account`,
    ADD_ADDRESS: '/v1/address',
    DELETE_ADDRESS: (addressId: any) => `/v1/address/${addressId}`,
    ADDRESS_LIST: (limit: any, offset: any) =>
        `/v1/address?limit=${limit}&offset=${offset}`,
    NFT_REQUEST_LIST: (limit: any, offset: any) =>
        `/v1/nft-request?limit=${limit}&offset=${offset}&sortBy=recent`,
    NFT_REQUEST: `/v1/nft-request`,
    FAV_LIST: (limit: any, offset: any) =>
        `/v1/favourite/items?query=&category=&sortBy=recent&offset=${offset}&limit=${limit}&itemTag`,
    SECONDARY_EMAIL: '/v1/profile/secondary/email',
    PAYMENT_HISTORY: (limit: any, offset: any) => `/v1/payment-transaction/list?limit=${limit}&offset=${offset}`,
    UPDATE_DIRECT_TRANSFER: `/v1/update-direct-transfer`,

    // Exibition
    GET_EXIBUTION:()=>`/v1/exhibition/list`,
    GET_ALL_EXHIBITION :(query: string, apiType: string, region: string,limit:number)=>
        `/v1/exhibition/alllist?search=${query}&apiType=${apiType}&region=${region}&limit=${limit}&offset=1&sortBy=recent`,
    GET_ALL_EXHIBITIONLIST :()=>`/v1/exhibition/list?limit=50&offset=1&sortBy=recent`,
    GET_EXIBITION_INFO: (exhibitionId?:string ,view?:string) =>view?`/v1/exhibition/${exhibitionId}?view=admin`: `/v1/exhibition/${exhibitionId}`,
    GET_EXHIBITION_LIST: (limit: any, offset: any, search: any, type: any, region: any) => `/v1/exhibition/list?limit=${limit}&offset=${offset}&sortBy=recent&search=${search}&type=${type}&region=${region}`,

    // Curators 
    GET_CURATORS_LIST: (limit: any, offset: any, search: any, region: any, type:any='curator', sortBy: any = 'recent') => 
        `/v1/user-list?limit=${limit}&offset=${offset}&search=${search}&region=${region}&type=${type}&sortBy=${sortBy}`,
    
    // My Creations
    GET_ARTISTS_DETAIL:  (limit: any, offset: any,userId?:string) => `/v1/items/owned?limit=${limit}&offset=${offset}&query=&category=&sortBy=recent&itemsStatus=all&lockStatus=all&&userId=${userId}`,
    GET_ARTISTS_USERINFO: (userId?:string,view?:string) => view? `/v1/user-info/${userId}?view=admin`: `/v1/user-info/${userId}`,
    GET_ARTISTS : (limit: any, offset: any, query: any, region: any, exhibition: any, sortBy: any = 'recent') => `/v1/artist-list?region=${region}&exhibitionId=${exhibition}&blueTick=all&emailStatus=all&kycStatus=all&registrationType=all&offset=${offset}&limit=${limit}&fromDate=&toDate=&search=${query}&celebrity=all&type=artist&sortBy=${sortBy}`,
    GET_TRENDING_ARTISTS:'/v1/trending/trending_artists?ids=false',
    GET_ARTIST_ITEMS: (userId: any, itemtype: any) => `/v1/items/created/${userId}?itemtype=${itemtype}`,    

    GET_TRENDING_ARTS:'/v1/trending/trending_arts?ids=false',
    OWNED_ITEMS: (limit: any, offset: any, land: any ,userId?:string) =>userId?
        `/v1/items/owned?limit=${limit}&offset=${offset}&query=&category=&land=${land}&sortBy=recent&userId=${userId}`: `/v1/items/owned?limit=${limit}&offset=${offset}&query=&category=&land=${land}&sortBy=recent`,
    MY_COLLECTION: (limit: any, offset: any, query: string) =>
        `/v1/collection?limit=${limit}&offset=${offset}&query=${query}`,
    COLLECTION_BY_COLLECTIONID: (
        limit: any,
        offset: any,
        query: string,
        sortBy: any,
        collectionId: any,
        collectionData: any
    ) =>
        `/v1/collection/${collectionId}?limit=${limit}&offset=${offset}&sortBy=${sortBy}&collectionData=${collectionData}&query=${query}`,
    CREATE_COLLECTION: '/v1/collection',
    UPDATE_COLLECTION: '/v1/collection',
    EXPORT_NFT_FEE: '/v1/export-nft/fee',
    EXPORT_NFT: '/v1/export-nft/create',
    EXPORT_INITIATE_TRIPLEA: '/v1/payment-service/initiate-export-transaction',
    EXPORT_HISTORY_LIST: (limit: any, offset: any) =>
        `/v1/export-nft?limit=${limit}&offset=${offset}`,
    EXPORT_NFT_FRACTO: '/v1/payment/fracto',
    OFFRES_LIST: (limit: any, offset: any) =>
        `/v1/offer/user?limit=${limit}&offset=${offset}`,

    // Buy and Sell
    CALCULATION: '/v1/calculation',
    PLACE_ITEM_LIST: '/v1/list',
    BUY_MINT_FEE: '/V1/buy/mint-fee',
    BUY_INITIATE: '/v1/buy/initiate',
    BUY_INITIATE_TRIPLEA:'/v1/payment-service/initiate-transaction',
    COMPLETE_BUY: '/v1/buy',
    BUY_CRYPTO_CALCULATION: '/v1/buy/crypto-calculation',
    COMPLETE_BUY_FROM_ETHER: '/v1/buy/ether',
    POST_BID_FROM_ETHER: '/v1/bid/ether',
    BUY_FRACTO: '/v1/payment/fracto',
    CRYPTO_SUPPORT: '/v1/crypto-supported',
    OFFER_PRICE: (itemId: any) => `/v1/offer/price/${itemId}`,
    OFFER_INITIATE: '/v1/offer/initiate',
    OFFER_INITIATE_CRYPTO:'/v1/payment-service/initiate-postoffer-transaction' ,
    COMPLETE_OFFER: '/v1/offer/request',
    OFFER_LIST: (itemId: any, offset: number, limit: number) =>
        `/v1/offer/item/${itemId}?offset=${offset}&limit=${limit}`,
    ACCEPT_OFFER: (offerId: any) => `/v1/offer/${offerId}`,
    RANDOM_HEX: '/v1/random-hex',
    CASH_BACK_SUCCESS: `/v1/buy/cashback`,
    OFFER_LISTING:(listType:any, limit: any, offset: any)=>`/v1/offer/${listType}?limit=${limit}&offset=${offset}`,

    OFFER_LISTS:(itemId:any) => `/v1/offer/item/${itemId}` ,
    // Auction
    LIST_FOR_AUCTION: '/v1/list/auction',
    CANCEL_AUCTION: (auctionId: string) => `/v1/auction/${auctionId}`,
    PLACE_BID: '/v1/bid/post',
    PLACE_BID_FRACTO: '/v1/bid/post-fracto',
    CANCEL_BID: '/v1/bid/cancel',
    AUCTIONS: (itemId: string, limit: number, offset: number) =>
        `/v1/item/${itemId}/auction?limit=${limit}&offset=${offset}`,
    AUCTION_DETAIL: (auctionId: string) => `/v1/auction/${auctionId}`,

    // Marketplace
    CATEGORY: () => `/v1/category/?filter=false`,
    ALL_CATEGORY: () => `/v1/category/?filter=false&all=true`,
    RECENTLY_ADDED_ITEM_LIST: (limit: any, offset: any) =>
        `/v1/items/featured?limit=${limit}&offset=${offset}`,
    RECENTLY_ADDED_BIDS: (limit: any, offset: any) =>
        `/v1/items/auction/featured?limit=${limit}&offset=${offset}`,
    SEARCH_ITEMS: (limit: any, offset: any, sortBy: string, query: string) =>
        `/v1/search?limit=${limit}&offset=${offset}&sortBy=${sortBy}&query=${query}`,
    ITEM_LIST: (
        limit: any,
        offset: any,
        sortBy: string,
        query: string,
        category: string,
        minPrice: string,
        maxPrice: string,
        buy: boolean,
        newItem: boolean,
        onAuction: boolean,
        hasOffer: boolean,
        celebrities: string
    ) =>
        `/v1/marketplace?limit=${limit}&offset=${offset}&sortBy=${sortBy}&query=${query}&category=${category}&minPrice=${minPrice}&maxPrice=${maxPrice}&buy=${buy}&newItem=${newItem}&onAuction=${onAuction}&hasOffer=${hasOffer}&celebrity=${celebrities}`,
    MARKET_PLACE_ITEMS: (
        limit: any,
        offset: any,
        sortBy: string,
        query: string,
        category: string,
        minPrice: string,
        maxPrice: string,
        buy: boolean,
        newItem: boolean,
        onAuction: boolean,
        hasOffer: boolean,
        notLaunched: boolean,
        celebrities: string,
        blockChain: string,
        region: string,
        exhibition: string,
        physicalNFT: string,
        nsfw: string,
        editions: string,
        curatorsPick: boolean,
        assetType: string,
        allArts:boolean,
    ) =>{
    if(allArts){
      return `/v1/items/all-items?limit=${limit}&offset=${offset}&sortBy=${sortBy}&query=${query}&category=${category}&minPrice=${minPrice}&maxPrice=${maxPrice}&buy=${buy}&newItem=${newItem}&onAuction=${onAuction}&hasOffer=${hasOffer}&notLaunched=${notLaunched}&celebrity=${celebrities}&blockchain_platform=${blockChain}&region=${region}&exhibitionId=${exhibition}&physicalNFT=${physicalNFT}&nsfw=${nsfw}&assetType=${assetType}&editions=${editions}&curatorsPick=${curatorsPick}`
     }
      return `/v1/marketplace?limit=${limit}&offset=${offset}&sortBy=${sortBy}&query=${query}&category=${category}&minPrice=${minPrice}&maxPrice=${maxPrice}&buy=${buy}&newItem=${newItem}&onAuction=${onAuction}&hasOffer=${hasOffer}&notLaunched=${notLaunched}&celebrity=${celebrities}&blockchain_platform=${blockChain}&region=${region}&exhibitionId=${exhibition}&physicalNFT=${physicalNFT}&nsfw=${nsfw}&assetType=${assetType}`
    },
    ITEM_DETAIL: (itemId: any,view?:string) =>view?`/v1/item/${itemId}?sortBy=recent&view=admin`: `/v1/item/${itemId}?sortBy=recent`,
    ITEM_TRADING_HISTORY: (itemId: any, limit: any, offset: any) =>
        `/v1/item/${itemId}/history?limit=${limit}&offset=${offset}&sortBy=recent`,
    ITEM_MIN_MAX: `/v1/items/min-max-amount`,

    // User Profile - Portfolio
    PORTFOLIO_INFO: (userId: string) => `/v1/user-info/${userId}`,
    PORTFOLIO_ITEMS: (limit: any, offset: any, userId: string) =>
        `/v1/items/owned?limit=${limit}&offset=${offset}&query=&category=&userId=${userId}`,
    PORTFOLIO_COLLECTION: (limit: any, offset: any, userId: string) =>
        `/v1/collection?limit=${limit}&offset=${offset}&query=&user=true&userId=${userId}`,

    PORTFOLIO_ONSALE: (
        limit: any, 
        offset: any,
        sortBy: string,
        query: string,
        category: string,
        minPrice: string,
        maxPrice: string,
        buy: boolean,
        newItem: boolean,
        onAuction: boolean,
        hasOffer: boolean,
        celebrity: string
    ) =>
        `/v1/marketplace?limit=${limit}&offset=${offset}&sortBy=${sortBy}&query=${query}&category=${category}&minPrice=${minPrice}&maxPrice=${maxPrice}&buy=${buy}&newItem=${newItem}&onAuction=${onAuction}&hasOffer=${hasOffer}&celebrity=${celebrity}`,

    PORTFOLIO_CREATED: (
        limit: any,
        offset: any,
        userId: string,
        sortBy: string
    ) =>
        `/v1/items/created?limit=${limit}&offset=${offset}&userId=${userId}&sortBy=${sortBy}`,

    // Item
    CREATE_ITEM: '/v1/item',
    UPDATE_ITEM: '/v1/item',
    ITEM_NAME_LIST: (name: string) => `/v1/item/${name}/exists`,
    TOGGLE_ITEM_FAV: `/v1/favourite`,
    NFT_VIEW_COUNT: '/v1/view/item',

    // Activity
    ACTIVITY_LIST: (limit: any, offset: any, sortBy: string, event: string) =>
        `/v1/activity/?limit=${limit}&offset=${offset}&sortBy=${sortBy}&event=${event}`,

    // Report
    CREATE_REPORT: '/v1/items/report',

    // Converstion
    CURRENCY_CONVERSION: (amount: any, from: string, to: string) =>
        `/v1/conversion/?amount=${amount}&from=${from}&to=${to}`,
    STORED_CURRENCY_CONVERSION: (amount: any, from: string, to: string) =>
        `/v1/conversion/?amount=${amount}&from=${from}&to=${to}`,
    CURRENCY_HOLDING: (amount: any, from: string, to: string) =>
        `/v1/conversion/hold/?amount=${amount}&from=${from}&to=${to}`,

    // Import NFT
    IMPORTED_NFT_HISTORY: (limit: any, offset: any) =>
        `/v1/import-nft?limit=${limit}&offset=${offset}`,
    CREATE_IMPORT: '/v1/import-nft',

    // Landing Page
    FEATURED_COLLECTIONS: `/v1/collection/featured?limit=6&offset=1`,
    FEATURED_ITEMS: `/v1/items/featured/category?limit=8&offset=1`,
    BANNER_ITEM: `/v1/items/banner`,
    TRENDING_AUCTIONS: `v1/items/featured/auction`,
    CATEGORIES: `/v1/item-tags`,
    SUBSCRIPTION: '/v1/subscribe',
    TOP_COLLECTIONS: (limit: number, offset: number): string =>
        `/v1/collection/featured?limit=${limit}&offset=${offset}`,
    FEATURED_NFTS: '/v1/items/featured',
    FEATURED_NFT: (limit: number, offset: number): string =>
        `/v1/items/showcase?limit=${limit}&offset=${offset}`,

    CATEGORY_ITEMS: (
        itemCategoriesId: string,
        limit: number,
        offset: number
    ): string =>
        `/v1/items/${itemCategoriesId}?limit=${limit}&offset=${offset}`,

    // Celebrity
    CELEBRITY_LIST: (limit: any, offset: any, query: string): string =>
        `/v1/celebrities/?limit=${limit}&offset=${offset}&search=${query}`,

    // Contact us
    CONTACT_US: '/v1/contact-us',
    CAREER: '/v1/career',
    CAPTCHA: (page: string) => `/v1/captcha?page=${page}`,

    // Lands
    LAND_DETAILS: (longitude: string, latitude: string, city: string) => 
        `https://serveruvemtl1v-dev-machine-server-3000.morpheuslabs.io/api/landNFTDetails?longitude=${longitude}&latitude=${latitude}&city=${city}`,

    BUY_LAND_INITIATE: `/v1//buy-land/initiate`,
    BUY_LAND: `/v1/buy-land/fracto`,
    BUY_COMPLETE: `/v1/buy-land/buy`,

    // Region
    REGION_LIST: (searchingFor: string) => `/v1/region/list?searchingFor=${searchingFor}`,
    COUNTRY_LIST: '/v1//country/list',
    LATEST_ANNOUNCEMENTS: '/v1/announcements',
    OFFER_ACCEPT:(offerId:any,opt:string)=>`v1/offer/${opt}/${offerId}`
};