import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress } from '@material-ui/core';
import queryString from 'query-string';
import { HeadTag } from '../../shared/components';
import { AuthenticationService } from '../../services';
import { Utils } from '../../shared/helpers';

const EmailVerificationPage = (): ReactElement => {
    const navigate = useNavigate();
    const { code } = queryString.parse(window.location.search);

    useEffect(() => {
        if (code) {
            AuthenticationService.verifyEmail({ code })
                .then((res) => {
                    Utils.handleSuccessResponse(res);
                })
                .catch((error) => {
                    Utils.handleErrorResponse({error});
                });
        }
        navigate('/login', { replace: true });
    }, []);

    return (
        <>
            <HeadTag title="Email Verification" />
            <Box className="empty-content">
                <CircularProgress />
            </Box>
        </>
    );
};

export default EmailVerificationPage;