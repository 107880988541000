import { Outlet } from 'react-router-dom';
import { ReactElement, useEffect, useState } from 'react';
import { AppBar, Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { AppState } from '../state/RootReducer';
import { AppHeader } from '../shared/components';
import AppFooterSmall from '../shared/components/AppFooterSmall';


const PageWrapper = (): ReactElement =>  <div className="app-container gradient-bg ">
        <div className="app-header">
            <AppHeader />
        </div>
        <div className="app-body">
            <Outlet />
        </div>
        <div className="app-footer">
            <AppFooterSmall />
        </div>
    </div>;


export default PageWrapper;
