import * as Yup from 'yup';

import { firstName, lastName, email, contactNo } from './Schema';

export const CareerSchema = Yup.object().shape({
    firstName,
    lastName,
    email,
    contactNo,
});
