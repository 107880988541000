import { Button, Box, IconButton } from '@material-ui/core';
import { ReactElement } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { getVideoUrl } from '../helpers/Utils';

const DownloadResource = (props: any): ReactElement => {
    const { data } = props

    const downloadstart = async (path: any, id: any): Promise<any> => {
        try {

            const response = await fetch(path);
            await response.blob().then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `file-${id}.${path.split('.').pop()}`;
                a.click();
            });
        } catch (error) {
            console.log(error)
        }
    }

    const downloadfiles = async (): Promise<any> => {
        if (data?.itemInfo) {
            const { thumbnail, images, video } = data?.itemInfo
            // const images = ['https://images.pexels.com/photos/7948965/pexels-photo-7948965.jpeg', 'https://images.pexels.com/photos/6042966/pexels-photo-6042966.jpeg']
            // const video = ['https://images.pexels.com/photos/7948965/pexels-photo-7948965.jpeg', 'https://images.pexels.com/photos/6042966/pexels-photo-6042966.jpeg', 'https://assets.mixkit.co/videos/preview/mixkit-going-down-a-curved-highway-through-a-mountain-range-41576-large.mp4', 'https://assets.mixkit.co/videos/preview/mixkit-three-boys-exploring-a-haunted-house-on-halloween-44292-large.mp4']
            const listofFiles = [`${process.env.REACT_APP_GIFURL}/${thumbnail}`]

            /* eslint-disable no-plusplus */
            for (let i = 0; i < images?.length; i++) {
                /* eslint-disable no-await-in-loop */
                listofFiles.push(`${process.env.REACT_APP_GIFURL}/${images[i]}`)
            }
            for (let i = 0; i < video?.length; i++) {
                /* eslint-disable no-await-in-loop */
                listofFiles.push(getVideoUrl(video[i]))
                // listofFiles.push(video[i])
            }
            alert('Start Download all resource in background')

            const videoUrls = async (): Promise<any> => {
                /* eslint-disable no-plusplus */
                for (let i = 0; i < listofFiles?.length; i++) {
                    /* eslint-disable no-await-in-loop */
                    await downloadstart(listofFiles[i], i)
                }
            }
            videoUrls()
        }
    }

    return (<div className='download-resource-btn'>
        {
            data?.itemInfo ?
                <>
                    <Button className='download-btn-lg' variant="contained" style={{ height: '2.4rem', marginTop: '0px' }} color='primary' size='small' onClick={downloadfiles}>
                        <Box display='flex' alignItems='center'>
                            <GetAppIcon fontSize='small' /> <span>Download</span>
                        </Box>
                    </Button>
                    <IconButton onClick={downloadfiles} color='primary' className='download-btn-sm'>
                        <GetAppIcon fontSize='small' />
                    </IconButton>
                </>
                : ''
        }
    </div>

    )
}

export default DownloadResource;