import { ReactElement } from 'react';
import { FinancialTransactionList } from './components';
import { HeadTag } from '../../shared/components';

const FinancialTransactionPage = (): ReactElement => (
    <>
        <HeadTag title="Financial Transaction List" />
        <FinancialTransactionList />
    </>
);

export default FinancialTransactionPage;
