import { Box, Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { ReactElement, useState } from 'react';
// import Created from './Created';
import Collected from './Collected';
import Liked from './Liked';
import Exhibited from './Exhibited'

const CuratorsDetailsTab = (): ReactElement => {
    const [value, setValue] = useState('1');
    const handleChange = (e: any, newValue: string): any => {
        setValue(newValue)
    }

    return (
        <>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="artist-tab">
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Curated" value='1' />
                        <Tab label="Collected" value='2' />
                        <Tab label="Liked" value='3' />
                    </TabList>
                </Box>
                <TabPanel className='market-slider-wrapper' value={value}>
                    <Box className={value === '1' ? 'market-slider active' : 'market-slider'}>
                        <Exhibited />
                    </Box>
                </TabPanel>
                <TabPanel value='2'>
                    <Box className='data-not-found'>
                        <Collected />
                    </Box>
                </TabPanel>
                <TabPanel value='3'>
                    <Box className='data-not-found'>
                        <Liked />
                    </Box>
                </TabPanel>
            </TabContext>
        </>
    )
}

export default CuratorsDetailsTab
