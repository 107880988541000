/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import update from 'immutability-helper';
import { AuthReduxActions } from './Action';

export interface AuthState {
    isUserLoggedIn: boolean;
    isBetaUser: boolean;
    isAPICalling: boolean;
}

const authState: AuthState = {
    isUserLoggedIn: false,
    isBetaUser: false,
    isAPICalling: false
};

const AuthStateReducer = (state = authState, action: any): AuthState => {
    switch (action.type) {
        case AuthReduxActions.AUTH_SET_LOGIN_STATUS:
            return update(state, {
                $merge: {
                    isUserLoggedIn: action.payload,
                },
            });
        case AuthReduxActions.SET_BETA_LOGIN_STATUS:
            return update(state, {
                $merge: {
                    isBetaUser: action.payload,
                },
            });
        case AuthReduxActions.LOGOUT:
            return update(state, {
                $merge: {
                    isUserLoggedIn: false,
                },
            });
        case AuthReduxActions.GLOBAL_LOADING:
            return update(state, {
                $merge: {
                    isAPICalling: action.payload,
                },
            });
        default:
            return state;
    }
};

export default AuthStateReducer;
