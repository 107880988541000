import { ReactElement } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { Avatar, Box, CardMedia, Fade, Grid, Tooltip, Typography } from '@material-ui/core';
import CollectionsIcon from '@material-ui/icons/Collections';
import moment from 'moment';
import { EmptyPlaceholder, HeadTag, SlideNextArrow, SlidePrevArrow } from '../../../shared/components';
import { getUrl, goesLive } from '../../../shared/helpers/Utils';
import ReginIconName from './regionIconName';


const UpcomingExhibitions = ({ exihibition }: any): ReactElement => {
    const title = 'About';
    const { upcoming } = exihibition;

    const description =
        "About";
    const ImageSliderConfigArtist = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        arrows: true,
        slidesToScroll: 2,
        initialSlide: 0,
        nextArrow: <SlideNextArrow />,
        prevArrow: <SlidePrevArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };




    return (
        <Box id="upcoming-wrapper " className='upcoming-wrraper'>
            {upcoming?.length > 0 ?
                <>
                    <HeadTag title={title} description={description} />
                    <Grid container className="banner-wrapper" alignItems="center">
                        <Grid xs={12} id="About">
                            <Box className='about-title-box upcoming-exhibitions-py'>
                                <Typography className="about-title">Upcoming Exhibitions</Typography>
                            </Box>
                        </Grid>
                        <Slider className='nft-slider' {...ImageSliderConfigArtist}>

                            {upcoming && upcoming.map((upcomingEx: any) => (
                                <Grid xs={12} sm={12} lg={12} className='exhibition-box-content upcoming-pr'>

                                    {/* eslint no-underscore-dangle: 0 */}
                                    <Link to={`/exhibition/${upcomingEx._id}`} >
                                        <Box className='about-image-box exhibition'>
                                            <CardMedia
                                                component="img"
                                                height="286"
                                                image={upcomingEx?.backgroundPicture ? getUrl(`${upcomingEx.backgroundPicture}`, '1000x1000/') : '/images/if-no-image.png'} alt="backgroundImage"
                                            />
                                            <Box className='exhibition-content'>
                                                <Typography variant="h1">
                                                    {upcomingEx.title ? upcomingEx.title : "No title"}
                                                </Typography>
                                                <Grid container spacing={1}>
                                                    <Grid item style={{ width: '100%' }} sm={12} md={12} lg={12} xl={12}>
                                                        <Box
                                                            width='100%'
                                                            display='flex'
                                                            flexDirection='row'
                                                            alignItems='center'
                                                            className="collage-mobile">
                                                            <Avatar src={getUrl(upcomingEx.curator.profilePicture) ? getUrl(`${upcomingEx.curator.profilePicture}`, '400x400/') : '/icons/user.png'} />
                                                            <Box
                                                                pl={1}
                                                                height={46}
                                                                display='flex'
                                                                flexDirection='column'
                                                                className="banner-text">
                                                                <Typography className='text-gray curator-text '>
                                                                    Curated By
                                                                </Typography>
                                                                <Tooltip arrow title={upcomingEx.curator.name || 'no name'} TransitionComponent={Fade}
                                                                    TransitionProps={{ timeout: 600 }}>
                                                                    <Typography variant="h5" className='curatorName text-ellipsis--1' title={upcomingEx.curator.name} >
                                                                        {upcomingEx?.curator?.name}
                                                                    </Typography>
                                                                </Tooltip>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item style={{ width: '100%' }} sm={12} md={12} lg={12} xl={12}>
                                                        <Box height={36} className='text-ellipsis--2'>
                                                            <Typography variant="body1">
                                                                {upcomingEx?.description.replace(/<[^>]+>/g, '')}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <Box mt={5} display='flex'
                                                    justifyContent='space-between'
                                                    alignItems='center'
                                                    className="exhibition-button">
                                                    <ReginIconName name={`${upcomingEx?.region?.name}, ${upcomingEx?.region?.subRegion}`} />
                                                    <Box
                                                        ml={2}
                                                        display='flex'
                                                        justifyContent='space-between'
                                                        alignItems='center'
                                                        className='exhibition-time'>
                                                        <img src="/images/clock.svg" alt="time-icon" className='time-icon' />
                                                        <Box
                                                            ml={1}
                                                            style={{ fontSize: 15 }}
                                                            display='flex'
                                                            justifyContent='flex-start'
                                                            flexDirection='column'
                                                            height={36}
                                                        >
                                                            <Box className='sales-start'>Sale Starts In</Box>
                                                            <Box>
                                                                {goesLive(upcomingEx?.startDate)}
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Link>
                                </Grid>
                            ))}
                        </Slider>

                        {upcoming === undefined || upcoming.length === 0 && (
                            <EmptyPlaceholder
                                icon={<CollectionsIcon color="primary" />}
                                message="Upcoming Exhibitions not available"
                            />
                        )}

                    </Grid>
                </>

                : <></>
            }
        </Box>
    )
}

export default UpcomingExhibitions