import { useEffect } from 'react';

export const useDebouncedEffect = (
    callback: any,
    dependancies: any[],
    delay: number
): void => {
    useEffect(() => {
        const handler = setTimeout(() => callback(), delay);
        return () => clearTimeout(handler);
    }, [...(dependancies || []), delay]);
};
