import { ReactElement } from 'react';
// import { Avatar } from '@material-ui/core';
// import { useGoogleLogin } from 'react-google-login';
import { useGoogleLogin } from '@react-oauth/google';
import { AppSecondaryButton } from './AppComponents';
// const clientId: any = '591261736761-2rh7sg4mo72pgk6hqa9o3hcocv5gnkn8.apps.googleusercontent.com'

const GoogleLoginOption = (props: any): ReactElement => {
    const { loading } = props;
    const { onClick } = props;

    const onSuccess = (response: any): void => {
        const requestObj = {
            tokenId: response.access_token,
            accessToken: response.access_token,
        };
        onClick(requestObj);
    };

    // const onFailure = (response: any): void => {
    //     console.log("response error: ", response)
    // }
 
    // const { signIn } = useGoogleLogin({
    //     onSuccess,
    //     onFailure,
    //     clientId,
    //     isSignedIn: false,
    //     accessType: 'offline',
    // });

    const signIn = useGoogleLogin({
        onSuccess,
        flow: 'implicit',
    });

    return (
        <>
            <AppSecondaryButton
                fullWidth
                title="Continue with Google"
                isLoading={loading}
                loadingText="Logging in"
                className="googleBtn auth-label"
                // startIcon={
                //     !loading ? (
                //         <Avatar alt="Google" src="/images/google-logo.png" />
                //     ) : (
                //         <></>
                //     )
                // }
                onClick={signIn}
            />
        </>
    );
};

export default GoogleLoginOption;
