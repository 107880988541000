import { Box, CircularProgress, Grid, Tab, Typography } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { ReactElement, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import NFTList from './NFTList';
import ArtistsTab from './ArtistsTab';
import ExhibitionTab from './ExhibitionTab';
import CuratorsTab from './CuratorsTab';
import NftFilters from './NftFilters';
import ArtistFilters from './ArtistFilters';
import ExhibitionFilters from './ExhibitionFilters';
import CuratorFilters from './CuratorFilters';
import SelectedFilter from './SelectedFilter';
import { MarketPlaceActions } from '../../../state/marketplace/Action';
import { MarketFilterAction } from '../../../state/filter/Action';
import { defaultMarketPlaceFilter } from '../../../state/marketplace/Reducer';
import { AppState } from '../../../state/RootReducer';
import { CollectionService } from '../../../services';
import { Utils } from '../../../shared/helpers';
import CuratingLoading from '../../../shared/components/curatingLoading';

const CommanLoading = (props: any): ReactElement => {
    const { name } = props

    return (<>
        <Box width='100%'
            height='78vh'
            display='flex'
            justifyContent='center'
            alignItems='center'>

            <Box display='flex'
                flexDirection='column'
                alignItems='center'>
                <CircularProgress />
                <br />
                <Typography color='textSecondary'>CURATING LIMITLESS POSSIBILITIES</Typography>
            </Box>
        </Box>
    </>

    )
}

const MarketPlaceTab = (props: any): ReactElement => {
    const { clearFilter, setRegionList, setExhibitioinList, marketPlaceFilter, setPrice, setMinMax, marketFilterList } = props;
    const url = window.location.href.split('/')[4];
    const [loading, setLoader] = useState(false);

    const navigate = useNavigate();
    const [value, setValue] = useState('1');
    const handleChange = (e: any, newValue: string): any => {
        clearFilter(marketFilterList)
        setValue(newValue)
        if (newValue === '2') {
            navigate('/marketplace/artist');
        } else if (newValue === '3') {
            navigate('/marketplace/exhibition');
        } else if (newValue === '4') {
            navigate('/marketplace/curator');
        } else {
            navigate('/marketplace/artwork');
        }
    }

    useEffect(() => {
        if (url === 'artist') {
            setValue('2')
        } else if (url === 'exhibition') {
            setValue('3')
        } else if (url === 'curator') {
            setValue('4')
        }
    }, [value])

    const getFilterComponent = (): ReactElement => {
        if (value === '1') {
            return <NftFilters />
        } if (value === '2') {
            return <ArtistFilters />
        } if (value === '3') {
            return <ExhibitionFilters />
        } if (value === '4') {
            return <CuratorFilters />
        }
        return <></>;
    }

    // Api Call
    const getRegions = (): void => {
        let searchingFor = '';
        if (value === '1') {
            searchingFor = 'artwork'
        } if (value === '2') {
            searchingFor = 'artist'
        } if (value === '3') {
            searchingFor = 'exhibition'
        } if (value === '4') {
            searchingFor = 'curator'
        }
        CollectionService.getRegions(searchingFor)
            .then((result: any) => {
                const data = result?.data?.data;
                if (data) {
                    setRegionList(data)

                }
            })
            .catch((error) => {
                Utils.handleErrorResponse({ error });
            })
    }

    const getExhibitions = (): void => {
        CollectionService.getAllexhibitionList()
            .then((result: any) => {
                const data = result?.data?.data;
                if (data) {
                    setExhibitioinList(data)

                }
            })
            .catch((error) => {
                Utils.handleErrorResponse({ error });
            })
    }

    const getMinMax = (): void => {
        CollectionService.getMinMax()
            .then((result: any) => {
                const data = result?.data?.data
                if (data) {
                    setMinMax({ ...marketFilterList, ...data })
                    console.log("price filter: ", { ...defaultMarketPlaceFilter, query: '', minPrice: data.min, maxPrice: data.max, absMin: data.min, absMax: data.max })
                    setPrice({ ...defaultMarketPlaceFilter, query: '', minPrice: data.min, maxPrice: data.max, absMin: data.min, absMax: data.max })
                }
            })
            .catch((error) => {
                Utils.handleErrorResponse({ error });
            })
    }

    useEffect(() => {
        getExhibitions();
        if (url === 'artwork' || url === undefined) {
            getMinMax()
        }
        getRegions();
        clearFilter(marketFilterList);
    }, [url])

    return (
        <>
            <div>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="exhibition-tab-main">
                        <TabList onChange={handleChange} aria-label="lab API tabs example" className='marketplace-tablist'>
                            <Tab label="Artworks" value='1' className='tab-value-one tab-value-box' />
                            <Tab label="Artists" value='2' className='tab-value-two tab-value-box' />
                            <Tab label="Exhibitions" value='3' className='tab-value-three tab-value-box' />
                            <Tab label="Curators" value='4' className='tab-value-four tab-value-box' />
                        </TabList>
                    </Box>
                    <Box className='Exi-tab'>
                        <Grid
                            className="filter-panel"
                            item
                            xl={3}
                            lg={3}
                            md={3}
                            sm={12}
                            xs={12}
                        >
                            {!loading && getFilterComponent()}
                        </Grid>
                        <Grid
                            className="nft-panel"
                            item
                            xl={9}
                            lg={9}
                            md={9}
                            sm={12}
                            xs={12}
                        >
                            <SelectedFilter />
                            <TabPanel className='market-slider-wrapper tab-panel-artist' value='1'>
                                {value === '1' &&
                                    <NFTList loaderUi={<CommanLoading />} />
                                }
                            </TabPanel>
                            <TabPanel value='2' className='tab-panel-artist'>
                                {value === '2' &&
                                    <ArtistsTab marketPlace loaderUi={<CommanLoading />} />
                                }
                            </TabPanel>
                            <TabPanel value='3' className='tab-panel-artist'>
                                {value === '3' &&
                                    <ExhibitionTab loaderUi={<CommanLoading />} />
                                }
                            </TabPanel>
                            <TabPanel value='4' className='tab-panel-artist'>
                                {value === '4' &&
                                    <CuratorsTab loaderUi={<CommanLoading />} />
                                }
                            </TabPanel>
                        </Grid>
                    </Box>
                </TabContext>
            </div>
        </>
    )
}
const mapStateToProps = (state: AppState): any => ({
    marketPlaceFilter: state.marketPlaceFilter,
    marketFilterList: state.MarketFilterList,
    isUserLoggedIn: state.authState.isUserLoggedIn
});

const mapDispatchToProps = (dispatch: any): any => ({
    clearFilter: (data: any) => dispatch(MarketPlaceActions.clearFilter(data)),
    setPrice: (data: any) => dispatch(MarketPlaceActions.setPrice(data)),
    setRegionList: (data: any) => dispatch(MarketFilterAction.setRegionList(data)),
    setExhibitioinList: (data: any) => dispatch(MarketFilterAction.setExhibitioinList(data)),
    setMinMax: (data: any) => dispatch(MarketFilterAction.setMinMax(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketPlaceTab);
