import { ReactElement } from 'react';
import { Avatar, Typography, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Utils } from '../helpers';
import { getUrl } from '../helpers/Utils';

export interface UserInfoProps {
    id: string;
    isPrivate: boolean;
    name: string;
    address: string;
    imgUrl?: string;
    blueTick: boolean;
    fontVariant?: any;
    originalArtist?: string;
}
const UserInfo = (props: UserInfoProps): ReactElement => {
    const {
        id,
        // isPrivate,
        name,
        // address,
        imgUrl,
        blueTick,
        fontVariant,
        originalArtist,
    } = props;
    const getVariant = fontVariant || 'body1';
    // UI Elements
    return (
        <>
            {/* {isPrivate && (
                <>
                    {!address && (
                        <Typography className="title" variant={getVariant}>
                            -
                        </Typography>
                    )}
                    {address && (
                        <>
                            <Tooltip
                                title="Private Account"
                                placement="top-start"
                            >
                                <Box className="user-info">
                                    <Avatar src="/icons/user.png" />
                                    <Box>
                                        <Typography
                                            className="title"
                                            variant={getVariant}
                                        >
                                            {Utils.formatedAddress(address)}
                                        </Typography>
                                        {blueTick && (
                                            <img
                                                className="icon-verified"
                                                src="/icons/icon_verified.png"
                                            />
                                        )}
                                        {originalArtist && (
                                            <Typography className="f11">
                                                Original Artist:{' '}
                                                <Typography className="primary">
                                                    {originalArtist}
                                                </Typography>
                                            </Typography>
                                        )}
                                    </Box>
                                </Box>
                            </Tooltip>
                        </>
                    )}
                </>
            )} */}
            {/* {isPrivate && ( */}
                <>
                    <Link
                        className="user-info"
                        to={id ? `/artist/${id}`: ''}
                    >
                        <Avatar src={getUrl(imgUrl,"400x400/") || '/icons/user.png'} />
                        <Box>
                            <Box display="flex">
                                <Typography
                                    className="title underLine"
                                    variant={getVariant}
                                >
                                    {name===null?'-':name}
                                </Typography>
                                {blueTick && (
                                    <img
                                        className="icon-verified"
                                        src="/icons/icon_verified.png"
                                    />
                                )}
                            </Box>

                            {originalArtist && (
                                <Typography className="f11">
                                    Original Artist:{' '}
                                    <Typography className="primary">
                                        {Utils.formateName(originalArtist)}
                                    </Typography>
                                </Typography>
                            )}
                        </Box>
                    </Link>
                </>
            {/* )} */}
        </>
    );
};

export default UserInfo;
