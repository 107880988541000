import { ReactElement } from 'react';
import { connect } from 'react-redux';
import { Box, Chip } from '@material-ui/core';
import { MarketPlaceActions } from '../../../state/marketplace/Action';
import { AppState } from '../../../state/RootReducer';
import { AppTeritaryButton } from '../../../shared/components/AppComponents';

const SelectedFilter = (props: any): ReactElement => {
    // Instance Variable
    const { marketPlaceFilter, setFilter, clearFilter, marketFilterList } = props;

    // UI Elements
    return (
        <Box className="selected-filters-container">
            {(marketPlaceFilter.newItem ||
                marketPlaceFilter.onAuction ||
                marketPlaceFilter.buy ||
                marketPlaceFilter.hasOffer ||
                marketPlaceFilter.crypto ||
                marketPlaceFilter.fiat ||
                marketPlaceFilter.notLaunched || 
                marketPlaceFilter.region ||
                marketPlaceFilter.assetType !== "all" ||
                marketPlaceFilter.exhibition ||
                marketPlaceFilter.apiType ||
                marketPlaceFilter.physicalNFT === 'true' ||
                marketPlaceFilter.nsfw === 'true' ||
                marketPlaceFilter.curatorsPick === 'true' ||
                marketPlaceFilter.editions !== 'ALL' ||
                marketPlaceFilter.category !== 'All NFTs' || marketPlaceFilter.allArts ) && (
                    <Box className="selected-filters">
                        {marketPlaceFilter.category !== 'All NFTs' && (
                            <Chip
                                size='small'
                                className='selected-filters-icon'
                                label={marketPlaceFilter.category}
                                onDelete={() => {
                                    setFilter({
                                        ...marketPlaceFilter,
                                        category: 'All NFTs',
                                    });
                                }}
                                color="primary"
                                variant="outlined"
                            />
                        )}
                        {marketPlaceFilter.buy && (
                            <Chip
                                size='small'
                                className='selected-filters-icon'
                                label="Available for sale"
                                onDelete={() => {
                                    setFilter({
                                        ...marketPlaceFilter,
                                        buy: !marketPlaceFilter.buy,
                                    });
                                }}
                                color="primary"
                                variant="outlined"
                            />
                        )}
                        {marketPlaceFilter.onAuction && (
                            <Chip
                                size='small'
                                className='selected-filters-icon'
                                label="On Auction"
                                onDelete={() => {
                                    setFilter({
                                        ...marketPlaceFilter,
                                        onAuction: !marketPlaceFilter.onAuction,
                                    });
                                }}
                                color="primary"
                                variant="outlined"
                            />
                        )}
                        {marketPlaceFilter.newItem && (
                            <Chip
                                size='small'
                                className='selected-filters-icon'
                                label="New"
                                onDelete={() => {
                                    setFilter({
                                        ...marketPlaceFilter,
                                        newItem: !marketPlaceFilter.newItem,
                                    });
                                }}
                                color="primary"
                                variant="outlined"
                            />
                        )}
                        {marketPlaceFilter.hasOffer && (
                            <Chip
                                size='small'
                                className='selected-filters-icon'
                                label="Has Offer"
                                onDelete={() => {
                                    setFilter({
                                        ...marketPlaceFilter,
                                        hasOffer: !marketPlaceFilter.hasOffer,
                                    });
                                }}
                                color="primary"
                                variant="outlined"
                            />
                        )}
                        {marketPlaceFilter.crypto && (
                            <Chip
                                size='small'
                                className='selected-filters-icon'
                                label="Crypto"
                                onDelete={() => {
                                    setFilter({
                                        ...marketPlaceFilter,
                                        crypto: !marketPlaceFilter.crypto,
                                    });
                                }}
                                color="primary"
                                variant="outlined"
                            />
                        )}
                        {marketPlaceFilter.fiat && (
                            <Chip
                                size='small'
                                className='selected-filters-icon'
                                label="Fiat"
                                onDelete={() => {
                                    setFilter({
                                        ...marketPlaceFilter,
                                        fiat: !marketPlaceFilter.fiat,
                                    });
                                }}
                                color="primary"
                                variant="outlined"
                            />
                        )}
                        {marketPlaceFilter.notLaunched && (
                            <Chip
                                size='small'
                                className='selected-filters-icon'
                                label="Upcoming"
                                onDelete={() => {
                                    setFilter({
                                        ...marketPlaceFilter,
                                        notLaunched: !marketPlaceFilter.notLaunched,
                                    });
                                }}
                                color="primary"
                                variant="outlined"
                            />
                        )}
                          {marketPlaceFilter.allArts && (
                            <Chip
                                size='small'
                                className='selected-filters-icon'
                                label="All Arts "
                                onDelete={() => {
                                    setFilter({
                                        ...marketPlaceFilter,
                                        allArts: !marketPlaceFilter.allArts,
                                    });
                                }}
                                color="primary"
                                variant="outlined"
                            />
                        )}
                        {marketPlaceFilter.region && (
                            <Chip
                                size='small'
                                className='selected-filters-icon'
                                label={marketPlaceFilter.regionName}
                                onDelete={() => {
                                    setFilter({
                                        ...marketPlaceFilter,
                                        regionName: '',
                                        region: ''
                                    });
                                }}
                                color="primary"
                                variant="outlined"
                            />
                        )}
                        {marketPlaceFilter.exhibition && (
                            <Chip
                                size='small'
                                className='selected-filters-icon'
                                label={marketPlaceFilter.exhibitionName}
                                onDelete={() => {
                                    setFilter({
                                        ...marketPlaceFilter,
                                        exhibitionName: '',
                                        exhibition: ''
                                    });
                                }}
                                color="primary"
                                variant="outlined"
                            />
                        )}
                        {marketPlaceFilter.editions && marketPlaceFilter.editions !== 'ALL' && (
                            <Chip
                                size='small'
                                className='selected-filters-icon'
                                label={`Editions=${marketPlaceFilter.editions}`}
                                onDelete={() => {
                                    setFilter({
                                        ...marketPlaceFilter,
                                        editions: ''
                                    });
                                }}
                                color="primary"
                                variant="outlined"
                            />
                        )}
                        {marketPlaceFilter.apiType && (
                            <Chip
                                size='small'
                                className='selected-filters-icon'
                                label={marketPlaceFilter.apiType}
                                onDelete={() => {
                                    setFilter({
                                        ...marketPlaceFilter,
                                        apiType: '',
                                    });
                                }}
                                color="primary"
                                variant="outlined"
                            />
                        )}
                        {marketPlaceFilter.assetType && marketPlaceFilter.assetType !== "all" && (
                            <Chip
                                size='small'
                                className='selected-filters-icon'
                                label={marketPlaceFilter.assetType}
                                onDelete={() => {
                                    setFilter({
                                        ...marketPlaceFilter,
                                        assetType: 'all',
                                    });
                                }}
                                color="primary"
                                variant="outlined"
                            />
                        )}
                        {/* {marketPlaceFilter.physicalNFT === 'all' && (
                        <Chip
                        className='selected-filters-icon'
                            label="All"
                           
                            color="primary"
                            variant="outlined"
                        />
                    )} */}
                        {marketPlaceFilter.physicalNFT === 'true' && (
                            <Chip
                                size='small'
                                className='selected-filters-icon'
                                label="NFT with Physical Artwork"
                                onDelete={() => {
                                    setFilter({
                                        ...marketPlaceFilter,
                                        physicalNFT: 'all',
                                    });
                                }}
                                color="primary"
                                variant="outlined"
                            />
                        )}
                        {marketPlaceFilter.nsfw === 'true' && (
                            <Chip
                                size='small'
                                className='selected-filters-icon'
                                label="NSFW Art"
                                onDelete={() => {
                                    setFilter({
                                        ...marketPlaceFilter,
                                        nsfw: 'false',
                                    });
                                }}
                                color="primary"
                                variant="outlined"
                            />
                        )}
                        {marketPlaceFilter.curatorsPick === 'true' && (
                            <Chip
                                size='small'
                                className='selected-filters-icon'
                                label="Curator's Pick"
                                onDelete={() => {
                                    setFilter({
                                        ...marketPlaceFilter,
                                        curatorsPick: 'false',
                                    });
                                }}
                                color="primary"
                                variant="outlined"
                            />
                        )}
                        <Chip size='small'
                            className='selected-filters-icon'
                            variant="outlined"
                            label='Clear All' onClick={() => {
                                clearFilter(marketFilterList);
                            }} />

                        {/* <AppTeritaryButton
                        title="Clear All"
                        className="btn-small selected-filters-clear"
                        onClick={() => {
                            clearFilter(marketFilterList);
                        }}
                    />
                     */}
                    </Box>
                )}
        </Box>
    );
};

const mapStateToProps = (state: AppState): any => ({
    marketPlaceFilter: state.marketPlaceFilter,
    marketFilterList: state.MarketFilterList
});

const mapDispatchToProps = (dispatch: any): any => ({
    setFilter: (data: any) => dispatch(MarketPlaceActions.setFilter(data)),
    clearFilter: (data: any) => dispatch(MarketPlaceActions.clearFilter(data)),
    resetAllFilter: (data: any) => dispatch(MarketPlaceActions.resetAllFilter(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectedFilter);
