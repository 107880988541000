import {
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    TablePagination,
    Box,
    Avatar,
    Typography,
    Divider,
    IconButton,
    withStyles,
    createStyles,
    Grid,
    Tabs,
    Tab,
    CircularProgress,
    Chip
} from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';

import { useSelector } from 'react-redux';

import ReceiptIcon from '@material-ui/icons/Receipt';
import { ReactElement, useEffect, useState } from 'react';
import moment from 'moment';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { ProfileService, DefaultPagination, CollectionService } from '../../../services';
import { Toast, Utils } from '../../../shared/helpers';
import WithdrawReqSkeleton from '../../../shared/skeletons/WithdrawReqSkeleton';
import LaunchLive from '../../../shared/components/LaunchIn';
import { CurrencyConvertion } from './WithDrawAmount';
import { AppState } from '../../../state/RootReducer';
import { EmptyPlaceholder } from '../../../shared/components';
// import ProfileEmptyPlaceholder from '../../../shared/components/ProfileEmptyPlaceHolder';



export interface ItemsInfo {
    name: string;
    id: string;
    file: string[];
    thumbnail: string;
}

export interface OffersInfo {
    paymentType: string;
    currencyType: string;
    events: string;
    quantity: number;
    amount: number;
    expiriesOn: Date;
    offerAccepted: boolean;
    paymentDoneVia: string;
    offerId: string;
    itemsInfo: ItemsInfo;
    editionQuantity: [];
}

const StyledTableCell = withStyles((): any =>
    createStyles({
        head: {
            backgroundColor: 'black',
            color: 'white',
            marginBottom: '10px'
        },
        body: {
            fontSize: 14,
        },
    }),
)(TableCell);

const StyledTableBodyCell = withStyles((): any =>
    createStyles({
        root: {
            padding: '10px'
        },
    }),
)(TableCell);


const StyledTableRow = withStyles((): any =>
    createStyles({
        root: {
            borderBottom: '1px solid #e0e0e0'
        },
    }),
)(TableRow);

const MyOffers = (): ReactElement => {
    const [buyOfferList, setBuyOfferList] =useState([]);
    const [sellOfferList, setSellOfferList] =useState([]);
    const [buyerPagination, setBuyerPagination] = useState({ ...DefaultPagination(10) });
    const [sellerPagination, setSellerPagination] = useState({ ...DefaultPagination(10) });
    const [loader, setLoader] = useState(true);
    const [btnLoading,setBtnloading] = useState(false);
    const [activeOffer, setActiveOffer] = useState('');
    const convertion: CurrencyConvertion = useSelector(
        (state: AppState) => state.applicationState.currencyConverstion
    );

    const navigate = useNavigate();

    
    const ethPrice = (price: number): any => {
        if (convertion.to.amount !== '0' && price) {
            const usdValue = parseFloat(convertion.to.amount);
            return (price / usdValue).toFixed(3);
        }
        return 0
    }

    const loadBuyerOffers = (): void => {
        setLoader(true)
        ProfileService.getOffersList(
            'buyer',  
            buyerPagination.limit,
            buyerPagination.offset
        ).then(res=>{
            console.log(res.data.data.offers,"res");
            setBuyOfferList(res.data.data.offers)
            setBuyerPagination(res.data.data.pagination)
            setLoader(false)
        })
    }

    const loadSellerOffers = (): void => {
        setLoader(true)
        ProfileService.getOffersList(
            'seller',
            sellerPagination.limit,
            sellerPagination.offset
        ).then(res=>{
            console.log(res,"seller")
            setSellOfferList(res.data.data.offers)
            setSellerPagination(res.data.data.pagination)
            setLoader(false)
    
        })
    }
   
    // API Calls
    const loadOffers = (): void => {
        loadBuyerOffers()
        loadSellerOffers()
    };
    const offerChoose = (offerId:any ,Option:string):void => {
        setBtnloading(true)
        setActiveOffer(offerId)
        CollectionService.acceptOrReject(offerId,Option).then(res=>{
            Toast.showSuccesMessage(`Successfully ${Option}ed`)
            setBtnloading(false)
            setActiveOffer('')
            loadSellerOffers()
        }).catch(error=>{
            setBtnloading(false)
            setActiveOffer('')
            Utils.handleErrorResponse({ error });
        })
    }
    // Hooks
    useEffect(() => {
        loadOffers();
    }, []);

    // UI Actions
    const actionHandleSellerLimitChange = (event: any): void => {
        const rowsPerPage = event.target.value;
        sellerPagination.limit = parseInt(rowsPerPage, 10);
        loadSellerOffers();
    };

    const actionHandleSellerPageChange = (event: any, newPage: number): void => {
        sellerPagination.offset = newPage + 1;
        loadSellerOffers();
    };
    const actionHandleBuyerLimitChange = (event: any): void => {
        const rowsPerPage = event.target.value;
        buyerPagination.limit = parseInt(rowsPerPage, 10);
        loadBuyerOffers();
    };

    const actionHandleBuyerPageChange = (event: any, newPage: number): void => {
        buyerPagination.offset = newPage + 1;
        loadBuyerOffers();
    };

    const [value, setValue] = useState('1');

    const handleChange = (event:any, newValue: any):any => {
      setValue(newValue);
    };


    const actionToItem = (id: any): any => {
        navigate(`/item/${id}`)
    }
    // UI Elements
    return (
        <>
            <Box className="profile-content">
                <Box className="card-header-btn view-profile">
                    <Typography className="title" variant="h3">
                        My Offers
                    </Typography>
                </Box>
                <Divider className='border-content '/>
                <Typography variant="body1" className="subtitle">
                    You can view all offers on your NFTs here.
                </Typography>
                <Box className='mt-12'>
                {loader === true && <WithdrawReqSkeleton />}
                {loader === false &&  <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                    <Tab label="Requested To" value="1" />
                    <Tab label="Requested By" value="2" />
                    </TabList>
                </Box>
                <TabPanel value="1">

                {buyOfferList.length === 0 && loader === false && (
                        <EmptyPlaceholder
                            icon={<ReceiptIcon />}
                            message="No Requested to list found"
                        />
                )}
                {loader === false && buyOfferList.length > 0 && (
                        <>
                            <TableContainer>
                                <Table stickyHeader className="striped-table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                Name
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                Item Logo
                                            </TableCell>
                                            <TableCell>
                                                From
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                To
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                Status
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                Price
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                Quantity
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                Created At
                                            </TableCell>
                                          
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {buyOfferList.map(
                                            (offer, index) => (
                                                <TableRow
                                                    hover
                                                    style={{cursor:'pointer'}}
                                                    className="f13 exo2-regular"
                                                    key={offer.offerId}
                                                    onClick={() => actionToItem(offer.itemInfo.itemId)}

                                                >
                                                    <TableCell>
                                                     {offer.itemInfo.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Avatar
                                                            alt='L'
                                                            src={
                                                                Utils.getUrl(offer.itemInfo.thumbnail)
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        component="td"
                                                        scope="row"
                                                    >
                                                        <Link className='text-underline' to={`/${offer?.requestedBy?.type}/${offer?.requestedBy?.userId}`}>
                                                                                                @{
                                                                                                    offer?.requestedBy?.userName
                                                                                                }
                                                        </Link>   
                                                     </TableCell>
                                                    <TableCell
                                                        className="type"
                                                        component="td"
                                                        scope="row"
                                                    >
                                                        <Link className='text-underline' to={`/${offer?.requestedTo?.type}/${offer?.requestedTo?.userId}`}>
                                                                                                @{
                                                                                                    offer?.requestedTo?.userName
                                                                                                }
                                                        </Link> 
                                                    </TableCell>
                                                    <TableCell
                                                        className="text-case"
                                                        component="td"
                                                        scope="row"
                                                    >
                                                     {offer.status}
                                                    </TableCell>
                                                    <TableCell
                                                        className="text-case"
                                                        component="td"
                                                        scope="row"
                                                    >
                                                      {offer.amount} {offer.currencyType}
                                                    </TableCell>
                                                    <TableCell
                                                        className="text-case"
                                                        component="td"
                                                        scope="row"
                                                    >
                                                      {offer.quantity}
                                                    </TableCell>
                                                    <TableCell
                                                        className="text-case"
                                                        component="td"
                                                        scope="row"
                                                    >
                                                    {
                                                        moment(offer.createdAt)
                                                        .format('DD MMM YYYY h:mm a')
                                                    }
                                                    </TableCell>
                                                   
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                component="div"
                                count={buyerPagination.total}
                                onPageChange={actionHandleBuyerPageChange}
                                onRowsPerPageChange={actionHandleBuyerLimitChange}
                                page={buyerPagination.offset - 1}
                                rowsPerPage={buyerPagination.limit}
                                rowsPerPageOptions={[5, 10, 25]}
                            />
                        </>
                  )}
                </TabPanel>
                <TabPanel value="2">
                {sellOfferList.length === 0 && loader === false && (
                        <EmptyPlaceholder
                            icon={<ReceiptIcon />}
                            message="No Requested by list found"
                        />
                )}
                {loader === false && sellOfferList.length > 0 && (
                        <>
                            <TableContainer>
                                <Table stickyHeader className="striped-table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                Name
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                Item Logo
                                            </TableCell>
                                            <TableCell>
                                                From
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                To
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                Status
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                Price
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                Quantity
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                Created At
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                Expire At
                                            </TableCell>
                                            <TableCell>
                                                Action
                                            </TableCell>
                                          
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sellOfferList.map(
                                            (offer, index) => (
                                                <TableRow
                                                    style={{cursor:'pointer'}}
                                                    className="f13 exo2-regular"
                                                    key={offer.offerId}

                                                >
                                                    <TableCell  
                                                      className='text-underline'
                                                     style={{cursor:'pointer'}}   
                                                       onClick={() => actionToItem(offer.itemInfo.itemId)}>
                                                     {offer.itemInfo.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Avatar
                                                            alt='L'
                                                            src={
                                                                Utils.getUrl(offer.itemInfo.thumbnail)
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        component="td"
                                                        scope="row"
                                                    >
                                                        <Link className='text-underline' to={`/${offer?.requestedBy?.type}/${offer?.requestedBy?.userId}`}>
                                                                                                @{
                                                                                                    offer?.requestedBy?.userName
                                                                                                }
                                                        </Link>   
                                                     </TableCell>
                                                    <TableCell
                                                        className="type"
                                                        component="td"
                                                        scope="row"
                                                    >
                                                        <Link className='text-underline' to={`/${offer?.requestedTo?.type}/${offer?.requestedTo?.userId}`}>
                                                                                                @{
                                                                                                    offer?.requestedTo?.userName
                                                                                                }
                                                        </Link> 
                                                    </TableCell>
                                                    <TableCell
                                                        className="text-case"
                                                        component="td"
                                                        scope="row"
                                                    >
                                                     {offer.status}
                                                    </TableCell>
                                                    <TableCell
                                                        className="text-case"
                                                        component="td"
                                                        scope="row"
                                                    >
                                                      {offer.amount} {offer.currencyType}
                                                    </TableCell>
                                                    <TableCell
                                                        className="text-case"
                                                        component="td"
                                                        scope="row"
                                                    >
                                                      {offer.quantity}
                                                    </TableCell>
                                                    <TableCell
                                                        className="text-case"
                                                        component="td"
                                                        scope="row"
                                                    >
                                                      {
                                                        moment(offer.createdAt)
                                                        .format('DD MMM YYYY h:mm a')
                                                      }
                                                    </TableCell>
                                                    <TableCell
                                                        className="text-case"
                                                        component="td"
                                                        scope="row"
                                                    >
                                                        {offer.status === 'open' && new Date(offer.expireAt) > new Date() ? 
                                                            <LaunchLive 
                                                                title="" 
                                                                date={offer.expireAt} 
                                                                isexpiry='true'
                                                            />
                                                        : "-"
                                                    }
                                                    </TableCell>
                                                    <TableCell>
                                                    <Box
                                                
                                                    width="100%"
                                                >
                                                    <>
                                                        {offer.canAccept && (
                                                                <Box width="100%">
                                                                    {btnLoading && activeOffer === offer.offerId ? (
                                                                        <CircularProgress
                                                                            className="mdl-typography--text-center"
                                                                            size={
                                                                                20
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <Chip
                                                                            variant="default"
                                                                            size="small"
                                                                            style={{
                                                                                marginBottom: '6px',
                                                                                background:
                                                                                    'black',
                                                                                borderRadius:
                                                                                    '2px',
                                                                                color: 'white',
                                                                                width: '100%',
                                                                            }}
                                                                            onClick={() => {
                                                                                offerChoose(offer.offerId,"accept")
                                                                                console.log("accept")
                                                                                }}
                                                                            label="Accept"
                                                                        />
                                                                    )}
                                                                </Box>
                                                            )}
                                                    </>
                                                    <>
                                                        {offer?.canAccept && (
                                                            <Box width='100%'>
                                                                {btnLoading && activeOffer === offer.offerId ? (
                                                                        <CircularProgress
                                                                            className="mdl-typography--text-center"
                                                                            size={
                                                                                20
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <Chip
                                                                            variant="default"
                                                                            size="small"
                                                                            style={{
                                                                                marginBottom: '6px',
                                                                                background:
                                                                                    'black',
                                                                                borderRadius:
                                                                                    '2px',
                                                                                color: 'white',
                                                                                width: '100%',
                                                                            }}
                                                                            onClick={() => {
                                                                            offerChoose(offer.offerId,"reject")
                                                                                console.log("reject")
                                                                            }}
                                                                            label="Reject"
                                                                        />
                                                                    )
                                                                }
                                                            </Box>
                                                                
                                                        )}
                                                    </>
                                                    </Box>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                component="div"
                                count={sellerPagination.total}
                                onPageChange={actionHandleSellerPageChange}
                                onRowsPerPageChange={actionHandleSellerLimitChange}
                                page={sellerPagination.offset - 1}
                                rowsPerPage={sellerPagination.limit}
                                rowsPerPageOptions={[5, 10, 25]}
                            />
                        </>
                  )}
                </TabPanel>
                </TabContext>}  
               </Box>     
                    
            
            </Box>
        </>
    );
};
export default MyOffers;
