import { useState, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Typography, Grid, Paper } from '@material-ui/core';
import { AppPrimaryButton } from '../../../shared/components/AppComponents';
import { BlockChain, Toast, Utils } from '../../../shared/helpers';
import { AuthenticationService } from '../../../services';
import { useAuth } from '../hooks/UseAuth';

const CreateWallet = (): ReactElement => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { processLoginResponse } = useAuth();

    // API Calls
    const loginWithMetamask = (loginData: any): any => {
        AuthenticationService.loginWithMetamask(loginData)
            .then((response: any) => {
                setLoading(false);
                processLoginResponse(response);
            })
            .catch((error: any) => {
                setLoading(false);
                Utils.handleErrorResponse({ error });
            });
    };

    const ToastMetaMaskLink = (message: string): ReactElement => (
        <p>
            <Typography>
                {!message?'You need MetaMask wallet to connect. Please first install the extension to continue. If you have just installed MetaMask, please make sure to close and reopen your browser. Still if you are unable to connect, please contact us at info@theupsidespace.com':message}
                <a
                    target="_blank"
                    rel="noreferrer"
                    className="text-underline"
                    href="https://metamask.io/download"
                >
                    {' '}
                </a>{' '}
            
            </Typography>
        </p>
    );

    const createConnection = async (): Promise<any> => {
        setLoading(true);
        BlockChain.setUpMetaMaskForLogin()
            .then((response: any) => {
                AuthenticationService.getRegisterNonce({
                    address: response.address,
                })
                    .then((nonceResponse: any) => {
                        const responseData = nonceResponse.data.data;
                        const { nonce } = responseData;
                        const { type } = responseData;
                        BlockChain.verifySignature(response.address, nonce)
                            .then((verification) => {
                                if (type === 'login') {
                                    loginWithMetamask({
                                        address: verification.publicAddress,
                                        signature: verification.signature,
                                    });
                                } else {
                                    setLoading(false);
                                    navigate('/metamask-signup', {
                                        state: {
                                            publicAddress:
                                                verification.publicAddress,
                                            signature: verification.signature,
                                        },
                                    });
                                }
                            })
                            .catch((error: any) => {
                                setLoading(false);
                                Utils.handleErrorResponse({ error });
                            });
                    })
                    .catch((error: any) => {
                        setLoading(true);
                        Utils.handleErrorResponse({ error });
                    });
            })
            .catch((error: any) => {
                setLoading(false);
                if (error.code || error.message) {
                    Toast.showErrorMessage(ToastMetaMaskLink(error.message));
                } else {
                    Toast.showErrorMessage(error);
                }
            });
    };

    // UI Action
    const actionCreateConnection = (): any => createConnection();

    // UI Elements
    return (
        <Box my={4}>
            <Container>
                <Box
                    height='88vh'
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                >

                    <Grid container className="center-content" spacing={8}>
                        <Grid item xs={12} sm={6} md={5} lg={4}>
                            <Paper className="create-wallet-card">
                                <Box className="wallet-box">
                                    <Typography align="center" variant="h4">
                                        Create your account using Metamask
                                    </Typography>
                                    <img
                                        alt="Email Logo"
                                        src="/images/metamask-logo.png"
                                        width="100"
                                    />

                                    <AppPrimaryButton
                                        className='meta-imp-btn'
                                        title="CONNECT"
                                        isLoading={loading}
                                        loadingText="CONNECTING"
                                        disabled={loading}
                                        onClick={actionCreateConnection}
                                        style={{
                                            width: '8rem',
                                        }}
                                    />
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={5} lg={4}>
                            <Paper className="create-wallet-card">
                                <Box className="wallet-box">
                                    <Typography align="center" variant="h4">
                                        Create your account using Email
                                    </Typography>
                                    <img
                                        alt="Email Logo"
                                        src="/images/emailicon.webp"
                                        width="100"
                                    />

                                    <AppPrimaryButton
                                        className='meta-imp-btn'
                                        title="CREATE"
                                        onClick={() => {
                                            navigate('/signup');
                                        }}
                                        style={{
                                            width: '8rem',
                                        }}
                                    />
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
};

export default CreateWallet;
