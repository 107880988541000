import { ReactElement } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SignupMetaMaskForm } from './components';
import { HeadTag } from '../../shared/components';
import { LocationModal } from '../../shared/models/StateModal';

const SignupMetaMaskFormPage = (): ReactElement => {
    const location = useLocation();
    const navigate = useNavigate();
    const { publicAddress, signature } = location.state as LocationModal;

    // UI Elements
    return (
        <>
            <HeadTag title="Signup" />
            <SignupMetaMaskForm
                walletDetail={{ publicAddress, signature } || navigate('../')}
            />
        </>
    );
};

export default SignupMetaMaskFormPage;
