/* eslint no-underscore-dangle: 0 */
import { ReactElement } from 'react';
import { Typography, Box } from '@material-ui/core';
import { connect } from 'react-redux';
import { AppState } from '../../../state/RootReducer'
import { MarketPlaceActions } from '../../../state/marketplace/Action';
import { MarketPlaceFilterState } from '../../../state/marketplace/Reducer';
import { MarketFilterState } from '../../../state/filter/Reducer';

interface FilterProps {
    marketPlaceFilter: MarketPlaceFilterState | any;
    marketFilterList: MarketFilterState| any;
    setPrice: any;
    setFilter: any;
}

const ExhibitionFilter = (props: any): ReactElement => {
    const {
        marketPlaceFilter,
        marketFilterList,
        setFilter,
    }: FilterProps = props;

    const regionFilterChangeHandler = (event: any): void => {
        const exhibition = marketFilterList.exhibitionList.filter((item: any) => item._id === event.target.value) 
        setFilter({
            ...marketPlaceFilter,
            [event.target.name]: event.target.value,
            'exhibitionName': exhibition[0]?.title 
        });
    }

    return (
        <Box className='region-wrapper'>
            <Typography className="filter-margin">
                Exhibition
            </Typography>
            <Box className='region-form'>
            <Box className="region-form-inner">
                <select 
                    name="exhibition" 
                    className='region-form-control'
                    onChange={regionFilterChangeHandler}
                    value={marketPlaceFilter.exhibition}
                >
                    <option value="" selected>Choose...</option>
                    {marketFilterList.exhibitionList && marketFilterList.exhibitionList.length > 0 && marketFilterList.exhibitionList.map((exhibition: any, key: any) => 
                        <option value={exhibition._id} key={key}>{exhibition.title}</option>
                    )}
                </select>
                </Box>
            </Box>
        </Box>
    )
}

const mapStateToProps = (state: AppState): any => ({
    marketPlaceFilter: state.marketPlaceFilter,
    marketFilterList: state.MarketFilterList,
});

const mapDispatchToProps = (dispatch: any): any => ({
    setFilter: (data: any) => dispatch(MarketPlaceActions.setFilter(data)),
});


export default connect(mapStateToProps, mapDispatchToProps)(ExhibitionFilter);