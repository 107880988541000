import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { ProfileService } from '../../../services';
import { Utils } from '../../../shared/helpers';
import { AppPreference } from '../../../shared/managers';
import { AuthActions } from '../../../state/authentication/Action';
import store from '../../../state/RootReducer';

export const useAuth = (): {
    processLoginResponse: (response: any) => void;
} => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const { origin, redirect } = queryParams;

    const processLoginResponse = (response: any): void => {
        const loginResponse = response.data.data;
        if (!loginResponse.twoFactorEnabled) {
            if (redirect) {
                const url = `${redirect}?token=${loginResponse.refreshToken}`;
                window.location.replace(url);
            } 
            else if(loginResponse.accessToken){
                    AppPreference.setAccessToken(loginResponse.accessToken);
                    AppPreference.setRefreshToken(loginResponse.refreshToken);
                    Utils.handleSuccessResponse(response);
                    ProfileService.getProfileDetailsAndStore();
                    store.dispatch(AuthActions.setLoginStatus(true));
                    const nextPathName = Utils.getNextPath(location);
                    navigate(nextPathName || '/home', { replace: true });
            }
            else{
                Utils.handleSuccessResponse(response);
                if(response.data.data.userData.email.id){
                    navigate('/email-confirmation', {
                        state: {
                            email:response.data.data.userData.email.id,
                        },
                    });
                }
              

            }
        } else if (loginResponse.twoFactorEnabled) {
            const query = origin ? `?origin=${origin}` : '';
            navigate(
                { pathname: `/security-verification`, search: query },
                { state: { tokenId: loginResponse.tokenId } }
            );
        }
    };

    return { processLoginResponse };
};
