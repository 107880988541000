import { Container, Typography, Box, Tooltip, Grid } from '@material-ui/core';
import { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import SubscribeEmail from './subscribeEmail';

const AppFooter = ():ReactElement => {
    // UI Elements
    const [show, setShow] = useState(false)

    const tooltipHandler = (e:any): any => {
        e.preventDefault();
        setShow(!show);
    }

    return (<>
        <Box className="footer-wrapper">
            <Container>
                <Grid container spacing={1}>
                    <Grid item style={{ width: '100%' }} sm={12} md={12} lg={6} xl={6}>
                        <Box mb={4}>
                            <Box ml={-2}>
                                <img
                                    style={{ width: '160px' }}
                                    className="app-logo cursor"
                                    src="/images/TUSwhite.svg"
                                    alt="logo"
                                />
                            </Box>
                            <Grid item style={{ width: '100%' }} sm={12} md={10} lg={10} xl={10}>
                                <SubscribeEmail />
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item style={{ width: '100%' }} sm={12} md={12} lg={2} xl={2}>
                        <Box className="link-section">
                            <Typography variant="body1" className="link-title">
                                Resources
                            </Typography>
                            <Link to='/faq/general-information'>
                                <Typography variant="body1" className="footer-link">
                                    FAQs
                                </Typography>
                            </Link>
                            <a
                                // target="_blank"
                                href="https://community.theupsidespace.com/"
                                rel="noreferrer"
                            >
                                <Typography variant="body1" className="footer-link">
                                    Community
                                </Typography>
                            </a>
                        </Box>
                    </Grid>
                    <Grid item style={{ width: '100%' }} sm={12} md={12} lg={2} xl={2}>
                        <Box className="link-section">
                            <Typography variant="body1" className="link-title">
                                Company
                            </Typography>
                            <Link to='/contact'>
                                <Typography variant="body1" className="footer-link">
                                    Contact Us
                                </Typography>
                            </Link>
                            <Link to='/privacy'>
                                <Typography variant="body1" className="footer-link">
                                    Privacy Policy
                                </Typography>
                            </Link>
                            <Link to='/terms'>
                                <Typography variant="body1" className="footer-link">
                                    Terms & Conditions
                                </Typography>
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item style={{ width: '100%' }} sm={12} md={12} lg={2} xl={2}>
                        <Box className="subscription-section">
                            <Typography variant="body1" className="link-title">
                                Follow Us
                            </Typography>
                            <Box className="policy-links-section">
                                <Box
                                    display='flex'
                                    flexDirection='row'
                                // justifyContent='spa/'
                                >
                                    <Box mr={4}>
                                        <a
                                            rel="noreferrer"
                                            href="https://www.facebook.com/theupsidespace"
                                            target="_blank"
                                        >
                                            <img src="/icons/facebookv.svg" alt="facebook" />
                                        </a>
                                    </Box>
                                    <Box mr={4}>
                                        <a
                                            rel="noreferrer"
                                            href="https://twitter.com/TheUpsideSpace"
                                            target="_blank"
                                        >
                                            <img src="/icons/_twitter.svg" alt="twitter" />
                                        </a>
                                    </Box>
                                    <Box mr={4}>
                                        <a
                                            rel="noreferrer"
                                            href="https://www.linkedin.com/company/theupsidespace/"
                                            target="_blank"
                                        >
                                            <img src="/icons/_linkedin.svg" alt="linkedin" />
                                        </a>
                                    </Box>
                                    <Box mr={4}>
                                        <a
                                            rel="noreferrer"
                                            href="https://www.instagram.com/theupsidespace/"
                                            target="_blank"
                                        >
                                            <img src="/icons/_instagram.svg" alt="_instagram" />
                                        </a>
                                    </Box>
                                    <Tooltip open={show} arrow title="Coming Soon" leaveTouchDelay={2000}>
                                        <a
                                            rel="noreferrer"
                                            href="https://discord.gg/Egxqx7Tttw"
                                            target="_blank"
                                        >
                                            <img src="/icons/_discord.svg" alt="discord" />
                                        </a>
                                    </Tooltip>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    </>
)};

export default AppFooter;
