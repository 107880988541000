export enum ApplicationReduxActions {
    CRYPTO_CURRENCY_STATUS = 'CRYPTO_CURRENCY_STATUS',
    SET_CATEGORY_LIST = 'SET_CATEGORY_LIST',
    SET_CURRENCY_CONVERSION = 'SET_CURRENCY_CONVERSION',
}

const setCryptoCurrencyStatus = (payload: any): any => ({
    type: ApplicationReduxActions.CRYPTO_CURRENCY_STATUS,
    payload,
});

const setCategoriesList = (payload: any): any => ({
    type: ApplicationReduxActions.SET_CATEGORY_LIST,
    payload,
});

const setCurrencyConverstion = (payload: any): any => ({
    type: ApplicationReduxActions.SET_CURRENCY_CONVERSION,
    payload,
});

export const ApplicationActions = {
    setCryptoCurrencyStatus,
    setCategoriesList,
    setCurrencyConverstion,
};
