import { useState, ReactElement, useEffect } from 'react';
import { Box, Grid, CircularProgress } from '@material-ui/core';
import CollectionsIcon from '@material-ui/icons/Collections';
import { Link, useParams } from 'react-router-dom';
import { EmptyPlaceholder } from '../../shared/components';
import { Utils } from '../../shared/helpers';
import { CollectionService } from '../../services';
import { NFTItem } from '../home/components';

const Collected = (): ReactElement => {
    const [items, setItems] = useState([]);   
    const { userId } = useParams();
    const [loadingItems, setLoadingItems] = useState(false);

    const loadItems = (): void => {
        setLoadingItems(true)
        CollectionService.getArtistItems(userId, "collected")
            .then((response: any) => {
                const { data } = response?.data
                setItems(data)
                setLoadingItems(false)

            })
            .catch((error: any) => {
                setLoadingItems(false);
                Utils.handleErrorResponse({ error });
            })
    }

    useEffect(() => {
        loadItems();
    }, [])
/* eslint no-underscore-dangle: 0 */

    return (
        <Grid container md={12}>
            {!loadingItems && items.length === 0 && (
                <EmptyPlaceholder
                    icon={<img src="/icons/NoData.png" />}
                    message=""
                />
            )}
            {items.length !== 0 && items.map((artistItem: any, index) => (
                <>
                    <Grid xs={12} sm={6} md={4} lg={3} item className="main-my-grid-wrapper main-my-grid-wrapper-art" key={index}>
                        <Grid className='my-grid'>
                            <Link to={`/item/${artistItem._id}`}>
                            <NFTItem {...artistItem} />
                            </Link>
                        </Grid>
                    </Grid>
                </>
            ))}
            {loadingItems && (
                <Box className="empty-content">
                    <CircularProgress />
                </Box>
            )}
        </Grid>
    )

}

export default Collected;