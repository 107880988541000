import { ReactElement, useState } from 'react';
import { connect } from 'react-redux';
import { Utils } from '../../../shared/helpers';
import { AuthActions } from '../../../state/authentication/Action';
import GoogleLoginOption from '../../../shared/components/GoogleLoginOption';
import { AuthenticationService } from '../../../services';
import { useAuth } from '../hooks/UseAuth';

const LoginGoogle = (): ReactElement => {
    const [loadingGoogle, setLoadingGoogle] = useState(false);
    const { processLoginResponse } = useAuth();

    // API Calls
    const google = (googleData: any): any => {
        AuthenticationService.registerWithGoogle(googleData)
            .then((response: any) => {
                setLoadingGoogle(false);
                processLoginResponse(response);
            })
            .catch((error: any) => {
                setLoadingGoogle(false);
                Utils.handleErrorResponse({
                    error,
                });
            });
    };

    // UI Actions
    const actionGoogle = (googleData: any): any => google(googleData);

    // UI Elements
    return (
        <>
            <img src="/images/icon-google.svg" alt="" className='google-img-icon' />

            <GoogleLoginOption
                onClick={actionGoogle}
                loading={loadingGoogle}
                setLoadingGoogle={setLoadingGoogle}
            />
        </>
    );
};

const mapDispatchToProps = (dispatch: any): any => ({
    setLoginStatus: (data: any) => dispatch(AuthActions.setLoginStatus(data)),
});

export default connect(null, mapDispatchToProps)(LoginGoogle);
