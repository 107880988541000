import update from 'immutability-helper';
import { Celebrity } from '../../shared/models/CelebrityModel';
import { MarketPlaceReduxActions } from './Action';

export interface MarketPlaceFilterState {
    category: string;
    query: string;
    buy: boolean;
    onAuction: boolean;
    newItem: boolean;
    hasOffer: boolean;
    notLaunched: boolean;
    crypto: boolean;
    fiat: boolean;
    minPrice: string;
    maxPrice: string;
    absMin: string;
    absMax: string;
    celebrities: Celebrity[];
    blockChain: string[];
    region: string;
    regionName: string;
    exhibition: string;
    exhibitionName: string;
    active: boolean;
    upcoming: boolean;
    apiType: string;
    physicalNFT: any;
    nsfw: any;
    editions: string;
    curatorsPick: boolean;
    assetType: string;
    allArts:boolean;
}

export const defaultMarketPlaceFilter: MarketPlaceFilterState = {
    category: 'All NFTs',
    query: '',
    buy: false,
    newItem: false,
    onAuction: false,
    hasOffer: false,
    notLaunched: false,
    crypto: false,
    fiat: false,
    minPrice: '0',
    maxPrice: '2000',
    absMin: '',
    absMax: '',
    celebrities: [],
    blockChain: ['ethereum', 'polygon'],
    region: '',
    regionName: '',
    exhibition: '',
    exhibitionName: '',
    active: true,
    upcoming: false,
    apiType: '',
    physicalNFT: 'all',
    nsfw: false,
    editions: 'ALL',
    curatorsPick: false,
    assetType: 'all',
    allArts:false
};

const MarketplaceReducer = (
    state = defaultMarketPlaceFilter,
    action: any
): MarketPlaceFilterState => {
    const { payload } = action;
    switch (action.type) {
        case MarketPlaceReduxActions.SET_CATEGORY:
            return update(state, {
                $merge: {
                    category: payload,
                },
            });
        case MarketPlaceReduxActions.SET_PRICE:
            return update(state, {
                $merge: {
                    ...payload,
                },
            });
        case MarketPlaceReduxActions.SET_CELEBRITY:
            return update(state, {
                $merge: {
                    celebrities: payload,
                },
            });
        case MarketPlaceReduxActions.SET_SEARCH:
            return update(state, {
                $merge: {
                    query: payload,
                },
            });
        case MarketPlaceReduxActions.SET_FILTER:
            return update(state, {
                $merge: {
                    ...payload,
                },
            });
        case MarketPlaceReduxActions.CLEAR_FILTER:
            return update(state, {
                $merge: {
                    newItem: false,
                    onAuction: false,
                    buy: false,
                    hasOffer: false,
                    category: 'All NFTs',
                    query: '',
                    notLaunched: false,
                    region: '',
                    regionName: '',
                    exhibition: '',
                    exhibitionName: '',
                    minPrice: payload?.min || '0',
                    maxPrice: payload?.max || '2000',
                    absMin: payload?.min || '',
                    absMax: payload?.max || '',
                    active: true,
                    upcoming: false,
                    apiType: '',
                    physicalNFT: 'all',
                    nsfw: false,
                    editions: 'ALL',
                    curatorsPick: false,
                    assetType: 'all' ,
                    allArts:false
                },
            });
        case MarketPlaceReduxActions.RESET_ALL_FILTER:
            return update(state, {
                $merge: {
                    ...defaultMarketPlaceFilter,
                },
            });
        default:
            return state;
    }
};

export default MarketplaceReducer;
