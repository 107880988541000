import { combineReducers } from 'redux';
import {
    CombinedState,
    configureStore,
    getDefaultMiddleware,
} from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/es/storage';
import AuthStateReducer, { AuthState } from './authentication/Reducer';
import ProfileReducer, { ProfileState } from './profile/Reducer';
import MarketplaceReducer, {
    MarketPlaceFilterState,
} from './marketplace/Reducer';
import ActivityReducer, { ActivityFilterState } from './activity/Reducer';
import ApplicationReducer, {
    ApplicationDataState,
} from './application/Reducer';
import MarketFilterReducer, { MarketFilterState } from './filter/Reducer';

export interface AppState {
    profileInfo: ProfileState;
    authState: AuthState;
    marketPlaceFilter: MarketPlaceFilterState;
    activityFilter: ActivityFilterState;
    applicationState: ApplicationDataState;
    MarketFilterList: MarketFilterState;
}

const appReducer = combineReducers<AppState>({
    authState: AuthStateReducer,
    profileInfo: ProfileReducer,
    marketPlaceFilter: MarketplaceReducer,
    activityFilter: ActivityReducer,
    applicationState: ApplicationReducer,
    MarketFilterList: MarketFilterReducer,
});

const rootReducer = (
    state: AppState | undefined,
    action: any
): CombinedState<AppState> => {
    if (action.type === 'LOGOUT' || action.type === 'RESET_STORE') {
        storage.removeItem('persist:root');
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};

const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [
    ...getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
        thunk: true,
    }),
];

const store = configureStore({
    reducer: persistedReducer,
    middleware,
    devTools: process.env.NODE_ENV !== 'production',
});
export const persistor = persistStore(store);
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export default store;
