import { ReactElement } from 'react';
import { Typography, Box, Divider } from '@material-ui/core';

const HeadSection = (props: any): ReactElement => {
    const { title, subtitle, content } = props;
    return (
        <Box className="flex-column mt-15">
            <Typography variant="h5">{title}</Typography>
            <Typography variant="h2" className="mt-2 playfair-bold">
                {subtitle}
            </Typography>

            <Divider className="my-3" />
            <Typography variant="body1">{content}</Typography>
        </Box>
    );
};

export default HeadSection;
