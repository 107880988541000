import { ReactElement } from 'react';
import { SignupForm } from './components';
import { HeadTag } from '../../shared/components';

const SignUpPage = (): ReactElement => (
    <>
        <HeadTag title="Signup" />
        <SignupForm />
    </>
);

export default SignUpPage;
