
import { Box, Chip, Typography } from '@material-ui/core';
import { ReactElement, useState } from 'react';
import { email } from '../../validators/Schema';
import { Toast } from '../helpers';
import axios from '../managers/AxiosManager';

const SubscribeEmail = (props: any): ReactElement => {
    const [emailId, setEmail] = useState('')
    const handleEmail = (e: any): any => {
        setEmail(e.target.value)
    }

    const SubscribeNow = (): any => {
        axios.post(`${process.env.REACT_APP_BASE_URL}/v1/subscribe`, { email: emailId }).then((response: any) => {
            const { data } = response?.data
            if (data?.subscribed) {
                Toast.showSuccesMessage('🎉 Email has been subscribed ! ')
            } else {
                Toast.showErrorMessage(response?.data?.msg);
            }

        }).catch((error: any) => {
            Toast.showErrorMessage(error.message);
        })
    }

    const handeSubscribe = (e: any): any => {
        email.isValid(emailId).then((res: any) => {
            if (res) {
                SubscribeNow()
            } else {
                Toast.showErrorMessage('Please add proper email');
            }
        }).catch((error: any) => {
            Toast.showErrorMessage(error.message);
        })
    }

    return (
        <Box>
            <Typography style={{ color: '#fff', paddingBottom: '4px' }}>Subscribe To Our Newsletter for Updates And Special Offers!</Typography>
            <Box
                // style={{ border: '1.5px solid #FFFFFF' }}
                display='flex'
                justifyContent='start'
                alignItems='center'
                className='subscribe-wrapper'
            >
                <input required
                    className='subscribe_input'
                    type='email'
                    onChange={handleEmail}
                    name='email'
                    placeholder='Enter your email' />
                    <img src='/images/subscibe-left-img.png' alt='border-left-style' />
                <Chip label='Subscribe'
                    onClick={handeSubscribe}
                    className='subscribe-btn'
                    style={{
                        marginTop:'0px'
                    }} />
            </Box>
        </Box>


    )
}

export default SubscribeEmail;