import ApiManager from '../shared/managers/APIManager';
import { RestEnds } from '../shared/constants';

const getLandDetails = async (longitude: string, latitude: string, city: string): Promise<any> => 
    ApiManager.sendGet(RestEnds.LAND_DETAILS(longitude, latitude, city))

const getLandMetaData = async (META_DATA_LINK: string): Promise<any> => 
    ApiManager.sendGet(META_DATA_LINK)

const initiateLandBuy = async (data: any): Promise<any> => 
    ApiManager.sendPost(RestEnds.BUY_LAND_INITIATE, data, true)

const completeBuy = async (data: any): Promise<any> => 
    ApiManager.sendPost(RestEnds.BUY_COMPLETE, data, true)

const buyLandWithFracto = async (data: any): Promise<any> => 
    ApiManager.sendPost(RestEnds.BUY_LAND, data, true)
    
export const LandService = {
    getLandDetails,
    getLandMetaData,
    initiateLandBuy,
    completeBuy,
    buyLandWithFracto,
}
