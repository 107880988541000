import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Grid,
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    IconButton,
    Divider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { EditProfileSchema } from '../../../validators/ProfileSchema';
import { ProfileActions } from '../../../state/profile/Actions';
import { AppState } from '../../../state/RootReducer';
import { ProfileState } from '../../../state/profile/Reducer';
import { Utils } from '../../../shared/helpers';
import {
    AppPrimaryButton,
    AppTeritaryButton,
    AppFormikTextField,
    AppFormikTextArea,
} from '../../../shared/components/AppComponents';
import { ProfileService } from '../../../services';

export interface ViewProfileProps {
    profileInfo: ProfileState;
}

const EditProfileForm = (props: any): ReactElement => {
    const {
        profileInfo,
        updateNameAndBio,
        openProfileModal,
        onCloseProfileModal,
    } = props;
    const navigate = useNavigate();
    const editProfileForm = {
        name: profileInfo.name,
        bio: profileInfo.bio ? profileInfo.bio : '',
    };

    // API Calls
    const saveProfile = (formValues: any, callback: any): any => {
        ProfileService.editProfile(formValues)
            .then((response: any) => {
                Utils.handleSuccessResponse(response);
                callback(true);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({
                    error,
                });
                callback(false);
            });
    };

    // UI Actions
    const actionEditProfile = (formValues: any, formik: any): any => {
        formik.setSubmitting(true);
        saveProfile(formValues, (status: boolean) => {
            if (status) {
                updateNameAndBio(formValues);
                navigate('/profile/view');
                onCloseProfileModal();
            }
            formik.setSubmitting(false);
        });
    };

    const actionOnCancel = (): void => {
        onCloseProfileModal();
    };

    // UI Elements
    return (
        <Dialog disableEscapeKeyDown open={openProfileModal}>
            <DialogTitle>
                <Box className="title-section">
                    <Box className="flex-column">
                        <Typography variant="h4">Edit Profile</Typography>
                        <Box my={1}>
                            <Typography variant="body2">
                                Modify your personal details here.
                            </Typography>
                        </Box>
                    </Box>
                    <IconButton onClick={actionOnCancel}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Divider />
            </DialogTitle>
            <DialogContent>
                <Formik
                    enableReinitialize
                    initialValues={editProfileForm}
                    validationSchema={EditProfileSchema}
                    validateOnMount
                    onSubmit={(formValues, formik) => {
                        actionEditProfile(formValues, formik);
                    }}
                >
                    {({ handleSubmit, isSubmitting, isValid }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid xs={12} item>
                                    <Box component="div" className='fieldset-box'>
                                        <AppFormikTextField
                                            label="Name"
                                            name="name"
                                            type="text"
                                            outerLabel
                                            size="large"
                                        />
                                    </Box>
                                </Grid>
                                <Grid xs={12} item>
                                    <Box component="div" className='fieldset-box'>
                                        <AppFormikTextArea
                                            label="Bio"
                                            placeholde="Bio"
                                            rows={3}
                                            name="bio"
                                            outerLabel
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box
                                display="flex"
                                justifyContent="flex-end"
                                my={2}
                            >
                                <AppTeritaryButton
                                    className="mr-2 cencelBtn"
                                    title="CANCEL"
                                    onClick={actionOnCancel}
                                />

                                <AppPrimaryButton
                                    title="SAVE"
                                    isLoading={isSubmitting}
                                    className='saveBtn'
                                    loadingText="SAVING"
                                    disabled={isSubmitting || !isValid}
                                    onClick={handleSubmit}
                                />
                            </Box>
                        </form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

const mapStateToProps = (state: AppState): any => ({
    profileInfo: state.profileInfo,
});

const mapDispatchToProps = (dispatch: any): any => ({
    updateNameAndBio: (data: any) =>
        dispatch(ProfileActions.updateNameAndBio(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileForm);
