import { ReactElement, useState } from 'react';
import {
    Button,
    CircularProgress,
    TextField,
    Checkbox,
    Typography,
    InputAdornment,
    // InputLabel,
    IconButton,
    Dialog,
    DialogContent,
    DialogActions,
    FormControlLabel,
    Grid,
    Box,
    Select,
    MenuItem,
    FormControl,
    ListItemText,
    Radio,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useField } from 'formik';
import { NavLink } from 'react-router-dom';

export interface ButtonInterface {
    className?: string;
    title: any;
    disabled?: boolean;
    fullWidth?: boolean;
    isLoading?: boolean;
    loadingText?: string;
    endIcon?: any;
    startIcon?: any;
    onClick?: any;
    variant?: 'text' | 'outlined' | 'contained';
    component?: any;
    to?: any;
    activeClassName?: string;
    style?: any;
}

export const AppButton = ({
    className,
    title,
    disabled,
    fullWidth,
    isLoading,
    loadingText,
    endIcon,
    startIcon,
    onClick,
    variant,
    component,
    to,
    activeClassName,
    style,
}: ButtonInterface): ReactElement => {
    const classNames = ['trans-3d-hover'];
    if (className) {
        classNames.push(className);
    }
    return (
        <Button
            type="submit"
            className={classNames.join(' ')}
            disabled={disabled}
            fullWidth={fullWidth}
            startIcon={startIcon}
            endIcon={endIcon}
            onClick={onClick}
            variant={variant || 'text'}
            component={component || Button}
            to={to || ''}
            color="default"
            activeClassName={activeClassName || ''}
            style={style}
        >
            {isLoading && (
                <>
                    <CircularProgress size={25} />
                    <span>{loadingText || 'PROCESSING'}</span>
                </>
            )}
            {!isLoading && <>{title}</>}
        </Button>
    );
};

export const AppPrimaryButton = (props: ButtonInterface): ReactElement => {
    const { className } = props;
    return (
        <AppButton
            {...{ ...props, variant: 'contained' }}
            className={`btn-primary btn-continue ${className}`}
        />
    );
};

export const AppSecondaryButton = (props: ButtonInterface): ReactElement => {
    const { className } = props;
    return (
        <AppButton
            {...{ ...props, variant: 'outlined' }}
            className={`btn-secondary ${className}`}
        />
    );
};

export const AppTeritaryButton = (props: ButtonInterface): ReactElement => {
    const { className } = props;
    return <AppButton {...props} className={`btn-teritary ${className}`} />;
};

export const AppFormikTextField = (props: any): ReactElement => {
    const { label, outerLabel, size, type, inputProps, onChange, placeholder } =
        props;
    const [field, meta] = useField(props);
    return (
        <>
            {outerLabel && <Typography variant="body1">{label}</Typography>}

            <TextField
                className={outerLabel ? 'outer-label auth-input' : 'auth-input'}
                error={Boolean(meta.touched && meta.error)}
                fullWidth
                helperText={meta.touched && meta.error}
                margin="normal"
                name={field.name}
                label={outerLabel ? '' : label}
                placeholder={placeholder || label}
                type={type}
                value={meta.value}
                onBlur={field.onBlur}
                onChange={(event: any) => {
                    field.onChange(event);
                    if (onChange) {
                        onChange(event);
                    }
                }}
                size={size || 'small'}
                InputProps={inputProps}
            />
        </>
    );
};

export const AppFormikPasswordField = (props: any): ReactElement => {
    const { label, size, placeholder } = props;
    const [field, meta] = useField(props);
    const [showPassword, setShowPassword] = useState(false);
    return (
        <TextField
            error={Boolean(meta.touched && meta.error)}
            fullWidth
            className='auth-input auth-password-error'
            helperText={meta.touched && meta.error}
            margin="normal"
            name={field.name}
            label={label}
            placeholder={placeholder}
            type={showPassword ? 'text' : 'password'}
            value={meta.value}
            onBlur={field.onBlur}
            onChange={field.onChange}
            size={size || 'small'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                        className='password-icon-btn'
                            onClick={() => {
                                setShowPassword(!showPassword);
                            }}
                            edge="end"
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export const AppFormikTextArea = (props: any): ReactElement => {
    const { label, outerLabel, readOnly, rows } = props;
    const [field, meta] = useField(props);
    return (
        <>
            {outerLabel && <Typography variant="body1">{label}</Typography>}
            <TextField
                multiline
                rows={rows}
                error={Boolean(meta.touched && meta.error)}
                fullWidth
                helperText={meta.touched && meta.error}
                margin="normal"
                name={field.name}
                label={outerLabel ? '' : label}
                placeholder={label}
                value={meta.value}
                variant="outlined"
                onBlur={field.onBlur}
                onChange={field.onChange}
                InputProps={{
                    readOnly,
                }}
            />
        </>
    );
};

export const AppFormikCheckBox = (props: any): ReactElement => {
    const { label } = props;
    const [field, meta] = useField(props);
    return (
        <>
        <Box className='signup-privacy-box'>
            <Checkbox
                checked={meta.value}
                name={field.name}
                onChange={field.onChange}
                className="primary-text"
            />
            <Typography>{label}</Typography>
            </Box>
            <Typography className='signup-privacy-error'>{meta.touched && meta.error ? meta.error : ''}</Typography>
        </>
    );
}; 
export const AppCheckBox = (props: any): ReactElement => {
    const { label, value, onChange } = props;
    return (
        <FormControlLabel
            label={label}
            control={
                <Checkbox
                    checked={value}
                    onChange={(event: any) => {
                        onChange(event?.target.checked);
                    }}
                    color="primary"
                />
            }
        />
    );
};

export const AppThemeCheckBox = (props: any): ReactElement => {
    const { label, value, onChange } = props;
    return (
        <Grid
            className="app-checkbox"
            container
            onClick={() => {
                onChange(!value);
            }}
        >
            <img
                src={
                    value
                        ? '/icons/icon_checkbox_selected.png'
                        : '/icons/icon_checkbox.png'
                }
            />
            <Typography
                variant="h6"
                className={value ? 'white' : 'text-secondary'}
            >
                {label}
            </Typography>
        </Grid>
    );
};

export const AppNavButton = ({
    title,
    to,
    fullWidth,
    endIcon,
}: any): ReactElement => (
    <Button
        fullWidth={fullWidth}
        className="btn-primary btn-small payment-nft-btn"
        to={to}
        component={NavLink}
        endIcon={endIcon}
    >
        {title}
    </Button>
);
export const AppBigNavButton = ({
    title,
    to,
    fullWidth,
    endIcon,
    id,
}: any): ReactElement => (
    <Button
        fullWidth={fullWidth}
        className="btn-primary"
        to={to}
        component={NavLink}
        endIcon={endIcon}
        id={id}
    >
        {title}
    </Button>
);

export const AppThemeRadioInput = (props: any): ReactElement => {
    const { label, selectedValue, value, onChange } = props;
    return (
        <FormControlLabel
            control={
                <Radio
                className='radio-checkout-btn'
                    value={value}
                    checked={selectedValue === value}
                    onChange={() => {
                        onChange(value);
                    }}
                />
            }
            label={label}
        />
    );
};

export const ConfirmDialog = (props: any): ReactElement => {
    const {
        title,
        subTitle,
        showConfirm,
        cancelTitle,
        successTitle,
        // isPositiveConfirm,
        onSuccess,
        onCancel,
    } = props;

    // Actions
    const actionPositive = (): void => {
        onSuccess();
    };
    const actionNegative = (): void => {
        onCancel();
    };

    // UI Elements
    return (
        <>
            <Dialog fullWidth maxWidth="sm" open={showConfirm}>
                <Box padding={2}>
                    <DialogContent className="dialog-content-container ">
                        <Typography variant="h4">{title}</Typography>
                        <Typography>{subTitle}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <AppTeritaryButton
                            className="btn-small"
                            title={cancelTitle}
                            onClick={actionNegative}
                        />
                        <AppPrimaryButton
                            className="btn-small"
                            title={successTitle}
                            onClick={actionPositive}
                        />
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
};

export const AppSelect = (props: any): ReactElement => {
    const {
        outerLabel,
        label,
        value,
        name,
        items,
        titleKey,
        valueKey,
        onBlur,
        onChange,
        readOnly,
        placeholder,
    } = props;
    return (
        <FormControl variant="outlined" className="select-box" fullWidth>
            {outerLabel && (
                <Typography variant="body1" className="mb-2">
                    {label}
                </Typography>
            )}

            <Select
                value={value}
                onBlur={onBlur}
                name={name}
                onChange={onChange}
                color="primary"
                readOnly={readOnly}
                displayEmpty
                className='kyc-select'
            >
                {placeholder && (
                    <MenuItem disabled value="" className='select-box-area'>
                        <Typography className="text-black">
                            {placeholder}
                        </Typography>
                    </MenuItem>
                )}
                {items.map((item: any, index: number): any => (
                    <MenuItem key={`${name}_${index}`} value={item[valueKey]}>
                        {item[titleKey]}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export const AppSelectCountry = (props: any): ReactElement => {
    const { label, outerLabel, items, readOnly, placeholder } = props;
    const [field, meta] = useField(props);
    return (
        <FormControl variant="outlined" className="select-box  mb-4" fullWidth>
            {outerLabel && (
                <Typography variant="body1" className=" mb-2">
                    {label}
                </Typography>
            )}
            <Select
                value={meta.value}
                onBlur={field.onBlur}
                name={field.name}
                onChange={field.onChange}
                color="primary"
                readOnly={readOnly}
                placeholder={placeholder || label}
                displayEmpty
            >
                {placeholder && (
                    <MenuItem disabled value="">
                        {placeholder}
                    </MenuItem>
                )}
                {items.map((item: any, index: number): any => (
                    <MenuItem key={`${field.name}_${index}`} value={item}>
                        {item}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const SelectPlaceholder = (props: any): ReactElement => {
    const { children } = props;
    return <div className="text-gray">{children}</div>;
};

export const AppFormikMultiSelect = (props: any): ReactElement => {
    const { label, outerLabel, items, readOnly, placeholder } = props;
    const [field, meta] = useField(props);
    return (
        <FormControl variant="outlined" className="select-box mb-4" fullWidth>
            {outerLabel && (
                <Typography variant="body1" className="mb-2">
                    {label}
                </Typography>
            )}
            <Select
                value={meta.value}
                onBlur={field.onBlur}
                name={field.name}
                onChange={field.onChange}
                color="primary"
                readOnly={readOnly}
                placeholder={placeholder || label}
                displayEmpty
                multiple
                renderValue={() =>
                    meta.value.length > 0 ? (
                        meta.value.join(', ')
                    ) : (
                        <SelectPlaceholder>
                            {placeholder || label}
                        </SelectPlaceholder>
                    )
                }
            >
                {placeholder && (
                    <MenuItem disabled value="">
                        {placeholder}
                    </MenuItem>
                )}
                {items.map((item: any, index: number): any => (
                    <MenuItem key={`${field.name}_${index}`} value={item}>
                        <Checkbox
                            color="primary"
                            checked={meta.value.indexOf(item) > -1}
                        />
                        <ListItemText primary={item} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export const NumberPretty = (props: any): ReactElement => {
    const { count } = props;

    const nFormatter = (num: number, digits = 1): any => {
        const lookup = [
            { value: 1, symbol: '' },
            { value: 1e3, symbol: 'k' },
            { value: 1e6, symbol: 'M' },
            { value: 1e9, symbol: 'G' },
            { value: 1e12, symbol: 'T' },
            { value: 1e15, symbol: 'P' },
            { value: 1e18, symbol: 'E' },
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        const item = lookup
            .slice()
            .reverse()
            .find((i) => num >= i.value);
        return item
            ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
            : '0';
    };
    const formattedValue = nFormatter(count);
    return <Typography variant="body1">{formattedValue}</Typography>;
};
