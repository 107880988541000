import { ReactElement } from 'react';
import {
    Typography,
    Grid,
    Container,
    Card,
    CardContent,
    CardActionArea,
    CardMedia,
    Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { HeadTag } from '../../shared/components';

const useStyles = makeStyles({
    root: {
        width: '100%',
        margin: '0 auto',
    },
    media: {
        position: 'relative',
        height: 200,
        backgroundPosition: 'top',
    },
    gradient: {
        position: 'absolute',
        bottom: '-2px',
        height: '40%',
        width: '100%',
        background: 'linear-gradient(transparent, #fff)',
    },
});

const Newsroom = (): ReactElement => {
    const classes = useStyles();
    const newsRooms = [
        {
            mediaName: 'The Economic Times | Tech',
            newsLink:
                'https://economictimes.indiatimes.com/tech/tech-bytes/kamal-haasan-to-launch-his-digital-avatar-in-the-metaverse/articleshow/87571929.cms?from=mdr',
            newsTitle:
                'Kamal Haasan to launch his digital avatar in the metaverse',
            imgUrl: 'https://img.etimg.com/thumb/msid-87571968,width-650,imgsize-30186,,resizemode-4,quality-100/kamal-haasan.jpg',
            imgAlt: 'The Economic Times',
            date: '08 Nov 2021',
        },
        {
            mediaName: 'Adda9',
            newsLink:
                'https://adda9.com/kamal-haasan-becomes-first-indian-actor-to-enter-a-metaverse-to-be-launched-by-TUS-49184.html',
            newsTitle:
                'Kamal Haasan becomes first Indian actor to enter a Metaverse, to be launched by TUS',
            imgUrl: 'https://i2.wp.com/www.bollywoodhungama.com/wp-content/uploads/2021/11/WhatsApp-Image-2021-11-07-at-3.16.08-PM.jpeg?resize=620%2C450&ssl=1',
            imgAlt: 'Adda9',
            date: '07 Nov 2021',
        },
        {
            mediaName: 'E Times',
            newsLink:
                'https://timesofindia.indiatimes.com/entertainment/tamil/movies/news/kamal-haasan-becomes-first-indian-actor-to-enter-a-metaverse/articleshow/87567502.cms?from=mdr',
            newsTitle:
                'Kamal Haasan becomes first Indian actor to enter a metaverse',
            imgUrl: 'https://static.toiimg.com/thumb/msid-87567493,imgsize-76724,width-800,height-600,resizemode-75/87567493.jpg',
            imgAlt: 'E Times',
            date: '07 Nov 2021',
        },
        {
            mediaName: 'Bollywood Hungama News Network',
            newsLink:
                'https://www.bollywoodhungama.com/news/south-cinema/kamal-haasan-becomes-first-indian-actor-enter-metaverse-launched-TUS/',
            newsTitle:
                'Kamal Haasan becomes first Indian actor to enter a Metaverse, to be launched by TUS',
            imgUrl: 'https://stat2.bollywoodhungama.in/wp-content/uploads/2021/11/WhatsApp-Image-2021-11-07-at-3.16.08-PM.jpeg',
            imgAlt: 'Bollywood Hungama News Network',
            date: '07 Nov 2021',
        },
        {
            mediaName: 'Chennai Vision',
            newsLink:
                'https://chennaivision.com/kamal-haasan-is-making-a-new-incarnation-in-the-virtual-online-world/',
            newsTitle:
                'மெய்நிகர்ஆன்லைன் உலகில் புதிய அவதாரமெடுக்கிறார் கமல்ஹாசன்',
            imgUrl: 'https://i2.wp.com/chennaivision.com/wp-content/uploads/2021/11/cv-post-41.png?fit=780%2C550&ssl=1',
            imgAlt: 'Chennai Vision',
            date: '07 Nov 2021',
        },
        {
            mediaName: 'United News of India',
            newsLink:
                'http://www.uniindia.com/~/kamal-haasan-becomes-first-indian-actor-to-enter-metaverse-to-be-launched-by-TUS/States/news/2555926.html',
            newsTitle:
                'Kamal Haasan becomes first Indian actor to enter  Metaverse, to be launched by TUS',
            imgUrl: 'https://stat2.bollywoodhungama.in/wp-content/uploads/2021/11/WhatsApp-Image-2021-11-07-at-3.16.08-PM.jpeg',
            imgAlt: 'United News of India',
            date: '07 Nov 2021',
        },
    ];
    return (
        <>
            <HeadTag
                title="Newsroom"
                description="India's first curated celebrity NFT & fan engagement platform. Own a bollywood memorabilia from TUS. Get Super rare collectibles from the field of art, music, films and sports. "
            />
            <div id="newsroom">
                <Container
                    maxWidth={false}
                    disableGutters
                    style={{ position: 'relative' }}
                >
                    <div className="gradient-bg">
                        <img
                            src="/images/newsroom-banner-new.jpeg"
                            className="newsroom-banner"
                            alt=""
                        />
                    </div>
                    <div className="headingContianer">
                        <Typography variant="h1"> Newsroom </Typography>
                    </div>
                </Container>

                <Container style={{ margin: '100px auto' }}>
                    <Grid container spacing={4}>
                        <Grid xs={12} sm={12} item className="">
                            <Typography variant="h2">Press Releases</Typography>
                        </Grid>
                        {newsRooms.map((news) => (
                            <Grid xs={12} md={6} lg={4} item className="">
                                <Card className={classes.root}>
                                    <CardActionArea>
                                        <Link
                                            href={news.newsLink}
                                            target="_blank"
                                            underline="none"
                                        >
                                            <CardMedia
                                                className={classes.media}
                                                image={news.imgUrl ? news.imgUrl : '/images/if-no-image.png'}
                                                title={news.imgAlt}
                                            >
                                                <div
                                                    className={classes.gradient}
                                                />
                                            </CardMedia>
                                            <CardContent>
                                                <Typography
                                                    variant="body1"
                                                    className="card-header"
                                                >
                                                    {news.mediaName}
                                                </Typography>
                                                <Typography
                                                    variant="h4"
                                                    className="card-text"
                                                >
                                                    {news.newsTitle}
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    className="card-date"
                                                >
                                                    {news.date}
                                                </Typography>
                                            </CardContent>
                                        </Link>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}

                        <Grid xs={12} md={6} lg={4} item className="">
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <Link
                                        href="https://m.dailyhunt.in/news/india/hindi/shriyamnews-epaper-dh93a9073106364ed6a383fe424c236838/-newsid-dh93a9073106364ed6a383fe424c236838_cf5b63e7c57c41098870a5ac79e13097?s=a&uu=0x5da553bf897937b1&ss=pd"
                                        target="_blank"
                                        underline="none"
                                    >
                                        <CardMedia
                                            className={classes.media}
                                            image="https://assets-news-bcdn.dailyhunt.in/cmd/resize/2124x2470_90/fetchdata16/images/6c/54/d0/6c54d01c54996bbcf70b1da2b133e4d9bb96933dbc833dcfe1f0487f20a07350.jpg"
                                            title="BW Disrupt - Business World"
                                        >
                                            <div className={classes.gradient} />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography className="card-header">
                                                Dailyhunt
                                            </Typography>
                                            <Typography className="card-text">
                                                फैंटिको ने बिग बी के 79वें
                                                जन्‍मदिन पर ‘शहंशाह’ की गाथा को
                                                जीवंत किया
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className="card-date"
                                            >
                                                12 Oct 2021
                                            </Typography>
                                        </CardContent>
                                    </Link>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6} lg={4} item className="">
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <Link
                                        href="https://www.mid-day.com/entertainment/bollywood-news/article/have-you-heard-amitabh-bachchans-costume-up-for-grabs-23196107"
                                        target="_blank"
                                        underline="none"
                                    >
                                        <CardMedia
                                            className={classes.media}
                                            image="https://images.mid-day.com/images/images/2021/oct/Shahenshah-ol_d.jpg"
                                            title="BW Disrupt - Business World"
                                        >
                                            <div className={classes.gradient} />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography className="card-header">
                                                Mid Day
                                            </Typography>
                                            <Typography
                                                variant="h4"
                                                className="card-text"
                                            >
                                                Have you heard? Amitabh
                                                Bachchan's costume up for grabs
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className="card-date"
                                            >
                                                11 Oct 2021
                                            </Typography>
                                        </CardContent>
                                    </Link>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6} lg={4} item className="">
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <Link
                                        href="https://www.adgully.com/TUS-keeps-the-shahenshah-saga-alive-on-big-b-s-79th-birthday-109843.html"
                                        target="_blank"
                                        underline="none"
                                    >
                                        <CardMedia
                                            className={classes.media}
                                            image="https://www.adgully.com/img/400/202110/shaheshaa.png.jpg"
                                            title="BW Disrupt - Business World"
                                        >
                                            <div className={classes.gradient} />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography className="card-header">
                                                Adgully
                                            </Typography>
                                            <Typography
                                                variant="h4"
                                                className="card-text"
                                            >
                                                TUS keeps the SHAHENSHAH
                                                Saga alive on Big B’s 79th
                                                Birthday
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className="card-date"
                                            >
                                                11 Oct 2021
                                            </Typography>
                                        </CardContent>
                                    </Link>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6} lg={4} item className="">
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <Link
                                        href="https://www.cinebuster.in/exquisite-collectibles-of-big-b-presented-to-viewers-on-his-birthday-by-TUS/"
                                        target="_blank"
                                        underline="none"
                                    >
                                        <CardMedia
                                            className={classes.media}
                                            image="https://i0.wp.com/www.cinebuster.in/wp-content/uploads/2021/10/IMG_5440-1.jpg?w=1280&ssl=1"
                                            title="BW Disrupt - Business World"
                                        >
                                            <div className={classes.gradient} />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography className="card-header">
                                                Cinebuster
                                            </Typography>
                                            <Typography
                                                variant="h4"
                                                className="card-text"
                                            >
                                                Exquisite collectibles of Big B
                                                presented to viewers on his
                                                birthday by TUS!
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className="card-date"
                                            >
                                                10 Oct 2021
                                            </Typography>
                                        </CardContent>
                                    </Link>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6} lg={4} item className="">
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <Link
                                        href="https://mediabrief.com/TUS-nft-shahenshah-saga-bigb/"
                                        target="_blank"
                                        underline="none"
                                    >
                                        <CardMedia
                                            className={classes.media}
                                            image="https://mediabrief.com/wp-content/uploads/2021/10/IMAGE-TUS-nft-shahenshah-saga-bigb-MEDIABRIEF.jpg"
                                            title="BW Disrupt - Business World"
                                        >
                                            <div className={classes.gradient} />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography className="card-header">
                                                Media Brief
                                            </Typography>
                                            <Typography
                                                variant="h4"
                                                className="card-text"
                                            >
                                                TUS keeps the ‘Shahenshah’
                                                saga alive on Big B’s 79th
                                                birthday
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className="card-date"
                                            >
                                                9 Oct 2021
                                            </Typography>
                                        </CardContent>
                                    </Link>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6} lg={4} item className="">
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <Link
                                        href="http://filmytown.com/TUS-keeps-the-shahenshah-saga-alive-on-big-bs-79th-birthday/"
                                        target="_blank"
                                        underline="none"
                                    >
                                        <CardMedia
                                            className={classes.media}
                                            image="http://filmytown.com/wp-content/uploads/2021/10/TUS-Shahenshah.jpg"
                                            title="BW Disrupt - Business World"
                                        >
                                            <div className={classes.gradient} />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography className="card-header">
                                                Filmy Town
                                            </Typography>
                                            <Typography
                                                variant="h4"
                                                className="card-text"
                                            >
                                                TUS keeps the ‘SHAHENSHAH’
                                                saga alive on BIG B’s 79th
                                                birthday
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className="card-date"
                                            >
                                                8 Oct 2021
                                            </Typography>
                                        </CardContent>
                                    </Link>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6} lg={4} item className="">
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <Link
                                        href="http://bwdisrupt.businessworld.in/article/Vistas-Media-Capital-To-Expand-Into-Blockchain-Entertainment-Business-With-Launch-Of-TUS/03-08-2021-399137/"
                                        target="_blank"
                                        underline="none"
                                    >
                                        <CardMedia
                                            className={classes.media}
                                            image="http://static.businessworld.in/article/article_extra_large_image/1627973827_l5PVpi_thumbnail_TUS_Logo_Final_04.png"
                                            title="BW Disrupt - Business World"
                                        >
                                            <div className={classes.gradient} />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography className="card-header">
                                                BW Disrupt - Business World
                                            </Typography>
                                            <Typography
                                                variant="h4"
                                                className="card-text"
                                            >
                                                Vistas Media Capital To Expand
                                                Into Blockchain Entertainment
                                                Business With Launch Of TUS
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className="card-date"
                                            >
                                                3 Aug 2021
                                            </Typography>
                                        </CardContent>
                                    </Link>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6} lg={4} item className="">
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <Link
                                        href="https://www.medianews4u.com/vistas-media-capital-to-expand-into-blockchain-entertainment-business-with-launch-of-TUS/"
                                        target="_blank"
                                        underline="none"
                                    >
                                        <CardMedia
                                            className={classes.media}
                                            image="https://www.medianews4u.com/wp-content/uploads/2021/08/TUS.jpg"
                                            title="Medianews4u"
                                        >
                                            <div className={classes.gradient} />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography className="card-header">
                                                Medianews4u
                                            </Typography>
                                            <Typography
                                                variant="h4"
                                                color="textSecondary"
                                                className="card-text"
                                            >
                                                Vistas Media Capital to expand
                                                into blockchain entertainment
                                                business with launch of TUS
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className="card-date"
                                            >
                                                3 Aug 2021
                                            </Typography>
                                        </CardContent>
                                    </Link>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6} lg={4} item className="">
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <Link
                                        href="https://businesstechnologiesdaily.com/vistas-media-capital-to-broaden-into-blockchain-leisure-enterprise-with-launch-of-TUS/"
                                        target="_blank"
                                        underline="none"
                                    >
                                        <CardMedia
                                            className={classes.media}
                                            image="https://static.businessworld.in/article/article_extra_large_image/1627973827_l5PVpi_thumbnail_TUS_Logo_Final_04.png"
                                            title="Business Technologies Daily"
                                        >
                                            <div className={classes.gradient} />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography className="card-header">
                                                Business Technologies Daily
                                            </Typography>
                                            <Typography
                                                variant="h4"
                                                color="textSecondary"
                                                className="card-text"
                                            >
                                                Vistas Media Capital to expand
                                                into blockchain entertainment
                                                business with launch of TUS
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className="card-date"
                                            >
                                                3 Aug 2021
                                            </Typography>
                                        </CardContent>
                                    </Link>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6} lg={4} item className="">
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <Link
                                        href="https://m.dailyhunt.in/news/bangladesh/english/tvnews4u-epaper-tvnews/vistas+media+capital+to+expand+into+blockchain+entertainment+business+with+launch+of+TUS-newsid-n303995846"
                                        target="_blank"
                                        underline="none"
                                    >
                                        <CardMedia
                                            className={classes.media}
                                            image="https://assets-news-bcdn.dailyhunt.in/cmd/resize/400x400_80/fetchdata16/images/f0/0c/91/f00c9123e0af1f1fdf26608c1517bac256d7737d271939820e9b0f64e3eba54e.jpg"
                                            title="Daily Hunt"
                                        >
                                            <div className={classes.gradient} />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography className="card-header">
                                                Daily Hunt
                                            </Typography>
                                            <Typography
                                                variant="h4"
                                                color="textSecondary"
                                                className="card-text"
                                            >
                                                Vistas Media Capital to expand
                                                into blockchain entertainment
                                                business with launch of TUS
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className="card-date"
                                            >
                                                3 Aug 2021
                                            </Typography>
                                        </CardContent>
                                    </Link>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6} lg={4} item className="">
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <Link
                                        href="https://nfttrending.com/vistas-media-capital-to-expand-into-blockchain-entertainment-business-with-launch-of-TUS/"
                                        target="_blank"
                                        underline="none"
                                    >
                                        <CardMedia
                                            className={classes.media}
                                            image="https://nfttrending.com/wp-content/uploads/1627973827_l5PVpi_thumbnail_TUS_Logo_Final_04.png"
                                            title="NFT Trending"
                                        >
                                            <div className={classes.gradient} />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography className="card-header">
                                                NFT Trending
                                            </Typography>
                                            <Typography
                                                variant="h4"
                                                color="textSecondary"
                                                className="card-text"
                                            >
                                                Vistas Media Capital To Expand
                                                Into Blockchain Entertainment
                                                Business With Launch Of TUS
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className="card-date"
                                            >
                                                3 Aug 2021
                                            </Typography>
                                        </CardContent>
                                    </Link>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6} lg={4} item className="">
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <Link
                                        href="https://flipboard.com/article/vistas-media-capital-expands-into-blockchain-entertainment-with-launch-of-fantic/f-e502090782%2Fkhaleejtimes.com"
                                        target="_blank"
                                        underline="none"
                                    >
                                        <CardMedia
                                            className={classes.media}
                                            image="https://ic-cdn.flipboard.com/khaleejtimes.com/5f1c8d9f2726aef346435133da82c9173799d4df/_medium.webp"
                                            title="Flipboard"
                                        >
                                            <div className={classes.gradient} />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography className="card-header">
                                                Flipboard
                                            </Typography>
                                            <Typography
                                                variant="h4"
                                                color="textSecondary"
                                                className="card-text"
                                            >
                                                Vistas Media Capital expands
                                                into blockchain entertainment
                                                with launch of TUS
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className="card-date"
                                            >
                                                3 Aug 2021
                                            </Typography>
                                        </CardContent>
                                    </Link>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6} lg={4} item className="">
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <Link
                                        href="https://odishaexpo.com/vistas-media-capital-expands-into-blockchain-entertainment-with-launch-of-TUS-news/"
                                        target="_blank"
                                        underline="none"
                                    >
                                        <CardMedia
                                            className={classes.media}
                                            image="https://images.khaleejtimes.com/storyimage/KT/20210803/ARTICLE/210809765/AR/0/AR-210809765.jpg&NCS_modified=20210803123409&exif=.jpg"
                                            title="Odisha Expo"
                                        >
                                            <div className={classes.gradient} />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography className="card-header">
                                                Odisha Expo
                                            </Typography>
                                            <Typography
                                                variant="h4"
                                                color="textSecondary"
                                                className="card-text"
                                            >
                                                Vistas Media Capital expands
                                                into blockchain entertainment
                                                with launch of TUS
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className="card-date"
                                            >
                                                3 Aug 2021
                                            </Typography>
                                        </CardContent>
                                    </Link>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6} lg={4} item className="">
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <Link
                                        href="https://www.khaleejtimes.com/business/vistas-media-capital-expands-into-blockchain-entertainment-with-launch-of-TUS"
                                        target="_blank"
                                        underline="none"
                                    >
                                        <CardMedia
                                            className={classes.media}
                                            image="https://images.khaleejtimes.com/storyimage/KT/20210803/ARTICLE/210809765/AR/0/AR-210809765.jpg&MaxW=780&imageVersion=16by9&NCS_modified=20210803123409"
                                            title="Khaleej Times"
                                        >
                                            <div className={classes.gradient} />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography className="card-header">
                                                Khaleej Times
                                            </Typography>
                                            <Typography
                                                variant="h4"
                                                color="textSecondary"
                                                className="card-text"
                                            >
                                                Vistas Media Capital expands
                                                into blockchain entertainment
                                                with launch of TUS
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className="card-date"
                                            >
                                                3 Aug 2021
                                            </Typography>
                                        </CardContent>
                                    </Link>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6} lg={4} item className="">
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <Link
                                        href="https://mediabrief.com/vistas-media-capital-launches-nft-platform-TUS/"
                                        target="_blank"
                                        underline="none"
                                    >
                                        <CardMedia
                                            className={classes.media}
                                            image="https://mediabrief.com/wp-content/uploads/2021/08/Image-Vistas-Media-Capital-launches-NFT-platform-TUS-MediaBrief-1-1.png"
                                            title="Mediabrief"
                                        >
                                            <div className={classes.gradient} />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography className="card-header">
                                                Mediabrief
                                            </Typography>
                                            <Typography
                                                variant="h4"
                                                color="textSecondary"
                                                className="card-text"
                                            >
                                                Vistas Media Capital forays into
                                                blockchain entertainment;
                                                launches NFT platform TUS
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className="card-date"
                                            >
                                                3 Aug 2021
                                            </Typography>
                                        </CardContent>
                                    </Link>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6} lg={4} item className="">
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <Link
                                        href="https://www.techinasia.com/singapore-media-firm-launches-nft-trading-platform-indian-movie-collectibles"
                                        target="_blank"
                                        underline="none"
                                    >
                                        <CardMedia
                                            className={classes.media}
                                            image="https://cdn.techinasia.com/wp-content/uploads/2021/08/1627973482_72ebb29ecd01e7146443577e4688a6d1_v1627973482_xlarge.webp"
                                            title="Tech in Asia"
                                        >
                                            <div className={classes.gradient} />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography className="card-header">
                                                Tech in Asia
                                            </Typography>
                                            <Typography
                                                variant="h4"
                                                color="textSecondary"
                                                className="card-text"
                                            >
                                                Singapore media investment firm
                                                launches NFT trading platform
                                                for Indian movie collectibles
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className="card-date"
                                            >
                                                3 Aug 2021
                                            </Typography>
                                        </CardContent>
                                    </Link>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6} lg={4} item className="">
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <Link
                                        href="https://www.qsarpress.com/%D8%AA%D8%AA%D9%88%D8%B3%D8%B9-vistas-media-capital-%D9%81%D9%8A-%D9%85%D8%AC%D8%A7%D9%84-%D8%AA%D8%B1%D9%81%D9%8A%D9%87-blockchain/"
                                        target="_blank"
                                        underline="none"
                                    >
                                        <CardMedia
                                            className={classes.media}
                                            image="https://images.khaleejtimes.com/storyimage/KT/20210803/ARTICLE/210809765/AR/0/AR-210809765.jpg&NCS_modified=20210803123409&exif=.jpg"
                                            title="QSAR Press"
                                        >
                                            <div className={classes.gradient} />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography className="card-header">
                                                QSAR Press
                                            </Typography>
                                            <Typography
                                                variant="h4"
                                                color="textSecondary"
                                                className="card-text"
                                            >
                                                تتوسع Vistas Media Capital في
                                                مجال ترفيه blockchain
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className="card-date"
                                            >
                                                3 Aug 2021
                                            </Typography>
                                        </CardContent>
                                    </Link>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6} lg={4} item className="">
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <Link
                                        href="https://www.outlookindia.com/newsscroll/vista-media-capital-launches-nft-platform-TUS/2135085"
                                        target="_blank"
                                        underline="none"
                                    >
                                        <CardMedia
                                            className={classes.media}
                                            image=""
                                            title="Outlook-PTI"
                                        >
                                            <div className={classes.gradient} />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography className="card-header">
                                                Outlook-PTI
                                            </Typography>
                                            <Typography
                                                variant="h4"
                                                color="textSecondary"
                                                className="card-text"
                                            >
                                                Vista Media Capital launches NFT
                                                platform TUS
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className="card-date"
                                            >
                                                4 Aug 2021
                                            </Typography>
                                        </CardContent>
                                    </Link>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6} lg={4} item className="">
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <Link
                                        href="https://www.latestly.com/agency-news/latest-news-vista-media-capital-launches-nft-platform-TUS-2711608.html"
                                        target="_blank"
                                        underline="none"
                                    >
                                        <CardMedia
                                            className={classes.media}
                                            image="https://st1.latestly.com/wp-content/uploads/2020/10/news-default-img-380x214.jpg"
                                            title="Latestly"
                                        >
                                            <div className={classes.gradient} />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography className="card-header">
                                                Latestly
                                            </Typography>
                                            <Typography
                                                variant="h4"
                                                color="textSecondary"
                                                className="card-text"
                                            >
                                                Vista Media Capital Launches NFT
                                                Platform TUS
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className="card-date"
                                            >
                                                4 Aug 2021
                                            </Typography>
                                        </CardContent>
                                    </Link>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6} lg={4} item className="">
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <Link
                                        href="https://flipboard.com/article/vista-media-capital-launches-nft-platform-TUS/f-6620d4e918%2Foutlookindia.com"
                                        target="_blank"
                                        underline="none"
                                    >
                                        <CardMedia
                                            className={classes.media}
                                            image="https://ic-cdn.flipboard.com/outlookindia.com/7a49c89f4f6c089d94d866376f7f087c97faea70/_medium.webp"
                                            title="Flipboard"
                                        >
                                            <div className={classes.gradient} />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography className="card-header">
                                                Flipboard
                                            </Typography>
                                            <Typography
                                                variant="h4"
                                                color="textSecondary"
                                                className="card-text"
                                            >
                                                Vista Media Capital launches NFT
                                                platform TUS
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className="card-date"
                                            >
                                                3 Aug 2021
                                            </Typography>
                                        </CardContent>
                                    </Link>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6} lg={4} item className="">
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <Link
                                        href="https://www.devdiscourse.com/news?tag=Vistas%20Media%20Capital"
                                        target="_blank"
                                        underline="none"
                                    >
                                        <CardMedia
                                            className={classes.media}
                                            image="https://www.devdiscourse.com/remote.axd?https://devdiscourse.blob.core.windows.net/imagegallery/29_02_2020_19_18_04_5779125.jpg?width=920&format=webp"
                                            title="Devdiscourse"
                                        >
                                            <div className={classes.gradient} />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography className="card-header">
                                                Devdiscourse
                                            </Typography>
                                            <Typography
                                                variant="h4"
                                                color="textSecondary"
                                                className="card-text"
                                            >
                                                Vista Media Capital launches NFT
                                                platform TUS
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className="card-date"
                                            >
                                                4 Aug 2021
                                            </Typography>
                                        </CardContent>
                                    </Link>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6} lg={4} item className="">
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <Link
                                        href="http://e-rmb.com/2021/08/03/vistas-media-capital-to-expand-into-blockchain-entertainment-business-with-launch-of-TUS-bw-businessworld/"
                                        target="_blank"
                                        underline="none"
                                    >
                                        <CardMedia
                                            className={classes.media}
                                            image=""
                                            title="e-RBM"
                                        >
                                            <div className={classes.gradient} />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography className="card-header">
                                                e-RBM
                                            </Typography>
                                            <Typography
                                                variant="h4"
                                                color="textSecondary"
                                                className="card-text"
                                            >
                                                Vistas Media Capital to expand
                                                into blockchain entertainment
                                                business with launch of TUS
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className="card-date"
                                            >
                                                3 Aug 2021
                                            </Typography>
                                        </CardContent>
                                    </Link>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    );
};

export default Newsroom;
