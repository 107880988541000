import { Typography, Box, Tooltip, CircularProgress } from '@material-ui/core';
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { CollectionService } from '../../../services';
import { MiniVideoPlayer } from '../../../shared/components';
import {
    AppTeritaryButton,
    NumberPretty,
} from '../../../shared/components/AppComponents';
import { Utils, Toast } from '../../../shared/helpers';
import { CurrencyConvertion } from '../../../state/application/Reducer';
import { AuthState } from '../../../state/authentication/Reducer';
import { AppState } from '../../../state/RootReducer';
import MiniAuctionTimer from './MiniAuctionTimer';

export interface PostedBy {
    id: string;
    name: string;
    isPrivate: boolean;
    profilePicture: string;
    blueTick: boolean;
    type: string;
    walletAddress: string;
    address: string;
}

export interface Favourite {
    isFavourite: boolean;
    total: number;
}

export interface NFTInfo {
    currencyType: string;
    itemId: string;
    id: string;
    name: string;
    originalArtist?: string;
    file: string[];
    logo: string;
    thumbnail: string;
    saleType: string;
    price: number;
    availableQuantity: number;
    liveTill?: any;
    postedBy: PostedBy;
    createdBy: PostedBy;
    tag: string;
    itemTagName: string;
    physicalNFT: boolean;
    creatorInfo: PostedBy;
    className: string;
    favourites: Favourite;
    authState: AuthState;
    unFavorite?: any;
    numberOfViews:number;
    phyNFTRequested?:boolean;
    ownedQuantity?: number;
}

const LandItem = (props: NFTInfo): ReactElement => {
    const {
        itemId,
        id,
        thumbnail,
        file,
        availableQuantity,
        name,
        price,
        saleType,
        liveTill,
        postedBy,
        createdBy,
        creatorInfo,
        className,
        favourites,
        authState,
        unFavorite,
        numberOfViews,
        ownedQuantity
    } = props;

    const {
        blueTick,
        isPrivate,
        name: personName,
        profilePicture,
        type,
        walletAddress,
        address,
    } = postedBy || createdBy || creatorInfo;
    const [fav, setFav] = useState(false);
    const [isFav, setIsFav] = useState(false);
    const [favTotal, setFavTotal] = useState(0);
    const [favLoader, setFavLoader] = useState(false);
    const navigate = useNavigate();
    const itemTag = useRef(null);

    const itemContainerWidth = useMemo(() => {
        if (itemTag.current) {
            const element: any = itemTag.current;
            return element.offsetWidth;
        }
        return 300;
    }, [itemTag.current, thumbnail]);

    // Hooks
    useEffect(() => {
        if (favourites) {
            setIsFav(favourites.isFavourite);
            setFavTotal(favourites.total);
            setFav(true);
        }
    }, []);

    const getFavIcon = (): ReactElement => {
        if (favLoader) {
            return <CircularProgress size={20} />;
        }
        if (isFav) {
            return <FavoriteIcon color="primary" />;
        }
        return <FavoriteBorderIcon color="primary" />;
    };
    const convertion: CurrencyConvertion = useSelector(
        (state: AppState) => state.applicationState.currencyConverstion
    );

    const ethPrice = useMemo(() => {
        if (convertion.to.amount !== '0') {
            const usdValue = parseFloat(convertion.to.amount);
            return ` ${(price / usdValue).toFixed(3)} ETH`;
        }
        return '';
    }, [convertion, price]);

    const isPlayableItem = (): any => Utils.isPlayableItem(file);

    const toggleFav = (_itemId: string, _isFav: boolean, e: any): void => {
        e.preventDefault();
        e.stopPropagation();
        if (authState.isUserLoggedIn) {
            if (!favLoader) {
                const data = {
                    itemId: _itemId,
                    favourite: !_isFav,
                };
                setFavLoader(true);
                CollectionService.toggleFavItems(data)
                    .then(() => {
                        if (_isFav) {
                            setIsFav(false);
                            setFavTotal(favTotal - 1);
                        } else {
                            setIsFav(true);
                            setFavTotal(favTotal + 1);
                        }
                        setFavLoader(false);
                        unFavorite(_itemId);
                    })
                    .catch((error: any) => {
                        Utils.handleErrorResponse({ error });
                        setFavLoader(false);
                    });
            }
        } else {
            Toast.showInfoMessage('Login to add favourite');
            navigate('/login', {
                state: { nextPathName: window.location.pathname },
            });
        }
    };

    // Auction status
	const isAuctionLive = (tilltime:any):any => {
		const currenttime = new Date();
		const currentEpochTime = currenttime.getTime();
		const liveTillEpochTime = new Date(tilltime).getTime();
		const isActive= liveTillEpochTime - currentEpochTime;
        if(isActive>0){return "Current Bid"}
        return "Sold At"
	}
    const auctionStatus = isAuctionLive(liveTill)

    // UI Elements
    return (
        <Link to={`/item/${itemId || id}`}>
            <div
                ref={itemTag}
                className={`nft-item trans-3d-hover ${className}`}
            >
                <Box
                    className="item-image"
                    style={{
                        width: `${itemContainerWidth}px`,
                        height: `${itemContainerWidth}px`,
                    }}
                >
                    {true && (
                        <img
                            src={thumbnail}
                            alt="thumbnail_image"
                            style={{
                                width: `${itemContainerWidth}px`,
                                height: `${itemContainerWidth}px`,
                            }}
                        />
                    )}
                    {false && isPlayableItem() && (
                        <MiniVideoPlayer {...props} />
                    )}
                </Box>
                <Box className="item-detail">
                    <Box mt={2} p={2}>
                    <Box display="flex" justifyContent="space-between">
                        <Tooltip arrow title={name}>
                            <Typography variant="body1" className="item-name">
                                {name}
                            </Typography>
                        </Tooltip>

                        {fav && (
                                <>
                                    <AppTeritaryButton
                                        className="btn-small"
                                        title=""
                                        startIcon={getFavIcon()}
                                        onClick={(e: any) =>
                                            toggleFav(itemId || id, isFav, e)
                                        }
                                    />
                                </>
                            )}
                            
                        </Box>

                        <Box className="user-detail" mt={2}>
                            <Tooltip
                                title={isPrivate ? 'Private Account' : ''}
                                placement="right-end"
                            >
                                <Box className="name-section">
                                    <img
                                        className="avatar"
                                        src={
                                            !isPrivate && profilePicture
                                                ? profilePicture
                                                : '/icons/user.png'
                                        }
                                        alt="user_avatar"
                                    />
                                    <Box>
                                        <Box className="fvc">
                                            <Typography
                                                variant="body2"
                                                className="user-name"
                                            >
                                                @{isPrivate
                                                    ? address || walletAddress
                                                    : personName}
                                            </Typography>

                                            {blueTick && (
                                                <img
                                                    className="icon-verified"
                                                    src="/icons/icon_verified.png"
                                                    alt="verify_icon"
                                                />
                                            )}
                                        </Box>

                                        <Typography
                                            variant="body2"
                                            className="user-role"
                                        >
                                            {type}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Tooltip>

                            {/* VIEWS ICONS & COUNT */}
                            <Box className="views">
                            <img className="view" src="/icons/eye-icon.svg"/>
                                    <NumberPretty
                                        count={numberOfViews}
                                    />
                            </Box>
                        </Box>
                    </Box>
                    <Box className="auction-detail">
                        {price> 0 && price ? (
                               <Box className="bid-info" p={2}>
                               <Typography
                                   variant="body2"
                                   className="current-bid-text"
                               >
                                   {saleType === 'auction'
                                       ? auctionStatus
                                       : 'Price'} 
                               </Typography>
                               <Box
                                   display="flex"
                                   flexDirection="column"
                                   alignItems=""
                                   mt={2}
                               >
                                   <Typography
                                       variant="body1"
                                       className="current-bid-value"
                                   >
                                       {price} USD
                                   </Typography>
                                   <Box mt={1} className="eth-price">
                                       <Typography variant="body2" id='eth-text'>
                                           {ethPrice}
                                       </Typography>
                                   </Box>
                               </Box>
                           </Box>
                        ): null}

                        {ownedQuantity && ownedQuantity > 0 ?
                        <>
                            <Box className="stock-info" p={2}>
                                    <Typography
                                    variant="body2"
                                    className=""
                                    >
                                    {ownedQuantity} in stock
                                    </Typography> 
                            </Box>
                        </>
                        : 
                        <>
                            <>
                            {saleType === 'auction' && (
                                <>
                                    <Box className="expire-info">
                                    <Typography
                                        variant="body2"
                                        className="end-value"
                                        mt={2}
                                        component={Box}
                                    >
                                        <MiniAuctionTimer liveTill={liveTill} availableQuantity={availableQuantity} />
                                    </Typography>
                                </Box>
                                </>
                            )}
                            {saleType === 'sale' && (
                                <>
                                {price > 0 && price ?
                                    <Box className="stock-info" p={2}>
                                    { availableQuantity > 0 && availableQuantity ?
                                        <Typography
                                        variant="body2"
                                        className=""
                                        >
                                            {availableQuantity} in stock
                                        </Typography> 
                                        :
                                        <Typography
                                            variant="body1"
                                            className=""
                                        >
                                            SOLD OUT
                                        </Typography>
                                    }
                                    </Box>
                                        :
                                    <Box className="stock-info" p={7.5} m="auto">
                                        <Typography
                                            variant="body2"
                                            className="end-text"
                                            >
                                            Not available for sale currently
                                        </Typography> 
                                    </Box>
                                }
                                </>
                            )}
                            </>
                        </>
                        }
                    </Box>
                </Box>
            </div>
        </Link>
    );
};

const mapStateToProps = (state: AppState): any => ({
    profileInfo: state.profileInfo,
    authState: state.authState,
    appState: state.applicationState,
});

    export default connect(mapStateToProps, null)(LandItem);
