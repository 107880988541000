import { ReactElement } from 'react';
import { HeadTag } from '../../shared/components';
import { MyFavorite } from './components';

const MyNFTsPage = (): ReactElement => (
    <>
        <HeadTag title="My NFTs" />
        <MyFavorite />
    </>
);

export default MyNFTsPage;
