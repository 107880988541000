import 'moment-timezone';
import { ReactElement } from 'react';
import Countdown from 'react-countdown';

const PriceCountDown = (props: any): ReactElement => {
    const { onPriceExpire, expiresIn } = props;
    const countdown = ({ completed, formatted }: any): ReactElement => {
        if (completed) {
            // Render a completed state
            onPriceExpire();
            return <>Loading...</>;
        }
        // Render a countdown
        return (
            <span style={{ color: 'red' }}>

                Price expire in {formatted.minutes}:{formatted.seconds}
            </span>
        );
    };

    return (
        <>
            <Countdown date={expiresIn} renderer={countdown} />
        </>
    );
};
export default PriceCountDown;
