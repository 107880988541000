import { useEffect, useState, ReactElement } from 'react';
import {
    Box,
    TableContainer,
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    TablePagination,
    Typography,
    Avatar,
    Divider,
} from '@material-ui/core';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
// import { loadStripe } from '@stripe/stripe-js';
// import { Elements } from '@stripe/react-stripe-js';
import NFTCourierPayment from './NFTCourierPayment';
import { EmptyPlaceholder } from '../../../shared/components';
import { Utils } from '../../../shared/helpers';
import { AppSecondaryButton } from '../../../shared/components/AppComponents';
import WithdrawReqSkeleton from '../../../shared/skeletons/WithdrawReqSkeleton';
import { DefaultPagination, ProfileService } from '../../../services';

// const publicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
// const stripePromise = loadStripe(publicKey!);

export interface NFTRequestInfo {
    address: {
        name: string;
        line1: string;
        line2: string;
        city: string;
        country: string;
        state: string;
        postcode: string;
        contactNumber: string;
    };
    itemInfo: {
        name: string;
        thumbnail: string;
        file: any;
    };
    requestId: string;
    trackingId: any;
    requestedOn: string;
    courier: any;
    paymentRequired: boolean;
    events: string;
    amount: number;
    paymentIntent: string;
}

const NFTRequestList = (): ReactElement => {
    const [nftRequestList, setNFTRequestList] = useState<NFTRequestInfo[]>([]);
    const [pagination, setPagination] = useState({ ...DefaultPagination(10) });
    const [loader, setloader] = useState(false);
    //  const [isSubmitting, setSubmitting] = useState(false);
    const [showPayDialog, setShowPayDialog] = useState(false);
    const [selectedNFTRequest, setSelectedNFTRequest] =
        useState<NFTRequestInfo>();

    // API Calls
    const loadNFTRequestList = (): any => {
        setloader(true);
        ProfileService.getNFTRequestList(pagination.limit, pagination.offset)
            .then((response: any) => {
                const nftRequest = response.data.data.requestInfo;
                const paginationResponse = response.data.data.pagination;
                setNFTRequestList(nftRequest);
                setPagination(paginationResponse);
                setloader(false);
            })
            .catch((error: any) => {
                setloader(false);
                Utils.handleErrorResponse({
                    error,
                });
            });
    };

    // Hooks
    useEffect(() => {
        loadNFTRequestList();
    }, []);

    // Instance Methods
    const reloadNFTRequestList = (): void => {
        pagination.offset = 1;
        loadNFTRequestList();
    };

    // Child Methods
    const onCloseCourierPaymentForm = (reload: boolean): any => {
        setShowPayDialog(false);
        if (reload) {
            reloadNFTRequestList();
        }
    };

    // UI Actions
    const actionHandleLimitChange = (event: any): any => {
        const rowsPerPage = event.target.value;
        pagination.limit = parseInt(rowsPerPage, 10);
        loadNFTRequestList();
    };

    const actionHandlePageChange = (event: any, newPage: number): any => {
        pagination.offset = newPage + 1;
        loadNFTRequestList();
    };

    // UI Elements
    return (
        <Box className="profile-content">
            <Box className="card-header-btn view-profile">
                <Typography className="title" variant="h3">
                    Physical NFT Requests
                </Typography>
            </Box>
            <Divider className='border-content'/>
            <Box className="profile-card">
                <Typography variant="body1" className="subtitle">
                    You can view all your Physical NFT requests here.
                </Typography>

                <Box mt={5}>
                    {nftRequestList.length === 0 && !loader && (
                        <EmptyPlaceholder
                            icon={<LocalShippingIcon />}
                            message="No Physical NFT requests found"
                        />
                    )}
                    {loader && <WithdrawReqSkeleton />}
                    {loader === false && nftRequestList.length > 0 && (
                        <>
                            <TableContainer>
                                <Table stickyHeader className="striped-table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>S.NO</TableCell>
                                            <TableCell>Item Logo</TableCell>
                                            <TableCell>Item Name</TableCell>
                                            <TableCell>User Name</TableCell>
                                            <TableCell>User Address</TableCell>
                                            <TableCell>Amount</TableCell>
                                            <TableCell>Courier</TableCell>
                                            <TableCell>Tracking ID</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {nftRequestList.map(
                                            (nftRequest: any, index: any) => (
                                                <TableRow
                                                    key={nftRequest.requestId}
                                                >
                                                    <TableCell>
                                                        {(pagination.offset - 1) *
                                                            pagination.limit +
                                                            (index + 1)}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Box
                                                            sx={{
                                                                alignItems:
                                                                    'center',
                                                                display: 'flex',
                                                            }}
                                                        >
                                                            <Avatar
                                                                src={
                                                                    nftRequest
                                                                        .itemInfo
                                                                        .thumbnail
                                                                }
                                                            />
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>
                                                            {
                                                                nftRequest.itemInfo
                                                                    .name
                                                            }
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell>
                                                        <Typography>
                                                            {
                                                                nftRequest.address
                                                                    .name
                                                            }
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell>
                                                        <Typography>
                                                            {
                                                                nftRequest.address
                                                                    .line1
                                                            }
                                                            <br />
                                                            {
                                                                nftRequest.address
                                                                    .line2
                                                            }
                                                            <br />{' '}
                                                            {
                                                                nftRequest.address
                                                                    .city
                                                            }
                                                            <br />
                                                            {
                                                                nftRequest.address
                                                                    .state
                                                            }
                                                            <br />
                                                            {
                                                                nftRequest.address
                                                                    .country
                                                            }
                                                            <br />
                                                            {
                                                                nftRequest.address
                                                                    .postcode
                                                            }
                                                            <br />
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        {nftRequest.amount}{' '}
                                                        {nftRequest.currencyType
                                                            ? nftRequest.currencyType
                                                            : ''}
                                                    </TableCell>
                                                    <TableCell>
                                                        {nftRequest.courier !== null
                                                            ? nftRequest.courier
                                                            : '-'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {nftRequest.trackingId !==
                                                            null
                                                            ? nftRequest.trackingId
                                                            : '-'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {Utils.titleCase(
                                                            nftRequest.events
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {nftRequest.events ===
                                                            'waiting for payment' ? (
                                                            <AppSecondaryButton
                                                                title="Pay"
                                                                className="btn-small"
                                                                loadingText="Processing"
                                                                onClick={() => {
                                                                    setShowPayDialog(
                                                                        true
                                                                    );
                                                                    setSelectedNFTRequest(
                                                                        nftRequest
                                                                    );
                                                                }}
                                                            />
                                                        ) :
                                                            ""
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                component="div"
                                count={pagination.total}
                                onPageChange={actionHandlePageChange}
                                onRowsPerPageChange={actionHandleLimitChange}
                                page={pagination.offset - 1}
                                rowsPerPage={pagination.limit}
                                rowsPerPageOptions={[5, 10, 20, 25]}
                            />
                        </>
                    )}
                </Box>

                {/* <Elements stripe={stripePromise}>
                    <NFTCourierPayment
                        showPayDialog={showPayDialog}
                        actionCloseDialog={onCloseCourierPaymentForm}
                        selectedNFTRequest={selectedNFTRequest}
                    />
                </Elements> */}
            </Box>
        </Box>
    );
};

export default NFTRequestList;
