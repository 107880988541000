export enum ActivityReduxActions {
    SET_ACTIVITY_FILTER = 'SET_ACTIVITY_FILTER',
    CLEAR_FILTER = 'CLEAR_FILTER',
}

const setFilter = (payload: any): any => ({
    type: ActivityReduxActions.SET_ACTIVITY_FILTER,
    payload,
});

const clearFilter = (payload: any): any => ({
    type: ActivityReduxActions.CLEAR_FILTER,
    payload,
});

export const ActivityActions = {
    setFilter,
    clearFilter,
};
