import { useState, ReactElement, useEffect } from 'react';
import { Box, Typography, Tooltip, CircularProgress, CardMedia, IconButton } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { AppTeritaryButton, NumberPretty } from '../../shared/components/AppComponents';
import { Utils, Toast } from '../../shared/helpers';
import { checkLauncDate, ethPrice, getUrl, goesLive } from '../../shared/helpers/Utils';
import { CollectionService } from '../../services';
import { CurrencyConvertion } from '../../state/application/Reducer';
import { AppState } from '../../state/RootReducer';
import DisplayVideoAudioIcon from '../../shared/components/displayVideoAudioIcon';
import EditionNFTCardCount from '../../shared/components/EditionNFTCardCount';

const NFTItem = (props: any): ReactElement => {

    const navigate = useNavigate();
    const [isFav, setIsFav] = useState(null);
    // const [favTotal, setFavTotal] = useState(0);
    const [favLoader, setFavLoader] = useState(false);
    const [item, setItem] = useState("");
    const [heart, setHeart] = useState('/icons/heart-dark.png');
    const convertion: CurrencyConvertion = useSelector(
        (state: AppState) => state.applicationState.currencyConverstion
    );
    const authState = useSelector((state: AppState) => state.authState);

    const { artistItem, itemId } = props;

    const isLaunched = artistItem?.saleInfo[0]?.launchDate ? checkLauncDate(artistItem.saleInfo[0].launchDate) : false;

    const getFavIcon = (): ReactElement => {
        if (favLoader) {
            return <CircularProgress size={20} />;
        }
        if (isFav) {
            // return <FavoriteIcon htmlColor='white' />;
            return <img src="/icons/heart-fill.png" style={{ width: "30px" }} />;
        }
        // return <FavoriteBorderIcon htmlColor='white' />;
        return <img src={heart} onMouseOver={() => setHeart('/icons/heart.png')} onMouseOut={() => setHeart('/icons/heart-dark.png')} onFocus={() => undefined} onBlur={() => undefined} style={{ width: "30px" }} />;
    };

    useEffect(() => {
        setItem(itemId);
        if (artistItem.favourites) {
            setIsFav(artistItem.favourites.isFavourite);
            // setFavTotal(favourites.total);
            // setFav(true);
        }
    }, []);

    const toggleFav = (_isFav: boolean, e: any): void => {
        e.preventDefault();
        e.stopPropagation();
        if (authState.isUserLoggedIn) {
            if (!favLoader) {
                const data = {
                    itemId: item,
                    favourite: !_isFav,
                };
                setFavLoader(true);
                CollectionService.toggleFavItems(data)
                    .then(() => {
                        if (_isFav) {
                            setIsFav(false);
                            // setFavTotal(favTotal - 1);
                        } else {
                            setIsFav(true);
                            // setFavTotal(favTotal + 1);
                        }
                        setFavLoader(false);
                        // unFavorite(_itemId);
                    })
                    .catch((error: any) => {
                        Utils.handleErrorResponse({ error });
                        setFavLoader(false);
                    });
            }
        } else {
            Toast.showInfoMessage('Login to add favourite');
            navigate('/login', {
                state: { nextPathName: window.location.pathname },
            });
        }
    };

    return (
        <div className='nft-item trans-3d-hover'>
            <Box style={{ width: '100%' }} p={2}>
                <Box
                    width='98%'
                    display='flex'
                    justifyContent='center'
                    style={{ position: 'absolute', zIndex: 500 }}>
                    <Box
                        width='94%'
                        display='flex'
                        justifyContent='flex-end'
                    > <IconButton
                        size='small'
                        onClick={(e: any) =>
                            toggleFav(isFav, e)
                        }>
                            {getFavIcon()}
                        </IconButton>
                    </Box>
                </Box>

                <CardMedia
                    className='cardthumbnail'
                    component="img"
                    height="320"
                    image={artistItem?.thumbnail ? getUrl(`${artistItem.thumbnail}`, '900x900/') : '/images/if-no-image.png'}
                    alt="thumbnail_image"
                />
                <EditionNFTCardCount qty={artistItem?.quantity} />
                <DisplayVideoAudioIcon audio={artistItem?.audio} thumbnail={artistItem?.thumbnail} video={artistItem?.video?.length} images={artistItem?.images} />
            </Box>
                  
            <Box className="item-detail">
                <Box className="items-content">
                    <Box display="flex" justifyContent="space-between">
                        <Tooltip arrow title={artistItem.name}>
                            <Typography variant="body1" className="item-name">
                                {artistItem.name}
                            </Typography>
                        </Tooltip>
                    </Box>
                    {/* <Box>

                        {artistItem.favourites && (
                            <>
                                <AppTeritaryButton
                                    className="add-fav"
                                    title=""
                                    style={{ 'position': 'absolute' }}
                                    startIcon={getFavIcon()}
                                    onClick={(e: any) =>
                                        toggleFav(isFav, e)
                                    }
                                />
                            </>
                        )}

                    </Box> */}

                    <Box className="user-detail" mt={2}>
                        <Tooltip
                            title={artistItem.createrId.name}
                            placement="right-end"
                        >
                            <Box className="name-section">
                                <img
                                    className="avatar"
                                    src={artistItem.createrId.profilePicture ? getUrl(`${artistItem.createrId.profilePicture}`, '400x400/') : '/icons/user.png'}
                                    alt="user"
                                />
                                <Box>
                                    <Box className="fvc">
                                        <Typography
                                            variant="body2"
                                            className="user-name"
                                        >
                                            {artistItem.createrId.name}
                                        </Typography>

                                        <img
                                            className="icon-verified"
                                            src="/icons/icon_verified.png"
                                            alt="verify_icon"
                                        />
                                    </Box>

                                    <Typography
                                        variant="body2"
                                        className="user-role"
                                    >
                                        {artistItem.createrId.name}
                                    </Typography>
                                </Box>
                            </Box>
                        </Tooltip>
                        {/* VIEWS ICONS & COUNT */}
                        <Box className="views">
                            <img className="view" src="/icons/visibility.svg" />
                            <NumberPretty
                                count={artistItem.numberOfViews}
                            />
                        </Box>
                    </Box>
                </Box>

                {isLaunched && artistItem?.saleInfo[0]?.price > 0 ? <Box className="auction-detail">
                    <Box className="bid-info" p={2}>

                        <Box
                            display="flex"
                            flexDirection="column"
                            mt={2}
                        >

                            <Typography
                                variant="body1"
                                className="current-bid-value"
                            >
                                {artistItem?.saleInfo[0]?.price} USD
                            </Typography>
                            <Box mt={1} className="eth-price">
                                <Typography variant="body2" id='eth-balance'>
                                    ( {ethPrice(artistItem?.saleInfo[0].price, convertion)} ETH)
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box className="stock-info">
                        <Box className='Artwork-sell'>
                            <Typography className='Artwork-buy-btn'>
                                BUY
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                    :
                    <Box className="auction-detail auction-detail-available">
                        <Box className="stock-info-not-available">
                            <Typography
                                variant="body2"
                                className="end-text"
                            >
                                {artistItem?.saleInfo[0]?.launchDate ? goesLive(artistItem?.saleInfo[0].launchDate) :
                                    'Not available for sale currently'
                                }
                            </Typography>
                        </Box>
                    </Box>
                }
            </Box>
        </div>
    )
};

export default NFTItem;