import { Grid, Box, Typography, Avatar } from '@material-ui/core';
import { useState, useEffect, ReactElement } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import CollectionsIcon from '@material-ui/icons/Collections';
import PanoramaIcon from '@material-ui/icons/Panorama';
import { CollectionService, DefaultPagination } from '../../../services';
import { Utils } from '../../../shared/helpers';
import NFTItem, { NFTInfo } from '../../home/components/NFTItem';
import { AppState } from '../../../state/RootReducer';
import { EmptyPlaceholder, UserInfo } from '../../../shared/components';
import CuratingLoading from '../../../shared/components/curatingLoading';

export interface Item {
    name: string;
    file: string;
    logo: string;
    description: string;
    id: string;
    collectionId: string;
    collectionName: string;
    price: number;
    physicalNFT: boolean;
}

export interface User {
    id: string;
    name: string;
    isPrivate: boolean;
    profilePicture?: any;
    blueTick: boolean;
    walletAddress: string;
}
export interface CollectionInfo {
    name: string;
    logo: string;
    thumbnail: string;
    backgroundPicture?: any;
    description: string;
    featured: boolean;
    collectionId: string;
    user: User;
}

const CreationDetail = (): ReactElement => {
    const [collectionInfo, setCollectionInfo] = useState<CollectionInfo>({
        name: '',
        logo: '',
        thumbnail: '',
        backgroundPicture: '',
        description: '',
        featured: false,
        collectionId: '',
        user: {
            id: '',
            name: '',
            isPrivate: false,
            profilePicture: '',
            blueTick: false,
            walletAddress: '',
        },
    });
    const [items, setItems] = useState<NFTInfo[]>([]);
    const { collectionId } = useParams();
    const [pagination, setPagination] = useState({ ...DefaultPagination(4) });
    const [loader, setLoader] = useState(true);
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [backgroundImage, setbackgroundImage] = useState('');
    const searchQuery = '';

    // API Calls
    const loadCollectionDetail = (): any => {
        setLoader(true);
        CollectionService.getCreationDetail(
            pagination.limit,
            pagination.offset,
            searchQuery,
            'recent',
            collectionId,
            true
        )
            .then((response: any) => {
                const result = response.data.data;
                const paginationResponse = result.pagination;
                setCollectionInfo(result.collectionInfo);
                setPagination(paginationResponse);
                setbackgroundImage(result.collectionInfo.backgroundPicture);
                setLoader(false);
            })
            .catch((error: any) => {
                setLoader(false);
                Utils.handleErrorResponse({
                    error,
                });
            });
    };

    const loadItems = (): any => {
        if (pagination.lastOffset !== pagination.offset) {
            pagination.lastOffset = pagination.offset;
            setLoader(true);
            CollectionService.getNft(
                pagination.limit,
                pagination.offset,
                searchQuery,
                'recent',
                collectionId,
                false
            )
                .then((response: any) => {
                    const newItems = response.data.data.items;
                    const paginationResponse = response.data.data.pagination;
                    if (pagination.offset === 1) {
                        setItems(newItems);
                    } else {
                        setItems(items.concat(newItems));
                    }
                    setHasMoreItems(Utils.hasMoreRecords(paginationResponse));
                    setLoader(false);
                    setPagination({
                        ...paginationResponse,
                        lastOffset: pagination.lastOffset,
                        offset: pagination.offset + 1,
                    });
                })
                .catch((error: any) => {
                    setLoader(false);
                    Utils.handleErrorResponse({ error });
                });
        }
    };

    const loadMoreItems = (): any => {
        loadItems();
    };

    // Hooks
    useEffect(() => {
        loadCollectionDetail();
        loadItems();
    }, []);

    // UI Elements
    return (
        <>
            <Avatar
                variant="square"
                className="creation-bg"
                src={backgroundImage}
            >
                <PanoramaIcon />
            </Avatar>
            <Avatar
                variant="circle"
                className="creation-logo"
                src={collectionInfo.logo}
            >
                <PanoramaIcon />
            </Avatar>
            <div className="creation-info-container">
                <Typography variant="h4">{collectionInfo.name}</Typography>
                <div className="creation-user-info-section">
                    <Typography variant="body2" className="mr-2">
                        by
                    </Typography>
                    <UserInfo
                        {...{
                            ...collectionInfo.user,
                            imgUrl: collectionInfo.user.profilePicture,
                            address: collectionInfo.user.walletAddress,
                        }}
                    />
                </div>

                <Typography variant="body1" className="creation-desc">
                    {collectionInfo.description}
                </Typography>
            </div>
            <br />
            <br /> <hr />
            <Box sx={{ mb: 15, mt: 10 }}>
                <InfiniteScroll
                    threshold={50}
                    pageStart={1}
                    loadMore={loadMoreItems}
                    hasMore={hasMoreItems}
                >
                    {items.length !== 0 && (
                        <Grid container spacing={4}>
                            {items.map((nftItem: NFTInfo) => (
                                <Grid
                                    key={nftItem.id}
                                    item
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                >
                                    <NFTItem
                                        {...{
                                            ...nftItem,
                                            className: 'home-nft',
                                        }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </InfiniteScroll>
                {!loader && items.length === 0 && (
                 <EmptyPlaceholder
                  icon={<img src="/icons/NoData.png" />}
                  />
                )}
                {loader &&<CuratingLoading />}
            </Box>
        </>
    );
};

const mapDispatchToProps = (): any => ({});

const mapStateToProps = (state: AppState): any => ({
    authState: state.authState,
    profileInfo: state.profileInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(CreationDetail);
