import { Typography, Link } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Toast, Utils } from '../helpers';
// import { callConfig } from "../../App";

const CopyLink = (props: any): any => {
    const { text, address } = props;

    // UI Element
    return (
        <CopyToClipboard
            text={text}
            onCopy={() => {
                Toast.showInfoMessage('Copied');
            }}
        >
            <Typography
                className="cursor"
                component={Link}
                
            >
                {address ? Utils.formatedAddress(text) : text}
            </Typography>

        </CopyToClipboard>
    );
};

export default CopyLink;
