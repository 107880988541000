/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export enum ProfileReduxActions {
    SET_PROFILE = 'PROFILE_SET_PROFILE',
    UPDATE_NAME_AND_BIO = 'PROFILE_UPDATE_NAME_AND_BIO',
    UPDATE_2FA_STATUS = 'UPDATE_2FA_STATUS',
    UPDATE_PRIVATE_STATUS = 'UPDATE_PRIVATE_STATUS',
    UPDATE_USERNAME = 'UPDATE_USERNAME',
    UPDATE_SECONDARY_EMAIL = 'UPDATE_SECONDARY_EMAIL',
}

const setProfileData = (payload: any): any => ({
    type: ProfileReduxActions.SET_PROFILE,
    payload,
});

const updateNameAndBio = (payload: any): any => ({
    type: ProfileReduxActions.UPDATE_NAME_AND_BIO,
    payload,
});

const updateSecondaryEmail = (payload: any): any => ({
    type: ProfileReduxActions.UPDATE_SECONDARY_EMAIL,
    payload,
});

const updateUserName = (payload: any): any => ({
    type: ProfileReduxActions.UPDATE_USERNAME,
    payload,
});

const updatePrivateStatus = (payload: any): any => ({
    type: ProfileReduxActions.UPDATE_PRIVATE_STATUS,
    payload,
});

export const ProfileActions = {
    setProfileData,
    updateNameAndBio,
    updatePrivateStatus,
    updateUserName,
    updateSecondaryEmail,
};
