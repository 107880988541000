import { ReactElement } from 'react';
import { HeadTag } from '../../shared/components';
import { NFTRequestList } from './components';

const NFTRequestPage = (): ReactElement => (
    <>
        <HeadTag title="Physical NFT Request" />
        <NFTRequestList />
    </>
);

export default NFTRequestPage;
