import update from 'immutability-helper';
import { ProfileReduxActions } from './Actions';

export interface ProfileState {
    name: string;
    blueTick: boolean;
    bio?: any;
    profilePicture?: string;
    backgroundPicture?: string;
    userId: string;
    userName?: any;
    emailId: string;
    registrationType: string;
    walletAddress: string;
    walletBalance: number;
    followers: number;
    following: number;
    twoFactorSetupCompleted: boolean;
    twoFactorEnabled: boolean;
    isPrivate: boolean;
    secondaryEmailId: string;
    social: any;
    directNFTTransfer: boolean;
    type:string;
}

export const defaultProfileInfo: ProfileState = {
    name: '',
    blueTick: false,
    bio: null,
    profilePicture: '',
    backgroundPicture: '',
    userId: '',
    userName: null,
    emailId: '',
    registrationType: '',
    walletAddress: '',
    walletBalance: 0,
    followers: 0,
    following: 0,
    twoFactorSetupCompleted: false,
    twoFactorEnabled: false,
    isPrivate: false,
    secondaryEmailId: '',
    social: null,
    directNFTTransfer: false,
    type:""
};

const ProfileReducer = (
    state = defaultProfileInfo,
    action: any
): ProfileState => {
    switch (action.type) {
        case ProfileReduxActions.SET_PROFILE:
            return update(state, {
                $merge: {
                    ...action.payload,
                },
            });
        case ProfileReduxActions.UPDATE_NAME_AND_BIO:
            return update(state, {
                $merge: {
                    name: action.payload.name,
                    bio: action.payload.bio,
                },
            });
        case ProfileReduxActions.UPDATE_USERNAME:
            return update(state, {
                $merge: {
                    userName: action.payload,
                },
            });
        case ProfileReduxActions.UPDATE_PRIVATE_STATUS:
            return update(state, {
                $merge: {
                    isPrivate: action.payload,
                },
            });
        case ProfileReduxActions.UPDATE_2FA_STATUS:
            return update(state, {
                $merge: {
                    twoFactorSetupCompleted:
                        action.payload.twoFactorSetupCompleted,
                    twoFactorEnabled: action.payload.twoFactorEnabled,
                },
            });
        case ProfileReduxActions.UPDATE_SECONDARY_EMAIL:
            return update(state, {
                $merge: {
                    secondaryEmailId: action.payload.email,
                },
            });
        default:
            return state;
    }
};

export default ProfileReducer;
