import { RestEnds } from '../shared/constants';
import ApiManager from '../shared/managers/APIManager';

const getCelebrityList = async (
    limit: any,
    offset: any,
    query: string
): Promise<any> =>
    ApiManager.sendGet(RestEnds.CELEBRITY_LIST(limit, offset, query));

const searchItems = async (
    limit: number,
    offset: number,
    sortBy: string,
    query: string
): Promise<any> =>
    ApiManager.sendGet(
        RestEnds.SEARCH_ITEMS(limit, offset, sortBy, query),
        false
    );

const getMarketPlaceItems = async (
    limit: number,
    offset: number,
    sortBy: string,
    query: string,
    category: string,
    minPrice: string,
    maxPrice: string,
    buy: boolean,
    newItem: boolean,
    onAuction: boolean,
    hasOffer: boolean,
    notLaunched: boolean,
    celebrities: string,
    blockChain: string,
    region: string,
    exhibition: string,
    physicalNFT: string,
    nsfw: string,
    editions: string,
    curatorsPick: boolean,
    assetType: string,
    allArts?:boolean
): Promise<any> =>
    ApiManager.sendGet(
        RestEnds.MARKET_PLACE_ITEMS(
            limit,
            offset,
            sortBy,
            query,
            category,
            minPrice,
            maxPrice,
            buy,
            newItem,
            onAuction,
            hasOffer,
            notLaunched,
            celebrities,
            blockChain,
            region,
            exhibition,
            physicalNFT,
            nsfw,
            editions,
            curatorsPick,
            assetType,
            allArts
        ),
        true
    );

const getItemDetail = async (itemId: string ,view?:string): Promise<any> =>
    ApiManager.sendGet(RestEnds.ITEM_DETAIL(itemId,view), true);

const getItemTradingHistory = async (
    itemId: any,
    limit: any,
    offset: any
): Promise<any> =>
    ApiManager.sendGet(
        RestEnds.ITEM_TRADING_HISTORY(itemId, limit, offset),
        true
    );

const createReport = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.CREATE_REPORT, data, true);

const getAllCategory = async (): Promise<any> =>
    ApiManager.sendGet(RestEnds.ALL_CATEGORY(), false);

const getExportNFTFee = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.EXPORT_NFT_FEE, data, true);

const completeNFTExport = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.EXPORT_NFT, data, true);

const initiateTripleANFTExport = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.EXPORT_INITIATE_TRIPLEA, data, true);

const exportPayWithFracto = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.EXPORT_NFT_FRACTO, data, true);

const getOwnedItems = async (limit: any, offset: any, land: any = false ,userId?:string): Promise<any> =>
    ApiManager.sendGet(RestEnds.OWNED_ITEMS(limit, offset, land,userId), true);

const getArtists = async (limit: any, offset: any, query: any, region: any, exhibition: any, sortBy: any): Promise<any> => ApiManager.sendGet(RestEnds.GET_ARTISTS(limit, offset, query, region, exhibition, sortBy),true);

const getTrendingArtists = async (): Promise<any> => ApiManager.sendGet(RestEnds.GET_TRENDING_ARTISTS,true);

const getTrendingArts = async (): Promise<any> => ApiManager.sendGet(RestEnds.GET_TRENDING_ARTS,true);

const getArtistItems = async (userId: any, apiType: any): Promise<any> => ApiManager.sendGet(RestEnds.GET_ARTIST_ITEMS(userId, apiType), true);


const getArtistsDetail = async (limit: any, offset: any,userId?:string): Promise<any> =>
    ApiManager.sendGet(RestEnds.GET_ARTISTS_DETAIL(limit, offset,userId), true);

const getArtistsUserDetail = async (userId:string,view?:string): Promise<any> =>
    ApiManager.sendGet(RestEnds.GET_ARTISTS_USERINFO(userId,view),true);

const getExibitionDetail = async (exhibitionId:string,view?:string): Promise<any> =>
    ApiManager.sendGet(RestEnds.GET_EXIBITION_INFO(exhibitionId,view),true);
    
const toggleFavItems = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.TOGGLE_ITEM_FAV, data, true);

const increaseViewCount = async (data: any): Promise<any> =>
    ApiManager.sendPost(RestEnds.NFT_VIEW_COUNT, data, false);

const getMyCreations = async (
    limit: any,
    offset: any,
    searchTerm: string
): Promise<any> =>
    ApiManager.sendGet(RestEnds.MY_COLLECTION(limit, offset, searchTerm), true);

const getCreationDetail = async (
    limit: any,
    offset: any,
    searchTerm: string,
    sortBy: string,
    collectionId: any,
    collectionData: boolean
): Promise<any> =>
    ApiManager.sendGet(
        RestEnds.COLLECTION_BY_COLLECTIONID(
            limit,
            offset,
            searchTerm,
            sortBy,
            collectionId,
            collectionData
        ),
        true
    );

const getNft = async (
    limit: any,
    offset: any,
    searchTerm: string,
    sortBy: string,
    collectionId: any,
    collectionData: boolean
): Promise<any> =>
    ApiManager.sendGet(
        RestEnds.COLLECTION_BY_COLLECTIONID(
            limit,
            offset,
            searchTerm,
            sortBy,
            collectionId,
            collectionData
        ),
        true
    );

const getUserCreations = async (
    limit: any,
    offset: any,
    userId: string
): Promise<any> =>
    ApiManager.sendGet(
        RestEnds.PORTFOLIO_COLLECTION(limit, offset, userId),
        false
    );

const getUserOnSale = async (
    limit: number,
    offset: number,
    sortBy: string,
    query: string,
    category: string,
    minPrice: string,
    maxPrice: string,
    buy: boolean,
    newItem: boolean,
    onAuction: boolean,
    hasOffer: boolean,
    celebrity: string
): Promise<any> =>
    ApiManager.sendGet(
        RestEnds.PORTFOLIO_ONSALE(
            limit,
            offset,
            sortBy,
            category,
            query,
            minPrice,
            maxPrice,
            buy,
            newItem,
            onAuction,
            hasOffer,
            celebrity
        ),
        true
    );

const getUserCreated = async (
    limit: any,
    offset: any,
    userId: string,
    sortBy: string
): Promise<any> =>
    ApiManager.sendGet(
        RestEnds.PORTFOLIO_CREATED(limit, offset, userId, sortBy),
        true
    );

const getUserNft = async (
    limit: any,
    offset: any,
    userId: string
): Promise<any> =>
    ApiManager.sendGet(RestEnds.PORTFOLIO_ITEMS(limit, offset, userId), true);

const getExibution = async ():Promise<any> =>ApiManager.sendGet(RestEnds.GET_EXIBUTION(),true)  
const getAllexhibition = async (query: string, apiType: string, region: string,limit:number):Promise<any> =>
    ApiManager.sendGet(RestEnds.GET_ALL_EXHIBITION(query,apiType, region,limit) ,true) 
const getAllexhibitionList = async ():Promise<any> =>ApiManager.sendGet(RestEnds.GET_ALL_EXHIBITIONLIST() ,true) 
const getExhibitionList = async (limit: any, offset: any, search:any, type: any, region: any): Promise<any> =>
     ApiManager.sendGet(RestEnds.GET_EXHIBITION_LIST(limit, offset, search, type, region), true)

const getRegions = async (searchingFor: string): Promise<any> => ApiManager.sendGet(RestEnds.REGION_LIST(searchingFor), true)
const getCountries = async (): Promise<any> => ApiManager.sendGet(RestEnds.COUNTRY_LIST, true)

const getCurators = async(limit: any, offset: any, search: any, region: any, type:any, sortBy: any): Promise<any> =>
     ApiManager.sendGet(RestEnds.GET_CURATORS_LIST(limit, offset, search, region, type, sortBy), true)

const getMinMax = async(): Promise<any> => 
    ApiManager.sendGet(RestEnds.ITEM_MIN_MAX, true)

const getLatestAnnouncements = async (): Promise<any>  => ApiManager.sendGet(RestEnds.LATEST_ANNOUNCEMENTS, true)

const getOffersList = async (itemId:any) : Promise<any> => ApiManager.sendGet(RestEnds.OFFER_LISTS(itemId), true)

const acceptOrReject = async (offerId:any,opt:any):Promise<any> =>ApiManager.sendPatch(RestEnds.OFFER_ACCEPT(offerId,opt), {}, true)

export const CollectionService = {
    getItemDetail,
    getCelebrityList,
    searchItems,
    getMarketPlaceItems,
    getItemTradingHistory,
    createReport,
    getAllCategory,
    getExportNFTFee,
    completeNFTExport,
    getOwnedItems,
    toggleFavItems,
    increaseViewCount,
    getMyCreations,
    getCreationDetail,
    getNft,
    getUserCreations,
    getUserNft,
    getUserOnSale,
    getUserCreated,
    exportPayWithFracto,
    getArtists,
    getArtistsDetail,
    getArtistsUserDetail,
    getExibution,
    getExibitionDetail,
    getAllexhibition,
    getAllexhibitionList,
    getTrendingArtists,
    getTrendingArts,
    getRegions,
    getCurators,
    getExhibitionList,
    getArtistItems,
    getMinMax,
    getCountries,
    initiateTripleANFTExport,
    getLatestAnnouncements,
    getOffersList,
    acceptOrReject
};