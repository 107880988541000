import { ReactElement } from 'react';
import { HeadTag } from '../../shared/components';
import { NFTSellInitiate } from './components';

const ItemSellInitiatePage = (): ReactElement => (
    // UI Elements
    <>
        <HeadTag title="Initiate NFT Sell" />
        <NFTSellInitiate />
    </>
);
export default ItemSellInitiatePage;
