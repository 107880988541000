import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { Utils } from '../helpers';

const NameLink = (props: any): ReactElement => {
    const { isPrivate, name, address, id } = props;
    return (
        <>
            {isPrivate && (
                <Typography className="text-primary">
                    {Utils.formatedAddress(address)}
                </Typography>
            )}
            {!isPrivate && (
                <Typography
                    className="text-primary"
                    component={NavLink}
                    to={`/user/${id}/creations`}
                >
                    {name}
                </Typography>
            )}
        </>
    );
};

export default NameLink;
