import { ReactElement, useEffect, useState } from 'react';
import {
    Box,
    Container,
    Grid,
    CircularProgress,
    Typography
} from '@material-ui/core'
import InfiniteScroll from 'react-infinite-scroller';
import CollectionsIcon from '@material-ui/icons/Collections';
import { connect } from 'react-redux';
import LandItem, { NFTInfo } from '../../home/components/LandItem';
import { CollectionService, DefaultPagination } from '../../../services';
import { Utils } from '../../../shared/helpers';
import { AppPrimaryButton } from '../../../shared/components/AppComponents';
import { AppState } from '../../../state/RootReducer';
import { EmptyPlaceholder } from '../../../shared/components';


const MyLands = (props: any): ReactElement => {
    const { profileInfo, actionOpenKyc } = props
    const [items, setItems] = useState<NFTInfo[]>([]);
    const [pagination, setPagination] = useState({ ...DefaultPagination(12) });
    const [loadingItems, setLoadingItems] = useState(false);
    const [hasMoreItems, setHasMoreItems] = useState(false);

    // API Calls
    const loadItems = (reload = false): void => {
        if (pagination.lastOffset !== pagination.offset) {
            if(reload){
                pagination.offset = 1
            }
            pagination.lastOffset = pagination.offset;
            setLoadingItems(true);
            CollectionService.getOwnedItems(pagination.limit, pagination.offset, true)
                .then((response: any) => {
                    const { data } = response.data;
                    const paginationResponse = data.pagination;
                    if (pagination.offset === 1) {
                        setItems(data.items);
                    } else {
                        setItems(items.concat(data.items));
                    }
                    setHasMoreItems(Utils.hasMoreRecords(paginationResponse));
                    setLoadingItems(false);
                    setPagination({
                        ...paginationResponse,
                        lastOffset: pagination.lastOffset,
                        offset: pagination.offset + 1,
                    });
                })
                .catch((error: any) => {
                    setLoadingItems(false);
                    Utils.handleErrorResponse({ error });
                });
        }
    };

    // Instance methods
    const loadMoreItems = (): void => {
        loadItems();
    };

    // Hooks
    useEffect(() => {
        loadItems();
    }, []);

   return (
    <Box my={2}>
        <Container>
            {profileInfo.kycVerified && !loadingItems && items.length === 0 && (
                <EmptyPlaceholder
                    icon={<CollectionsIcon color="primary" />}
                    message="You do not own any NFTs yet"
                />
            )}
            {profileInfo.kycVerified ?
                <InfiniteScroll
                    threshold={0}
                    pageStart={0}
                    loadMore={loadMoreItems}
                    hasMore={hasMoreItems}
                >
                    <Grid container xs={12} spacing={4}>
                        {items.length !== 0 && (
                            <>
                                {items.map((nftItem) => (
                                    <Grid
                                        key={nftItem.id}
                                        item
                                        lg={4}
                                        md={4}
                                        sm={6}
                                        xs={12}
                                    >
                                        <LandItem
                                            {...{
                                                ...nftItem,
                                                className: ' profile-nft',
                                            }}
                                        />

                                    </Grid>
                                ))}
                            </>
                        )}
                    </Grid>
                    {loadingItems && (
                        <Box className="empty-content">
                            <CircularProgress />
                        </Box>
                    )}
                </InfiniteScroll>
            : 
                <Box mx="auto" className='text-center'>
                    <Typography
                        variant="body1"
                        className="my-4"
                    >
                        Complete your KYC to see your Lands.
                    </Typography>
                    <AppPrimaryButton 
                        title="Verify KYC" 
                        onClick={() => actionOpenKyc(1)}
                    />
                </Box>
            }
        </Container>
    </Box>
   ) 
}

const mapDispatchToProps = (): any => ({});

const mapStateToProps = (state: AppState): any => ({
    profileInfo: state.profileInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(MyLands);