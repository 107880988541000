import update from 'immutability-helper';
import { ActivityReduxActions } from './Action';

export interface ActivityFilterState {
    listing: boolean;
    sales: boolean;
    bids: boolean;
    transfer: boolean;
}

export const defaultActivityFilter: ActivityFilterState = {
    listing: false,
    sales: false,
    bids: false,
    transfer: false,
};

const activityReducer = (
    state = defaultActivityFilter,
    action: any
): ActivityFilterState => {
    const { payload } = action;
    switch (action.type) {
        case ActivityReduxActions.SET_ACTIVITY_FILTER:
            return update(state, {
                $merge: {
                    ...payload,
                },
            });
        case ActivityReduxActions.CLEAR_FILTER:
            return update(state, {
                $merge: {
                    listing: false,
                    sales: false,
                    bids: false,
                    transfer: false,
                },
            });

        default:
            return state;
    }
};

export default activityReducer;
