import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Container } from '@material-ui/core';
import { ReactElement } from 'react';
import { HeadTag } from '../../shared/components';
import { CreationDetail } from './components';

const CreationDetailPage = (): ReactElement => {
    const navigate = useNavigate();
    return (
        <>
            <HeadTag title="Creation Detail" />
            {false && (
                <Typography id="back-link">
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<ArrowBackIosIcon />}
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        View Creations
                    </Button>
                </Typography>
            )}
            <Container>
                <CreationDetail />
            </Container>
        </>
    );
};

export default CreationDetailPage;
