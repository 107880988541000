import {
    Typography,
    Grid,
    Box,
    Container,
    CardMedia,
} from '@material-ui/core';
import { Fragment, ReactElement } from 'react';

const NameAndDesignation = (props: any): ReactElement => {
    const { name } = props

    return (
        <Box mb={4}>
            <Typography variant='h2'>{name}</Typography>
        </Box>
    )
}


const ourpartner = [
    {
        logo: '/ourPartner/AlgoBitz.png',
        company: 'AlgoBitz',
        content: 'Headquartered in Singapore, Algobitz is a technology-driven company working predominantly in Blockchain technology and related applications. The team has delivered custom blockchain solutions and consultation to governments, startups, and Fortune 100 companies. Algobitz has specialised in building public sale platforms, crypto exchanges, DeFi solutions, NFT marketplaces and wallets. Most recently, Algobitz has forayed into Gaming and Metaverse development services with its vertical - Algo Studio.'
    },
    {
        logo: '/ourPartner/Amigoo.png',
        company: 'Amigoo',
        content: 'Amigoo is a venture support studio that provides holistic solution-based services for Web3 endeavours. Amigoo specialises in supporting blockchain startups and multinational brands achieve their Web3 goals through growth marketing, community management, fundraising, and corporate structuring. The team consists of experienced entrepreneurs, strategists, marketers, and crypto-enthusiasts.'
    }, {
        logo: '/ourPartner/eDAO.png',
        company: 'eDAO',
        content: 'Incubated by Polygon, eDAO builds immersive infrastructure for Web3 commerce with global brands and creators, as well as art, media, and entertainment IPs. eDAO helps create unique experiences for the largest spectrum of culture.'
    }, {
        logo: '/ourPartner/Makeitwork.png',
        company: 'MakeitWork',
        content: 'MakeitWorkTM is a creative collective, focusing on brand strategy, identity, product design and other digital experiences. We are a team of designers, copywriters and strategic thinkers, opposites with a common objective - to make the most relevant brand experiences.'
    }

]
const AboutPage = (): ReactElement => (
    <div id="aboutPage">
        <Container className="about-page-wrapper-box">
            <Box className="about-grid-text">
                <Typography variant="h3" className="about-page-header">
                    Curating limitless possibilities
                </Typography>
                <Typography variant="body1">
                    <p className="about-paragraph">
                        TheUpsideSpace is a curator-led digital arts platform
                        that spotlights artistic expressions from Southeast
                        Asia, South Asia and the Middle East. Our mission is to
                        build a thriving creative ecosystem of art enthusiasts,
                        new audiences, existing collectors and artists from
                        untapped geographies in one place. NFTs and blockchain
                        are the tools of our vision: Curating Limitless
                        Possibilities.
                    </p>

                    <p className="about-paragraph">
                        TUS was created to be more than an NFT marketplace.
                        TUS’s purpose comes to life as an online art lab, a
                        creator studio, and an inclusive community for discovery
                        and discourse that celebrates both uniqueness and
                        diversity.
                    </p>
                    <p className="about-paragraph">
                        We put creativity at the center, artists at the helm and
                        an innovative tech- culture as the means to unleash
                        value, cultural conversation and change. A new age of
                        art and artistic expression are leading the way .
                    </p>
                    <p className="about-paragraph">Welcome to TheUpsideSpace.</p>
                </Typography>
            </Box>
            <Box mt={10} className="about-title-box works-py">
                <Typography className="about-title">
                    TheUpsideSpace Team
                </Typography>
            </Box>
            <Grid container spacing={4}>
                <Grid item sm={4} md={4} lg={4} style={{ width: '100%' }}>
                    <Box
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Box p={2} width="100%" className="team-img-border">
                            <CardMedia
                                component="img"
                                className="team-image"
                                alt="Lisa Ray"
                                image="/teamImages/Lisa.jpg"
                                height="318px"
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item sm={12} md={8} lg={8}>
                    <NameAndDesignation name="Lisa Ray" />
                    <Typography className="about-paragraph">
                        Lisa Ray is a cross cultural pioneering creative; an
                        award-winning actor, accomplished author and writer,
                        philanthropist, art collector and aficionado,
                        international keynote speaker, and co-founder of TheUpsideSpace
                    </Typography>
                    <Typography className="about-paragraph">
                        In parallel to her career in the creative arts, Lisa has
                        nurtured a passion for contemporary artistic expression
                        in India and across Asia. In the roles of both patron
                        and enthusiast, she has cultivated a relationship with
                        the visual arts realm over a span of three decades.
                        Shortly after relocating to the UAE, Lisa began engaging
                        with the philosophy underpinning Web3, understanding how
                        decentralisation returns autonomy to artists and the
                        promise it holds for artists, curators, and collectors.
                        Currently based in Dubai, she is working on her next
                        book while focusing on building TheUpsideSpace into an
                        inclusive art ecosystem that combines tech with
                        contemporary cultural heritage to break barriers
                        associated with the traditional art ecosystem.
                    </Typography>
                </Grid>
                <Grid item sm={12} md={12} lg={12}>
                    <Box
                        my={10}
                        style={{ background: '#000000' }}
                        height="1px"
                        width="100%"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item sm={4} md={4} lg={4} style={{ width: '100%' }}>
                    <Box
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Box p={2} width="100%" className="team-img-border">
                            <CardMedia
                                component="img"
                                className="team-image"
                                alt="Ayesha Khan"
                                image="/teamImages/Ayesha.png"
                                height="318px"
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item sm={12} md={8} lg={8}>
                    <NameAndDesignation name="Ayesha Khan" />
                    <Typography className="about-paragraph">
                        Ayesha Khan is an art professional, collector,
                        philanthropist and co-founder of TheUpsideSpace. She
                        is based in Singapore Ayesha’s earliest exposure to
                        family heirlooms, collectibles, and vintage cars led to
                        her becoming an accredited and sought-after antique
                        appraiser in South Asia
                    </Typography>
                    <Typography className="about-paragraph">
                        Having lived in numerous countries as an expat
                        encouraged her to investigate how to make regional art
                        in Asia more accessible to a global audience, while
                        solving the barriers to collection. This led to the
                        earliest iterations of ‘TheUpsideSpace’. With the
                        arrival of NFTs, Ayesha was able to blend her
                        professional and personal passions by envisioning a
                        platform that builds a bridge between diverse art
                        practices from untapped geographies and a global
                        audience. As co-founder, she is committed to building
                        TheUpsideSpace into an exciting, diverse, transparent
                        art marketplace.
                    </Typography>
                    <Typography className="about-paragraph">
                        She is also the co-founder of SAKWK, a philanthropic
                        foundation in India that supports the education of young
                        girls. Ayesha aspires to amalgamate her philanthropic
                        side with her mission of nurturing a digital space that
                        offers art for all.
                    </Typography>
                </Grid>
                <Grid item sm={12} md={12} lg={12}>
                    <Box
                        my={10}
                        style={{ background: '#000000' }}
                        height="1px"
                        width="100%"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item sm={4} md={4} lg={4} style={{ width: '100%' }}>
                    <Box
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Box p={2} width="100%" className="team-img-border">
                            <CardMedia
                                component="img"
                                className="team-image"
                                alt="Natasha"
                                image="/teamImages/Natasha.png"
                                height="318px"
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item sm={12} md={8} lg={8}>
                    <NameAndDesignation name="Natasha Jeyasingh" />
                    <Typography className="about-paragraph">
                        Natasha Jeyasingh is an art consultant and creative
                        project manager with an interest in projects traversing
                        art, craft, and design. As an avid collector of art, she
                        is well-versed with the contemporary Indian art scene
                        and has worked with several private and public
                        collections in India, Hong Kong, Dubai, and France.
                    </Typography>
                    <Typography className="about-paragraph">
                        Natasha has consulted on prestigious heritage projects
                        such as the refurbishing of the state hall at
                        'Rashtrapati Bhavan' - the Presidential Palace in New
                        Delhi - and restoration of the chandeliers at the Durbar
                        Hall at the Palace of Baroda. She has also consulted
                        onpublic art projects for the Hyatt Regency, Chennai,
                        Six Senses Fort Barwara, and the ongoing convention
                        centre of the Pragati Maidan in New Delhi.
                    </Typography>
                    <Typography className="about-paragraph">
                        She is the founder of Carpe Arte, a platform that makes
                        contemporary art accessible to the general public.
                        Natasha seeks to do the same via TheUpsideSpace.
                    </Typography>
                </Grid>
                <Grid item sm={12} md={12} lg={12}>
                    <Box
                        my={10}
                        style={{ background: '#000000' }}
                        height="1px"
                        width="100%"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item sm={4} md={4} lg={4} style={{ width: '100%' }}>
                    <Box
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Box p={2} width="100%" className="team-img-border">
                            <CardMedia
                                component="img"
                                className="team-image"
                                alt="Rafi Abdullah"
                                image="/teamImages/Rafi.jpg"
                                height="318px"
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item sm={12} md={8} lg={8}>
                    <NameAndDesignation name="Rafi Abdullah" />
                    <Typography className="about-paragraph">
                        Rafi Abdullah is a cultural worker with interests in new
                        media and contemporary art, with extensive experience
                        across cultural institutions, museums and galleries for
                        close to a decade.
                    </Typography>
                    <Typography className="about-paragraph">
                        He has written for artist catalogues and books, as well
                        as journals, platforms, and institutions such as Yavuz
                        Gallery, National Gallery Singapore, and So Far. He,
                        most recently, was the co-curator of the digital
                        exhibition Many Beliefs, One Future 2.0 (2022),
                        Crossroads (2022) and Poor Imagination (2019).
                    </Typography>
                    <Typography className="about-paragraph">
                        Rafi is based in Singapore and continues to live and
                        work there with a close network of digital and new-media
                        artists across the South-East Asia region.
                    </Typography>
                </Grid>
                <Grid item sm={12} md={12} lg={12}>
                    <Box
                        my={10}
                        style={{ background: '#000000' }}
                        height="1px"
                        width="100%"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item sm={4} md={4} lg={4} style={{ width: '100%' }}>
                    <Box
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Box p={2} width="100%" className="team-img-border">
                            <CardMedia
                                component="img"
                                className="team-image"
                                alt="Nabeel Qazi"
                                image="/teamImages/Nabeel.jpg"
                                height="318px"
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item sm={12} md={8} lg={8}>
                    <NameAndDesignation name="Nabeel Qazi" />
                    <Typography className="about-paragraph">
                        Nabeel is a cloud solutions architect and developer with
                        a decade of developer experience in domains such as
                        online sports, online real estate brokerage, and social
                        media apps. He experienced early success as part of
                        Cricingif, which saw the startup winning multiple
                        awards, including the APICTA’17 award for best startup
                        in Media Broadcasting and Brand Management
                    </Typography>
                    <Typography className="about-paragraph">
                        With Rare Sense Inc, Nabeel developed Superfandom, where
                        he worked on cutting-edge software technologies and
                        embraced blockchain and NFTs. His prior experience with
                        NFTs and launching celebrity storefronts makes him a
                        valuable member of TheUpsideSpace.
                    </Typography>
                </Grid>
                <Grid item sm={12} md={12} lg={12}>
                    <Box
                        my={10}
                        style={{ background: '#000000' }}
                        height="1px"
                        width="100%"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item sm={4} md={4} lg={4} style={{ width: '100%' }}>
                    <Box
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Box p={2} width="100%" className="team-img-border">
                            <CardMedia
                                component="img"
                                className="team-image"
                                alt="Fabin Rasheed"
                                image="/teamImages/Fabin.jpg"
                                height="318px"
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item sm={12} md={8} lg={8}>
                    <NameAndDesignation name="Fabin Rasheed" />
                    <Typography className="about-paragraph">
                        Fabin Rasheed is an artist, designer, innovator and
                        technologist working at the intersection of creativity
                        and technology. His work has been featured in Popular
                        Science, Mashable, SlashGear, BuzzFeed, Indian Express,
                        Business Standard, Mid-Day, etc.He has worked with
                        innovation labs at Xerox Research, Adobe, and IIT
                        Guwahati and holds several patents and publications in
                        creativity and technology.
                    </Typography>
                    <Typography className="about-paragraph">
                        In early 2021, he was selected as one of the top 10
                        global finalists at the Aurea Awards, honouring
                        Excellence in VR & AR Entertainment. In 2021, he was
                        also nominated for the Digital Innovation in Arts award
                        and is an artist council member at the Museum of
                        Cryptoart.
                    </Typography>
                    <Typography className="about-paragraph">
                        Fabin is a guest lecturer at the Indian Institute of
                        Technology, Hederabad and MIT Institute of Design, Pune
                        amongst others. He is currently based in Dubai
                    </Typography>
                </Grid>
                <Grid item sm={12} md={12} lg={12}>
                    <Box
                        my={10}
                        style={{ background: '#000000' }}
                        height="1px"
                        width="100%"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item sm={4} md={4} lg={4} style={{ width: '100%' }}>
                    <Box
                        height="100%"
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Box p={2} width="100%" className="team-img-border">
                            <CardMedia
                                component="img"
                                className="team-image"
                                alt="Sahana Srikanth"
                                image="/teamImages/Sahana.JPG"
                                height="318px"
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item sm={12} md={8} lg={8}>
                    <NameAndDesignation name="Sahana Srikanth" />
                    <Typography className="about-paragraph">
                        Sahana is a visual art practitioner with a diverse
                        practice ranging from painting, film, photography, and
                        text-based works. She is also skilled in art management
                        and exhibition support
                    </Typography>
                    <Typography className="about-paragraph">
                        Sahana has been a part of Asia-Europe Foundation (ASEF)
                        and Casa Asia's e-residency on arts and climate change.
                        She was also selected for the Young Artist’s Safe Space
                        (‘YCSS’) program by the British Council as part of OSCH
                        (‘Our shared cultural heritage’) in association with the
                        Indian Music Experience Museum. She has produced a
                        publication called ‘The Pandemic Times’ as part of YCSS,
                        a news-zine on lived experiences during the pandemic.
                    </Typography>
                </Grid>
            </Grid>
            <Box mt={10} className="about-title-box works-py">
                <Typography className="about-title">Our Advisors</Typography>
            </Box>
            <Grid container spacing={2}>
                <Grid item sm={12} md={6} lg={6}>
                    <Grid container spacing={1}>
                        <Grid
                            style={{ width: '100%' }}
                            item
                            sm={6}
                            md={6}
                            lg={6}
                        >
                            <Box
                                height="100%"
                                width="100%"
                                display="flex"
                                justifyContent="center"
                            >
                                <Box
                                    p={2}
                                    width="100%"
                                    className="team-img-border"
                                >
                                    <CardMedia
                                        component="img"
                                        className="team-image"
                                        alt="Aaron Cezar"
                                        image="/teamImages/Aaron Cezar.png"
                                        height="318"
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item sm={6} md={6} lg={6}>
                            <Box
                                width="100%"
                                height="100%"
                                display="flex"
                                justifyContent="center"
                            >
                                <Box width="90%">
                                    <Typography variant="h3">
                                        Aaron Cezar
                                    </Typography>
                                    <br />
                                    <Typography className='justify-text' style={{ lineHeight: '18px', fontSize:"16px" }}>
                                        Aaron Cezar is a curator and producer
                                        based in London. He is the Founding
                                        Director of Delfina Foundation, where he
                                        oversees and develops its interrelated
                                        programme of residencies, exhibitions,
                                        and public events. Cezar has curated
                                        external exhibitions and performances at
                                        Hayward Gallery Project Space, SongEun
                                        Artspace, and the 58th Venice Art
                                        Biennale. Independently and through
                                        Delfina Foundation, he sits on numerous
                                        boards, committees, and advisory groups.
                                        He has written for The Art Newspaper,
                                        Harper’s Bazaar, and ArtAsiaPacific,
                                        among others.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={12} md={6} lg={6}>
                    <Box width="100%" height="100%" className="Our-Advisors-2">
                        <Grid container spacing={1}>
                            <Grid
                                style={{ width: '100%' }}
                                item
                                sm={6}
                                md={6}
                                lg={6}
                            >
                                <Box
                                    height="100%"
                                    width="100%"
                                    display="flex"
                                    justifyContent="center"
                                >
                                    <Box
                                        p={2}
                                        width="100%"
                                        className="team-img-border"
                                    >
                                        <CardMedia
                                            component="img"
                                            className="team-image"
                                            alt="Dr Amin Jaffer"
                                            image="/teamImages/Dr Amin Jaffer.png"
                                            height="318"
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item sm={6} md={6} lg={6}>
                                <Box
                                    width="100%"
                                    height="100%"
                                    display="flex"
                                    justifyContent="center"
                                >
                                    <Box width="90%">
                                        <Typography variant="h3">
                                            Dr Amin Jaffer
                                        </Typography>
                                        <br />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <Box mt={10} className="about-title-box works-py">
                <Typography className="about-title">Our Partners</Typography>
            </Box>
            <Grid container spacing={2}>
                {ourpartner?.map((item: any) => (
                    <Fragment key={item?.com}>
                        <Grid item sm={2} md={2} lg={2} >
                            <Box
                                height="100%"
                                width="100%"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                pb={2}
                            >
                                <img
                                    width="120px"
                                    src={item?.logo}
                                    alt={item?.company}
                                />
                            </Box>
                        </Grid>
                        <Grid item sm={10} md={10} lg={10} >
                            <Typography variant="h3" style={{visibility:'hidden'}}>
                                {item?.company}
                            </Typography> 
                             <br />
                            <Typography className='about-company'>{item?.content}</Typography>
                            <Box
                                my={10}
                                style={{ background: '#000000' }}
                                height="1px"
                                width="100%"
                            />
                        </Grid>
                    </Fragment>
                ))}
            </Grid>
        </Container>
    </div>
);

export default AboutPage;
