import { ReactElement, useState } from 'react';
import {
    Box,
    Typography,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Divider,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SecondaryEmailForm from './SecondaryEmailForm';
import {
    AppSecondaryButton,
    AppPrimaryButton,
    ConfirmDialog,
} from '../../../shared/components/AppComponents';
import { ProfileActions } from '../../../state/profile/Actions';
import { AppState } from '../../../state/RootReducer';
import { ProfileService } from '../../../services';
import { Utils } from '../../../shared/helpers';

const SecondaryEmail = (props: any): ReactElement => {
    const { profileInfo, updateSecondaryEmail } = props;
    const navigate = useNavigate();
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [showEmailForm, setShowEmailForm] = useState(false);

    // API Calls
    const deleteSecondaryEmail = (): any => {
        ProfileService.deleteSecondaryEmail()
            .then(() => {
                updateSecondaryEmail({ email: '' });
                navigate('/profile/view');
                setShowConfirmDialog(false);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({
                    error,
                });
            });
    };

    return (
        <Box className="profile-card email-profile-wrapper">
            <Box className="card-header">
                <Typography className="title" variant="h3">
                    Notifications
                </Typography>
            </Box>
            <Divider />
            <Typography variant="body1" className="subtitle">
                Secondary Email for your communication
            </Typography>

            <Box mt={2}>
                <TableContainer>
                    <Table className="profile-table">
                        <TableBody>
                            <TableRow>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    className="w-200"
                                >
                                    Email
                                </TableCell>
                                <TableCell>
                                    <Box display="flex" alignItems="center">
                                        {!profileInfo.secondaryEmailId && (
                                            <AppPrimaryButton
                                                title="ADD NOW"
                                                className="btn-small btnContent"
                                                onClick={() => {
                                                    setShowEmailForm(true);
                                                }}
                                            />
                                        )}

                                        {profileInfo.secondaryEmailId && (
                                            <>
                                                <Typography className="btn-small mr-2">
                                                    {
                                                        profileInfo.secondaryEmailId
                                                    }
                                                </Typography>

                                                <AppPrimaryButton
                                                    title="EDIT"
                                                    className="btn-small mr-2"
                                                    onClick={() => {
                                                        setShowEmailForm(true);
                                                    }}
                                                />
                                                <AppSecondaryButton
                                                    title="DELETE"
                                                    className="btn-small"
                                                    onClick={() => {
                                                        setShowConfirmDialog(
                                                            true
                                                        );
                                                    }}
                                                />
                                            </>
                                        )}
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <ConfirmDialog
                showConfirm={showConfirmDialog}
                title="Secondary Email"
                subTitle="Are you sure wants to delete ?"
                successTitle="YES"
                cancelTitle="NO"
                onSuccess={() => {
                    deleteSecondaryEmail();
                }}
                onCancel={() => {
                    setShowConfirmDialog(false);
                }}
            />
            <SecondaryEmailForm
                {...{
                    showForm: showEmailForm,
                    onCloseForm: () => {
                        setShowEmailForm(false);
                    },
                }}
            />
        </Box>
    );
};

const mapStateToProps = (state: AppState): any => ({
    profileInfo: state.profileInfo,
});

const mapDispatchToProps = (dispatch: any): any => ({
    updateSecondaryEmail: (data: any) =>
        dispatch(ProfileActions.updateSecondaryEmail(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SecondaryEmail);
