import { ReactElement } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

const PDFSkeleton = (): ReactElement => (
    <>
        <Skeleton
            variant="rect"
            width={300}
            height={50}
            animation="wave"
            style={{ marginBottom: 10 }}
        />
        <Skeleton
            variant="rect"
            width={300}
            height={75}
            animation="wave"
            style={{ marginBottom: 10 }}
        />
        <Skeleton variant="rect" width={300} height={200} animation="wave" />
    </>
);

export default PDFSkeleton;
