import { useState, ReactElement } from 'react';
import GoogleLoginOption from '../../../shared/components/GoogleLoginOption';
import { Utils } from '../../../shared/helpers';
import { AuthenticationService } from '../../../services';
import { useAuth } from '../hooks/UseAuth';

const SignupGoogleForm = (): ReactElement => {
    const [loadingGoogle, setLoadingGoogle] = useState(false);

    const { processLoginResponse } = useAuth();

    // API Calls
    const google = (googleData: any): any => {
        setLoadingGoogle(true);
        AuthenticationService.registerWithGoogle(googleData)
            .then((response: any) => {
                setLoadingGoogle(false);
                processLoginResponse(response);
            })
            .catch((error: any) => {
                setLoadingGoogle(false);
                Utils.handleErrorResponse({ error });
            });
    };

    // UI Actions
    const actionGoogle = (googleData: any): any => {
        google(googleData);
    };

    // UI Elements
    return (
        <>
         <img src="/images/icon-google.svg" alt="" className='google-img-icon' />
        <GoogleLoginOption
        
            onClick={actionGoogle}
            loading={loadingGoogle}
            setLoadingGoogle={setLoadingGoogle}
        />
        </>
    );
};

export default SignupGoogleForm;
