import {
    Box,
    Divider,
    IconButton,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    CircularProgress,
    Grid,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { ProfileService } from '../../../services/Profile';
import { Utils } from '../../../shared/helpers';
import { ProfileActions } from '../../../state/profile/Actions';
import WithDrawAmount from './WithDrawAmount';
import { BuySellService } from '../../../services/BuySellService';
import { AppPrimaryButton } from '../../../shared/components/AppComponents';

export interface Balance {
    balance: string;
    token: string;
}

export interface Wallet {
    walletName: string;
    address: string;
    balances: Balance[];
}

export interface FiatWallet {
    address: string;
    freeBalance: number;
    lockedBalance: number;
    totalBalance: number;
    currencyType: string;
}

const WalletBalance = (props: any): any => {
    const { appState, profileInfo } = props;
    const [cryptoWallets, setCryptoWallets] = useState<Wallet[]>([]);
    const [fiatWallet, setFiatWallet] = useState<FiatWallet>({} as FiatWallet);
    const [loadingWallet, setLoadingWallet] = useState(false);
    const [openWithdrawModal, setOpenWithdrawModal] = useState(false);
    const [withDrawType, setWithDrawType] = useState('fiat');

    // API Calls
    const loadWalletBalance = (): void => {
        setLoadingWallet(true);
        ProfileService.walletBalance()
            .then((response) => {
                const wallet = response.data.data;
                setCryptoWallets(wallet.cryptoWallet);
                setFiatWallet(wallet.fiatWallet);
                setLoadingWallet(false);
            })
            .catch((error) => {
                Utils.handleErrorResponse(error);
                setLoadingWallet(false);
            });
    };

    // Hooks
    useEffect(() => {
        loadWalletBalance();
        BuySellService.loadCryptoPaymentAvailability();
    }, []);

    // Child Actions
    const onWithDrawCancel = (): void => setOpenWithdrawModal(false);

    const onWithDrawSuccess = (): void => {
        setOpenWithdrawModal(false);
        loadWalletBalance();
    };

    // UI Elements
    return (
        <>
            {/* {loadingWallet && (
                <Box className="empty-content">
                    <CircularProgress />
                </Box>
            )} */}
            <Box className="card-header view-profile">
                                            <Typography
                                                className="title tus-title"
                                                variant="h3"
                                            >
                                                TUS Wallet
                                                {/* {wallet.walletName} */}
                                            </Typography>
                                        </Box>
            {!loadingWallet && (
                <Box className='walletbalance-wrapper'>
                    {/* {cryptoWallets.map((wallet) => (
                        <>
                            {((appState.acceptCrypto &&
                                wallet.walletName === 'Fantico Wallet') ||
                                wallet.walletName !== 'Meta Wallet') && (
                                <>
                                    <Grid xs={12} sm={10} md={9} lg={5} className="profile-content">
                                        <Box className="card-header view-profile">
                                            <Typography
                                                className="title"
                                                variant="h3"
                                            >
                                                TUS Wallet
                                                {wallet.walletName}
                                            </Typography>
                                        </Box>
                                        <Divider className='border-content'/>
                                        <Box className="wallet-balance-box">
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            className="profile-card"
                                        >
                                            {wallet.balances.map((balance) => (
                                                <Typography
                                                    variant="body1"
                                                    className=""
                                                    id="wallet-fantico"
                                                >
                                                    Wallet : {`${balance.balance} ${balance.token}`}
                                                </Typography>
                                            ))}
                                            <Box className='wallet-btn-wrapper'>
                                            <Typography
                                                className=""
                                                variant="body1"
                                                id="wallet-address"
                                            >
                                                Wallet address :
                                                <Typography className='wallet-address-data'> {wallet.address}</Typography>
                                            </Typography>
                                            {appState.acceptCrypto && (
                                                <Box>
                                                    <AppPrimaryButton
                                                        title="Withdraw"
                                                        className="btn-small primary-btn"
                                                        onClick={() => {
                                                            setOpenWithdrawModal(
                                                                true
                                                            );
                                                            setWithDrawType(
                                                                'crypto'
                                                            );
                                                        }}
                                                    />
                                                    
                                                    <Tooltip
                                                        enterTouchDelay={100}
                                                        title={
                                                            <Typography>
                                                                To withdraw the available balance in your wallet, 
                                                                please complete the following under “Profile Settings”:
                                                                <br />
                                                                1. Enable 2-Factor Authentication <br />
                                                                2. Enter your KYC information <br />
                                                                3. Enter your Address <br />
                                                                4. Add your Crypto Account details <br />
                                                            </Typography>
                                                        }
                                                    >
                                                        <IconButton>
                                                            <InfoIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                            )}
                                            </Box>
                                        </Box>
                                        </Box>
                                    </Grid>
                                </>
                            )}
                        </>
                    ))} */}

                    <Grid xs={12} sm={10} md={9} lg={5} className="profile-card">
                        {/* <Box className="card-header">
                            <Typography className="title" variant="h3">
                                Fiat Wallet
                            </Typography>
                        </Box> */}
                        {/* <Divider /> */}
                                                        
                        <Box display="flex" flexDirection="column" className='fiat-wallet-warpper'>
                            <Typography 
                                variant="body1" 
                                className=""
                                id="wallet-fiat"
                            >
                            Fiat Wallet : {`${fiatWallet.totalBalance} ${fiatWallet.currencyType}`}
                            </Typography>
                            <Box className='fiat-wallet-btn-box'> 
                            <Box className='fiat-wallet-btn-type'>
                            <Box style={{display: 'flex'}} className="lock-type">
                                <Typography className=" wallet-content" variant="body1">
                                    Locked :{' '}
                                </Typography>
                                <Typography className="" id="balance" variant="body1">
                                    {`${fiatWallet.lockedBalance} ${fiatWallet.currencyType}`}
                                </Typography>                      
                            </Box>
                                
                            <Box style={{display: 'flex'}} className="available">
                                <Typography className="wallet-content" variant="body1">
                                    Available :{' '}
                                </Typography>
                                <Typography className="" id="balance" variant="body1">
                                    {`${fiatWallet.freeBalance} ${fiatWallet.currencyType}`}
                                </Typography> 
                            </Box>
                            </Box>
                                
                            <Box>
                                <AppPrimaryButton
                                    title="Withdraw"
                                    className="btn-small primary-btn btnContent"
                                    onClick={() => {
                                        setOpenWithdrawModal(true);
                                        setWithDrawType('fiat');
                                    }}
                                />
                                {/* <Tooltip
                                    enterTouchDelay={100}
                                    title={
                                        <Typography>
                                            To withdraw the available balance in your wallet, 
                                            please complete the following under “Profile Settings”:
                                            <br />
                                            1. Enable 2-Factor Authentication <br />
                                            2. Enter your KYC information <br />
                                            3. Enter your Address <br />
                                            4. Add your Bank Account details <br />
                                        </Typography>
                                    }
                                >
                                    <IconButton>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip> */}
                            </Box>
                            </Box>
                        </Box>
                    </Grid>

                    {/* WithDraw Form */}
                    <Dialog
                        maxWidth="sm"
                        fullWidth
                        open={openWithdrawModal}
                        className="withdraw-container"
                    >
                        <DialogTitle className='withdraw-popup'>
                            <Box className="title-section">
                                <Typography variant="h4" className='tobias-fontfamily'>Withdraw</Typography>
                                <IconButton onClick={onWithDrawCancel}>
                                    <CloseIcon/>
                                </IconButton>
                            </Box>
                            <Divider />
                        </DialogTitle>

                        <DialogContent  className='withdraw-popup'>
                            <WithDrawAmount
                                profileInfo={profileInfo}
                                withDrawType={withDrawType}
                                onWithDrawCancel={onWithDrawCancel}
                                onWithDrawSuccess={onWithDrawSuccess}
                                {...props}
                            />
                        </DialogContent>
                    </Dialog>
                </Box>
            )}
        </>
    );
};

const mapStateToProps = (state: any): any => ({
    profileInfo: state.profileInfo,
    appState: state.applicationState,
});

const mapDispatchToProps = (dispatch: any): any => ({
    setProfileData: (data: any) =>
        dispatch(ProfileActions.setProfileData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletBalance);