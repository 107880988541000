import { Box, Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { useParams } from 'react-router-dom';
import { ReactElement, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DefaultPagination, CollectionService } from '../../services';
import { Utils } from '../../shared/helpers';
import { CurrencyConvertion } from '../../state/application/Reducer';
import { AppState } from '../../state/RootReducer';
import Created from './Created';
import Collected from './Collected';
import Liked from './Liked';
import Exhibited from './Exhibited'

const ArtistTab = (props:any): ReactElement => {
    const { userId } = useParams();
    const [value, setValue] = useState('1');
    const handleChange = (e: any, newValue: string): any => {
        setValue(newValue)
    }
    const { user ,userType} = props;

    const [artistsDetail, setArtistsDetail] = useState([]);
    const [pagination, setPagination] = useState({ ...DefaultPagination(30) });
    const [loadingItems, setLoadingItems] = useState(false);
    const convertion: CurrencyConvertion = useSelector(
        (state: AppState) => state.applicationState.currencyConverstion
    );
    // TODO immediate implementation
    // API Calls
    const loadItems = (reload = false): void => {
        if (pagination.lastOffset !== pagination.offset) {
            if (reload) {
                pagination.offset = 1
            }
            pagination.lastOffset = pagination.offset;
            setLoadingItems(true);

            CollectionService.getOwnedItems(pagination.limit, pagination.offset, false, userId)
                .then((response: any) => {
                    const { data } = response.data;
                    setArtistsDetail(data.items);
                    const paginationResponse = data.pagination;
                    setLoadingItems(false);
                    setPagination({
                        ...paginationResponse,
                        lastOffset: pagination.lastOffset,
                        offset: pagination.offset + 1,
                    });
                })
                .catch((error: any) => {
                    setLoadingItems(false);
                    Utils.handleErrorResponse({ error });
                });
        }
    };

    useEffect(() => {
        loadItems();
        if(user){
            setValue('2');
        }
        if(userType === 'user'){
            setValue('3');
        }
    }, []);

    


    return (
        <>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="artist-tab">
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                       {user ? <Tab style={{'display':'none'}} label="Created" value='1' /> :<Tab  label="Created" value='1' />}
                       {userType !== 'user'?<Tab label="Exhibited" value='2' />:<Tab style={{'display':'none'}} label="Exhibited" value='2' />}
                        <Tab label="Collected" value='3' />
                        <Tab label="Liked" value='4' />
                    </TabList>
                </Box>
                <TabPanel className='market-slider-wrapper' value={value}>
                    {value === '1' && 
                        <Box className={value === '1' ? 'market-slider active' : 'market-slider'}>
                            <Created />
                        </Box>
                    }
                </TabPanel>
                <TabPanel className='market-slider-wrapper' value={value}>
                    {value === '2' && 
                        <Box className={value === '2' ? 'market-slider active' : 'market-slider'}>
                            <Exhibited />
                        </Box>
                    }
                </TabPanel>
                <TabPanel value='3'>
                    {value === '3' && 
                        <Box className={value === '3' ? 'market-slider active' : 'market-slider'}>
                            <Collected />
                        </Box>
                    }
                </TabPanel>
                <TabPanel value='4'>
                    {value === '4' && 
                        <Box className={value === '4' ? 'market-slider active' : 'market-slider'}>
                            <Liked />
                        </Box>
                    }
                </TabPanel>
            </TabContext>
        </>
    )
}

export default ArtistTab
