import * as Yup from 'yup';
import { comment, accountAddress, quantity } from './Schema';

export const ReportValidation = Yup.object().shape({
    comment,
});

export const ExportCryptotSchema = (): any =>
    Yup.object().shape({
        accountAddress,
        quantity,
        // editions: Yup.array().required('Please select editions'),
    });
