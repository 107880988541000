import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Container,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import LockIcon from '@material-ui/icons/Lock';
import OpenInNewOutlined from '@material-ui/icons/OpenInNewOutlined';
import moment from 'moment';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import {
    BuySellService,
    CollectionService,
    DefaultPagination,
} from '../../services';
import {
    AppVideoPlayer,
    GradientChip,
    HeadTag,
    MoreMenu,
    NFTTag,
} from '../../shared/components';
import {
    AppTeritaryButton,
    NumberPretty,
} from '../../shared/components/AppComponents';
import { GradientVariants } from '../../shared/components/GradientChip';
import { MoreMenuData } from '../../shared/components/MoreMenu';
import NotFound from '../../shared/components/NotFound';
import Video from '../../shared/components/Video';
import { Toast, Utils } from '../../shared/helpers';
import {
    checkLauncDate,
    getUrl,
    getVideoUrl,
    isSecondarySale,
} from '../../shared/helpers/Utils';
import { AppState } from '../../state/RootReducer';
import {
    ApplicationDataState,
    CurrencyConvertion,
} from '../../state/application/Reducer';
import { AuthState } from '../../state/authentication/Reducer';
import { MyNFTsPage } from '../profile';
import {
    // NFTSales,
    NFTDetail,
    NFTMediaBox,
} from './components';
import NFTCheckoutForm from './components/NFTCheckout';
import NFTExportForm from './components/NFTExport';
import { ImageSliderConfig } from './components/SliderConfig';
// import VideoNFT from '../../shared/components/VideoNFT';
import PlayAudioWaveForm from '../../shared/components/PlayAudioWaveForm';
import CuratingLoading from '../../shared/components/curatingLoading';
import DownloadResource from '../../shared/components/download-resource';
import GoesLiveUi from '../../shared/components/goesLiveUi';
import ViewImageZoom from '../../shared/components/imageZoom';
import AppPreference from '../../shared/managers/AppPreference';
import SellNft from './components/SellNft';
import CancelNft from './components/cancelNft';

import PostOffer from './components/PostOffer';

const CASHBACK_WITHIN_TIME = 180;

export interface OwnedBy {
    id: string;
    name: string;
    address: string;
    isPrivate: boolean;
    blueTick: boolean;
    quantity: number;
    physicalAddressExists: boolean;
    profilePicture: string;
    editionQuantity: string[];
}

export interface CollectionInfo {
    name: string;
    logo: string;
    description: string;
    id: string;
    secondarySale: boolean;
}

export interface Property {
    Test: string;
}

export interface ItemInfo {
    images: any;
    name: string;
    originalArtist?: string;
    file: string[];
    logo?: any;
    thumbnail: string;
    description: string;
    totalQuantity: number;
    royalty: number;
    fee: number;
    price: number;
    physicalNFT: boolean;
    currencyType: string;
    properties: Property[];
    totalOwners: number;
    numberOfViews: number;
    id: string;
    hasAuction: boolean;
    category: string;
    categoryId: string;
    tag: string;
    tagId: string;
    isCreator: boolean;
    isOwner: boolean;
    physicalNFTAvailable: boolean;
    physicalNFTDescription: string;
    lockableContent: string;
    lockableContentAvailable: boolean;
    keywords: string;
    favourites: {
        isFavourite: boolean;
        total: number;
    };
    whiteList: boolean;
    saleWhiteList: boolean;
    cashbackAvailable: boolean;
    tokenType: any;
    exhibitionName: string;
    canBuy?: boolean;
    quantity?: any;
    availableQuantity?: number;
    launchDate: any;
    canCancel?: boolean;
    postedBy?: any;
    address?: any;
    isPrivate?: any;
    video?: any;
    secondarySale?: boolean;
    totalAvailableQuantity: any;
    primarySale?: boolean;
    buttonStatus?: string;
    audio?: string;
    ipfs?: string;
    ipfsMetadataUrl?: string;
    canPostOffer?: boolean;
}

export interface CreatorInfo {
    name: string;
    address: string;
    isPrivate: boolean;
    blueTick: boolean;
    id: string;
    profilePicture: string;
}

export interface ChainInfo {
    network: string;
    contractAddress: string;
    tokenId: string;
}

export interface OwnerInfo {
    profilePicture: string;
    id: string;
    name: string;
    address: string;
    isPrivate: boolean;
    blueTick: boolean;
    quantity: number;
    physicalAddressExists: boolean;
    editionQuantity: string[];
    thumbnail: string;
    ownedUserId: string;
}

export interface Listing {
    canCancel?: boolean;
    canBuy?: boolean;
    from?: string;
    id: string;
    totalQuantity: number;
    availableQuantity?: number;
    currencyType: string;
    postedBy?: string;
    price: number;
    address?: string;
    isPrivate?: boolean;
    acceptedPaymentMethod?: string;
    name: string;
    profilePicture?: string;
    availableEditionQuantity?: string[];
    pricePerQuantity?: number;
    postOffer?: boolean;
}
export interface ItemFullDetail {
    canRequest: boolean;
    buySellRestrict: boolean;
    userWhiteList: boolean;
    ownedBy: OwnedBy[];
    collectionInfo: CollectionInfo;
    itemInfo: ItemInfo;
    tradingInfo: any;
    creatorInfo: CreatorInfo;
    chainInfo: ChainInfo;
    listings: Listing[];
    ownerInfo: OwnerInfo;
    secondarySale: boolean;
    exhibition: any;
    saleInfo?: any;
    video?: any;
    isOwner?: boolean;
}

export interface NFTDetailPageProps {
    authState: AuthState;
    appState: ApplicationDataState;
}

export interface Bid {
    id: string;
    name: string;
    blueTick: boolean;
    address: string;
    amount: number;
    cryptoAmount: number;
    currencyType: string;
    isPrivate: boolean;
    profilePicture: string;
}

export interface Auction {
    pendingDays: number;
    auctionId: string;
    saleId: string;
    quantity: number;
    canBid: boolean;
    canCancel: boolean;
    minimumPrice: number;
    highestBid: number;
    cryptoMinimumPrice: number;
    highestCryptoBid: number;
    currencyType: string;
    liveTill: Date;
    bids: Bid[];
    editionQuantity: string[];
    viewBid: boolean;
    isCancellingAuction: boolean;
    acceptedPaymentMethod: string;
}

const moreMenus: any = [
    // {
    //     title: 'Share',
    //     icon: <ShareIcon color="primary" />,
    // },
    // {
    //     title: 'Report',
    //     icon: <FlagIcon color="primary" />,
    // },
    // {
    //     title: 'Export',
    //     icon: <ImportExportIcon color="primary" />,
    // }
];
const StyledTableCell = withStyles((): any =>
    createStyles({
        head: {
            backgroundColor: 'black',
            color: 'white',
            marginBottom: '10px',
        },
        body: {
            fontSize: 14,
        },
    })
)(TableCell);

const StyledTableBodyCell = withStyles((): any =>
    createStyles({
        root: {
            padding: '10px',
        },
    })
)(TableCell);

const StyledTableRow = withStyles((): any =>
    createStyles({
        root: {
            borderBottom: '1px solid #e0e0e0',
        },
    })
)(TableRow);

const NFTDetailPage = (props: any): ReactElement => {
    const { authState, appState, profileInfo } = props;

    // Instance Variables
    const title = 'NFT Detail';
    const { itemId } = useParams();
    // const [menuIndex, setMenuIndex] = useState(1);
    const [auctions, setAuctions] = useState<Auction[]>([]);
    // const [loadingAuction, setLoadingAuction] = useState(false);
    const [itemDetail, setItemDetail] = useState<ItemFullDetail>();
    const [pagination] = useState({ ...DefaultPagination(50) });
    const [showCheckout, setShowCheckout] = useState(false);
    const [showOffer, setShowOffer] = useState(false);
    const [showReport, setShowReport] = useState(false);
    const [showShareDialog, setShowShareDialog] = useState(false);
    const [openExportNFT, setOpenExportNFT] = useState(false);
    const [menus, setMenus] = useState(moreMenus);
    const [fav, setFav] = useState(false);
    const [favTotal, setFavTotal] = useState(0);
    const [isFav, setIsFav] = useState(false);
    const [shown, setShown] = useState(false);
    const [favLoader, setFavLoader] = useState(false);
    const [loadingItemDetail, setLoadingItemDetail] = useState(false);
    const [listingToBuy, setListingToBuy] = useState<Listing | undefined>(
        undefined
    );
    const [showVideoMessage, setShowVideoMessage] = useState<boolean>(false);
    const [auctionToBid, setAuctionToBid] = useState<Auction | undefined>(
        undefined
    );
    const [offer, setOffer] = useState<boolean>(false);
    const [paymentResponse, setPaymentResponse] = useState(false);

    // const isLaunched = itemDetail?.itemInfo.launchDate ? checkLauncDate(itemDetail?.itemInfo.launchDate) : false;

    const { search } = useLocation();
    const orderStatus = new URLSearchParams(search).get('orderStatus');
    const [btnLoading, setBtnloading] = useState(false);
    const [offerLoading, setOfferLoading] = useState(false);
    const [activeOffer, setActiveOffer] = useState('');
    const [errorPage, setErrorPage] = useState(false);
    // 1. Fixed Price
    // 2. Auction
    const [purchaseType, setPurchaseType] = useState('direct');
    const [buyStart, setBuyStart] = useState<any>(null);
    const [buyEnd, setBuyEnd] = useState<any>(null);
    const [currentSlide, setCurrnetSlide] = useState(0);
    const [videosArrayLength, setVideosArrayLength] = useState(0);
    const [offers, setOffers] = useState([]);

    const [ipfsMetadataUrl, setIpfsMetadataUrl] = useState('');

    const searchT = window?.location?.search;
    const params = new URLSearchParams(searchT);
    const view = params.get('view');
    // API Calls
    const updateViewCount = (): void => {
        CollectionService.increaseViewCount({
            itemId,
        })
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .then(() => {});
    };

    const navigate = useNavigate();

    const getItemDetail = (): void => {
        setLoadingItemDetail(true);
        CollectionService.getItemDetail(itemId, view)
            .then((response) => {
                const itemResponse: ItemFullDetail = response.data.data;
                setItemDetail(itemResponse);
                setLoadingItemDetail(false);
            })
            .catch((error) => {
                setLoadingItemDetail(false);
                const statusCode = JSON.parse(
                    JSON.stringify(error)
                )?.message?.replace(/^\D+/g, '');
                if (statusCode === '400' || statusCode === '404') {
                    setErrorPage(true);
                }
            });
        CollectionService.getOffersList(itemId).then((response) => {
            setOffers(response.data.data.offers);
            console.log(offers, 'offerss');
        });
    };

    const [controls] = useState(true);
    const [playing, setPlaying] = useState(true);
    const [muted] = useState(true);
    const [loop] = useState(true);
    const [volume] = useState(0);
    const [pip] = useState(true);
    const [countPrimaryEdition, setCountPrimaryEdition] = useState(0);

    const loadAuctionList = (): void => {
        if (itemDetail && itemDetail.itemInfo.hasAuction) {
            // setLoadingAuction(true);
            BuySellService.getAuctions(
                itemDetail.itemInfo.id,
                pagination.limit,
                pagination.offset
            )
                .then((response) => {
                    let newAuctions = response.data.data.auctions;
                    newAuctions = newAuctions.map((auction: Auction) => ({
                        ...auction,
                        viewBid: false,
                        isCancellingAuction: false,
                    }));
                    // setLoadingAuction(false);
                    setAuctions(newAuctions);
                })
                .catch((error) => {
                    Utils.handleErrorResponse({ error });
                    // setLoadingAuction(false);
                });
        }
    };

    const showVideoAudioMessage = useCallback(() => {
        if (!shown) {
            setShowVideoMessage(true);
            setTimeout(() => setShowVideoMessage(false), 5000);
        }
    }, [shown]);

    const offerChoose = (offerId: any, Option: string): void => {
        setOfferLoading(true);
        setActiveOffer(offerId);
        CollectionService.acceptOrReject(offerId, Option)
            .then((res) => {
                Toast.showSuccesMessage(`Successfully ${Option}ed`);
                setOfferLoading(false);
                setActiveOffer('');
                CollectionService.getOffersList(itemId).then((response) => {
                    setOffers(response.data.data.offers);
                    getItemDetail();
                });
            })
            .catch((error) => {
                setOfferLoading(false);
                setActiveOffer('');
                Utils.handleErrorResponse({ error });
            });
    };

    // Child events
    const reloadItemDetail = (): void => {
        getItemDetail();
    };

    const onListingCancel = (): void => {
        reloadItemDetail();
    };

    const onAuctionCancel = (): void => {
        loadAuctionList();
    };

    const onBuyNFT = (listing: Listing): void => {
        setListingToBuy(listing);
        setPurchaseType('direct');
        setShowCheckout(true);
        setPaymentResponse(false);
        const d = new Date();
        setBuyStart(d);
        d.setSeconds(d.getSeconds() + Number(CASHBACK_WITHIN_TIME));
        setBuyEnd(d);
    };

    const getNFTDirectSellToBuy = (): any => {
        if (itemDetail?.itemInfo.canBuy) {
            return true;
        }
        return null;
    };

    const onPostOffer = (): void => {
        setShowOffer(true);
    };

    const onPlaceBid = (auction: Auction): void => {
        setAuctionToBid(auction);
        setPurchaseType('auction');
        setShowCheckout(true);
        setPaymentResponse(false);
    };

    const onPaymentResponse = (status: boolean): void => {
        if (status) {
            console.log('onPaymentResponse');
            setPaymentResponse(true);
            reloadItemDetail();
        }
    };

    const userRedirect = (item: any): any => (
        <>
            <Link to="/">"ddd</Link>
        </>
    );

    const getEvent = (item: any): any => {
        if (item.event === 'list') {
            return `On Sale`;
        }
        if (item.event === 'cancel') {
            return `From Sale`;
        }
        return (
            <Link
                className="underLine"
                to={`/${
                    item?.to?.type === undefined ? 'user' : item?.to?.type
                }/${item.to.userId}`}
            >
                @{item.to.userName}
            </Link>
        );
    };

    const actionExportNFT = (): void => {
        if (authState.isUserLoggedIn) {
            setOpenExportNFT(true);
        } else {
            Toast.showInfoMessage('Login to export NFT');
            navigate('/login', {
                state: { nextPathName: window.location.pathname },
            });
        }
    };
    const windowObject: any = window;
    const buyNft = (saleList: any): any => {
        if (AppPreference.getAccessToken()) {
            setBtnloading(true);
            setShowCheckout(true);
            setPaymentResponse(false);
            setPurchaseType('direct');
            setListingToBuy(saleList);

            if (
                windowObject.gtag &&
                itemDetail &&
                itemDetail.itemInfo &&
                !view &&
                !orderStatus
            ) {
                console.log('event', 'add to cart');
                windowObject.gtag('event', 'add_to_cart', {
                    currency: 'USD',
                    value: itemDetail?.saleInfo[0]?.pricePerQuantity,
                    items: [
                        {
                            item_id: itemDetail?.itemInfo.id,
                            item_name: itemDetail?.itemInfo?.name,
                            currency: 'USD',
                            discount: 0,
                            index: 0,
                            price: itemDetail?.saleInfo[0]?.pricePerQuantity,
                            quantity: itemDetail?.itemInfo?.quantity,
                        },
                    ],
                });
            }
        } else {
            Toast.showInfoMessage('Please login to buy NFT');
            Utils.clearSession();
            navigate('/login', {
                state: { nextPathName: window.location.pathname },
            });
        }
    };

    const postOfferUser = (offerData: any): any => {
        console.log(offerData, 'postoffer');
        if (AppPreference.getAccessToken()) {
            // setBtnloading(true);
            setShowCheckout(true);
            setPaymentResponse(false);
            setPurchaseType('direct');
            setListingToBuy({
                canCancel: false,
                canBuy: false,
                from: '',
                id: itemDetail.itemInfo.id,
                totalQuantity: offerData.quantity,
                availableQuantity: offerData.quantity,
                currencyType: 'USD',
                postedBy: '',
                price: offerData.pricePerQuantity,
                address: '',
                isPrivate: false,
                acceptedPaymentMethod: '',
                name: '',
                profilePicture: '',
                availableEditionQuantity: [],
                pricePerQuantity: offerData.pricePerQuantity,
                postOffer: true,
            });
        } else {
            Toast.showInfoMessage('Please login to Post Offer');
            Utils.clearSession();
            navigate('/login', {
                state: { nextPathName: window.location.pathname },
            });
        }
    };

    const triggerGtagEvent = (id: any): any => {
        if (windowObject.gtag && itemDetail && itemDetail.itemInfo) {
            console.log('event', 'purchase');

            windowObject.gtag('event', 'purchase', {
                currency: 'USD',
                transaction_id: id,
                value: itemDetail?.saleInfo[0]?.pricePerQuantity,
                items: [
                    {
                        item_id: itemDetail?.itemInfo.id,
                        item_name: itemDetail?.itemInfo?.name,
                        currency: 'USD',
                        discount: 0,
                        index: 0,
                        price: itemDetail?.saleInfo[0]?.pricePerQuantity,
                        quantity: itemDetail?.itemInfo?.quantity,
                    },
                ],
            });
        }
        console.log('purchase event trigger');
    };

    // Hooks

    useEffect(() => {
        if (!paymentResponse) {
            getItemDetail();
            updateViewCount();
        }
        console.log('in usereffect tripleA update');
        if (orderStatus === 'success') {
            Toast.showSuccesMessage('Your Order Placed Successfully');
            triggerGtagEvent('triplA');
        }
        if (orderStatus === 'failure') {
            Toast.showErrorMessage('Your order failed');
        }

        if (orderStatus === 'successExport') {
            Toast.showSuccesMessage('Your Nft Export is Successfull');
        }
        if (orderStatus === 'failureExport') {
            Toast.showSuccesMessage('Your Nft Export is Successfull');
        }
        if (orderStatus === 'successPostOffer') {
            Toast.showSuccesMessage('Offer posted Successfull');
        }
        if (orderStatus === 'failurePostOffer') {
            Toast.showSuccesMessage('Something went wrong');
        }
    }, [itemId]);

    useEffect(() => {
        BuySellService.loadCryptoPaymentAvailability();
    }, []);

    useEffect(() => {
        if (itemDetail) {
            if (itemDetail.itemInfo.hasAuction) {
                loadAuctionList();
            } else {
                setAuctions([]);
            }

            if (
                windowObject.gtag &&
                itemDetail &&
                itemDetail.itemInfo &&
                !view
            ) {
                console.log('event', 'view item');
                windowObject.gtag('event', 'view_item', {
                    currency: 'USD',
                    value: itemDetail?.saleInfo[0]?.pricePerQuantity,
                    items: [
                        {
                            item_id: itemDetail?.itemInfo.id,
                            item_name: itemDetail?.itemInfo?.name,
                            currency: 'USD',
                            discount: 0,
                            index: 0,
                            price: itemDetail?.saleInfo[0]?.pricePerQuantity,
                            quantity: itemDetail?.itemInfo?.quantity,
                        },
                    ],
                });
                //   console.log("view event triggered" ,( {
                //     currency: "USD",
                //     value:itemDetail?.saleInfo[0]?.pricePerQuantity,
                //     items: [
                //       {
                //         item_id:  itemDetail?.itemInfo.id,
                //         item_name: itemDetail?.itemInfo?.name,
                //         currency: "USD",
                //         discount: 0,
                //         index: 0,
                //         price: itemDetail?.saleInfo[0]?.pricePerQuantity,
                //         quantity: itemDetail?.itemInfo?.quantity
                //       }
                //     ]

                //   }))
            }

            if (itemDetail?.itemInfo?.ipfsMetadataUrl) {
                setIpfsMetadataUrl(itemDetail?.itemInfo?.ipfsMetadataUrl);
            } else if (itemDetail?.chainInfo?.tokenId) {
                setIpfsMetadataUrl(
                    `${process.env.REACT_APP_BASE_URL}/nft-details/${itemDetail?.chainInfo.tokenId}`
                );
            }
        }

        if (itemDetail && itemDetail.itemInfo.isOwner) {
            setMenus([
                ...moreMenus,
                {
                    title: 'Export',
                    icon: <ImportExportIcon color="primary" />,
                },
            ]);
        }

        if (
            itemDetail &&
            itemDetail.saleInfo &&
            itemDetail.saleInfo.length > 0
        ) {
            itemDetail.saleInfo.map((sale: any) => {
                if (!sale.secondarySale) {
                    setCountPrimaryEdition(sale.availableQuantity);
                }
                return 0;
            });
            // for (const sale of itemDetail.saleInfo) {
            //     if(!sale.secondarySale && sale.event === 'active') {
            //         console.log(sale.availableQuantity)
            //         setCountPrimaryEdition(sale.availableQuantity)
            //     }
            // }
        }
    }, [itemDetail]);

    const convertion: CurrencyConvertion = useSelector(
        (state: AppState) => state.applicationState.currencyConverstion
    );

    const ethPrice = (price: number): any => {
        if (convertion.to.amount !== '0' && price) {
            const usdValue = parseFloat(convertion.to.amount);
            return (price / usdValue).toFixed(3);
        }
        return 0;
    };

    const getFavIcon = (): ReactElement => {
        if (favLoader) {
            return <CircularProgress size={20} />;
        }
        if (isFav) {
            return <FavoriteIcon color="primary" />;
        }
        return <FavoriteBorderIcon color="primary" />;
    };
    const toggleFav = (_itemId: string, _isFav: boolean, e: any): void => {
        if (authState.isUserLoggedIn) {
            e.preventDefault();
            e.stopPropagation();
            if (!favLoader) {
                const data = {
                    itemId: _itemId,
                    favourite: !_isFav,
                };
                setFavLoader(true);
                CollectionService.toggleFavItems(data)
                    .then(() => {
                        if (_isFav) {
                            setIsFav(false);
                            setFavTotal(favTotal - 1);
                        } else {
                            setIsFav(true);
                            setFavTotal(favTotal + 1);
                        }
                        setFavLoader(false);
                    })
                    .catch((error: any) => {
                        Utils.handleErrorResponse({ error });
                        setFavLoader(false);
                    });
            }
        } else {
            Toast.showInfoMessage('Login to add favourite');
            navigate('/login', {
                state: { nextPathName: window.location.pathname },
            });
        }
    };

    const getOwnerSale = (): any => {
        const saleInfo =
            itemDetail?.saleInfo &&
            itemDetail?.saleInfo.length > 0 &&
            itemDetail?.saleInfo.filter(
                (item: any) => item.postedBy === profileInfo?.userId
            );
        if (saleInfo.length > 0) {
            return saleInfo[0];
        }
        return false;
    };

    const getOwnedItem = (): any => {
        const Owned =
            itemDetail?.ownedBy &&
            itemDetail?.ownedBy.length > 0 &&
            itemDetail?.ownedBy.filter(
                (item: any) => item.ownedUserId === profileInfo?.userId
            );
        if (Owned.length > 0 || isSecondarySale()) {
            return Owned[0];
        }
        return false;
    };

    const getOwnedHasQuantity = (): any => {
        const Owned =
            itemDetail?.ownedBy &&
            itemDetail?.ownedBy.length > 0 &&
            itemDetail?.ownedBy.filter(
                (item: any) => item.ownedUserId === profileInfo?.userId
            );
        if (Owned.length > 0 && Owned[0]?.quantity > 0) {
            return Owned[0];
        }
        return false;
    };

    useEffect(() => {
        setVideosArrayLength(itemDetail?.itemInfo?.video.length);
    }, [itemDetail?.itemInfo?.video]);

    const formatDate = (dateString: any): any =>
        moment(dateString).format('MMMM Do YYYY, h:mm:ss a');
    const isLaunchedDate = (sale: any): any => checkLauncDate(sale.launch_date);

    if (errorPage) return <NotFound />;

    return (
        /* eslint no-underscore-dangle: 0 */

        <Box my={4} className="NFT-details-body">
            <HeadTag title={title} />
            {loadingItemDetail && <CuratingLoading />}
            {!loadingItemDetail && itemDetail && (
                <>
                    <div className="align-media-center">
                        <Grid item xs={12} className="nft-hover-video">
                            {itemDetail?.itemInfo?.images.length > 0 ||
                            itemDetail.itemInfo.audio ||
                            itemDetail?.itemInfo?.video.length > 0 ? (
                                <Slider
                                    {...ImageSliderConfig}
                                    className="img-slider"
                                    afterChange={(cur) => setCurrnetSlide(cur)}
                                >
                                    {itemDetail.itemInfo?.video &&
                                        itemDetail.itemInfo?.video.map(
                                            (vid: any, i: number) => (
                                                <Box className="video-box">
                                                    <Video
                                                        video={getVideoUrl(vid)}
                                                        pip={pip}
                                                        playing={playing}
                                                        controls={controls}
                                                        volume={volume}
                                                        muted={muted}
                                                        loop={loop}
                                                        setPlaying={setPlaying}
                                                        currentSlide={
                                                            currentSlide
                                                        }
                                                        currnetIndex={i}
                                                        showVideoAudioMessage={
                                                            showVideoAudioMessage
                                                        }
                                                        shown={shown}
                                                        setShown={setShown}
                                                    />
                                                </Box>
                                            )
                                        )}

                                    {itemDetail?.itemInfo?.audio && (
                                        <PlayAudioWaveForm
                                            artworkname={
                                                itemDetail?.itemInfo?.name
                                            }
                                            audiofile={
                                                itemDetail?.itemInfo?.audio
                                            }
                                        />
                                    )}

                                    {/* {itemDetail?.itemInfo?.audio && (
                                        <PlayAudioWaveForm
                                            artworkname={itemDetail?.itemInfo?.name}
                                            audiofile={itemDetail?.itemInfo?.audio}
                                        />
                                    )} */}

                                    {itemDetail?.itemInfo?.images.map(
                                        (item: any, index: number) => (
                                            <>
                                                {Utils.isPlayableUrl(item) && (
                                                    <Box className="video-box">
                                                        {/* replaced AppVideoPlayer with Video */}
                                                        <Video
                                                            video={getVideoUrl(
                                                                item
                                                            )}
                                                            pip={pip}
                                                            playing={playing}
                                                            controls={controls}
                                                            volume={volume}
                                                            muted={muted}
                                                            loop={loop}
                                                            setPlaying={
                                                                setPlaying
                                                            }
                                                            currentSlide={
                                                                currentSlide
                                                            }
                                                            showVideoAudioMessage={
                                                                showVideoAudioMessage
                                                            }
                                                            shown={shown}
                                                            setShown={setShown}
                                                        />
                                                    </Box>
                                                )}
                                                {!Utils.isPlayableUrl(item) && (
                                                    <ViewImageZoom
                                                        com={
                                                            <NFTMediaBox
                                                                mediaUrl={getUrl(
                                                                    `${item}`,
                                                                    '00x00/'
                                                                )}
                                                                imgStyle="slide-image"
                                                            />
                                                        }
                                                        key={`slide_${index}`}
                                                        preview={getUrl(
                                                            `${item}`,
                                                            '700x656/'
                                                        )}
                                                        fullimage={getUrl(
                                                            `${item}`,
                                                            '00x00/'
                                                        )}
                                                    />
                                                )}
                                            </>
                                        )
                                    )}

                                    {itemDetail?.itemInfo?.images.length ===
                                        0 &&
                                        itemDetail?.itemInfo?.video.length ===
                                            0 &&
                                        itemDetail?.itemInfo.thumbnail && (
                                            <ViewImageZoom
                                                com={
                                                    <NFTMediaBox
                                                        mediaUrl={getUrl(
                                                            `${itemDetail?.itemInfo.thumbnail}`,
                                                            '00x00/'
                                                        )}
                                                        imgStyle="slide-image slide-thumbnail"
                                                    />
                                                }
                                                fullimage={getUrl(
                                                    `${itemDetail?.itemInfo.thumbnail}`,
                                                    '00x00/'
                                                )}
                                            />
                                        )}
                                </Slider>
                            ) : (
                                <div className="img-slider">
                                    <ViewImageZoom
                                        com={
                                            <NFTMediaBox
                                                mediaUrl={getUrl(
                                                    `${itemDetail?.itemInfo.thumbnail}`,
                                                    '00x00/'
                                                )}
                                            />
                                        }
                                        fullimage={getUrl(
                                            `${itemDetail?.itemInfo.thumbnail}`,
                                            '00x00/'
                                        )}
                                    />
                                </div>
                            )}

                            {videosArrayLength > 0 &&
                                currentSlide < videosArrayLength && (
                                    <Box
                                        className={`message-content ${
                                            showVideoMessage
                                                ? 'fade-in'
                                                : 'fade-out'
                                        }`}
                                    >
                                        <Typography>
                                            This artwork contains audio. Please
                                            turn on sound for better experience.
                                        </Typography>
                                    </Box>
                                )}
                        </Grid>
                    </div>
                    <Container className="nft-detail-container">
                        <Grid
                            container
                            spacing={10}
                            className="nft-detail-wrapper"
                        >
                            <Box className="nft-title-section nft-title-section-text-wrapper">
                                <Box
                                    width="100%"
                                    // display="flex"
                                    // justifyContent="space-between"
                                    className="title-n-action-btn-Wrapper-artwork-detail"
                                >
                                    <Grid
                                        style={{ width: '100%' }}
                                        item
                                        sm={12}
                                        md={7}
                                        lg={7}
                                    >
                                        <Typography variant="h3">
                                            <b>
                                                {' '}
                                                {
                                                    itemDetail?.itemInfo?.name
                                                }{' '}
                                                {itemDetail.itemInfo
                                                    .lockableContentAvailable && (
                                                    <LockIcon color="primary" />
                                                )}
                                            </b>
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        style={{ width: '100%' }}
                                        className="editionWrapper-detailpage"
                                        item
                                        sm={12}
                                        md={5}
                                        lg={5}
                                    >
                                        <Box
                                            width="100%"
                                            className="align-item-edition"
                                        >
                                            {itemDetail?.itemInfo?.quantity && (
                                                <Chip
                                                    size="small"
                                                    label={`${
                                                        itemDetail.itemInfo
                                                            .quantity
                                                    } ${
                                                        itemDetail.itemInfo
                                                            .quantity > 1
                                                            ? 'Editions'
                                                            : 'Edition'
                                                    }`}
                                                    className="PhysicalNFT"
                                                />
                                            )}
                                            {itemDetail?.itemInfo
                                                ?.physicalNFTAvailable && (
                                                <Chip
                                                    size="small"
                                                    label="Physical NFT"
                                                    className="PhysicalNFT ml-3"
                                                />
                                            )}

                                            <Box className="more-view">
                                                <Box>
                                                    {fav && (
                                                        <>
                                                            <AppTeritaryButton
                                                                className="btn-small"
                                                                title={
                                                                    <NumberPretty
                                                                        count={
                                                                            favTotal
                                                                        }
                                                                    />
                                                                }
                                                                startIcon={getFavIcon()}
                                                                onClick={(
                                                                    e: any
                                                                ) =>
                                                                    toggleFav(
                                                                        itemDetail
                                                                            .itemInfo
                                                                            .id,
                                                                        isFav,
                                                                        e
                                                                    )
                                                                }
                                                            />
                                                        </>
                                                    )}
                                                </Box>
                                                {authState.isUserLoggedIn &&
                                                    itemDetail &&
                                                    getOwnedItem() && (
                                                        <Box
                                                            display="flex"
                                                            justifyContent="between"
                                                        >
                                                            <DownloadResource
                                                                data={
                                                                    itemDetail
                                                                }
                                                            />
                                                            {getOwnedHasQuantity() && (
                                                                <MoreMenu
                                                                    // downloaddata={itemDetail}
                                                                    menus={
                                                                        menus
                                                                    }
                                                                    onMenuSelect={(
                                                                        menu: MoreMenuData
                                                                    ) => {
                                                                        if (
                                                                            menu.title ===
                                                                            'Share'
                                                                        ) {
                                                                            setShowShareDialog(
                                                                                true
                                                                            );
                                                                        } else if (
                                                                            menu.title ===
                                                                            'Report'
                                                                        ) {
                                                                            if (
                                                                                authState.isUserLoggedIn
                                                                            ) {
                                                                                setShowReport(
                                                                                    true
                                                                                );
                                                                            } else {
                                                                                Toast.showSuccesMessage(
                                                                                    'Login to report items'
                                                                                );
                                                                                navigate(
                                                                                    '/login',
                                                                                    {
                                                                                        state: {
                                                                                            nextPathName:
                                                                                                window
                                                                                                    .location
                                                                                                    .pathname,
                                                                                        },
                                                                                    }
                                                                                );
                                                                            }
                                                                        } else if (
                                                                            menu.title ===
                                                                            'Export'
                                                                        ) {
                                                                            actionExportNFT();
                                                                        }
                                                                    }}
                                                                />
                                                            )}
                                                        </Box>
                                                    )}
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Box>
                            </Box>
                            <Grid
                                item
                                xs={12}
                                className="nft-detail"
                                id="nft-detail-mobile"
                            >
                                <Box display="flex">
                                    <NFTTag tag={itemDetail.itemInfo.tag} />
                                    {itemDetail.itemInfo.physicalNFT && (
                                        <GradientChip
                                            variant={GradientVariants.LIGHT}
                                            label="Physical NFT"
                                        />
                                    )}
                                </Box>
                            </Grid>

                            <Box className="ntf-content ntf-details nft-content-over">
                                <Grid
                                    md={5}
                                    sm={12}
                                    className="ntf-detail-part-1"
                                >
                                    <NFTDetail
                                        auctionList={auctions}
                                        onPlaceBid={onPlaceBid}
                                        onBuyNFT={onBuyNFT}
                                        onPostOffer={onPostOffer}
                                        authState={authState}
                                        itemDetail={itemDetail}
                                        onListingCancel={onListingCancel}
                                        onAuctionCancel={onAuctionCancel}
                                        reloadItemDetail={reloadItemDetail}
                                    />
                                </Grid>
                                <Grid md={5} sm={12}>
                                    {itemDetail.exhibition?.curatedby &&
                                        itemDetail.exhibition?.name && (
                                            <Box className="ntf-content nft-content-box-wrapper">
                                                <Box className="detail-nft-value">
                                                    {itemDetail.exhibition
                                                        ?.curatedby && (
                                                        <Typography
                                                            className="ntf-title"
                                                            variant="body2"
                                                        >
                                                            Curated by
                                                        </Typography>
                                                    )}
                                                    <Link
                                                        to={`/curator/${itemDetail.exhibition?.curator}`}
                                                    >
                                                        <Typography
                                                            className="ntf-value detail-value underLine"
                                                            variant="body2"
                                                        >
                                                            {
                                                                itemDetail
                                                                    .exhibition
                                                                    ?.curatedby
                                                            }
                                                        </Typography>
                                                    </Link>
                                                </Box>
                                                <Box>
                                                    {itemDetail.exhibition
                                                        ?.name && (
                                                        <Typography
                                                            className="ntf-title"
                                                            variant="body2"
                                                        >
                                                            Exhibition
                                                        </Typography>
                                                    )}
                                                    <Link
                                                        to={`/exhibition/${itemDetail.exhibition?._id}`}
                                                    >
                                                        <Typography
                                                            className="ntf-value detail-value underLine"
                                                            variant="body2"
                                                        >
                                                            {
                                                                itemDetail
                                                                    .exhibition
                                                                    ?.name
                                                            }
                                                        </Typography>
                                                    </Link>
                                                </Box>
                                            </Box>
                                        )}
                                    <Grid item>
                                        {itemDetail?.saleInfo.length < 2 &&
                                        itemDetail?.saleInfo.length > 0 &&
                                        itemDetail?.itemInfo?.buttonStatus ===
                                            'launchTimer' ? (
                                            <GoesLiveUi
                                                date={
                                                    itemDetail.saleInfo[0]
                                                        ?.launch_date
                                                }
                                                title="Sale Starts In"
                                            />
                                        ) : null}
                                    </Grid>

                                    {/* TODO HIDDEN SECONDARY SALE */}
                                    {itemDetail?.saleInfo &&
                                        itemDetail?.saleInfo.length > 0 &&
                                        itemDetail?.saleInfo.map(
                                            (sale: any) => (
                                                <>
                                                    {((checkLauncDate(
                                                        sale.launch_date
                                                    ) &&
                                                        sale.pricePerQuantity >
                                                            0 &&
                                                        sale.availableQuantity >
                                                            0) ||
                                                        itemDetail?.itemInfo
                                                            ?.buttonStatus ===
                                                            'buyButton') &&
                                                        !sale.secondarySale && (
                                                            <Box
                                                                key={sale.id}
                                                                width="100%"
                                                                display="flex"
                                                                justifyContent="space-between"
                                                                alignItems="center"
                                                            >
                                                                <Box>
                                                                    <Typography
                                                                        className="ntf-value"
                                                                        variant="body2"
                                                                    >
                                                                        {
                                                                            sale.pricePerQuantity
                                                                        }{' '}
                                                                        USD
                                                                    </Typography>
                                                                    <Typography
                                                                        className="ntfvalue"
                                                                        variant="body1"
                                                                    >
                                                                        (
                                                                        {ethPrice(
                                                                            sale.pricePerQuantity
                                                                        )}{' '}
                                                                        ETH)
                                                                    </Typography>
                                                                </Box>
                                                                <>
                                                                    {/* {itemDetail.ownerInfo?.ownedUserId !== profileInfo?.userId && <Box> */}
                                                                    {itemDetail
                                                                        ?.itemInfo
                                                                        ?.buttonStatus ===
                                                                        'buyButton' && (
                                                                        <Box>
                                                                            <Box
                                                                                display="flex"
                                                                                justifyContent="flex-end"
                                                                            >
                                                                                {btnLoading ? (
                                                                                    <CircularProgress
                                                                                        className="mdl-typography--text-center"
                                                                                        size={
                                                                                            20
                                                                                        }
                                                                                    />
                                                                                ) : (
                                                                                    <Chip
                                                                                        label="BUY"
                                                                                        variant="default"
                                                                                        size="small"
                                                                                        style={{
                                                                                            background:
                                                                                                'black',
                                                                                            borderRadius:
                                                                                                '2px',
                                                                                            height: '2.4rem',
                                                                                            color: 'white',
                                                                                            width: '94px',
                                                                                            fontSize:
                                                                                                '1.2rem',
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            if (
                                                                                                authState.isUserLoggedIn
                                                                                            ) {
                                                                                                if (
                                                                                                    !sale.canBuy
                                                                                                ) {
                                                                                                    Toast.showSuccesMessage(
                                                                                                        'Owner can not to buy items'
                                                                                                    );
                                                                                                } else {
                                                                                                    buyNft(
                                                                                                        sale
                                                                                                    );
                                                                                                }
                                                                                            } else {
                                                                                                Toast.showSuccesMessage(
                                                                                                    'Login to buy items'
                                                                                                );
                                                                                                navigate(
                                                                                                    '/login',
                                                                                                    {
                                                                                                        state: {
                                                                                                            nextPathName:
                                                                                                                window
                                                                                                                    .location
                                                                                                                    .pathname,
                                                                                                        },
                                                                                                    }
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </Box>

                                                                            <Box
                                                                                mt={
                                                                                    2
                                                                                }
                                                                                display="flex"
                                                                                justifyContent="flex-end"
                                                                                alignItems="center"
                                                                            >
                                                                                {countPrimaryEdition >
                                                                                    0 && (
                                                                                    <Typography
                                                                                        className="ntfvalue"
                                                                                        style={{
                                                                                            fontSize:
                                                                                                '18px',
                                                                                        }}
                                                                                        // variant="body1"
                                                                                    >
                                                                                        {
                                                                                            countPrimaryEdition
                                                                                        }{' '}
                                                                                        Edition
                                                                                        {countPrimaryEdition >
                                                                                        1
                                                                                            ? 's'
                                                                                            : ''}{' '}
                                                                                        Remaining
                                                                                    </Typography>
                                                                                )}
                                                                            </Box>
                                                                        </Box>
                                                                    )}

                                                                    {sale.canCancel &&
                                                                        getOwnerSale() && (
                                                                            <CancelNft
                                                                                getItemDetail={
                                                                                    getItemDetail
                                                                                }
                                                                                detail={
                                                                                    itemDetail
                                                                                }
                                                                                saleInfo={getOwnerSale()}
                                                                            />
                                                                        )}
                                                                </>
                                                            </Box>
                                                        )}
                                                </>
                                            )
                                        )}
                                    {itemDetail?.saleInfo &&
                                    isSecondarySale() &&
                                    itemDetail?.saleInfo.filter(
                                        (item: any) => item.secondarySale
                                    ).length > 0 ? (
                                        <Box
                                            width="100%"
                                            p={2}
                                            mt={6}
                                            style={{
                                                boxShadow:
                                                    '0px 5px 10px rgb(0 0 0 / 10%)',
                                            }}
                                        >
                                            {isSecondarySale() && (
                                                <Box mb={2}>
                                                    <Typography>
                                                        Secondary Sale
                                                    </Typography>
                                                </Box>
                                            )}
                                            <Box width="100%">
                                                <TableContainer>
                                                    <Table
                                                        aria-label="customized table"
                                                        className="custom-table"
                                                    >
                                                        <TableHead>
                                                            <TableRow>
                                                                <StyledTableCell>
                                                                    From
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    style={{
                                                                        minWidth:
                                                                            '130px',
                                                                    }}
                                                                    align="left"
                                                                >
                                                                    Price
                                                                </StyledTableCell>
                                                                <StyledTableCell align="left">
                                                                    Qty
                                                                </StyledTableCell>
                                                                <StyledTableCell align="right" />
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody
                                                            style={{
                                                                boxShadow:
                                                                    '0px 5px 10px rgb(0 0 0 / 5%)',
                                                                marginTop:
                                                                    '10px',
                                                            }}
                                                        >
                                                            {itemDetail?.saleInfo.map(
                                                                (
                                                                    sale: any,
                                                                    i: any
                                                                ) => (
                                                                    <>
                                                                        {sale.secondarySale && (
                                                                            <StyledTableRow
                                                                                key={
                                                                                    i
                                                                                }
                                                                            >
                                                                                <StyledTableBodyCell
                                                                                    component="th"
                                                                                    scope="row"
                                                                                >
                                                                                    <Link
                                                                                        className="text-underline"
                                                                                        to={`/${sale?.type}/${sale?.userId}`}
                                                                                    >
                                                                                        @
                                                                                        {
                                                                                            sale?.userName
                                                                                        }
                                                                                    </Link>
                                                                                </StyledTableBodyCell>
                                                                                <StyledTableBodyCell align="left">
                                                                                    <Box
                                                                                        style={{
                                                                                            paddingLeft:
                                                                                                '10px',
                                                                                            borderLeft:
                                                                                                '1px solid #e0e0e0',
                                                                                            borderRight:
                                                                                                '1px solid #e0e0e0',
                                                                                        }}
                                                                                        width="100%"
                                                                                    >
                                                                                        <Typography>
                                                                                            ${' '}
                                                                                            {
                                                                                                sale.pricePerQuantity
                                                                                            }
                                                                                        </Typography>
                                                                                        <Typography>
                                                                                            {ethPrice(
                                                                                                sale.pricePerQuantity
                                                                                            )}{' '}
                                                                                            (ETH)
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </StyledTableBodyCell>
                                                                                <StyledTableBodyCell align="left">
                                                                                    {
                                                                                        sale?.availableQuantity
                                                                                    }
                                                                                </StyledTableBodyCell>
                                                                                <StyledTableBodyCell align="right">
                                                                                    <Box
                                                                                        style={{
                                                                                            borderLeft:
                                                                                                '1px solid #e0e0e0',
                                                                                            paddingLeft:
                                                                                                '10px',
                                                                                        }}
                                                                                        width="100%"
                                                                                    >
                                                                                        <>
                                                                                            {isLaunchedDate(
                                                                                                sale
                                                                                            ) &&
                                                                                                sale?.canBuy && (
                                                                                                    <Box width="100%">
                                                                                                        {btnLoading ? (
                                                                                                            <CircularProgress
                                                                                                                className="mdl-typography--text-center"
                                                                                                                size={
                                                                                                                    20
                                                                                                                }
                                                                                                            />
                                                                                                        ) : (
                                                                                                            <Chip
                                                                                                                variant="default"
                                                                                                                size="small"
                                                                                                                style={{
                                                                                                                    marginBottom:
                                                                                                                        '6px',
                                                                                                                    background:
                                                                                                                        'black',
                                                                                                                    borderRadius:
                                                                                                                        '2px',
                                                                                                                    color: 'white',
                                                                                                                    width: '100%',
                                                                                                                }}
                                                                                                                onClick={() => {
                                                                                                                    if (
                                                                                                                        authState.isUserLoggedIn
                                                                                                                    ) {
                                                                                                                        if (
                                                                                                                            !itemDetail
                                                                                                                                .itemInfo
                                                                                                                                .canBuy
                                                                                                                        ) {
                                                                                                                            Toast.showSuccesMessage(
                                                                                                                                'Owner can not to buy items'
                                                                                                                            );
                                                                                                                        } else {
                                                                                                                            buyNft(
                                                                                                                                sale
                                                                                                                            );
                                                                                                                        }
                                                                                                                    } else {
                                                                                                                        Toast.showSuccesMessage(
                                                                                                                            'Login to buy items'
                                                                                                                        );
                                                                                                                        navigate(
                                                                                                                            '/login',
                                                                                                                            {
                                                                                                                                state: {
                                                                                                                                    nextPathName:
                                                                                                                                        window
                                                                                                                                            .location
                                                                                                                                            .pathname,
                                                                                                                                },
                                                                                                                            }
                                                                                                                        );
                                                                                                                    }
                                                                                                                }}
                                                                                                                label="BUY"
                                                                                                            />
                                                                                                        )}
                                                                                                    </Box>
                                                                                                )}
                                                                                        </>
                                                                                        <>
                                                                                            {sale?.canCancel && (
                                                                                                <CancelNft
                                                                                                    getItemDetail={
                                                                                                        getItemDetail
                                                                                                    }
                                                                                                    detail={
                                                                                                        itemDetail
                                                                                                    }
                                                                                                    saleInfo={getOwnerSale()}
                                                                                                />
                                                                                            )}
                                                                                        </>
                                                                                        {!isLaunchedDate(
                                                                                            sale
                                                                                        ) && (
                                                                                            // <GoesLiveUiSecondarySale
                                                                                            //     date={
                                                                                            //         sale?.launch_date
                                                                                            //     }
                                                                                            //     title="Sale Starts In"
                                                                                            // />
                                                                                            <>
                                                                                                <Chip
                                                                                                    size="small"
                                                                                                    style={{
                                                                                                        borderRadius:
                                                                                                            '0',
                                                                                                        marginTop:
                                                                                                            '6px',
                                                                                                    }}
                                                                                                    label="Coming soon"
                                                                                                />
                                                                                            </>
                                                                                        )}
                                                                                    </Box>
                                                                                </StyledTableBodyCell>
                                                                            </StyledTableRow>
                                                                        )}
                                                                    </>
                                                                )
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Box>
                                        </Box>
                                    ) : (
                                        ''
                                    )}

                                    {offers.length > 0 && (
                                        <Box>
                                            <Box
                                                width="100%"
                                                p={2}
                                                mt={6}
                                                style={{
                                                    boxShadow:
                                                        '0px 5px 10px rgb(0 0 0 / 10%)',
                                                }}
                                            >
                                                <Box mb={2}>
                                                    <Typography>
                                                        Offers Listing
                                                    </Typography>
                                                </Box>
                                                <Box width="100%">
                                                    <TableContainer>
                                                        <Table
                                                            aria-label="customized table"
                                                            className="custom-table"
                                                        >
                                                            <TableHead>
                                                                <TableRow>
                                                                    <StyledTableCell>
                                                                        From
                                                                    </StyledTableCell>
                                                                    <StyledTableCell>
                                                                        To
                                                                    </StyledTableCell>
                                                                    <StyledTableCell
                                                                        style={{
                                                                            minWidth:
                                                                                '130px',
                                                                        }}
                                                                        align="left"
                                                                    >
                                                                        Price
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="left">
                                                                        Qty
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="left">
                                                                        Action
                                                                    </StyledTableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody
                                                                style={{
                                                                    boxShadow:
                                                                        '0px 5px 10px rgb(0 0 0 / 5%)',
                                                                    marginTop:
                                                                        '10px',
                                                                }}
                                                            >
                                                                {offers.map(
                                                                    (
                                                                        offerItem: any,
                                                                        i: any
                                                                    ) => (
                                                                        <>
                                                                            <StyledTableRow
                                                                                key={
                                                                                    i
                                                                                }
                                                                            >
                                                                                <StyledTableBodyCell
                                                                                    component="th"
                                                                                    scope="row"
                                                                                >
                                                                                    <Link
                                                                                        className="text-underline"
                                                                                        to={`/${'user'}/${
                                                                                            offerItem
                                                                                                ?.requestedBy
                                                                                                ?.userId
                                                                                        }`}
                                                                                    >
                                                                                        @
                                                                                        {
                                                                                            offerItem
                                                                                                ?.requestedBy
                                                                                                ?.userName
                                                                                        }
                                                                                    </Link>
                                                                                </StyledTableBodyCell>
                                                                                <StyledTableBodyCell
                                                                                    component="th"
                                                                                    scope="row"
                                                                                >
                                                                                    <Link
                                                                                        className="text-underline"
                                                                                        to={`/${'user'}/${
                                                                                            offerItem
                                                                                                ?.requestedTo
                                                                                                ?.userId
                                                                                        }`}
                                                                                    >
                                                                                        @
                                                                                        {
                                                                                            offerItem
                                                                                                ?.requestedTo
                                                                                                ?.userName
                                                                                        }
                                                                                    </Link>
                                                                                </StyledTableBodyCell>
                                                                                <StyledTableBodyCell align="left">
                                                                                    <Box
                                                                                        style={{
                                                                                            paddingLeft:
                                                                                                '10px',
                                                                                            borderLeft:
                                                                                                '1px solid #e0e0e0',
                                                                                            borderRight:
                                                                                                '1px solid #e0e0e0',
                                                                                        }}
                                                                                        width="100%"
                                                                                    >
                                                                                        <Typography>
                                                                                            ${' '}
                                                                                            {
                                                                                                offerItem.amount
                                                                                            }
                                                                                        </Typography>
                                                                                        <Typography>
                                                                                            {ethPrice(
                                                                                                offerItem.amount
                                                                                            )}{' '}
                                                                                            (ETH)
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </StyledTableBodyCell>
                                                                                <StyledTableBodyCell align="left">
                                                                                    {
                                                                                        offerItem?.quantity
                                                                                    }
                                                                                </StyledTableBodyCell>
                                                                                <StyledTableBodyCell align="right">
                                                                                    <Box
                                                                                        style={{
                                                                                            borderLeft:
                                                                                                '1px solid #e0e0e0',
                                                                                            paddingLeft:
                                                                                                '10px',
                                                                                        }}
                                                                                        width="100%"
                                                                                    >
                                                                                        <>
                                                                                            {offerItem.canAccept && (
                                                                                                <Box width="100%">
                                                                                                    {offerLoading &&
                                                                                                    activeOffer ===
                                                                                                        offerItem.offerId ? (
                                                                                                        <CircularProgress
                                                                                                            className="mdl-typography--text-center"
                                                                                                            size={
                                                                                                                20
                                                                                                            }
                                                                                                        />
                                                                                                    ) : (
                                                                                                        <Chip
                                                                                                            variant="default"
                                                                                                            size="small"
                                                                                                            style={{
                                                                                                                marginBottom:
                                                                                                                    '6px',
                                                                                                                background:
                                                                                                                    'black',
                                                                                                                borderRadius:
                                                                                                                    '2px',
                                                                                                                color: 'white',
                                                                                                                width: '100%',
                                                                                                            }}
                                                                                                            onClick={() => {
                                                                                                                offerChoose(
                                                                                                                    offerItem.offerId,
                                                                                                                    'accept'
                                                                                                                );
                                                                                                                console.log(
                                                                                                                    'accept'
                                                                                                                );
                                                                                                            }}
                                                                                                            label="Accept"
                                                                                                        />
                                                                                                    )}
                                                                                                </Box>
                                                                                            )}
                                                                                        </>
                                                                                        <>
                                                                                            {offerItem?.canAccept && (
                                                                                                <Box width="100%">
                                                                                                    {offerLoading &&
                                                                                                    activeOffer ===
                                                                                                        offerItem.offerId ? (
                                                                                                        <CircularProgress
                                                                                                            className="mdl-typography--text-center"
                                                                                                            size={
                                                                                                                20
                                                                                                            }
                                                                                                        />
                                                                                                    ) : (
                                                                                                        <Chip
                                                                                                            variant="default"
                                                                                                            size="small"
                                                                                                            style={{
                                                                                                                marginBottom:
                                                                                                                    '6px',
                                                                                                                background:
                                                                                                                    'black',
                                                                                                                borderRadius:
                                                                                                                    '2px',
                                                                                                                color: 'white',
                                                                                                                width: '100%',
                                                                                                            }}
                                                                                                            onClick={() => {
                                                                                                                offerChoose(
                                                                                                                    offerItem.offerId,
                                                                                                                    'reject'
                                                                                                                );
                                                                                                                console.log(
                                                                                                                    'reject'
                                                                                                                );
                                                                                                            }}
                                                                                                            label="Reject"
                                                                                                        />
                                                                                                    )}
                                                                                                </Box>
                                                                                            )}
                                                                                        </>
                                                                                    </Box>
                                                                                </StyledTableBodyCell>
                                                                            </StyledTableRow>
                                                                        </>
                                                                    )
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}

                                    {itemDetail?.itemInfo?.buttonStatus ===
                                        'soldOut' && (
                                        <Box className="sold-content">
                                            <Typography variant="h4">
                                                <Button
                                                    className="buybtn"
                                                    disabled
                                                >
                                                    SOLD
                                                </Button>
                                            </Typography>
                                        </Box>
                                    )}
                                    <Box
                                        mt={5}
                                        display="flex"
                                        justifyContent="center"
                                        width="100%"
                                        // alignItems="center"
                                    >
                                        {itemDetail?.ownedBy?.length > 0 && (
                                            <>
                                                {itemDetail?.itemInfo
                                                    ?.isOwner &&
                                                    isSecondarySale() &&
                                                    getOwnedItem() &&
                                                    !getOwnerSale() &&
                                                    getOwnedItem().quantity >
                                                        0 && (
                                                        <SellNft
                                                            getItemDetail={
                                                                getItemDetail
                                                            }
                                                            detail={itemDetail}
                                                            ownerInfo={getOwnedItem()}
                                                        />
                                                    )}
                                            </>
                                        )}
                                    </Box>

                                    {itemDetail.itemInfo.canPostOffer && (
                                        <Box
                                            mt={5}
                                            display="flex"
                                            justifyContent="center"
                                            width="100%"
                                        >
                                            <Chip
                                                variant="default"
                                                // size="large"
                                                style={{
                                                    //  marginBottom: '6px',
                                                    border: '1px solid black',
                                                    background: '#fff',
                                                    borderRadius: '2px',
                                                    // height: '2.4rem',
                                                    color: 'black',
                                                    fontSize: '1.2rem',
                                                    width: '100%',
                                                }}
                                                onClick={() => {
                                                    if (
                                                        authState.isUserLoggedIn
                                                    ) {
                                                        setOffer(true);
                                                        // postOffer("postoffer");
                                                    } else {
                                                        Toast.showSuccesMessage(
                                                            'Login to Post offer'
                                                        );
                                                        navigate('/login', {
                                                            state: {
                                                                nextPathName:
                                                                    window
                                                                        .location
                                                                        .pathname,
                                                            },
                                                        });
                                                    }
                                                }}
                                                label="POST OFFER"
                                            />
                                        </Box>
                                    )}
                                    {offer && (
                                        <PostOffer
                                            detail={itemDetail}
                                            offer
                                            setOffer={(el: boolean) =>
                                                setOffer(el)
                                            }
                                            onPostOffer={(offerData: any) =>
                                                postOfferUser(offerData)
                                            }
                                        />
                                    )}

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        className="mt-2"
                                    >
                                        {getOwnedItem() &&
                                            getOwnedItem().quantity > 0 && (
                                                <Box>
                                                    {getOwnedItem().quantity >
                                                        0 && (
                                                        <Typography
                                                            className="ntfvalue"
                                                            variant="body1"
                                                        >
                                                            You Own&nbsp;
                                                            {
                                                                getOwnedItem()
                                                                    .quantity
                                                            }
                                                            &nbsp;Edition
                                                            {getOwnedItem()
                                                                .quantity > 1
                                                                ? 's'
                                                                : ''}{' '}
                                                        </Typography>
                                                    )}
                                                </Box>
                                            )}
                                    </Box>
                                </Grid>
                            </Box>

                            <Box className="ntf-content nft-details-history">
                                <Grid
                                    md={5}
                                    sm={12}
                                    xs={12}
                                    className="main-authenticity"
                                >
                                    <Box>
                                        <Typography className="authenticityHeading">
                                            Details
                                        </Typography>
                                        <Grid container className="properties">
                                            {/* ipfs link */}
                                            {itemDetail?.itemInfo?.ipfs && (
                                                <Box className="property">
                                                    <Typography
                                                        className="title nft-type"
                                                        variant="body2"
                                                    >
                                                        Original Media
                                                    </Typography>
                                                    <Typography
                                                        className="value nft-type"
                                                        variant="body1"
                                                    >
                                                        <a
                                                            href={
                                                                itemDetail
                                                                    .itemInfo
                                                                    .ipfs
                                                            }
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            style={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                marginBottom:
                                                                    '0px',
                                                            }}
                                                        >
                                                            View on IPFS
                                                            <OpenInNewOutlined className="open-in-new" />
                                                        </a>
                                                    </Typography>
                                                </Box>
                                            )}

                                            <Box className="property">
                                                <Typography
                                                    className="title nft-type"
                                                    variant="body2"
                                                >
                                                    Metadata
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    className="value file-icon nft-type"
                                                >
                                                    <a
                                                        href={ipfsMetadataUrl}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        style={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            marginBottom: '0px',
                                                        }}
                                                    >
                                                        {Utils.formatedAddress(
                                                            itemDetail.chainInfo
                                                                .tokenId
                                                        )}
                                                        <OpenInNewOutlined className="open-in-new" />
                                                    </a>
                                                </Typography>
                                            </Box>

                                            <Box className="property">
                                                <Typography
                                                    className="title nft-type"
                                                    variant="body2"
                                                >
                                                    Contract Address
                                                </Typography>
                                                <Typography
                                                    className="value nft-type"
                                                    variant="body1"
                                                >
                                                    <a
                                                        target="_blank"
                                                        href={
                                                            itemDetail.chainInfo
                                                                .network ===
                                                            'private'
                                                                ? `${process.env.REACT_APP_POLYGON_EXPLORER}/address/${itemDetail.chainInfo.contractAddress}`
                                                                : `${process.env.REACT_APP_ETHERSCAN}/address/${itemDetail.chainInfo.contractAddress}`
                                                        }
                                                        rel="noreferrer"
                                                        style={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'flex-start',
                                                            marginBottom: '0px',
                                                        }}
                                                    >
                                                        {itemDetail.chainInfo
                                                            .contractAddress &&
                                                            Utils.formatedAddress(
                                                                itemDetail
                                                                    .chainInfo
                                                                    .contractAddress
                                                            )}

                                                        <OpenInNewOutlined className="open-in-new" />
                                                    </a>
                                                </Typography>
                                            </Box>

                                            <Box className="property">
                                                <Typography
                                                    className="title nft-type"
                                                    variant="body2"
                                                >
                                                    Blockchain
                                                </Typography>
                                                <Typography
                                                    className="value nft-type"
                                                    variant="body1"
                                                >
                                                    {itemDetail.chainInfo
                                                        .network === 'private'
                                                        ? 'Polygon'
                                                        : Utils.titleCase(
                                                              itemDetail
                                                                  .chainInfo
                                                                  .network
                                                          )}
                                                </Typography>
                                            </Box>

                                            <Box className="property">
                                                <Typography
                                                    className="title nft-type"
                                                    variant="body2"
                                                >
                                                    Token Standard
                                                </Typography>
                                                <Typography
                                                    className="value nft-type"
                                                    variant="body1"
                                                >
                                                    {
                                                        itemDetail.itemInfo
                                                            .tokenType
                                                    }
                                                    {/* <a
                                                        className="primary-link"
                                                        target="_blank"
                                                        href={
                                                            itemDetail.chainInfo
                                                                .network === 'private'
                                                                ? `${process.env.REACT_APP_POLYGON_EXPLORER}/address/${itemDetail.chainInfo.contractAddress}`
                                                                : `${process.env.REACT_APP_ETHERSCAN}/address/${itemDetail.chainInfo.contractAddress}`
                                                        }
                                                        rel="noreferrer"
                                                    >
                                                        {itemDetail.chainInfo
                                                            .contractAddress &&
                                                            Utils.formatedAddress(
                                                                itemDetail.chainInfo
                                                                    .contractAddress
                                                            )}
                                                    </a> */}
                                                </Typography>
                                            </Box>

                                            <Box className="property">
                                                <Typography
                                                    className="title nft-type"
                                                    variant="body2"
                                                >
                                                    Royalty Fee
                                                </Typography>
                                                <Typography
                                                    className="value nft-type"
                                                    variant="body1"
                                                >
                                                    {
                                                        itemDetail.itemInfo
                                                            .royalty
                                                    }
                                                    %
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid md={5} sm={12} xs={12}>
                                    <Typography className="authenticityHeading">
                                        Provenance
                                    </Typography>
                                    <Box className="nft-content-wrapper">
                                        {itemDetail.tradingInfo &&
                                            itemDetail.tradingInfo.length > 0 &&
                                            itemDetail.tradingInfo.map(
                                                (item: any) => (
                                                    <Box
                                                        key={item.id}
                                                        className="ntf-content properties nft-content-box-wrapper"
                                                    >
                                                        <img
                                                            className="img-icons"
                                                            src={`/icons/${item?.event}.svg`}
                                                            alt={`${item?.event}-icon`}
                                                        />
                                                        <Box className="property property-history">
                                                            <Box width="100%">
                                                                <Box
                                                                    width="100%"
                                                                    display="flex"
                                                                    justifyContent="space-between"
                                                                >
                                                                    <Typography
                                                                        className="title title-text-line"
                                                                        variant="body2"
                                                                    >
                                                                        <span className="title-first">
                                                                            {item.event ===
                                                                            'lazy_mint'
                                                                                ? 'Created'
                                                                                : item.event}
                                                                        </span>{' '}
                                                                        {/* {
                                                                            itemDetail
                                                                                .itemInfo
                                                                                .name
                                                                        }{' '} */}
                                                                        {getEvent(
                                                                            item
                                                                        )}
                                                                    </Typography>
                                                                    {item?.amount >
                                                                        0 && (
                                                                        <Chip
                                                                            size="small"
                                                                            label={`${item?.amount} USD`}
                                                                        />
                                                                    )}
                                                                </Box>
                                                                <Typography
                                                                    className="history-time"
                                                                    variant="body2"
                                                                >
                                                                    {formatDate(
                                                                        item.timestamp
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                )
                                            )}
                                    </Box>
                                </Grid>
                            </Box>
                        </Grid>

                        <Box className="item-ntf-contant">
                            <MyNFTsPage
                                details={itemDetail.creatorInfo.id}
                                artWork={itemDetail.itemInfo.id}
                            />
                        </Box>
                    </Container>
                </>
            )}
            {showCheckout && (
                <NFTCheckoutForm
                    showCheckout={showCheckout}
                    actionCloseCheckoutDialog={() => {
                        setShowCheckout(false);
                        setBtnloading(false);
                    }}
                    itemDetail={itemDetail}
                    listingToBuy={listingToBuy}
                    purchaseType={purchaseType}
                    auctionToBid={auctionToBid}
                    appState={appState}
                    onPaymentResponse={onPaymentResponse}
                    buyStart={buyStart}
                    buyEnd={buyEnd}
                />
            )}
            {openExportNFT && (
                <NFTExportForm
                    {...{
                        openExportNFT,
                        itemDetail,
                        onCloseExportDialog: (reloadItems: boolean) => {
                            setOpenExportNFT(false);
                            if (reloadItems) {
                                reloadItemDetail();
                            }
                        },
                    }}
                />
            )}
            {/* {itemDetail?.canRequest && (
                <NFTPostOfferForm
                    showOffer={showOffer}
                    actionCloseCheckoutDialog={() => {
                        setShowOffer(false);
                    }}
                    itemDetail={itemDetail}
                    appState={appState}
                    onPaymentResponse={onPaymentResponse}
                />
            )} */}
            {/* {order&&order === "success"? :'' } */}
        </Box>
    );
};

const mapStateToProps = (state: AppState): any => ({
    profileInfo: state.profileInfo,
    authState: state.authState,
    appState: state.applicationState,
});

export default connect(mapStateToProps, null)(NFTDetailPage);
