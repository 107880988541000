import { useState, useCallback, useEffect } from 'react';
import truncate from 'html-truncate';
import { Chip, Typography } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const ArtistReadMore = (props: any): any => {
    const { byWords, parentClass, readLess, readMore, children, length, ellipsis, spaceBefore } = props;
    const [showMore, setShowMore] = useState(false);

    // truncate.setup({
    //     byWords: !!byWords,
    //     ellipsis: ellipsis ? ellipsis : '',
    // })

    const [state, setState] = useState({
        showRealData: false,
        realData: children,
        truncatedData: truncate(children, length)
    });

    const handleShowData = useCallback(() => {
        setState(prevState => ({
            ...prevState,
            showRealData: !prevState.showRealData
        }));
    }, [setState]);

    useEffect(() => {
        if (byWords && props.children) {
            const textDetails = props.children.replace(/<[^>]+>/g, '');
            const arr = textDetails.split(" ");
            if (textDetails.length > length) {
                setShowMore(true);
            }
        }
    }, [byWords])

    return (
        <div className={parentClass}>
            <Typography
                className='artist-Detail-description'
                dangerouslySetInnerHTML={{
                    __html: `${!state.showRealData ? state.truncatedData : state.realData
                        }`
                }}
            />

            {spaceBefore === false ? '' : ' '}

            {
                showMore && <Chip size='small' clickable
                    onClick={handleShowData}
                    className='readmore-global'
                    deleteIcon={<ArrowForwardIosIcon fontSize='small' />} label={(!state.showRealData ? "Read more " : "Read Less")} />
            }
        </div>
    );
};

export default ArtistReadMore;