import { ReactElement } from 'react';
import { Box, Typography } from '@material-ui/core';

const ProfileEmptyPlaceholder = (props: any): ReactElement => {
    const { icon, message } = props;
    return (
        <Box className="justify-center primary-btn float-left" p={3}>
            {icon}
            <Typography
                variant="body1"
                className="ml-2 align-content"
            >
                {message}
            </Typography>
        </Box>
    );
};

export default ProfileEmptyPlaceholder;
