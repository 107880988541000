import { ReactElement, useEffect, useState } from 'react';
// import Typography from '@material-ui/core/Typography';
import { Box, CardMedia, Grid, Link } from '@material-ui/core';

// import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Slider from 'react-slick';
import { EmptyPlaceholder, SlideNextArrow, SlidePrevArrow } from '../../../shared/components';
// import { CollectionService } from '../../../services';
import { getUrl } from '../../../shared/helpers/Utils';

const ImageSliderConfig = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SlideNextArrow />,
    prevArrow: <SlidePrevArrow />,
};

const MobileImageSliderConfig = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SlideNextArrow />,
    prevArrow: <SlidePrevArrow />,
};

const LatestAnnouncement = (props: any): ReactElement => {
    const { announcements } = props;

    return (
        <Box width='100%'>
            {announcements === undefined || announcements.length === 0 && (
                <EmptyPlaceholder
                    icon={<img src="/icons/NoData.png" />}
                />
            )}
            <Box
                className='mobile-announcement-img-wrapper'
            >
                <Slider
                    className='announcement-img-wrapper'
                    {...MobileImageSliderConfig}
                >
                    {announcements.map((item: any): any => (
                        <div key={item}>
                            <Box p={1}>
                                <Box width='100%'
                                    display='flex'
                                    justifyContent='center'
                                    height='100%'
                                    className='how-it-work-content'
                                >
                                    {item && item.link ? <Link href={item?.link} target="_blank">
                                        <CardMedia
                                            component='img'
                                            width='100%'
                                            height='100%'
                                            src={getUrl(item?.mobile_image?.length>0?item?.mobile_image:item?.desktop_image, '00x700/')} />
                                    </Link>
                                        :
                                        <CardMedia
                                            component='img'
                                            width='100%'
                                            height='100%'
                                            src={getUrl(item?.mobile_image?.length>0?item?.mobile_image:item?.desktop_image, '00x700/')} />
                                    }
                                </Box>
                            </Box>
                        </div>
                    ))}
                </Slider>
                {/* <div>
                    {
                        announcements?.map((item: any): any => (
                            <div key={item} className='imageBox'>
                                <Box width='100%'
                                    display='flex'
                                    justifyContent='center'
                                    height='100%'
                                    className='how-it-work-content'

                                >
                                    {item && item.link ?
                                        <Link href={item?.link} target="_blank">
                                            <CardMedia
                                                component='img'
                                                width='100%'
                                                height='100%'
                                                src={getUrl(item?.mobile_image, '00x700/')} />

                                        </Link> :
                                        <CardMedia
                                            component='img'
                                            width='100%'
                                            height='100%'
                                            src={getUrl(item?.mobile_image, '00x700/')} />
                                    }
                                </Box>
                            </div>
                        ))
                    }
                </div> */}
            </Box>
            <div className='desktop-announcement-img-wrapper'>
                <Slider
                    className='announcement-img-wrapper'
                    {...ImageSliderConfig}
                >
                    {announcements.map((item: any): any => (
                        <div key={item}>
                            <Box p={1}>
                                <Box width='100%'
                                    display='flex'
                                    justifyContent='center'
                                    height='100%'
                                    className='how-it-work-content'
                                >
                                    {item && item.link ? <Link href={item?.link} target="_blank">
                                        <CardMedia
                                            component='img'
                                            width='100%'
                                            height='100%'
                                            src={getUrl(item?.desktop_image, '00x700/')} />
                                    </Link>
                                        :
                                        <CardMedia
                                            component='img'
                                            width='100%'
                                            height='100%'
                                            src={getUrl(item?.desktop_image, '00x700/')} />
                                    }
                                </Box>
                            </Box>
                        </div>
                    ))}
                </Slider>
            </div>

        </Box>
    )
}

export default LatestAnnouncement