import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    // Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Switch,
    TextField,
    Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';

import {
    CardNumberElement,
    Elements,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import { StripeCardNumberElement, loadStripe } from '@stripe/stripe-js';
import { ReactElement, useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import {
    AppCheckBox,
    AppPrimaryButton,
    AppThemeRadioInput,
} from '../../../shared/components/AppComponents';
import { AppState } from '../../../state/RootReducer';
// import { FractoCard } from '../../../shared/components';
import { BuySellService, ProfileService } from '../../../services';
import { StripeCard } from '../../../shared/components';
import { BlockChain, Toast, Utils } from '../../../shared/helpers';
import { getUrl } from '../../../shared/helpers/Utils';
import { useDebouncedEffect } from '../../../shared/hooks';
import { ApplicationDataState } from '../../../state/application/Reducer';
import CryptoAccountForm from '../../profile/components/CryptoAccountForm';
import { CryptoAccountInfo } from '../../profile/components/CryptoAccountList';
import { Auction, ItemFullDetail, Listing } from '../NFTDetailPage';
import PriceCountDown from './PriceCountDown';

const isWindowContext = typeof window !== 'undefined';

const isMobileDevice = /Mobi/i.test(window.navigator.userAgent);

const _self: any = window;

const publicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
// // const CASHBACK_WITHIN_TIME = 180;
const stripePromise = loadStripe(publicKey!);
export interface Wallet {
    currencyType: string;
    freeBalance: string;
    lockedBalance: string;
    totalBalance: string;
}

interface Currency {
    amount: string;
    currencyType: string;
}

export interface CurrencyConvertion {
    from: Currency;
    to: Currency;
    holdingId: string;
    expiresIn: Date;
}

export interface CryptoBuySummary {
    auctionId: string;
    userId: string;
    saleId: string;
    itemId: string;
    sellerWithdrawalAddress: string;
    totalCost: string;
    sellerCost: string;
    randomHex: string;
}
interface Currency {
    amount: string;
    currencyType: string;
}

export interface NFTCheckoutProps {
    itemDetail: ItemFullDetail;
    listingToBuy: Listing;
    auctionToBid: Auction;
    purchaseType: string;
    appState: ApplicationDataState;
    onPaymentResponse: any;
}

const NFTCheckout = (props: any): ReactElement => {
    const {
        onPaymentResponse,
        appState,
        itemDetail,
        listingToBuy,
        auctionToBid,
        purchaseType,
        buyEnd,
        setShowCountDown,
    } = props;
    const [quantity, setQuantity] = useState('1');
    const [quantityError, setQuantityError] = useState('');
    const [bidAmount, setBidAmount] = useState('');
    const [bidFormError, setBidFormError] = useState('');
    const [paymentMode, setPaymentMode] = useState('fiat');
    const [showLoading, setShowLoading] = useState(false);
    const [showCard, setShowCard] = useState(false);
    const [aggreed, setAggreed] = useState(false);
    const [convertingPrice, setConvertingPrice] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [validCard, setValidCard] = useState(false);
    const [paymentResponse, setPaymentResponse] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const [wallet, setWallet] = useState<Wallet>({} as Wallet);
    const [metamaskAccountDetails, setMetamaskAccountDetails] = useState({
        address: '',
        balance: '',
    });
    const [editions, setEditions] = useState<string[]>([]);
    const [cryptoBuySummary, setCryptoBuySummary] = useState<CryptoBuySummary>({
        saleId: '',
        itemId: '',
        sellerWithdrawalAddress: '',
        totalCost: '',
        sellerCost: '',
        randomHex: '',
        userId: '',
        auctionId: '',
    });
    const [currencyConverstion, setCurrencyConverstion] = useState<
        CurrencyConvertion | undefined
    >();
    const [cardForm, setCardForm] = useState({
        cardNumber: '',
        cardCvc: '',
        cardExpiry: '',
    });

    const [tripleA, setTripleA] = useState({ widgetUrl: '' });
    // const [randomEdition, setRandomEdition] = useState<string[]>([]);
    const stripe: any = useStripe();
    _self.stripe = stripe;
    const elements = useElements();
    // 0. Bid Amount or Quantity Form
    // 1. Checkout summary
    // 2. Sucess/Failure

    const windowObject: any = window;

    const history = useHistory();
    const navigate = useNavigate();

    const [otpEnable, setOtpEnable] = useState(false);
    const [otpScreen, setOtpScreen] = useState('');

    const [step, setStep] = useState(0);
    const [cashBackAmount, setCashBackAmount] = useState<any>(null);
    const [loadMinting, setLoadMinting] = useState(false);
    const [mintingFee, setMintingFee] = useState({
        mintFee: 0,
        transferFee: 0,
        expireIn: moment(),
        currency: 'USD',
    });

    const convertion: any = useSelector(
        (state: AppState) => state.applicationState.currencyConverstion
    );

    const [walletPreferenceLoading, setWalletPreferenceLoading] =
        useState(true);
    const [directNFTTransfer, setDirectNFTTransfer] = useState(false);
    const [transferWalletAddress, setTransferWalletAddress] = useState(null);
    const [showAddWalletLink, setShowAddWalletLink] = useState(false);
    const [openAccountCreateModal, setOpenAccountCreateModal] = useState(false);

    const [cryptoAccounts, setCryptoAccounts] = useState<CryptoAccountInfo[]>(
        []
    );
    const [cryptoAccountInfoToEdit, setCryptoAccountInfoToEdit] = useState<
        CryptoAccountInfo | undefined
    >(undefined);
    const [modalFormType, setModalFormType] = useState('');
    // API Calls
    const loadProfileDetails = (): void => {
        ProfileService.getProfile()
            .then((response: any) => {
                const { directNFTTransfer: profileDirectNFTTransfer } =
                    response.data.data;

                setDirectNFTTransfer(profileDirectNFTTransfer);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
            });
    };

    const loadCryptoAccountDetails = (): void => {
        setWalletPreferenceLoading(true);
        ProfileService.getCrpytoAccountDetails()
            .then((response: any) => {
                const {
                    addressDetails,
                }: { addressDetails: CryptoAccountInfo[] } = response.data.data;
                setCryptoAccounts(addressDetails);

                if (
                    addressDetails.length > 0 &&
                    addressDetails.find((x) => x.cryptoType === 'ETH')
                ) {
                    setShowAddWalletLink(false);
                    setTransferWalletAddress(
                        addressDetails.find((x) => x.cryptoType === 'ETH')
                            ?.address
                    );
                } else {
                    setShowAddWalletLink(true);
                }

                setWalletPreferenceLoading(false);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
                setWalletPreferenceLoading(false);
            });
    };

    const updateDirectTransferStatus = (): any => {
        setDirectNFTTransfer(!directNFTTransfer);
        const reqObj = {
            directNFTTransfer: !directNFTTransfer,
        };
        ProfileService.updateDirectTransferStatus(reqObj)
            .then((response) => {
                Utils.handleSuccessResponse(response);
                setDirectNFTTransfer(!directNFTTransfer);
            })
            .catch((error) => {
                Utils.handleErrorResponse({ error });
            });
    };

    const buyCompleteOnTime = (saleId: any, itemId: any, amount: any): any => {
        const requestObj = {
            saleId,
            itemId,
            amount,
        };
        BuySellService.successCashBack(requestObj)
            .then((response: any) => {
                const data: any = response?.data?.data;
                setCashBackAmount(data?.amount);
                setShowCountDown(false);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
            });
    };

    const lazyMintFee = (reqObj: any): void => {
        if (itemDetail.itemInfo.itemStatus === 'not_mint') {
            setLoadMinting(true);
            const expire = moment(new Date()).add(1, 'minutes');
            BlockChain.getMintFee()
                .then((res) => {
                    setLoadMinting(false);
                    const mintFee = (
                        Number(res.mintFee) * Number(convertion.to.amount)
                    ).toFixed(2);
                    const transferFee = (
                        Number(res.transferFee) *
                        Number(convertion.to.amount)
                    ).toFixed(2);
                    const data = {
                        mintFee: Number(mintFee),
                        transferFee: Number(transferFee),
                        expireIn: expire,
                        currency: 'USD',
                    };
                    setMintingFee(data);
                })
                .catch((error) => {
                    setLoadMinting(false);
                    Utils.handleErrorResponse({ error });
                });

            // BuySellService.mintFee(reqObj)
            //     .then((response: any) => {
            //         setLoadMinting(false)
            //         const data: any = response?.data?.data;
            //         setMintingFee(data)
            //     })
            //     .catch((error) => {
            //         setLoadMinting(false)
            //         Utils.handleErrorResponse({error})
            //     })
        } else {
            setLoadMinting(true);
            const expire = moment(new Date()).add(1, 'minutes');

            BlockChain.getMintFee()
                .then((res) => {
                    setLoadMinting(false);
                    const transferFee = (
                        Number(res.transferFee) *
                        Number(convertion.to.amount)
                    ).toFixed(2);
                    const data = {
                        mintFee: 0,
                        transferFee: Number(transferFee),
                        expireIn: expire,
                        currency: 'USD',
                    };
                    setMintingFee(data);
                })
                .catch((error) => {
                    setLoadMinting(false);
                    Utils.handleErrorResponse({ error });
                });
        }
    };
    const convertFiatToCryptoPrice = (total: number): void => {
        setConvertingPrice(true);
        const type = listingToBuy
            ? listingToBuy.currencyType
            : auctionToBid.currencyType;
        BuySellService.convertPriceAndHold(total, type, 'ETH')
            .then((response) => {
                const converstion = response.data.data;
                setCurrencyConverstion(converstion);
                setConvertingPrice(false);
            })
            .catch(() => {
                setConvertingPrice(false);
            });
    };

    const processInitiateResponse = async (
        paymentMethod: string,
        response: any
    ): Promise<any> => {
        if (paymentMethod === 'crypto') {
            setCryptoBuySummary(response);
            // setRandomEdition(response.editions);
            setShowLoading(true);
            BlockChain.setUpMetaMask()
                .then((metaMaskResponse: any) => {
                    setShowLoading(false);
                    setMetamaskAccountDetails(metaMaskResponse);
                    setStep(1);
                })
                .catch((error) => {
                    setShowLoading(false);
                    if (error.code || error.message) {
                        Toast.showErrorMessage(error.message);
                    } else {
                        Toast.showErrorMessage(error);
                    }
                });
            setShowCard(false);
        } else {
            if (paymentMode === 'tripleA') {
                setStep(2);
            } else {
                setStep(1);
            }
            setClientSecret(response.paymentIntent);
            _self.clientSecret = response.paymentIntent;
            setShowCard(response.cardRequired);
            setShowCountDown(true);
            setWallet(response.fiatBalance);
            // setRandomEdition(response.editions);
        }
    };

    const initiateBuy = (paymentType: string, holdingId = ''): void => {
        setPaymentMode(paymentMode);
        setShowLoading(true);
        let data = {};
        /* eslint no-underscore-dangle: 0 */

        if (purchaseType === 'direct') {
            if (paymentType === 'crypto') {
                data = {
                    // saleId: itemDetail.saleInfo._id,
                    saleId: listingToBuy?.id,
                    itemId: itemDetail.itemInfo.id,
                    // quantity: editions.length,
                    quantity,
                    holdingId,
                    type: 'sale',
                    // editions,
                };
            } else if (paymentMode === 'tripleA') {
                data = {
                    // amount: listingToBuy.amount,
                    // saleId: itemDetail.saleInfo._id,
                    saleId: listingToBuy?.id,
                    orderCurrency: 'USD',
                    itemId: itemDetail.itemInfo.id,
                    successUrl: `${window.location.href}?orderStatus=success`,
                    cancelUrl: `${window.location.href}?orderStatus=failure`,
                    // quantity: editions.length,
                    quantity,
                    mintFee: mintingFee.mintFee,
                    transferFee: mintingFee.transferFee,
                    // editions,
                };
            } else {
                data = {
                    // amount: listingToBuy.amount,
                    saleId: listingToBuy?.id,
                    // orderCurrency:"USD",
                    itemId: itemDetail.itemInfo.id,
                    // quantity: editions.length,
                    quantity,
                    modeOfPayment: 'card',
                    paymentType: 'stripe',
                    mintFee: mintingFee.mintFee,
                    transferFee: mintingFee.transferFee,
                    // editions,
                };
            }
        }
        console.log(listingToBuy);

        const promise =
            paymentType === 'crypto'
                ? BuySellService.initiateBuyCryptoCalculation(data)
                : paymentMode === 'tripleA'
                ? BuySellService.initiateBuyTripleA(data)
                : BuySellService.initiateBuy(data);
        promise
            .then((res: any) => {
                const response = res.data.data;
                const { widgetUrl } = response;
                if (widgetUrl) setTripleA({ widgetUrl });
                setShowLoading(false);
                processInitiateResponse(paymentMode, response);
            })
            .catch((error) => {
                Utils.handleErrorResponse({
                    error,
                });
                setShowLoading(false);
            });
    };

    // NFT Purchase using Fiat
    const completeBuy = (paymentId: string): void => {
        setShowLoading(true);
        const data: any = {
            saleId: listingToBuy._id,
            itemId: itemDetail.itemInfo.id,
            quantity: Number(quantity),
            // editions: randomEdition,
        };
        // Append payment id if payment done from stripe
        if (paymentId !== '') {
            data.paymentId = paymentId;
        }
        BuySellService.completeBuy(data)
            .then(() => {
                setShowLoading(false);
                setPaymentStatus(true);
                setPaymentResponse(
                    'Congrats! Your transaction has been completed successfully. You are now the owner of this NFT.'
                );
                setStep(2);
                onPaymentResponse(true);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
                setShowLoading(false);
                setPaymentStatus(false);
                setPaymentResponse('Item purchase failed');
                setStep(2);
            });
    };

    // NFT purchase using crypto
    const completeBuyForCrypto = (hash: any, walletAddress: any): void => {
        setShowLoading(true);
        const data: any = {
            quantity: Number(quantity),
            saleId: cryptoBuySummary.saleId,
            itemId: cryptoBuySummary.itemId,
            txHash: hash,
            metamaskAddress: walletAddress,
            randomHex: `${cryptoBuySummary.randomHex}`,
            totalPrice: cryptoBuySummary.totalCost,
            // editions: randomEdition,
        };

        BuySellService.buyFromEther(data)
            .then((res: any) => {
                const message = res.data.msg;
                setShowLoading(false);
                setPaymentStatus(true);
                onPaymentResponse(true);
                setPaymentResponse(message);
                setStep(2);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
                setShowLoading(false);
                setPaymentStatus(false);
                setPaymentResponse('Item purchase failed');
                setStep(2);
            });
    };

    // Bid post using Fiat
    const completeAuctionBid = (paymentId: string): void => {
        setShowLoading(true);
        const data: any = {
            saleId: auctionToBid.saleId,
            itemId: itemDetail.itemInfo.id,
            amount: Number(bidAmount),
        };
        // Append payment id if payment done from stripe
        if (paymentId !== '') {
            data.paymentId = paymentId;
        }
        BuySellService.placeBid(data)
            .then(() => {
                setShowLoading(false);
                setPaymentStatus(true);
                setPaymentResponse('Bidding added successfully');
                setStep(2);
                onPaymentResponse(true);
            })
            .catch(() => {
                setShowLoading(false);
                setPaymentStatus(false);
                setPaymentResponse('Bidding added failed');
                setStep(2);
            });
    };

    // Post bid using crypto
    const postBidForCrypto = (hash: any, walletAddress: any): void => {
        setShowLoading(true);
        const data: any = {
            saleId: cryptoBuySummary.saleId,
            itemId: cryptoBuySummary.itemId,
            txHash: hash,
            metamaskAddress: walletAddress,
            randomHex: `${cryptoBuySummary.randomHex}`,
            totalPrice: `${currencyConverstion?.to.amount} ETH`,
            amount: Number(parseFloat(bidAmount).toFixed(2)),
        };

        BuySellService.postABidUsingCrypto(data)
            .then((res: any) => {
                const message = res.data.msg;
                setShowLoading(false);
                setPaymentStatus(true);
                setPaymentResponse(message);
                setStep(2);
                onPaymentResponse(true);
            })
            .catch(() => {
                setShowLoading(false);
                setPaymentStatus(false);
                setPaymentResponse('NFT bidding failed');
                setStep(2);
            });
    };

    const initiateCryptoPayment = async (): Promise<any> => {
        setShowLoading(true);
        BlockChain.buyNFT(cryptoBuySummary)
            .then(async (response: any) => {
                completeBuyForCrypto(response.hash, response.verifiedAddress);
            })
            .catch((error: any) => {
                setShowLoading(false);
                if (error.code || error.message) {
                    Toast.showErrorMessage(error.message);
                } else {
                    Toast.showErrorMessage(error);
                }
            });
    };

    const initiateCryptoPaymentForAuction = async (): Promise<any> => {
        setShowLoading(true);
        BlockChain.bidCryptoForAuction(
            cryptoBuySummary,
            `${currencyConverstion?.to.amount}`
        )
            .then(async (response: any) => {
                postBidForCrypto(response.hash, response.verifiedAddress);
            })
            .catch((error: any) => {
                setShowLoading(false);
                if (error.code || error.message) {
                    Toast.showErrorMessage(error.message);
                } else {
                    Toast.showErrorMessage(error);
                }
            });
    };

    // Instance Methods
    const getSubTotal = (): number => {
        if (purchaseType === 'direct') {
            return parseInt(quantity, 10) * listingToBuy.pricePerQuantity;
        }
        return auctionToBid.quantity * Number(bidAmount);
    };

    const onMintPriceExpire = (): void => {
        const mintReq = {
            itemId: itemDetail.itemInfo.id,
            quantity: Number(quantity),
        };
        lazyMintFee(mintReq);
    };

    const onPriceExpire = (): void => {
        if ((step === 0 || step === 1) && paymentMode === 'crypto') {
            convertFiatToCryptoPrice(getSubTotal());
        }
    };

    const getConfirmButtonTitle = (): string => {
        if (
            paymentMode === 'fiat' &&
            itemDetail.itemInfo.itemStatus === 'not_mint'
        ) {
            return `CONFIRM & PAY  ${(
                parseInt(quantity, 10) * listingToBuy.pricePerQuantity +
                mintingFee?.mintFee +
                mintingFee?.transferFee
            ).toFixed(2)}  ${listingToBuy.currencyType}`;
        }

        if (paymentMode === 'fiat' && purchaseType === 'direct') {
            return `CONFIRM & PAY ${getSubTotal() + mintingFee?.transferFee} ${
                listingToBuy.currencyType
            }`;
        }
        if (paymentMode === 'fiat' && purchaseType === 'auction') {
            return `CONFIRM & PAY ${getSubTotal()} ${
                auctionToBid.currencyType
            }`;
        }
        return `CONFIRM & PAY ${currencyConverstion?.to.amount} ${currencyConverstion?.to.currencyType}`;
    };

    const actionToProfile = (): any => {
        navigate('/profile/view', { state: { payment: 'Payment History' } });
        history?.go(0);
    };

    const actionToMarketPlace = (): any => {
        navigate('/marketplace');
        history?.go(0);
    };

    // Hooks
    useEffect(() => {
        loadCryptoAccountDetails();
        loadProfileDetails();
    }, []);

    useEffect(() => {
        if (listingToBuy) {
            if (listingToBuy.acceptedPaymentMethod === 'fiat') {
                setPaymentMode('fiat');
            }
        }
    }, [listingToBuy]);

    useEffect(() => {
        if (auctionToBid) {
            if (auctionToBid.acceptedPaymentMethod === 'fiat') {
                setPaymentMode('fiat');
            }
            setEditions(auctionToBid.editionQuantity);
        }
    }, [auctionToBid]);

    useEffect(() => {
        if (listingToBuy || auctionToBid) {
            if (paymentMode === 'crypto') {
                convertFiatToCryptoPrice(getSubTotal());
            }
        }
    }, [paymentMode]);

    useDebouncedEffect(
        (): void => {
            if (listingToBuy || auctionToBid) {
                if (paymentMode === 'crypto') {
                    if (editions.length > 0) {
                        convertFiatToCryptoPrice(getSubTotal());
                    }
                }
            }
        },
        [paymentMode, quantity, bidAmount],
        1000
    );

    useEffect(() => {
        const mintReq = {
            itemId: itemDetail.itemInfo.id,
            quantity: Number(quantity),
        };
        lazyMintFee(mintReq);
    }, []);

    // UI Actions
    const actionCancel = (): void => {
        setOpenAccountCreateModal(false);
        loadCryptoAccountDetails();
        loadProfileDetails();
    };

    const actionOpenAddModal = (): void => {
        setOpenAccountCreateModal(true);
        setModalFormType('Add');
        setCryptoAccountInfoToEdit(undefined);
    };

    const actionOpenEditModal = (): void => {
        setOpenAccountCreateModal(true);
        setModalFormType('Edit');
        setCryptoAccountInfoToEdit(
            cryptoAccounts.find((x) => x.cryptoType === 'ETH')
        );
    };

    const setPurchaseQuantity = (qty: number): void => {
        let error = '';
        if (qty === 0) {
            error = `Please enter quantity to buy`;
        } else if (qty > listingToBuy.availableQuantity) {
            error = `Maximum quantity should be ${listingToBuy.availableQuantity}`;
        }
        setQuantity(`${qty}`);
        setQuantityError(error);
        convertFiatToCryptoPrice(qty * listingToBuy.pricePerQuantity);
        const mintReq = {
            itemId: itemDetail.itemInfo.id,
            quantity: qty,
        };
        lazyMintFee(mintReq);
    };

    const setBiddingAmount = (amount: string): void => {
        let error = '';
        const floatAmount = parseFloat(amount);
        let minBidAmount = 0;
        minBidAmount =
            auctionToBid.bids.length > 0
                ? auctionToBid.highestBid
                : auctionToBid.minimumPrice;
        setBidAmount(amount);
        if (floatAmount <= minBidAmount) {
            error = `Please enter valid bid amount greater than ${minBidAmount} ${auctionToBid.currencyType}`;
        } else if (Number.isNaN(floatAmount) && amount !== '') {
            error = `Please enter valid bid amount`;
        } else if (Number.isNaN(parseInt(amount, 10))) {
            error = `Please enter valid bid amount`;
        }
        setBidFormError(error);
    };

    const actionInitiateBuy = (paymentMethod: string): void => {
        initiateBuy(paymentMethod, currencyConverstion?.holdingId);
    };

    const actionProcessStripePayment = async (): Promise<any> => {
        setShowLoading(true);
        const payload = await stripe!.confirmCardPayment(
            clientSecret,
            {
                payment_method: {
                    card: elements!.getElement(
                        CardNumberElement
                    ) as StripeCardNumberElement,
                },
                return_url: window.location.href,
            },
            { handleActions: false }
        );

        if (payload.paymentIntent) {
            setOtpEnable(true);
            setStep(3);
            if (payload.paymentIntent?.next_action) {
                setOtpScreen(
                    payload.paymentIntent?.next_action?.redirect_to_url.url
                );
                onPaymentResponse(true);
            }
            onPaymentResponse(true);
        } else if (payload.error) {
            setShowLoading(false);
            setPaymentStatus(false);
            setPaymentResponse(`${payload.error.message}`);
            setStep(3);
        } else if (purchaseType === 'direct') {
            // completeBuy(payload.paymentIntent.id);
            console.log('Order pleased....');
            setShowLoading(false);
            setPaymentStatus(true);
            setPaymentResponse('Your order is placed successfully.');
            setStep(3);
            onPaymentResponse(true);
        }
    };

    const actionProcessFractoPayment = (): void => {
        const card = elements!.getElement(
            CardNumberElement
        ) as StripeCardNumberElement;
        const ccnumber = cardForm.cardNumber.replace(/ +/g, '').trim();
        const ccexp = cardForm.cardExpiry.replace('/', '').trim();
        const cvv = cardForm.cardCvc.trim();

        setShowLoading(true);
        const requestObj: any = {
            ccnumber,
            ccexp,
            cvv,
            amount: purchaseType === 'direct' ? listingToBuy.price : bidAmount,
            saleId:
                purchaseType === 'direct'
                    ? listingToBuy.id
                    : auctionToBid.saleId,
            itemId: itemDetail.itemInfo.id,
            // quantity: editions.length,
            quantity,
            // editions: randomEdition,
        };
        if (purchaseType === 'direct') {
            BuySellService.buyFracto(requestObj)
                .then((response: any) => {
                    const data: any = response?.data?.data;
                    setShowLoading(false);
                    setPaymentStatus(true);
                    setPaymentResponse(
                        'Congrats! Your transaction has been completed successfully. You are now the owner of this NFT.'
                    );
                    setStep(2);
                    onPaymentResponse(true);
                    const endTime: any = new Date();
                    if (
                        buyEnd > endTime &&
                        itemDetail.itemInfo.cashbackAvailable
                    ) {
                        buyCompleteOnTime(
                            listingToBuy.id,
                            itemDetail.itemInfo.id,
                            data.amount
                        );
                    }
                })
                .catch((error: any) => {
                    console.log('error: ', error);
                    Utils.handleErrorResponse({ error });
                    setShowLoading(false);
                    setPaymentStatus(false);
                    setPaymentResponse('Item purchase failed');
                    setStep(2);
                });
        } else {
            // call fracto bid api
            if (showCard) {
                requestObj.cardRequired = showCard;
            }
            BuySellService.placeBidWithFracto(requestObj)
                .then(() => {
                    setShowLoading(false);
                    setPaymentStatus(true);
                    setPaymentResponse('Bidding added successfully');
                    setStep(2);
                    onPaymentResponse(true);
                })
                .catch((error: any) => {
                    Utils.handleErrorResponse({ error });
                    setShowLoading(false);
                    setPaymentStatus(false);
                    setPaymentResponse('Bidding added failed');
                    setStep(2);
                });
        }
    };

    const actionConfirmBuy = (): void => {
        if (purchaseType === 'direct') {
            if (paymentMode === 'crypto') {
                initiateCryptoPayment();
            } else if (showCard) {
                // actionProcessFractoPayment();
                actionProcessStripePayment();
            } else {
                completeBuy('');
            }
        } else if (purchaseType === 'auction') {
            if (paymentMode === 'fiat') {
                if (showCard) {
                    // actionProcessFractoPayment();
                    actionProcessStripePayment();
                } else {
                    completeAuctionBid('');
                }
            } else {
                initiateCryptoPaymentForAuction();
            }
        } else {
            actionProcessStripePayment();
        }
    };

    const triggerGtagEvent = (id: any): any => {
        console.log('event', 'purchase');
        if (windowObject && windowObject.gtag) {
            windowObject.gtag('event', 'purchase', {
                currency: 'USD',
                transaction_id: id,
                value: itemDetail?.saleInfo[0]?.pricePerQuantity,
                items: [
                    {
                        item_id: itemDetail?.itemInfo.id,
                        item_name: itemDetail?.itemInfo?.name,
                        currency: 'USD',
                        discount: 0,
                        index: 0,
                        price: itemDetail?.saleInfo[0]?.pricePerQuantity,
                        quantity: itemDetail?.itemInfo?.quantity,
                    },
                ],
            });
            console.log('purchase event trigger');
        }
    };
    // UI Elements
    const TotalSection = (
        <>
            {purchaseType === 'direct' && (
                <>
                    {quantity === '' && <>{listingToBuy.currencyType}</>}
                    {quantity !== '' && (
                        <>
                            {(
                                parseInt(quantity, 10) *
                                    listingToBuy.pricePerQuantity +
                                mintingFee?.mintFee +
                                mintingFee.transferFee
                            ).toFixed(2)}{' '}
                            {listingToBuy.currencyType}
                            {paymentMode === 'crypto' && (
                                <small>
                                    <br />({currencyConverstion?.to?.amount}{' '}
                                    ETH)
                                </small>
                            )}
                        </>
                    )}
                </>
            )}
            {purchaseType === 'auction' && (
                <>
                    {quantity === '' && <>{auctionToBid.currencyType}</>}
                    {quantity !== '' && (
                        <>
                            {getSubTotal()} {auctionToBid.currencyType}
                            {paymentMode === 'crypto' && (
                                <small>
                                    <br />({currencyConverstion?.to?.amount}{' '}
                                    ETH)
                                </small>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
    const actionFractoFormChange = (event: any): void => {
        let value;
        if (event.target.name === 'cardNumber') {
            value = Utils.formatCreditCardNumber(event.target.value);
        } else if (event.target.name === 'cardExpiry') {
            value = Utils.formatExpirationDate(event.target.value);
        } else if (event.target.name === 'cardCvc') {
            value = Utils.formatCVC(event.target.value, {
                number: cardForm.cardNumber,
            });
        }
        setCardForm({
            ...cardForm,
            [event.target.name]: value,
        });
        setValidCard(true);
    };

    const on3DSComplete = (): any => {
        // setOtpEnable(false);

        if (_self.stripe) {
            _self.stripe
                .retrievePaymentIntent(_self.clientSecret)
                .then((result: any) => {
                    if (result.error) {
                        // PaymentIntent client secret was invalid
                        setOtpEnable(false);
                        setShowLoading(false);
                        setPaymentStatus(false);
                        setPaymentResponse('Item purchase failed');
                    } else if (result.paymentIntent.last_payment_error) {
                        setOtpEnable(false);
                        setShowLoading(false);
                        setPaymentStatus(false);
                        setPaymentResponse(
                            result.paymentIntent.last_payment_error.message
                        );
                    } else if (
                        result.paymentIntent.status === 'requires_capture'
                    ) {
                        setOtpEnable(false);
                        setPaymentResponse(
                            'Congrats! Your transaction has been completed successfully. You are now the owner of this NFT.'
                        );
                        setShowLoading(false);
                        setPaymentStatus(true);
                        triggerGtagEvent(result.paymentIntent.status);
                    } else if (result.paymentIntent.status === 'succeeded') {
                        // completeAuctionBid()

                        setOtpEnable(false);
                        setPaymentResponse(
                            'Congrats! Your transaction has been completed successfully. You are now the owner of this NFT.'
                        );
                        setShowLoading(false);
                        setPaymentStatus(true);
                        triggerGtagEvent(result.paymentIntent.status);
                        // Show your customer that the payment has succeeded
                    } else if (
                        result.paymentIntent.status ===
                        'requires_payment_method'
                    ) {
                        // Authentication failed, prompt the customer to enter another payment method
                        setOtpEnable(false);
                        setShowLoading(false);
                        setPaymentStatus(false);
                    } else {
                        setOtpEnable(false);
                        setShowLoading(false);
                        setPaymentStatus(false);
                        setPaymentResponse('Item purchase failed');
                    }
                });
        }
    };

    useEffect(() => {
        window.addEventListener(
            'message',
            (ev) =>
                ev?.data === '3DS-authentication-complete'
                    ? on3DSComplete()
                    : '',
            false
        );
    }, []);

    return (
        <>
            {step < 2 && (
                <Grid container spacing={2} className="checkout-container">
                    <Grid item md={6} sm={12} className="left-panel">
                        <Box className="nft-section">
                            <Box display="flex" flexDirection="column">
                                <Typography
                                    variant="h4"
                                    className="purchase-text"
                                >
                                    Purchase Summary
                                </Typography>
                                <Box className="nft-preview">
                                    <img
                                        className="nft-image"
                                        src={getUrl(
                                            `${itemDetail.itemInfo.thumbnail}`,
                                            '400x400/'
                                        )}
                                    />
                                    <Box className="nft-detail">
                                        <Typography
                                            variant="h5"
                                            className="itemDetail-text"
                                        >
                                            {itemDetail.itemInfo.name}
                                        </Typography>
                                        <Box
                                            mt={4}
                                            display="flex"
                                            alignItems="center"
                                        >
                                            {purchaseType === 'direct' && (
                                                <>
                                                    <FormControl
                                                        fullWidth
                                                        size="small"
                                                        variant="outlined"
                                                        className="checkout-input"
                                                    >
                                                        <InputLabel>
                                                            Quantity
                                                        </InputLabel>
                                                        {/* <TextField
                                                            variant="outlined"
                                                            label="Quantity"
                                                            fullWidth
                                                            
                                                            type="number"
                                                            value={quantity}
                                                            onChange={(
                                                                e: any
                                                            ) =>
                                                                setPurchaseQuantity(
                                                                    Number(
                                                                        parseInt(
                                                                            e
                                                                                .target
                                                                                .value,
                                                                            10
                                                                        )
                                                                    )
                                                                )
                                                            }
                                                        /> */}
                                                        <Select
                                                            value={quantity}
                                                            onChange={(
                                                                event: any
                                                            ) => {
                                                                const selectedQuantity: string =
                                                                    event.target
                                                                        .value;
                                                                // setEditions(
                                                                //     Utils.sortEditions(
                                                                //         selectedEditions
                                                                //     )
                                                                // );
                                                                setPurchaseQuantity(
                                                                    parseInt(
                                                                        selectedQuantity,
                                                                        10
                                                                    )
                                                                );
                                                            }}
                                                            label="Select Quantity"
                                                            inputProps={{
                                                                readOnly:
                                                                    step > 0,
                                                            }}
                                                        >
                                                            {/* {listingToBuy?.availableQuantity && listingToBuy?.availableQuantity.length > 0 && listingToBuy?.availableQuantity.map( */}
                                                            {[
                                                                ...Array(
                                                                    listingToBuy?.availableQuantity
                                                                ),
                                                            ].map(
                                                                (
                                                                    value: string,
                                                                    index: number
                                                                ): any => (
                                                                    <MenuItem
                                                                        key={`editions_${index}`}
                                                                        value={
                                                                            index +
                                                                            1
                                                                        }
                                                                    >
                                                                        {/* <Checkbox
                                                                        color="primary"
                                                                        checked={
                                                                            editions.indexOf(
                                                                                value
                                                                            ) >
                                                                            -1
                                                                        }
                                                                    /> */}
                                                                        <ListItemText
                                                                            primary={
                                                                                index +
                                                                                1
                                                                            }
                                                                        />
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                    {/* <TextField
                                                        className="qty-input"
                                                        variant="outlined"
                                                        placeholder="ex 10"
                                                        size="small"
                                                        type="number"
                                                        value={quantity}
                                                        inputProps={{
                                                            readOnly: step > 0,
                                                        }}
                                                        onChange={(e) => {
                                                            setPurchaseQuantity(
                                                                e.target.value
                                                            );
                                                        }}
                                                    /> */}
                                                </>
                                            )}
                                            {purchaseType === 'auction' && (
                                                <Box
                                                    display="flex"
                                                    flexDirection="column"
                                                >
                                                    <Box>
                                                        <Typography>
                                                            Editions
                                                        </Typography>
                                                        <Typography className="text-gray">
                                                            {Utils.sortEditions(
                                                                auctionToBid.editionQuantity
                                                            ).join(', ')}
                                                        </Typography>
                                                    </Box>
                                                    <Box mt={4} display="flex">
                                                        <Typography>
                                                            Enter Bid Amount
                                                        </Typography>
                                                        <TextField
                                                            className="qty-input"
                                                            variant="outlined"
                                                            placeholder="ex 10"
                                                            size="small"
                                                            type="number"
                                                            inputProps={{
                                                                readOnly:
                                                                    step > 0,
                                                            }}
                                                            value={bidAmount}
                                                            onChange={(e) =>
                                                                setBiddingAmount(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </Box>
                                                </Box>
                                            )}
                                        </Box>
                                        <FormHelperText className="Mui-error">
                                            {quantityError || bidFormError}
                                        </FormHelperText>
                                    </Box>
                                </Box>
                            </Box>

                            <Box className="pb-5">
                                <Grid item className="profile-name-field">
                                    <Typography variant="h5">
                                        Wallet Preference
                                    </Typography>
                                </Grid>
                                {walletPreferenceLoading && <p>Loading...</p>}
                                {!walletPreferenceLoading && (
                                    <>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={directNFTTransfer}
                                                    onChange={
                                                        updateDirectTransferStatus
                                                    }
                                                    color="primary"
                                                    className="details"
                                                />
                                            }
                                            label={`NFT will be transferred to ${
                                                directNFTTransfer
                                                    ? 'your non-custodial wallet'
                                                    : 'TUS custodial wallet'
                                            }`}
                                        />
                                        {directNFTTransfer &&
                                            transferWalletAddress && (
                                                <Typography className="secondary-text">
                                                    Make sure you have access to
                                                    your connected wallet
                                                    address{' '}
                                                    {Utils.formatedAddress(
                                                        transferWalletAddress
                                                    )}{' '}
                                                    or{' '}
                                                    {isMobileDevice
                                                        ? 'tap'
                                                        : 'click'}{' '}
                                                    <Typography
                                                        onClick={
                                                            actionOpenEditModal
                                                        }
                                                        className="setting-link"
                                                    >
                                                        Edit Wallet Address
                                                    </Typography>{' '}
                                                    to update.
                                                </Typography>
                                            )}

                                        {directNFTTransfer &&
                                            showAddWalletLink && (
                                                <Typography
                                                    className="secondary-text"
                                                    color="error"
                                                >
                                                    To transfer NFT to
                                                    non-custodial wallet, you
                                                    must provide ERC-20
                                                    compatible wallet address
                                                    e.g. MetaMask.{' '}
                                                    {isMobileDevice
                                                        ? 'Tap'
                                                        : 'Click'}{' '}
                                                    <Typography
                                                        onClick={
                                                            actionOpenAddModal
                                                        }
                                                        className="setting-link"
                                                    >
                                                        Add Wallet Address
                                                    </Typography>{' '}
                                                    to setup.
                                                </Typography>
                                            )}

                                        {openAccountCreateModal && (
                                            <CryptoAccountForm
                                                modalFormType={modalFormType}
                                                openAccountModal={
                                                    openAccountCreateModal
                                                }
                                                onCloseAccountModal={
                                                    actionCancel
                                                }
                                                actionOnCancel={actionCancel}
                                                cryptoAccounts={cryptoAccounts}
                                                cryptoAccountInfoToEdit={
                                                    cryptoAccountInfoToEdit
                                                }
                                            />
                                        )}
                                    </>
                                )}
                            </Box>

                            <Divider />
                            <Box className="mt-4 subtotal-box">
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    className="subtotal-text"
                                >
                                    <Typography>Subtotal</Typography>
                                    <Typography align="right">
                                        {getSubTotal()}{' '}
                                        {listingToBuy.currencyType}
                                    </Typography>
                                </Box>
                                {itemDetail.itemInfo.itemStatus ===
                                    'not_mint' && (
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        className="subtotal-text"
                                    >
                                        <Typography>Mint Fee</Typography>
                                        <Box>
                                            <Typography align="right">
                                                {mintingFee.mintFee}{' '}
                                                {mintingFee.currency}
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    className="subtotal-text"
                                >
                                    <Typography>Transfer Fee</Typography>
                                    <Box>
                                        <Typography align="right">
                                            {mintingFee.transferFee}{' '}
                                            {mintingFee.currency}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box
                                    mt={1}
                                    display="flex"
                                    justifyContent="space-between"
                                    className="total-text"
                                >
                                    <Typography variant="subtitle1">
                                        Total
                                    </Typography>
                                    {loadMinting && <CircularProgress />}
                                    {!loadMinting && (
                                        <Box>
                                            <Typography
                                                variant="subtitle1"
                                                align="right"
                                            >
                                                {TotalSection}
                                            </Typography>

                                            <Typography>
                                                {!loadMinting &&
                                                    purchaseType === 'direct' &&
                                                    // paymentMode === 'crypto' &&
                                                    // currencyConverstion &&
                                                    step < 2 && (
                                                        <Typography className="text-secondary mt-1">
                                                            <PriceCountDown
                                                                onPriceExpire={
                                                                    // onPriceExpire
                                                                    onMintPriceExpire
                                                                }
                                                                expiresIn={
                                                                    mintingFee.expireIn
                                                                    // currencyConverstion?.expiresIn
                                                                }
                                                            />
                                                        </Typography>
                                                    )}
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item md={6} sm={12} className="right-panel">
                        {step === 0 && purchaseType === 'direct' && (
                            <Box className="payment-mode">
                                <Box className="payment-method-box">
                                    <Typography className="payment-text">
                                        Select The Payment Method
                                    </Typography>

                                    <Box className="payment-wrapper">
                                        <Box
                                            onClick={() => {
                                                setPaymentMode('tripleA');
                                            }}
                                            className={
                                                paymentMode === 'tripleA'
                                                    ? 'crypto-box active'
                                                    : 'crypto-box'
                                            }
                                        >
                                            <img
                                                src="/images/crypto-icon.svg"
                                                alt="crypto-icon"
                                            />
                                            <Box className="crypto-box-text">
                                                <Button
                                                    style={{ marginTop: '0px' }}
                                                    onClick={() => {
                                                        setPaymentMode(
                                                            'tripleA'
                                                        );
                                                    }}
                                                >
                                                    Cryptocurrency
                                                </Button>
                                                {/* <img src="/images/payment-arrow.svg" alt="arrow" /> */}
                                            </Box>
                                        </Box>

                                        <Box
                                            onClick={() => {
                                                setPaymentMode('fiat');
                                            }}
                                            className={
                                                paymentMode === 'fiat'
                                                    ? 'crypto-box active'
                                                    : 'crypto-box'
                                            }
                                        >
                                            <img
                                                src="/images/credit-card.svg"
                                                alt="credit-card"
                                                className="credit-img"
                                            />
                                            <Box className="crypto-box-text">
                                                <Button
                                                    style={{ marginTop: '0px' }}
                                                    onClick={() => {
                                                        setPaymentMode('fiat');
                                                    }}
                                                >
                                                    Credit Card
                                                </Button>
                                                {/* <img src="/images/payment-arrow.svg" alt="arrow"  /> */}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <AppPrimaryButton
                                    title="CONTINUE"
                                    isLoading={showLoading}
                                    disabled={
                                        (directNFTTransfer &&
                                            !transferWalletAddress) ||
                                        loadMinting ||
                                        showLoading ||
                                        convertingPrice ||
                                        quantity >
                                            listingToBuy.availableQuantity
                                        // editions.length === 0
                                    }
                                    onClick={() => {
                                        actionInitiateBuy(paymentMode);
                                    }}
                                />
                            </Box>
                        )}

                        {step === 0 && purchaseType === 'auction' && (
                            <Box className="payment-mode">
                                <Box>
                                    <Typography variant="h4">
                                        Payment Mode
                                    </Typography>
                                    <Box mt={2}>
                                        {(auctionToBid.acceptedPaymentMethod ===
                                            'fiat' ||
                                            auctionToBid.acceptedPaymentMethod ===
                                                'all') && (
                                            <AppThemeRadioInput
                                                label="Fiat (USD)"
                                                selectedValue={paymentMode}
                                                value="fiat"
                                                onChange={() => {
                                                    setPaymentMode('fiat');
                                                }}
                                            />
                                        )}

                                        {(auctionToBid.acceptedPaymentMethod ===
                                            'crypto' ||
                                            auctionToBid.acceptedPaymentMethod ===
                                                'all') && (
                                            <AppThemeRadioInput
                                                label="Crypto"
                                                selectedValue={paymentMode}
                                                value="crypto"
                                                onChange={() => {
                                                    setPaymentMode('crypto');
                                                }}
                                            />
                                        )}
                                    </Box>
                                </Box>
                                <AppPrimaryButton
                                    title="CONTINUE"
                                    isLoading={showLoading}
                                    disabled={
                                        showLoading ||
                                        convertingPrice ||
                                        bidFormError !== '' ||
                                        bidAmount === ''
                                    }
                                    onClick={() => {
                                        actionInitiateBuy(paymentMode);
                                    }}
                                />
                            </Box>
                        )}
                        {/* {step === 1 && showLoading && (
                            <Box className="center-content">
                                <CircularProgress color="primary" />
                            </Box>
                        )} */}
                        {step === 1 && (
                            <Box className="payment-mode">
                                {!showCard && paymentMode !== 'tripleA' && (
                                    <Box>
                                        <Typography variant="h5">
                                            Pay using wallet balance
                                        </Typography>
                                        {paymentMode === 'fiat' && (
                                            <Box
                                                mt={2}
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <Typography variant="h2">
                                                    {`${wallet.totalBalance} ${wallet.currencyType}`}
                                                </Typography>
                                                <Box ml={2}>
                                                    <Typography className="text-secondary">
                                                        {`Available: ${wallet.freeBalance} ${wallet.currencyType}`}
                                                    </Typography>
                                                    <Typography className="text-secondary">
                                                        {`Locked: ${wallet.lockedBalance} ${wallet.currencyType}`}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        )}
                                        {paymentMode === 'crypto' && (
                                            <Box mt={2}>
                                                <Typography variant="h2">
                                                    {`${Utils.formatCrypto(
                                                        metamaskAccountDetails.balance
                                                    )} ETH`}
                                                </Typography>
                                                <Box mt={2}>
                                                    <Typography className="text-gray">
                                                        Address:
                                                        <span className="text-secondary">
                                                            {metamaskAccountDetails.address !==
                                                            ''
                                                                ? Utils.formatedAddress(
                                                                      metamaskAccountDetails.address
                                                                  )
                                                                : ''}
                                                        </span>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>
                                )}
                                {showCard && (
                                    <Box>
                                        <Typography variant="h4">
                                            Pay by Card
                                        </Typography>
                                        <Box mt={4}>
                                            <StripeCard
                                                // cardForm={cardForm}
                                                // onFormChange={
                                                //     actionFractoFormChange
                                                // }
                                                onFormChange={({
                                                    completed,
                                                }: any) => {
                                                    setValidCard(completed);
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                )}
                                <Box mt={4}>
                                    <AppCheckBox
                                        value={aggreed}
                                        label={
                                            <Box display="flex">
                                                <Typography>
                                                    I agree with
                                                </Typography>

                                                <Typography
                                                    component={Link}
                                                    to="/terms"
                                                    className="ml-1"
                                                    target="_blank"
                                                >
                                                    Terms and Conditions
                                                </Typography>
                                            </Box>
                                        }
                                        onChange={(status: boolean) => {
                                            setAggreed(status);
                                        }}
                                    />
                                    {purchaseType === 'auction' && (
                                        <Box>
                                            <Typography className="text-gray">
                                                Note: Bid once placed cannot be
                                                cancelled.
                                            </Typography>
                                        </Box>
                                    )}
                                    <Box>
                                        <AppPrimaryButton
                                            title="BACK"
                                            className="mr-10 mt-10"
                                            disabled={showLoading}
                                            onClick={() => {
                                                setStep(0);
                                            }}
                                        />

                                        <AppPrimaryButton
                                            className="mt-10"
                                            title={getConfirmButtonTitle()}
                                            disabled={
                                                !aggreed ||
                                                (showCard && !validCard) ||
                                                showLoading
                                            }
                                            loadingText="PROCESSING"
                                            isLoading={showLoading}
                                            onClick={actionConfirmBuy}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            )}

            {step === 2 &&
                !otpEnable &&
                (tripleA.widgetUrl ? (
                    <iframe
                        title="tripleA"
                        id="triplea-payment-form"
                        src={tripleA.widgetUrl}
                        style={{
                            width: isMobileDevice ? '350px' : '930px',
                            height: isMobileDevice ? '600px' : '700px',
                            border: ' 0',
                            overflow: isMobileDevice ? 'hidden' : 'unset',
                        }}
                        scrolling="no"
                    />
                ) : (
                    <Box
                        className="center-content checkout-container"
                        flexDirection="column"
                    >
                        <Box mb={2}>
                            <Typography variant="h4">
                                {paymentStatus
                                    ? 'Payment Successful!'
                                    : 'Payment Failed!'}
                            </Typography>
                        </Box>
                        <Box my={2}>
                            <Avatar
                                src={
                                    paymentStatus
                                        ? '/icons/icon-success.png'
                                        : '/icons/icon-failed.png'
                                }
                            />
                        </Box>
                        <Box mt={2}>
                            <Typography
                                className="text-secondary"
                                align="center"
                            >
                                {paymentResponse}
                            </Typography>
                            <Box mt={2} id="checkout-success-options">
                                <Box mr={3} id="view-nft-button">
                                    <AppPrimaryButton
                                        onClick={() => actionToProfile()}
                                        title="View History"
                                    />
                                </Box>
                                <Box id="marketplace-button">
                                    <AppPrimaryButton
                                        onClick={() => actionToMarketPlace()}
                                        title="Continue Shopping"
                                    />
                                </Box>
                            </Box>
                            {cashBackAmount && (
                                <>
                                    <Typography
                                        className="text-secondary py-5"
                                        align="center"
                                    >
                                        <strong>BONUS REWARD:</strong>{' '}
                                        <span className="primary-link">
                                            {cashBackAmount} USD
                                        </span>{' '}
                                        has been credited to your
                                        <Typography
                                            component={Link}
                                            to="/profile/view"
                                            className="primary ml-1"
                                        >
                                            TUS Wallet
                                        </Typography>
                                    </Typography>
                                </>
                            )}
                        </Box>
                    </Box>
                ))}

            {otpEnable && (
                <>
                    <iframe
                        src={otpScreen}
                        title="3d-secure"
                        style={{
                            width: '800px',
                            height: '700px',
                            border: ' 0',
                        }}
                        scrolling="no"
                    />
                </>
            )}
            {step === 3 && !otpEnable && (
                <Box
                    className="center-content checkout-container"
                    flexDirection="column"
                >
                    <Box mb={2}>
                        <Typography variant="h4">
                            {paymentStatus
                                ? 'Payment Successful!'
                                : 'Payment Failed!'}
                        </Typography>
                    </Box>
                    <Box my={2}>
                        <Avatar
                            src={
                                paymentStatus
                                    ? '/icons/icon-success.png'
                                    : '/icons/icon-failed.png'
                            }
                        />
                    </Box>
                    <Box mt={2}>
                        <Typography className="text-secondary" align="center">
                            {paymentResponse}
                        </Typography>
                        <Box mt={2} id="checkout-success-options">
                            <Box mr={3} id="view-nft-button">
                                <AppPrimaryButton
                                    onClick={() => actionToProfile()}
                                    title="View History"
                                />
                            </Box>
                            <Box id="marketplace-button">
                                <AppPrimaryButton
                                    onClick={() => actionToMarketPlace()}
                                    title="Continue Shopping"
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};
interface NFTCheckoutFormProps {
    showCheckout: boolean;
    actionCloseCheckoutDialog: any;
    itemDetail: ItemFullDetail | undefined;
    listingToBuy: Listing | undefined;
    auctionToBid: Auction | undefined;
    purchaseType: string;
    appState: ApplicationDataState;
    onPaymentResponse: any;
    buyStart: any;
    buyEnd: any;
}

const NFTCheckoutForm = (props: NFTCheckoutFormProps): ReactElement => {
    const { showCheckout, actionCloseCheckoutDialog, buyEnd, itemDetail } =
        props;
    const [showCountDown, setShowCountDown] = useState(false);
    const options: any = {
        clientSecret: _self.clientSecret,
    };
    // UI Elements
    const saleCountdown = ({
        // minutes,
        // seconds,
        formatted,
        completed,
    }: any): ReactElement => {
        if (completed) {
            setShowCountDown(false);
            return <></>;
        }
        return (
            <>
                {!completed && (
                    <Typography>
                        Complete this transaction in the next
                        <br />
                        <span className="primary-link">
                            {formatted.minutes}:{formatted.seconds}
                        </span>{' '}
                        minutes to avail a <strong>10% cashback!</strong>
                    </Typography>
                )}
            </>
        );
    };

    return (
        <Dialog open={showCheckout} fullWidth maxWidth="md">
            <DialogTitle className="popup-check">
                <Box className="title-section">
                    <Typography variant="h4" className="checkout-text">
                        Checkout
                    </Typography>
                    {showCountDown && itemDetail?.itemInfo?.cashbackAvailable && (
                        <>
                            <Countdown date={buyEnd} renderer={saleCountdown} />
                        </>
                    )}
                    <IconButton
                        onClick={() => {
                            actionCloseCheckoutDialog();
                            setShowCountDown(false);
                        }}
                    >
                        <CloseIcon color="primary" />
                    </IconButton>
                </Box>
                <hr />
            </DialogTitle>
            <DialogContent className="popup-check">
                <Elements stripe={stripePromise} options={options}>
                    <NFTCheckout
                        {...props}
                        setShowCountDown={setShowCountDown}
                    />
                </Elements>
            </DialogContent>
        </Dialog>
    );
};

const mapStateToProps = (state: AppState): any => ({
    appState: state.applicationState,
});

export default connect(mapStateToProps, null)(NFTCheckoutForm);