import { ReactElement } from 'react';
import { ChangePasswordForm } from './components';
import { HeadTag } from '../../shared/components';

const ChangePasswordPage = (): ReactElement => (
    <>
        <HeadTag title="Profile Change Password" />
        <ChangePasswordForm />
    </>
);

export default ChangePasswordPage;
