import { Typography, Box } from '@material-ui/core';
import { ReactElement } from 'react';

export enum GradientVariants {
    RED = 'red-chip',
    YELLOW = 'yellow-chip',
    BLUE = 'blue-chip',
    GREEN = 'green-chip',
    PURPLE = 'purple-chip',
    GRAY = 'gray-chip',
    LIGHT = 'light-chip',
}

interface FlagProps {
    variant: GradientVariants;
    label: string;
}

const GradientChip = (props: FlagProps): ReactElement => {
    const { variant, label } = props;

    // UI Elements
    return (
        <Box className={`${variant} chip gradient-chip`}>
            <Typography variant="body2">{label}</Typography>
        </Box>
    );
};
export default GradientChip;
