import {
    // Accordion,
    // AccordionSummary,
    Typography,
    // AccordionDetails,
    // Grid,
    // TextField,
    Box,
    Slider,
    // FormControl,
    // InputLabel,
    // Select,
    // MenuItem,

    // IconButton,
} from '@material-ui/core';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
// import ClearIcon from '@material-ui/icons/Clear';
import { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
// import ClearIcon from '@material-ui/icons/Clear';
// import SearchIcon from '@material-ui/icons/Search';
import { MarketPlaceActions } from '../../../state/marketplace/Action';
import { AppState } from '../../../state/RootReducer';
import { Utils } from '../../../shared/helpers';
import {
    // AppSecondaryButton,
    // AppThemeRadioInput,
    AppCheckBox, AppSecondaryButton,
} from '../../../shared/components/AppComponents';
// import CelebrityList from './CelebrityList';
import { ApplicationActions } from '../../../state/application/Action';
import { CollectionService } from '../../../services';
import { Category } from '../../../state/application/Reducer';
import { MarketPlaceFilterState } from '../../../state/marketplace/Reducer';
import CelebrityList from './CelebrityList';


interface FilterProps {
    marketPlaceFilter: MarketPlaceFilterState | any;
    setPrice: any;
    setFilter: any;
    resetAllFilter: any;
    categories: Category[];
    setCategories: any;
}
const Filters = (props: any): ReactElement => {
    const {
        marketPlaceFilter,
        // setPrice,
        setFilter,
        // resetAllFilter,
        // categories,
        setCategories,
    }: FilterProps = props;

    // const [minPrice, setMinPrice] = useState('');
    // const [maxPrice, setMaxPrice] = useState('');

    // const valuetext = (value: number):any => {
    //     console.log("value",value);
    //     return value
    // }

    // API Calls
    const loadCategories = (): void => {
        CollectionService.getAllCategory()
            .then((result: any) => {
                const res = result.data.data;
                setCategories(res);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
            });
    };

    // const actionOnReset = (): void => {
    //     setMinPrice('');
    //     setMaxPrice('');
    //     resetAllFilter();
    // };

    // Hooks
    useEffect(() => {
        loadCategories();
        // actionOnReset()
    }, []);

    // UI Actions
    // const actionApplyFilter = (): void => {
    //     if (minPrice === '' || maxPrice === '') {
    //         Toast.showErrorMessage('Please enter min and max price');
    //     } else if (
    //         Number(minPrice) !== 0 &&
    //         Number(minPrice) > Number(maxPrice)
    //     ) {
    //         Toast.showErrorMessage('Max price value should be greater');
    //     } else {
    //         setPrice({ minPrice, maxPrice });
    //     }
    // };


    // UI Elements
    const StatusFilter = ({
        label,
        valueKey,
    }: {
        label: string;
        valueKey: string;
    }): ReactElement => (
        <AppCheckBox
            label={label}
            value={marketPlaceFilter[valueKey]}
            onChange={(status: boolean): void => {
                setFilter({
                    ...marketPlaceFilter,
                    [valueKey]: status,
                });
            }}
        />
    );
    const BlockChainFilter = ({
        label,
        value,
    }: {
        label: string;
        value: string;
    }): ReactElement => (
        <AppCheckBox
            label={label}
            value={marketPlaceFilter.blockChain.indexOf(value) > -1}
            onChange={(status: boolean): void => {
                const { blockChain } = marketPlaceFilter;
                const index = blockChain.indexOf(value);
                if (status) {
                    blockChain.push(value);
                } else {
                    blockChain.splice(index, 1);
                }
                setFilter({
                    ...marketPlaceFilter,
                    blockChain: [...blockChain],
                });
            }}
        />
    );
    // const TypeFilter = ({
    //     label,
    //     valueKey,
    // }: {
    //     label: string;
    //     valueKey: string;
    // }): ReactElement => (
    //     <AppCheckBox
    //         label={label}
    //         value={marketPlaceFilter[valueKey]}
    //         onChange={(status: boolean): void => {
    //             setFilter({
    //                 ...marketPlaceFilter,
    //                 category: status,
    //             });
    //         }}
    //     />
    // );
    // const CategoryFilter = ({
    //     label,
    //     name,
    //     value,
    // }: {
    //     label: string;
    //     value: string;
    //     name: string;
    // }): ReactElement => (
    //     <AppThemeRadioInput
    //         label={label}
    //         value={name}
    //         selectedValue={value}
    //         onChange={(status: boolean): void => {
    //             setFilter({
    //                 ...marketPlaceFilter,
    //                 category: status,
    //             });
    //         }}
    //     />
    // );

    const openFilter = (): any => {
        const appEl: HTMLDivElement = document.getElementsByClassName(
            'app-body'
        )[0] as HTMLDivElement;
        const el = document.getElementsByClassName('filter-container')[0];
        appEl.style.zIndex = '1000';
        el.classList.add('open');
    };

    const closeFilter = (): any => {
        const appEl: HTMLDivElement = document.getElementsByClassName(
            'app-body'
        )[0] as HTMLDivElement;
        const el = document.getElementsByClassName('filter-container')[0];
        appEl.style.zIndex = '0';
        el.classList.remove('open');
    };

    const hasStatus = (): any => {
        const f = marketPlaceFilter;
        return f.buy || f.newItem || f.onAuction || f.hasOffer;
    };

    const hasPrice = (): any =>
        Number(marketPlaceFilter.maxPrice) > 0 ||
        Number(marketPlaceFilter.minPrice) > 0;
    const hasCategory = (): any => marketPlaceFilter.category !== 'All NFTs';
    const hasCeleb = (): any => marketPlaceFilter.celebrities.length > 0;
    const hasType = (): any => marketPlaceFilter.crypto;

    // mui slider
    const [value, setValue] = useState<number[]>([20, 37]);

    const handleChange = (event: any, newValue: number | number[]): any => {
        setValue(newValue as number[]);
    };

    // mui select
    // const [age, setAge] = useState('');
    // const handleChange2 = (event: any): any => {
    //     setAge(event.target.value);
    // };


    return (
        <Box className="filter-container">
            <Typography
                className="filter-margin filter-margin-icon heading mobile top"
                variant="h4"
                onClick={openFilter}
            >
                <FilterListIcon color="primary" />
                FILTER
                {(hasStatus() ||
                    hasPrice() ||
                    hasCategory() ||
                    hasCeleb() ||
                    hasType()) && <bdi />}
            </Typography>
            <Box className="mobile-filter">
                <Box className='mobile-filter-wrapper'>
                    <CelebrityList {...props} />
                    <Box p={2} mb={2}>
                        <AppSecondaryButton
                            fullWidth
                            title="Apply"
                            className="btn-small mobile apply-btn"
                            onClick={closeFilter}
                        />
                    </Box>
                    <Box className='price-wrapper'>
                        <Box className='price-box'>
                            <Typography className="filter-margin">
                                Price{hasPrice() && <bdi />}
                            </Typography>
                            <Box sx={{ width: 300 }} className="price-slider">
                                <Slider
                                    className='price-slider-design'
                                    getAriaLabel={() => 'Temperature range'}
                                    value={value}
                                    onChange={handleChange}
                                    valueLabelDisplay="auto"
                                />
                            </Box>
                        </Box>
                    </Box>

                    <Box className='status-wrapper'>
                        <Box >      {/* expandIcon={<ExpandMoreIcon />} */}
                            <Typography className="filter-margin">
                                Status{hasStatus() && <bdi />}
                            </Typography>

                        </Box>

                        <Box className="filter-margin filter-content">
                            <Box>
                                <Box className='buy-now class'>
                                    <StatusFilter valueKey="buy" label="Buy Now" />
                                </Box>
                                <Box className='buy-now class'>
                                    <StatusFilter
                                        valueKey="onAuction"
                                        label="Not for sale"
                                    />
                                </Box>
                                <Box className='buy-now class'>
                                    <StatusFilter valueKey="newItem" label="All" />
                                </Box>
                                <Box className='buy-now class'>
                                    <StatusFilter
                                        valueKey="hasOffer"
                                        label="Open for offers"
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className='type-wrapper'>
                        <Typography className="filter-margin">
                            Type
                        </Typography>
                        <Box>
                            <Typography className='class'>Video</Typography>
                            <Typography className='class'>Audio</Typography>
                            <Typography className='class'>Image</Typography>
                        </Box>
                    </Box>
                    <Box className='single-wrapper'>
                        <Box>
                            <Typography className='classes single'>Single</Typography>
                            <Typography className='classes single-edition'>Single editions</Typography>
                        </Box>
                    </Box>
                    <Box className='region-wrapper'>
                        <Typography className="filter-margin">
                            Region
                        </Typography>
                        <Box className='region-form'>
                            <Box className="region-form-inner">
                                <select name="dropdown" className='region-form-control'>
                                    <option value="Data Structures" selected>Choose...</option>
                                    <option value="Data Mining">Data Mining</option>
                                </select>
                            </Box>
                        </Box>
                    </Box>
                    <Box className='blockchain-wrapper'>
                        <Typography className="filter-margin">
                            Blockchain
                        </Typography>
                        <Box>
                            <Box className='class blockchain-filter'>
                                <BlockChainFilter value="polygon" label="Polygon" />
                            </Box>
                            <Box className='class blockchain-filter'>
                                <BlockChainFilter
                                    value="ethereum"
                                    label="Ethereum"
                                />
                            </Box>

                        </Box>
                    </Box>
                    <Box className='exhibition-wrapper'>
                        <Typography className="filter-margin">
                            Exhibition
                        </Typography>
                        <Box className='region-form'>
                            <Box className="region-form-inner">
                                <select name="dropdown" className='region-form-control'>
                                    <option value="Data Structures" selected>Choose...</option>
                                    <option value="Data Mining">Data Mining</option>
                                </select>
                            </Box>
                        </Box>
                    </Box>

                    <Box className='utility-wrapper'>
                        <Typography className="filter-margin">
                            Utility
                        </Typography>
                        <Box className='utility'>
                            <Box className='utility-box' />
                            <Box className='utility-box' />
                        </Box>
                    </Box>

                    <Box className='year-wrapper'>
                        <Typography className="filter-margin">
                            Year
                        </Typography>
                        <Box className='year-form'>
                            <Box className='year-form-inner'>
                                <select name="dropdown" className='region-form-control'>
                                    <option value="Data Structures" selected>Choose...</option>
                                    <option value="Data Mining">Data Mining</option>
                                </select>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

const mapStateToProps = (state: AppState): any => ({
    marketPlaceFilter: state.marketPlaceFilter,
    categories: state.applicationState.categories,
});

const mapDispatchToProps = (dispatch: any): any => ({
    setPrice: (data: any) => dispatch(MarketPlaceActions.setPrice(data)),
    setFilter: (data: any) => dispatch(MarketPlaceActions.setFilter(data)),
    clearFilter: (data: any) => dispatch(MarketPlaceActions.clearFilter(data)),
    resetAllFilter: (data: any) =>
        dispatch(MarketPlaceActions.resetAllFilter(data)),
    setCategories: (data: any) =>
        dispatch(ApplicationActions.setCategoriesList(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
