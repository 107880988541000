import { Box, IconButton } from '@material-ui/core';
import { ReactElement, useEffect, useState } from 'react';
// import DisplayVideoIcon from './DisplayVideoIcon';
// import AudiotrackOutlinedIcon from '@material-ui/icons/AudiotrackOutlined';

const DisplayVideoAudioIcon = (props: any): ReactElement => {
    const { video, images, thumbnail, audio } = props
    const [isGif, setIsGif] = useState(false)

    useEffect(() => {
        images?.forEach((item: any): any => {
            if (item?.endsWith('.gif')) {
                setIsGif(true)
                return true
            }
            return false
        });
    }, [])

    return (<>
        <Box
            width='94%'
            style={{ position: 'absolute', left: 8, top: '286px' }}>
            <Box
                width='100%'
                display='flex'
                justifyContent='flex-end'
            >
                <Box
                    gridGap={4}
                    display='flex'
                    justifyContent='flex-end'
                    alignItems='center'
                >
                    <IconButton
                        size="small">
                        {audio || thumbnail?.endsWith('.gif') || video?.length || isGif ? <img alt='video-icon' width='34px' src='/icons/videoPlay.svg' /> : ''}
                    </IconButton>
                    {/* {audio ? <IconButton
                        style={{ background: 'white', width: '34px', height: '34px' , marginRight:'4px'}}
                        size="small">
                        <AudiotrackOutlinedIcon fontSize='small' color='primary' />
                    </IconButton> : ''} */}
                </Box>
            </Box>
        </Box>
    </>)
}

export default DisplayVideoAudioIcon;