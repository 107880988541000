import { ReactElement } from 'react';
import {
    Box,
    Grid,
    Typography,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    Divider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { AppPrimaryButton } from '../../../shared/components/AppComponents';

const RestrictionForm = (props: any): ReactElement => {
    const { open, onCloseForm } = props;

    return (
        <Dialog maxWidth="xs" fullWidth open={open}>
            <DialogTitle>
                <Box className="title-section">
                    <Typography variant="h4">Restriction to buy</Typography>
                    <IconButton onClick={onCloseForm}>
                        <CloseIcon color="primary" />
                    </IconButton>
                </Box>
                <Divider />
            </DialogTitle>
            <DialogContent>
                <Box>
                    <Typography variant="body1">
                    <strong>Invite-only NFT</strong> <br />
                    Sorry! At the moment, this NFT is currently available
                    only to users that have registered for this pre-sale. 
                    Sign-up and follow us on <a href="https://www.instagram.com/vistaverseio/" 
                    target="_blank" className='primary-link' rel="noreferrer">Instagram</a> or join our <a href="https://discord.gg/vistaverse"
                    target="_blank" className='primary-link' rel="noreferrer">Discord</a> channel and we will keep you posted when the sale opens!
                    </Typography>

                    <Grid container className="end-content mt-4">
                        <AppPrimaryButton
                            title="Close"
                            onClick={onCloseForm}
                        />
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default RestrictionForm;
