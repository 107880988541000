import { ReactElement, useState } from 'react';
import {
    Box,
    Button,
    Grid,
    InputLabel,
    Dialog,
    DialogActions,
    DialogContent,
} from '@material-ui/core';
import FingerprintOutlinedIcon from '@material-ui/icons/FingerprintOutlined';
import PDFViewer from './PDFViewer';
import { AppSelect } from '../../../shared/components/AppComponents';
import ProfileEmptyPlaceholder from '../../../shared/components/ProfileEmptyPlaceHolder';
import { Utils } from '../../../shared/helpers';
import { Proofs, AddressProofs } from '../../../services/Profile';

const KYCDocumentPreview = (props: any): ReactElement => {
    const { previewDocument, message } = props;
    const {
        // kycStatus,
        profileUrl,
        idProofOne,
        idProofOneUrl,
        idProofTwo,
        idProofTwoUrl,
        addressProof,
        addressProofTUrl,
    } = previewDocument;
    const [showDocument, setShowDocument] = useState(false);
    const [previewUrl, setPreviewUrl] = useState('');
    const [previewType, setPreviewType] = useState('');

    // UI Actions
    const actionViewKYCDoc = (documentUrl: any): any => {
        setPreviewType(Utils.fileExtension(documentUrl));
        setShowDocument(true);
        setPreviewUrl(documentUrl);
    };

    const identityProofOne = Proofs.filter(
        (identity) => identity.value !== idProofTwo
    );

    const identityProofTwo = Proofs.filter(
        (identity) => identity.value !== idProofOne
    );

    // UI Elements
    return (
        <>
            <ProfileEmptyPlaceholder
                icon={<FingerprintOutlinedIcon className="text-secondary" />}
                message={message}
            />
            <Box mt={2}>
                <InputLabel>Upload Selfie </InputLabel>

                <Box className="upload-wrapper" my={2}>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            className="upload-container"
                            onClick={() => actionViewKYCDoc(profileUrl)}
                        >
                            <label htmlFor="profile">
                                {/* src="/icons/icon_camera.png" */}
                                <img src={profileUrl} className="cursor" />
                            </label>
                        </Grid>
                    </Grid>
                </Box>

                <Box mt={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <InputLabel>Identity Proof 1</InputLabel>

                            <Box my={2}>
                                <AppSelect
                                    name="idProofOne"
                                    titleKey="title"
                                    valueKey="value"
                                    size="small"
                                    items={identityProofOne}
                                    value={idProofOne}
                                    readOnly
                                />
                            </Box>

                            <Box
                                my={1}
                                className="doc-upload-container"
                                onClick={() => actionViewKYCDoc(
                                    Utils.isPDFDocument(idProofOneUrl) ?
                                    `${idProofOneUrl}`
                                    : idProofOneUrl
                                    )
                                }
                            >
                                <img
                                    src={
                                        Utils.isPDFDocument(idProofOneUrl)
                                            ? '/images/pdf-icon.png'
                                            : idProofOneUrl
                                    }
                                    alt=""
                                    className="cursor"
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <InputLabel>Identity Proof 2</InputLabel>

                            <Box my={2}>
                                <AppSelect
                                    name="idProofTwo"
                                    titleKey="title"
                                    valueKey="value"
                                    size="small"
                                    items={identityProofTwo}
                                    value={idProofTwo}
                                    readOnly
                                />
                            </Box>

                            <Box
                                my={1}
                                className="doc-upload-container"
                                onClick={() => actionViewKYCDoc(
                                    Utils.isPDFDocument(idProofTwoUrl) ?
                                    `${idProofTwoUrl}`
                                    : idProofTwoUrl
                                )}
                            >
                                <img
                                    src={
                                        Utils.isPDFDocument(idProofTwoUrl)
                                            ? '/images/pdf-icon.png'
                                            : idProofTwoUrl
                                    }
                                    alt=""
                                    className="cursor"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Box mt={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <InputLabel>Address Proof</InputLabel>

                            <Box my={2} className="identity-select">
                                <AppSelect
                                    name="addressProof"
                                    titleKey="title"
                                    valueKey="value"
                                    size="small"
                                    items={AddressProofs}
                                    value={addressProof}
                                    readOnly
                                />
                            </Box>

                            <Box
                                my={1}
                                className="doc-upload-container"
                                onClick={() =>
                                    actionViewKYCDoc(
                                        Utils.isPDFDocument(addressProofTUrl) ?
                                        `${addressProofTUrl}`
                                        : addressProofTUrl
                                    )
                                }
                            >
                                <img
                                    src={
                                        Utils.isPDFDocument(addressProofTUrl)
                                            ? '/images/pdf-icon.png'
                                            : addressProofTUrl
                                    }
                                    alt=""
                                    className="cursor"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <Dialog
                open={showDocument}
                onClose={() => setShowDocument(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {previewType === 'pdf' && (
                    <DialogContent>
                        <PDFViewer url={previewUrl} />
                    </DialogContent>
                )}
                {previewType !== 'pdf' && (
                    <DialogContent>
                        <img src={previewUrl} alt="Documents" width="100%" />
                    </DialogContent>
                )}
                <DialogActions>
                    <Button
                        onClick={() => setShowDocument(false)}
                        color="primary"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default KYCDocumentPreview;
