import { ReactElement, useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Divider,
} from '@material-ui/core';
import { Formik } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import { AddCryptoAccountSchema } from '../../../validators/ProfileSchema';
import { Utils } from '../../../shared/helpers';
import {
    AppFormikTextField,
    AppPrimaryButton,
    AppTeritaryButton,
} from '../../../shared/components/AppComponents';
import { ProfileService } from '../../../services';

const CryptoAccountForm = (props: any): ReactElement => {
    const {
        modalFormType,
        openAccountModal,
        actionOnCancel,
        cryptoAccounts,
        cryptoAccountInfoToEdit,
    } = props;
    const createAccountForm = {
        name: '',
        address: '',
    };

    const [cryptoAccountForm, setCryptoAccountForm] =
        useState(createAccountForm);
    const [accountId, setAccountId] = useState('');
    // Instance method
    const bindCryptoAccountInfoToEdit = (): any => {
        const { name, address, id } = cryptoAccountInfoToEdit;
        setAccountId(id);
        setCryptoAccountForm({
            name,
            address,
        });
    };
    // Hooks
    useEffect(() => {
        if (cryptoAccountInfoToEdit) {
            bindCryptoAccountInfoToEdit();
        } else {
            setCryptoAccountForm(cryptoAccountForm);
        }
    }, [cryptoAccountInfoToEdit]);

    // API Calls
    const create = (data: any, callback: any): any => {
        ProfileService.createCryptoAccount(data)
            .then((response: any) => {
                callback(true);
                Utils.handleSuccessResponse(response);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
                callback(false);
            });
    };

    const edit = (formValues: any, callback: any): any => {
        const { address, name } = formValues;
        const requestObj = {
            name,
            address,
            type: 'ETH',
            accountId,
        };
        ProfileService.editCryptoAccount(requestObj)
            .then((response: any) => {
                Utils.handleSuccessResponse(response);
                callback(true);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
                callback(false);
            });
    };

    // UI Actions
    const actionAddCryptoAccount = (formValues: any, formik: any): any => {
        formik.setSubmitting(true);
        if (cryptoAccounts.length !== 0 && modalFormType === 'Edit') {
            edit(formValues, (status: boolean) => {
                if (status) {
                    formik.resetForm();
                    actionOnCancel();
                }
                formik.setSubmitting(false);
            });
        } else {
            const { address, name } = formValues;
            const requestObj = {
                name,
                address,
                type: 'ETH',
            };

            create(requestObj, (status: boolean) => {
                if (status) {
                    setCryptoAccountForm(createAccountForm);
                    actionOnCancel();
                }
                formik.setSubmitting(false);
            });
        }
    };

    // UI Elements
    return (
        <Dialog 
            maxWidth="sm"
            fullWidth
            disableEscapeKeyDown
            open={openAccountModal}
        >
            <DialogTitle>
                <Box className="title-section">
                    <Typography variant="h4">
                        {modalFormType === 'Add' ? 'Add' : 'Edit'} Crypto
                        Account
                    </Typography>
                    <IconButton onClick={actionOnCancel}>
                        <CloseIcon color="primary" />
                    </IconButton>
                </Box>
                <Divider />
            </DialogTitle>
            <DialogContent>
                <Formik
                    enableReinitialize
                    initialValues={cryptoAccountForm}
                    validateOnMount
                    validationSchema={AddCryptoAccountSchema}
                    onSubmit={(values, formik) => {
                        actionAddCryptoAccount(values, formik);
                    }}
                >
                    {({
                        handleSubmit,
                        isSubmitting,

                        isValid,
                    }) => (
                        <form autoComplete="off" className='address-form'>
                            <AppFormikTextField
                                label="Account Name *"
                                placeholder="Name"
                                name="name"
                                type="text"
                                outerLabel
                                size="medium"
                            />
                            <AppFormikTextField
                                label="Account address *"
                                placeholder="Address"
                                name="address"
                                type="type"
                                outerLabel
                                size="medium"
                            />

                            <Box py={2}>
                                <Grid container className="end-content">
                                    <Grid>
                                        <Box mr={2}>
                                            <AppTeritaryButton
                                            className='account-cancel-btn'
                                                title="CANCEL"
                                                onClick={actionOnCancel}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid>
                                        <AppPrimaryButton
                                            title={
                                                modalFormType === 'Add'
                                                    ? 'ADD'
                                                    : 'UPDATE'
                                            }
                                            loadingText={
                                                modalFormType === 'Add'
                                                    ? 'CREATING'
                                                    : 'UPDATING'
                                            }
                                            isLoading={isSubmitting}
                                            disabled={isSubmitting || !isValid}
                                            onClick={handleSubmit}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default CryptoAccountForm;
