import { Box, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { ReactElement } from 'react';

const EmptyPlaceholder = (props: any): ReactElement => {
    const { icon, message } = props;
    return (
        <Grid
            className="empty-content"
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
        >
            {icon}
            <Box mt={2} className="empty-text">
                <Typography className='matter-fontfamily'>{message}</Typography>
            </Box>
        </Grid>
    );
};

export default EmptyPlaceholder;
