import { ReactElement } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ResendEmailForm } from './components';
import { HeadTag } from '../../shared/components';
import { LocationModal } from '../../shared/models/StateModal';

const ResendEmailPage = (): ReactElement => {
    const location = useLocation();
    const navigate = useNavigate();
    const { email } = location.state as LocationModal;

    // UI Elements
    return (
        <>
            <HeadTag title="Email Confirmation" />
            <ResendEmailForm email={email || navigate('../')} />
        </>
    );
};

export default ResendEmailPage;
