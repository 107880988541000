import { Box, Grid, IconButton, Typography } from "@material-ui/core";
import { useEffect, useRef, useState, ReactElement } from "react";

import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import WaveSurfer from "wavesurfer.js";
import { getVideoUrl } from "../helpers/Utils";

const formWaveSurferOptions = (ref: any): any => ({
    container: '#waveform',
    waveColor: "#999999",
    progressColor: "#333",
    cursorColor: "#333",
    barWidth: 3,
    barRadius: 3,
    responsive: true,
    height: 100,
    normalize: true,
    partialRender: true
});

const Waveform = (props: any): ReactElement => {
    const { audiofile, artworkname } = props
    const waveformRef = useRef(null);
    const wavesurfer = useRef(null);
    const audioRef = useRef(null);
    const [playingWave, setPlayWave] = useState(false);
    const [playingAudio, setPlayAudio] = useState(false);
    const [loaderforMedia, setLoaderforMedia] = useState(true);
    const [currentTime, setCurrentTime] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            const options = formWaveSurferOptions(waveformRef.current);
            wavesurfer.current = WaveSurfer.create(options);
            wavesurfer.current.on("ready", () => {
                audioRef.current.pause();
                setPlayAudio(false)
                setLoaderforMedia(false)
                setPlayWave(false)

            });
            wavesurfer.current.load(audiofile);
        }, 1600)

        return () => wavesurfer.current.destroy();
    }, [audiofile]);

    const handleTimeUpdate = (e: any): any => {
        setCurrentTime(e.target.currentTime);
    }

    const handlePlayPause = (): any => {
        setPlayWave(!playingWave);
        wavesurfer.current.playPause();
    };

    const handlePlayPausePlanAudio = (): any => {
        if (playingAudio) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setPlayAudio(!playingAudio);
    }

    useEffect(() => {
        console.log(document.getElementById('waveform'), document.getElementById('waveform').childElementCount);
        if (document.getElementById('waveform').childElementCount > 1) {
            document.getElementById('waveform').removeChild(document.getElementById('waveform').lastChild);
        }
    }, [waveformRef])

    return (
        <Grid
            style={{
                width: '100%', background: '#fff', borderRadius: '1rem',
                boxShadow: '0px 5px 10px rgb(0 0 0 / 5%)'
            }}
            item sm={12} md={6} lg={6}>
            <Box p={3}>
                <Box
                    display='flex'
                    justifyContent='start'
                    alignItems='center'>
                    <Box mr={4}>
                        {loaderforMedia ? <IconButton
                            title='plan Audio'
                            onClick={handlePlayPausePlanAudio}
                            style={{ background: '#eeeeee' }}>
                            {!playingAudio ? <PlayArrowIcon color="primary" fontSize='large' /> : <PauseIcon color="primary" fontSize='large' />}
                        </IconButton> : <IconButton
                            onClick={handlePlayPause}
                            style={{ background: '#eeeeee' }}>
                            {!playingWave ? <PlayArrowIcon color="primary" fontSize='large' /> : <PauseIcon color="primary" fontSize='large' />}
                        </IconButton>}
                    </Box>
                    <Typography variant="h3">
                        {artworkname}
                    </Typography>
                </Box>
            </Box>
            <Box m={4}>
                <div id="waveform"
                    style={{ backgroundImage: loaderforMedia ? 'url(/images/audioWave.png)' : '' }} ref={waveformRef} />
            </Box>
            {loaderforMedia ? <audio onTimeUpdate={handleTimeUpdate} ref={audioRef} src={audiofile} /> : ''}
        </Grid>
    );
}

const PlayAudioWaveForm = (props: any): ReactElement => {
    const { audiofile, artworkname } = props

    return (
        <Box id='audioBox'>
            <Waveform
                artworkname={artworkname}
                audiofile={getVideoUrl(audiofile)}
            />
        </Box>
    );
}


export default PlayAudioWaveForm;
