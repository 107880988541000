import { ReactElement } from 'react';
import { HeadTag } from '../../shared/components';
import { MyOffers } from './components';

const MyOffersPage = (): ReactElement => (
    <>
        <HeadTag title="Offers" />
        <MyOffers />
    </>
);

export default MyOffersPage;
