enum PreferenceKeys {
    ACCESS_TOKEN = 'ACCESS_TOKEN',
    BETA_ACCESS_TOKEN = 'BETA_ACCESS_TOKEN',
    REFRESH_TOKEN = 'REFRESH_TOKEN',
    KYC_TOKEN = 'KYC_TOKEN',
    DEPLOY_COUNT = 'DEPLOY_COUNT'
}

class AppPreference {
    private setInStorage(key: PreferenceKeys, value: any): void {
        localStorage.setItem(key.toString(), value);
    }

    private getFromStorage(key: PreferenceKeys): any {
        return localStorage.getItem(key.toString());
    }

    private deleteFromStorage(key: PreferenceKeys): void {
        localStorage.removeItem(key.toString());
    }

    clearStorage(): void {
        localStorage.clear();
    }

    setObject(key: PreferenceKeys, object: any): void {
        const objectStr = JSON.stringify(object);
        this.setInStorage(key, objectStr);
    }

    getObject(key: PreferenceKeys): any {
        const objectStr = this.getFromStorage(key);
        if (objectStr) {
            return JSON.parse(objectStr);
        }
        return null;
    }

    clearLoginToken(): void {
        this.deleteFromStorage(PreferenceKeys.ACCESS_TOKEN);
        this.deleteFromStorage(PreferenceKeys.REFRESH_TOKEN);
    }

    clearBetaToken(): void {
        this.deleteFromStorage(PreferenceKeys.BETA_ACCESS_TOKEN);
    }

    setAccessToken(accessToken: string): void {
        this.setInStorage(PreferenceKeys.ACCESS_TOKEN, accessToken);
    }

    getAccessToken(): string {
        return this.getFromStorage(PreferenceKeys.ACCESS_TOKEN);
    }
    
    setRefreshToken(refreshToken: string): void {
        this.setInStorage(PreferenceKeys.REFRESH_TOKEN, refreshToken);
    }

    getRefreshToken(): string {
        return this.getFromStorage(PreferenceKeys.REFRESH_TOKEN);
    }

    setKycToken(kycToken: string): void {
        this.setInStorage(PreferenceKeys.KYC_TOKEN, kycToken)
    }

    getKycToken(): string {
        return this.getFromStorage(PreferenceKeys.KYC_TOKEN)
    }

    setBetaAccessToken(accessToken: string): void {
        this.setInStorage(PreferenceKeys.BETA_ACCESS_TOKEN, accessToken);
    }

    getBetaAccessToken(): string {
        return this.getFromStorage(PreferenceKeys.BETA_ACCESS_TOKEN);
    }

    setDeployCount(count: number): void {
        this.setInStorage(PreferenceKeys.DEPLOY_COUNT, count);
    }
    
    getDeployCount(): number {
        return this.getFromStorage(PreferenceKeys.DEPLOY_COUNT);
    }
}

export default new AppPreference();
