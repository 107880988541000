import { ReactElement } from 'react';
import { Box } from '@material-ui/core';
import {  ViewProfile } from './components';

const ProfilePage = (props: any): ReactElement => (
    // UI Elements
    <Box>
        <ViewProfile {...props} />
        {/* <Box my={6}>
            <SecondaryEmail />
        </Box> */}
    </Box>
);
export default ProfilePage;
