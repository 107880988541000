import { ReactElement, useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Typography,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Card,
    CardContent,
    IconButton,
    // Divider,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CryptoAccountForm from './CryptoAccountForm';
import { Utils } from '../../../shared/helpers';
import { ProfileService } from '../../../services';
import { AppPrimaryButton } from '../../../shared/components/AppComponents';

export interface CryptoAccountInfo {
    name: string;
    cryptoType: string;
    address: string;
    id: string;
}

const CryptoAccountList = (): ReactElement => {
    const [openAccountCreateModal, setOpenAccountCreateModal] = useState(false);
    const [cryptoAccounts, setCryptoAccounts] = useState<CryptoAccountInfo[]>(
        []
    );
    const [cryptoAccountInfoToEdit, setCryptoAccountInfoToEdit] = useState<
        CryptoAccountInfo | undefined
    >(undefined);

    const [isLoading, setLoading] = useState(false);
    const [modalFormType, setModalFormType] = useState('');

    // API Calls
    const loadCryptoAccountDetails = (): void => {
        setLoading(true);
        ProfileService.getCrpytoAccountDetails()
            .then((response: any) => {
                const { addressDetails } = response.data.data;
                setCryptoAccounts(addressDetails);
                setLoading(false);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
                setLoading(false);
            });
    };

    // Hooks
    useEffect(() => {
        loadCryptoAccountDetails();
    }, []);

    // UI Actions
    const actionCancel = (): void => {
        setOpenAccountCreateModal(false);
        loadCryptoAccountDetails();
    };

    const actionOpenAddModal = (): void => {
        setOpenAccountCreateModal(true);
        setModalFormType('Add');
        setCryptoAccountInfoToEdit(undefined);
    };

    const actionOpenEditModal = (account: any): void => {
        setOpenAccountCreateModal(true);
        setModalFormType('Edit');
        setCryptoAccountInfoToEdit(account);
    };

    return (
        <Box className="profile-card">
            <Box className="card-header-btn">
                <Typography className="title" variant="h3">
                    Artwork Transfer Address
                </Typography>

                {/* {cryptoAccounts.length === 0 && (
                    <AppTeritaryButton
                        className="btn-small"
                        title="ADD NEW"
                        startIcon={<AddIcon />}
                        onClick={actionOpenAddModal}
                    />
                )} */}
            </Box>

            {cryptoAccounts.length === 0 && (
                <Box className="adress-sub-wrapper">
                    <Box className="address-line">
                        {/* {accountDetails.length === 0 && (
                 <> */}
                        <Typography
                            variant="body2"
                            className="subtitle subtitle-bank-line"
                        >
                            Manage your artwork transfer address to transfer
                            artworks.
                        </Typography>
                        {/* <Typography variant="body1" className="mt-5" id="textsmall">
                         You have not added your bank account yet.
                     </Typography>{' '} */}
                        {/* </>
             )} */}
                    </Box>
                    <Box className="adress-data adress-data-add-btn">
                        {/* {accountDetails.length === 0 && ( */}
                        <AppPrimaryButton
                            title="Add new"
                            className="primary-btn btnContent  addressBtn"
                            // startIcon={<AddIcon />}
                            onClick={actionOpenAddModal}
                        />
                        {/* )} */}
                    </Box>
                </Box>
            )}

            {cryptoAccounts.length > 0 && (
                <Grid container>
                    {!isLoading &&
                        cryptoAccounts.map((account) => (
                            <Grid key={account.address} item xs={12} xl={12}>
                                <Card>
                                    <CardContent className="profile-card bank-profile-card-box">
                                        <Box className="space-content">
                                            <Typography
                                                variant="body1"
                                                className="align-center"
                                            >
                                                Account
                                            </Typography>
                                            <Box>
                                                <IconButton>
                                                    <EditIcon
                                                        fontSize="small"
                                                        color="primary"
                                                        onClick={() =>
                                                            actionOpenEditModal(
                                                                account
                                                            )
                                                        }
                                                    />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                        <Box mt={2}>
                                            <TableContainer>
                                                <Table className="profile-card-table">
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell
                                                                component="th"
                                                                scope="row"
                                                                className="w-100"
                                                            >
                                                                Name :
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {account.name}
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow>
                                                            <TableCell
                                                                component="th"
                                                                scope="row"
                                                                className="w-100"
                                                            >
                                                                Account Type :
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {
                                                                    account.cryptoType
                                                                }
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow>
                                                            <TableCell
                                                                component="th"
                                                                scope="row"
                                                                className="w-100"
                                                            >
                                                                Account Address
                                                                :
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {Utils.formatedAddress(
                                                                    account.address
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                </Grid>
            )}

            {openAccountCreateModal && (
                <CryptoAccountForm
                    modalFormType={modalFormType}
                    openAccountModal={openAccountCreateModal}
                    onCloseAccountModal={actionCancel}
                    actionOnCancel={actionCancel}
                    cryptoAccounts={cryptoAccounts}
                    cryptoAccountInfoToEdit={cryptoAccountInfoToEdit}
                />
            )}
        </Box>
    );
};

export default CryptoAccountList;
