const Palette = {
    primary: {
        contrastText: '#FFFFFF',
        main: '#000000',
        dark: '#00000',
    },
    secondary: {
        contrastText: '#8522fa',
        main: '#FFFFFF',
    },
};

export default Palette;
