import { ReactElement } from 'react';
import { Typography, Container, Box } from '@material-ui/core';
import HeadSection from './HeadSection';
import { HeadTag } from '../../shared/components';

const TermsPage = (): ReactElement => {
    const getBaseUrl = (): string => window.location.origin;
    const title = 'Terms of Use';
    const description = 'Terms of Use';


    return (
        <Container className="terms-condition-wrapper">
            <HeadTag title={title} description={description} />
            <HeadSection title="" subtitle="TheUpsideSpace Terms of Use" content="" className="" />
            <Box className="terms-box-wrapper">
                <Box className="terms-condition-container">
                   
                    <Typography variant="subtitle1" className="poppins-medium terms-header">
                        TheUpsideSpace TERMS OF USE
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        Welcome to TheUpsideSpace! TheUpsideSpace is a platform that allows you to buy, sell,
                        view, share and display non-fungible tokens (“NFT(s)” or “NFT”). These Terms of Use are
                        entered into by and between you and TheUpsideSpace (referred to herein as “The Upside
                        Space,” “Company,” “we,” or “us”). The following terms and conditions, together with any
                        documents they expressly incorporate by reference, govern your access to and use of TheUpsideSpace, including any content, functionality, and services offered on or through
                        <a href='https://theupsidepsace.com'>https://theupsidepsace.com</a>
                        (the “Site”).
                    </Typography>
                    <Typography variant="subtitle1" className="poppins-medium terms-header">
                        1) Accepting these Terms of Use
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        These Terms of Use set out your rights and responsibilities when you use TheUpsideSpace to
                        buy, sell, or display NFT(s) or create a collection of NFT(s) (collectively, the “Services”), so
                        please read them carefully. TheUpsideSpace is an administrative platform that facilitates
                        transactions between a buyer and a seller but is not a party to any agreement between the
                        buyer and seller of NFT(s) or between any users. By clicking on the “I Accept” button,
                        completing the account registration process, or using the Site, you accept and agree to be
                        bound and abide by these Terms of Use and all of the terms incorporated herein by reference.
                        By agreeing to these terms, you hereby certify that you are at least 18 years of age. If you do
                        not agree to these Terms of Use, you must not access or use the Site.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        Please note that this Agreement contains an arbitration clause and class action waiver. By
                        agreeing to these Terms of Use, you agree to resolve all disputes through binding individual
                        arbitration, which means that you waive any right to have the dispute decided by a judge or jury,
                        and you waive any right to participate in collective action, whether that be a class action, class
                        arbitration, or representative action.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        TheUpsideSpace reserves the right to change or modify these Terms of Use at any time and in
                        our sole discretion. You agree and understand that by logging into your account, accessing or
                        using the Site following any change to these Terms of Use, you agree to the revised Terms of
                        Use and all of the terms incorporated therein by reference. We encourage you to review the
                        Terms of Use from time to time to ensure that you understand the terms and conditions that
                        apply when you access or use the Site.
                    </Typography>

                    <Typography variant="subtitle1" className="poppins-medium terms-header">
                        2) Your TheUpsideSpace Account
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        You need to create an account with TheUpsideSpace to use the Services. When you create an
                        account, we will ask you for some information about yourself. We may require you, in our sole
                        discretion, to provide additional information and/or documents. If you do not provide complete
                        and accurate information and/or documents in response to such a request, we may refuse to
                        provide you with the Services.
                    </Typography>
                   
                    <Typography variant="body1" className="my-2 terms-text">
                        Your TheUpsideSpace account is subject to the following conditions:
                    </Typography>
                    <ul>
                        <li>
                            <Typography variant="body1" className="my-2 terms-text">
                                You may only open one account on TheUpsideSpace. If we have a reasonable
                                suspicion that you have opened multiple accounts on TheUpsideSpace, we reserve the
                                right to take whatever action we deem appropriate, including but not limited to closing
                                and/or freezing your accounts.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1" className="my-2 terms-text">
                                <u>Access:</u> you understand and agree that access to your TheUpsideSpace account is
                                limited solely to you. You agree that you will not sell, rent, lease, or grant access to your
                                TheUpsideSpace account to any person without our prior written permission.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1" className="my-2 terms-text">
                                <u>Security:</u> you understand and agree that you are solely responsible for maintaining the
                                security of your account and control over any user names, passwords, or any other
                                codes that you use to access the Services. Any unauthorized access to your account by
                                third parties could result in the loss or theft of NFT(s) and/or funds held in your account
                                and any associated accounts, including your linked bank account(s) and credit card(s).
                                You understand and agree that you will not hold us responsible for managing and
                                maintaining the security of your account. You further understand and agree that we are
                                not responsible (and you will not hold us responsible) for any unauthorized access to or
                                use of your account. You are responsible for monitoring your account. If you notice any
                                unauthorized or suspicious activity in your account, please notify us immediately.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1" className="my-2 terms-text">
                                <u>Trading Fees:</u> by buying or selling a NFT on TheUpsideSpace, you agree to pay all
                                applicable fees and you authorize TheUpsideSpace to automatically deduct fees
                                directly from your payment. You will always be provided with a breakdown of fees prior
                                to your purchase or sale of a NFT.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1" className="my-2 terms-text">
                                <u>Communication:</u> you agree and understand that we will communicate with you via
                                electronic means. To ensure that you receive all of our communications, you agree to
                                keep your email address and telephone number current and notify us if there are any
                                changes. You agree that any notices, agreements, disclosures, or other communications
                                delivered to your email address on record is considered valid.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1" className="my-2 terms-text">
                                <u>Unclaimed Property:</u> if TheUpsideSpace is holding funds in your account and has no
                                record of your use of the Service for several years, we may be required, upon passage
                                of applicable time periods, to report these funds as unclaimed property in accordance
                                with the abandoned property and escheat laws. If this occurs, we will use reasonable
                                efforts to give you written notice. If you fail to respond within seven business days or as
                                required by law, we may be required to deliver any such funds to the applicable state or
                                jurisdiction as unclaimed property. We reserve the right to deduct a dormancy fee or
                                administrative fee from such unclaimed funds, as permitted by applicable law.
                            </Typography>
                        </li>
                    </ul>

                    <Typography variant="subtitle1" className="poppins-medium terms-header">
                        3) Account Suspension
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        You agree that TheUpsideSpace has the right to immediately suspend your account, pause or
                        cancel your access to the Services, or close your account if we suspect, in our sole discretion,
                        that (1) your account is being used for money laundering or any illegal activity; (2) you have
                        concealed or provided false identification information or other details; (3) you have engaged in
                        fraudulent activity; (4) you have acquired NFT(s) using inappropriate methods (including but not
                    </Typography>
                   
                    <Typography variant="body1" className="my-2 terms-text ">
                        limited to using stolen funds or payments methods, or attempting to chargeback your payment
                        while retaining or disposing of a NFT); (5) you are using, employing, or operating bots or other
                        forms of automation and/or multiple accounts to engage in any activity on TheUpsideSpace; or
                        (6) you have otherwise acted in violation of these Terms of Use.<br />
                        IF TheUpsideSpace HAS A REASONABLE BELIEF THAT YOU HAVE ENGAGED IN ANY
                        OF THE ACTIVITIES (1), (2), (3), (4), (5) OR (6) ABOVE, TheUpsideSpace RESERVES
                        THE RIGHT, IN ITS SOLE DISCRETION, TO CONFISCATE ANY NFT(S) PURCHASES
                        PURSUANT TO THOSE ACTIVITIES, TERMINATE YOUR ACCOUNT, PROHIBIT YOU FROM
                        UTILISING OUR PLATFORM IN THE FUTURE AND/OR DEEM SUCH TRANSACTIONS NULL
                        AND VOID.
                    </Typography>

                    <Typography variant="subtitle1" className="poppins-medium terms-header">
                        4) Communication
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        You agree that we may send you promotional communications by email, including, but not
                        limited to, newsletters, special offers, surveys, and other news and information we think will be
                        of interest to you.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        You agree that we may send you communications by email or text message that pertain to the
                        status of a purchase or sale of a NFT on TheUpsideSpace, and other communications that
                        pertain to your interaction with the website, including but not limited to notifications about the
                        status of a NFT you are interested in purchasing.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        You may opt out of promotional communications at any time by following the instructions
                        provided therein.
                    </Typography>

                    <Typography variant="subtitle1" className="poppins-medium terms-header">
                        5) User Content
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        The Site allows (1) users to create a profile where they can post certain information about
                        themselves, link to other websites, and display NFT(s) that they own (collectively, “Profile
                        Information”); and (2) artists or creators of NFT(s) (“NFT Creators”) to post their NFT(s) (“NFT
                        Creations”), and bios (collectively, with Profile Information, “User Content”). All User Content
                        must comply with these Terms of Use.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        Any Profile Information you post to the Site will be considered non-confidential and non-
                        proprietary. By providing any User Content on the Website, you grant us and our affiliates and
                        our respective licensees, successors, and assigns the right to use, reproduce, modify, perform,
                        display, distribute, retransmit, publish, broadcast, and otherwise disclose to third parties any
                        such material for any purpose. You represent and warrant that (1) you own and control all rights
                        in and to your User Content and have the right to grant such licenses to us and our affiliates and
                        our respective licensees, successors, and assigns; and (2) all of your User Content do and will
                        comply with these Terms of Use.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        You understand and agree that you are responsible for any User Content you submit or
                        contribute, and you, not TheUpsideSpace, have full responsibility for such content, including its
                    </Typography>
                   
                    <Typography variant="body1" className="my-2 terms-text">
                        Legality, reliability, accuracy, and appropriateness. We are not responsible or liable to any third
                        party for the content, accuracy, or appropriateness of any User Content posted by you or any
                        other user on the Site.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        If you are a NFT Creator, you hereby grant TheUpsideSpace non-exclusive, irrevocable,
                        worldwide, royalty-free licenses to:
                    </Typography>
                    <ul>
                        <li>
                            <Typography variant="body1" className="my-2 terms-text">
                                use the artwork contained in your NFT Creation for purposes of minting, displaying and selling your NFT Creations;
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1" className="my-2 terms-text">
                                use your name and image for marketing or promotional purposes;
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1" className="my-2 terms-text">
                                use, copy, modify, distribute, publish, and otherwise transmit images from your NFT Creations for marketing or promotional purposes; and
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1" className="my-2 terms-text">
                                use your biography and other public information about you to promote your NFT Creations.
                            </Typography>
                        </li>
                    </ul>

                    <Typography variant="body1" className="my-2 terms-text">
                        As a NFT Creator, you understand and agree that:
                    </Typography>
                    <ul>
                        <li>
                            <Typography variant="body1" className="my-2 terms-text">
                                You are responsible for the accuracy and content of your NFT Creations
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1" className="my-2 terms-text">
                                Any NFT Creation that violates any of TheUpsideSpace’s policies may be obfuscated or deleted by TheUpsideSpace, in its sole discretion;
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1" className="my-2 terms-text">
                                You will not use the artwork incorporated into your NFT Creations for the creation of additional non-fungible tokens;
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1" className="my-2 terms-text">
                                You will respect the intellectual property of others; and
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1" className="my-2 terms-text">
                                You will not coordinate pricing with other NFT Creators.
                            </Typography>
                        </li>
                    </ul>
                    <Typography variant="body1" className="my-2 terms-text">
                        We reserve the right, in our sole discretion, to prohibit you from uploading your NFT Creations.
                        Although we are not required to monitor any User Content, we may, in our sole discretion,
                        remove User Content at any time and for any reason without notice. TheUpsideSpace may
                        monitor User Content to detect and prevent fraudulent activity or violation of these Terms of
                        Use.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        You may choose to submit feedback, comments, ideas or other feedback about the The Upside
                        Space (collectively, “Feedback”). By submitting any Feedback, you agree that we are free to
                        use such Feedback at our discretion and without additional compensation to you, and to
                        disclose such Feedback to third parties (whether on a non-confidential basis or otherwise). You
                        hereby grant us a perpetual, irrevocable, nonexclusive, worldwide licence under all rights
                        necessary for us to incorporate and use your Feedback for any purpose.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        We may, but are not obligated to, monitor the NFTs, metadata, NFT Media and User Content
                        uploaded to or minted on the TheUpsideSpace for any infringement of a third party’s
                        intellectual property rights. If you believe that any User Content that is available on the TheUpsideSpace infringes your copyright, you may send us a notice that the content be removed.
                    </Typography>
                   
                    <Typography variant="subtitle1" className="poppins-medium terms-header">
                        6) Ownership
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        Unless otherwise indicated in writing by us, the Site, all content, and all other materials
                        contained therein, including, without limitation, TheUpsideSpace logo, and all designs, text,
                        graphics, pictures, information, data, software, sound files, other files, and the selection and
                        arrangement thereof (collectively, “TheUpsideSpace Content”) are the proprietary property of
                        TheUpsideSpace or our affiliates, licensors, or users, as applicable. TheUpsideSpace logo
                        and any TheUpsideSpace product or service names, logos, or slogans that may appear on the
                        Site or elsewhere are trademarks of TheUpsideSpace or our affiliates, and may not be copied,
                        imitated or used, in whole or in part, without our prior written permission.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        You may not use any TheUpsideSpace Content to link to the Site or Content without our
                        express written permission. You may not use framing techniques to enclose any The Upside
                        Space Content without our express written consent. In addition, the look and feel of the Site and
                        Content, including without limitation, all page headers, custom graphics, button icons, and
                        scripts constitute the service mark, trademark, or trade dress of TheUpsideSpace and may not
                        be copied, imitated, or used, in whole or in part, without our prior written permission.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        Notwithstanding anything to the contrary herein, you understand and agree that you shall have
                        no ownership or other property interest in your account, and you further agree that all rights in
                        and to your account are and shall forever be owned by and inure to the benefit of The Upside
                        Space.
                    </Typography>

                    <Typography variant="subtitle1" className="poppins-medium terms-header">
                        7) Terms of Sale
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        By placing a bid or an order on or through TheUpsideSpace, you agree that you are submitting
                        a binding offer to purchase such NFT or Service. Your order is accepted and confirmed once
                        purchase is complete, and TheUpsideSpace displays the Confirmation Page (&quot;Confirmation
                        Page&quot;). YOU HEREBY EXPRESSLY AGREE THAT THE SUPPLY OF A NFT BEGINS
                        IMMEDIATELY AFTER THE CONFIRMATION PAGE IS DISPLAYED.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        No refunds or returns are permitted except with respect to any statutory warranties or
                        guaranties that cannot be excluded or limited by law.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        NFT Creators may choose to provide certain rights to holders of their NFT's, which may include, but is not limited to, physical items, special access, or other exclusive content (collectively, “Additional Items”). Any Additional Items will be provided directly to you from the NFT Creator; TheUpsideSpace has no involvement in such transactions and is not a party to such transactions. Notwithstanding the above, TheUpsideSpace may publish a description of the Additional Items on its website on behalf of the NFT Creator. If you own a NFT that entitles you to Additional Items, you hereby consent to TheUpsideSpace sharing your contact information with the NFT Creator to facilitate delivery of your Additional Items.<b> _Unless otherwise stated, transactions on the Secondary Market are only for NFT's and do not grant the purchaser the right to any Additional Items. _</b>
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        NFT Creators may choose to provide certain rights to holders of their NFT(s), which may
                        include, but is not limited to, physical items, special access, or other exclusive content
                        (collectively, “Additional Items”). Any Additional Items will be provided directly to you from the
                        NFT Creator; TheUpsideSpace has no involvement in such transactions and is not a party to
                        such transactions. Notwithstanding the above, TheUpsideSpace may publish a description of
                        the Additional Items on its website on behalf of the NFT Creator. If you own a NFT that entitles
                        you to Additional Items, you hereby consent to TheUpsideSpace sharing your contact
                        information with the NFT Creator to facilitate delivery of your Additional Items. Unless
                    </Typography>
                   
                    <Typography variant="body1" className="my-2 terms-text">
                        <b>
                            otherwise stated, transactions on the Secondary Market are only for NFT(s) and do not
                            grant the purchaser the right to any Additional Items.
                        </b>
                    </Typography>
                    <Typography variant="subtitle1" className="poppins-medium terms-header">
                        8) Intellectual Property
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        Outside TheUpsideSpace Content, all other trademarks, product names, and logos on the Site
                        are the property of their respective owners and may not be copied, imitated, or used, in whole or
                        in part, without the permission of the applicable trademark holder. Without limiting the foregoing,
                        if you believe that third-party material hosted by TheUpsideSpace infringes your copyright or
                        trademark rights, please file a notice of infringement by contacting TheUpsideSpace’s
                        Copyright Agent at <a href='mailto:info@theupsidespace.com'>info@theupsidespace.com</a>
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        By using the Website, in addition to any other licenses contained in this Agreement, you grant
                        us a worldwide, non-exclusive, sublicensable, royalty-free license to use, copy, modify, and
                        display any content, including but not limited to text, materials, images, files, communications,
                        comments, feedback, suggestions, ideas, concepts, questions, data, or otherwise, that you
                        submit or post on or through the Website for our current and future business purposes, including
                        to provide, promote, and improve the Website. This includes any digital file, art, or other material
                        linked to or associated with any NFTs that are displayed on the Website.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        You represent and warrant that you have, or have obtained, all rights, licenses, consents,
                        permissions, power and/or authority necessary to grant the rights granted herein for any content
                        that you create, submit, post, promote, or display on or through the Website. You represent and
                        warrant that such content does not contain material subject to copyright, trademark, publicity
                        rights, or other intellectual property rights, unless you have necessary permission or are
                        otherwise legally entitled to post the material and to grant TheUpsideSpace the license
                        described above, and that the content does not violate any laws.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        In such event, please provide TheUpsideSpace’s Copyright Agent with the following
                        information: (1) an electronic or physical signature of the person authorized to act on behalf of
                        the owner of the copyright interest; (2) a description of the copyrighted work that you claim has
                        been infringed; (3) a description of the location on Site of the material that you claim is
                        infringing; (4) your address, telephone number and e-mail address; (5) a written statement by
                        you that you have a good faith belief that the disputed use is not authorized by the copyright
                        owner, its agent or the law; and (6) a statement by you, made under penalty of perjury, that the
                        above information in your notice is accurate and that you are the copyright owner or authorized
                        to act on the copyright owner’s behalf.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        TheUpsideSpace’s policy is to suspend or terminate the account of repeat infringers. TheUpsideSpace’s response may depend on relevant aggravating or mitigating circumstances, but
                        generally we will terminate an account if it is the subject of three valid infringement notices.
                    </Typography>
                    <Typography variant="subtitle1" className="poppins-medium terms-header">
                        9) Your Use of TheUpsideSpace
                    </Typography>
                   
                    <Typography variant="body1" className="my-2 terms-text">
                        We hereby grant you a limited, non-exclusive, non-transferable, revocable license to access and
                        use the Site and Content. Our grant of such license is subject to the following conditions:
                    </Typography>
                    <ul>
                        <li>
                            <Typography variant="body1" className="my-2 terms-text">
                                Unlawful Activity: you agree not to engage, or assist, in any activity that violates any law,
                                statute, ordinance, regulation, or sanctions program, including but not limited to the U.S.
                                Department of Treasury’s Office of Foreign Assets Control (“OFAC”), or that involves
                                proceeds of any unlawful activity.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1" className="my-2 terms-text">
                                Abusive Activity: you agree not to engage in any activity that poses a threat to TheUpsideSpace, for example by distributing a virus or other harmful code, or through
                                unauthorized access to the Site or other users’ accounts.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1" className="my-2 terms-text">
                                Inappropriate Behavior: you agree not to interfere with other users’ access to or use of
                                the Services.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1" className="my-2 terms-text">
                                Communication: you agree not to communicate with other users for purposes of (1)
                                sending unsolicited advertising or promotions, requests for donations, or spam; (2)
                                engaging in hate speech or harassing or abusing other users; (3) interfering with
                                transactions of other users. You agree not to use data collected from the Site to contact
                                individuals, companies, or other persons or entities outside the Site for any purpose,
                                including but not limited to marketing activity.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1" className="my-2 terms-text">
                                Fraud: you agree not to engage in any activity which operates to defraud The Upside
                                Space, others users, or any other person; or to provide any false, inaccurate, or
                                misleading information to TheUpsideSpace.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1" className="my-2 terms-text">
                                Gambling: you agree not to utilize the Services to engage in any lottery, bidding fee
                                auctions, contests, sweepstakes, or other games of chance.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1" className="my-2 terms-text">
                                Taxes: Neither TheUpsideSpace nor any other TheUpsideSpace Party is responsible
                                for determining the withholding, sales, use, value added, transfer or other taxes, together
                                with any interest and penalties imposed with respect thereto (“Taxes”), that apply to your
                                NFT transactions. You agree that you are solely responsible for determining what, if any,
                                Taxes apply to your NFT transactions and to withhold, collect, report and remit the
                                correct amounts of Taxes to the appropriate taxing authorities. Unless otherwise
                                indicated on an applicable invoice, any amounts due in respect of a NFT transaction are
                                exclusive of sale, use, value added or similar Taxes (“Sales Taxes”) which shall be your
                                responsibility. Any payments with respect to your NFT transactions shall be made
                                without deduction or withholding for any Taxes, except as required by applicable law. If
                                any applicable law requires the deduction or withholding of any Tax (a “Withholding
                                Tax”) from any such payment then the sum payable by you shall be increased as
                                necessary so that after such deduction or withholding has been made the amount
                                received is equal to the sum that would have been received had no such deduction or
                                withholding been made. Upon our request, you agree to promptly provide a properly
                                executed Internal Revenue Service Form W-9 or applicable Internal Revenue Service
                                W-8 and any other tax form that is reasonably required by us so to comply with our tax
                                reporting obligations.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1" className="my-2 terms-text">
                                Intellectual Property Infringement: you agree not to (1) engage in transactions involving
                                items that infringe or violate any copyright, trademark, right of publicity or privacy or any
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1" className="my-2 terms-text">
                                Hyperlinks: you are hereby granted a limited, non-exclusive, non-transferable, revocable
                                license to create a text hyperlink to the Site for noncommercial purposes, provided that
                                such link does not portray TheUpsideSpace, our affiliates, or the Services in a false,
                                misleading, derogatory, or otherwise defamatory manner, and provided further that the
                                linking site does not contain any adult or illegal materials, or other materials that is
                                offensive, harassing, or otherwise objectionable.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1" className="my-2 terms-text">
                                Additional Prohibitions: you agree not to (1) distribute, publish, broadcast, reproduce,
                                copy, retransmit, or publicly display any Content; (2) modify or create derivative works
                                from the Site or Content, or any portion thereof; (3) use any data mining, robots, or
                                similar data gathering or extraction methods on the Site or Content; (4) download any
                                portion of the Site or Content, other than for purposes of page caching, except as
                                expressly permitted by us.
                            </Typography>
                        </li>
                    </ul>
                    <Typography variant="body1" className="my-2 terms-text">
                        If you are unsure whether a contemplated use would violate these Terms of Use, please contact
                        us at the address listed below under the section entitled ‘Contact Information’ below.
                    </Typography>


                    <Typography variant="subtitle1" className="poppins-medium terms-header">
                        10) Accidental Transfers
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        You understand and agree that in the event that you deposit digital assets or NFT(s) into an
                        incorrect address, we may not have the ability, and are under no obligation or duty, to return
                        such digital assets or NFT(s) to you. This section applies regardless of whether we control the
                        destination address.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        **Please note: **exceptions to this section may be considered and made on a case-by-case
                        basis and in our sole discretion.
                    </Typography>

                    <Typography variant="subtitle1" className="poppins-medium terms-header">
                        11) Privacy
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        You acknowledge and agree to the collection, use, and disclosure of your personal information
                        in accordance with TheUpsideSpace’s Privacy Policy, which is incorporated into this User
                        Agreement by reference.
                    </Typography>

                    <Typography variant="subtitle1" className="poppins-medium terms-header">
                        12) Modifications
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        You agree and understand that we may modify part or all of TheUpsideSpace or the Services
                        without notice.
                    </Typography>

                    <Typography variant="subtitle1" className="poppins-medium terms-header">
                        13) Risks
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        Please note the following risks in accessing or using TheUpsideSpace: The price and liquidity
                        of blockchain assets, including NFT(s), are extremely volatile and may be subject to large                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        You agree and understand that you are solely responsible for determining the nature, potential value, suitability, and appropriateness of these risks for yourself, and that TheUpsideSpace does not give advice or recommendations regarding NFT's, including the suitability and appropriateness of, and investment strategies for, NFT's. You agree and understand that you access and use TheUpsideSpace at your own risk; however, this brief statement does not disclose all of the risks associated with NFT's and other digital assets. You agree and understand that TheUpsideSpace will not be responsible for any communication failures, disruptions, errors, distortions or delays you may experience when using NFT's, however caused.
                    </Typography>
                   
                    <Typography variant="subtitle1" className="poppins-medium terms-header">
                        14) Third Parties
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        TheUpsideSpace may rely on third-party platforms to perform NFT transactions. Our Site may
                        contain links to third-party websites or applications (collectively, “Third Party Sites”). The Upside
                        Space does not own or control Third Party Sites. You understand and agree that your use of
                        any Third Party Site is subject to any terms of use and/or privacy policy provided by such Third
                        Party Site. TheUpsideSpace is not a party to any such agreement. You should review any
                        terms of use and/or privacy policy provided by such Third Party Site and should make whatever
                        investigation you feel necessary or appropriate before proceeding with any transaction with any
                        third party.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        TheUpsideSpace provides these Third Party Sites only as a convenience and does not review,
                        approve, monitor, endorse, warrant, or make any representations with respect to Third Party
                        Sites, or their products or services. You use all links in Third Party Sites at your own risk. When
                        you leave our Site, our Agreement and policies no longer govern. We may, but are not obligated
                        to, warn you that you are leaving our Site.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        In order to use some functionality of our Services, you must open an account provided by Third
                        Parties and you must accept the Third Party Terms of Service and Privacy Policy. Any funds
                    </Typography>
                   
                    <Typography variant="subtitle2" className="poppins-medium terms-header">
                        held in any of the Third Party account are held by that Third Party financial institution partners
                        as set out in their Terms of Service. You authorize TheUpsideSpace to share your identity and
                        account data with the relevant Third Parties for the purposes of opening and supporting your
                        payment account, and you are responsible for the accuracy and completeness of that data. You
                        understand that you will access and manage your Third Parties payment account through our
                        application, and the Third Party account notifications will be sent by them. TheUpsideSpace
                        will provide customer support for your account activity, and can be reach at
                        info@theupsidespace.com.
                    </Typography>
                    <Typography variant="subtitle2" className="poppins-medium terms-header">
                        Some transactions on our site may be facilitated and run by Metamask. Any such transactions
                        are governed by the Metamask Terms of Service and Privacy Policy.
                    </Typography>

                    <Typography variant="subtitle1" className="poppins-medium terms-header">
                        15) External Projects
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        TheUpsideSpace allows for the display, purchase, and sale of NFT(s) that were not initially
                        sold through TheUpsideSpace (“External Projects”). TheUpsideSpace has the right, in its sole
                        discretion, to determine the criteria that External Projects must meet before being allowed onto
                        TheUpsideSpace Platform and to remove any External Projects that do not comply with these
                        Terms of Use or other requirements of TheUpsideSpace.
                    </Typography>
                    <ol>
                        <li>
                            <Typography variant="body1" className="my-2 terms-text">
                                <b>1. Creators of External Projects.</b> If you are a NFT Creator of an External Project, you will be entitled to the same share of sales of your NFT's on TheUpsideSpace as you would be on the platform that you initially released the NFT's on; provided, however, that: (i) you and your NFT Creations from External Projects comply with these Terms of Use; and (ii) you provide TheUpsideSpace with all information that it requests from you. By selling your NFT Creations from an External Project on TheUpsideSpace, you represent and warrant that you have all necessary licenses and permits to the artwork contained in your NFT's. As a NFT Creator of an External Project, you grant TheUpsideSpace all licenses granted by NFT Creators under these Terms of Use. Your proceeds will be delivered into your TheUpsideSpace account.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1" className="my-2 terms-text">
                                <b>2. Owners of NFT's from External Projects.</b> If you acquired a NFT from an External Project outside TheUpsideSpace, by transferring such NFT to your TheUpsideSpace account, you represent and warrant that you have all right and title to such NFT and that you have the right to display and/or sell such NFT on TheUpsideSpace.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1" className="my-2 terms-text">
                                3. **Buyers of External Projects. **You understand and agree that your purchase of a NFT from an External Project is made from the seller of such NFT and not TheUpsideSpace. TheUpsideSpace makes no representations or warranties with respect to NFT's from External Projects and all such purchases are made on an “as is” basis.
                            </Typography>
                        </li>
                    </ol>

                    <Typography variant="subtitle1" className="poppins-medium terms-header">
                        16) External Listings
                    </Typography>
                   
                    <Typography variant="body1" className="my-2 terms-text">
                        information for External Listings, it is not possible for TheUpsideSpace to guarantee the prices
                        displayed for External Listing, which can be changed at any time. By purchasing an External
                        Listing, you acknowledge and agree that TheUpsideSpace is in no way responsible for the
                        accuracy, timeliness, or completeness of such External Listings.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        If you choose to purchase a NFT from an External Listing, TheUpsideSpace will act as a
                        facilitator in the acquisition of such NFT and transfer it to you. TheUpsideSpace will cease
                        acting as facilitator as soon as such NFT is transferred to you.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        To facilitate the acquisition of your External Listing, TheUpsideSpace will charge your method
                        of payment, in addition to the price of the External Listing, any applicable charges for taxes and
                        fees (an amount that will be disclosed to you before you elect to proceed). This charge includes
                        an amount we pay on your behalf to recover the amount we pay to the External Listing platform
                        in connection with your acquisition.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        If TheUpsideSpace is unable to acquire a NFT from an External Listing for any reason
                        whatsoever, TheUpsideSpace will cancel the transaction and your funds will be returned to
                        you. IN NO EVENT WILL TheUpsideSpace BE LIABLE FOR THE FAILURE TO ACQUIRE A
                        NFT FROM AN EXTERNAL LISTING.
                    </Typography>

                    <Typography variant="subtitle1" className="poppins-medium terms-header">
                        17) Redeemable
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        If you are the eligible owner of an NFT created by a person who is a contributor of that NFT (the
                        “Contributor”), depending on the terms of sale of that NFT and as determined by Contributor in
                        its sole discretion, the Contributor may provide you with a digital or physical item
                        (“Redeemable”) upon the Contributor’s receipt of your written request and proof of satisfaction
                        of the Contributor’s terms, including, but not limited to, evidence that you are the eligible buyer
                        of a qualifying NFT. Any Redeemable is provided by the Contributor directly to the relevant
                        eligible NFT owner, and the Redeemable (including, but not limited to, its delivery,
                        correspondence to its description, and condition or quality) is the sole and exclusive
                        responsibility of the Contributor. For the purposes of clarity, you understand that this clause
                        applies only to qualifying NFTs that state that such a request for the Redeemable may be made
                        by the Buyer. This clause shall not apply to any other NFTs on the platform.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        TheUpsideSpace shall have no liability whatsoever with respect to the Redeemable (including,
                        but not limited to, its delivery, correspondence to its description, and condition or quality).
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        Any notices or questions regarding the Redeemable should be sent to the Contributor directly,
                        through contact details as may be provided at the relevant time of purchasing the qualifying
                        NFT. You understand that TheUpsideSpace is not involved in this transaction. Any such
                        transaction shall be at your sole liability and risk. Furthermore, any coordination for the
                        Redeemable is between the you and the Contributor.
                    </Typography>
                   
                    <Typography variant="body1" className="my-2 terms-text">
                        For avoidance of doubt, for any arrangement between the Buyer, Contributor, or artist, including
                        but not limited to circumstances where physical work is offered for free by the artist but is not
                        linked to services provided by TheUpsideSpace and save for the digital artwork itself where
                        TheUpsideSpace serves as a platform, TheUpsideSpace is not involved in any other
                        transactions which are between the Contributor, the buyer and/or the artist.
                    </Typography>

                    <Typography variant="subtitle1" className="poppins-medium terms-header">
                        18)Disclaimers
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN A WRITING BY THE UPSIDE
                        SPACE, THE SITE, CONTENT CONTAINED THEREIN, AND NFT(s) LISTED THEREIN ARE
                        PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OR
                        CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED. TheUpsideSpace (AND
                        ITS SUPPLIERS) MAKE NO WARRANTY THAT THE SITE WILL (1) MEET YOUR
                        REQUIREMENTS; (2) BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR
                        ERROR-FREE BASIS; OR (3) BE ACCURATE, RELIABLE, COMPLETE, LEGAL, OR SAFE.
                        ADDITIONALLY, TO THE EXTENT PERMITTED BY APPLICABLE LAW, WE WILL NOT BE
                        RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS RELATED TO ANY USE OF NFTS,
                        INCLUDING WITHOUT LIMITATION ANY LOSSES, DAMAGES OR CLAIMS ARISING FROM:
                        (A) USER ERROR SUCH AS FORGOTTEN PASSWORDS, INCORRECTLY CONSTRUCTED
                        TRANSACTIONS OR MISTYPED ADDRESSES; (B) SERVER FAILURE OR DATA LOSS; (C)
                        CORRUPTED WALLET FILES; (D) UNAUTHORISED ACCESS TO APPLICATIONS; (E) ANY
                        UNAUTHORISED THIRD PARTY ACTIVITIES, INCLUDING WITHOUT LIMITATION THE USE
                        OF VIRUSES, PHISHING, BRUTEFORCING OR OTHER MEANS OF ATTACK AGAINST THE
                        NFT PLATFORM OR ANY NFTS.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        WE WILL NOT BE LIABLE FOR ANY LOSS OF ANY KIND FROM ANY ACTION TAKEN OR
                        TAKEN IN RELIANCE ON MATERIAL OR INFORMATION, CONTAINED ON THE SITE. THEUPSIDESPACE DOES NOT REPRESENT OR WARRANT THAT CONTENT ON THE SITE IS
                        ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        WHILE TheUpsideSpace ATTEMPTS TO MAKE YOUR ACCESS TO AND USE OF THE
                        SITE AND CONTENT SAFE, TheUpsideSpace CANNOT AND DOES NOT REPRESENT
                        OR WARRANT THAT THE SITE, CONTENT, ANY NFT(S) LISTED ON OUR SITE OR OUR
                        SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. WE CANNOT
                        GUARANTEE THE SECURITY OF ANY DATA THAT YOU DISCLOSE ONLINE. YOU ACCEPT
                        THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE
                        OVER THE INTERNET AND WILL NOT HOLD US RESPONSIBLE FOR ANY BREACH OF
                        SECURITY UNLESS IT IS DUE TO OUR GROSS NEGLIGENCE.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND TAKE NO
                        RESPONSIBILITY FOR, AND WILL NOT BE LIABLE TO YOU FOR, ANY USE OF NFT(S)
                        INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS ARISING FROM:
                        (1) USER ERROR SUCH AS FORGOTTEN PASSWORDS, INCORRECTLY CONSTRUCTED
                        TRANSACTIONS, OR MISTYPED ADDRESSES; (2) SERVER FAILURE OR DATA LOSS; (3)
                        CORRUPTED WALLET FILES; (4) UNAUTHORIZED ACCESS TO APPLICATIONS; (5) ANY UNAUTHORIZED THIRD PARTY ACTIVITIES, INCLUDING WITHOUT LIMITATION THE USE
                        OF VIRUSES, PHISHING, BRUTEFORCING OR OTHER MEANS OF ATTACK AGAINST THE
                        SITE OR NFT(S).
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        NFT(S) ARE INTANGIBLE DIGITAL ASSETS. THEY EXIST ONLY BY VIRTUE OF THE
                        OWNERSHIP RECORD MAINTAINED IN THE ETHEREUM NETWORK. ANY TRANSFER OF
                        TITLE THAT MIGHT OCCUR IN ANY UNIQUE DIGITAL ASSET OCCURS ON THE
                        DECENTRALIZED LEDGER WITHIN THE ETHEREUM PLATFORM. WE DO NOT
                        GUARANTEE THAT TheUpsideSpace OR ANY TheUpsideSpace PARTY CAN
                        EFFECT THE TRANSFER OF TITLE OR RIGHT IN ANY NFT(S).
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        TheUpsideSpace is not responsible for sustained losses due to vulnerability or any kind of
                        failure, abnormal behavior of software (e.g., wallet, smart contract), blockchains, or any other
                        features of NFT(s). TheUpsideSpace is not responsible for sustained losses due to late reports
                        by developers or representatives (or no report at all) of any issues with the blockchain
                        supporting NFT(s) including forks, technical node issues or any other issues having fund losses
                        as a result.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        Nothing in this Agreement shall exclude or limit liability of either party for fraud, death or bodily
                        injury caused by negligence, violation of laws, or any other activity that cannot be limited or
                        excluded by legitimate means.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        TO THE FULLEST EXTENT PROVIDED BY LAW, TheUpsideSpace HEREBY DISCLAIMS
                        ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR
                        OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
                        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-
                        INFRINGEMENT AS TO THE SITE AND CONTENT CONTAINED THEREIN.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED
                        OR LIMITED UNDER APPLICABLE LAW.
                    </Typography>
                    <Typography variant="subtitle1" className="poppins-medium terms-header">
                        19) Limitation of Liability
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL TheUpsideSpace
                        BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY INDIRECT,
                        CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES
                        ARISING FROM THIS AGREEMENT, THE SITE, PRODUCTS OR THIRD PARTY SITES AND
                        PRODUCTS, OR FOR ANY DAMAGES RELATED TO LOSS OF REVENUE, LOSS OF
                        PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF
                        GOODWILL, OR LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING
                        NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE AND
                        EVEN IF TheUpsideSpace HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                        DAMAGES. ACCESS TO, AND USE OF, THE SITES, PRODUCTS OR THIRD PARTY SITES
                        AND PRODUCTS ARE AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE
                        DEVICE OR LOSS OF DATA RESULTING THEREFROM.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN NO EVENT
                        SHALL THE MAXIMUM AGGREGATE LIABILITY OF TheUpsideSpace ARISING OUT OF
                        OR IN ANY WAY RELATED TO THIS AGREEMENT, THE ACCESS TO AND USE OF THE
                        SITE, CONTENT, NFT(S), OR ANY PRODUCTS OR SERVICES PURCHASED ON THE SITE
                        EXCEED THE GREATER OF (A) 100 USD OR(B) THE AMOUNT YOU HAVE PAID TO THE
                        UPSIDE SPACE FOR THE SERVICES IN THE LAST SIX MONTHS OUT OF WHICH
                        LIABILITY AROSE. THE FOREGOING LIMITATIONS OF LIABILITY SHALL NOT APPLY TO
                        LIABILITY OF TheUpsideSpace FOR (A) DEATH OR PERSONAL INJURY CAUSED BY A
                        MEMBER OF TheUpsideSpace’S NEGLIGENCE; OR FOR (B) ANY INJURY CAUSED BY
                        A MEMBER OF TheUpsideSpace’S FRAUD OR FRAUDULENT MISREPRESENTATION.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        Some jurisdictions do not allow the exclusion or limitation of incidental or consequential
                        damages, so the above limitation or exclusion may not apply to you. Some jurisdictions also
                        limit disclaimers or limitations of liability for personal injury from consumer products, so this
                        limitation may not apply to personal injury claims.
                    </Typography>
                    <Typography variant="subtitle1" className="poppins-medium terms-header">
                        20) Indemnification
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        To the fullest extent permitted by applicable law, you agree to indemnify, defend and hold
                        harmless TheUpsideSpace, and our respective past, present and future employees, officers,
                        directors, contractors, consultants, equity holders, suppliers, vendors, service providers, parent
                        companies, subsidiaries, affiliates, agents, representatives, predecessors, successors and
                        assigns (individually and collectively, the “TheUpsideSpace Parties”), from and against all
                        actual or alleged third party claims, damages, awards, judgments, losses, liabilities, obligations,
                        penalties, interest, fees, expenses (including, without limitation, attorneys’ fees and expenses)
                        and costs (including, without limitation, court costs, costs of settlement and costs of pursuing
                        indemnification and insurance), of every kind and nature whatsoever, whether known or
                        unknown, foreseen or unforeseen, matured or unmatured, or suspected or unsuspected, in law
                        or equity, whether in tort, contract or otherwise (collectively, “Claims”), including, but not limited
                        to, damages to property or personal injury, that are caused by, arise out of or are related to (a)
                        your use or misuse of the Site, Content or NFT(s), (b) any Feedback you provide, (c) your
                        violation of these Terms of Use, (d) your violation of the rights of a third party, including another
                        user or MetaMask, (e) any intellectual property disputes relating to your NFT Creations sold on
                        TheUpsideSpace platform, if you are a NFT Creator; and (f) your failure to pay any Withholding
                        Taxes or Sales Taxes in connection with your NFT transactions or to provide us with a properly
                        executed tax form described in Section 9. You agree to promptly notify TheUpsideSpace of
                        any third party Claims and cooperate with TheUpsideSpace Parties in defending such Claims.
                        You further agree that TheUpsideSpace Parties shall have control of the defense or settlement
                        of any third party Claims. THIS INDEMNITY IS IN ADDITION TO, AND NOT IN LIEU OF, ANY
                        OTHER INDEMNITIES SET FORTH IN A WRITTEN AGREEMENT BETWEEN YOU AND TheUpsideSpace.
                    </Typography>

                    <Typography variant="subtitle1" className="poppins-medium terms-header">
                        21) Governing Law
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        These Terms of Use, your use of TheUpsideSpace, your rights and obligations, and all actions
                        contemplated by, arising out of or related to these Terms of Use shall be governed by the laws
                        of the Republic of Singapore, as if these Terms of Use are a contract wholly entered into and
                        wholly performed within the Republic of Singapore.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        YOU UNDERSTAND AND AGREE THAT YOUR USE OF TheUpsideSpace AS
                        CONTEMPLATED BY THESE TERMS OF USE SHALL BE DEEMED TO HAVE OCCURRED
                        IN THE REPUBLIC OF SINGAPORE AND BE SUBJECT TO THE INTERNAL LAWS OF
                        SINGAPORE WITHOUT REGARD TO ITS CONFLICTS OF LAWS PROVISIONS.
                    </Typography>

                    <Typography variant="subtitle1" className="poppins-medium terms-header">
                        22)Dispute Resolution
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        Any dispute arising out of or in connection with this contract, including any question regarding its
                        existence, validity or termination, shall be referred to and finally resolved by arbitration
                        administered by the Singapore International Arbitration Centre (“SIAC”) in accordance with the
                        Arbitration Rules of the Singapore International Arbitration Centre (“SIAC Rules”) for the time
                        being in force, which rules are deemed to be incorporated by reference in this clause.                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        You agree that any such arbitration shall be settled solely and exclusively by binding arbitration
                        held by a single arbitrator in the Republic of Singapore administered by the SIAC and conducted
                        in English, rather than in court. The seat of the arbitration shall be Singapore. You agree that
                        the arbitrator shall be appointed by TheUpsideSpace.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        The arbitrator shall have exclusive authority to (1) determine the scope and enforceability of this
                        Arbitration Agreement; and (2) resolve any dispute related to the interpretation, applicability,
                        enforceability or formation of this Arbitration Agreement, including but not limited to any claim
                        that all or part of this Arbitration Agreement is void or voidable; (3) decide the rights and
                        liabilities, if any, of you and TheUpsideSpace; (4) grant motions dispositive of all or part of any
                        claim; (5) award monetary damages and grant any non-monetary remedy or relief available to a
                        party under applicable law, arbitration rules, and these Terms of Use. The arbitrator has the
                        same authority to award relief on an individual basis that a judge in a court of law would have.
                        The arbitrator shall issue a written award and statement of decision describing the essential
                        findings and conclusions on which the award is based, including the calculation of any damages
                        awarded. Such an award is final and binding upon you and us.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        You understand that by agreeing to this clause, you and TheUpsideSpace are each waiving
                        their right to trial by jury and to participate in a class action or class arbitration.
                    </Typography>
                    <Typography variant="subtitle1" className="poppins-medium terms-header">
                        23) Termination
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        You may discontinue your access to and use of TheUpsideSpace at any time.
                        We reserve the right, without notice and in our sole discretion, to terminate your license to
                        access or use the Site or Content, at any time and for any reason. You understand and hereby
                        agree that we shall have no liability or obligation to you in such an event.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        Upon any termination or expiration, whether by you or us, you may no longer have access to
                        information that you have posted on TheUpsideSpace or that is related to your account, and
                        you acknowledge that we will have no obligation to maintain any such information.
                    </Typography>

                    <Typography variant="subtitle1" className="poppins-medium terms-header">
                        24) Severability &amp; Entire Agreement
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        If any term, clause, or provision of these Terms of Use is held invalid or unenforceable, then that
                        term, clause, or provision shall be severable from these Terms of Use and will not affect the
                        validity or enforceability of any remaining part of that term, cause, or provision, or any other
                        terms, clause, or provision of these Terms of Use.
                    </Typography>
                    <Typography>
                        These Terms of Use comprise the entire agreement between you and TheUpsideSpace
                        relating to your access to and use of the Site and Content, and supersede any and all prior
                        discussions agreements, and understandings of any kind (including without limitation prior
                        versions of this User Agreement). Except as otherwise provided herein, these Terms of Use are
                        intended solely for the benefit of the parties and are not intended to confer third party
                        beneficiary rights upon any other person or entity.
                    </Typography>

                    <Typography variant="subtitle1" className="poppins-medium terms-header">
                        25) Survival
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        You agree and understand that all provisions of these Terms of Use shall survive the
                        termination or expiration of these Terms of Use.
                    </Typography>
                    <Typography variant="subtitle1" className="poppins-medium terms-header">
                        26) Contact Information
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        If you have any questions, would like to provide feedback, or would like more information about
                        TheUpsideSpace, please feel free to email us at <a href='mailto:info@theupsidespace.com'>info@theupsidespace.com</a>.
                        We may amend this policy from time to time by posting the updated policy on our Site. By
                        continuing to use our Site and our App after the changes come into effect means that you agree
                        to be bound by the revised policy.
                    </Typography>
                    <Typography variant="body1" className="my-2 terms-text">
                        Last updated: <b>24 November 2022</b>
                    </Typography>

                    <Typography variant="h4" className='end-of-terms'>END OF AGREEMENT</Typography>
                </Box>
            </Box>
        </Container>
    );
};

export default TermsPage;
