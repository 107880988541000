import update from 'immutability-helper';
import { ApplicationReduxActions } from './Action';

export interface Category {
    name: string;
    label: string;
    logo: string;
    description: string;
    order: number;
    id: string;
}

interface Currency {
    amount: string;
    currencyType: string;
}

export interface CurrencyConvertion {
    from: Currency;
    to: Currency;
}

export interface ApplicationDataState {
    acceptCrypto: boolean;
    categories: Category[];
    currencyConverstion: CurrencyConvertion;
}

export const defaultApplicationState: ApplicationDataState = {
    acceptCrypto: false,
    categories: [],
    currencyConverstion: {
        from: {
            amount: '0',
            currencyType: '',
        },
        to: {
            amount: '0',
            currencyType: '',
        },
    },
};

const applicationReducer = (
    state = defaultApplicationState,
    action: any
): ApplicationDataState => {
    const { payload } = action;
    switch (action.type) {
        case ApplicationReduxActions.CRYPTO_CURRENCY_STATUS:
            return update(state, {
                $merge: {
                    acceptCrypto: payload,
                },
            });
        case ApplicationReduxActions.SET_CATEGORY_LIST:
            return update(state, {
                $merge: {
                    categories: payload,
                },
            });
        case ApplicationReduxActions.SET_CURRENCY_CONVERSION:
            return update(state, {
                $merge: {
                    currencyConverstion: payload,
                },
            });
        default:
            return state;
    }
};

export default applicationReducer;
