import { useState, useEffect, useCallback, ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import {
    Typography,
    Box,
    Grid,
    TextField,
    InputAdornment,
    CircularProgress,
    FormHelperText,
    Button,
} from '@material-ui/core';
import debounce from 'lodash.debounce';
import {
    AppPrimaryButton,
    AppThemeRadioInput,
} from '../../../shared/components/AppComponents';
import { Utils } from '../../../shared/helpers';
import { BuySellService, ProfileService } from '../../../services';

export interface Currency {
    amount: string;
    currencyType: string;
}

export interface CurrencyConvertion {
    from: Currency;
    to: Currency;
}

export interface Balance {
    balance: string;
    token: string;
}

export interface Wallet {
    walletName: string;
    address: string;
    balances: Balance[];
}

export interface FiatWallet {
    address: string;
    freeBalance: number;
    lockedBalance: number;
    totalBalance: number;
    currencyType: string;
}

const WithDrawAmount = (props: any): ReactElement => {
    const { withDrawType, profileInfo, onWithDrawCancel, onWithDrawSuccess, actionOpenSetting } =
        props;
    const [cryptoWallets, setCryptoWallets] = useState<Wallet[]>([]);
    const [cryptoWallet, setCryptoWallet] = useState<Wallet>({} as Wallet);
    const [fiatWallet, setFiatWallet] = useState<FiatWallet>({} as FiatWallet);
    const [selectedWithDrawAccount, setSelectedWithDrawAccount] = useState('');
    const [loadingWallet, setLoadingWallet] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [loadingConversion, setLoadingConversion] = useState(false);
    const [withDrawAmount, setWithdrawAmount] = useState('');
    const [withDrawAccounts, setWithDrawAccounts] = useState([]);
    const [withDrawError, setWithDrawError] = useState('');
    const [currencyConverstion, setCurrencyConverstion] =
        useState<CurrencyConvertion>({
            from: {
                amount: '',
                currencyType: '',
            },
            to: {
                amount: '',
                currencyType: '',
            },
        });
    const minimumWithDrawAmount = 50;

    // Instance Methods
    const canWithDraw = (): any =>
        !loadingConversion &&
        withDrawError === '' &&
        selectedWithDrawAccount !== '' &&
        withDrawAmount !== '';

    const getFanticoWallet = (): any => {
        const wallet = cryptoWallets.find(
            (walletType) => walletType.walletName === 'Fantico Wallet'
        );
        return wallet;
    };

    const checkCryptoBalanceToWithDraw = (): any => {
        const walletBalance = cryptoWallet.balances[0].balance;
        if (currencyConverstion.to.amount > walletBalance) {
            setWithDrawError(
                "You don't have enough crypto balance to withdraw"
            );
        }
    };

    // API Calls
    const loadWalletBalance = (): any => {
        setLoadingWallet(true);
        ProfileService.walletBalance()
            .then((response) => {
                const wallet = response.data.data;
                setCryptoWallets(wallet.cryptoWallet);
                setFiatWallet(wallet.fiatWallet);
                setLoadingWallet(false);
            })
            .catch((error) => {
                Utils.handleErrorResponse(error);
                setLoadingWallet(false);
            });
    };

    const loadFiatAccounts = (): void => {
        ProfileService.getAccountDetails()
            .then((response: any) => {
                const accounts = response.data.data.bankDetails;
                setWithDrawAccounts(accounts);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
            });
    };

    const loadCryptoAccounts = (): void => {
        ProfileService.getCrpytoAccountDetails()
            .then((response: any) => {
                const accounts = response.data.data.addressDetails;
                setWithDrawAccounts(accounts);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
            });
    };

    const convertCurrency = (amount: any, from: string): any => {
        setLoadingConversion(true);
        BuySellService.convertPrice(amount, from, 'ETH')
            .then((response) => {
                const Response = response.data.data;
                setLoadingConversion(false);
                setCurrencyConverstion(Response);
            })
            .catch(() => {
                setLoadingConversion(false);
            });
    };

    const fiatDebounced = useCallback(
        debounce((amount: any) => {
            convertCurrency(amount, fiatWallet.currencyType);
        }, 500),
        [fiatWallet]
    );

    const processWithDrawRequest = (): any => {
        setSubmitting(true);
        const requestObj = {
            amount:
                withDrawType === 'fiat'
                    ? Number(withDrawAmount)
                    : Number(currencyConverstion.to.amount),
            accountId: selectedWithDrawAccount,
            type: withDrawType,
        };
        ProfileService.withdrawAmount(requestObj)
            .then((response: any) => {
                Utils.handleSuccessResponse(response);
                setSubmitting(false);
                onWithDrawSuccess();
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
                setSubmitting(false);
            });
    };

    // Hooks
    useEffect(() => {
        loadWalletBalance();
        ProfileService.getProfileDetailsAndStore();
        if (withDrawType === 'fiat') {
            loadFiatAccounts();
        } else {
            loadCryptoAccounts();
        }
    }, [withDrawType]);

    useEffect(() => {
        const wallet = getFanticoWallet();
        setCryptoWallet(wallet!);
    }, [cryptoWallets]);

    useEffect(() => {
        if (currencyConverstion.to.amount !== '') {
            checkCryptoBalanceToWithDraw();
        }
    }, [currencyConverstion]);

    // UI Actions
    const actionWithDraw = (): void => {
        processWithDrawRequest();
    };

    const onAmountChange = (event: any): any => {
        const amountStr = event.target.value;
        const amount = parseFloat(amountStr);
        setWithdrawAmount(amountStr);
        setWithDrawError('');
        if (withDrawType === 'fiat') {
            // eslint-disable-next-line no-restricted-globals
            if (isNaN(amount) || amountStr.trim() === '') {
                setWithDrawError('Please enter valid amount');
            } else if (amount > fiatWallet.freeBalance) {
                setWithDrawError(
                    `Please enter amount less than or equal to ${fiatWallet.freeBalance}`
                );
            } else if (amount < minimumWithDrawAmount) {
                setWithDrawError(
                    `Minimum withdraw amount is ${minimumWithDrawAmount} ${fiatWallet.currencyType}`
                );
            }
            // eslint-disable-next-line no-restricted-globals
        } else if (isNaN(amount) || amountStr.trim() === '') {
            setWithDrawError('Please enter valid amount');
        } else if (amount < minimumWithDrawAmount) {
            setWithDrawError(
                `Minimum withdraw amount is ${minimumWithDrawAmount}${fiatWallet.currencyType}`
            );
        } else {
            setLoadingConversion(true);
            fiatDebounced(amount);
        }
    };

    // UI Elements
    const getAccountListBasedonAccountType = (
        accountDetail: any
    ): ReactElement => {
        switch (withDrawType) {
            case 'crypto':
                return (
                    <Box className="accountlist-container">
                        <AppThemeRadioInput
                            onChange={(value: string) => {
                                setSelectedWithDrawAccount(value);
                            }}
                            selectedValue={selectedWithDrawAccount}
                            value={accountDetail.id}
                            label={
                                <Typography>
                                    <span className="text-gray">Address: </span>
                                    {Utils.formatedAddress(
                                        accountDetail.address
                                    )}
                                </Typography>
                            }
                        />
                    </Box>
                );
            default:
                return (
                    <Box className="accountlist-container">
                        <AppThemeRadioInput
                            selectedValue={selectedWithDrawAccount}
                            value={accountDetail.id}
                            label={
                                <Box>
                                    <Typography>
                                        {`Account Holder Name: ${accountDetail.name}`}
                                    </Typography>
                                    <Typography>
                                        {`Bank Name: ${accountDetail.bankName}`}
                                    </Typography>
                                    <Typography>
                                        {`Account Number: ${accountDetail.accountNumber}`}
                                    </Typography>
                                </Box>
                            }
                            onChange={(value: string) =>
                                setSelectedWithDrawAccount(value)
                            }
                        />
                    </Box>
                );
        }
    };

    return (
        <>
            {loadingWallet && (
                <Box className="empty-content">
                    <CircularProgress />
                </Box>
            )}
            {!loadingWallet && (
                <>
                    {withDrawAccounts.length !== 0 &&
                    // profileInfo.twoFactorEnabled === true &&
                    profileInfo.kycVerified === true && 
                    profileInfo.hasAddress === true ? (
                        <>
                            <Typography variant="body2">
                                Available Amount
                            </Typography>
                            <Typography variant="h2">
                                {withDrawType === 'fiat' && (
                                    <>
                                        {fiatWallet.freeBalance}{' '}
                                        {fiatWallet.currencyType}
                                    </>
                                )}
                                {withDrawType === 'crypto' && cryptoWallet && (
                                    <>
                                        {cryptoWallet.balances[0].balance}{' '}
                                        {cryptoWallet.balances[0].token}
                                    </>
                                )}
                            </Typography>

                            {withDrawType === 'fiat' && (
                                <Box className="amount-wrapper">
                                    <TextField
                                        fullWidth
                                        label="Amount"
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    <Typography>
                                                        {
                                                            fiatWallet.currencyType
                                                        }
                                                    </Typography>
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={(e) => onAmountChange(e)}
                                    />
                                </Box>
                            )}

                            {withDrawType === 'crypto' && (
                                <Grid
                                    container
                                    className="amount-wrapper mt-4"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Grid item className="mt-4">
                                        <TextField
                                            fullWidth
                                            label="Fiat Amount"
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Typography>
                                                            {
                                                                fiatWallet.currencyType
                                                            }
                                                        </Typography>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={(e) => onAmountChange(e)}
                                        />
                                    </Grid>
                                    {loadingConversion && (
                                        <Grid item className="mt-4">
                                            <CircularProgress />
                                        </Grid>
                                    )}
                                    <Grid item className="mt-4">
                                        <TextField
                                            fullWidth
                                            value={
                                                currencyConverstion.to.amount
                                            }
                                            label="Crypto Amount"
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="start">
                                                        <Typography>
                                                            ETH
                                                        </Typography>
                                                    </InputAdornment>
                                                ),
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            )}

                            <FormHelperText className="Mui-error">
                                {withDrawError}
                            </FormHelperText>

                            {withDrawAccounts.map(
                                (withDrawAccount: any, index: number) => (
                                    <Box mt={6} key={`withdraw_${index}`}>
                                        {getAccountListBasedonAccountType(
                                            withDrawAccount
                                        )}
                                    </Box>
                                )
                            )}

                            <Grid
                                container
                                justifyContent="flex-end"
                                className="m-2"
                            >
                                <AppPrimaryButton
                                    title="WITHDRAW"
                                    isLoading={submitting}
                                    loadingText="WITHDRAWING"
                                    disabled={!canWithDraw()}
                                    onClick={actionWithDraw}
                                />
                            </Grid>
                        </>
                    ) : (
                        <Box>
                            <Typography
                                className="text-secondary matter-fontfamily"
                                variant="body1"
                            >
                                <br />
                                <Typography className='withdraw-popup-head'>
                                To withdraw the available balance in your wallet, 
                                please complete the following under “Account Settings”:
                                </Typography>
                                <br />
                                {/* 1. Enable 2-Factor Authentication <br /> */}
                                1. Enter your KYC information <br />
                                2. Enter your Address <br />
                                3. Add your  
                                {withDrawType === 'fiat'
                                    ? ' Bank Account details'
                                    : ' Crypto Account details'} 
                            </Typography>

                            <Button
                                component={NavLink}
                                to="#"
                                onClick={() => { onWithDrawCancel(); actionOpenSetting(14)} }
                                className="settings end-content matter-fontfamily view-popup-btn"
                            >
                                View Settings
                            </Button>
                        </Box>
                    )}
                </>
            )}
        </>
    );
};

export default WithDrawAmount;
