import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import { useEffect, useState, ReactElement } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import CollectionsIcon from '@material-ui/icons/Collections';
import { DefaultPagination } from '../../../services';
import { CollectionService } from '../../../services/Collection';
import { EmptyPlaceholder } from '../../../shared/components';
import { GradientVariants } from '../../../shared/components/GradientChip';
import { Utils } from '../../../shared/helpers';
import { Celebrity } from '../../../shared/models/CelebrityModel';
import { AppState } from '../../../state/RootReducer';
import NFTItem, { NFTInfo } from '../../home/components/NFTItem';
import { MarketFilterAction } from '../../../state/filter/Action';
import CuratingLoading from '../../../shared/components/curatingLoading';

export interface NFTDetail {
    nftImage: string;
    avatar: string;
    variant: GradientVariants;
    showStock: boolean;
    popularity: string;
}
export interface NFTLISTPROPS {
    marketPlaceFilter?: any;
    trending?: boolean;
    arts?: any;
    marketFilterList?: any;
    setMinMax?: any;
    loaderUi?: any;
    isAPICalling?: any;
}

const NFTList = (props: NFTLISTPROPS): any => {
    // Instance Vairable
    const { marketPlaceFilter, marketFilterList, setMinMax, loaderUi, isAPICalling } = props;
    const [items, setItems] = useState<NFTInfo[]>([]);
    const [pagination, setPagination] = useState({ ...DefaultPagination(12) });
    const [hasMoreItems, setHasMoreItems] = useState(false);
    const [loader, setLoader] = useState(true);
    const [loadLimit ,setLoadLimit] = useState(Number(sessionStorage.getItem('items'))||30)

    // Instance Methods
    const buildCelebrityIds = (): string => {
        const ids: string[] = [];
        marketPlaceFilter.celebrities.forEach((celebrity: Celebrity) => {
            ids.push(celebrity.id);
        });
        return ids.toString();
    };

    const windowObject:any = window;
    // API Calls
    const loadItems = (): void => {

        if (pagination.lastOffset !== pagination.offset) {
            pagination.lastOffset = pagination.offset;
            let allArtsApi = true
            if (marketPlaceFilter.buy === true || marketPlaceFilter.notLaunched === true || marketPlaceFilter.minPrice !== marketFilterList.min ||  marketPlaceFilter.maxPrice !== marketFilterList.max  ) {
                allArtsApi = false
            }
            CollectionService.getMarketPlaceItems(
                loadLimit,
                pagination.offset,
                'recent',
                marketPlaceFilter.query,
                '',
                marketPlaceFilter.minPrice,
                marketPlaceFilter.maxPrice,
                marketPlaceFilter.buy,
                marketPlaceFilter.newItem,
                marketPlaceFilter.onAuction,
                marketPlaceFilter.hasOffer,
                marketPlaceFilter.notLaunched,
                buildCelebrityIds(),
                marketPlaceFilter.blockChain.length === 2 ||
                    marketPlaceFilter.blockChain.length === 0
                    ? 'all'
                    : marketPlaceFilter.blockChain[0],
                marketPlaceFilter.region,
                marketPlaceFilter.exhibition,
                marketPlaceFilter.physicalNFT,
                marketPlaceFilter.nsfw,
                marketPlaceFilter.editions,
                marketPlaceFilter.curatorsPick,
                marketPlaceFilter.assetType?marketPlaceFilter.assetType:'all',
                allArtsApi,
            )
                .then((response: any) => {
                    const newItems = response.data.data.items;
                    const paginationResponse = response.data.data.pagination;
                    setItems(
                        pagination.offset === 1
                            ? newItems
                            : items.concat(newItems)
                    );
                    setHasMoreItems(Utils.hasMoreRecords(paginationResponse));

                    setPagination({
                        ...paginationResponse,
                        lastOffset: pagination.lastOffset,
                        offset: pagination.offset + 1,
                    });
                    setLoader(false);

                })
                .catch((error: any) => {
                    setLoader(false);
                    Utils.handleErrorResponse({ error });
                });
        }
    };

    // Business Logic
    const reloadItems = (): void => {
        pagination.offset = 1;
        pagination.lastOffset = 0;
        setItems([]);
        loadItems();
    };

    const loadMoreItems = (): void => {
        loadItems();
    };

    // Hooks
    useEffect(() => {
        reloadItems();
       

    }, [marketPlaceFilter]);

    const setSession = ():any => {
        sessionStorage.setItem('scrollPosition', windowObject.pageYOffset);
        sessionStorage.setItem('items', String(items.length))
    }


    useEffect(() => {
        if(items.length){
            const scrollPosition = sessionStorage.getItem('scrollPosition');
            const itemsNumber = Number(sessionStorage.getItem('items'));
            if (scrollPosition && items.length === itemsNumber) {
              windowObject.scrollTo(0, parseInt(scrollPosition, 10));
              sessionStorage.removeItem('scrollPosition');
              sessionStorage.removeItem('items');
            }else if(scrollPosition){
                loadMoreItems();
            }
        }  
      
    })
    // UI Elements
    return (
        <Box>
            {/* {(loader || isAPICalling) ? loaderUi : */}
             <InfiniteScroll
                threshold={50}
                pageStart={1}
                loadMore={loadMoreItems}
                hasMore={hasMoreItems}
            >
                {items.length !== 0 && (
                    <Grid container justifyContent='flex-start'>
                        {items.map((nftItem: any) => (
                            <Grid
                                className='nft-market'
                                key={nftItem.id}
                                item
                                lg={4}
                                md={4}
                                sm={4}
                                xs={12}
                            >
                                <NFTItem
                                    {...{ ...nftItem, className: 'market-nft' }}
                                onRouteChange ={setSession}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </InfiniteScroll>
            {/* } */}

            {(loader || isAPICalling) ? loaderUi : <></>}
            { !isAPICalling && items.length === 0 && (
                <EmptyPlaceholder
                    icon={<img src="/icons/NoData.png" />}
                />
            )}
        </Box>
    );
};

const mapStateToProps = (state: AppState): any => ({
    marketPlaceFilter: state.marketPlaceFilter,
    marketFilterList: state.MarketFilterList,
    isAPICalling: state.authState.isAPICalling
});

const mapDispatchToProps = (dispatch: any): any => ({
    setMinMax: (data: any) => dispatch(MarketFilterAction.setMinMax(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NFTList);
