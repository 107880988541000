export enum AuthReduxActions {
    AUTH_SET_LOGIN_STATUS = 'AUTH_SET_LOGIN_STATUS',
    SET_BETA_LOGIN_STATUS = 'SET_BETA_LOGIN_STATUS',
    LOGOUT = 'LOGOUT',
    GLOBAL_LOADING = 'GLOBAL_LOADING'
}

export const setLoginStatus = (payload: any): any => ({
    type: AuthReduxActions.AUTH_SET_LOGIN_STATUS,
    payload,
});

export const setBetaUserStatus = (payload: any): any => ({
    type: AuthReduxActions.SET_BETA_LOGIN_STATUS,
    payload,
});

export const doLogout = (payload: any): any => ({
    type: AuthReduxActions.LOGOUT,
    payload,
});

export const isAPICalling = (payload:any):any => ({
    type: AuthReduxActions.GLOBAL_LOADING,
    payload,
})

export const AuthActions = {
    setLoginStatus,
    setBetaUserStatus,
    doLogout,
    isAPICalling
};


  