import { ReactElement, useEffect, useState, useRef } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { Box, Typography, Grid, Divider, Button, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { Formik } from 'formik';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { ForgotPasswordSchema } from '../../../validators/AuthenticationSchema';
import {
    AppFormikTextField, AppPrimaryButton,
} from '../../../shared/components/AppComponents';
import { Utils } from '../../../shared/helpers';
// import { AuthenticationService } from '../../../services';
import { AuthenticationService, CaptchaSetting } from '../../../services';

const ForgotPasswordForm = (): ReactElement => {
    const navigate = useNavigate();
    const forgotPasswordForm = {
        email: '',
    };
    const [captcha, setCaptcha] = useState('');
    const captchaRef = useRef(null);
    const [captchaSetting, setCaptchaSetting] = useState<any>()
    // API Calls
    // const getCaptchaSetting = (): void => {
    //     CaptchaSetting.getCaptchaSetting('forgot')
    //         .then((response: any) => {
    //             if (response.data.data)
    //                 setCaptchaSetting(response.data.data)
    //         })
    //         .catch((error: any) => {
    //             Utils.handleErrorResponse({ error });
    //         })
    // }
    useEffect(() => {
        if (process.env.REACT_APP_DEV_MODE === 'true') {
            // getCaptchaSetting()
        } else {
            // setCaptchaSetting({status: true})
        }
    }, [])
    const forgotpassword = (formValues: any, callback: any): any => {
        const data: any = {...formValues, captchaToken: captcha}
        AuthenticationService.forgotPassword(data)
            .then((response: any) => {
                Utils.handleSuccessResponse(response);
                callback(true);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
                callback(false);
            });
    };

    // UI Actions
    const actionForgotPassword = (formValues: any, formik: any): any => {
        forgotpassword({ ...formValues }, (status: boolean): any => {
            formik.setSubmitting(true);
            if (status) {
                navigate('/login');
                formik.setSubmitting(false);
            } else {
                setCaptcha('');
                if (captchaRef !== null) {
                    const hcaptcha: any = captchaRef.current;
                    if (hcaptcha instanceof HCaptcha) {
                        hcaptcha.resetCaptcha();
                    }
                }
                formik.setSubmitting(false);
            }
        });
    };

    // Captcha events
    const onVerifyCaptcha = (token: string): any => {
        if (token) {
            setCaptcha(token);
        } else {
            setCaptcha('');
        }
    };

    const onExpireCaptcha = (): any => {
        setCaptcha('');
    };

    // UI Elements
    return (
        <Box className="auth-bg">
            <Box className="auth-content auth-content-verify forgot-auth-wrapper">
                <Formik
                    initialValues={forgotPasswordForm}
                    enableReinitialize
                    validationSchema={ForgotPasswordSchema}
                    validateOnMount
                    onSubmit={(values: any, formik: any): any => {
                        actionForgotPassword(values, formik);
                    }}
                >
                    {({ handleSubmit,isSubmitting,isValid  }): any => (
                        <Grid item xs={12}>
                            <form autoComplete="off" onSubmit={handleSubmit}>
                                <Box className="forgot-title-content ">
                                        <Typography
                                            className="auth-main-title forgot-h3"
                                            variant="h3"
                                        >
                                            Forgot password
                                        </Typography>
                                        <Divider className='auth-divider' />
                                    </Box>

                                {/* <Box>
                                    <Typography
                                        variant="body1"
                                        className="my-4"
                                    >
                                        Enter your registered email ID to reset
                                        your password
                                    </Typography>
                                </Box> */}

                                <Box className='forgot-input-name'>
                                    {/* <InputLabel required>Email</InputLabel> */}
                                    <AppFormikTextField
                                        name="email"
                                        type="text"
                                        size="medium"
                                        placeholder="Email ID*"
                                        // className="forgot-email"
                                    />
                                </Box>
                                
                                    <Box pt={2} className="captcha-sm">
                                        <HCaptcha
                                            sitekey="2b1dc988-7604-4a33-ba53-d705132c81c5"
                                            onVerify={onVerifyCaptcha}
                                            onExpire={onExpireCaptcha}
                                            ref={captchaRef}
                                        />
                                    </Box>
                                 
                                    {/* <Box className='human-box'>
                                        <FormGroup >
                                            <FormControlLabel control={<Checkbox  className="human-box-checkbox"/>} label="I am a human" className='human-check'/>
                                        </FormGroup>
                                    </Box> */}

                                <Box className='forgot-pwd-button'>
                                <AppPrimaryButton
                                    title="SUBMIT"
                                    className='backBtn auth-label'
                                    isLoading={isSubmitting}
                                    loadingText="PROCESSING"
                                    disabled={
                                        isSubmitting || captcha ==='' ||
                                        // (captchaSetting !== undefined && captchaSetting.status && captcha === '') ||
                                        !isValid
                                    }
                                    onClick={handleSubmit}
                                />

                                <Grid item className=" backbtn-content" >
                                    <Button
                                        className="flex backBtn auth-label"
                                        component={NavLink}
                                        to="/login"
                                        style={{backgroundColor:"#8522FA", color:"#FFF"}}
                                       >Back</Button>                                        
                                </Grid>
                                </Box>
                            </form>
                        </Grid>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

export default ForgotPasswordForm;
