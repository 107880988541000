import * as Yup from 'yup';
import {
    name,
    accountHolderName,
    newPassword,
    currentPassword,
    confirmPassword,
    bio,
    bankName,
    accountNumber,
    confirmAccountNumber,
    IFSC,
    additionalDetails,
    swiftCode,
    amount,
    accountName,
    accountAddress,
    importAccountAddress,
    tokenId,
    importQuantity,
    line1,
    line2,
    city,
    state,
    country,
    postcode,
    contactNumber,
    userName,
    IBAN,
    email,
    blockChain,
} from './Schema';

export const EditProfileSchema = Yup.object().shape({
    name,
    bio,
});

export const ChangePasswordSchema = Yup.object().shape({
    currentPassword,
    newPassword,
    confirmPassword,
});

export const AddBankAccountSchema = Yup.object().shape({
    name: accountHolderName,
    bankName,
    IFSC,
    accountNumber,
    confirmAccountNumber,
    swiftCode,
    additionalDetails,
    IBAN,
});

export const EditBankAccountSchema = Yup.object().shape({
    name: accountHolderName,
    IFSC,
    accountNumber,
    IBAN,
    swiftCode,
    additionalDetails,
});

export const WidthDrawSchema = Yup.object().shape({
    amount,
});

export const AddCryptoAccountSchema = Yup.object().shape({
    name: accountName,
    address: accountAddress,
});

export const ImportNFTSchema = Yup.object().shape({
    tokenId,
    fromAddress: importAccountAddress,
    quantity: importQuantity,
    blockChain,
});

export const ExportCryptotSchema = Yup.object().shape({
    quantity: Yup.string()
        .required('Quantity is required')
        .matches(/^[0-9]{0,9}$/, 'Enter a valid quantity')
        .max(10, 'Enter a valid quantity in max 10 digits'),
    accountAddress,
});

export const AddAddressSchema = Yup.object().shape({
    name,
    line1,
    line2,
    city,
    state,
    country,
    postcode,
    contactNumber,
});

export const UserNameSchema = Yup.object().shape({
    userName,
});

export const SecondaryEmailSchema = Yup.object().shape({
    email,
});
