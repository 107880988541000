import { Helmet } from "react-helmet";

export default function OpenMeta(title:any,description:any ,type:any ,image:string ,url:any):any {
    return (
      <Helmet>
        <meta property="og:title" content={title} />
        <meta property="og:type" content={type} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={url} />
        <meta property="og:description" content={description} />
      </Helmet>
    )
  }

  