import { useState, ReactElement, useEffect } from 'react';
import {
    Grid,
    Typography,
    Dialog,
    DialogContent,
    DialogTitle,
    Box,
    IconButton,
    Divider,
} from '@material-ui/core';
import { Formik } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import { AddAddressSchema } from '../../../validators/ProfileSchema';
import { Utils, Countries } from '../../../shared/helpers';
import {
    AppFormikTextField,
    AppPrimaryButton,
    AppSelectCountry,
    AppTeritaryButton,
    // AppOutlinedSelect,
} from '../../../shared/components/AppComponents';
import { ProfileService } from '../../../services';

export interface AddressState {
    name: string;
    line1: string;
    line2: string;
    city: string;
    state: string;
    country: string;
    contactNumber: string;
    postcode: string;
    id: string;
}

const AddressManagementForm = (props: any): ReactElement => {
    const { 
        showAddressFormDialog,
        modalFormtype,
        addressList,
        addressInfoToEdit,
        actionCancel 
    } = props;
    const createAddressForm = {
        name: '',
        line1: '',
        line2: '',
        city: '',
        state: '',
        country: '',
        contactNumber: '',
        postcode: '',
    };
    const [addressForm, setAddressForm] = useState(createAddressForm);
    const [addressId, setAddressId] = useState('');

    const bindAddressInfoToEdit = (): any => {
        const {
            name,
            line1,
            line2,
            city,
            state,
            country,
            contactNumber,
            postcode,
            id,
        } = addressInfoToEdit;
        setAddressId(id)
        setAddressForm({
            name,
            line1,
            line2,
            city,
            state,
            country,
            contactNumber,
            postcode,
          
        })
    }

    useEffect(() => {
        if (addressInfoToEdit) {
            bindAddressInfoToEdit()
        } else {
            setAddressForm(addressForm)
        }
    }, [addressInfoToEdit])

    // Child Methods
    const onCloseModalForm = (): any => {
        actionCancel();
    };

    // API Calls
    const create = (formValues: any, callback: any): void => {
        ProfileService.addAddress(formValues)
            .then((response: any) => {
                Utils.handleSuccessResponse(response);
                callback(true);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
                callback(false);
            });
    };

    const edit = (formValues: any, callback: any): any => {
        const {
            name,
            line1,
            line2,
            city,
            state,
            country,
            contactNumber,
            postcode,
        }: any = formValues
        const requestObj = {
            name,
            line1,
            line2,
            city,
            state,
            country,
            contactNumber,
            postcode,
            addressId
        }
        ProfileService.editAddress(requestObj)
            .then((response: any) => {
                Utils.handleSuccessResponse(response)
                callback(true)
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({error})
                callback(false)
            })
    }

    // UI Actions
    const actionAddAddress = (formValues: any, formik: any): any => {
        formik.setSubmitting(true);
        if (addressList.length !== 0 && modalFormtype === 'Edit') {
            edit(formValues, (status: boolean) => {
                if (status) {
                    onCloseModalForm();
                    formik.ressetForm()
                }
                formik.setSubmitting(false)
            })
        } else {
            create(formValues, (status: boolean) => {
                if (status) {
                    onCloseModalForm();
                    formik.resetForm();
                }
                formik.setSubmitting(false);
            });
        }
    };


    // UI Elements
    return (
        <>
            <Dialog
                maxWidth="sm"
                fullWidth
                disableEscapeKeyDown
                open={showAddressFormDialog}
            >
                <DialogTitle>
                    <Box className="title-section">
                        <Typography className='tobias-fontfamily' variant="h4">{modalFormtype === 'ADD' ? 'Add' : 'Edit'} Address</Typography>
                        <IconButton onClick={onCloseModalForm}>
                            <CloseIcon color="primary" />
                        </IconButton>
                    </Box>
                    <Divider />
                </DialogTitle>

                <DialogContent>
                    <Formik
                        enableReinitialize
                        initialValues={addressForm}
                        validationSchema={AddAddressSchema}
                        validateOnMount
                        onSubmit={(values, formik) => {
                            actionAddAddress(values, formik);
                        }}
                    >
                        {({ handleSubmit, isSubmitting, isValid }) => (
                            <form autoComplete="off" className='address-form'>
                                <AppFormikTextField
                                    label="Name *"
                                    placeholder="Enter Name"
                                    name="name"
                                    type="text"
                                    outerLabel
                                    size="medium"
                                />
                                <AppFormikTextField
                                    label="Address - Line 1 *"
                                    placeholder="Enter address line 1"
                                    name="line1"
                                    outerLabel
                                    size="medium"
                                />
                                <AppFormikTextField
                                    label="Address - Line 2 *"
                                    placeholder="Enter address line 2"
                                    name="line2"
                                    type="text"
                                    outerLabel
                                    size="medium"
                                />
                                <AppFormikTextField
                                    placeholder="Enter City"
                                    label="City *"
                                    name="city"
                                    type="text"
                                    outerLabel
                                    size="medium"
                                />
                                <AppFormikTextField
                                    label="State *"
                                    placeholder="Enter State"
                                    name="state"
                                    type="text"
                                    outerLabel
                                    size="medium"
                                />
                                <AppSelectCountry
                                    label="Country *"
                                    placeholder="Select Country"
                                    name="country"
                                    items={Countries}
                                    outerLabel
                                />
                                <AppFormikTextField
                                    label="Phone Number *"
                                    name="contactNumber"
                                    type="text"
                                    placeholder="Enter Phone Number"
                                    outerLabel
                                    size="medium"
                                />
                                <AppFormikTextField
                                    placeholder="Enter Post Code"
                                    label="Post Code *"
                                    name="postcode"
                                    type="text"
                                    outerLabel
                                    size="medium"
                                />
                                <Box py={2}>
                                    <Grid container className="end-content">
                                        <Grid>
                                            <Box mr={2}>
                                                <AppTeritaryButton
                                                    title="CANCEL"
                                                    className='address-btn'
                                                    onClick={() => {
                                                        actionCancel();
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid>
                                            <AppPrimaryButton
                                                title="ADD"
                                                isLoading={isSubmitting}
                                                loadingText="ADDING"
                                                disabled={
                                                    isSubmitting || !isValid
                                                }
                                                onClick={handleSubmit}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default AddressManagementForm;
