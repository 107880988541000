import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Typography,
    Grid,
    Container,
    Box,
    Card,
    CardContent,
    Divider,
} from '@material-ui/core';
import { LocationOn, Email } from '@material-ui/icons';
import { Formik } from 'formik';
import HeadSection from './HeadSection';
import { ContactUsSchema } from '../../validators/ContactSchema';
import {
    AppFormikTextArea,
    AppFormikTextField,
    AppPrimaryButton,
} from '../../shared/components/AppComponents';
import { Utils } from '../../shared/helpers';
import { ContactService } from '../../services';

const ContactPage = (): ReactElement => {
    const navigate = useNavigate();
    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        contactNo: '',
        message: '',
    };
    const actionContactUs = (formValues: any, formik: any): void => {
        const data = { ...formValues };
        ContactService.contactUs(data)
            .then((response: any) => {
                Utils.handleSuccessResponse(response);
                navigate('/');
                formik.setSubmitting(false);
            })
            .catch((error: any) => {
                formik.setSubmitting(false);
                Utils.handleErrorResponse({
                    error,
                });
            });
    };
    return (
        <Container>
            <Box id="contactPage">
                <HeadSection
                    title=""
                    subtitle="Contact Us"
                    content="We’d love to solve your queries and answer all 
                    of your questions. Simply fill out this form 
                    and Get in touch with us!"
                />

                <Grid container spacing={3} className="py-14 contact-us-wrapper" >
                    <Grid xs={12} sm={4} item className="contact-container">
                        <Card className="customCard">
                            <CardContent className="flex-column contact-left-box">
                                <Box className="flex align-center contact-us-left-text">
                                    <LocationOn color="primary" />
                                    <Typography
                                        variant="body1"
                                        className="ml-4 contact-address-text"
                                    >
                                        Singapore 
                                    </Typography>
                                </Box>
                                <Divider className="my-4" />
                                <Box className="flex mt-1 align-center contact-us-left-text">
                                    <Email color="primary" />
                                    <Typography
                                        variant="body1"
                                        className="ml-4 contact-address-text"
                                    >
                                        info@theupsidespace.com
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid xs={12} sm={8} item>
                        <Card>
                            <CardContent className="form-card">
                                <Formik
                                    initialValues={initialValues}
                                    enableReinitialize
                                    validateOnMount
                                    validationSchema={ContactUsSchema}
                                    onSubmit={(values, formik) => {
                                        actionContactUs(values, formik);
                                    }}
                                >
                                    {({
                                        handleSubmit,
                                        isSubmitting,
                                        isValid,
                                    }) => (
                                        <form
                                            // className="myCareerForm"
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <Grid container spacing={3} className='contactus-form'>
                                                <Grid xs={12} sm={6} item className='contact-field'>
                                                    <AppFormikTextField
                                                        name="firstName"
                                                        size="large"
                                                        label="First name"
                                                        outerLabel
                                                        className=""
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={6} item className='contact-field'>
                                                    <AppFormikTextField
                                                        name="lastName"
                                                        size="large"
                                                        label="Last name"
                                                        outerLabel
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={6} item className='contact-field'>
                                                    <AppFormikTextField
                                                        name="email"
                                                        size="large"
                                                        label="Email ID"
                                                        outerLabel
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={6} item className='contact-field'>
                                                    <AppFormikTextField
                                                        name="contactNo"
                                                        size="large"
                                                        label="Contact number"
                                                        outerLabel
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} item className='contact-field contact-field-textarea'>
                                                    <AppFormikTextArea
                                                        name="message"
                                                        rows={4}
                                                        label="Message"
                                                        outerLabel
                                                    />
                                                </Grid>
                                                <Grid container spacing={3}>
                                                    <Grid xs={12} sm={3} item className='contactus-submit-btn'>
                                                        <Box className="my-2">
                                                            <AppPrimaryButton
                                                                fullWidth
                                                                title="Submit"
                                                                className='contact-submit-btn'
                                                                isLoading={
                                                                    isSubmitting
                                                                }
                                                                loadingText="SUBMITTING"
                                                                disabled={
                                                                    isSubmitting ||
                                                                    !isValid
                                                                }
                                                                onClick={
                                                                    handleSubmit
                                                                }
                                                            />
                                                        </Box>{' '}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    )}
                                </Formik>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default ContactPage;
