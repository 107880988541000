import { ReactElement } from 'react';
import { CreateWallet } from './components';
import { HeadTag } from '../../shared/components';

const CreateWalletPage = (): ReactElement => (
    <>
        <HeadTag title="Create Wallet" />
        <CreateWallet />
    </>
);

export default CreateWalletPage;
