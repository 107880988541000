import { ReactElement } from 'react';
import { Box } from '@material-ui/core';
import { Outlet } from 'react-router-dom';
import { HeadTag } from '../../shared/components';

const NFTPageWrapper = (): ReactElement => {
    // Instance Variables
    const title = 'NFT Detail';
    // UI Elements
    return (
        <Box>
            <HeadTag title={title} />
            <Outlet />
        </Box>
    );
};

export default NFTPageWrapper;
