import { Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { ReactElement, useEffect, useState } from 'react';
import { goesLiveIn } from "../helpers/Utils";

const GoesLiveUi = (props: any): ReactElement => {
    const { title, date } = props
    const [livetime, setLiveTime] = useState(null)

    useEffect((): any => {
        setInterval((): any => {
            setLiveTime(goesLiveIn(date))
        }, 1000)
    }, [])
    return (<Box
        width='100%'
        display='flex'
        flexDirection='column'
    >
        <Typography>{title}</Typography>
        {!livetime ? <Skeleton animation={false} variant="rect" width={310} height={50} /> : <Box
            mt={2}
            p={2}
            px={8}
            display='flex'
            justifyContent='space-evenly'
            alignItems='center'
            className='goesLiveUiWrapper'>
            <Box display='flex'
                flexDirection='column'
                alignItems='center'
            >
                <Typography variant='h3' >{livetime?.day}</Typography>
                <Typography><small>DAYS</small></Typography>
            </Box>
            <Box mx={4}>
                <Typography color='textSecondary' variant="h4"><b>:</b></Typography>
            </Box>
            <Box display='flex'
                flexDirection='column'
                alignItems='center'
            >
                <Typography variant='h3' >{livetime?.hour}</Typography>
                <Typography><small>HOURS</small></Typography>
            </Box>
            <Box mx={4}>
                <Typography color='textSecondary' variant="h4"><b>:</b></Typography>
            </Box>
            <Box display='flex'
                flexDirection='column'
                alignItems='center'
            >
                <Typography variant='h3' >{livetime?.minute}</Typography>
                <Typography><small>MINUTES</small></Typography>
            </Box>
            <Box mx={4}>
                <Typography color='textSecondary' variant="h4"><b>:</b></Typography>
            </Box>
            <Box display='flex'
                flexDirection='column'
                alignItems='center'
            >
                <Typography variant='h3' >{livetime?.second}</Typography>
                <Typography><small>SECONDS</small></Typography>
            </Box>
        </Box>}
    </Box>)
}

export default GoesLiveUi;