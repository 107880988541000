import { Formik } from 'formik';
import {
    Grid,
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    IconButton,
    Divider,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { ReactElement } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {
    AppFormikTextField,
    AppPrimaryButton,
    AppSelect,
    AppTeritaryButton,
} from '../../../shared/components/AppComponents';
import { BlockChain, Toast } from '../../../shared/helpers';
import { ImportNFTSchema } from '../../../validators/ProfileSchema';
import { AppState } from '../../../state/RootReducer';
import { ProfileService } from '../../../services';

const ImportNFT = (props: any): any => {
    const { onCancel, onImportSuccess, profileInfo } = props;
    const importForm = {
        tokenId: '',
        fromAddress: '',
        quantity: '',
        toAddress: profileInfo.walletAddress,
        blockChain: '',
    };

    // API Calls
    const createImportRecord = (importData: any, callback: any): void => {
        ProfileService.createImportEntry(importData)
            .then(() => {
                callback(true);
            })
            .catch(() => {
                callback(false);
            });
    };

    // UI Actions
    const actionImportNFT = (formValues: any, formik: any): void => {
        const { tokenId, fromAddress, quantity, toAddress, blockChain } =
            formValues;
        // Get NFT Balance
        BlockChain.getNFTBalance(`${tokenId}`, fromAddress, blockChain)
            .then((response) => {
                // Validate having enough token quantity to import
                if (response.nftBalance >= quantity) {
                    // Import NFT
                    BlockChain.importNFT(
                        `${tokenId}`,
                        `${quantity}`,
                        fromAddress,
                        toAddress,
                        blockChain
                    )
                        .then((transactionHash) => {
                            createImportRecord(
                                {
                                    fromAddress,
                                    quantity: parseInt(quantity, 10),
                                    toAddress,
                                    tokenId: `${tokenId}`,
                                    txnId: transactionHash,
                                },
                                () => {
                                    formik.setSubmitting(false);
                                    Toast.showSuccesMessage(
                                        'NFT import initiated successfully'
                                    );
                                    onImportSuccess();
                                }
                            );
                        })
                        .catch((error) => {
                            if (error.code || error.message) {
                                Toast.showErrorMessage(error.message);
                            } else {
                                Toast.showErrorMessage(error);
                            }
                            formik.setSubmitting(false);
                        });
                } else {
                    const balance = response.nftBalance;
                    if (balance === 0) {
                        Toast.showErrorMessage(
                            'Unable to fetch NFT quantity. Make sure you have entered valud TokenId and Address'
                        );
                    } else {
                        Toast.showErrorMessage(
                            `You are not having enough quantity to import. Please enter quantity less than or equal to ${balance}`
                        );
                    }
                    formik.setSubmitting(false);
                }
            })
            .catch((error) => {
                formik.setSubmitting(false);
                if (error.code || error.message) {
                    Toast.showErrorMessage(error.message);
                } else {
                    Toast.showErrorMessage(error);
                }
            });
    };

    const actionCancel = (): void => {
        onCancel();
    };

    // UI Elements
    return (
        <Formik
            enableReinitialize
            initialValues={importForm}
            validateOnMount
            validationSchema={ImportNFTSchema}
            onSubmit={(values, formik) => {
                actionImportNFT(values, formik);
            }}
        >
            {({
                handleSubmit,
                values,
                isSubmitting,
                isValid,
                setFieldValue,
            }) => (
                <>
                    <AppSelect
                        outerLabel
                        label="Select Blockchain"
                        placeholder="Select Blockchain"
                        items={[
                            { title: 'Ethereum', value: 'ethereum' },
                        ]}
                        titleKey="title"
                        valueKey="value"
                        value={values.blockChain}
                        onChange={(event: any) => {
                            setFieldValue(
                                'blockChain',
                                event.target.value,
                                true
                            );
                        }}
                    />

                    <AppFormikTextField
                        placeholder="Enter Token ID"
                        label="Token ID"
                        name="tokenId"
                        type="text"
                        size="large"
                        outerLabel
                    />

                    <AppFormikTextField
                        label="Address"
                        placeholder="Enter address from where you want to import your NFT"
                        name="fromAddress"
                        type="text"
                        size="large"
                        outerLabel
                    />

                    <AppFormikTextField
                        placeholder="Enter Quantity to Import"
                        label="Quantity"
                        name="quantity"
                        type="number"
                        size="large"
                        outerLabel
                    />

                    <Box py={2}>
                        <Grid container justifyContent="flex-end">
                            <Grid>
                                <Box mr={2}>
                                    <AppTeritaryButton
                                        disabled={isSubmitting}
                                        title="CANCEL"
                                        className='import-nft-cancle'
                                        onClick={() => {
                                            actionCancel();
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid>
                                <AppPrimaryButton
                                    title="IMPORT"
                                    loadingText="IMPORTING"
                                    isLoading={isSubmitting}
                                    disabled={isSubmitting || !isValid}
                                    onClick={handleSubmit}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )}
        </Formik>
    );
};

const mapStateToProps = (state: AppState): any => ({
    profileInfo: state.profileInfo,
});

export const ImportNFTForm = connect(mapStateToProps, null)(ImportNFT);

const ImportNFTDialog = (props: any): ReactElement => {
    const { showImportDialog, onCancel, onImportSuccess } = props;
    return (
        <Dialog
            maxWidth="sm"
            fullWidth
            disableEscapeKeyDown
            open={showImportDialog}
        >
            <DialogTitle>
                <Box className="title-section">
                    <Typography variant="h4">Import NFT</Typography>
                    <IconButton onClick={onCancel}>
                        <CloseIcon color="primary" />
                    </IconButton>
                </Box>
                <Divider />
            </DialogTitle>
            <DialogContent className='import-nft-blockchain'>
                <ImportNFTForm
                    {...{ showImportDialog, onCancel, onImportSuccess }}
                />
            </DialogContent>
        </Dialog>
    );
};

export default ImportNFTDialog;
