import ApiManager from '../shared/managers/APIManager';
import { RestEnds } from '../shared/constants';

const getTradingHistory = async (
    limit: any,
    offset: any,
    sortBy: string,
    event: string
): Promise<any> =>
    ApiManager.sendGet(RestEnds.ACTIVITY_LIST(limit, offset, sortBy, event));

export const ActivityService = {
    getTradingHistory,
};
