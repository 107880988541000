import { ReactElement } from 'react';
import { Box, Typography } from '@material-ui/core';
import Countdown from 'react-countdown';

const AuctionTimer = (props: any): ReactElement => {
    const { liveTill } = props;
    const saleCountdown = ({
        days,
        hours,
        minutes,
        completed,
        formatted,
    }: any): ReactElement => {
        if (completed) {
            // Render a completed state
            return (
                <>
                    <Typography className="text-secondary">
                        Sale closed
                    </Typography>
                </>
            );
        }
        // Render a countdown
        return (
            <>
                {days >= 0 && (
                    <Box className="timer-section">
                        <Typography variant="h3">{days}</Typography>
                        <Typography className="text-secondary">Days</Typography>
                    </Box>
                )}
                {hours >= 0 && (
                    <Box className="timer-section">
                        <Typography variant="h3">{hours}</Typography>
                        <Typography className="text-secondary">
                            Hours
                        </Typography>
                    </Box>
                )}
                {minutes >= 0 && (
                    <Box className="timer-section">
                        <Typography variant="h3">
                            {formatted.minutes}
                        </Typography>
                        <Typography className="text-secondary">
                            Minutes
                        </Typography>
                    </Box>
                )}
                <Box className="timer-section">
                    <Typography variant="h3">{formatted.seconds}</Typography>
                    <Typography className="text-secondary">Seconds</Typography>
                </Box>
            </>
        );
    };

    return (
        <Box className="action-timer">
            <Countdown date={liveTill} renderer={saleCountdown} />
        </Box>
    );
};

export default AuctionTimer;
