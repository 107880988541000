import { ReactElement } from 'react';
import { HeadTag } from '../../shared/components';
import { CreationList } from './components';

const CreationsPage = (props: any): ReactElement => (
    <>
        <HeadTag title="Creations" />
        <CreationList {...props} />
    </>
);

export default CreationsPage;
