import { ReactElement, useState, useEffect } from 'react';
import { Typography, Grid, Container, Box, Avatar } from '@material-ui/core';
import { Link } from 'react-router-dom';
// import { makeStyles } from '@material-ui/core/styles';
import PanoramaIcon from '@material-ui/icons/Panorama';
import { HeadTag, UserInfo } from '../../shared/components';

// import NFTCollection from '../home/components/NFTCollection';

// const useStyles = makeStyles({
//     root: {
//         width: '100%',
//         margin: '0 auto',
//     },
//     media: {
//         position: 'relative',
//         height: 200,
//         backgroundPosition: 'top',
//     },
//     gradient: {
//         position: 'absolute',
//         bottom: '-2px',
//         height: '40%',
//         width: '100%',
//         background: 'linear-gradient(transparent, #fff)',
//     },
// });

interface Size {
    width: number | undefined;
    height: number | undefined;
}

function useWindowSize(): Size {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState<Size>({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize(): any {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener('resize', handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}

const VikramPage = (): ReactElement => {
    // const classes = useStyles();

    const { width } = useWindowSize();

    const collections = [
        {
            name: 'VIKRAM - MAGNUM',
            logo: 'https://fantico-edition.s3.ap-south-1.amazonaws.com/collections/61039326dac51c4eeb10cb5b/logo.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAURIR4HE6FPM45LS3%2F20220530%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220530T185629Z&X-Amz-Expires=600&X-Amz-Signature=1043f9dd1e85336f90f1f6482d957efdd05dffdd1749e5f5532c0637fe195303&X-Amz-SignedHeaders=host',
            backgroundPicture:
                'https://fantico-edition.s3.ap-south-1.amazonaws.com/collections/61039326dac51c4eeb10cb5b/backgroundpicture.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAURIR4HE6FPM45LS3%2F20220530%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220530T185629Z&X-Amz-Expires=600&X-Amz-Signature=50085b3f7d730399c47812210272e2f2288c659f4c74d58aa44b5b59024fc4e0&X-Amz-SignedHeaders=host',
            featured: true,
            thumbnail: '/images/magnum.jpeg',
            id: '62834ec27d999500122b32cc',
            createdBy: {
                name: 'Vikram',
                isPrivate: false,
                address: '0x26084eb6fb0Aec14F3074d96550db8dB1345D1AD',
                profilePicture: '/images/vikram.jpeg',
                blueTick: true,
            },
            itemsCount: 1,
            price: 13,
            discountedPrice: 10,
            benefits: 'VIKRAM WORLD EXPERIENCE & EXCLUSIVE OFFERS',
            extraBenefits: 'and much more...',
            videoSrc: '/videos/magnum.mp4',
            idA: 'vikram-magnum',
        },
        {
            name: 'VIKRAM - 50 CALIBER',
            logo: 'https://fantico-edition.s3.ap-south-1.amazonaws.com/collections/6139bec9dc22c2b839fc5150/logo.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAURIR4HE6FPM45LS3%2F20220530%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220530T185629Z&X-Amz-Expires=600&X-Amz-Signature=f523180fb2eb7a7bb311440644ded7fa5f42c31a51b0bf0b0a890cef766b754f&X-Amz-SignedHeaders=host',
            thumbnail: '/images/caliber.jpeg',
            backgroundPicture:
                'https://fantico-edition.s3.ap-south-1.amazonaws.com/collections/6139bec9dc22c2b839fc5150/backgroundpicture.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAURIR4HE6FPM45LS3%2F20220530%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220530T185629Z&X-Amz-Expires=600&X-Amz-Signature=0311f298b77a741c449763f3bc3c51a8435da178d6b76dc844ec13bcbc6c8625&X-Amz-SignedHeaders=host',
            featured: true,
            id: '628350cc7d999500122b32df',
            createdBy: {
                name: 'Vikram',
                isPrivate: false,
                address: '0x3D0B426e2960A0A05840F2B2a3A35f0367617dAd',
                profilePicture: '/images/vikram.jpeg',
                blueTick: true,
            },
            itemsCount: 3,
            price: 69,
            discountedPrice: 52,
            benefits: 'VIRTUAL AUDITION & SIGNED POSTER',
            extraBenefits: '+ all MAGNUM benefits',
            videoSrc: '/videos/caliber.mp4',
            idA: 'vikram-caliber',
        },
        {
            name: 'VIKRAM - RADIOACTIVE',
            logo: 'https://fantico-edition.s3.ap-south-1.amazonaws.com/collections/615fe124a304ff043f93ef2f/logo.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAURIR4HE6FPM45LS3%2F20220530%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220530T185629Z&X-Amz-Expires=600&X-Amz-Signature=783b33311ba1522260b06effb59f2246e256c70ef194ac765b66a464b057351b&X-Amz-SignedHeaders=host',
            thumbnail: '/images/radioactive.jpeg',
            backgroundPicture:
                'https://fantico-edition.s3.ap-south-1.amazonaws.com/collections/615fe124a304ff043f93ef2f/backgroundpicture.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAURIR4HE6FPM45LS3%2F20220530%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220530T185629Z&X-Amz-Expires=600&X-Amz-Signature=0ea00e0b6bf1f43280e5eca9ff52bbed6d32dbbc42db39817dc1f04f144b3b66&X-Amz-SignedHeaders=host',

            featured: true,
            id: '62835bdc7d999500122b339b',
            createdBy: {
                name: 'Vikram',
                isPrivate: false,
                address: '0x399A455235B2aF246A69db0C1aD33e7cC2761244',
                profilePicture: '/images/vikram.jpeg',

                blueTick: true,
            },
            itemsCount: 10,
            price: 1333,
            discountedPrice: 1000,
            benefits: 'VIRTUAL MEET WITH THE STARS & VISTADOME PARTY',
            extraBenefits: '+ all MAGNUM, 50 CALIBER benefits',
            videoSrc: '/videos/Radioactive.mp4',
            idA: 'vikram-radioactive',
        },
    ];

    return (
        <>
            <HeadTag
                title="Vikram"
                description="The Metaverse and NFT platform for upcoming Tamil movie, Vikram"
            />
            <div id="" className="">
                <Container
                    maxWidth={false}
                    disableGutters
                    style={{ position: 'relative' }}
                >
                    <div className="" id="banner-video">
                        {/* <img
                            src="/images/newsroom-banner-new.jpeg"
                            className="newsroom-banner"
                            alt=""
                        /> */}
                        <video
                            src={
                                width && width <= 768
                                    ? '/videos/vikram_world_2_mobile.mp4'
                                    : '/videos/Vikram_World_2.mp4'
                            }
                            style={{ width: '100%' }}
                            autoPlay
                            loop
                            // controls
                            playsInline
                            muted
                        />
                        <div className="headingContianer">
                            <Typography variant="h5">
                                EXCLUSIVE COLLECTIBLES AND EXPERIENCES FROM
                            </Typography>
                            <Typography variant="h1">
                                KAMAL HAASAN’S VIKRAM
                            </Typography>
                        </div>
                    </div>
                </Container>

                <Container className="nft-cat fadeIn">
                    <Grid container spacing={10}>
                        {collections.map((collection) => (
                            <Grid
                                xs={12}
                                md={6}
                                lg={4}
                                item
                                className=""
                                style={{ textAlign: 'center' }}
                                key={collection.id}
                            >
                                <Link
                                    to={`/creation/${collection.id}`}
                                    id={collection.idA}
                                >
                                    <Box
                                        className="top-collection trans-3d-hover "
                                        p={2}
                                    >
                                        <Avatar
                                            variant="square"
                                            className="collection-banner"
                                            src={collection.thumbnail}
                                        >
                                            <PanoramaIcon />
                                        </Avatar>
                                        {/* <img
                                        className="collection-logo"
                                        src={collection.logo}
                                        alt="logo"
                                    /> */}

                                        <Box className="user-detail">
                                            <UserInfo
                                                id=""
                                                isPrivate={
                                                    collection.createdBy
                                                        .isPrivate
                                                }
                                                imgUrl={
                                                    collection.createdBy
                                                        .profilePicture
                                                }
                                                name={collection.createdBy.name}
                                                blueTick={
                                                    collection.createdBy
                                                        .blueTick
                                                }
                                                address={
                                                    collection.createdBy.address
                                                }
                                                fontVariant="body2"
                                            />
                                        </Box>
                                        <Box className="item-detail">
                                            <Box className="item-detail-wrapper">
                                                <Box>
                                                    <Typography
                                                        variant="body1"
                                                        className="collection-name"
                                                    >
                                                        {collection.name}
                                                    </Typography>
                                                </Box>

                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    width="100%"
                                                >
                                                    <Box className="item-detail-collections">
                                                        <Typography
                                                            variant="body2"
                                                            className="item-text"
                                                        >
                                                            {
                                                                collection.itemsCount
                                                            }{' '}
                                                            Collectibles
                                                        </Typography>
                                                    </Box>
                                                    <Box className="item-detail-favourites">
                                                        <img
                                                            className="icon-item"
                                                            src="./icons/icon_items.png"
                                                            alt="items_count"
                                                        />
                                                        <Typography
                                                            variant="body2"
                                                            className="item-text"
                                                        >
                                                            {
                                                                collection.itemsCount
                                                            }
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box
                                            className="prices"
                                            style={{ display: 'none' }}
                                        >
                                            {/* <del
                                                className="price"
                                                style={{ color: 'gray' }}
                                            >
                                                <Typography variant="h4">
                                                    <span>
                                                        ${collection.price}
                                                    </span>
                                                    {'  '}
                                                </Typography>
                                            </del> */}
                                            <Box
                                                className="discountedPrice"
                                                style={{
                                                    width: '50px',
                                                    height: '50px',
                                                    textAlign: 'center',
                                                    paddingTop: '20%',
                                                }}
                                            >
                                                <Typography variant="h3">
                                                    <span>
                                                        {collection.price} USD
                                                    </span>
                                                </Typography>
                                            </Box>{' '}
                                        </Box>
                                    </Box>

                                    <Box>
                                        <Typography
                                            variant="h4"
                                            style={{
                                                maxWidth: '310px',
                                                textAlign: 'center',
                                                margin: '0 auto',
                                                color: '#8522fa',
                                                marginTop: '1rem',
                                                marginBottom: '0.5rem',
                                            }}
                                        >
                                            {collection.benefits}
                                        </Typography>

                                        <Typography
                                            variant="body1"
                                            style={{ marginBottom: '1rem' }}
                                        >
                                            {collection.extraBenefits}
                                        </Typography>
                                        <Box>
                                            <video
                                                src={collection.videoSrc}
                                                style={{
                                                    width: '100%',
                                                    maxWidth: '300px',
                                                    border: '2px solid #8522FA',
                                                }}
                                                autoPlay
                                                loop
                                                // controls
                                                playsInline
                                                muted
                                            />
                                        </Box>
                                    </Box>
                                </Link>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
                <Box id="vikram-world-container">
                    <Typography variant="h3">
                        Got your NFTs?{' '}
                        <span>
                            Check your email for your invite to Vikram World!
                        </span>
                    </Typography>
                </Box>
            </div>
        </>
    );
};

export default VikramPage;
