import { ReactElement, useRef, useEffect, useState, useMemo } from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Typography,
    IconButton,
    Grid,
    // Avatar,
    Dialog,
    DialogTitle,
    DialogContent,
    Divider,
    CircularProgress,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ShareIcon from '@material-ui/icons/Share';
import FlagIcon from '@material-ui/icons/Flag';
import { useNavigate } from 'react-router-dom';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import LockIcon from '@material-ui/icons/Lock';
import { useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';

// import GradientChip, {
//     GradientVariants,
// } from '../../../shared/components/GradientChip';
import {
    AppSecondaryButton,
    AppPrimaryButton,
    NumberPretty,
    AppTeritaryButton,
} from '../../../shared/components/AppComponents';
import AuctionTimer from './AuctionTimer';
import { Toast, Utils } from '../../../shared/helpers';
import { Auction, ItemFullDetail, Listing } from '../NFTDetailPage';
import {
    UserInfo,
    SocialShare,
    MoreMenu,
} from '../../../shared/components';
import { MoreMenuData } from '../../../shared/components/MoreMenu';
import ReportNFT from './ReportNFT';
import { AuthState } from '../../../state/authentication/Reducer';
import NFTExportForm from './NFTExport';
import DisClaimerForm from './DisclaimerForm';
import RestrictionForm from './RestrictionForm';
import { BuySellService, CollectionService } from '../../../services';
import { CurrencyConvertion } from '../../../state/application/Reducer';
import { AppState } from '../../../state/RootReducer';
import ArtistReadMore from '../../../shared/components/ArtistReadMore';

interface NFTDetailProps {
    auctionList: Auction[];
    itemDetail: ItemFullDetail;
    authState: AuthState;
    onPlaceBid: any;
    onBuyNFT: any;
    onPostOffer: any;
    onListingCancel: any;
    onAuctionCancel: any;
    reloadItemDetail: any;
}

const moreMenus: any = [
    // {
    //     title: 'Share',
    //     icon: <ShareIcon color="primary" />,
    // },
    // {
    //     title: 'Report',
    //     icon: <FlagIcon color="primary" />,
    // },
    // {
    //     title: 'Export',
    //     icon: <ImportExportIcon color="primary" />,
    // }
];

const NFTDetail = (props: NFTDetailProps): ReactElement => {
    const userId: string = useSelector(
        (state: AppState) => state.profileInfo.userId
    );
    // Instance Variables
    const navigate = useNavigate();
    const {
        auctionList,
        itemDetail,
        authState,
        onPlaceBid,
        onBuyNFT,
        onPostOffer,
        onListingCancel,
        onAuctionCancel,
        reloadItemDetail,
    } = props;
    const [showReport, setShowReport] = useState(false);
    const [showShareDialog, setShowShareDialog] = useState(false);
    const [showFullDescription] = useState(false);
    const [showReadMore, setShowReadMore] = useState(false);
    const [menus, setMenus] = useState(moreMenus);
    const [openExportNFT, setOpenExportNFT] = useState(false);
    const [isCancelingSell, setCancellingSell] = useState(false);
    const descriptionRef = useRef<HTMLElement>(null as any);
    const [showDisclaimer, setShowDisclaimer] = useState(false);
    const [disclaimerFor, setDisclaimerFor] = useState('');
    const [fav, setFav] = useState(false);
    const [isFav, setIsFav] = useState(false);
    const [favTotal, setFavTotal] = useState(0);
    const [favLoader, setFavLoader] = useState(false);
    const [showCancelForm, setShowCancelForm] = useState(false);
    const [cancelOptionFor, setCancelOptionFor] = useState('');
    const [restrictBuy, setRestrictBuy] = useState(false);

    const getNFTDirectSellToBuy = (): Listing | null => {
        if (itemDetail.listings.length > 0) {
            const items = itemDetail.listings.filter((item) => item.canBuy);
            const lists = items.filter((list) => list.postedBy === itemDetail.creatorInfo.id)
            let list: any = null
            if (lists.length > 0) {
                [list] = lists;
            } else if (items.length > 0) {
                [list] = items;
            }
            return list;
        }
        return null;
    };

    const canShowDirectBuy = (): boolean => {
        if (Object.keys(itemDetail).length > 0) {
            return getNFTDirectSellToBuy() != null;
        }
        return false;
    };

    const getNFTAuctionToBid = (): Auction | null => {
        if (auctionList.length > 0) {
            const nfts = auctionList.filter((auction) => auction.canBid);
            return nfts.length > 0 ? nfts[0] : null;
        }
        return null;
    };

    const canShowBid = (): boolean => {
        if (auctionList.length > 0) {
            return getNFTAuctionToBid() != null;
        }
        return false;
    };

    const getListingToCancel = (): Listing | null => {
        if (itemDetail.listings.length > 0) {
            const items = itemDetail.listings.filter((item) => item.canCancel);
            return items.length > 0 ? items[0] : null;
        }
        return null;
    };

    const getAuctionToCancel = (): Auction | null => {
        if (auctionList.length > 0) {
            const nfts = auctionList.filter((auction) => auction.canCancel);
            return nfts.length > 0 ? nfts[0] : null;
        }
        return null;
    };

    const getSellTitle = (): string => {
        if (getListingToCancel()) {
            return 'CANCEL SALE';
        }
        if (getAuctionToCancel()) {
            return 'CANCEL AUCTION';
        }
        if (itemDetail.itemInfo.isOwner && itemDetail.ownerInfo.quantity > 0) {
            if (userId === itemDetail.creatorInfo.id) {
                return 'SELL';
            }
            if (!itemDetail.collectionInfo.secondarySale) {
                return 'SELL';
            }
        }
        return '';
    };

    const convertion: CurrencyConvertion = useSelector(
        (state: AppState) => state.applicationState.currencyConverstion
    );

    const ethPrice = useMemo(() => {
        if (convertion.to.amount !== '0') {
            const sale = getNFTDirectSellToBuy();
            if (sale) {
                const listing: Listing | null = getNFTDirectSellToBuy();
                const usdValue = parseFloat(convertion.to.amount);
                return listing ? `${((sale.price / usdValue) / listing.totalQuantity).toFixed(3)} ETH` : `${(sale.price / usdValue).toFixed(3)} ETH`;
            }
        }
        return '';
    }, [convertion]);

    const auctionEthPrice = useMemo(() => {
        if (convertion.to.amount !== '0') {
            const auction = getNFTAuctionToBid();
            if (auction) {
                const usdValue = parseFloat(convertion.to.amount);
                const price =
                    auction.bids.length > 0
                        ? auction.highestBid
                        : auction.minimumPrice;
                return `${(price / usdValue).toFixed(3)} ETH`;
            }
        }
        return '';
    }, [convertion, auctionList]);

    // API Calls
    const cancelSellItem = (id: string): void => {
        setCancellingSell(true);
        BuySellService.cancelSellItem(id)
            .then((res: any) => {
                setCancellingSell(false);
                Utils.handleSuccessResponse(res);
                onListingCancel();
                setShowCancelForm(false);
            })
            .catch((error) => {
                Utils.handleErrorResponse({
                    error,
                });
                onListingCancel();
                setShowCancelForm(false);
            });
    };

    const cancelAuction = (auction: Auction): void => {
        setCancellingSell(true);
        BuySellService.cancelAuction(auction.auctionId)
            .then((response) => {
                setCancellingSell(false);
                Utils.handleSuccessResponse(response);
                onAuctionCancel();
                setShowCancelForm(false);
            })
            .catch((error) => {
                Utils.handleErrorResponse({ error });
                setCancellingSell(false);
                setShowCancelForm(false);
            });
    };

    // UI Auctions
    const actionSellAction = (): void => {
        const title = getSellTitle();
        if (title === 'CANCEL SALE') {
            setCancelOptionFor('sale');
            setShowCancelForm(true);
        }
        if (title === 'CANCEL AUCTION') {
            setCancelOptionFor('auction');
            setShowCancelForm(true);
        }
        if (title === 'SELL') {
            navigate(`/creation/item/sell/${itemDetail.itemInfo.id}`);
        }
    };

    const actionDisclaimerAgreedToPlaceBid = (): any => {
        if (disclaimerFor === 'place bid') {
            setShowDisclaimer(false);
            setDisclaimerFor('');
            onPlaceBid(getNFTAuctionToBid());
        }
    };

    const actionDisclaimerAgreedToBuyNFT = (): any => {
        if (disclaimerFor === 'buy') {
            setShowDisclaimer(false);
            setDisclaimerFor('');
            onBuyNFT(getNFTDirectSellToBuy());
        }
    };

    const actionDisclaimerAgreedToPostOffer = (): any => {
        if (disclaimerFor === 'post offer') {
            setShowDisclaimer(false);
            setDisclaimerFor('');
            onPostOffer(itemDetail);
        }
    };

    const toggleFav = (_itemId: string, _isFav: boolean, e: any): void => {
        if (authState.isUserLoggedIn) {
            e.preventDefault();
            e.stopPropagation();
            if (!favLoader) {
                const data = {
                    itemId: _itemId,
                    favourite: !_isFav,
                };
                setFavLoader(true);
                CollectionService.toggleFavItems(data)
                    .then(() => {
                        if (_isFav) {
                            setIsFav(false);
                            setFavTotal(favTotal - 1);
                        } else {
                            setIsFav(true);
                            setFavTotal(favTotal + 1);
                        }
                        setFavLoader(false);
                    })
                    .catch((error: any) => {
                        Utils.handleErrorResponse({ error });
                        setFavLoader(false);
                    });
            }
        } else {
            Toast.showInfoMessage('Login to add favourite');
            navigate('/login', {
                state: { nextPathName: window.location.pathname },
            });
        }
    };

    // Hooks
    useEffect(() => {
        if (itemDetail && itemDetail.itemInfo.isOwner) {
            setMenus([
                ...moreMenus,
                {
                    title: 'Export',
                    icon: <ImportExportIcon color="primary" />,
                },
            ]);
        }

        if (descriptionRef !== null && descriptionRef.current !== null) {
            const descriptionOverflowing =
                descriptionRef.current.clientHeight <
                descriptionRef.current.scrollHeight ||
                descriptionRef.current.clientWidth <
                descriptionRef.current.scrollWidth;
            if (descriptionOverflowing) {
                setShowReadMore(true);
            } else {
                setShowReadMore(false);
            }
        }
        if (
            itemDetail &&
            itemDetail.itemInfo &&
            itemDetail.itemInfo.favourites
        ) {
            setFav(true);
            setIsFav(itemDetail.itemInfo.favourites.isFavourite);
            setFavTotal(itemDetail.itemInfo.favourites.total);
        }
    }, [itemDetail]);

    // UI Actions
    const actionExportNFT = (): void => {
        if (authState.isUserLoggedIn) {
            setOpenExportNFT(true);
        } else {
            Toast.showInfoMessage('Login to export NFT');
            navigate('/login', {
                state: { nextPathName: window.location.pathname },
            });
        }
    };

    const getFavIcon = (): ReactElement => {
        if (favLoader) {
            return <CircularProgress size={20} />;
        }
        if (isFav) {
            return <FavoriteIcon color="primary" />;
        }
        return <FavoriteBorderIcon color="primary" />;
    };

    // UI Elements
    const ListingToBuy = (): ReactElement => {
        const listing: Listing | null = getNFTDirectSellToBuy();

        // Instance Methods
        const getSubTotal = (): number =>
            listing ? listing.price / listing.totalQuantity : 0;

        return (
            <>
                {listing && (
                    <Grid container className="bid-detail" style={{ display: 'none' }} spacing={4} >
                        <Grid item md={6} xs={12}>
                            <Box className="bid-detail-1">
                                <Typography className="heading">
                                    Current Price
                                </Typography>
                                <Typography
                                    component={Box}
                                    variant="body1"
                                    className="bid-amount"
                                >
                                    {getSubTotal()} USD
                                </Typography>
                                <Typography variant="body2" className="mt-1">
                                    {ethPrice}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Box className="bid-detail-2">
                                <Typography className="heading">
                                    Quantity Available
                                </Typography>

                                <Typography
                                    className="nft-count mt-2"
                                    component={Box}
                                >
                                    {listing.availableQuantity &&
                                        listing.availableQuantity > 0 ? (
                                        <>
                                            {' '}
                                            {listing.availableQuantity} of
                                            {' '}{listing.totalQuantity}
                                        </>
                                    ) : (
                                        'N/A'
                                    )}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box my={2}>
                                <AppPrimaryButton
                                    className="btn-small"
                                    title="BUY"
                                    fullWidth
                                    onClick={() => {
                                        if (authState.isUserLoggedIn) {
                                            if (
                                                itemDetail.itemInfo.whiteList &&
                                                itemDetail.buySellRestrict &&
                                                !itemDetail.userWhiteList
                                            ) {
                                                setRestrictBuy(true);
                                            } else if (
                                                itemDetail.itemInfo.physicalNFT
                                            ) {
                                                setDisclaimerFor('buy');
                                                setShowDisclaimer(true);
                                            } else {
                                                onBuyNFT(
                                                    getNFTDirectSellToBuy()
                                                );
                                            }
                                        } else {
                                            Toast.showSuccesMessage(
                                                'Login to buy items'
                                            );
                                            navigate('/login', {
                                                state: {
                                                    nextPathName:
                                                        window.location
                                                            .pathname,
                                                },
                                            });
                                        }
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </>
        );
    };

    const AuctionToBid = (): ReactElement => {
        const auction: Auction | null = getNFTAuctionToBid();

        // Auction status
        const isAuctionLive = (tilltime: any): any => {
            const currenttime = new Date();
            const currentEpochTime = currenttime.getTime();
            const liveTillEpochTime = new Date(tilltime).getTime();
            const isActive = liveTillEpochTime - currentEpochTime;
            if (isActive > 0) { return true }
            return false
        }
        const auctionStatus = isAuctionLive(auction?.liveTill)
        return (
            <>
                {auction && (
                    <Grid container className="bid-detail" spacing={4}>
                        <Grid item md={6} xs={12}>
                            <Box className="bid-detail-1">
                                <Typography className="heading">
                                    {auction.bids.length > 0
                                        ? 'Highest Bid'
                                        : 'Min Bid'}
                                </Typography>

                                <Typography variant="h3" className="bid-amount">
                                    {auction.bids.length > 0
                                        ? auction.highestBid
                                        : auction.minimumPrice} USD{' '}
                                    {/* {auction.currencyType} */}
                                </Typography>
                                <Typography variant="body2" className="mt-1">
                                    {auctionEthPrice}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Box className="bid-detail-2">
                                <Typography className="heading">
                                    Auction ending in
                                </Typography>
                                <AuctionTimer liveTill={auction.liveTill} />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box my={2}>
                                {auctionStatus ? (<AppPrimaryButton
                                    className="btn-small"
                                    title="PLACE A BID"
                                    fullWidth
                                    onClick={() => {
                                        if (authState.isUserLoggedIn) {
                                            if (
                                                itemDetail.itemInfo.whiteList &&
                                                itemDetail.buySellRestrict &&
                                                !itemDetail.userWhiteList
                                            ) {
                                                setRestrictBuy(true);
                                            } else if (
                                                itemDetail.itemInfo.physicalNFT
                                            ) {
                                                setDisclaimerFor('place bid');
                                                setShowDisclaimer(true);
                                            } else {
                                                onPlaceBid(
                                                    getNFTAuctionToBid()
                                                );
                                            }
                                        } else {
                                            Toast.showSuccesMessage(
                                                'Login to buy items'
                                            );
                                            navigate('/login', {
                                                state: {
                                                    nextPathName:
                                                        window.location
                                                            .pathname,
                                                },
                                            });
                                        }
                                    }}
                                />) : (null)}

                            </Box>
                        </Grid>
                    </Grid>
                )}
            </>
        );
    };

    const CancelNFTItem = (cancelProps: any): ReactElement => {
        const { open, onCloseForm, onCancel } = cancelProps;
        return (
            <Dialog maxWidth="xs" fullWidth open={open}>
                <DialogTitle>
                    <Box className="title-section">
                        <Typography variant="h4">Cancel</Typography>
                        <IconButton onClick={onCloseForm}>
                            <CloseIcon color="primary" />
                        </IconButton>
                    </Box>
                    <Divider />
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Typography variant="body1">
                            Are you sure you want to cancel this{' '}
                            {cancelOptionFor} ?
                        </Typography>

                        <Grid container className="end-content mt-4">
                            <AppPrimaryButton
                                title="CANCEL"
                                isLoading={isCancelingSell}
                                disabled={isCancelingSell}
                                loadingText="CANCELLING"
                                onClick={onCancel}
                            />
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    };

    return (
        <>
            {itemDetail && (
                <Box>
                    <Box className="nft-detail">
                        {authState.isUserLoggedIn && itemDetail && itemDetail.itemInfo.isOwner &&
                            <Box>
                                {/* <MoreMenu
                                menus={menus}
                                onMenuSelect={(menu: MoreMenuData) => {
                                    if (menu.title === 'Share') {
                                        setShowShareDialog(true);
                                    } else if (menu.title === 'Report') {
                                        if (authState.isUserLoggedIn) {
                                            setShowReport(true);
                                        } else {
                                            Toast.showSuccesMessage(
                                                'Login to report items'
                                            );
                                            navigate('/login', {
                                                state: {
                                                    nextPathName:
                                                        window.location
                                                            .pathname,
                                                },
                                            });
                                        }
                                    } else if (menu.title === 'Export') {
                                        actionExportNFT();
                                    }
                                }}
                            /> */}
                            </Box>
                        }
                        {/* <Box display="flex" className="tags">
                            <NFTTag tag={itemDetail.itemInfo.tag} />
                            <GradientChip
                                variant={GradientVariants.LIGHT}
                                label={itemDetail.itemInfo.category}
                            />
                            {itemDetail.itemInfo.physicalNFT && (
                                <GradientChip
                                    variant={GradientVariants.LIGHT}
                                    label="Physical NFT"
                                /> 
                            )}
                        </Box> */}
                        {/* <Box className="nft-title-section">
                            <Typography variant="h3" className="title">
                                {itemDetail.itemInfo.name}{' '}
                                {itemDetail.itemInfo
                                    .lockableContentAvailable && (
                                        <LockIcon color="primary" />
                                    )}
                            </Typography>
                            <Box className="more-view">
                                <Box
                                    className="view-and-availablitiy"
                                    id="view-and-availablitiy-mobile"
                                >
                                    <img
                                        className="view"
                                        src="/icons/visibility.svg"
                                    />
                                    <NumberPretty
                                        count={
                                            itemDetail.itemInfo.numberOfViews
                                        }
                                    />
                                    {false && (
                                        <>
                                            <img
                                                className="dot"
                                                src="/icons/icon_dot.png"
                                            />
                                            <Typography variant="body2">
                                                5 of 7 available
                                            </Typography>
                                        </>
                                    )}
                                </Box>
                                <Box>
                                    {fav && (
                                        <>
                                            <AppTeritaryButton
                                                className="btn-small"
                                                title={
                                                    <NumberPretty
                                                        count={favTotal}
                                                    />
                                                }
                                                startIcon={getFavIcon()}
                                                onClick={(e: any) =>
                                                    toggleFav(
                                                        itemDetail.itemInfo.id,
                                                        isFav,
                                                        e
                                                    )
                                                }
                                            />
                                        </>
                                    )}
                                </Box>

                                <MoreMenu
                                    menus={menus}
                                    onMenuSelect={(menu: MoreMenuData) => {
                                        if (menu.title === 'Share') {
                                            setShowShareDialog(true);
                                        } else if (menu.title === 'Report') {
                                            if (authState.isUserLoggedIn) {
                                                setShowReport(true);
                                            } else {
                                                Toast.showSuccesMessage(
                                                    'Login to report items'
                                                );
                                                navigate('/login', {
                                                    state: {
                                                        nextPathName:
                                                            window.location
                                                                .pathname,
                                                    },
                                                });
                                            }
                                        } else if (menu.title === 'Export') {
                                            actionExportNFT();
                                        }
                                    }}
                                />
                            </Box>
                        </Box> */}
                        <Grid
                            className="collection-creator"
                            spacing={4}
                        >
                            <Box className="creatorName">
                                {/* <Typography className='ntf-value'>Creator </Typography> */}
                                <UserInfo
                                    id={itemDetail.creatorInfo.id}
                                    name={itemDetail.creatorInfo.name}
                                    blueTick={
                                        itemDetail.creatorInfo.blueTick
                                    }
                                    isPrivate={
                                        itemDetail.creatorInfo.isPrivate
                                    }
                                    address={itemDetail.creatorInfo.address}
                                    imgUrl={            
                                        itemDetail.creatorInfo
                                            .profilePicture
                                    }
                                    originalArtist={
                                        itemDetail.itemInfo.originalArtist
                                    }
                                />
                            </Box>
                            {/* <Grid item md={6} xs={12}> */}
                            {/* <Box className="collections">
                                    <Typography>Collection</Typography>
                                    <a
                                        className="user-info"
                                        href={`/creation/${itemDetail.collectionInfo.id}`}
                                    >
                                        <Avatar
                                            src={itemDetail.collectionInfo.logo}
                                        />
                                        <Typography className="title">
                                            {itemDetail.collectionInfo.name}
                                        </Typography>
                                    </a>
                                </Box> */}
                            {/* </Grid> */}
                        </Grid>
                        {canShowDirectBuy() && (
                            <>
                                <ListingToBuy />
                            </>
                        )}
                        {canShowBid() && (
                            <>
                                <AuctionToBid />
                            </>
                        )}
                        <Box className="nft-description">
                            {itemDetail.itemInfo.description?.length && (<Box className='artist-Detail-description-text'>
                                <ArtistReadMore
                                    byWords
                                    length={350}
                                    ellipsis="..."
                                    parentClass="nft-description"
                                    className="nft-description-text">
                                    {itemDetail.itemInfo.description}
                                </ArtistReadMore>
                            </Box>)}
                        </Box>
                        {itemDetail.itemInfo.physicalNFTDescription !== null &&
                            itemDetail.itemInfo.physicalNFTDescription !==
                            '' && (
                                <>
                                    {/* <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography className="filter-margin">
                                                Physical NFT
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography
                                                variant="body2"
                                                dangerouslySetInnerHTML={{
                                                    __html: itemDetail.itemInfo
                                                        .physicalNFTDescription,
                                                }}
                                            />
                                        </AccordionDetails>
                                    </Accordion> */}
                                </>
                            )}

                        {itemDetail.itemInfo.lockableContentAvailable && (
                            <>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <Typography className="filter-margin">
                                            Unlockable Content
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {!itemDetail.itemInfo.isOwner && (
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <LockIcon color="primary" />
                                                <Typography variant="body1">
                                                    This content can only be
                                                    unlocked and revealed by the
                                                    owner of this item.
                                                </Typography>
                                            </Box>
                                        )}
                                        {itemDetail.itemInfo.isOwner && (
                                            <Typography
                                                variant="body1"
                                                dangerouslySetInnerHTML={{
                                                    __html: itemDetail.itemInfo
                                                        .lockableContent,
                                                }}
                                            />
                                        )}
                                    </AccordionDetails>
                                </Accordion>
                            </>
                        )}

                        {false &&
                            itemDetail.itemInfo.keywords &&
                            itemDetail.itemInfo.keywords !== '' && (
                                <>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography className="filter-margin">
                                                Keywords
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant="body1">
                                                {itemDetail.itemInfo.keywords}
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <hr />
                                </>
                            )}

                        {/* {getSellTitle() !== '' && (
                            <Box mt={2}>
                                <AppPrimaryButton
                                    fullWidth
                                    title={getSellTitle()}
                                    onClick={actionSellAction}
                                />
                            </Box>
                        )} */}

                        {itemDetail.canRequest &&
                            !itemDetail.itemInfo.hasAuction && (
                                <AppSecondaryButton
                                    className="mt-2"
                                    title="POST OFFER"
                                    fullWidth
                                    onClick={() => {
                                        if (authState.isUserLoggedIn) {
                                            if (
                                                itemDetail.itemInfo.physicalNFT
                                            ) {
                                                setDisclaimerFor('post offer');
                                                setShowDisclaimer(true);
                                            } else {
                                                onPostOffer(itemDetail);
                                            }
                                        } else {
                                            Toast.showSuccesMessage(
                                                'Login to post offers'
                                            );
                                            navigate('/login', {
                                                state: {
                                                    nextPathName:
                                                        window.location
                                                            .pathname,
                                                },
                                            });
                                        }
                                    }}
                                />
                            )}
                    </Box>
                </Box>
            )}
            <SocialShare
                onCloseDialog={() => {
                    setShowShareDialog(false);
                }}
                showShare={showShareDialog}
                tooltip="Share your portfolio"
                title={`Checkout NFT @${itemDetail.itemInfo.name.replace(
                    ' ',
                    ''
                )} at @TUS`}
                link={`/item/${itemDetail.itemInfo.id}`}
            />
            <ReportNFT
                itemId={itemDetail.itemInfo.id}
                onCloseReport={() => {
                    setShowReport(false);
                }}
                showReport={showReport}
            />
            {openExportNFT && (
                <NFTExportForm
                    {...{
                        openExportNFT,
                        itemDetail,
                        onCloseExportDialog: (reloadItems: boolean) => {
                            setOpenExportNFT(false);
                            if (reloadItems) {
                                reloadItemDetail();
                            }
                        },
                    }}
                />
            )}
            {showDisclaimer && (
                <DisClaimerForm
                    open={showDisclaimer}
                    onCloseForm={() => setShowDisclaimer(false)}
                    isAgreed={() => {
                        if (disclaimerFor === 'place bid') {
                            actionDisclaimerAgreedToPlaceBid();
                        } else if (disclaimerFor === 'buy') {
                            actionDisclaimerAgreedToBuyNFT();
                        } else if (disclaimerFor === 'post offer') {
                            actionDisclaimerAgreedToPostOffer();
                        }
                    }}
                />
            )}
            {restrictBuy && (
                <RestrictionForm
                    open={restrictBuy}
                    onCloseForm={() => setRestrictBuy(false)}
                />
            )}

            {/* {showCancelForm && (
                <CancelNFTItem
                    open={showCancelForm}
                    onCloseForm={() => setShowCancelForm(false)}
                    onCancel={() => {
                        if (cancelOptionFor === 'sale') {
                            const listing = getListingToCancel();
                            if (listing) {
                                cancelSellItem(listing!.id);
                            }
                        } else if (cancelOptionFor === 'auction') {
                            const auction = getAuctionToCancel();
                            if (auction) {
                                cancelAuction(auction);
                            }
                        }
                    }}
                />
            )} */}
        </>
    );
};
export default NFTDetail;
