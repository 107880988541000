import { ReactElement } from 'react';
import {
    Box,
    Grid,
    Typography,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    Divider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { AppPrimaryButton } from '../../../shared/components/AppComponents';

const DisClaimerForm = (props: any): ReactElement => {
    const { open, onCloseForm, isAgreed } = props;

    return (
        <Dialog maxWidth="xs" fullWidth open={open}>
            <DialogTitle>
                <Box className="title-section">
                    <Typography variant="h4">Disclaimer</Typography>
                    <IconButton onClick={onCloseForm}>
                        <CloseIcon color="primary" />
                    </IconButton>
                </Box>
                <Divider />
            </DialogTitle>
            <DialogContent>
                <Box>
                    <Typography variant="body1">
                        Lorem Ipsum is simply dummy text of the printing 
                        and typesetting industry. Lorem Ipsum has been the 
                        industry's standard dummy text ever since the 1500s, 
                        when an unknown printer took a galley of type and 
                        scrambled it to make a type specimen book. 
                    </Typography>

                    <Grid container className="end-content mt-4">
                        <AppPrimaryButton
                            title="Agree and Continue"
                            onClick={isAgreed}
                        />
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default DisClaimerForm;
