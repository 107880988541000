
import {
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    IconButton,
    Divider,
    Grid,
} from '@material-ui/core';
import { ReactElement } from 'react';
import {
    FacebookShareButton, FacebookIcon,
    TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon, PinterestShareButton, PinterestIcon, TelegramShareButton, TelegramIcon, LinkedinIcon, LinkedinShareButton
} from "react-share";

// Share button import 
import LinkIcon from '@material-ui/icons/Link';
import CloseIcon from '@material-ui/icons/Close';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Toast } from '../../shared/helpers';

interface SocialShareProps {
    showShare: boolean;
    tooltip: string;
    link: string;
    title: string;
    onCloseDialog: any;
    imagefile: string;
    header:string;
}

const SocialShare = (props: SocialShareProps): ReactElement => {
    const { showShare, tooltip, link, title, onCloseDialog, imagefile = 'https://dev.theupsidespace.com/images/if-no-image.png' ,header } = props;

    // Instance Methods
    const getUrl = (): string => `${window.location.protocol}//${window.location.host}${link}`;
    // UI Elements
    return (
        <Box className="social-share-container">
            <Dialog open={showShare}>
                <DialogTitle>
                    <Box className="title-section">
                        <Typography variant="h4">Share {header} via</Typography>
                        <IconButton
                            onClick={() => {
                                onCloseDialog();
                            }}
                        >
                            <CloseIcon color="primary" />
                        </IconButton>
                    </Box>
                    <Divider />
                </DialogTitle>
                <DialogContent>
                    <Box
                        display='flex'
                        flexDirection='row'
                        alignItems='center'>
                        <FacebookShareButton
                            url={link.includes('http')||link.includes('https') ? link : getUrl()}
                            quote={title}
                            hashtag={title}
                        >
                            <Box m={3} display='flex'
                                flexDirection='column'
                                alignItems='center'>
                                <FacebookIcon iconFillColor='#fff' bgStyle={{ fill: '#000' }} size={32} round />
                                <small>Facebook</small>
                            </Box>
                        </FacebookShareButton>

                        <TwitterShareButton
                            url={title}
                        >
                            <Box m={3} display='flex'
                                flexDirection='column'
                                alignItems='center'>
                                <TwitterIcon iconFillColor='#fff' bgStyle={{ fill: '#000' }} size={32} round />
                                <small>Twitter</small>
                            </Box>
                        </TwitterShareButton>
                        <WhatsappShareButton
                            url={title}
                        >
                            <Box m={3} display='flex'
                                flexDirection='column'
                                alignItems='center'>
                                <WhatsappIcon iconFillColor='#fff' bgStyle={{ fill: '#000' }} size={32} round />
                                <small>WhatsApp</small>
                            </Box>
                        </WhatsappShareButton>
                    </Box>
                    <Box
                        display='flex'
                        flexDirection='row'
                        alignItems='center'
                    >

                        <LinkedinShareButton
                            url={getUrl()}
                        >
                            <Box m={3} display='flex'
                                flexDirection='column'
                                alignItems='center'>
                                <LinkedinIcon iconFillColor='#fff' bgStyle={{ fill: '#000' }} size={32} round />
                                <small>LinkedIn</small>
                            </Box>
                        </LinkedinShareButton>
                        <PinterestShareButton
                            media={imagefile}
                            description={title}
                            url={title}
                        >
                            <Box m={3} display='flex'
                                flexDirection='column'
                                alignItems='center'>
                                <PinterestIcon iconFillColor='#fff' bgStyle={{ fill: '#000' }} size={32} round />
                                <small>Pinterest</small>
                            </Box>
                        </PinterestShareButton>
                        <TelegramShareButton
                            url={title}
                        >
                            <Box m={3} display='flex'
                                flexDirection='column'
                                alignItems='center'>
                                <TelegramIcon iconFillColor='#fff' bgStyle={{ fill: '#000' }} size={32} round />
                                <small>Telegram</small>
                            </Box>
                        </TelegramShareButton>

                    </Box>
                    <CopyToClipboard
                        text={getUrl()}
                        onCopy={() => Toast.showSuccesMessage('Copied')}
                    >
                        <Box m={3} display='flex'
                            flexDirection='column'
                            alignItems='center'>
                            <IconButton size='small' style={{ background: '#000', color: '#fff' }} >
                                <LinkIcon />
                            </IconButton>
                            <small>Copy Link</small>
                        </Box>
                    </CopyToClipboard>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default SocialShare;
