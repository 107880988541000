import { ReactElement } from 'react';
import { PaymentTransactionList } from './components';
import { HeadTag } from '../../shared/components';

const PaymentTransactionPage = (): ReactElement => (
    <>
        <HeadTag title="Payment histories" />
        <PaymentTransactionList />
    </>
);

export default PaymentTransactionPage;
