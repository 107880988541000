import { Box } from '@material-ui/core';

function NFTMediaBox({
    mediaUrl,
    imgStyle,
}: {
    mediaUrl: string;
    imgStyle?: string | string[];
}): JSX.Element {
    return (
        <Box style={{ padding: 24 }}>
            <img
                className={
                    Array.isArray(imgStyle) ? imgStyle.join(' ') : imgStyle
                }
                style={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    maxHeight: '55vh',
                    objectFit: 'contain',
                }}
                src={mediaUrl}
                alt="nft-artwork"
            />
        </Box>
    );
}

NFTMediaBox.defaultProps = {
    imgStyle: '',
};

export default NFTMediaBox;
