import { useEffect, useState, ReactElement } from 'react';
import {
    Typography,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    Box,
    ButtonGroup,
    Button,
    TablePagination,
    Divider,
} from '@material-ui/core';
import moment from 'moment';
import FolderIcon from '@material-ui/icons/Folder';
import WithdrawReqSkeleton from '../../../shared/skeletons/WithdrawReqSkeleton';
import { EmptyPlaceholder } from '../../../shared/components';
import { Utils } from '../../../shared/helpers';
import { ProfileService, DefaultPagination } from '../../../services';

export interface AccountDetails {
    currencyType: string;
    name: string;
    accountNumber: string;
    IFSCCode: string;
    IBANCode: string;
    bankName: string;
    id: string;
    cryptoType: string;
    address: string;
}

export interface Request {
    amount: number;
    withdrawalType: string;
    events: string;
    requestedAt: string;
    createdAt: string;
    id: string;
    userName?: null;
    name: string;
    accountDetails: AccountDetails;
    transactionId: string;
}

const WithdrawRequestList = (): ReactElement => {
    const [req, setReq] = useState<Request[]>([]);
    const [pagination, setPagination] = useState({ ...DefaultPagination(10) });
    const [loader, setLoader] = useState(true);
    const [currencyType, setCurrencyType] = useState('all');
    const sortBy = 'recent';
    const status = 'all';

    // API Calls
    const loadWithDrawList = (): any => {
        setLoader(true);
        ProfileService.withdrawList(
            pagination.limit,
            pagination.offset,
            sortBy,
            status,
            currencyType
        )
            .then((response: any) => {
                const { withdrawalRequests } = response.data.data;
                const paginationResponse = response.data.data.pagination;
                setReq(withdrawalRequests);
                setPagination(paginationResponse);
                setLoader(false);
            })
            .catch(() => {
                setLoader(false);
            });
    };

    // Hooks
    useEffect(() => {
        pagination.offset = 1;
        loadWithDrawList();
    }, [currencyType]);

    // UI Actions
    const actionHandleLimitChange = (event: any): void => {
        const rowsPerPage = event.target.value;
        pagination.limit = parseInt(rowsPerPage, 10);
        loadWithDrawList();
    };

    const actionHandlePageChange = (event: any, newPage: number): void => {
        pagination.offset = newPage + 1;
        loadWithDrawList();
    };

    const actionLoadWithRequests = (type: string): void => {
        setCurrencyType(type);
    };

    // UI Elements
    return (
        <Box className="profile-content">
            <Box className="card-header-btn view-profile">
                <Typography className="title" variant="h3">
                    Withdrawal Requests
                </Typography>
                {/* <ButtonGroup
                    color="primary"
                    aria-label="outlined primary button group"
                >
                    <Button
                        className="btn-small"
                        onClick={() => {
                            actionLoadWithRequests('all');
                        }}
                        variant={
                            currencyType === 'all' ? 'contained' : undefined
                        }
                    >
                        All
                    </Button>
                    <Button
                        className="btn-small"
                        onClick={() => {
                            actionLoadWithRequests('fiat');
                        }}
                        variant={
                            currencyType === 'fiat' ? 'contained' : undefined
                        }
                    >
                        Fiat
                    </Button>
                    <Button
                        className="btn-small"
                        onClick={() => {
                            actionLoadWithRequests('crypto');
                        }}
                        variant={
                            currencyType === 'crypto' ? 'contained' : undefined
                        }
                    >
                        Crypto
                    </Button>
                </ButtonGroup> */}
            </Box>
            <Divider className='border-content'/>
            <Box className="profile-card">
                <Typography variant="body1" className="subtitle">
                    You can view all your withdrawal requests here.
                </Typography>

                <Box mt={5}>
                    {req.length === 0 && loader === false && (
                        <EmptyPlaceholder
                            icon={<FolderIcon />}
                            message="No withdrawal requests found"
                        />
                    )}
                    {loader === true && <WithdrawReqSkeleton />}

                    {loader === false && req.length > 0 && (
                        <>
                            <TableContainer>
                                <Table stickyHeader className="striped-table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                Date
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                Type
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                Transaction Ref
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                Account Detail
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                Amount
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                Status
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {req.map((request) => (
                                            <TableRow
                                                className="f13 exo2-regular"
                                                key={request.id}
                                            >
                                                <TableCell
                                                    component="td"
                                                    scope="row"
                                                >
                                                    {moment(
                                                        request.requestedAt
                                                    ).format('DD-MM-YYYY, h:mm a')}
                                                </TableCell>
                                                <TableCell
                                                    className="type"
                                                    component="td"
                                                    scope="row"
                                                >
                                                    {request.withdrawalType}
                                                </TableCell>
                                                <TableCell
                                                    className="type"
                                                    component="td"
                                                    scope="row"
                                                >
                                                    {request.withdrawalType ===
                                                        'crypto'
                                                        ? Utils.formatedAddress(
                                                            request.transactionId
                                                        )
                                                        : request.transactionId}
                                                </TableCell>
                                                <TableCell
                                                    component="td"
                                                    scope="row"
                                                >
                                                    {request.withdrawalType ===
                                                        'fiat' && (
                                                            <>
                                                                <strong>
                                                                    {
                                                                        request
                                                                            .accountDetails
                                                                            .bankName
                                                                    }
                                                                </strong>
                                                                <br />
                                                                <small>
                                                                    {
                                                                        request
                                                                            .accountDetails
                                                                            .accountNumber
                                                                    }
                                                                </small>
                                                                <br />
                                                            </>
                                                        )}
                                                    {request.withdrawalType ===
                                                        'crypto' && (
                                                            <>
                                                                <strong>
                                                                    {Utils.formatedAddress(
                                                                        request
                                                                            .accountDetails
                                                                            .address
                                                                    )}
                                                                </strong>
                                                            </>
                                                        )}
                                                </TableCell>
                                                <TableCell
                                                    component="td"
                                                    scope="row"
                                                >
                                                    {request.amount}{' '}
                                                    {request.withdrawalType ===
                                                        'fiat'
                                                        ? request.accountDetails
                                                            .currencyType
                                                        : request.accountDetails
                                                            .cryptoType}
                                                </TableCell>
                                                <TableCell
                                                    className={`status ${request.events}`}
                                                    component="td"
                                                    scope="row"
                                                >
                                                    {request.events}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                component="div"
                                count={pagination.total}
                                onPageChange={actionHandlePageChange}
                                onRowsPerPageChange={actionHandleLimitChange}
                                page={pagination.offset - 1}
                                rowsPerPage={pagination.limit}
                                rowsPerPageOptions={[5, 10, 25]}
                            />
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default WithdrawRequestList;
