
import { Avatar, Box, Button, CircularProgress, Container, Tooltip, Typography ,Chip } from '@material-ui/core';
import { ReactElement, useEffect, useState } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { Utils } from '../../shared/helpers';
import { CollectionService } from '../../services';
import { HeadTag, SlideNextArrow, SlidePrevArrow } from '../../shared/components';
import ArtistTab from './ArtistTab';
import Video from '../../shared/components/Video';
import { getUrl, getVideoUrl } from '../../shared/helpers/Utils';
import NotFound from '../../shared/components/NotFound';
import ArtistReadMore from '../../shared/components/ArtistReadMore';
import CuratingLoading from '../../shared/components/curatingLoading';
import SocialShare from '../Exhibitions/SocialShare';
import OpenMeta from '../Exhibitions/ExhibitionMeta';

const ArtistDetail = (props: any): ReactElement => {
    const location = useLocation();
    const pageUrl = location.pathname.split("/");
    const search = window?.location?.search;
    const params = new URLSearchParams(search);
    const view = params.get('view');
    const title = 'Artist Detail';
    const description = " Artist Detail";
    const { userId } = useParams();
    const [controls] = useState(true);
    const [loading, setLoding] = useState(false);
    const [playing, setPlaying] = useState(true);
    const [userType ,setUserType] =useState("Artist")
    const [muted] = useState(true);
    const [loop] = useState(true);
    const [volume] = useState(0);
    const [pip] = useState(true);
    const [artistsUserDetail, setArtistsUserDetail]: any = useState(null);
    const [errorPage, setErrorPage] = useState(false);
    const { user } = props;
    const [showShareDialog, setShowShareDialog] = useState(false);


    const navigate = useNavigate()

    // TODO immediate implementation
    // API Calls
    const loadItems = (): void => {
        setLoding(true)
        CollectionService.getArtistsUserDetail(userId,view)
            .then((response: any) => {
                const { data } = response.data;
                setArtistsUserDetail(data.userInfo);
                setUserType(data.userInfo?.type)

                if(pageUrl[1] !== data.userInfo?.type) {
                    navigate(`/${data.userInfo?.type}/${pageUrl[2]}`);
                }
                setLoding(false)
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
                setLoding(false)
                const statusCode = JSON.parse(
                    JSON.stringify(error)
                )?.message.replace(/^\D+/g, '');
                if (statusCode === 400 || 404) {
                    setErrorPage(true)
                }
            });
    };

    useEffect(() => {
        loadItems();
    }, []);

    const ImageSliderConfigArtistDetails = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SlideNextArrow />,
        prevArrow: <SlidePrevArrow />,
    };

    const userData = artistsUserDetail?.social !== undefined ? (JSON.parse(artistsUserDetail?.social)) : null;
    // if (errorPage) return <NotFound />
    return (
        <>
            {loading ? (
                <CuratingLoading />
            ) : (!errorPage && artistsUserDetail &&
                <Box className='artist-detail-main-container'>
                    <HeadTag title={title} description={description} />
                    {OpenMeta(artistsUserDetail?.title,artistsUserDetail?.description,"article",getUrl(artistsUserDetail?.profilePicture, '00x700/'),window.location.href)}
                    {artistsUserDetail?.video && artistsUserDetail?.video?.length !== 0 ? (
                        <Box className='artist-detail-inner-container video-box 123'>
                            <Video video={getVideoUrl(artistsUserDetail.video[0])} pip={pip}
                                playing={playing}
                                controls={controls}
                                volume={volume}
                                muted={muted}
                                loop={loop}
                                setPlaying={setPlaying} />
                        </Box>
                    ) :
                        artistsUserDetail?.video?.length === 0 && artistsUserDetail?.backgroundPicture && (
                            <Box className='artist-detail-inner-container video-box 456'>
                                {artistsUserDetail?.backgroundPicture && <img className='nft-video-img' src={getUrl(artistsUserDetail.backgroundPicture, '00x00/')} />}
                            </Box>
                        )
                    }

                    <Box className='artist-detail-section'>
                        <Container>
                            <Box className='artist-Detail-inner-section'>
                                <Box className='artist-Datail-image-section'>
                                    <img src={artistsUserDetail.profilePicture ? getUrl(`${artistsUserDetail.profilePicture}`, '1000x1000/') : 'https://e7.pngegg.com/pngimages/753/432/png-clipart-user-profile-2018-in-sight-user-conference-expo-business-default-business-angle-service-thumbnail.png'} alt="artist-img" />
                                </Box>
                                <Box className='artist-Detail-Description-section'>
                                {userType !== "user" &&
                                <Chip className='type-chip' size='small' label={Utils.titleCase(userType)} />
                                 }
                                    <Box className='align-items'>
                                        <Typography className='artist-Detail-Description-Heading'>{artistsUserDetail?.name}</Typography>
                                        <Button className='small-device-share' onClick={() => setShowShareDialog(true)} >
                                            <img src='/images/exibition-menu-icon.svg' />
                                        </Button>
                                    </Box>
                                    <Box className='artist-Detail-social-media'>
                                        {artistsUserDetail?.social && Object.keys(JSON.parse(artistsUserDetail?.social))?.map((socialkey, i) => (
                                            <Box key={socialkey} mr={3}>
                                                <a
                                                    target="_blank"
                                                    href={JSON.parse(artistsUserDetail?.social)[socialkey]}
                                                    rel="noreferrer"
                                                >
                                                    <Tooltip arrow title={socialkey}>
                                                        <Avatar style={{ backgroundColor: '#fff', color: '#000' }} alt={socialkey} src={`/socialicons/${socialkey?.toLowerCase()}.svg`} />
                                                    </Tooltip>
                                                </a>
                                            </Box>
                                        ))}
                                        {artistsUserDetail?.region?.subRegion && <Box
                                            display='flex'
                                            flexDirection='row'
                                            alignItems='center'
                                        >
                                            <Box mr={3}>
                                                <Avatar style={{ backgroundColor: '#fff', color: '#000' }} alt={artistsUserDetail?.region?.subRegion} src='/socialicons/region.svg' />
                                            </Box>
                                            <Typography variant='h5'><b>{`${artistsUserDetail?.region?.name}, ${artistsUserDetail?.region?.subRegion}`}</b></Typography>
                                        </Box>}

                                    </Box>

                                    {(artistsUserDetail.description || artistsUserDetail.bio) && (
                                        <Box className='artist-Detail-description-text'>
                                            <ArtistReadMore
                                                byWords
                                                length={350}
                                                ellipsis="..."
                                                parentClass="nft-description"
                                                className="nft-description-text">
                                                {user ? (artistsUserDetail?.bio ? artistsUserDetail?.bio : '') : (artistsUserDetail?.description ? artistsUserDetail?.description : '')}
                                            </ArtistReadMore>
                                        </Box>
                                    )}

                                </Box>
                            </Box>
                            <Box className='artist-main-tab'>
                                {artistsUserDetail &&
                                    <ArtistTab user={user} userType={userType} />
                                }
                            </Box>
                        </Container>
                    </Box>
                    <SocialShare
                        onCloseDialog={() => {
                            setShowShareDialog(false);
                        }}
                        imagefile={artistsUserDetail.profilePicture ? getUrl(`${artistsUserDetail.profilePicture}`, '1000x1000/') : 'https://e7.pngegg.com/pngimages/753/432/png-clipart-user-profile-2018-in-sight-user-conference-expo-business-default-business-angle-service-thumbnail.png'}
                        header={user ? "Collector" : "Artist"}
                        showShare={showShareDialog}
                        tooltip="Share your portfolio"
                        title={`${window.location.href} \n\n artist @${(artistsUserDetail?.name).replace(' ',
                            '')} \n at @TheUpsideSpace \n\n ${artistsUserDetail?.description?.replace(/<[^>]*>/g, '')}`}
                        link={`/${user ? 'user' : 'artist'}/${artistsUserDetail.id}`}
                    />
                </Box>

            )
            }
            {errorPage && <Box className="error-page-wrapper">
                <Box className='error-page-box'>
                    <Typography variant='h2'>Lost Your Way?</Typography>
                    <Box className='error-page-img'>
                        <img src="/images/mona-lisa.png" alt="img" />
                    </Box>
                    <Typography variant='h5'>Our artists and curators <br /> are waiting for you on the <br /> home page</Typography>
                    <Link to='/home'>
                        <Button className='error-home-btn'>VISIT HOME PAGE</Button>
                    </Link>
                </Box>
            </Box>}
        </>
    )
}

export default ArtistDetail
