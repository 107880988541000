import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Grid } from '@material-ui/core';
import { Formik } from 'formik';
import { AppPrimaryButton } from '../../../shared/components/AppComponents';
import { Utils } from '../../../shared/helpers';
import { AuthenticationService } from '../../../services';

const ResendEmailForm = (props: any): ReactElement => {
    const navigate = useNavigate();
    const { email } = props;
    const emailValue = email;
    const resendEmailForm = {
        email: emailValue,
    };

    // API Calls
    const resendemail = (formValues: any, callback: any): any => {
        AuthenticationService.resendEmail(formValues)
            .then((response: any) => {
                Utils.handleSuccessResponse(response);
                callback(true);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
                callback(false);
            });
    };

    // UI Actions
    const actionResendEmail = (formValues: any, formik: any): any => {
        resendemail(formValues, (status: boolean) => {
            formik.setSubmitting(true);
            if (status) {
                navigate('/login', { replace: true });
                formik.setSubmitting(false);
            } else {
                formik.setSubmitting(false);
            }
        });
    };

    // UI Elements
    return (
        <Box className="auth-bg">
            <Box className="auth-content auth-emailverify ">
                <Formik
                    initialValues={resendEmailForm}
                    onSubmit={(values: any, formik: any): void => {
                        actionResendEmail(values, formik);
                    }}
                >
                    {({ handleSubmit, isSubmitting }): any => (
                        <Grid container spacing={0} className="verify-content">
                            <Box className="email-confirmation-layout">
                                <Grid item xs={12}>
                                    <form
                                        onSubmit={handleSubmit}
                                        autoComplete="off"
                                    >
                                        {/* <Grid container justifyContent="center">
                                            <img
                                                alt="Email Logo"
                                                src="/images/email-confirmation.jpg"
                                                className="verification-logo-image"
                                                width="100"
                                                height="100"
                                            />
                                        </Grid> */}
                                        {/* <Box className='email-verify'>
                                            <Box className='email-left'>
                                                <Typography className='email-none'>a</Typography>
                                            </Box>
                                            <Box className='email-right'>
                                                <Typography className='email-none'>a</Typography>
                                            </Box>
                                        </Box> */}
                                        <Box className='email-verify-icon'>
                                            <img src="/images/email-reverify.svg" />
                                        </Box>
                                        <Box className="title-content verify-email-name">
                                            <Typography
                                                className="auth-main-title"
                                                variant="h3"
                                            >
                                                Email Verification
                                            </Typography>
                                            {/* <Divider className='auth-divider' /> */}

                                        </Box>

                                        <Typography
                                            align="center"
                                            variant="body1"
                                            className="center-content email-verify-text-line"
                                        >
                                            To confirm your email, you just have to click on the link/url we have sent to your email.
                                        </Typography>

                                        <Box className="center-content backbtn-content email-verify-btn">
                                            <AppPrimaryButton
                                                title="Resend mail"
                                                isLoading={isSubmitting}
                                                loadingText="SENDING"
                                                onClick={handleSubmit}
                                                className="flex backBtn auth-label"
                                            />
                                        </Box>
                                    </form>
                                </Grid>
                            </Box>
                        </Grid>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

export default ResendEmailForm;
