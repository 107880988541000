import { Box, CircularProgress, Grid } from '@material-ui/core';
import { useEffect, useState, ReactElement } from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import CollectionsIcon from '@material-ui/icons/Collections';
import { DefaultPagination } from '../../../services';
import { CollectionService } from '../../../services/Collection';
import { EmptyPlaceholder, SlideNextArrow, SlidePrevArrow } from '../../../shared/components';
import { GradientVariants } from '../../../shared/components/GradientChip';
import { Utils } from '../../../shared/helpers';
import { Celebrity } from '../../../shared/models/CelebrityModel';
import { AppState } from '../../../state/RootReducer';
import NFTItem, { NFTInfo } from './NFTItem';
import '../../../assets/styles/App.scss';
import CuratingLoading from '../../../shared/components/curatingLoading';

export interface NFTDetail {
    nftImage: string;
    avatar: string;
    variant: GradientVariants;
    showStock: boolean;
    popularity: string;
}
const ImageSliderConfigNFT = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    arrows: true,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SlideNextArrow />,
    prevArrow: <SlidePrevArrow />,
    responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            dots: true
          }
        },
        {
          breakpoint: 969,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }

  export interface NFTLISTPROPS {
    marketPlaceFilter?:any;
    trending?:boolean;
    arts?:any;
    loader?:boolean;
    setLoader?:any;
 }   

const NFTList = (props:NFTLISTPROPS): ReactElement => {
    // Instance Vairable
    const { marketPlaceFilter ,trending,arts} = props;
    const [items, setItems] = useState<NFTInfo[]>([]);
    const [loader, setLoader] = useState(true);
    const [pagination, setPagination] = useState({ ...DefaultPagination(12) });
    const [, setHasMoreItems] = useState(false);

    // Instance Methods
    const buildCelebrityIds = (): string => {
        const ids: string[] = [];
        marketPlaceFilter.celebrities.forEach((celebrity: Celebrity) => {
            ids.push(celebrity.id);
        });
        return ids.toString();
    };

    // API Calls
    const loadItems = (): void => {
        if (pagination.lastOffset !== pagination.offset && !trending) {
            pagination.lastOffset = pagination.offset;
            setLoader(true);

            CollectionService.getMarketPlaceItems(
                pagination.limit,
                pagination.offset,
                'recent',
                marketPlaceFilter.category,
                '',
                marketPlaceFilter.minPrice,
                marketPlaceFilter.maxPrice,
                marketPlaceFilter.buy,
                marketPlaceFilter.newItem,
                marketPlaceFilter.onAuction,
                marketPlaceFilter.hasOffer,
                marketPlaceFilter.notLaunched,
                buildCelebrityIds(),
                marketPlaceFilter.blockChain.length === 2 ||
                    marketPlaceFilter.blockChain.length === 0
                    ? 'all'
                    : marketPlaceFilter.blockChain[0],
                marketPlaceFilter.region,
                marketPlaceFilter.exhibition,
                'all',
                'false',
                '',
                false,
                'all',
                marketPlaceFilter.allArts

            )
                .then((response: any) => {
                    const newItems = response.data.data.items;
                    const paginationResponse = response.data.data.pagination;
                    setItems(
                        pagination.offset === 1
                            ? newItems
                            : items.concat(newItems)
                    );
                    setHasMoreItems(Utils.hasMoreRecords(paginationResponse));
                    setLoader(false);
                    setPagination({
                        ...paginationResponse,
                        lastOffset: pagination.lastOffset,
                        offset: pagination.offset + 1,
                    });
                })
                .catch((error: any) => {
                    setLoader(false);
                    Utils.handleErrorResponse({ error });
                });
        }else{
                setItems(arts);
                setLoader(false);
        }
    };

    // Business Logic
    const reloadItems = (): void => {
        pagination.offset = 1;
        pagination.lastOffset = 0;
        setItems([]);
        loadItems();
    };
    useEffect(() => {
        reloadItems();
    }, [marketPlaceFilter ,arts]);

    // UI Elements
    return (
        <Box className='market-slider'>
            {items.length !== 0 && (
                  
                <Grid container xs={12} className="main-my-grid-wrapper">
                    <Slider className='nft-slider' {...ImageSliderConfigNFT}>
                    {items.map((nftItem: NFTInfo) => (
                        <Grid
                        className='my-grid'
                            key={nftItem.id}
                        >
                            <NFTItem
                                {...{ ...nftItem, className: 'market-nft' }}
                            />
                        </Grid>
                    ))}
                     </Slider>
                </Grid>
               
            )}
            {!loader && items.length === 0 && (
              <EmptyPlaceholder
               icon={<img src="/icons/NoData.png" />}
              />
            )}
            {loader && <CuratingLoading />}
        </Box>
    );
};

const mapStateToProps = (state: AppState): any => ({
    marketPlaceFilter: state.marketPlaceFilter,
});

export default connect(mapStateToProps, null)(NFTList);
