import { ReactElement } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SecurityVerificationForm } from './components';
import { HeadTag } from '../../shared/components';
import { LocationModal } from '../../shared/models/StateModal';

const SecurityVerificationPage = (props: any): ReactElement => {
    const location = useLocation();
    const navigate = useNavigate();
    const { tokenId } = location.state as LocationModal;
    return (
        <>
            <HeadTag title="Verify 2FA" />
            <SecurityVerificationForm
                tokenId={tokenId || navigate('../')}
                {...props}
            />
        </>
    );
};

export default SecurityVerificationPage;
