import { Box, Grid } from '@material-ui/core';
import { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import VideoLoader from './VideoLoader';

const Video = (props: any): any => {
    const [load, steLoaded] = useState(false);

    const {
        video,
        pip,
        playing,
        controls,
        volume,
        muted,
        loop,
        setPlaying,
        showVideoAudioMessage,
        currentSlide,
        currnetIndex,
        shown,
        setShown,
    } = props;

    const loadedvideo = (): any => {
        steLoaded(true);
    };

    useEffect(() => {
        try {
            fetch(video).then((res) => {
                res.blob().then(async (blob) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    const sampleRate = 16000;
                    let myBuffer;
                    const numberOfChannels = 1;
                    const data = await blob.arrayBuffer();

                    const audioContext = new window.AudioContext();
                    audioContext
                        .decodeAudioData(data)
                        .then((decodedAudioData) => {
                            const { duration } = decodedAudioData;

                            const offlineAudioContext = new OfflineAudioContext(
                                numberOfChannels,
                                sampleRate * duration,
                                sampleRate
                            );
                            const soundSource =
                                offlineAudioContext.createBufferSource();

                            myBuffer = decodedAudioData;
                            soundSource.buffer = myBuffer;
                            soundSource.connect(
                                offlineAudioContext.destination
                            );
                            soundSource.start();

                            offlineAudioContext
                                .startRendering()
                                .then(() => {
                                    if (
                                        currnetIndex === currentSlide ||
                                        !shown
                                    ) {
                                        if (!shown) {
                                            showVideoAudioMessage();
                                            setShown(true);
                                        }
                                    }
                                })
                                .catch((err) => {
                                    console.error('err1', err);
                                });
                        })
                        .catch((err) => {
                            console.error('err2', err);
                        });
                });
            });
        } catch (err) {
            console.error('err3', err);
        }
    }, [currnetIndex, currentSlide]);

    return (
        <Box width="100%" display="flex" justifyContent="center">
            <Grid item sm={12} md={8} lg={8} className="main-video-Wrapper">
                <Box
                    style={{
                        display: !load ? 'none' : 'block',
                    }}
                    width="100%"
                    height="100%"
                >
                    <ReactPlayer
                        className="react-player"
                        url={video}
                        width="100%"
                        // changed this auto remove unneccessary padding on mobile
                        height="auto"
                        // added this for potrait videos
                        maxHeight="50vh"
                        pip={pip}
                        playing={playing}
                        controls={controls}
                        config={{
                            file: {
                                attributes: { controlsList: 'nodownload' },
                            },
                        }}
                        onContextMenu={(e: any) => e.preventDefault()}
                        volume={volume}
                        muted={muted}
                        onReady={loadedvideo}
                        loop={loop}
                    />
                </Box>
                {!load && <VideoLoader />}
            </Grid>
        </Box>
    );
};
export default Video;
