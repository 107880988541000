import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { Activity } from './modules/activity';
import {
    NFTPageWrapper,
    NFTDetailPage,
    // LandNFTDetailPage,
    CreationWrapper,
    NFTSellInitiatePage,
} from './modules/creations';
import { HomePage } from './modules/home';
import { MarketPlace } from './modules/marketplace';
import PageWrapper from './modules/PageWrapper';
import { DesignBook } from './shared/components';
import {
    CreateWalletPage,
    SignUpPage,
    SignupMetaMaskPage,
    LoginPage,
    EmailVerifiedPage,
    ResendEmailPage,
    ForgotPasswordPage,
    ResetPasswordPage,
    SecurityVerificationPage,
} from './modules/authentication';
import CreationDetailPage from './modules/creations/CreationDetailPage';
import {
    PortfolioCreationPage,
    PortfolioNftPage,
    PortfolioPage,
    PortfolioCreatedPage,
    PortfolioOnSalePage,
} from './modules/portfolio';
import {
    ProfilePage,
    ProfileWrapper,
    ChangePasswordPage,
    FinancialTransactionPage,
    WithdrawRequestPage,
    ExportHistoryPage,
    NFTRequestPage,
    ImportNFTPage,
    WalletPage,
    CreationsPage,
    MyOffersPage,
    MyFavoritePage,
} from './modules/profile';
import MyNFTsPage from './modules/profile/MyNFTsPage';
import {
    AboutPage,
    ContactPage,
    CareerPage,
    PrivacyPolicyPage,
    FAQPage,
    TermsPage,
    HelpPage,
    NewsRoom,
    VikramPage,
} from './modules/marketting';
import {
    LandWrapper,
    LandDetailsPage
} from './modules/lands'
import EmailVerificationPage from './modules/authentication/EmailVerificaionPage';
import { ArtistDetail } from './modules/ArtistDetail';
import { ExhibitionDetail } from './modules/Exhibitions';
import { Community } from './modules/community';
import NotFound from './shared/components/NotFound';
import { CuratorsDetails } from './modules/CuratorDetail';


const checkAuth = (isUserLoggedIn: boolean, component: any): ReactElement => {
    if (isUserLoggedIn) {
        return component;
    }
    return <Navigate to="/home" />;
};

const emailCheck = (emailId: string, component: any): ReactElement => {
    const regex = new RegExp('[a-z0-9]+@fantico.io');
    if (regex.test(emailId)) {
        return component;
    }
    return <Navigate to="/home" />;
};

const routes = (isUserLoggedIn: boolean, emailId: string): any => [
   
    {
        path: '/',
        element: <PageWrapper />,
        children: [
            {
                path: 'login',
                element: isUserLoggedIn ? (
                    <Navigate to="/home" />
                ) : (
                    <LoginPage />
                ),
            },
           
            { path: 'signup', element: <SignUpPage /> },
            { path: 'forgot-password', element: <ForgotPasswordPage /> },
            { path: 'reset-password/:id', element: <ResetPasswordPage /> },
            { path: 'email-confirmation', element: <ResendEmailPage /> },
            { path: 'verify-email/:id', element: <EmailVerifiedPage /> },
            {
                path: 'security-verification',
                element: <SecurityVerificationPage />,
            },
            {
                path: 'email-verification',
                element: <EmailVerificationPage />
            },
            { path: 'metamask-signup', element: <SignupMetaMaskPage /> },
            { path: 'create-wallet', element: <CreateWalletPage /> },
            {
                path: 'marketplace',
                element: <MarketPlace />,
                children: [
                    {
                        path: ':artwork',
                        element: <MarketPlace />,
                    },
                    {
                        path: ':artist',
                        element: <MarketPlace />,
                    },
                    {
                        path: ':exhibition',
                        element: <MarketPlace />,
                    },
                    {
                        path: ':curator',
                        element: <MarketPlace />,
                        // children: [
                        //     { path: 'curatordetails', element: <CuratorsDetails /> },
                        // ]
                    },
                    {
                        path: '',
                        element: <Navigate to="artwork" />,
                    },
                ]
            },
            {
                path: 'activity',
                element: emailCheck(emailId, <Activity />),
            },
            { path: 'about', element: <AboutPage /> },
            { path: 'contact', element: <ContactPage /> },
            { path: 'career', element: <CareerPage /> },
            { path: 'privacy', element: <PrivacyPolicyPage /> },
            { path: 'terms', element: <TermsPage /> },
            { path: 'faq', element: <HelpPage /> },
            { path: 'faq/:slug', element: <FAQPage /> },
            { path: 'newsroom', element: <NewsRoom /> },
            { path: 'vikram', element: <VikramPage /> },
            { path: 'home', element: <HomePage /> },
            {
                path: 'curator/:userId',
                element:  <CuratorsDetails />,
            },
            { path: 'community', element: <Community /> },
            {
                path: 'artist/:userId',
                element:  <ArtistDetail user={false}/>,
            },
            {
                path: 'exhibition/:exhibitionId',
                element:  <ExhibitionDetail />,
            },            
            {
                path: 'item',
                element: <NFTPageWrapper />,
                children: [
                    {
                        path: ':itemId',
                        element: <NFTDetailPage />,
                    },
                ],
            },
            { path: 'style', element: <DesignBook /> },
            {
                path: '',
                element: <Navigate to="/home" />,
            },
            {
                path: 'creation',
                element: <CreationWrapper />,
                children: [
                    {
                        path: ':collectionId',
                        element: <CreationDetailPage />,
                    },
                    {
                        path: 'item/sell/:itemId',
                        element: checkAuth(
                            isUserLoggedIn,
                            <NFTSellInitiatePage />
                        ),
                    },
                ],
            },
            {
                path: 'user/:userId',
                element: <ArtistDetail user />,
                // children: [
                //     {
                //         path: 'creations',
                //         element: <PortfolioCreationPage />,
                //     },
                //     {
                //         path: 'nfts',
                //         element: <PortfolioNftPage />,
                //     },
                //     {
                //         path: 'created',
                //         element: <PortfolioCreatedPage />,
                //     },
                //     {
                //         path: 'on-sale',
                //         element: <PortfolioOnSalePage />,
                //     },

                //     {
                //         path: '',
                //         element: <Navigate to="creations" />,
                //     },
                // ],
            },

            {
                path: 'profile',
                element: checkAuth(isUserLoggedIn, <ProfileWrapper />),
                children: [
                    {
                        path: 'view',
                        element: <ProfilePage />,
                    },
                    {
                        path: 'change-password',
                        element: <ChangePasswordPage />,
                    },
                    {
                        path: 'withdraw-requests',
                        element: <WithdrawRequestPage />,
                    },
                    {
                        path: 'financial-transactions',
                        element: <FinancialTransactionPage />,
                    },
                    {
                        path: 'owned-nfts',
                        element: <MyNFTsPage />,
                    },
                    {
                        path: 'export-history',
                        element: <ExportHistoryPage />,
                    },
                    {
                        path: 'import-nft',
                        element: <ImportNFTPage />,
                    },
                    {
                        path: 'nft-request',
                        element: <NFTRequestPage />,
                    },
                    {
                        path: 'wallet',
                        element: <WalletPage />,
                    },
                    {
                        path: 'creations',
                        element: <CreationsPage />,
                    },
                    {
                        path: 'offers',
                        element: <MyOffersPage />,
                    },
                    {
                        path: 'favorites',
                        element: <MyFavoritePage />,
                    },
                    {
                        path: '',
                        element: <Navigate to="profile" />,
                    },
                ],
            },
            {
                path: "land",
                element: <LandWrapper />,
                children: [
                    {
                        path: 'details',
                        element: <LandDetailsPage />,
                    },
                ]
            },
            {
                path:"*",
                element:<NotFound/>
            },

            {
                path:"notfound",
                element:<NotFound/>
            },
            // {
            //     path: 'land-item',
            //     element: <NFTPageWrapper />,
            //     children: [
            //         {
            //             path: ':itemId',
            //             element: <LandNFTDetailPage />,
            //         },
            //     ],
            // },

           
        ], 
    },

];

export default routes;
