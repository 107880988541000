// Reference
// https://material-ui.com/customization/globals/
// https://material-ui.com/api/button-base/
// https://material-ui.com/api/button/

export default {
    root: {
        fontSize: '14px',
    },
};
