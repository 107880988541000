import update from 'immutability-helper';
import { Celebrity } from '../../shared/models/CelebrityModel';
import { MarketFilterReduxActions } from './Action';

export interface MarketFilterState {
    regionList: [];
    exhibitionList: [];
    min: string,
    max: string
}

export const defaultMarketFilterState: MarketFilterState = {
    regionList: [],
    exhibitionList: [],
    min: '0',
    max: '2000',
}

const MarketFilterReducer = (
    state = defaultMarketFilterState, 
    action: any
): MarketFilterState => {
    const { payload } = action;

    switch(action.type) {
        case MarketFilterReduxActions.SET_REGION_LIST: 
            return update(state, {
                $merge: {
                    regionList: payload
                }
            })
        case MarketFilterReduxActions.SET_EXHIBITION_LIST: 
            return update(state, {
                $merge: {
                    exhibitionList: payload
                }
            })
        case MarketFilterReduxActions.SET_MIN_MAX: 
            return update(state, {
                $merge: {
                    ...payload,
                }
            })
        default:
            return state;
    }
}

export default MarketFilterReducer;