import { ReactElement, useEffect, useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    Typography,
    Grid,
    IconButton,
    Divider,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
// import LocationOnIcon from '@material-ui/icons/LocationOn';
import AddressManagementForm from './AddressManagementForm';
import { Utils } from '../../../shared/helpers';
import { ProfileService, DefaultPagination } from '../../../services';
import { AppTeritaryButton } from '../../../shared/components/AppComponents';

interface AddressInfo {
    name: string;
    line1: string;
    line2: string;
    city: string;
    state: string;
    country: string;
    contactNumber: string;
    postcode: string;
    userId: string;
    id: string;
}

const AddressManagementList = (): ReactElement => {
    const [addressList, setAddressList] = useState<AddressInfo[]>([]);
    const [pagination, setPagination] = useState({
        ...DefaultPagination(10),
    });
    const [loader, setloader] = useState(false);
    const [showAddressFormDialog, setShowAddressFormDialog] = useState(false);
    const [addressInfoToEdit, setAddressInfoToEdit] = useState<
        AddressInfo | undefined
    >(undefined);
    const [modalFormType, setModalFormType] = useState('');

    // API Calls
    const loadAddressList = (): any => {
        setloader(true);
        ProfileService.getAddressList(pagination.limit, pagination.offset)
            .then((response: any) => {
                const { address } = response.data.data;
                const paginationResponse = response.data.data.pagination;
                setAddressList(address);
                setPagination(paginationResponse);
                setloader(false);
            })
            .catch((error: any) => {
                setloader(false);
                Utils.handleErrorResponse({
                    error,
                });
            });
    };

    const actionDeleteAddress = (id: string): any => {
        setloader(true);
        ProfileService.deleteAddress(id)
            .then((response: any) => {
                setloader(false);
                Utils.handleSuccessResponse(response);
                loadAddressList();
            })
            .catch((error: any) => {
                setloader(false);
                Utils.handleErrorResponse({ error });
            });
    };

    // Hooks
    useEffect(() => {
        loadAddressList();
    }, []);

    const actionCancel = (): void => {
        setShowAddressFormDialog(false);
        setModalFormType('');
        loadAddressList();
    };

    const actionOnAddAddress = (): void => {
        setModalFormType('ADD');
        setShowAddressFormDialog(true);
        setAddressInfoToEdit(undefined);
    };

    const actionOpenEditAddress = (addressDetails: any): any => {
        setModalFormType('Edit');
        setShowAddressFormDialog(true);
        setAddressInfoToEdit(addressDetails);
    };

    const getAddress = (address: string): string => {
        if(address.length > 40){
            return `${address.slice(0,40)} ...`;
        }
        return address;

    }

    // UI Elements
    return (
        <>
            <Box className="profile-card">
                <Box className="card-header-btn">
                    <Typography className="title" variant="h3">
                        Address Management
                    </Typography>

                    {/* <AppTeritaryButton
                        title="ADD NEW"
                        className="btn-small"
                        startIcon={<AddIcon />}
                        onClick={actionOnAddAddress}
                    /> */}
                </Box>
                {/* <Divider className='account-devider' /> */}
                {addressList.length === 0 && (
                     <Box className='adress-sub-wrapper'>
                     <Box className='address-line'>
                     {/* {addressList.length === 0 && (
                         <> */}
                             <Typography variant="body2" className="subtitle">
                                 Add your residential address.
                             </Typography>
                             
                         {/* </>
                     )} */}
                     </Box>
                     <Box className="adress-data">
                     {/* {addressList.length === 0 && (
                         <> */}
                         <Typography
                                 variant="body1"
                                 className="matter-fontfamily small-line"
                                 id="textsmall"
                             >
                                 You have not added your address yet.
                             </Typography>
                         <AppTeritaryButton
                                 title="Add new"
                                 className="primary-btn addressBtn"
                                 // startIcon={<AddIcon />}
                                 onClick={actionOnAddAddress}
                             />
                             {/* </>
                             
                         )} */}
                         </Box>
                       </Box>
                )}
               
                
                    {loader === false && addressList.length > 0 && (
                        <Grid container>
                            {addressList.map((address) => (
                                <Grid key={address.id} item xs={12} xl={12} className="address-data-box"> 
                                    <Card>
                                        <CardContent className="profile-card">
                                            <Box className="space-content">
                                                <Typography
                                                    variant="body1"
                                                    className="align-center"
                                                >
                                                    Home
                                                </Typography>

                                                <Box>
                                                    <IconButton
                                                        onClick={() => {
                                                            actionDeleteAddress(
                                                                address.id
                                                            );
                                                        }}
                                                    >
                                                        <DeleteOutlineIcon
                                                            color="primary"
                                                            fontSize="small"
                                                        />
                                                    </IconButton>

                                                    <IconButton
                                                        onClick={() => {
                                                            actionOpenEditAddress(
                                                                address
                                                            );
                                                        }}
                                                    >
                                                        <EditIcon
                                                            color="primary"
                                                            fontSize="small"
                                                        />
                                                    </IconButton>
                                                </Box>
                                            </Box>

                                            {/* <Box mt={2}>
                                                <Typography variant="body1" className='adress-data-text'>
                                                    <p>
                                                    <b>
                                                        {Utils.titleCase(
                                                            address.name
                                                        )}
                                                    </b>
                                                    </p>
                                                    <p>{address.line1} </p>
                                                    <p> {address.line2} </p>
                                                    <p>{address.city}</p>
                                                   
                                                    <p> {address.state}{' '}</p>
                                                    <p> {address.postcode} </p>
                                                    <p> {address.country} </p>
                                                    <p> {address.contactNumber}{' '}</p>
                                                   
                                                </Typography>
                                            </Box> */}

                                            <Box mt={2}>
                                                <TableContainer>
                                                    <Table className="profile-card-table">
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell
                                                                    component="th"
                                                                    scope="row"
                                                                    className="w-100"
                                                                >
                                                                   Name :
                                                                </TableCell>
                                                                <TableCell align="left">
                                                        {Utils.titleCase(
                                                            address.name
                                                        )}
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell
                                                                    component="th"
                                                                    scope="row"
                                                                    className="w-100"
                                                                >
                                                                  Address - Line 1 :
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                 {/* {address.line1} */}
                                                                 {address.line1 && getAddress(address.line1)}
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell
                                                                    component="th"
                                                                    scope="row"
                                                                    className="w-100"
                                                                >
                                                                   Address - Line 2 :
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                 {/* {address.line2} */}
                                                                 {address.line2 && getAddress(address.line2)}
                                                                </TableCell>
                                                            </TableRow>
                                                           
                                                                <TableRow>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        className="w-100"
                                                                    >
                                                                       City :
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                    {/* {address.city} */}
                                                                    {address.city && getAddress(address.city)}
                                                                    </TableCell>
                                                                </TableRow>

                                                                <TableRow>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        className="w-100"
                                                                    >
                                                                        State :
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                    {/* {address.state}{' '} */}
                                                                    {address.state && getAddress(address.state)}{' '}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        className="w-100"
                                                                    >
                                                                        Country :
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                    {/* {address.country}{' '} */}
                                                                    {address.country && getAddress(address.country)}                                                                   
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        className="w-100"
                                                                    >
                                                                        Phone Number :
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                    {address.contactNumber}{' '}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        className="w-100"
                                                                    >
                                                                        Post Code :
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                    {address.postcode}                                                                   
                                                                    </TableCell>
                                                                </TableRow>

                                                        
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    )}

                    
                
               
              
            </Box>

            {showAddressFormDialog && (
                <AddressManagementForm
                    modalFormtype={modalFormType}
                    showAddressFormDialog={showAddressFormDialog}
                    actionCancel={actionCancel}
                    addressList={addressList}
                    addressInfoToEdit={addressInfoToEdit}
                />
            )}
        </>
    );
};

export default AddressManagementList;
