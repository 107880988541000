import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { EmailVerifiedForm } from './components';
import { HeadTag } from '../../shared/components';

const EmailVerifiedPage = (): ReactElement => {
    const { id } = useParams();

    // UI Elements
    return (
        <>
            <HeadTag title="Email Verified" />
            <EmailVerifiedForm id={id} />
        </>
    );
};

export default EmailVerifiedPage;
