import { ReactElement, useState, useEffect, useRef } from 'react';
import { Box, Typography, InputLabel, Grid, Divider } from '@material-ui/core';
import FingerprintOutlinedIcon from '@material-ui/icons/FingerprintOutlined';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import KYCDocumentPreview from './KYCDocumentPreview';
import { ProfileState } from '../../../state/profile/Reducer';
import { AppState } from '../../../state/RootReducer';
import { Utils, Toast } from '../../../shared/helpers';
import {
    AppSelect,
    AppPrimaryButton,
} from '../../../shared/components/AppComponents';
import ProfileEmptyPlaceholder from '../../../shared/components/ProfileEmptyPlaceHolder';
import { ProfileService, AuthenticationService } from '../../../services';
import { Proofs, AddressProofs } from '../../../services/Profile';
import ImageCropper from '../../../shared/components/ImageCropper'
import { AppPreference } from '../../../shared/managers';

export interface ViewProfileProps {
    profileInfo: ProfileState;
}

const KYCVerifyForm = (props: any): ReactElement => {
    const { profileInfo } = props;
    const [kycStatus, setKycStatus] = useState('');
    const [isLoading, setLoading] = useState(false);

    const [idOne, setIdOne] = useState('');
    const [idTwo, setIdTwo] = useState('');
    const [addressOne, setAddressOne] = useState('');

    const [idProofOne, setIdProofOne] = useState('');
    const [idProofTwo, setIdProofTwo] = useState('');
    const [addressProof, setAddressProof] = useState('');
    const [profileFile, setProfileFile] = useState('');

    const [preProfile, setPreProfile] = useState('');
    const [preProofOne, setPreProofOne] = useState('');
    const [preProofTwo, setPreProofTwo] = useState('');
    const [preAddressProof, setPreAddressProof] = useState('');
    const [previewDocument, setPreviewDocument] = useState({
        kycStatus: '',
        profileUrl: '',
        idProofOne: '',
        idProofOneUrl: '',
        idProofTwo: '',
        idProofTwoUrl: '',
        addressProof: '',
        addressProofTUrl: '',
    });
    const profileImageInput = useRef<HTMLInputElement>(null);
    const [imageToCrop, setImageToCrop] = useState(undefined);
    const [showCropper, setShowCropper] = useState(false);
    const [fileIdentifier, setFileIdentifier] = useState('');
    const [cropRatio, setCropRatio] = useState(1);

    // API Calls
    const getKYCDetails = (): void => {
        ProfileService.getKyc()
            .then(async (response) => {
                const Response = response.data.data;
                const { photo, document1, document2, document3 } = Response;
                setKycStatus(Response.kycStatus);
                if (
                    Response.kycStatus === 'verified' ||
                    Response.kycStatus === 'submitted'
                ) {
                    setPreviewDocument({
                        kycStatus: Response.kycStatus,
                        profileUrl: photo,
                        idProofOne: document1.identityType,
                        idProofOneUrl: document1.url,
                        idProofTwo: document2.identityType,
                        idProofTwoUrl: document2.url,
                        addressProof: document3.identityType,
                        addressProofTUrl: document3.url,
                    });
                }
            })
            .catch();
    };

    const kycverify = (data: any): any => {
        ProfileService.uploadKyc(data)
            .then((response: any) => {
                setLoading(false);
                getKYCDetails();
                Utils.handleSuccessResponse(response);
            })
            .catch((error: any) => {
                setLoading(false);
                Utils.handleErrorResponse({ error });
            });
    };

    // const kycAuthLogin = (): any => {
    //     AuthenticationService.kycLogin()
    //         .then((response: any) => {
    //             console.log(response.data)
    //             const { data } = response.data

    //             console.log("data: ", data.result.token)
    //             AppPreference.setKycToken(data.result.token)
    //         })
    //         .catch((error: any) => {
    //             Utils.handleErrorResponse({ error })
    //         })
    // }

    const autoKycSuccess = (transactionId: any): any => {
        const requestObj = {
            transactionId
        }
        ProfileService.autoKycSuccess(requestObj)
            .then((response: any) => {
                setLoading(false);
                Utils.handleSuccessResponse(response);
                getKYCDetails();
            })
            .catch((error: any) => {
                setLoading(false);
                Utils.handleErrorResponse({ error });
            });
    };

    const actionKycFailure = (transactionId: string, reason: string, status: string): any => {
        const requestObj = {
            transactionId,
            reason,
            status
        }
        ProfileService.autoKycFailure(requestObj)
            .catch((error: any) => {
                Utils.handleErrorResponse({ error })
            })
    }

    // Hooks
    useEffect(() => {
        getKYCDetails();
        // kycAuthLogin()

        // const workflow = ['DOCUMENT', 'FACE'];
        // const hyperKycConfig = new (window as any).HyperKycConfig("", workflow, "");
        // console.log(hyperKycConfig)
    }, []);

    // UI Actions

    const updateKyc = (): void => {
        setLoading(true);
        AuthenticationService.kycLogin()
            .then((response: any) => {
                const { data } = response.data;

                AppPreference.setKycToken(data.result.token);

                const workflow = [new (window as any).Document(), new (window as any).Face()];
                const hyperKycConfig = new (window as any).HyperKycConfig(
                    data.result.token,
                    workflow,
                    profileInfo.userId,
                    ''
                );
                const handler = (HyperKycResult: any): any => {
                    if (HyperKycResult.Cancelled) {
                        setLoading(false);
                        Toast.showErrorMessage(
                            'You cancelled the kyc verification please try again.'
                        );
                        actionKycFailure(HyperKycResult?.Cancelled?.data?.config?.transactionId, "Cancelled by the user", "cancelled")
                    } else if (HyperKycResult.Failure) {
                        setLoading(false);
                        Toast.showErrorMessage(
                            HyperKycResult?.Failure?.reason ?
                                HyperKycResult?.Failure?.reason :
                                'Kyc verification failed please try again.'
                        );
                        actionKycFailure(HyperKycResult?.Failure?.data?.config?.transactionId, HyperKycResult?.Failure?.reason, "failure")
                    } else if (HyperKycResult.Success) {
                        autoKycSuccess(HyperKycResult?.Success?.data?.config?.transactionId);
                    }
                };

                (window as any).HyperKYCModule.launch(hyperKycConfig, handler);
            })
            .catch((error: any) => {
                Utils.handleErrorResponse({ error });
            });
    };
    const actionKYCVerify = (event: any): any => {
        event.preventDefault();
        const data = new FormData();
        setLoading(true);

        data.append('image', profileFile);
        data.append('identity1', idProofOne);
        data.append('identity2', idProofTwo);
        data.append('identity3', addressProof);
        data.append('identityType1', idOne);
        data.append('identityType2', idTwo);
        data.append('identityType3', addressOne);
        kycverify(data);
    };

    const onChangeIdOne = (event: any): void => {
        setIdOne(event.target.value as string);
    };
    const onChangeIdTwo = (event: any): void => {
        setIdTwo(event.target.value as string);
    };

    const onChangeIdAddressProof = (event: any): void => {
        setAddressOne(event.target.value as string);
    };

    const setEmpty = (value: string): any => {
        switch (value) {
            case 'profile':
                setProfileFile('');
                setPreProfile('');
                break;
            case 'proof1':
                setIdProofOne('');
                setPreProofOne('');
                break;
            case 'proof2':
                setIdProofTwo('');
                setPreProofTwo('');
                break;
            case 'addressproof':
                setAddressProof('');
                setPreAddressProof('');
                break;
            default:
                break;
        }
    };

    const actionFileUpload = (event: any, value: any): any => {
        const file = event.target.files[0];
        if (file) {
            const isValidFile =
                value === 'profile'
                    ? file.type === 'image/png' || file.type === 'image/jpeg'
                    : file.type === 'image/png' ||
                    file.type === 'image/jpeg' ||
                    file.type === 'application/pdf';
            const allowedFileTypes =
                value === 'profile' ? 'JPEG and PNG' : 'JPEG,PNG and PDF';
            if (isValidFile) {
                const size: any = (file.size / 1024 / 1024).toFixed(2);
                if (size <= 2) {
                    switch (value) {
                        case 'profile':
                            // setProfileFile(file);
                            setImageToCrop(file);
                            setShowCropper(true);
                            setFileIdentifier(value);
                            setCropRatio(1);
                            break;
                        case 'proof1':
                            setIdProofOne(file);
                            break;
                        case 'proof2':
                            setIdProofTwo(file);
                            break;
                        case 'addressproof':
                            setAddressProof(file);
                            break;
                        default:
                            break;
                    }

                    const reader = new FileReader();
                    let preFileTemp: any = '';
                    reader.onload = () => {
                        preFileTemp = reader.result;
                        switch (value) {
                            case 'profile':
                                // setPreProfile(preFileTemp);
                                break;
                            case 'proof1':
                                if (file.type === 'application/pdf') {
                                    setPreProofOne('/images/pdf-icon.png');
                                } else {
                                    setPreProofOne(preFileTemp);
                                }
                                break;
                            case 'proof2':
                                if (file.type === 'application/pdf') {
                                    setPreProofTwo('/images/pdf-icon.png');
                                } else {
                                    setPreProofTwo(preFileTemp);
                                }
                                break;

                            case 'addressproof':
                                if (file.type === 'application/pdf') {
                                    setPreAddressProof('/images/pdf-icon.png');
                                } else {
                                    setPreAddressProof(preFileTemp);
                                }
                                break;
                            default:
                                break;
                        }
                    };
                    reader.readAsDataURL(file);
                } else if (size > 2) {
                    toast.error(
                        'File too Big, please select a file less than 2MB'
                    );
                    setEmpty(value);
                }
            } else {
                toast.error(
                    `Uploaded file is not a valid identity, Only ${allowedFileTypes} files are allowed`
                );
            }
        }
    };

    const isValidForm = (): any =>
        profileFile !== '' &&
        idProofOne !== '' &&
        idProofTwo !== '' &&
        addressProof !== '' &&
        idOne !== '' &&
        idOne !== 'Choose' &&
        idTwo !== '' &&
        idTwo !== 'Choose' &&
        addressOne !== '' &&
        addressOne !== 'Choose';

    // UI Elements
    const VerificationFailed = (): any => (
        <ProfileEmptyPlaceholder
            icon={<FingerprintOutlinedIcon className="text-secondary" />}
            message="Your KYC Verification is rejected.  Please submit again."
        />
    );

    const identityProofOne = Proofs.filter(
        (identity) => identity.value !== idTwo
    );

    const identityProofTwo = Proofs.filter(
        (identity) => identity.value !== idOne
    );

    const onCropperClose = (): void => {
        setShowCropper(false);
        setImageToCrop(undefined);
    };

    const onCropFinished = (
        croppedInfo: any
    ): any => {
        setProfileFile(croppedInfo.croppedFile)
        setPreProfile(croppedInfo.croppedImageUrl);
        setShowCropper(false);
        setImageToCrop(undefined);
    };

    return (
        <Box className="profile-card">
            <ImageCropper
                fileIdentifier={fileIdentifier}
                cropRatio={cropRatio}
                showCropper={showCropper}
                inputFile={imageToCrop}
                onCropperClose={onCropperClose}
                onCropFinished={onCropFinished}
                showImageUpload={false}
            />
            <Box className="card-header">
                <Typography className="title" variant="h3">
                    KYC Verification
                </Typography>
            </Box>
            {kycStatus === 'verified' ? (
                <Typography variant="body1" className="subtitle kyc-subline">
                    Your KYC is verified
                </Typography>
            ) : (
                <Typography variant="body1" className="subtitle kyc-subline" style={{ marginBottom: "10px" }} >
                    Complete your KYC to become a verified User.
                </Typography>
            )}
            {kycStatus === 'submitted' && (
                <KYCDocumentPreview
                    previewDocument={previewDocument}
                    message="Verification is Pending"
                />
            )}
            {kycStatus === 'rejected' && <VerificationFailed />}
            {kycStatus === 'verified' && (
                <KYCDocumentPreview
                    previewDocument={previewDocument}
                    message="KYC is Verified."
                />
            )}

            {(kycStatus === 'notSubmitted' || kycStatus === 'rejected') && (
                <>
                    <Box className='kyc-main-box-wrapper'>
                        <Box className='kyc-box-wrapper'>
                            <Grid md={12} lg={5} xl={5} className='kyc-one'>
                                <Box className='kyc-selfie-box'>
                                    <Box className="upload-wrapper">
                                        <div className="upload-container">
                                            <input
                                                ref={profileImageInput}
                                                type="file"
                                                style={{ display: 'none' }}
                                                accept="image/png,image/jpeg,image/jpg"
                                                // id="profile"
                                                onChange={(e) => {
                                                    actionFileUpload(e, 'profile');
                                                }}
                                            />

                                            <label htmlFor="profile">
                                                {preProfile === '' && (
                                                    <img
                                                        src="/images/selfie-camera-icon.svg"
                                                        alt=""
                                                        onClick={() => {
                                                            profileImageInput!.current!.value =
                                                                '';
                                                            profileImageInput!.current!.click();
                                                        }}
                                                    />
                                                )}
                                                {preProfile && 
                                                    <img 
                                                    alt='pre-profile-avatar' 
                                                    src={preProfile}
                                                    onClick={() => {
                                                        profileImageInput!.current!.value =
                                                            '';
                                                        profileImageInput!.current!.click();
                                                    }}
                                                    />}
                                            </label>
                                        </div>
                                    </Box>
                                    <InputLabel className='selfie-label'>
                                        Upload Selfie{' '}
                                        <span className="primary-text"> * </span>
                                    </InputLabel>

                                </Box>

                                <Box className="kyc-grid-top-box">
                                    <div className="kyc-proofdiv">
                                        <div>
                                            <InputLabel className="kyc-label">
                                                Identity Proof 1
                                                <span className="primary-text">
                                                    {' '}
                                                    *{' '}
                                                </span>
                                            </InputLabel>
                                        </div>
                                        <div>
                                            <Box className='kyc-label-select'>
                                                <AppSelect
                                                    name="idOne"
                                                    titleKey="title"
                                                    valueKey="value"
                                                    size="small"
                                                    items={identityProofOne}
                                                    value={idOne}
                                                    onChange={onChangeIdOne}
                                                    placeholder="Select ID Proof #1"
                                                />
                                            </Box>
                                            <div className="upload-div">
                                                <Box className='kyc-text-line'>
                                                    <Typography variant='body1' className='small-text'>Format: Jpeg, Png and Pdf</Typography>
                                                    <Box className='text-border' />
                                                    <Typography variant='body1' className='small-text'>Max size: 2MB</Typography>
                                                </Box>
                                                <Box className='kyc-box-photo'>
                                                    <Box my={2} className="">
                                                        <input
                                                            type="file"
                                                            style={{ display: 'none' }}
                                                            accept="image/png,image/jpeg,image/jpg,application/pdf"
                                                            id="id-proof-1"
                                                            onChange={(e) => {
                                                                actionFileUpload(e, 'proof1');
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box className='kyc-box-image'>
                                                        {preProofOne !== '' && (
                                                            <Box
                                                                my={1}
                                                                className="doc-upload-container "
                                                            >
                                                                <img src={preProofOne} alt="" />
                                                            </Box>
                                                        )}
                                                    </Box>

                                                    <label
                                                        htmlFor="id-proof-1"
                                                        className="flex cursor upload-btn"
                                                    >
                                                        <img src="/icons/icon_upload.png" />
                                                        <Typography
                                                            className="upload-text"
                                                            variant="body2"
                                                        >
                                                            {preProofOne !== ''
                                                                ? 'Change file'
                                                                : 'Upload file'}
                                                        </Typography>
                                                    </label>
                                                </Box>
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                            </Grid>
                            <Grid lg={2} className="kyc-sub-border-grid">
                                <Box className='kyc-sub-border' />
                            </Grid>

                            <Grid md={12} lg={6} xl={6} className='kyc-two'>
                                <Box className="kyc-grid-second-box">
                                    <div>
                                        <InputLabel className="kyc-label">
                                            Identity Proof 2
                                            <span className="primary-text">
                                                {' '}
                                                *{' '}
                                            </span>
                                        </InputLabel>
                                    </div>
                                    <div>
                                        <div>
                                            <Box className='kyc-label-select'>
                                                <AppSelect
                                                    placeholder="Select ID Proof #1"
                                                    name="idTwo"
                                                    titleKey="title"
                                                    valueKey="value"
                                                    size="small"
                                                    items={identityProofTwo}
                                                    value={idTwo}
                                                    onChange={onChangeIdTwo}
                                                />
                                            </Box>
                                        </div>
                                        <div className="upload-div">
                                            <Box className='kyc-text-line'>
                                                <Typography variant='body1' className='small-text'>Format: Jpeg, Png and Pdf</Typography>
                                                <Box className='text-border' />
                                                <Typography variant='body1' className='small-text'>Max size: 2MB</Typography>
                                            </Box>
                                            <Box className='kyc-box-photo'>
                                                <Box my={2}>
                                                    <input
                                                        type="file"
                                                        style={{ display: 'none' }}
                                                        accept="image/png,image/jpeg,image/jpg,application/pdf"
                                                        id="id-proof-2"
                                                        onChange={(e) => {
                                                            actionFileUpload(e, 'proof2');
                                                        }}
                                                    />
                                                </Box>
                                                <Box className='kyc-box-image'>
                                                    {preProofTwo !== '' && (
                                                        <Box
                                                            my={1}
                                                            className="doc-upload-container "
                                                        >
                                                            <img src={preProofTwo} alt="" />
                                                        </Box>
                                                    )}
                                                </Box>

                                                <label
                                                    htmlFor="id-proof-2"
                                                    className="flex cursor upload-btn"
                                                >
                                                    <img src="/icons/icon_upload.png" />
                                                    <Typography
                                                        className="upload-text"
                                                        variant="body2"
                                                    >
                                                        {preProofTwo !== ''
                                                            ? 'Change file'
                                                            : 'Upload file'}
                                                    </Typography>
                                                </label>
                                            </Box>
                                        </div>
                                    </div>
                                </Box>

                                <Box className="kyc-grid-third-box">
                                    <Grid container direction='row' className="kyc-proofdiv">
                                        <Grid item xs={12} md={10} lg={12} xl={4}>
                                            <Typography>
                                                <InputLabel className="kyc-label">
                                                    identity proof 3
                                                    <span className="primary-text">
                                                        {' '}
                                                        *{' '}
                                                    </span>
                                                </InputLabel>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={10} lg={12} xl={8}>
                                            <Grid item xs={12} md={12}>
                                                <Box className="identity-select kyc-label-select">
                                                    <AppSelect
                                                        placeholder="Select Address Proof #1"
                                                        name="addressOne"
                                                        titleKey="title"
                                                        valueKey="value"
                                                        size="small"
                                                        items={AddressProofs}
                                                        value={addressOne}
                                                        onChange={onChangeIdAddressProof}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={12} className="upload-div">
                                                <Box className='kyc-text-line'>
                                                    <Typography variant='body1' className='small-text'>Format: Jpeg, Png and Pdf</Typography>
                                                    <Box className='text-border' />
                                                    <Typography variant='body1' className='small-text'>Max size: 2MB</Typography>
                                                </Box>
                                                <Box className='kyc-box-photo'>
                                                    <Box my={2}>
                                                        <input
                                                            type="file"
                                                            style={{ display: 'none' }}
                                                            accept="image/png,image/jpeg,image/jpg,application/pdf"
                                                            id="address-proof"
                                                            onChange={(e) => {
                                                                actionFileUpload(
                                                                    e,
                                                                    'addressproof'
                                                                );
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box className='kyc-box-image'>
                                                        {preAddressProof !== '' && (
                                                            <Box
                                                                my={1}
                                                                className="doc-upload-container"
                                                            >
                                                                <img src={preAddressProof} alt="" />
                                                            </Box>
                                                        )}
                                                    </Box>

                                                    <label
                                                        htmlFor="address-proof"
                                                        className="flex cursor upload-btn"
                                                    >
                                                        <img src="/icons/icon_upload.png" />
                                                        <Typography
                                                            className="upload-text"
                                                            variant="body2"
                                                        >
                                                            {preAddressProof !== ''
                                                                ? 'Change file'
                                                                : 'Upload file'}
                                                        </Typography>
                                                    </label>
                                                </Box>



                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid container direction='row' className="kyc-proofdiv">
                                        <Grid item xs={12} md={10} lg={12} xl={12} className="kyc-btn-grid">
                                            <AppPrimaryButton
                                                title="VERIFY"
                                                className='verify-btn verify-btn-kyc'
                                                disabled={isLoading || !isValidForm()}
                                                onClick={actionKYCVerify}
                                                isLoading={isLoading}
                                                loadingText="VERIFYING"
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>

                        </Box>


                    </Box>
                </>
            )}
        </Box>
    );
};

const mapStateToProps = (state: AppState): any => ({
    profileInfo: state.profileInfo,
});

export default connect(mapStateToProps)(KYCVerifyForm);
