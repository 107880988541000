import { Typography, Box, Tooltip, CircularProgress, CardMedia, IconButton, Avatar } from '@material-ui/core';
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { CollectionService } from '../../../services';
import { MiniVideoPlayer } from '../../../shared/components';
import {
    NumberPretty,
} from '../../../shared/components/AppComponents';
import { Utils, Toast } from '../../../shared/helpers';
import { CurrencyConvertion } from '../../../state/application/Reducer';
import { AuthState } from '../../../state/authentication/Reducer';
import { AppState } from '../../../state/RootReducer';
// import MiniAuctionTimer from './MiniAuctionTimer';
import { checkLauncDate, getUrl, goesLive } from '../../../shared/helpers/Utils';
import DisplayVideoAudioIcon from '../../../shared/components/displayVideoAudioIcon';
import EditionNFTCardCount from '../../../shared/components/EditionNFTCardCount';
import LaunchLive from '../../../shared/components/LaunchIn';

export interface PostedBy {
    id: string;
    name: string;
    isPrivate: boolean;
    profilePicture: string;
    blueTick: boolean;
    type: string;
    walletAddress: string;
    address: string;
    thumbnail: string;
}

export interface Favourite {
    isFavourite: boolean;
    total: number;
}

export interface NFTInfo {
    currencyType: string;
    itemId: string;
    id: string;
    _id?: string;
    name: string;
    originalArtist?: string;
    file: string[];
    logo: string;
    thumbnail: string;
    saleType: string;
    price: number;
    availableQuantity: number;
    liveTill?: any;
    postedBy: PostedBy;
    createdBy: PostedBy;
    tag: string;
    itemTagName: string;
    physicalNFT: boolean;
    creatorInfo: PostedBy;
    createrId?: any;
    className: string;
    favourites: Favourite;
    authState: AuthState;
    unFavorite?: any;
    numberOfViews: number;
    phyNFTRequested?: boolean;
    ownedQuantity?: number;
    saleInfo?: any;
    video?: any;
    images?: any;
    quantity?: any;
    primarySale?: boolean;
    itemViewsInfo?: any;
    buttonStatus?: string;
    audio?: string;
    onRouteChange?:any;
}

const NFTItem = (props: NFTInfo): ReactElement => {
    const {
        itemId,
        id,
        thumbnail,
        file,
        availableQuantity,
        name,
        price,
        _id,
        // saleType,
        // liveTill,
        quantity,
        postedBy,
        createdBy,
        creatorInfo,
        createrId,
        className,
        favourites,
        authState,
        unFavorite,
        numberOfViews,
        ownedQuantity,
        saleInfo,
        video,
        primarySale,
        images,
        itemViewsInfo,
        buttonStatus,
        audio,
        onRouteChange
    } = props;

    const {
        blueTick,
        name: personName,
        profilePicture,
        // type,
        walletAddress,
        address,
    } = postedBy || createdBy || creatorInfo || createrId || {};
    // changing isprivate to false to show all users as public
    const getPrice = (): number => {
        if (price) {
            return price;
        }

        if (saleInfo && saleInfo.length > 0) {
            if (saleInfo[0]?.pricePerQuantity)
                return saleInfo[0]?.pricePerQuantity;
            if (saleInfo[0]?.price)
                return saleInfo[0]?.price;
        }
        return 0;
    };
    const getAvailableQuantity = (): number => { if (availableQuantity) { return availableQuantity } if (saleInfo && saleInfo.length > 0) { return saleInfo[0]?.availableQuantity } return 0; }

    const isPrivate = false;
    const [fav, setFav] = useState(false);
    const [isFav, setIsFav] = useState(false);
    const [favTotal, setFavTotal] = useState(0);
    const [favLoader, setFavLoader] = useState(false);
    const [heart, setHeart] = useState('/icons/heart-dark.png');
    const navigate = useNavigate();
    const itemTag = useRef(null);


    const isLaunched = (saleInfo && saleInfo[0]?.launchDate) ? checkLauncDate(saleInfo[0]?.launchDate) : false;
    const itemContainerWidth = useMemo(() => {
        if (itemTag.current) {
            const element: any = itemTag.current;
            return element.offsetWidth;
        }
        return 300;
    }, [itemTag.current, thumbnail]);

    // Hooks
    useEffect(() => {
        if (favourites) {
            setIsFav(favourites.isFavourite);
            setFavTotal(favourites.total);
            setFav(true);
        }
    }, []);

    const getFavIcon = (): ReactElement => {
        if (favLoader) {
            return <CircularProgress size={20} />;
        }
        if (isFav) {
            // return <FavoriteIcon htmlColor='white' />;
            return <img src="/icons/heart-fill.png" style={{ width: "30px" }} />;
        }
        // return <FavoriteBorderIcon htmlColor='white' />;
        return <img src={heart} onMouseOver={() => setHeart('/icons/heart.png')} onMouseOut={() => setHeart('/icons/heart-dark.png')} onFocus={() => undefined} onBlur={() => undefined} style={{ width: "30px" }} />;
        // return <img src="/icons/heart.png" style={{width:"28px"}}  />;
    };
    const convertion: CurrencyConvertion = useSelector(
        (state: AppState) => state.applicationState.currencyConverstion
    );

    const ethPrice = useMemo(() => {
        if (convertion.to.amount !== '0') {
            const usdValue = parseFloat(convertion.to.amount);
            return ` ${(getPrice() / usdValue).toFixed(3)} ETH`;
        }
        return '';
    }, [convertion, getPrice()]);

    const isPlayableItem = (): any => Utils.isPlayableItem(file);

    const toggleFav = (_itemId: string, _isFav: boolean, e: any): void => {
        e.preventDefault();
        e.stopPropagation();
        if (authState.isUserLoggedIn) {
            if (!favLoader) {
                const data = {
                    itemId: _itemId,
                    favourite: !_isFav,
                };
                setFavLoader(true);
                CollectionService.toggleFavItems(data)
                    .then(() => {
                        if (_isFav) {
                            setIsFav(false);
                            setFavTotal(favTotal - 1);
                        } else {
                            setIsFav(true);
                            setFavTotal(favTotal + 1);
                        }
                        setFavLoader(false);
                        unFavorite(_itemId);
                    })
                    .catch((error: any) => {
                        Utils.handleErrorResponse({ error });
                        setFavLoader(false);
                    });
            }
        } else {
            Toast.showInfoMessage('Login to add favourite');
            navigate('/login', {
                state: { nextPathName: window.location.pathname },
            });
        }
    };

    // Auction status
    // const isAuctionLive = (tilltime: any): any => {
    //     const currenttime = new Date();
    //     const currentEpochTime = currenttime.getTime();
    //     const liveTillEpochTime = new Date(tilltime).getTime();
    //     const isActive = liveTillEpochTime - currentEpochTime;
    //     if (isActive > 0) { return "Current Bid" }
    //     return "Sold At"
    // }
    // const auctionStatus = isAuctionLive(liveTill)

    // UI Elements

    const getNumberofViews = (): any => {
        if (numberOfViews) {
            return numberOfViews;
        }
        return itemViewsInfo?.numberOfViews;
    };

    return (
        <Link onClick={()=>onRouteChange()} to={`/item/${itemId || id || _id}`} className="artwork-link">
            <div
                ref={itemTag}
                className={`nft-item trans-3d-hover ${className}`}
            >
                <Box style={{ width: '100%' }} p={2}>
                    <Box
                        width="94%"
                        style={{ position: 'absolute', zIndex: 500 }}
                    >
                        <Box
                            width="100%"
                            display="flex"
                            justifyContent="flex-end"
                        >
                            {fav && (
                                <>
                                    <IconButton
                                        onClick={(e: any) =>
                                            toggleFav(
                                                itemId || id || _id,
                                                isFav,
                                                e
                                            )
                                        }
                                        size="small"
                                    >
                                        {getFavIcon()}
                                    </IconButton>
                                </>
                            )}
                        </Box>
                    </Box>

                    <CardMedia
                        className="cardthumbnail"
                        component="img"
                        height="320"
                        image={thumbnail ?
                            thumbnail?.includes('https:') ||
                                thumbnail?.includes('http:')
                                ? thumbnail
                                : getUrl(`${thumbnail}`, '900x900/') : '/images/if-no-image.png'
                        }
                        alt="thumbnail_image"
                    />
                    <EditionNFTCardCount qty={quantity} />
                    <DisplayVideoAudioIcon
                        thumbnail={thumbnail}
                        video={video}
                        images={images}
                        audio={audio}
                    />
                    {false && isPlayableItem() && (
                        <MiniVideoPlayer {...props} />
                    )}
                </Box>

                <Box className="item-detail">
                    <Box className="items-content">
                        <Box display="flex" justifyContent="space-between">
                            <Tooltip arrow title={name}>
                                <Typography
                                    variant="body1"
                                    className="item-name item-art-name"
                                >
                                    {name}
                                </Typography>
                            </Tooltip>
                        </Box>

                        <Box className="user-detail" mt={2}>
                            <Tooltip title={personName} placement="right-end">
                                <Box className="name-section">
                                    <img
                                        className="avatar"
                                        src={
                                            profilePicture
                                                ? getUrl(
                                                    `${profilePicture}`,
                                                    '400x400/'
                                                )
                                                : '/icons/user.png'
                                        }
                                        alt="user"
                                    />
                                    <Box>
                                        <Box className="fvc">
                                            <Typography
                                                variant="body2"
                                                className="user-name"
                                            >
                                                @
                                                {isPrivate
                                                    ? address || walletAddress
                                                    : personName}
                                            </Typography>

                                            {blueTick && (
                                                <img
                                                    className="icon-verified"
                                                    src="/icons/icon_verified.png"
                                                    alt="verify_icon"
                                                />
                                            )}
                                        </Box>

                                        <Typography
                                            variant="body2"
                                            className="user-role"
                                        >
                                            {personName}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Tooltip>

                            {/* VIEWS ICONS & COUNT */}
                            <Box className="views">
                                <img
                                    className="view"
                                    src="/icons/visibility.svg"
                                />
                                <NumberPretty count={getNumberofViews()} />
                            </Box>
                        </Box>
                    </Box>
                    <Box height={68} className="auction-detail" style={{ justifyContent: (buttonStatus === 'soldOut') ? 'center' : '' }}>
                        {buttonStatus === 'buyButton' ? (
                            <Box className="bid-info" p={2}>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems=""
                                >
                                    <Typography
                                        variant="body1"
                                        className="current-bid-value"
                                    >
                                        {getPrice()} USD
                                    </Typography>
                                    <Box className="eth-price">
                                        <Typography
                                            variant="body2"
                                            id="eth-balance"
                                        >
                                            ({ethPrice || '0.000 ETH'})
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        ) : null}

                        {buttonStatus === 'soldOut' &&
                            <Box className='soldBtn'>
                                <Typography
                                    variant="body1"
                                    className=""
                                >
                                    SOLD
                                </Typography>
                            </Box>

                        }

                        {ownedQuantity && ownedQuantity > 0 ? (
                            <>
                                <Box height={38} className="stock-info" p={2}>
                                    <Typography variant="body2" className="">
                                        {ownedQuantity} in stock
                                    </Typography>
                                </Box>
                            </>
                        ) : null}
                        <>
                            <>
                                <>
                                    {buttonStatus === 'buyButton' &&
                                        <Box className="stock-info">

                                            <Box className="Artwork-sell">
                                                <Typography className="Artwork-buy-btn">
                                                    BUY
                                                </Typography>
                                            </Box>
                                        </Box>

                                    }





                                    {buttonStatus === 'launchTimer' && <LaunchLive
                                        title="Sale Starts in"
                                        date={
                                            saleInfo[0]
                                                ?.launchDate
                                        }
                                    />
                                    }

                                    {buttonStatus === 'notAvailableForSale' && <Box className="stock-info-not-available">
                                        <Typography
                                            variant="body2"
                                            className="end-text"
                                        >
                                            Not available for sale currently
                                        </Typography>
                                    </Box>}

                                </>
                            </>
                        </>

                    </Box>
                </Box>
            </div>
        </Link>
    );
};

const mapStateToProps = (state: AppState): any => ({
    profileInfo: state.profileInfo,
    authState: state.authState,
    appState: state.applicationState,
});

export default connect(mapStateToProps, null)(NFTItem);
