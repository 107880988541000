import { ReactElement } from 'react';
import { Avatar, Box, CardMedia, Grid, Typography } from '@material-ui/core';
import Slider from 'react-slick';
import CollectionsIcon from '@material-ui/icons/Collections';
import { Link } from 'react-router-dom';
import { EmptyPlaceholder } from '../../../shared/components';
import { AppBigNavButton } from '../../../shared/components/AppComponents';
import { getUrl } from '../../../shared/helpers/Utils';

const ImageSliderConfigNFT = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    arrows: true,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 6000,
    lazyload: true,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true
            }
        },
        {
            breakpoint: 969,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
            }
        },
        {
            breakpoint: 776,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true
            }
        }
    ]
}

const HomeBanner = (props: any): ReactElement => {

    const { exihibitions } = props;
    const { featured } = exihibitions;

    // TODO error handling
    /* eslint no-underscore-dangle: 0 */

    return (

        <Slider className='nft-slider home-banner-wrapper-py' {...ImageSliderConfigNFT}>
            {featured && featured.map((item: any) => (
                <>
                    <Box key={item?._id}>
                        <Grid container spacing={1}>
                            <Grid item sm={12} md={6} lg={6} xl={6}>
                                <Link to={`/exhibition/${item?._id}`}>
                                    <Box
                                        height='100%'
                                        display='flex'
                                        justifyContent='center'
                                        alignContent='flex-start'
                                    >
                                        <Grid item sm={10} md={9} lg={9} xl={9}>
                                            <CardMedia component='img' image={item?.thumbnail ? getUrl(`${item.thumbnail}`, '713x712/') : '/images/if-no-image.png'} />
                                        </Grid>
                                    </Box>
                                </Link>

                            </Grid>
                            <Grid item sm={12} md={6} lg={6} xl={6}>
                                <Box
                                    display='flex'
                                    flexDirection='column'
                                    justifyContent='space-around'
                                    height='100%' width='100%'>
                                    <Box>
                                        <Link to={`/exhibition/${item?._id}`}>
                                            <Box width='100%'>
                                                <Typography variant="h2" className='homebanner-title text-ellipsis--3'>
                                                    {item?.title}
                                                </Typography>
                                                <Box id='banner-details' className='desc-wrapper-banner' mt={8} pr={2}>
                                                    <Typography className='text-ellipsis--3'>
                                                        {item?.description.replace(/<[^>]+>/g, '')}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Link>
                                    </Box>
                                    <Box>
                                        <Link to={`/exhibition/${item?._id}`}>
                                            <Box className='curatorinfo-wrapper'>
                                                <Avatar
                                                    className='avatar-curator-banner'
                                                    src={getUrl(`${item?.curator?.profilePicture}`, '400x400/')} alt='avatar' />
                                                <Box ml={2}>
                                                    <Typography variant='h4' color='textSecondary' >Curated By</Typography>
                                                    <Link to={`/curator/${item?.curator?._id}`}>
                                                        <Typography variant='h4' className='underLine'>{item?.curator?.name}</Typography>
                                                    </Link>
                                                    <Box display='flex' mt={2}>
                                                        <Avatar style={{ height: '25px', width: '25px', backgroundColor: '#fff', color: '#000' }} src='/socialicons/region.svg' />
                                                        <Typography style={{ fontSize: '15px', lineHeight: '25px', marginLeft: '5px' }} variant='h5'><b>{`${item?.region?.name},  ${item?.region?.subRegion}`}</b></Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Link>

                                        <Box
                                            display='flex'
                                            justifyContent='flex-end'
                                        >
                                            <AppBigNavButton
                                                endIcon={<Avatar src="/images/banner-right-arrow.svg" />}
                                                title="View Exhibition"
                                                to={`/exhibition/${item._id}`}
                                                id="hero-button"
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            ))}
            {featured === undefined || featured.length === 0 && (
                <EmptyPlaceholder
                    icon={<img src="/icons/NoData.png" />}
                />
            )
            }
        </Slider>
    )
}

export default HomeBanner
