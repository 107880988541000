import { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Box,
    Typography,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    TextField,
    FormHelperText,
    CircularProgress,
    Avatar,
    // MenuItem,
    // ListItemText,
    // Select,
    // Checkbox,
    FormControl,
    // InputLabel,
    Divider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import { AppState } from '../../../state/RootReducer';
import {
    AppPrimaryButton,
    AppThemeRadioInput,
    AppSecondaryButton,
    AppCheckBox,
    AppNavButton,
} from '../../../shared/components/AppComponents';
import { FractoCard } from '../../../shared/components';

import { ApplicationDataState } from '../../../state/application/Reducer';
import { BlockChain, Toast, Utils } from '../../../shared/helpers';
import { BuySellService, LandService } from '../../../services';
// import { useDebouncedEffect } from '../../../shared/hooks';

// const CASHBACK_WITHIN_TIME = 180;

export interface Wallet {
    currencyType: string;
    freeBalance: string;
    lockedBalance: string;
    totalBalance: string;
}

interface Currency {
    amount: string;
    currencyType: string;
}

export interface CurrencyConvertion {
    from: Currency;
    to: Currency;
    holdingId: string;
    expiresIn: Date;
}

export interface CryptoBuySummary {
    auctionId: string;
    userId: string;
    saleId: string;
    itemId: string;
    sellerWithdrawalAddress: string;
    totalCost: string;
    sellerCost: string;
    randomHex: string;
}
interface Currency {
    amount: string;
    currencyType: string;
}

export interface NFTCheckoutProps {
    purchaseType: string;
    appState: ApplicationDataState;
    onPaymentResponse: any;
}

const LandCheckout = (props: any): ReactElement => {
    const {
        appState,
        purchaseType,
        onPaymentResponse,
        buyDetails,
        attributes
    } = props;
    const [quantity, setQuantity] = useState('1');
    const [quantityError, setQuantityError] = useState('');
    const [bidAmount, setBidAmount] = useState('');
    const [bidFormError, setBidFormError] = useState('');
    const [paymentMode, setPaymentMode] = useState('fiat');
    const [showLoading, setShowLoading] = useState(false);
    const [showCard, setShowCard] = useState(false);
    const [aggreed, setAggreed] = useState(false);
    const [convertingPrice, setConvertingPrice] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [validCard, setValidCard] = useState(false);
    const [paymentResponse, setPaymentResponse] = useState('');
    const [wallet, setWallet] = useState<Wallet>({} as Wallet);
    const [metamaskAccountDetails, setMetamaskAccountDetails] = useState({
        address: '',
        balance: '',
    });
    const [cryptoBuySummary, setCryptoBuySummary] = useState<CryptoBuySummary>({
        saleId: '',
        itemId: '',
        sellerWithdrawalAddress: '',
        totalCost: '',
        sellerCost: '',
        randomHex: '',
        userId: '',
        auctionId: '',
    });
    const [currencyConverstion, setCurrencyConverstion] = useState<
        CurrencyConvertion | undefined
    >();
    const [cardForm, setCardForm] = useState({
        cardNumber: '',
        cardCvc: '',
        cardExpiry: '',
    });
    // 0. Bid Amount or Quantity Form
    // 1. Checkout summary
    // 2. Sucess/Failure
    const [step, setStep] = useState(0);
    const [acceptedPaymentMethod, setAcceptedPaymentMethod] = useState('fiat')
    // API Calls
    // const buyCompleteOnTime = (saleId: any, itemId: any, amount: any): any => {
    //     const requestObj = {
    //         saleId,
    //         itemId,
    //         amount,
    //     };
    //     BuySellService.successCashBack(requestObj)
    //         .then((response: any) => {
    //             const data: any = response?.data?.data;
    //             setCashBackAmount(data?.amount);
    //             setShowCountDown(false);
    //         })
    //         .catch((error: any) => {
    //             Utils.handleErrorResponse({ error });
    //         });
    // };

    // const convertFiatToCryptoPrice = (total: number): void => {
    //     setConvertingPrice(true);
    //     const type = 'USD'
    //     BuySellService.convertPriceAndHold(total, type, 'ETH')
    //         .then((response) => {
    //             const converstion = response.data.data;
    //             setCurrencyConverstion(converstion);
    //             setConvertingPrice(false);
    //         })
    //         .catch(() => {
    //             setConvertingPrice(false);
    //         });
    // };

    const processInitiateResponse = async (
        paymentMethod: string,
        response: any
    ): Promise<any> => {
        if (paymentMethod === 'crypto') {
            setCryptoBuySummary(response);
            // // setRandomEdition(response.editions);
            // setShowLoading(true);
            // BlockChain.setUpMetaMask()
            //     .then((metaMaskResponse: any) => {
            //         setShowLoading(false);
            //         setMetamaskAccountDetails(metaMaskResponse);
            //         setStep(1);
            //     })
            //     .catch((error) => {
            //         setShowLoading(false);
            //         if (error.code || error.message) {
            //             Toast.showErrorMessage(error.message);
            //         } else {
            //             Toast.showErrorMessage(error);
            //         }
            //     });
            // setShowCard(false);
        } else {
            setStep(1);
            // setClientSecret(response.paymentIntent);
            setShowCard(response.cardRequired);
            setWallet(response.fiatBalance);
            // setRandomEdition(response.editions);
        }
    };

    const initiateBuy = (paymentType: string): void => {
        setPaymentMode(paymentMode);
        setShowLoading(true);
        let data = {}
        if (purchaseType === 'direct') {
            if (paymentType === 'fiat') {
                data = {
                    amount: Number(buyDetails.landSalesPriceUsd),
                    landTokenId: buyDetails.metadata.tokenId,
                    quantity: 1,
                    saleType: "sale"
                };
            } else {
                data = {};
            }
        }
        // if (purchaseType === 'auction') {
        //     if (paymentType === 'crypto') {
        //         data = {};
        //     } else {
        //         data = {};
        //     }
        // }
        const promise =
            paymentType === 'fiat'
                ? LandService.initiateLandBuy(data)
                : LandService.initiateLandBuy(data)
        promise
            .then((res: any) => {
                const response = res.data.data;
                setShowLoading(false);
                processInitiateResponse(paymentMode, response);
            })
            .catch((error) => {
                Utils.handleErrorResponse({
                    error,
                });
                setShowLoading(false);
            });
    };

    // NFT Purchase using Fiat
    const completeBuy = (): void => {
        setShowLoading(true);
        const requestObj: any = {
            amount: purchaseType === 'direct' ? buyDetails.landSalesPriceUsd : bidAmount,
            quantity: 1,
            landTokenId: buyDetails.metadata.tokenId,
            metadataLink: buyDetails.metadataLink, 
            longitude: buyDetails.metadata.longitude,
            latitude: buyDetails.metadata.latitude,
            city: buyDetails.metadata.city,
            type: buyDetails.metadata.type,
            name: buyDetails.metadata.name,
            description: buyDetails.metadata.description,
            image: buyDetails.metadata.image,
            properties: JSON.stringify(attributes)
        };
        // call final land purchase api
        
            LandService.completeBuy(requestObj)
                .then(() => {
                    setShowLoading(false);
                    setPaymentStatus(true);
                    setPaymentResponse(
                        'Congrats! Your transaction has been completed successfully. You are now the owner of this Land.'
                    );
                    setStep(2);
                    onPaymentResponse(true);
                })
                .catch(() => {
                    setShowLoading(false);
                    setPaymentStatus(false);
                    setPaymentResponse('Item purchase failed');
                    setStep(2);
                })
    };

    // NFT purchase using crypto
    const completeBuyForCrypto = (hash: any, walletAddress: any): void => {
        setShowLoading(true);
        const data: any = {
            quantity: Number(quantity),
            saleId: cryptoBuySummary.saleId,
            itemId: cryptoBuySummary.itemId,
            txHash: hash,
            metamaskAddress: walletAddress,
            randomHex: `${cryptoBuySummary.randomHex}`,
            totalPrice: cryptoBuySummary.totalCost,
            // editions: randomEdition,
        };

        BuySellService.buyFromEther(data)
            .then((res: any) => {
                const message = res.data.msg;
                setShowLoading(false);
                setPaymentStatus(true);
                onPaymentResponse(true);
                setPaymentResponse(message);
                setStep(2);
            })
            .catch(() => {
                setShowLoading(false);
                setPaymentStatus(false);
                setPaymentResponse('Item purchase failed');
                setStep(2);
            });
    };

    // Bid post using Fiat
    const completeAuctionBid = (paymentId: string): void => {
        setShowLoading(true);
        const data: any = {};
        // Append payment id if payment done from stripe
        if (paymentId !== '') {
            data.paymentId = paymentId;
        }
        BuySellService.placeBid(data)
            .then(() => {
                setShowLoading(false);
                setPaymentStatus(true);
                setPaymentResponse('Bidding added successfully');
                setStep(2);
                onPaymentResponse(true);
            })
            .catch(() => {
                setShowLoading(false);
                setPaymentStatus(false);
                setPaymentResponse('Bidding added failed');
                setStep(2);
            });
    };

    // Post bid using crypto
    const postBidForCrypto = (hash: any, walletAddress: any): void => {
        setShowLoading(true);
        const data: any = {
            saleId: cryptoBuySummary.saleId,
            itemId: cryptoBuySummary.itemId,
            txHash: hash,
            metamaskAddress: walletAddress,
            randomHex: `${cryptoBuySummary.randomHex}`,
            totalPrice: `${currencyConverstion?.to.amount} ETH`,
            amount: Number(parseFloat(bidAmount).toFixed(2)),
        };

        BuySellService.postABidUsingCrypto(data)
            .then((res: any) => {
                const message = res.data.msg;
                setShowLoading(false);
                setPaymentStatus(true);
                setPaymentResponse(message);
                setStep(2);
                onPaymentResponse(true);
            })
            .catch(() => {
                setShowLoading(false);
                setPaymentStatus(false);
                setPaymentResponse('NFT bidding failed');
                setStep(2);
            });
    };

    const initiateCryptoPayment = async (): Promise<any> => {
        setShowLoading(true);
        BlockChain.buyNFT(cryptoBuySummary)
            .then(async (response: any) => {
                completeBuyForCrypto(response.hash, response.verifiedAddress);
            })
            .catch((error: any) => {
                setShowLoading(false);
                if (error.code || error.message) {
                    Toast.showErrorMessage(error.message);
                } else {
                    Toast.showErrorMessage(error);
                }
            });
    };

    const initiateCryptoPaymentForAuction = async (): Promise<any> => {
        setShowLoading(true);
        BlockChain.bidCryptoForAuction(
            cryptoBuySummary,
            `${currencyConverstion?.to.amount}`
        )
            .then(async (response: any) => {
                postBidForCrypto(response.hash, response.verifiedAddress);
            })
            .catch((error: any) => {
                setShowLoading(false);
                if (error.code || error.message) {
                    Toast.showErrorMessage(error.message);
                } else {
                    Toast.showErrorMessage(error);
                }
            });
    };

    // Instance Methods
    const getSubTotal = (): number => buyDetails.landSalesPriceUsd;

    // const onPriceExpire = (): void => {
    //     if ((step === 0 || step === 1) && paymentMode === 'crypto') {
    //         convertFiatToCryptoPrice(getSubTotal());
    //     }
    // };

    const getConfirmButtonTitle = (): string => {
        if (paymentMode === 'fiat' && purchaseType === 'direct') {
            return `CONFIRM & PAY ${getSubTotal()} ${
                'USD'
            }`;
        }
        // if (paymentMode === 'fiat' && purchaseType === 'auction') {
        //     return `CONFIRM & PAY ${getSubTotal()} ${
        //         auctionToBid.currencyType
        //     }`;
        // }
        return `CONFIRM & PAY ${currencyConverstion?.to.amount} ${currencyConverstion?.to.currencyType}`;
    };

    // Hooks

    useEffect(() => {
        setQuantity('1')
        setQuantityError('')
        setBidAmount('0')
        setBidFormError('')
        setWallet({} as Wallet)
        setMetamaskAccountDetails({
            address: '',
            balance: ''
        })
        setCryptoBuySummary({
            saleId: '',
            itemId: '',
            sellerWithdrawalAddress: '',
            totalCost: '',
            sellerCost: '',
            randomHex: '',
            userId: '',
            auctionId: '',
        })
        setAcceptedPaymentMethod('all')
        setConvertingPrice(false)
        setCurrencyConverstion(undefined)
    }, [])

    // UI Actions



    const actionInitiateBuy = (paymentMethod: string): void => {
        initiateBuy(paymentMethod);
    };

    const actionProcessFractoPayment = (): void => {
        // const card =  elements!.getElement(
        //     CardNumberElement
        // ) as StripeCardNumberElement
        const ccnumber = cardForm.cardNumber.replace(/ +/g, '').trim();
        const ccexp = cardForm.cardExpiry.replace('/', '').trim();
        const cvv = cardForm.cardCvc.trim();

        setShowLoading(true);
        const requestObj: any = {
            ccnumber,
            ccexp,
            cvv,
            amount: purchaseType === 'direct' ? buyDetails.landSalesPriceUsd : bidAmount,
            quantity: 1,
            landTokenId: buyDetails.metadata.tokenId,
            metadataLink: buyDetails.metadataLink, 
            longitude: buyDetails.metadata.longitude,
            latitude: buyDetails.metadata.latitude,
            city: buyDetails.metadata.city,
            type: buyDetails.metadata.type,
            name: buyDetails.metadata.name,
            description: buyDetails.metadata.description,
            image: buyDetails.metadata.image,
            properties: JSON.stringify(attributes),
        };

        if (purchaseType === 'direct') {
            LandService.buyLandWithFracto(requestObj)
                .then(() => {
                    // const data: any = response?.data?.data;
                    setShowLoading(false);
                    setPaymentStatus(true);
                    setPaymentResponse(
                        'Congrats! Your transaction has been completed successfully. You are now the owner of this Land.'
                    );
                    setStep(2);
                    onPaymentResponse(true);
                   
                })
                .catch((error: any) => {
                    console.log('error: ', error);
                    Utils.handleErrorResponse({ error });
                    setShowLoading(false);
                    setPaymentStatus(false);
                    setPaymentResponse('Land purchase failed');
                    setStep(2);
                });
        } else {
            // call fracto bid api
            if (showCard) {
                requestObj.cardRequired = showCard;
            }
            BuySellService.placeBidWithFracto(requestObj)
                .then(() => {
                    setShowLoading(false);
                    setPaymentStatus(true);
                    setPaymentResponse('Bidding added successfully');
                    setStep(2);
                    onPaymentResponse(true);
                })
                .catch((error: any) => {
                    Utils.handleErrorResponse({ error });
                    setShowLoading(false);
                    setPaymentStatus(false);
                    setPaymentResponse('Bidding added failed');
                    setStep(2);
                });
        }
    };

    const actionConfirmBuy = (): void => {
        if (purchaseType === 'direct') {
            if (paymentMode === 'crypto') {
                initiateCryptoPayment();
            } else if (showCard) {
                actionProcessFractoPayment();
                // actionProcessStripePayment();
            } else {
                completeBuy();
            }
        } else if (purchaseType === 'auction') {
            if (paymentMode === 'fiat') {
                if (showCard) {
                    actionProcessFractoPayment();
                    // actionProcessStripePayment();
                } else {
                    completeAuctionBid('');
                }
            } else {
                initiateCryptoPaymentForAuction();
            }
        } 
    };

    // UI Elements
    // const TotalSection = (
    //     <>
    //         {purchaseType === 'direct' && (
    //             <>
    //                 {quantity === '' && <>{listingToBuy.currencyType}</>}
    //                 {quantity !== '' && (
    //                     <>
    //                         {getSubTotal()} {listingToBuy.currencyType}
    //                         {paymentMode === 'crypto' && (
    //                             <small>
    //                                 <br />({currencyConverstion?.to?.amount}{' '}
    //                                 ETH)
    //                             </small>
    //                         )}
    //                     </>
    //                 )}
    //             </>
    //         )}
    //         {purchaseType === 'auction' && (
    //             <>
    //                 {quantity === '' && <>{auctionToBid.currencyType}</>}
    //                 {quantity !== '' && (
    //                     <>
    //                         {getSubTotal()} {auctionToBid.currencyType}
    //                         {paymentMode === 'crypto' && (
    //                             <small>
    //                                 <br />({currencyConverstion?.to?.amount}{' '}
    //                                 ETH)
    //                             </small>
    //                         )}
    //                     </>
    //                 )}
    //             </>
    //         )}
    //     </>
    // );
    const actionFractoFormChange = (event: any): void => {
        let value;
        if (event.target.name === 'cardNumber') {
            value = Utils.formatCreditCardNumber(event.target.value);
        } else if (event.target.name === 'cardExpiry') {
            value = Utils.formatExpirationDate(event.target.value);
        } else if (event.target.name === 'cardCvc') {
            value = Utils.formatCVC(event.target.value, {
                number: cardForm.cardNumber,
            });
        }
        setCardForm({
            ...cardForm,
            [event.target.name]: value,
        });
        setValidCard(true);
    };
    return (
        <>
            {step < 2 && (
                <Grid container spacing={2} className="checkout-container">
                    <Grid item md={6} sm={12} className="left-panel">
                        <Box className="nft-section">
                            <Box display="flex" flexDirection="column">
                                <Typography variant="h4">
                                    Purchase Summary
                                </Typography>
                                <Box className="nft-preview">
                                    <img
                                        className="nft-image"
                                        src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_960_720.jpg"
                                    />
                                    <Box className="nft-detail">
                                        <Typography variant="h5">
                                            {buyDetails.metadata.name}
                                        </Typography>
                                        <Box
                                            mt={4}
                                            display="flex"
                                            alignItems="center"
                                        >
                                            {purchaseType === 'direct' && (
                                                <>
                                                    <FormControl
                                                        fullWidth
                                                        size="small"
                                                        variant="outlined"
                                                    >
                                                        {/* <InputLabel>
                                                            Select Edition
                                                        </InputLabel> */}
                                                        <TextField
                                                            variant="outlined"
                                                            label="Quantity"
                                                            fullWidth
                                                            type="number"
                                                            value={quantity}
                                                            
                                                            // onChange={(
                                                            //     e: any
                                                            // ) =>
                                                            //     setPurchaseQuantity(
                                                            //         Number(
                                                            //             parseInt(
                                                            //                 e
                                                            //                     .target
                                                            //                     .value,
                                                            //                 10
                                                            //             )
                                                            //         )
                                                            //     )
                                                            // }
                                                        />
                                                    </FormControl>
                                                </>
                                            )}
                                            {/* {purchaseType === 'auction' && (
                                                <Box
                                                    display="flex"
                                                    flexDirection="column"
                                                >
                                                    <Box>
                                                        <Typography>
                                                            Editions
                                                        </Typography>
                                                        <Typography className="text-gray">
                                                            {Utils.sortEditions(
                                                                auctionToBid.editionQuantity
                                                            ).join(', ')}
                                                        </Typography>
                                                    </Box>
                                                    <Box mt={4} display="flex">
                                                        <Typography>
                                                            Enter Bid Amount
                                                        </Typography>
                                                        <TextField
                                                            className="qty-input"
                                                            variant="outlined"
                                                            placeholder="ex 10"
                                                            size="small"
                                                            type="number"
                                                            inputProps={{
                                                                readOnly:
                                                                    step > 0,
                                                            }}
                                                            value={bidAmount}
                                                            onChange={(e) =>
                                                                setBiddingAmount(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </Box>
                                                </Box>
                                            )} */}
                                        </Box>
                                        <FormHelperText className="Mui-error">
                                            {quantityError || bidFormError}
                                        </FormHelperText>
                                    </Box>
                                </Box>
                            </Box>
                            <Divider />
                            <Box className="mt-4">
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Typography>Subtotal</Typography>
                                    <Typography align="right">
                                        {buyDetails.landSalesPriceUsd} USD
                                        {/* {TotalSection} */}
                                    </Typography>
                                </Box>
                                <Box
                                    mt={1}
                                    display="flex"
                                    justifyContent="space-between"
                                    className='total-sub'
                                >
                                    <Typography variant="subtitle1">
                                        Total
                                    </Typography>
                                    {convertingPrice && <CircularProgress className='circular-load'/>}
                                    <Box className='sub-total-value'>
                                        <Typography
                                            variant="subtitle1"
                                            align="right"
                                        >
                                            {buyDetails.landSalesPriceUsd} USD
                                        </Typography>
                                    </Box>
                                    {/* {!convertingPrice && (
                                        <Box>
                                            <Typography
                                                variant="subtitle1"
                                                align="right"
                                            >
                                                {TotalSection}
                                            </Typography>

                                            <Typography>
                                                {!convertingPrice &&
                                                    purchaseType === 'direct' &&
                                                    paymentMode === 'crypto' &&
                                                    currencyConverstion &&
                                                    step < 2 && (
                                                        <Typography className="text-secondary">
                                                            <PriceCountDown
                                                                onPriceExpire={
                                                                    onPriceExpire
                                                                }
                                                                expiresIn={
                                                                    currencyConverstion?.expiresIn
                                                                }
                                                            />
                                                        </Typography>
                                                    )}
                                            </Typography>
                                        </Box>
                                    )} */}
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item md={6} sm={12} className="right-panel">
                        {step === 0 && purchaseType === 'direct' && (
                            <Box className="payment-mode">
                                <Box>
                                    <Typography variant="h4">
                                        Payment Mode
                                    </Typography>
                                    <Box mt={2}>
                                        {(acceptedPaymentMethod ===
                                            'fiat' ||
                                            acceptedPaymentMethod ===
                                                'all') && (
                                            <AppThemeRadioInput
                                                label="Fiat (USD)"
                                                selectedValue={paymentMode}
                                                value="fiat"
                                                onChange={() => {
                                                    setPaymentMode('fiat');
                                                }}
                                            />
                                        )}
                                        {(acceptedPaymentMethod ===
                                            'crypto' ||
                                            acceptedPaymentMethod ===
                                                'all') &&
                                            appState.acceptCrypto && (
                                                <AppThemeRadioInput
                                                    label="Crypto (ETH)"
                                                    selectedValue={paymentMode}
                                                    value="crypto"
                                                    onChange={() => {
                                                        setPaymentMode(
                                                            'crypto'
                                                        );
                                                    }}
                                                />
                                            )}
                                    </Box>
                                </Box>
                                <AppPrimaryButton
                                    title="CONTINUE"
                                    isLoading={showLoading}
                                    disabled={
                                        showLoading ||
                                        // convertingPrice ||
                                        quantity !== '1'
                                        // editions.length === 0
                                    }
                                    onClick={() => {
                                        actionInitiateBuy(paymentMode);
                                    }}
                                />
                            </Box>
                        )}
                        {step === 0 && purchaseType === 'auction' && (
                            <Box className="payment-mode">
                                <Box>
                                    <Typography variant="h4">
                                        Payment Mode
                                    </Typography>
                                    <Box mt={2}>
                                        {(acceptedPaymentMethod ===
                                            'fiat' ||
                                            acceptedPaymentMethod ===
                                                'all') && (
                                            <AppThemeRadioInput
                                                label="Fiat (USD)"
                                                selectedValue={paymentMode}
                                                value="fiat"
                                                onChange={() => {
                                                    setPaymentMode('fiat');
                                                }}
                                            />
                                        )}
                                        {(acceptedPaymentMethod ===
                                            'crypto' ||
                                            acceptedPaymentMethod ===
                                                'all') && (
                                            <AppThemeRadioInput
                                                label="Crypto"
                                                selectedValue={paymentMode}
                                                value="crypto"
                                                onChange={() => {
                                                    setPaymentMode('crypto');
                                                }}
                                            />
                                        )}
                                    </Box>
                                </Box>
                                <AppPrimaryButton
                                    title="CONTINUE"
                                    isLoading={showLoading}
                                    disabled={
                                        showLoading ||
                                        convertingPrice ||
                                        bidFormError !== '' ||
                                        bidAmount === ''
                                    }
                                    onClick={() => {
                                        actionInitiateBuy(paymentMode);
                                    }}
                                />
                            </Box>
                        )}
                        {/* {step === 1 && showLoading && (
                            <Box className="center-content">
                                <CircularProgress color="primary" />
                            </Box>
                        )} */}
                        {step === 1 && (
                            <Box className="payment-mode">
                                {!showCard && (
                                    <Box>
                                        <Typography variant="h5">
                                            Pay using wallet balance
                                        </Typography>
                                        {paymentMode === 'fiat' && (
                                            <Box
                                                mt={2}
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <Typography variant="h2">
                                                    {`${wallet.totalBalance} ${wallet.currencyType}`}
                                                </Typography>
                                                <Box ml={2}>
                                                    <Typography className="text-secondary">
                                                        {`Available: ${wallet.freeBalance} ${wallet.currencyType}`}
                                                    </Typography>
                                                    <Typography className="text-secondary">
                                                        {`Locked: ${wallet.lockedBalance} ${wallet.currencyType}`}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        )}
                                        {paymentMode === 'crypto' && (
                                            <Box mt={2}>
                                                <Typography variant="h2">
                                                    {`${Utils.formatCrypto(
                                                        metamaskAccountDetails.balance
                                                    )} ETH`}
                                                </Typography>
                                                <Box mt={2}>
                                                    <Typography className="text-gray">
                                                        Address:
                                                        <span className="text-secondary">
                                                            {metamaskAccountDetails.address !==
                                                            ''
                                                                ? Utils.formatedAddress(
                                                                      metamaskAccountDetails.address
                                                                  )
                                                                : ''}
                                                        </span>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>
                                )}
                                {showCard && (
                                    <Box>
                                        <Typography variant="h4">
                                            Pay by Card
                                        </Typography>
                                        <Box mt={4}>
                                            <FractoCard
                                                cardForm={cardForm}
                                                onFormChange={
                                                    actionFractoFormChange
                                                }
                                                // onFormChange={({
                                                //     completed,
                                                // }: any) => {
                                                //     setValidCard(completed);
                                                // }}
                                            />
                                        </Box>
                                    </Box>
                                )}
                                <Box mt={4}>
                                    <AppCheckBox
                                        value={aggreed}
                                        label={
                                            <Box display="flex">
                                                <Typography>
                                                    I agree with
                                                </Typography>

                                                <Typography
                                                    component={Link}
                                                    to="/terms"
                                                    className="primary ml-1"
                                                    target="_blank"
                                                >
                                                    Terms and Conditions
                                                </Typography>
                                            </Box>
                                        }
                                        onChange={(status: boolean) => {
                                            setAggreed(status);
                                        }}
                                    />
                                    {purchaseType === 'auction' && (
                                        <Box>
                                            <Typography className="text-gray">
                                                Note: Bid once placed cannot be
                                                cancelled.
                                            </Typography>
                                        </Box>
                                    )}
                                    <Box>
                                        <AppSecondaryButton
                                            title="BACK"
                                            className="mr-10 mt-10"
                                            disabled={showLoading}
                                            onClick={() => {
                                                setStep(0);
                                            }}
                                        />

                                        <AppPrimaryButton
                                            className="mt-10"
                                            title={getConfirmButtonTitle()}
                                            disabled={
                                                !aggreed ||
                                                (showCard && !validCard) ||
                                                showLoading
                                            }
                                            loadingText="PROCESSING"
                                            isLoading={showLoading}
                                            onClick={actionConfirmBuy}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            )}

            {step === 2 && (
                <Box
                    className="center-content checkout-container"
                    flexDirection="column"
                >
                    <Box mb={2}>
                        <Typography variant="h4">
                            {paymentStatus
                                ? 'Payment Successful!'
                                : 'Payment Failed!'}
                        </Typography>
                    </Box>
                    <Box my={2}>
                        <Avatar
                            src={
                                paymentStatus
                                    ? '/icons/icon-success.png'
                                    : '/icons/icon-failed.png'
                            }
                        />
                    </Box>
                    <Box mt={2}>
                        <Typography className="text-secondary" align="center">
                            {paymentResponse}
                        </Typography>
                        <Box mt={2} id="checkout-success-options">
                            <Box mr={3} id="view-nft-button ">
                                <AppNavButton
                                    to="/profile/owned-nfts"
                                    title="View NFT"
                                />
                            </Box>
                            <Box id="marketplace-button">
                                <AppNavButton
                                    to="/marketplace"
                                    title="Continue Shopping"
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};
interface LandCheckoutFormProps {
    showCheckout: boolean;
    actionCloseCheckoutDialog: any;
    purchaseType: string;
    appState: ApplicationDataState;
    onPaymentResponse: any,
    buyDetails: any
    attributes: any
}

const LandCheckoutForm = (props: LandCheckoutFormProps): ReactElement => {
    const { showCheckout, actionCloseCheckoutDialog } = props;
   
    return (
        <Dialog open={showCheckout} fullWidth maxWidth="md">
            <DialogTitle>
                <Box className="title-section checkout-title-section">
                    <Typography variant="h4">Checkout</Typography>
                    <IconButton
                    className=''
                        onClick={() => {
                            actionCloseCheckoutDialog();
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                </Box>
                <hr />
            </DialogTitle>
            <DialogContent>
                <LandCheckout
                    {...props}
                />
            </DialogContent>
        </Dialog>
    );
};

const mapStateToProps = (state: AppState): any => ({
    appState: state.applicationState,
});

export default connect(mapStateToProps, null)(LandCheckoutForm);
