import {
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    IconButton,
    Divider,
} from '@material-ui/core';
import { ReactElement } from 'react';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkIcon from '@material-ui/icons/Link';
import CloseIcon from '@material-ui/icons/Close';
import { AppTeritaryButton } from './AppComponents';
import { Toast } from '../helpers';

interface SocialShareProps {
    showShare: boolean;
    tooltip: string;
    link: string;
    title: string;
    onCloseDialog: any;
}

const SocialShare = (props: SocialShareProps): ReactElement => {
    const { showShare, tooltip, link, title, onCloseDialog } = props;

    // Instance Methods
    const getUrl = (): string =>
        `${window.location.protocol}//${window.location.host}${link}`;

    // UI Elements
    return (
        <Box className="social-share-container">
            <Dialog open={showShare} maxWidth="sm">
                <DialogTitle>
                    <Box className="title-section">
                        <Typography variant="h4">Share NFT via</Typography>
                        <IconButton
                            onClick={() => {
                                onCloseDialog();
                            }}
                        >
                            <CloseIcon color="primary" />
                        </IconButton>
                    </Box>
                    <Divider />
                </DialogTitle>
                <DialogContent>
                    <Box display="flex" mb={2}>
                        <a
                            href={`https://twitter.com/intent/tweet?text=${title}&url=${getUrl()}`}
                            target="blank"
                            title={tooltip}
                        >
                            <AppTeritaryButton
                                title={
                                    <Box>
                                        <TwitterIcon />
                                        <Typography>Twitter</Typography>
                                    </Box>
                                }
                            />
                        </a>

                        <a
                            href={`https://www.facebook.com/sharer.php?u=${getUrl()}`}
                            target="blank"
                            title={tooltip}
                        >
                            <AppTeritaryButton
                                title={
                                    <Box>
                                        <FacebookIcon />
                                        <Typography>Facebook</Typography>
                                    </Box>
                                }
                            />
                        </a>

                        <AppTeritaryButton
                            onClick={() => {
                                navigator.clipboard.writeText(getUrl());
                                Toast.showSuccesMessage('Copied');
                            }}
                            title={
                                <Box>
                                    <LinkIcon />
                                    <Typography>Copy Link</Typography>
                                </Box>
                            }
                        />
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default SocialShare;
