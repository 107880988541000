import { ReactElement } from 'react';
import { Typography, Container, Box, Divider } from '@material-ui/core';
import HeadSection from './HeadSection';
import { HeadTag } from '../../shared/components';

const PrivacyPolicyPage = (): ReactElement => {
    const getBaseUrl = (): string => window.location.origin;
    const title = 'Privacy Policy';
    const description = 'Privacy Policy';
    return (
        <Container className="privacy-terms">
            <HeadTag title={title} description={description} />
            <HeadSection title="" subtitle="TheUpsideSpace Privacy Policy" content="" />
            <Box className="privacy-box-wrapper">
                <Box className="terms-condition-container">
                    <Typography variant="body1" className='policy-text'>
                        Last Revised: 06 December 2022
                    </Typography>

                    <Typography variant="body1" className='policy-text'>
                        This policy sets out the basis on which TheUpsideSpace (referred to herein as “The Upside Space,” “we” or “us”) collects personal data from you and how we process such data. By visiting our website https://www.theupsidespace.com (the “Site”), you hereby consent to our Privacy Policy and agree to its terms.
                    </Typography>
                    <Typography variant="body1" className='policy-text'>
                        Your privacy matters to us, so please do take the time to get to know and familiarize yourself with our policies and practices. Please understand that we reserve the right to change any of our policies and practices at any time, but you can always find the latest version of this Privacy Policy here on this page.
                    </Typography>

                    <Typography variant='h3' className='policy-main-text'>
                        I. COLLECTION OF PERSONAL INFORMATION
                    </Typography>

                    <Typography className='policy-text'>
                        As used herein, “Personal Information” means information that identifies or is reasonably capable of identifying an individual, directly or indirectly, and information that is capable of being associated with an identified or reasonably identifiable individual.
                    </Typography>
                    <Typography className='policy-main-head-text'>
                        <b> (A) Personal Information we collect from you</b>
                    </Typography>

                    <Typography className='policy-text'>
                        We may collect the following categories of Personal Information directly from you:
                    </Typography>

                    <ul>
                        <li>
                            <Typography className='policy-text'>
                                <b>Identification Information</b> such as name, email, phone number, postal address, photo ID etc;
                            </Typography>
                        </li>
                        <li>
                            <Typography className='policy-text'>
                                <b>Commercial Activity</b> such as trading activity, order activity, deposits, NFT related information, withdrawals, account balances;
                            </Typography>
                        </li>
                        <li>
                            <Typography className='policy-text'>
                                <b>Any Information you provide us</b> with such as information required at the time of sign up;
                            </Typography >
                        </li>
                        <li>
                            <Typography className='policy-text'>
                                <b>Correspondence</b> such as information you provide to us in correspondence, including account opening and customer support; and
                            </Typography >
                        </li>
                        <li>
                            <Typography className='policy-text'>
                                <b>Sensory Information</b> such as images that you upload to your User Profile;
                            </Typography>
                        </li>

                    </ul>

                    <Typography className='policy-main-head-text'>
                        <b> (B) Personal Information we collect automatically </b>
                    </Typography>

                    <Typography className='policy-text'>
                        We may collect the following categories of Personal Information automatically through your use of our services:
                    </Typography>

                    <ul>
                        <li>
                            <Typography className='policy-text'>
                                <b>Online Identifiers </b> such as IP address, blockchain address, domain name;
                            </Typography>
                        </li>
                        <li>
                            <Typography className='policy-text'>
                                <b>Device Information </b>such as hardware, operating system, browser;
                            </Typography>
                        </li>
                        <li>
                            <Typography className='policy-text'>
                                <b>Cookies </b>such as session cookies and persistent cookies;
                            </Typography>
                        </li>
                        <li>
                            <Typography className='policy-text'>
                                <b>Usage Data </b> such as system activity, internal and external information related to TheUpsideSpace pages that you visit, clickstream information; and
                            </Typography>
                        </li>
                        <li>
                            <Typography className='policy-text'>
                                <b>Geolocation Data.</b>
                            </Typography>
                        </li>
                    </ul>
                    <Typography className='policy-text'>
                        Our automatic collection of Personal Information may involve the use of Cookies, described in greater detail below.
                    </Typography >

                    <Typography className='policy-main-head-text'>
                        <b> (C <span style={{marginLeft:'-5px'}}>)</span>. Personal Information we collect from third parties </b>
                    </Typography>

                    <Typography className='policy-text'>
                        We may collect and/or verify the following categories of Personal Information about you from third parties, including service providers and our affiliates:
                    </Typography>

                    <ul>
                        <li>
                            <Typography className='policy-text'>
                                <b>Identification Information  </b> such as name, email, phone number, postal address
                            </Typography>
                        </li>
                        <li>
                            <Typography className='policy-text'>
                                <b>Transaction Information  </b> such as public blockchain data (bitcoin, ether, and other Digital Assets are not truly anonymous. We, and any others who can match your public Digital Asset address to other Personal Information about you, may be able to identify you from a blockchain transaction because, in some circumstances, Personal Information published on a blockchain (such as your Digital Asset address and IP address) can be correlated with Personal Information that we and others may have. Furthermore, by using data analysis techniques on a given blockchain, it may be possible to identify other Personal Information about you);
                            </Typography >
                        </li>
                        <li>
                            <Typography className='policy-text'>
                                <b>Financial Information </b> such as bank account information, routing number, credit card number, debit card number; and
                            </Typography>
                        </li>
                        <li>
                            <Typography className='policy-text'>
                                <b>Additional Information </b> at our discretion to comply with legal obligations.
                            </Typography>
                        </li>
                    </ul>
                    <Typography className='policy-main-head-text'>
                        <b> (D). Accuracy and retention of Personal Information </b>
                    </Typography>

                    <Typography className='policy-text'>
                        We take reasonable and practicable steps to ensure that your Personal Information held by us is (i) accurate with regard to the purposes for which it is to be used, and (ii) not kept longer than is necessary for the fulfillment of the purpose for which it is to be used.
                    </Typography >



                    <Typography variant='h3' className='policy-main-text'>
                        II.	USES MADE OF THE INFORMATION
                    </Typography>

                    <Typography variant="body1" className='policy-text'>
                        We collect Personal Information about you in an attempt to provide you with the best experience possible, protect you from risks related to improper use and fraud, and help us maintain and improve our Services. 
                    </Typography>
                    
                    <Typography className='policy-text'>
                        We will not share your Personal Information with third parties, except as described below:
                    </Typography>
                    
                    <ul>
                        <li>
                            <Typography className='policy-text'>
                                (a)	providing, improving and developing our services;
                            </Typography>
                        </li>
                        <li>
                            <Typography className='policy-text'>
                                (b)	researching, designing and launching new features or products;
                            </Typography>
                        </li>
                        <li>
                            <Typography className='policy-text'>
                                (c)	presenting content and information in our Site and our App in the most effective manner for you and for the device you use;
                            </Typography>
                        </li>
                        <li>
                            <Typography className='policy-text'>
                                (d)	providing you with alerts, updates, materials or information about our services or other types of information that you requested or signed up to;
                            </Typography>
                        </li>
                        <li>
                            <Typography className='policy-text'>
                                (e)	verifying your identify;
                            </Typography>
                        </li>
                        <li>
                            <Typography className='policy-text'>
                                (f)	processing payment or credit transactions;
                            </Typography>
                        </li>
                        <li>
                            <Typography className='policy-text'>
                                (g)	complying with laws and regulations applicable to us in any part of the world;
                            </Typography>
                        </li>
                        <li>
                            <Typography className='policy-text'>
                                (h)	assisting in law enforcement and investigations conducted by any governmental and/or regulatory authority;
                            </Typography>
                        </li>
                        <li>
                            <Typography className='policy-text'>
                                (i)	responding or taking part in legal proceedings, including seeking professional advice;
                            </Typography>
                        </li>
                        <li>
                            <Typography className='policy-text'>
                                (j)	communicating with you and responding to your questions or requests; and
                            </Typography>
                        </li>
                        <li>
                            <Typography className='policy-text'>
                                (k)	purposes directly related or incidental to the above.
                            </Typography>
                        </li>
                    </ul>

                    <Typography className='policy-text'>
                        If we decide to modify the purpose for which your Personal Information is collected and used, we will amend this Privacy Policy.
                    </Typography>


                    <Typography variant='h3' className='policy-main-text'>
                        III. COOKIES
                    </Typography>

                    <Typography className='policy-text'>
                        When you access TheUpsideSpace, we may make use of the standard practice of placing tiny data files called cookies, flash cookies, pixel tags, or other tracking tools (herein, “Cookies”) on your computer or other devices used to visit TheUpsideSpace. We use Cookies to help us recognize you as a customer, collect information about your use of TheUpsideSpace to better customize our services and content for you, and collect information about your computer or other access devices to: (i) ensure that your account security has not been compromised by detecting irregular, suspicious, or potentially fraudulent account activities; (ii) assess and improve our services and advertising campaigns.
                    </Typography>
                    <Typography className='policy-text'>
                        You are advised that if you wish to deny the use and saving of cookies from this Site onto your computers and/or other electronic devices, you should take the necessary steps within their internet browsers’ security settings to block all cookies from this Site.
                    </Typography>
                    <Typography className='policy-text'>
                        You also can learn more about cookies by visiting http://www.allaboutcookies.org, which includes additional useful information on cookies and how to block cookies on different types of browsers and mobile devices. Please note that if you reject cookies, you may not be able to use some or all of TheUpsideSpace. If you do not consent to the placing of Cookies on your device, please do not visit, access, or use TheUpsideSpace.
                    </Typography>
                    <Typography className='policy-text'>
                        Where TheUpsideSpace utilizes any third-party vendors, such as Google use first-party cookies (such as the Google Analytics cookies) and third-party cookies (such as the DoubleClick cookie) or other third-party identifiers together to compile data regarding user interactions with ad impressions, and other ad service functions as they relate to our website. Except otherwise stated, TheUpsideSpace does not allow third party behavioral tracking. 
                    </Typography>
                    


                    <Typography variant='h3' className='policy-main-text'>
                        IV.	DIRECT MARKETING
                    </Typography>
                    
                    <Typography className='policy-text'>
                        Subject to applicable laws and regulations, we may from time to time send direct marketing materials promoting services, products, facilities, or activities to you using information collected from or about you. You may also opt-out of such communications by following the directions provided in any marketing communication. It is our policy to not provide your Personal Information for those third parties’ direct marketing purposes without your consent.
                    </Typography>

                    <Typography variant='h3' className='policy-main-text'>
                        V. INFORMATION SECURITY 
                    </Typography>
                    <Typography className='policy-text'>
                        No security is foolproof, and the Internet is an insecure medium. Although we try our best to protect your personal data, we cannot guarantee absolute security, but we work hard to protect TheUpsideSpace and you from unauthorized access to or unauthorized alteration, disclosure, or destruction of Personal Information we collect and store. 
                    </Typography>
                    <Typography className='policy-text'>
                        Measures we take include encryption of TheUpsideSpace website communications with SSL; optional two-factor authentication; periodic review of our Personal Information collection, storage, and processing practices; and restricted access to your Personal Information on a need-to-know basis for our employees, contractors and agents who are subject to strict contractual confidentiality obligations and may be disciplined or terminated if they fail to meet these obligations.
                    </Typography>

                    <Typography variant='h3' className='policy-main-text'>
                        VI. DATA RETENTION
                    </Typography>
                    <Typography className='policy-text'>
                        We retain user data for no longer than necessary in relation to the purposes for which it is collected or otherwise processed.
                    </Typography>
                    <Typography className='policy-text'>
                        Your Personal Information shall be deleted immediately, unless stated otherwise in this Privacy Policy, after you delete your account on TheUpsideSpace website. However, other anonymous data in reference to your access and use of the Services collected with your permission may be retained indefinitely. You can contact us at any time to have your Personal Information deleted or modified to reflect the most accurate details.
                    </Typography>
                    <Typography className='policy-text'>
                        We will retain your Personal Information in a private electronic database for the period that we find to be necessary to fulfill the purposes outlined in this Privacy Policy, unless we believe a longer retention period is required or permitted by law. When your Personal Information in our possession is no longer needed, we may erase, destroy, or otherwise modify it in whole or part, at our sole discretion.
                    </Typography>


                    <Typography variant='h3' className='policy-main-text'>
                        VII. YOUR RIGHTS
                    </Typography>

                    <Typography className='policy-text'>
                        Subject to any applicable legal and regulatory exemptions, you have the right to:
                    </Typography>

                    <ul>
                        <li>
                            <Typography className='policy-text'>
                                (a)	check whether we hold personal data about you;
                            </Typography>
                        </li>
                        <li>
                            <Typography className='policy-text'>
                                (b)	access any personal data we hold about you; and
                            </Typography>
                        </li>
                        <li>
                            <Typography className='policy-text'>
                                (c)	require us to correct any inaccuracy or error in any personal data we hold about you.
                            </Typography>
                        </li>
                    </ul>

                    <Typography className='policy-text'>
                        Any such requests may be subject to a small administrative fee to meet our cost in processing your request.
                    </Typography>
                    
                    <Typography variant='h3' className='policy-main-text'>
                        VIII. Information For European Residents
                    </Typography>

                    <Typography className='policy-text'>
                        We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following: 
                    </Typography>

                    <ul>
                        <li>
                            <Typography className='policy-text'>
                                (a)	The right to access. You have the right to request copies of your personal data. We may charge you a small fee for this service. 
                            </Typography>
                        </li>
                        <li>
                            <Typography className='policy-text'>
                                (b)	The right to rectification. You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete. 
                            </Typography>
                        </li>
                        <li>
                            <Typography className='policy-text'>
                                (c)	The right to erasure. You have the right to request that we erase your personal data, under certain conditions. 
                            </Typography>
                        </li>
                        <li>
                            <Typography className='policy-text'>
                                (d)	The right to restrict processing. You have the right to request that we restrict the processing of your personal data, under certain conditions.
                            </Typography>
                        </li>
                        <li>
                            <Typography className='policy-text'>
                                (e)	The right to object to processing. You have the right to object to our processing of your personal data, under certain conditions. 
                            </Typography>
                        </li>
                        <li>
                            <Typography className='policy-text'>
                                (f)	The right to data portability. You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
                            </Typography>
                        </li>
                    </ul>

                    <Typography className='policy-text'>
                        If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us. Any such requests may be subject to a small administrative fee to meet our cost in processing your request.
                    </Typography>

                    <Typography variant='h3' className='policy-main-text'>
                        IX. Information For Persons From Outside the Republic of Singapore
                    </Typography>

                    <Typography className='policy-text'>
                        TheUpsideSpace is based in the Republic of Singapore and governed by Singapore law. If you are accessing our site from outside the Republic of Singapore, please be aware that personal information collected through the site will be processed in the Republic of Singapore. Data protection laws in Republic of Singapore are likely different from those of your country of residence. By submitting your personal information through our site, you acknowledge that your personal information will be processed in Republic of Singapore in accordance with this policy. Visitors residing outside the Republic of Singapore may contact us at info@theupsidespace.com for more information about our data practices.
                    </Typography>

                    <Typography className='policy-text'>
                        Further, you agree, acknowledge and consent that your personal information (and such other personal information which you provide to us) may be stored or processed in any country where we have operations or where we engage service providers. We may transfer personal information outside Singapore to countries which may have data protection rules that are different from those of Singapore. However, we shall take measures to ensure that any such transfers comply with the Singapore Data Protection Act, and such personal information remains protected to the standards described in this Privacy Policy. In certain circumstances, courts, law enforcement agencies, regulatory agencies or security authorities in those other countries may be entitled to access the personal information.
                    </Typography>

                    <Typography variant='h3' className='policy-main-text'>
                        X. THIRD-PARTY SITES
                    </Typography>
                    <Typography className='policy-text'>
                        Our Site or our communication with you may contain links to third-party websites, over which we have no control. If you follow a link to any of these websites, please note that they have their own practices and policies. 
                    </Typography>
                    <Typography className='policy-text'>
                        We accept no responsibility or liability for any practices of third-party websites.
                    </Typography>
                    
                    <Typography variant='h3' className='policy-main-text'>
                        XI. CONTACT US
                    </Typography>

                    <Typography className='policy-text'>
                        If you have question, concerns or feedback regarding this policy or our processing of your Personal Information, you may contact our Data Protection Officer at:
                    </Typography>

                    <Typography className='policy-text'>
                        <b>The Data Protection Officer</b> <br />
                        Email address: info@theupsidespace.com
                    </Typography>

                    <Typography className='policy-text'>
                        We may amend this policy from time to time by posting the updated policy on our Site. By continuing to use our Site and our App after the changes come into effect means that you agree to be bound by the revised policy.
                    </Typography>

                    {/* <Typography variant='h3' className='policy-main-text'>
                        XII. Your Rights
                    </Typography> */}

                    <Typography className='policy-text'>
                        Last updated: <b>06 December 2022</b>
                    </Typography>

                </Box>
            </Box>
        </Container>
    );
};

export default PrivacyPolicyPage;
