import { ReactElement } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import {
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    ListItemSecondaryAction,
} from '@material-ui/core';

const FollowListSkeleton = (): ReactElement => {
    const items = [1, 2, 3];
    return (
        <>
            {items.map((value) => (
                <ListItem key={value}>
                    <ListItemAvatar>
                        <Avatar>
                            <Skeleton animation="wave" width={50} height={50} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Skeleton
                                style={{
                                    height: '40px',
                                    width: '150px',
                                }}
                            />
                        }
                    />
                    <ListItemSecondaryAction>
                        <Skeleton style={{ height: '45px', width: '100px' }} />
                    </ListItemSecondaryAction>
                </ListItem>
            ))}
        </>
    );
};

export default FollowListSkeleton;
