import { ReactElement } from 'react';
import { BankAccountList } from './components';
import { HeadTag } from '../../shared/components';

const BankAccountsPage = (): ReactElement => (
    <>
        <HeadTag title="Bank Accounts" />
        <BankAccountList />
    </>
);

export default BankAccountsPage;
