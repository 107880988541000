import { ReactElement, useState, useEffect } from 'react';
import { Box, Grid, Container, Typography, CircularProgress } from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import CollectionsIcon from '@material-ui/icons/Collections';
import { EmptyPlaceholder, HeadTag } from '../../../shared/components';
import { CollectionService, DefaultPagination } from '../../../services';
import { getUrl } from '../../../shared/helpers/Utils';
import { AppState } from '../../../state/RootReducer';
import { Utils } from '../../../shared/helpers';

export interface ARTISTINFO {
    marketPlaceFilter?: any;
    marketPlace?: any;
    loaderUi?: any;
    isAPICalling?: any;
}

const ArtistsTab = (props: ARTISTINFO): ReactElement => {
    const marketPlaceFilter = useSelector((state: AppState) => state.marketPlaceFilter)
    const { marketPlace, loaderUi, isAPICalling } = props;

    const title = 'Artist Tab';
    const description = " Artist Tab";

    const [artists, setArtists] = useState([]);
    const [pagination, setPagination] = useState({ ...DefaultPagination(12) });
    const [hasMoreItems, setHasMoreItems] = useState(false);
    const [loader, setLoader] = useState(true);
    const [empty, setEmpty] = useState(false)

    // TODO immediate implementation
    const loadItems = (): void => {
        if (marketPlace) {
            if (pagination.lastOffset !== pagination.offset) {
                pagination.lastOffset = pagination.offset;
                CollectionService.getArtists(
                    30,
                    pagination.offset,
                    marketPlaceFilter.query,
                    marketPlaceFilter.region,
                    marketPlaceFilter.exhibition,
                    'asc'
                )
                    .then((response: any) => {
                        setLoader(false);
                        const newItems = response.data.data.users;
                        const paginationResponse = response.data.data.pagination;
                        setArtists(
                            pagination.offset === 1
                                ? newItems
                                : artists.concat(newItems)
                        )
                        if (artists.concat(newItems).length <= 0) {
                            setEmpty(true)
                        }
                        setHasMoreItems(Utils.hasMoreRecords(paginationResponse));
                        setPagination({
                            ...paginationResponse,
                            lastOffset: pagination.lastOffset,
                            offset: pagination.offset + 1,
                        });
                    })
                    .catch((error) => {
                        setLoader(false);
                        Utils.handleErrorResponse({ error });
                    });
            }
        } else {
            setLoader(true);
            CollectionService.getTrendingArtists()
                .then((response: any) => {
                    setLoader(false);
                    if (response) setArtists(response.data.data)
                })
                .catch((error) => {
                    setLoader(false);
                    Utils.handleErrorResponse({ error });
                });
        }

    }

    // Business Logic
    const reloadItems = (): void => {
        pagination.offset = 1;
        pagination.lastOffset = 0;
        setArtists([]);
        loadItems();
    };

    const loadMoreItems = (): void => {
        loadItems();
    };

    useEffect(() => {
        reloadItems();
    }, [marketPlaceFilter]);
    /* eslint no-underscore-dangle: 0 */
    return (
        <Box className='artist-detail-main-container'>
            <HeadTag title={title} description={description} />
            <InfiniteScroll
                threshold={50}
                pageStart={1}
                loadMore={loadMoreItems}
                hasMore={hasMoreItems}
            >
                <Box>
                    <Box className='exhibition-main-wrapper exhibition-tab-main-wrapper'>
                        <Box className='exibition-artists-component'>
                            {/* {(loader || isAPICalling) ? loaderUi : */}
                             <Box className='exibition-artists-tag exibition-artists-tag-center'>
                                {artists.map((artist: any) => (
                                    <Grid item xs={6} sm={4} md={4} lg={3} >
                                        <Link to={`/artist/${artist.userId}`} key={artist.userId} className="artist-link artist-link-tab">
                                            <Box className='exibition-artists-bg'>
                                                <Box className='exibition-artists-tag-box' >
                                                    <img src={artist.profilePicture ? getUrl(`${artist?.profilePicture}`, '464x464/') : "/images/art-blank-design.svg"} alt="ArtistImg" />
                                                    {/* <img src='https://e7.pngegg.com/pngimages/753/432/png-clipart-user-profile-2018-in-sight-user-conference-expo-business-default-business-angle-service-thumbnail.png' alt="ArtistImg" /> */}
                                                </Box>
                                                <Typography>
                                                    {artist?.name}
                                                </Typography>
                                            </Box>
                                        </Link>
                                    </Grid>
                                ))}

                            </Box>
                         {/*   } */}
                        </Box>

                    </Box>
                </Box>
            </InfiniteScroll>
            {(loader || isAPICalling) ? loaderUi : <></>}
            {(!loader || !isAPICalling) && artists.length <= 0  && (
              <EmptyPlaceholder
              icon={<img src="/icons/NoData.png" />}
              />
            )}

           
        </Box>
    )
}

const mapStateToProps = (state: AppState): any => ({
    isAPICalling: state.authState.isAPICalling

});
export default connect(mapStateToProps, null)(ArtistsTab);
